export enum DebriefingSteps {
	INITIAL_MESSAGE = 'initial_message',
	CHOOSE_PATH = 'choose_path',
	PARTICIPANTS_CHOICE = 'participants_choice',
	GROUP_PROCESS_MESSAGE = 'group_process_message',
	EXPECTED_RESULT = 'expected_result',
	CHOOSE_GOAL = 'choose_goal',
	CHOOSE_VALUE = 'choose_value',
	CHOOSE_DEPARTMENT = 'choose_department',
	WRITE_DEPARTMENT = 'write_department',
	CHOOSE_SUB_DEPARTMENT = 'choose_sub_department',
	WRITE_SUB_DEPARTMENT = 'write_sub_department',
	RECONSTRUCTION_MESSAGE = 'reconstruction_message',
	CHRONOLOGICAL_EVENTS = 'chronological_events',
	MACTHPOINT_CHOICE = 'matchpoint_choice',
	MATCHPOINT_CONFIRMATION = 'matchpoint_confirmation',
	SELF_ANALISYS_MESSAGE = 'self_analisys_message',
	DEBRIEFING_SELF_ANALISYS_CHAT = 'DebriefingSelfAnalisys',
	LEARNING_PROCESS_OUTPUT = 'learningprocessoutputs',
	SUMMARY = 'summary',
	FINISH = 'finish',
}
