import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles<Theme>(({ palette, breakpoints }) => ({
	container: {
		display: 'flex',
		margin: '3rem auto 0',
		width: '68.5%',
		[breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	percent: {
		color: palette.primary.main,
		fontFamily: 'Sora, sans-serif',
		fontSize: '42px',
		letterSpacing: 0,
		lineHeight: '53px',
		textAlign: 'center',
	},

	box: {
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
		textAlign: 'center',
		alignItems: 'center',
		justifyItems: 'space-evenly',
		padding: '2% 0 0 1%',
	},
	praiseChip: {
		backgroundColor: '#34AB53',
		color: 'white',
		padding: '0 20px',
		borderRadius: '20px',
		display: 'flex',
		alignSelf: 'flex-start',
	},
	feedbackChip: {
		backgroundColor: '#0071C2',
		color: 'white',
		padding: '0 20px',
		borderRadius: '20px',
		display: 'flex',
		alignSelf: 'flex-start',
	},
	borderBox: {
		height: '13em',
		border: `2px solid #DEE3EF`,
		borderRadius: '10px',
		boxShadow: 'rgb(0 0 0 / 4%) 2px 2px',
		padding: '0 1%',
		flex: 1,
		margin: '0 1rem',

		'&:hover': {
			border: `2px solid ${palette.secondary.main}`,
		},
		[breakpoints.down('sm')]: {
			margin: 0,

			'&:nth-child(1)': {
				margin: '5.5px',
			},

			'&:hover': {
				border: `2px solid #DEE3EF`,
			},
		},
	},
}))