import { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

type MessageHeaderProp = {
	bgColor?: string
	marginTop?: string
	additionalClass?: string
	renderItem?: React.ReactNode
}
export const MessageHeader: FC<MessageHeaderProp> = ({
	children,
	additionalClass,
	renderItem,
	...rest
}) => {
	const classes = useStyles({ ...rest })

	return (
		<>
			{renderItem}
			<div
				className={
					additionalClass
						? `${classes.headerSection} ${additionalClass}`
						: classes.headerSection
				}
			>
				{children}
			</div>
		</>
	)
}

export const useStyles = makeStyles<
	Theme,
	Pick<MessageHeaderProp, 'bgColor' | 'marginTop'>
>(({ breakpoints }) => ({
	headerSection: {
		marginTop: ({ marginTop }) => marginTop ?? '20px',
		padding: '15px ',
		display: 'flex',
		flexDirection: 'column',
		background: ({ bgColor }) => bgColor ?? '#0071C2',
		borderRadius: '14px',
		[breakpoints.down('sm')]: {
			margin: '0 3% 0 3%',
		},
	},
}))
