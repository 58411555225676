import { FC, useState, useEffect, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import useStyles from './useStyles'
import { useStyles as innerStyles } from '../useStyles'
import { userLoggedInSelector, userSelector } from 'redux/user/user-selector'

import { UseClickActions } from 'components/Bot/useHooks/index'
import { Loader } from 'components/common/Loader'
import UserAvatar from 'components/common/Avatar'
import BotOutput from 'components/BotOutput/index'
import Button from 'components/common/Button'

import launch from 'assets/launch.svg'
import rocketRide from 'assets/rocketRideTransparent.svg'
import clappingIcon from 'assets/clappingIcon.svg'
import { OutputWrapperProps } from 'interface/bot'
import RenderOneOnOneReviewButton from 'components/BotOutput/RenderOneOnOneReviewButton'
import { AppDirectionContext } from 'context/languages-context'
import TextareaButtons from '../textareaButtons'
import { update_replay_message } from 'redux/praise/praise-actions'

const PraiseReciever: FC<OutputWrapperProps> = props => {
	const {
		readed,
		VALUES = [],
		receiver,
		sender,
		FREE_TEXT_LABEL,
		FREE_TEXT,
		CONTEXT,
		CONTRIBUTION,
		IMPACTS,
		impactsExpansion = [],
		contributionExpansion = '',
		replay,
		currentOutputId,
		handleToggleReview,
	} = props

	console.log({ props })
	const { isRtlDirection } = useContext(AppDirectionContext)
	const { company_id } = useSelector(userSelector)

	const [loading, setLoading] = useState(true)

	const [replayMessage, setReplayMessage] = useState('')
	const [editReplay, setEditReplay] = useState(false)

	const isPraise = true
	const value = VALUES?.join(',')
	const {
		personalInfo: { firstName = '' },
	} = receiver
	const {
		personalInfo: { firstName: senderFirstName = '' },
	} = sender

	const history = useHistory()
	const classes = useStyles()
	const innerClasses = innerStyles({ isRtlDirection, readed })
	const { t } = useTranslation()

	const dispatch = useDispatch()
	const userLoggedIn = useSelector(userLoggedInSelector)

	const { closeAction } = UseClickActions(isPraise)

	useEffect(() => {
		!userLoggedIn && history.push('/')
		return
	}, [])

	const canEdit = !replay?.trim() ? true : false
	const toggleEditMode = () => !replay?.trim() && setEditReplay(prev => !prev)

	const handleReplay = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setReplayMessage(e.target.value)
	}
	const handleSaveReplay = () => {
		const payload = {
			company_id,
			output_id: currentOutputId!,
			replayMessage,
		}
		dispatch(update_replay_message(payload))
		toggleEditMode()
	}

	useEffect(() => {
		setTimeout(() => {
			setLoading(false)
		}, 1000)
	}, [])

	if (loading) return <Loader />
	console.log({ IMPACTS })
	return (
		<>
			<div className={`${classes.recognizion} ${classes.receiver}`}>
				{t('great_job', {
					name: firstName,
					interpolation: { escapeValue: false },
				})}

				<embed src={clappingIcon} />
			</div>
			<div className={`${classes.recognizion} ${classes.userSending}`}>
				{t('Youv’e received praise')}

				<div className={classes.greetings}>
					<span className={classes.userName}>{senderFirstName}</span>
					!
					<UserAvatar userInfo={sender} className={classes.senderAvatar} />
				</div>
			</div>
			<BotOutput>
				<BotOutput.ContextWrapper>
					<div className={classes.launchIcon}>
						<embed src={launch} />
					</div>
					<div className={classes.intro}>
						{t('praise_output_intro_text_1')}
						<br />
						{t('praise_output_intro_text_2')}
					</div>

					<BotOutput.Context>
						<div className={classes.context}>{CONTEXT}</div>
					</BotOutput.Context>

					{FREE_TEXT_LABEL && FREE_TEXT && (
						<BotOutput.Text
							label={`${FREE_TEXT_LABEL}`}
							text={FREE_TEXT}
							icon={isPraise ? rocketRide : null}
						/>
					)}

					{CONTRIBUTION && (
						<>
							<BotOutput.ContextPoints text={CONTRIBUTION} />
							{contributionExpansion.trim() !== '' && (
								<BotOutput.Text text={contributionExpansion} label={''} />
							)}
						</>
					)}

					{!!IMPACTS.length &&
						IMPACTS.map((impact: string, index: number) => (
							<>
								<BotOutput.ContextPoints text={impact} />
								{!!impactsExpansion[index] && (
									<BotOutput.Text text={impactsExpansion[index]} label={''} />
								)}
							</>
						))}

					{!!VALUES.length && (
						<BotOutput.ContextPoints
							text={t('conduct_value_receiver_praise', {
								username: senderFirstName,
								value,
								interpolation: { escapeValue: false },
							})}
						/>
					)}
				</BotOutput.ContextWrapper>
				<div
					className={classes.textInput}
					style={{ direction: isRtlDirection ? 'rtl' : 'ltr' }}
				>
					{!replay?.trim() && <div>{t('your_comment')}</div>}

					<div style={{ display: 'flex', flexDirection: 'row' }}>
						{editReplay ? (
							<textarea
								className={innerClasses.replayTextarea}
								onChange={handleReplay}
								value={replayMessage}
								autoFocus
								rows={4}
							/>
						) : (
							<div>{replay}</div>
						)}
						{!replay?.trim() && (
							<TextareaButtons
								replay={replay!}
								replayMessage={replayMessage}
								canEdit={canEdit}
								isEdit={editReplay}
								toggleEditMode={toggleEditMode}
								handleUpdateText={handleSaveReplay}
							/>
						)}
					</div>
				</div>
			</BotOutput>

			<div className={classes.buttonsWrapper}>
				<div>
					<Button className={classes.button} onClick={closeAction}>
						{t('Home Page')}
					</Button>
				</div>
				<RenderOneOnOneReviewButton
					{...{ styles: innerClasses, handleClick: handleToggleReview }}
				/>
			</div>
		</>
	)
}

export default PraiseReciever
