import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import { IThemeOptions } from 'theme'

import makeBotStyles from '../../Bot/ActionButtons/useStyles'

import { ActionButton } from './ActionButton'
import ConfirmModalSB from '../../common/confirmModalSB/ConfirmModal'
// import ConfirmModal from '../../common/ConfirmModal/ConfirmModal'

import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import { Debrief } from 'interface/debrief/debrief-interface'

interface Step {
	step: DebriefingSteps
	data?: Partial<Debrief>
}
interface Props {
	nextStep?: Step
	prevStep?: Step
	isNextDisabeld?: boolean
	bottomText?: string
	height?: string
	isParticipant?: boolean
	update: (step: any, data: any) => void
	nextButtonText?: string
	setParticipantStep?: (step: DebriefingSteps) => void
}

const containerStyle = {
	paddingTop: '1%',
	marginBottom: '2%',
	marginTop: 'auto',
	height: 'auto',
}

export const ActionButtons = (props: Props) => {
	const {
		isNextDisabeld,
		nextStep,
		isParticipant,
		setParticipantStep,
		update,
		prevStep,
		bottomText,
		nextButtonText,
	} = props

	const [displayFinishModal, setDisplayFinishModal] = useState<boolean>(false)

	const { t } = useTranslation()
	const classes = makeBotStyles()
	const innerClasses = useInnerStyles({ isNextDisabeld })

	const isSelfAnalisys = nextStep?.step === DebriefingSteps.SELF_ANALISYS_MESSAGE
	const isSummary = nextStep?.step === DebriefingSteps.SUMMARY
	const isFinish = nextStep?.step === DebriefingSteps.FINISH

	const handleModalDisplay = (shouldDisplay = false) => setDisplayFinishModal(prev => shouldDisplay)

	const moveStep = (forward: boolean) => {
		if (forward && isFinish && !displayFinishModal) {
			handleModalDisplay(true)
			return
		}

		if (isParticipant) {
			if (nextStep?.step === DebriefingSteps.SELF_ANALISYS_MESSAGE) {
				setParticipantStep?.(DebriefingSteps.DEBRIEFING_SELF_ANALISYS_CHAT)
			}
			return
		}
		const data = forward ? nextStep?.data : prevStep?.data
		update(data, forward ? nextStep?.step : prevStep?.step)
		if (isParticipant)
			setParticipantStep?.(DebriefingSteps.LEARNING_PROCESS_OUTPUT)
	}

	const finishStepText = isFinish ? t('debrief.finish') : t(`next`)
	const summaryStepText = isSummary ? t('debrief.summary') : finishStepText
	const selfAnalisysText = isSelfAnalisys ? t('debrief.confirm') : summaryStepText
	const nextButton = nextButtonText ? nextButtonText : selfAnalisysText

	return (
		<>
			<div
				id='botButtonsContainer'
				style={containerStyle}
				className={classes.container}
			>
				<ActionButton
					classes={`${classes.previousButton} ${innerClasses.previousButton}`}
					displayButton={prevStep && !isParticipant}
					handleClick={() => moveStep(false)}
				>
					{t(`back`)}
				</ActionButton>

				<ActionButton
					isButtonDisabled={isNextDisabeld}
					displayButton={nextStep && !isParticipant}
					handleClick={() => moveStep(true)}
				>
					{nextButton}
				</ActionButton>
			</div>

			{bottomText && <div className={innerClasses.bottomText}>{bottomText}</div>}

			<ConfirmModalSB
				isOpen={displayFinishModal}
				onClose={handleModalDisplay}
				onDiscard={() => handleModalDisplay(false)}
				onConfirm={() => {
					handleModalDisplay(true)
					moveStep(true)
				}}
				child={t('debrief.endLessonModal')}
			/>
		</>
	)
}

export default ActionButtons

export const useInnerStyles = makeStyles<
	IThemeOptions,
	{ isNextDisabeld?: boolean }
>(({ breakpoints, palette }) => ({
	bottomText: {
		color: 'rgba(151, 151, 151, 1)',
	},
	nextButton: {
		cursor: ({ isNextDisabeld }) => (isNextDisabeld ? 'unset' : 'pointer'),
		'&:hover': {
			backgroundColor: ({ isNextDisabeld }) =>
				isNextDisabeld ? '' : palette.debrief.dark,
		},
		backgroundColor: palette.debrief.main,
	},
	previousButton: {
		'&:hover': {
			backgroundColor: palette.debrief.light,
		},
		color: palette.debrief.main,
		borderColor: palette.debrief.main,
	},
}))
