import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './learningOutputStyles'

export enum DEPARTMENT_TYPE {
	DEPARTMENT = 'department',
	SUB_DEPARTMENT = 'sub_department',
}

type DepartmentViewProps = {
	handleDoubleClick: () => void
	placeholder: string
	value?: string | React.ReactNode
}

export const DepartmentView: FC<DepartmentViewProps> = props => {
	const { handleDoubleClick, placeholder, value } = props
	const classes = useStyles()

	const { t } = useTranslation()

	return (
		<span className={classes.infoBarData} onDoubleClick={handleDoubleClick}>
			<span
				className={classes.placeholder}
				style={{ display: 'flex', alignItems: 'center' }}
			>
				<div>{t(placeholder, { interpolation: { escapeValue: false } })}</div>

				{value}
			</span>
		</span>
	)
}
