import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
type Styles = {
	shouldShow: Array<string>
	employee_id: string
}
export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
	container: {
		width: '100%',
		height: '45px',
		display: 'flex',
		overflow: 'hidden',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
		fontSize: '14px',
		cursor: 'pointer',
		textDecorationColor: 'lightgrey',

		textDecorationLine: ({ shouldShow, employee_id }) =>
			shouldShow.includes(employee_id) ? 'none' : 'line-through',
		'& >div': {
			color: ({ shouldShow, employee_id }) =>
				shouldShow.includes(employee_id) ? 'grey' : 'lightgrey !important',
			[breakpoints.down('xs')]: {
				fontSize: '14px',
			},
			'& >.MuiCheckbox-colorPrimary': {
				color: ({ shouldShow, employee_id }) =>
					shouldShow.includes(employee_id) ? 'grey' : 'lightgrey',
				[breakpoints.down('xs')]: {
					// width: '20px',
					height: '20px',
				},
			},
		},

		'& >div>span': {
			padding: 0,
		},
	},
	description: {
		height: '100%',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		textAlign: 'start',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		position: 'relative',
		paddingLeft: '1%',
	},
	date: {
		color: '#FF8A8A !important',
	},
}))
