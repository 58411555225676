import { FC, useState, ChangeEvent, useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import eyeShown from 'assets/eye-shown.svg'
import eyeHidden from 'assets/eye-hidden.svg'
import {
	serverErrorSelector,
	userErrorSelector,
	userLoadingSelector,
	userLoggedInSelector,
	userSelector,
} from 'redux/user/user-selector'
import useStyles from './styles'
import Input from '../common/Input'
import Paths from 'enums/routes/path-names'
import { userActions } from 'redux/user/user-reducer'
import {
	ActionTypes,
	FormErrors,
	FormType,
	FormState,
	reducer,
} from './useReducer'
import { sign_in_user, interval_job } from 'redux/user/user-actions'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'
// import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal'
import { Loader } from '../common/Loader/index'
import { Button, Checkbox } from '@material-ui/core'
import ForgotPassword from './ForgotPassword'
import { ok_emoji, error_emoji } from 'components/common/Constants/emojis'

type Props = {
	displayAcceptedPolicyTerm?: boolean
	isChangePasswordScreen?: boolean
	userChangingPasswordEmail?: string
	onContentChange?: (status: boolean) => void
}

const initialState: FormState = {
	isValid: false,
	type: null,
	email: '',
	password: '',
	newPassword: '',
}

const LoginForm: FC<Props> = ({
	displayAcceptedPolicyTerm,
	isChangePasswordScreen,
	userChangingPasswordEmail,
	onContentChange,
}) => {
	const { t } = useTranslation()
	const [{ type, email, password, newPassword, isValid }, localDispatch] =
		useReducer(reducer, initialState)
	//const changePasswordForm = type === FormType.change_password
	const [lang, setLang] = useState<string>('EN')
	const [forgotFormIsShown, setForgotFormIsShown] = useState(false)
	const [validPassword, setValidPassword] = useState(true)
	const [showPassword, setShowPassword] = useState<boolean>(false)
	const [hasAcceptedPolicyTerm, setHasAcceptedPolicyTerm] = useState(false)
	const [errors, setErrors] = useState<FormErrors>({})
	const [tooltipTitles, setTooltipTitle] = useState<
		{ emoji: string; title: string }[]
	>([])
	const [tooltipMatchedTitles, setTooltipMatchedTitles] = useState<
		{ emoji: string; title: string }[]
	>([])
	const userError = useSelector(userErrorSelector)
	const isLoggedIn = useSelector(userLoggedInSelector)
	const history = useHistory()
	const dispatch = useDispatch()
	const serverError = useSelector(serverErrorSelector)
	const userLoading = useSelector(userLoadingSelector)
	const user = useSelector(userSelector)
	const classes = useStyles({
		isDisabled: userChangingPasswordEmail
			? (!displayAcceptedPolicyTerm && !hasAcceptedPolicyTerm) ||
			  !validPassword ||
			  password !== newPassword
			: isValid,
	})

	useEffect(() => {
		isLoggedIn && history?.push(Paths.DASHBOARD)
		return () => {
			const payload = {
				user_id: user._id!,
				loginAction: true,
			}
			// dispatch(interval_job(payload))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoggedIn])

	useEffect(() => {
		localDispatch({
			type: isChangePasswordScreen ? FormType.change_password : FormType.login,
		})
		localDispatch({
			type: ActionTypes.validate_form,
		})
	}, [])
	const onTogglePassword = () => setShowPassword(prev => !prev)
	const onFormChange = ({
		target: { name, value },
	}: ChangeEvent<HTMLInputElement>) => {
		if (userError) dispatch(userActions.clearUserError())

		if (name === 'email') {
			setErrors({ ...errors, email: '' })
			localDispatch({ type: ActionTypes.set_email, email: value })
		}
		if (name === 'new_password') {
			const titles: any = []
			const smallLetterTest = /[a-z]/.test(value)
			const capitalLetterTest = /[A-Z]/.test(value)
			const hasNumbersTest = /[0-9]/.test(value)
			const lengthTest = value.length > 7

			if (smallLetterTest)
				titles.push({
					title: 'Your password includes a lowercase letter',
					emoji: ok_emoji,
				})
			else
				titles.push({
					title: "Your password doesn't have a lowercase letter",
					emoji: error_emoji,
				})
			if (capitalLetterTest)
				titles.push({
					title: 'Your password has an uppercase letter',
					emoji: ok_emoji,
				})
			else
				titles.push({
					title: "Your password doesn't have an uppercase letter",
					emoji: error_emoji,
				})
			if (lengthTest)
				titles.push({
					title: 'Your password is 8 digits or more',
					emoji: ok_emoji,
				})
			else
				titles.push({
					title: 'Your password must be at least 8 digits long',
					emoji: error_emoji,
				})
			setTooltipTitle(titles)
			setValidPassword(smallLetterTest && capitalLetterTest && lengthTest)
			localDispatch({ type: ActionTypes.set_new_password, password: value })
		}
		if (name === 'password') {
			setErrors({ ...errors, password: '' })
			localDispatch({ type: ActionTypes.set_password, password: value })
		}

		localDispatch({
			type: ActionTypes.validate_form,
		})
	}

	const onFormSubmit = async (e: any) => {
		e.preventDefault()
		setErrors({})

		if (!isChangePasswordScreen && email.trim().length === 0) {
			setErrors({ email: 'email field cannot be empty' })
			return
		}

		if (isChangePasswordScreen && !validPassword) {
			return dispatch(userActions.setError(t('not_strong_password')))
		}

		if (isFormValid()) {
			const payload = {
				email: userChangingPasswordEmail ? userChangingPasswordEmail : email.trim(),
				password,
				changePassword: userChangingPasswordEmail !== undefined,
			}

			if (isChangePasswordScreen && !displayAcceptedPolicyTerm) {
				const payloadAcceptedPolicyTerm = {
					...payload,
					hasAcceptedPolicyTerm,
				}

				dispatch(sign_in_user(payloadAcceptedPolicyTerm))
				return
			}
			dispatch(sign_in_user({ ...payload }))
		}
	}

	const isFormValid = () => {
		const formErrors: FormErrors = {}
		if (userChangingPasswordEmail) {
			if (newPassword.trim() === '') {
				formErrors.newPassword = 'This field is required.'
			}
		} else {
			if (email.trim() === '') {
				formErrors.email = 'This field is required.'
			}
		}
		if (password.trim() === '') {
			formErrors.password = 'This field is required.'
		}

		const hasNewErrors = formErrors.newPassword || formErrors.password

		const isValid = userChangingPasswordEmail
			? formErrors.email || formErrors.password
			: !hasNewErrors && isChangePasswordScreen && validPassword

		if (isValid) {
			setErrors(formErrors)
			return false
		}
		return true
	}

	useEffect(() => {
		onContentChange && onContentChange(!forgotFormIsShown)
	}, [forgotFormIsShown])

	useEffect(() => {
		// if user changed the password we would redirect him to app
		if (userChangingPasswordEmail) {
			setTimeout(() => {
				history.push('/')
			}, 1000 * 60 * 20)
		}
	}, [userChangingPasswordEmail])
	const handleChooseLang = (lang: string) => setLang(lang)
	useEffect(() => {
		if (!userChangingPasswordEmail) return
		const titles: any = []
		const matchedPassword =
			password.trim() && newPassword.trim() && newPassword === password
		if (matchedPassword)
			titles.push({
				title: 'Password and confirm password does match.',
				emoji: ok_emoji,
			})
		else
			titles.push({
				title: 'Password and confirm password does not match.',
				emoji: error_emoji,
			})
		setTooltipMatchedTitles(titles)
	}, [newPassword, password])

	const termsServiceEG = () =>
		window.open(
			Paths.PRIVACY_POLICY_EG,
			require('../../documents/terms-of-use.pdf')
		)
	const privacyPolicyEG = () =>
		window.open(
			Paths.TERMS_SERVICE_EG,
			require('../../documents/privacy-policy.pdf')
		)
	const privacyPolicyHE = () =>
		window.open(
			Paths.PRIVACY_POLICY_HE,
			require('../../documents/privacy-policy.pdf')
		)
	const termsServiceHE = () =>
		window.open(
			Paths.TERMS_SERVICE_HE,
			require('../../documents/terms-of-use.pdf')
		)

	const anchorStyle = {
		padding: '0 5px',
		textDecorationLine: 'underline',
		outline: 'none',
		color: 'blue',
		border: 'none',
		backgroundColor: 'inherit',
		fontSize: '15.5px',
		cursor: 'pointer',
	}
	const termsInEnglish = (
		<>
			<p>I accept the </p>
			<button onClick={privacyPolicyEG} style={anchorStyle}>
				privacy policy
			</button>
			<p>and the</p>
			<button onClick={termsServiceEG} style={anchorStyle}>
				terms of service
			</button>
		</>
	)
	const termsInHebrew = (
		<>
			<p>אני מקבל את </p>
			<button onClick={privacyPolicyHE} style={anchorStyle}>
				מדיניות פרטיות
			</button>
			<p>ואת</p>
			<button onClick={termsServiceHE} style={anchorStyle}>
				תנאי השימוש
			</button>
		</>
	)
	return (
		<div className={classes.container}>
			{!forgotFormIsShown ? (
				<form onSubmit={onFormSubmit}>
					<div className={classes.wrapper}>
						<Input
							label={userChangingPasswordEmail ? 'new_password' : 'email'}
							type={userChangingPasswordEmail ? 'password' : 'email'}
							name={userChangingPasswordEmail ? 'new_password' : 'email'}
							htmlFor={userChangingPasswordEmail ? 'new_password' : 'email'}
							onChange={onFormChange}
							value={userChangingPasswordEmail ? newPassword : email}
							error={userChangingPasswordEmail ? errors.password : errors.email}
							tooltipTitle={[...tooltipMatchedTitles, ...tooltipTitles]}
						/>
					</div>

					<div className={classes.wrapper}>
						<Input
							label={userChangingPasswordEmail ? 'confirm_new_password' : 'password'}
							type={showPassword ? 'text' : 'password'}
							name='password'
							htmlFor='password'
							onChange={onFormChange}
							value={password}
						/>
						<button
							type='button'
							onClick={onTogglePassword}
							className={classes.passwordEyeBtn}
						>
							<img
								src={showPassword ? eyeShown : eyeHidden}
								alt='password-eye'
								className={classes.passwordEye}
							/>
						</button>
					</div>

					{userError &&
						(userError.field === 'email' || userError.field === 'password') && (
							<p className={classes.error}>{t('invalid_credentials')}</p>
						)}
					{isChangePasswordScreen && !displayAcceptedPolicyTerm && (
						<div style={{ display: 'flex', alignItems: 'center', width: '50vw' }}>
							<Checkbox
								checked={hasAcceptedPolicyTerm}
								onChange={e => {
									setHasAcceptedPolicyTerm(e.target.checked)
								}}
							/>
							{lang === 'EN' && termsInEnglish}
							{lang === 'HE' && termsInHebrew}
							<div className={classes.langs}>
								<p onClick={() => handleChooseLang('HE')}>HE</p>
								<p onClick={() => handleChooseLang('EN')}>EN</p>
							</div>
						</div>
					)}

					<div className={classes.wrapper}>
						{userLoading ? (
							<Loader height='16vh' />
						) : (
							<>
								{userChangingPasswordEmail && (
									<button
										className={classes.button}
										type='submit'
										disabled={
											(!displayAcceptedPolicyTerm && !hasAcceptedPolicyTerm) ||
											!validPassword ||
											password !== newPassword
										}
										autoFocus
									>
										{t(userChangingPasswordEmail ? 'create_password' : 'login')}
									</button>
								)}
								{!userChangingPasswordEmail && (
									<button
										className={classes.button}
										type='submit'
										disabled={isValid}
										autoFocus
									>
										{t(userChangingPasswordEmail ? 'create_password' : 'login')}
									</button>
								)}

								{/* {isChangePasswordScreen && (
									<div className={classes.passwordRules}>
										{t('password_instructions')}
									</div>
								)} */}
								<div className={classes.cantLoginWrapper}></div>
							</>
						)}
					</div>
				</form>
			) : (
				<ForgotPassword backToLogin={() => setForgotFormIsShown(false)} />
			)}
			{!userChangingPasswordEmail && (
				<div className={classes.cantLoginWrapper}>
					<Button
						name='forgotPasswordBtn'
						onClick={() => setForgotFormIsShown(prev => !prev)}
					>
						{t(!forgotFormIsShown ? 'forgot_password' : 'Go back to login')}
					</Button>
				</div>
			)}
			<ConfirmModalSB
				text={t(`${serverError}`.trim())}
				isOpen={!!serverError}
				closeButtonText={t('okay')}
				onClose={() => dispatch(userActions.clearUserError())}
			/>
		</div>
	)
}

export default LoginForm
