import { ChangeEvent, FC, useContext, useEffect, useRef, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { TextareaAutosize } from '@material-ui/core'
import classes from './styles.module.css'

import dayjs from 'dayjs'
import { AppDirectionContext } from 'context/languages-context'

type Props = {
	onDescriptionChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
	handleKeyDown: (event: KeyboardEvent<HTMLTextAreaElement>) => void
	renderCheckIcon: JSX.Element
	dueDate: Date | undefined
	isEditMode: boolean
	currentTask: any
	renderCalendarIcon: JSX.Element
}

const TodoTextArea: FC<Props> = ({
	renderCheckIcon,
	dueDate,
	currentTask,
	isEditMode,
	renderCalendarIcon,
	handleKeyDown,
	onDescriptionChange,
}) => {
	const { t } = useTranslation()
	const inputRef = useRef<HTMLTextAreaElement>(null)

	useEffect(() => {
		if (inputRef.current) inputRef.current.selectionStart = inputRef.current.value.length
	}, [])

	const { isRtlDirection } = useContext(AppDirectionContext)
	const maxLength = 250
	const containerInputClass = isRtlDirection ? `${classes.action} ${classes.rtl}` : classes.action

	const ConvertedDateFormat = dayjs(dueDate).format('DD/MM/YYYY')
	const displayDueDate = dueDate ? `${t('task_due_date')} ${ConvertedDateFormat}` : t('on_going_task')

	return (
		<>
			<div className={containerInputClass}>

				{renderCalendarIcon}

				<TextareaAutosize
					onKeyDown={handleKeyDown}
					ref={inputRef}
					onChange={e => onDescriptionChange(e)}
					className={classes.actionInput}
					value={currentTask}
					autoFocus
					maxLength={maxLength}
					placeholder={t('action_plan_input_placeholder')}
					aria-label='action'
				/>

				<span className={!isRtlDirection ? classes.userIcon : ''}>
					{isEditMode && renderCheckIcon}
				</span>
			</div>

			<div className={!isRtlDirection ? classes.dueDate : classes.rtlDueDate}>
				{displayDueDate}
			</div>

		</>
	)
}
export default TodoTextArea