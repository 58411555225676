import {
	useMemo,
	useState,
	ChangeEvent,
	KeyboardEvent,
	useEffect,
	useContext,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useStyles from './styled'

import {
	delete_matchpoint_lesson,
	update_analysis_answer,
} from 'redux/debrief/debrief-actions'

import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'
// import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal'
import LessonRow from './LessonRow/index'

import colors from 'utils/colors'

import { ILessonRow, Summary } from 'interface/debrief/debrief-interface'
import IUser from 'interface/user/user-interface'
import { BotAnswerAnalysis } from 'enums/debriefing/debriefings-analysis.enum'

import close from 'assets/closeIcon.svg'
import { AppDirectionContext } from 'context/languages-context'
import { debriefOnProccesSelector } from 'redux/debrief/debrief-selector'
import { userSelector } from 'redux/user/user-selector'
import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'

export type TableCardHeader = {
	mp_id: string
	num: number
	renderTitle: string
	isLeader: boolean
	summaries: Summary[]
	sharedEmployess?: IUser[]
}
type Props = {
	updateMatchpointDetails: (
		_id: string,
		value: string,
		e: KeyboardEvent<HTMLInputElement>
	) => void
	onDelete?: (mp_id: string) => void
	event: TableCardHeader
	creator: IUser
	participants?: IUser[]
}
const MatchpointWrapper = (props: Props) => {
	const { event, creator, participants, updateMatchpointDetails, onDelete } =
		props
	const {
		renderTitle,
		num,
		isLeader,
		summaries,
		mp_id,
		sharedEmployess = [],
	} = event

	const [displayEmptyLesson, setDisplayEmptyLesson] = useState<boolean>(false)
	const [mpDescription, setMPDescription] = useState<string>(renderTitle ?? '')
	const [showInputField, setShowInputField] = useState<boolean>(false)
	const [acceptDeleteMP, setAcceptDeleteMP] = useState<boolean>(false)

	const { isRtlDirection } = useContext(AppDirectionContext)

	const debrief = useSelector(debriefOnProccesSelector)
	const { _id: userId } = useSelector(userSelector)

	useEffect(() => {
		if (!renderTitle) return setShowInputField(true)
	}, [renderTitle])

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const windowWidth = useWindowWidth()
	const smallMobildWidth = 844
	const mediumMobildWidth = 896
	const midLargMobileWidth = 915
	const isMobile =
		smallMobildWidth >= windowWidth ||
		mediumMobildWidth >= windowWidth ||
		midLargMobileWidth >= windowWidth

	const matchPointColor = {
		[1]: colors.saydoGreen,
		[2]: colors.boldPurple,
		[3]: colors.boldBlue,
	}[num] as string
	const classes = useStyles({ matchPointColor, isRtlDirection })

	const handleDeleteLesson = (lesson_id: string) => {
		const payload = {
			company_id: creator.company_id,
			lesson_id,
			event_id: mp_id,
			summary_id: mp_id,
		}
		dispatch(delete_matchpoint_lesson(payload))
	}
	const handleUpdateBotAnswers = (
		answer: string,
		section: BotAnswerAnalysis
	) => {
		const payload = {
			company_id: creator.company_id,
			event_id: mp_id,
			[section]: answer,
		}
		dispatch(update_analysis_answer(payload))
	}

	const saveNewMPDescription = ({
		target: { value },
	}: ChangeEvent<HTMLInputElement>) => {
		setMPDescription(value)
	}
	const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		hideInputOnEscapeClicked(e.key)
		updateMatchpointDetails(mp_id, mpDescription, e)
	}
	const hideInputOnEscapeClicked = (key: string) =>
		key === 'Escape' && setShowInputField(false)

	const toggleConfirmDeleteModal = () => setAcceptDeleteMP(prev => !prev)

	const summedLessons = useMemo(() => {
		const _lessons: Array<ILessonRow> = []

		summaries.forEach((summary: Summary) => {
			const summaryLessons = summary.lessons.map(lesson => ({
				...lesson,
				lessonOwnerId: summary.lessonsOwner_id,
				isLeader: isLeader,
				summary,
				event_id: mp_id,
				sharedEmployess: sharedEmployess,
				members_id: lesson.members_id,
			}))
			_lessons.push(...summaryLessons)
		})
		return _lessons
	}, [summaries])

	const onDoubleClickOnDescription = () =>
		isLeader && setShowInputField(prev => true)
	const toggleShowEmptyLesson = () => setDisplayEmptyLesson(prev => !prev)

	const inputField = (
		<input
			type='text'
			autoFocus
			placeholder={t('description')}
			value={mpDescription}
			onChange={saveNewMPDescription}
			onKeyDown={onKeyDown}
			className={classes.mpInputField}
		/>
	)
	const closeIcon = (
		<div>
			<img
				src={close}
				className={classes.closeBtn}
				onClick={toggleConfirmDeleteModal}
			/>
		</div>
	)

	const debriefIsClosed = debrief?.step === DebriefingSteps.FINISH

	const hadleSwitchInputTitle =
		showInputField && !debriefIsClosed ? inputField : renderTitle

	const shouldViewAnalysis = (lessonOwnerId: string = '') => {
		return (
			(!debriefIsClosed && isLeader) ||
			(debriefIsClosed && lessonOwnerId === userId)
		)
	}
	const allowEditAccess = isLeader && !debriefIsClosed
	return (
		<>
			<div className={classes.matchpointWrapper}>
				{isLeader && !debriefIsClosed && closeIcon}
				<div className={classes.matchpointTitles}>
					<div
						className={classes.mpDescription}
						onDoubleClick={onDoubleClickOnDescription}
					>
						<p>
							<span>
								{t('mp')}
								{num}:{' '}
							</span>
							{hadleSwitchInputTitle}
						</p>
					</div>
					{!isMobile && (
						<>
							<div>
								<span>{t('owner')}</span>
							</div>
							<div>
								<span>{t('task_due_date')}</span>
							</div>
						</>
					)}
				</div>

				{summedLessons.map((lesson: ILessonRow) => (
					<LessonRow
						matchPointColor={matchPointColor}
						allowEditAccess={allowEditAccess}
						creator={creator}
						lesson={lesson}
						toggleShowEmptyLesson={toggleShowEmptyLesson}
						participants={participants}
						onDelete={handleDeleteLesson}
						mp_id={mp_id}
						updateAnalysis={handleUpdateBotAnswers}
						shouldViewAnalysis={shouldViewAnalysis(lesson.lessonOwnerId)}
					/>
				))}

				{isLeader && !debriefIsClosed && (
					<div className={classes.addNewLesson} onClick={toggleShowEmptyLesson}>
						{t('lesson')} +
					</div>
				)}

				{displayEmptyLesson && isLeader && (
					<LessonRow
						mp_id={mp_id}
						matchPointColor={matchPointColor}
						allowEditAccess={allowEditAccess}
						creator={creator}
						toggleShowEmptyLesson={toggleShowEmptyLesson}
					/>
				)}
			</div>

			<ConfirmModalSB
				isOpen={acceptDeleteMP}
				onConfirm={() => onDelete!(mp_id)}
				onClose={toggleConfirmDeleteModal}
				closeButtonText={t('no')}
				confirmButtonText={t('yes')}
				text={t('delete_mp')}
			/>
		</>
	)
}
export default MatchpointWrapper
