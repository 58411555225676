import {
	FC,
	useMemo,
	useState,
	KeyboardEvent,
	useContext,
	useRef,
	useEffect,
} from 'react'
import { useSelector } from 'react-redux'

import classes from './styles.module.css'

import { createDebriefEvent } from 'utils/createDebriefEventFun'

import { debriefOnProccesSelector } from 'redux/debrief/debrief-selector'
import MatchpointWrapper from './MatchpointWrapper'
import useManageMatchpoints from './useManageMatchpoints'

import IUser from 'interface/user/user-interface'
import { DebriefEvent, Debrief } from 'interface/debrief/debrief-interface'
import { userSelector } from 'redux/user/user-selector'
import { useMatchPointsList } from 'utils/useMatchPointsList'
import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import { AppDirectionContext } from 'context/languages-context'
import { makeStyles } from '@material-ui/core'
import { Theme } from '@mui/material'

const SUMMERY_TABLE_CONTAINER_CLASS = 'summeryTable-container'
const MAX_MATCHPOINTS = 2

type Props = {
	isLeader: boolean
	users?: IUser[]
}
export type UserMapUsers = Record<string, string>
const SummeryTable: FC<Props> = props => {
	const [showEmptyMP, setShowEmptyMP] = useState(false)

	const { users = [], isLeader } = props

	const { isRtlDirection } = useContext(AppDirectionContext)

	const debrief: Debrief | null = useSelector(debriefOnProccesSelector)
	const user = useSelector(userSelector)

	const innerStyle = useStyles({ isRtlDirection })
	const summeryTableContainerRef = useRef<HTMLDivElement | null>(null)
	const matchpoints = useMatchPointsList()
	const { createNewMP, updateExistingMP, deleteMP } = useManageMatchpoints()

	useEffect(() => {
		if (!summeryTableContainerRef?.current) return

		summeryTableContainerRef.current.scrollTo({
			behavior: 'smooth',
			top: summeryTableContainerRef.current.scrollHeight,
		})
	}, [])

	const userMapUsers = useMemo(() => {
		// TODO: test this
		let userMap: UserMapUsers = {}
		for (let { _id, personalInfo } of users) {
			const { firstName } = personalInfo

			if (_id in userMap) continue

			userMap = {
				...userMap,
				[_id]: firstName,
			}
		}
		return userMap
	}, [users.length])

	const handleAddNewMPTItlt = () => setShowEmptyMP(prev => true)

	const saveNewUpdates = (
		_id: string,
		value: string,
		e: KeyboardEvent<HTMLInputElement>
	) => {
		if (!isEnterKeyDown(value, e)) return
		const eventDetails = createDebriefEvent(value)

		if (!_id) return createNewMP(eventDetails)
		return updateExistingMP(eventDetails, _id)
	}

	const isEnterKeyDown = (
		value: string,
		{ key }: KeyboardEvent<HTMLInputElement>
	) => {
		if (key === 'Escape') return setShowEmptyMP(false)
		else if (key !== 'Enter' || !value.trim()) return
		return true
	}

	const hasLessThanMaxMatchPoints = matchpoints.length - 1 < MAX_MATCHPOINTS
	const canAddMP =
		hasLessThanMaxMatchPoints &&
		isLeader &&
		debrief?.step !== DebriefingSteps.FINISH

	const getMPData = ({
		_id = '',
		description = '',
		index = 0,
		summaries = [] as any[],
	}) => ({
		num: index + 1,
		isLeader,
		renderTitle: description,
		sharedEmployess: debrief?.sharedEmployess,
		summaries,
		mp_id: _id,
	})

	return (
		<div
			className={classes[SUMMERY_TABLE_CONTAINER_CLASS]}
			ref={summeryTableContainerRef}
		>
			{matchpoints.map(
				({ _id, description, summaries = [] }: DebriefEvent, index) => (
					<MatchpointWrapper
						updateMatchpointDetails={saveNewUpdates}
						onDelete={deleteMP}
						key={index}
						event={getMPData({ _id, description, summaries, index })}
						creator={debrief?.creator!}
						participants={debrief?.participants!}
					/>
				)
			)}

			{showEmptyMP && (
				<MatchpointWrapper
					updateMatchpointDetails={saveNewUpdates}
					event={{
						mp_id: '',
						num: matchpoints.length + 1,
						renderTitle: '',
						isLeader: false,
						summaries: [],
					}}
					creator={user}
					participants={debrief?.participants!}
				/>
			)}

			{canAddMP && !showEmptyMP && (
				<div onClick={handleAddNewMPTItlt} className={innerStyle.addMP}>
					MP+
				</div>
			)}

		</div>
	)
}

export default SummeryTable
type Styles = {
	isRtlDirection: boolean
}
const useStyles = makeStyles<Theme, Styles>(({ breakpoints }) => ({
	addMP: {
		cursor: 'pointer',
		position: 'relative',
		marginTop: 'none',
		left: ({ isRtlDirection }) => (isRtlDirection ? 0 : '20%'),
		right: ({ isRtlDirection }) => (isRtlDirection ? '20%' : 0),
		[breakpoints.down('xs')]: {
			marginTop: '4%',
			left: ({ isRtlDirection }) => (isRtlDirection ? 0 : '.5%'),
			right: ({ isRtlDirection }) => (isRtlDirection ? '.5%' : 0),
		},
		[breakpoints.down('md')]: {
			right: ({ isRtlDirection }) => (isRtlDirection ? '7.5%' : 0),
			left: ({ isRtlDirection }) => (isRtlDirection ? 0 : '7.5%'),
		},
	},
}))
