import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './style.css'

import { userActions } from 'redux/user/user-reducer'
import { lessonCopiedSelector, userSelector } from 'redux/user/user-selector'
import { employeesSelector } from 'redux/employee/employee.selector'

import { getModalHeaders } from 'utils/ModalLessonsManagementLabels'
import useLMRequests from './useLMRequests'

import useItemsSection from './useItemsSection'
import useSelectedFolder from './useSelectedFolder'
import initPermissionsList from '../utils/initPermissionsSetList'
import initModalInfo from '../utils/initModalInfo'
import { getHRDepartment } from 'utils/getHRdepartment'
import ModalContent from './ModalContent'

import {
	ModalButtonLabel,
	ModalOperations,
	UI,
} from 'enums/lessonsManagement/index'
import {
	Folder,
	File,
	ILessonsBase,
	BaseFolderChild,
} from 'interface/lessons-management/LessonsBase.interface'
import usePrivateSection from '../item/usePrivateSection'
import { ModalInfoTyes } from '../props'

type Props = {
	currentFolder?: ILessonsBase<Folder | File> | null
}
const useModalContent = ({ currentFolder }: Props) => {
	const [openModal, setOpenModal] = useState(false)
	const [modalInfo, setModalInfo] = useState<ModalInfoTyes>(initModalInfo())
	const [userInput, setUserInput] = useState('')
	const [showEmptyLesson, setShowEmptyLesson] = useState(false)
	const [permissionsSetList, setPermissionsSetList] = useState<Set<string>>(new Set())

	const dispatch = useDispatch()

	const {
		getTitleIdOfLesson,
		handleTitleSelected,
		selectedSection,
		resetSelectedSection,
		titlesOptions,
		selectedEmailType,
		//handleEmailType
	} = useItemsSection({ currentFolder, setShowEmptyLesson })

	const { getSelectedFolder, selectedFolder, setSelectedFolder } = useSelectedFolder({ currentFolder })
	const { isPrivate, resetPriavte } = usePrivateSection({ permissions: currentFolder?.permissions })

	useEffect(() => {
		if (!currentFolder?.permissions?.length) return

		else assignedPermissionsList()
	}, [currentFolder?.permissions])

	const assignedPermissionsList = () => {
		setPermissionsSetList(initPermissionsList(currentFolder?.permissions!))
	}

	const copiedLessonId = useSelector(lessonCopiedSelector)
	const employees = useSelector(employeesSelector)
	const { _id: user_id, company_id } = useSelector(userSelector)

	const handleClosePopup = () => setOpenModal(prev => !prev)
	const openPopup = () => setOpenModal(prev => true)

	const toggleEmptyLesson = () => {
		if (abortPasteLesson()) dispatch(userActions.lessonIsCopied(''))

		setShowEmptyLesson(prev => !prev)
	}
	const abortPasteLesson = (): boolean => {
		const someLessonCopied = Boolean(copiedLessonId.trim())
		return someLessonCopied && !showEmptyLesson
	}

	const {
		createFile,
		createFolder,
		createTitle,
		deleteFolder,
		editFolder,
		editFile,
		deleteFile,
		insertLesson,
		deleteFileLesson,
		updateTitle,
		getUpdatedTitle,
		updateLesson,
		errorWhileRequest
	} = useLMRequests({
		userInput,
		selectedFolder,
		currentFolder,
		permissionsSetList,
		selectedSection,
		getTitleIdOfLesson,
		openPopup
	})

	/* 
	*  All following functions, till 'onAcceptModal' function,
	*  are event listeners to user clicks the buttons 
	*  on <Stickybar> or <Main> and prepare the Modal info.	    
	*/
	const handleDeleteFolder = (_id?: string | null) => {
		if (isHighLevelFolder() && !isHumanResource()) return

		else {
			const selectedFolder = getSelectedFolder(_id)
			const isFolderDeleting = selectedFolder?.ui === UI.FOLDER

			if (isFolderDeleting) setDeleteFolderProperties(selectedFolder!)

			else setDeleteFileProperties(selectedFolder!)
		}
	}

	const handleEditFolder = (_id?: string | null) => {
		if (isHighLevelFolder() && !isHumanResource()) return

		setEditFlowProperties(getSelectedFolder(_id))
	}

	const setDeleteFolderProperties = (folder: BaseFolderChild) => {
		openPopup()
		setModalInfo({
			...getModalHeaders(ModalOperations.DELETE_FOLDER, '', `"${folder.name}"`),
			operation: ModalOperations.DELETE_FOLDER,
			modalBtnLabel: ModalButtonLabel.Delete,
		})
	}

	const setDeleteFileProperties = (file: BaseFolderChild) => {
		openPopup()
		setModalInfo({
			...getModalHeaders(ModalOperations.DELETE_FILE, '', `"${file.name}"`),
			operation: ModalOperations.DELETE_FILE,
			modalBtnLabel: ModalButtonLabel.Delete,
		})
	}

	const handleDeleteLesson = (_id: string) => {
		openPopup()
		setModalInfo({
			name: [{ label: '', placeholder: '' }],
			title: '',
			operation: ModalOperations.DELETE_LESSON,
			modalBtnLabel: ModalButtonLabel.Delete,
		})
		setSelectedFolder({ _id })
	}

	const setEditFlowProperties = (folder?: BaseFolderChild) => {
		const { name, operation } = setupAddionalProperties(folder)
		openPopup()
		setModalInfo({
			...getModalHeaders(operation, name, ''),
			operation: operation,
			modalBtnLabel: ModalButtonLabel.Save,
		})
	}

	const handleCreateFolder = () => {
		openPopup()
		setModalInfo({
			...getModalHeaders(ModalOperations.NEW_FOLDER),
			operation: ModalOperations.NEW_FOLDER,
			modalBtnLabel: ModalButtonLabel.Create,
		})
	}

	const handleCreateFile = () => {
		openPopup()
		setModalInfo({
			...getModalHeaders(ModalOperations.NEW_FILE),
			operation: ModalOperations.NEW_FILE,
			modalBtnLabel: ModalButtonLabel.Create,
		})
	}

	const handleCreateTitle = () => {
		openPopup()
		setModalInfo({
			...getModalHeaders(ModalOperations.NEW_TITLE),
			operation: ModalOperations.NEW_TITLE,
			modalBtnLabel: ModalButtonLabel.Create,
		})
	}

	const insertNewLesson = () => {
		const operation = copiedLessonId.trim() ? ModalOperations.ATTACHED : ModalOperations.NEW_LESSON
		openPopup()
		setModalInfo({
			...getModalHeaders(operation),
			operation,
			modalBtnLabel: ModalButtonLabel.Create,
		})
	}

	const isHighLevelFolder = (): boolean => {
		return Boolean(currentFolder?._id === company_id)
	}
	const isHumanResource = (): boolean => {
		const hrDepartment = getHRDepartment(employees).ids
		return Boolean(hrDepartment.includes(user_id))
	}

	const setupAddionalProperties = (folder?: BaseFolderChild) => {
		let name = ''
		let operation = ModalOperations.EDIT_FOLDER

		if (folder) {
			name = folder.name
			setSelectedFolder(prev => folder)
			setPermissionsSetList(initPermissionsList(folder.permissions))

			if (folder?.ui === UI.FILE) operation = ModalOperations.EDIT_FILE
		} else {
			name = currentFolder?.name!
			setPermissionsSetList(initPermissionsList(currentFolder?.permissions!))
		}

		return { name, operation }
	}

	const handleValue = (value: string) => setUserInput(value/*.toLowerCase()*/)

	const onAcceptModal = () => {
		if (modalInfo.operation === ModalOperations.NEW_FOLDER) createFolder()
		if (modalInfo.operation === ModalOperations.NEW_FILE) createFile()
		if (modalInfo.operation === ModalOperations.EDIT_FOLDER) editFolder()
		if (modalInfo.operation === ModalOperations.EDIT_FILE) editFile()
		if (modalInfo.operation === ModalOperations.NEW_LESSON) insertLesson()
		if (modalInfo.operation === ModalOperations.ATTACHED) insertLesson()
		if (modalInfo.operation === ModalOperations.NEW_TITLE) createTitle()
		if (modalInfo.operation === ModalOperations.DELETE_FOLDER) deleteFolder()
		if (modalInfo.operation === ModalOperations.DELETE_FILE) deleteFile()
		if (modalInfo.operation === ModalOperations.DELETE_LESSON) deleteFileLesson(selectedFolder?._id!)

		resetState()
	}

	const resetState = () => {
		setPermissionsSetList(new Set())
		setUserInput('')
		setSelectedFolder(undefined)
		resetSelectedSection()
		resetPriavte()
	}

	const allowConfirmAction = useMemo(() => {
		const userCompareFolderName = selectedFolder?.name?.trim() === userInput.trim()
		const { title } = getUpdatedTitle()

		if (modalInfo.operation === ModalOperations.DELETE_FOLDER) return userCompareFolderName
		if (modalInfo.operation === ModalOperations.NEW_LESSON) return !title ? false : true

		else return undefined
	}, [selectedFolder?.name, userInput, modalInfo.operation, selectedSection])

	const modalContent = !errorWhileRequest ? (<ModalContent
		handleSectionSelected={handleTitleSelected}
		selectedSection={selectedSection?.title_id}
		modalInfo={modalInfo}
		permissionsSetList={permissionsSetList}
		setPermissionsSetList={setPermissionsSetList}
		handleValue={handleValue}
		closeModal={handleClosePopup}
		isPrivate={isPrivate}
		titles={titlesOptions}
	/>) : <p>{errorWhileRequest}</p>

	return {
		content: modalContent,
		isAcceptConfirm: allowConfirmAction,
		modalBtnLabel: modalInfo.modalBtnLabel,
		showEmptyLesson,
		openModal,
		handleCreateFolder,
		handleValue,
		handleCreateFile,
		handleClosePopup,
		handleEditFolder,
		handleCreateTitle,
		onAcceptModal,
		toggleEmptyLesson,
		insertNewLesson,
		handleDeleteFolder,
		updateTitle,
		updateLesson,
		handleDeleteLesson
	}
}

export default useModalContent