import { FC, useState } from 'react'
import { Button, styled } from '@material-ui/core'

import StepsBar from 'components/HRsettings/StepsBar'
import { CreateForm } from 'components/SuperAdmin/OutputSettings/CreateOutputForm'
import { OutputList } from 'components/SuperAdmin/OutputSettings/OutputsList'
import { UseLogic } from './UseLogic'
import SearchRectangle from 'components/common/SearchRectangle'
import { Loader } from '../../components/common/Loader/index'
import { RenderCreateFurtherImpactForm } from './RenderFurtherImpactForm'

export enum Tab {
	praise,
	feedback,
}

export const OutputSetting: FC<{}> = () => {
	const [tabState, setTabState] = useState(Tab.praise)
	const [inputText, setInputText] = useState('')

	const {
		newOutput,
		loading,
		outputs,
		handleCreateOutput,
		handleFormChange,
		fetchDate,
	} = UseLogic({ tabState })

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setInputText(e.target.value)

	const fillteredList = outputs.filter(({ title }) => title.match(inputText))

	const RenderCreateOutputForm = (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
			}}
		>
			<CreateForm onChange={handleFormChange} values={newOutput} />

			<Button
				color={'secondary'}
				onClick={handleCreateOutput}
				type='submit'
				variant='contained'
			>
				Create
			</Button>
		</div>
	)

	const RenderSearch = (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				margin: '4% 0 2%',
			}}
		>
			<div style={{ width: '30vw' }}>
				<SearchRectangle placeholder='search' handleChange={handleChange} />
			</div>
			<span style={{ marginTop: '15px' }}>{fillteredList.length} results</span>
		</div>
	)
	const RenderOutputsList = (
		<Wrapper>
			{loading ? (
				<Loader />
			) : (
				<OutputList
					tabState={tabState}
					fetchDate={fetchDate}
					outputs={fillteredList}
				/>
			)}
		</Wrapper>
	)

	return (
		<>
			<StepsBar
				barWidth='25vw'
				tabWidth='50%'
				stepsData={[
					{ label: 'Praises Output', value: Tab.praise },
					{ label: 'Feedbacks Output', value: Tab.feedback },
				]}
				onStepChange={value => setTabState(value)}
				currentStep={tabState}
			/>
			{RenderSearch}
			{RenderCreateOutputForm}
			{RenderOutputsList}

			<RenderCreateFurtherImpactForm />
		</>
	)
}
const Wrapper = styled('div')({
	marginBottom: '10%',
	padding: '15px 0',
	width: '100%',
	height: '35vh',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'center',
	color: '#46484a',
	overflowY: 'auto',
})
