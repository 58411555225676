import React, { FC, useContext, Dispatch, ChangeEvent } from 'react'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'

import classes from './styles.module.css'

import deleteIcon from 'assets/deleteIcon.svg'
import editIcon from 'assets/editIcon.svg'

import TodoInputBox from './TodoInputBox'
import { CloseIcon } from 'components/common/CloseIcon'
import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'

import { TodoState, TodoStatusOptions } from 'enums/todos/todos.enum'
import { TasksAction, Task } from 'interface/todos/todos-interface'
import IUser from 'interface/user/user-interface'

import taskContainerIndicator from 'utils/taskContainerIndicator'
import { AppDirectionContext } from 'context/languages-context'
import useEditMode from './useEditMode'
import useTodoReuqests from './useTodos'
import TodoTimes from './TodoTimes'
import TodoButtons from './TodoButtons'

type Props = {
	onDueDateSelected: (day: Date) => void
	onDeleteTask: (todo_id: string) => void
	onOpenCalendar: () => void
	resetCalendarState: () => void
	setTodosChunkNumber: Dispatch<React.SetStateAction<number>>
	resetDueDate: () => void
	isManagerViewing: boolean
	managerSide: boolean
	dueDate: Date | undefined
	todo: Task
	selectedEmployee: IUser
	userTodos: TasksAction
	isManagerAction?: boolean
}
const PresentTask: FC<Props> = ({
	todo,
	selectedEmployee,
	userTodos,
	managerSide,
	dueDate,
	isManagerViewing,
	onDeleteTask,
	onDueDateSelected,
	onOpenCalendar,
	resetCalendarState,
	setTodosChunkNumber,
	resetDueDate,
}) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const taskIndicatorStyle = taskContainerIndicator(todo)

	const { state, createdAt, dueDate: taskDate, description, _id: todo_id, status = TodoStatusOptions.NOT_STARTED } = todo

	const {
		isEditMode,
		onEditTask,
		resetEditMode,
		taskToUpdate,
		setTaskToUpdate,
		toggleShowingEditMode,
	} = useEditMode()

	const { restoreTask, changeTaskStatus } = useTodoReuqests(selectedEmployee)

	const windowWidth = useWindowWidth()
	const mobileWidth = 600
	const isMobile = mobileWidth >= windowWidth

	const rtlAtMobileDevice = isRtlDirection && isMobile

	const restoreDeletedTask = () => restoreTask(todo_id)

	const updateStatus = ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
		const newStatus = value as TodoStatusOptions
		changeTaskStatus(newStatus, todo_id)
	}

	const controlButtons = (
		<div
			className={
				rtlAtMobileDevice ? classes.rtlMobileTaskIcons : classes.taskIcons
			}
		>
			<img
				className={classes.icon}
				src={deleteIcon}
				alt='delete icon'
				onClick={() => onDeleteTask(todo_id)}
			/>
			<span></span>
			<img
				className={classes.icon}
				src={editIcon}
				alt='edit icon'
				onClick={() => onEditTask(todo_id)}
			/>
		</div>
	)

	const restoreButton = (
		<div
			className={
				rtlAtMobileDevice ? classes.rtlMobileTaskIcons : classes.restoreSection
			}
		>
			<SettingsBackupRestoreIcon
				onClick={restoreDeletedTask}
				className={classes.restoreButton}
			/>
		</div>
	)

	const props = {
		dueDate,
		selectedEmployee,
		userTodos,
		taskToUpdate,
		isManagerViewing,
		setTodosChunkNumber,
		onDueDateSelected,
		setTaskToUpdate,
		resetCalendarState,
		resetDueDate,
	}
	return (
		<>
			{!isEditMode ? (
				<div
					style={{ ...taskIndicatorStyle }}
					className={
						rtlAtMobileDevice ? classes.rltMobilePresentTask : classes.presentTask
					}
				>
					<TodoButtons updateStatus={updateStatus} status={status}>
						{state !== TodoState.deleted ? controlButtons : restoreButton}
					</TodoButtons>

					<div
						className={
							rtlAtMobileDevice ? classes.rtlMobileContainer : classes.container
						}
					>
						<div
							className={
								rtlAtMobileDevice ? classes.rtlMobileDescription
									: classes.taskDescription
							}
						>
							{
								description.split('\n').map((line, index) => (
									<div key={index}>{line}</div>
								))
							}
						</div>

					</div>

					<TodoTimes createdAt={createdAt} dueDate={taskDate} />

				</div>

			) : (
				<>
					<CloseIcon
						width='30px'
						height='30px'
						className={classes.editMode}
						handleClick={resetEditMode}
					/>
					<TodoInputBox
						managerSide={managerSide}
						openCalendar={onOpenCalendar}
						isEditMode={isEditMode}
						toggleShowingEditMode={toggleShowingEditMode}
						{...props}
					/>
				</>
			)}
		</>
	)
}
export default PresentTask