import { steps_list } from './stepMapComponent'

const noStep = {
	prevStep: undefined,
	nextStep: undefined,
}

const {
	CHOOSE_DEPARTMENT,
	CHOOSE_GOAL,
	CHOOSE_PATH,
	CHOOSE_SUB_DEPARTMENT,
	CHOOSE_VALUE,
	CHRONOLOGICAL_EVENTS,
	DEBRIEFING_SELF_ANALISYS_CHAT,
	EXPECTED_RESULT,
	FINISH,
	GROUP_PROCESS_MESSAGE,
	INITIAL_MESSAGE,
	LEARNING_PROCESS_OUTPUT,
	MATCHPOINT_CHOICE,
	MATCHPOINT_CONFIRMATION,
	PARTICIPANTS_CHOICE,
	RECONSTRUCTION_MESSAGE,
	SELF_ANALISYS_MESSAGE,
	SUMMARY,
	WRITE_DEPARTMENT,
	WRITE_SUB_DEPARTMENT,
} = steps_list

export const steps = {
	[PARTICIPANTS_CHOICE]: {
		prevStep: CHOOSE_PATH,
		nextStep: GROUP_PROCESS_MESSAGE,
	},
	[EXPECTED_RESULT]: {
		prevStep: CHOOSE_PATH,
		nextStep: RECONSTRUCTION_MESSAGE,
	},
	[CHOOSE_GOAL]: {
		prevStep: SELF_ANALISYS_MESSAGE,
		nextStep: LEARNING_PROCESS_OUTPUT,
	},
	[CHOOSE_VALUE]: {
		prevStep: LEARNING_PROCESS_OUTPUT,
		nextStep: SUMMARY,
	},
	/*[CHOOSE_DEPARTMENT]: {
		prevStep: SELF_ANALISYS_MESSAGE,
		nextStep: LEARNING_PROCESS_OUTPUT,
	},*/
	[CHRONOLOGICAL_EVENTS]: {
		prevStep: EXPECTED_RESULT,
		nextStep: MATCHPOINT_CHOICE,
	},
	[MATCHPOINT_CHOICE]: {
		prevStep: CHRONOLOGICAL_EVENTS,
		nextStep: SELF_ANALISYS_MESSAGE,
	},
	[LEARNING_PROCESS_OUTPUT]: {
		prevStep: MATCHPOINT_CHOICE,
		nextStep: SUMMARY,
	},
	[SUMMARY]: {
		prevStep: MATCHPOINT_CHOICE,
		nextStep: FINISH,
	},
	// steps that will not display button.
	[FINISH]: noStep,
	[CHOOSE_PATH]: noStep,
	[INITIAL_MESSAGE]: noStep,
	[GROUP_PROCESS_MESSAGE]: noStep,
	[CHOOSE_SUB_DEPARTMENT]: noStep,
	[WRITE_DEPARTMENT]: noStep,
	[WRITE_SUB_DEPARTMENT]: noStep,
	[RECONSTRUCTION_MESSAGE]: noStep,
	[MATCHPOINT_CONFIRMATION]: noStep,
	[SELF_ANALISYS_MESSAGE]: noStep,
	[DEBRIEFING_SELF_ANALISYS_CHAT]: noStep,
}
