import ParticipantsChoice from 'components/Debriefing/ParticipantsChoice'
import { EMPTY_STATE } from '../useTreeLogic'

export const RenderParticipantsChoice = ({ ...props }) => {
	const { debrief, setNextData, setDisableNext } = props

	const pageIcon = {
		src: 'heartIcon',
		opacity: '',
	}
	const content = (
		<ParticipantsChoice
			shouldSendSms={debrief?.shouldSendSms}
			currentParticipants={debrief?.participants}
			setData={setNextData}
			setDisableNext={setDisableNext}
		/>
	)
	const nextButtonText = 'debrief.invite_group'

	return { ...EMPTY_STATE, content, pageIcon, nextButtonText }
}
