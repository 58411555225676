import { grey } from '@material-ui/core/colors'
import { makeStyles, Theme } from '@material-ui/core/styles'
interface StyleProps {
	isRtlDirection: boolean
	isManagersBox?: boolean
}
export default makeStyles<Theme, StyleProps>(({ palette }) => ({
	// '@global': {
	// 	userSelect: 'none',
	// 	'*::-webkit-scrollbar': {
	// 		width: '25px',
	// 		background: 'transparent',
	// 	},
	// 	'*::-webkit-scrollbar-track': {
	// 		background: 'rgb(0,0,0,0.0)',
	// 	},
	// 	'*::-webkit-scrollbar-thumb': {
	// 		border: '10.5px solid transparent',
	// 		backgroundClip: 'padding-box',
	// 		borderRadius: '9999px',
	// 		backgroundColor: 'rgb(0,113,194,0.7)',
	// 	},
	// },
	textField: {
		'& .MuiOutlinedInput-root': {
			boxShadow: 'none !important',
			outline: 'none !important',
			border: 'none !important',
		},
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			boxShadow: 'none !important',
			outline: 'none !important',
			border: 'none !important',
		},
		'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			boxShadow: 'none !important',
			outline: 'none !important',
			border: 'none !important',
		},
		'& .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline': {
			boxShadow: 'none !important',
			outline: 'none !important',
			border: 'none !important',
		},
	},
	f: {
		'& .MuiAutocomplete-clearIndicator': {
			display: 'none !important',
		},
		'& .MuiAutocomplete': {
			display: 'none !important',
			border: '1px solid red',
		},
	},

	mainContainer: {
		padding: '1%',
		userSelect: 'none',
		'& p': {
			textAlign: 'center',
			width: '50%',
			color: '#46484A',
			padding: '0',
			margin: '1% 0 1.7% 0',
		},
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'& h1': {
			color: '#46484A',
			margin: '1% 2% 0% 2%',
		},
	},
	searchBoxContainer: {
		margin: 'auto auto 2% auto',
		width: '60%',
	},
	cardContainer: {
		width: '30%',
	},
	cardContent: {
		maxHeight: '55vh',
		minHeight: '50vh',
		overflow: 'scroll',
		// width: '100%',
		// height: '100%',
		// overflow: 'overlay',
	},
	card: {
		borderRadius: '10px',
		// height: '65vh', //fixed height
		minHeight: '65vh', //height for scrolling
		maxHeight: '65vh',
		textAlign: 'center',
	},
	cardsContainer: {
		width: '100%',
		display: 'flex',

		justifyContent: 'center',
	},
	cardTitle: {
		display: 'flex',
		alignItems: 'center',
		height: '62px',
		color: 'white',
		fontSize: '140%',
		fontWight: 'bolder',
		backgroundColor: '#0071C2',
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
		'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: 'transparent',
		},
		'& .MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline': {
			outline: 'none',
		},

		'& $input': {
			backgroundColor: 'transparent !important',
			width: '75%',
			textAlign: 'center !important',
			border: 'none',
			color: 'white !important',
			fontWight: 'bolder !important',
			outline: 'none',
			fontSize: '90%',
			padding: '2% 0 0 0',
			margin: '0 0 0 5% !important',
		},
		'& $input::placeholder': {
			textAlign: 'center !important',
			color: 'white',
		},
		'& $input:focus::placeholder': {
			textAlign: ({ isRtlDirection }) =>
				isRtlDirection ? 'right !important' : 'left !important',
			opacity: '0.5 !important',
		},
		'& input:active::placeholder': {
			opacity: '0 !important',
		},

		'& $input:focus': {
			outline: 'none',
			textAlign: ({ isRtlDirection }) =>
				isRtlDirection ? 'right !important' : 'left !important',
		},

		'& $div': {
			'& input:active': {
				opacity: '0 !important',
			},
			'&:hover': {
				border: 'none',
				outline: 'none',
			},
			'&:focus': {
				border: 'none',
				outline: 'none !important',
			},
			'& $input': {
				height: '90% !important',
				padding: '4.5% 0 2% 0 !important',
				fontSize: '130% !important',
			},

			'& $label': {
				display: 'none',
			},
		},
	},

	row: {
		textAlign: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
		padding: '1% 1% 1% 2.5%',
	},
	duplicateExtraDetails: {
		fontSize: '75%',
	},
	blackLetters: {
		color: 'black',
	},
	buttonsContainer: {
		justifyContent: 'center',
		margin: '3%',
		width: '100%',
		display: 'flex',
	},
	actionButton: {
		margin: '0 2.5% 0 2.5%',
		padding: '0 1% 0 1%',
		minWidth: '9% !important',
		width: 'fit-content !important', // important just because  button component is not ready yet. in the fuce will get a width prop, not this a class name
	},
	popupIcon: {
		height: '100%',
		color: 'white'
	},
	navigationButtons: {
		width: '12%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		'& $button': {
			alignSelf: 'center',
			color: 'white',
			backgroundColor: '#0071C2',
		},
		'& $button:hover': {
			backgroundColor: '#004679',
		},
		'& $svg': {
			width: '80%',
			height: '80%',
		},
	},
	markedRow: {
		backgroundColor: '#F4F4F4',
	},
	disabeldRow: {
		color: '#c5c5c5',
	},

	icon: {
		'& svg': {
			height: '30px',
			width: '30px',
		},
		height: '50%',
		margin: '1.5% 1% 0 2%',
	},

	unassignedEmployees: {
		padding: '2%',
		width: '50%',
		height: '200px',
		margin: '2% auto 2% auto',
		backgroundColor: '#F4F4F4',
		overflow: 'overlay',
	},
	unassignedEmployee: {
		margin: '2% auto 2% auto',
	},
}))
