import { FC, ReactNode, useContext } from 'react'
import { AppDirectionContext } from 'context/languages-context'
import useStyles from './styled'

type ContainerProps = {
	height?: string | number
	children: JSX.Element | ReactNode
	scroll?: boolean
	tasksContainer?: boolean
	tasksSection?: boolean
	containerDescription: string
	hideChip?: boolean
	width?: string
	margin?: string
	handleClick?: () => void
}

const TasksContainer: FC<ContainerProps> = props => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const {
		hideChip,
		height,
		children,
		scroll,
		tasksContainer,
		tasksSection,
		containerDescription,
		width,
		margin,
		handleClick
	} = props

	const classes = useStyles({
		isRtlDirection,
		containerHeight: height,
		scroll: scroll!,
		tasksContainer: tasksContainer!,
		tasksSection: tasksSection!,
		width,
		margin
	})
	return (
		<>
			<div className={classes.container} onClick={handleClick}>
				{!hideChip && (
					<div>
						<span className={classes.containerDescription}>
							{containerDescription}
						</span>
					</div>
				)}
				{children}
			</div>
		</>
	)
}

export default TasksContainer
