import { useContext } from 'react'
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

import useStyles from './styled'

import { AppDirectionContext } from '../../context/languages-context'

import { ActivityUserAccess } from './useEmployeesActivities'
import { DisplayPermissionIndicator } from './DisplayPermissionIndicator'

export interface Main {
	isManager: boolean
	names: ActivityUserAccess[]
	color: string
	pagination: () => void
	handlingPaginationBack: () => void
}

const firstEmployee = 0
export const Main = (props: Main) => {
	const { isManager, names, color, pagination, handlingPaginationBack } = props

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ color, isRtlDirection })
	const { t } = useTranslation()

	const backArrow = (
		<span>
			<ArrowBackIos
				className={classes.icon}
				onClick={isRtlDirection ? pagination : handlingPaginationBack}
			/>
		</span>
	)
	const nextArrow = (
		<span>
			<ArrowForwardIos
				className={classes.icon}
				onClick={isRtlDirection ? handlingPaginationBack : pagination}
			/>
		</span>
	)

	return (
		<div className={classes.header}>
			<div>{t('employee_name')}</div>
			{names.map(({ name, _id, permissions }, index) => (
				<div key={_id}>
					{isRtlDirection && index === 0 && nextArrow}
					{!isRtlDirection && index === 0 && backArrow}
					<div className={classes.headerName}>
						{name}
						{index === firstEmployee && (
							<DisplayPermissionIndicator {...{ _id, permissions, isManager }} />
						)}
					</div>
					{isRtlDirection && index === names.length - 1 && backArrow}
					{!isRtlDirection && index === names.length - 1 && nextArrow}
				</div>
			))}
		</div>
	)
}
