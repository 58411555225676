import { makeStyles, Theme } from '@material-ui/core'
import colors from 'utils/colors'

type Styles = {
	isRtlDirection: boolean
	isUserCreatedForHimself?: boolean
}
export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
	container: {
		overflow: 'hidden auto',
		width: '100%',
		border: `2.5px solid lightgrey`,
		height: 'fit-content',
		minHeight: '500px',
		maxHeight: '600px',
		borderRadius: '15px',
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		'& >p': {
			textAlign: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
			fontSize: '11px',
			fontWeight: 'bold',
			margin: ({ isRtlDirection }) =>
				!isRtlDirection ? '1% 0 0 2%' : '1% 2% 0 0',
		}
	},
	membersList: {
		position: 'absolute',
		display: 'flex',
		right: ({ isRtlDirection }) => (isRtlDirection ? undefined : '2%'),
		left: ({ isRtlDirection }) => (isRtlDirection ? '2%' : undefined),
		bottom: 0,
		top: 0,
		border: `2.5px dotted ${colors.saydoGreen}`,
		width: '50px',
		height: '15px',
		borderBottomRightRadius: '8px',
		borderBottomLeftRadius: '8px',
		borderTop: 'none',
		transition: '.5s',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		overflow: 'hidden',
		'&:hover': {
			height: '150px',
			overflow: 'auto',
			border: `2.5px solid ${colors.saydoGreen}`,
			borderTop: 'none',
			transition: '1.5s',
			'& > div': {
				padding: '1% 0 1% 0',
				display: 'inline-block',
				textAlign: 'center',
				alignItems: 'center',
				width: '30px',
				height: '30px'
			}
		},
		'& > div': {
			display: 'none'
		},
	},
	tasksCounter: {
		margin: 0,
		width: 'fit-content',
		height: 'fit-content',
		fontSize: '12px',
		padding: '5px',
		fontFamily: 'Sora, send-serif',
		'& > ul > li': {
			margin: '.5px',
			'& >span:nth-child(1)': {
				fontWeight: 'bold',
				fontSize: '11px'
			},
			'& >span:nth-child(2)': {
				textDecoration: 'underline',
				cursor: 'pointer'
			}
		}
	},
	title: {
		width: '93%',
		height: '77px',
		padding: '8px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '25px',
		position: 'relative',
		left: '1px',
		top: '2%',
		color: colors.saydoGreen,

		'& >p': {
			width: 'fit-content',
			cursor: 'pointer',
			'&:hover': {
				border: `3px solid ${colors.saydoGreen}`,
				backgroundColor: colors.saydoGreen,
				color: 'white',
				borderRadius: '5px',
			}
		},
		'& >:last-child': {
			width: '21%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			position: 'relative',
			height: '62%',
			alignItems: 'flex-start',
			right: ({ isRtlDirection }) => !isRtlDirection && '4%',
			left: ({ isRtlDirection }) => isRtlDirection && '5%',
			[breakpoints.down('xs')]: {
				width: '300px',
				right: ({ isRtlDirection }) => !isRtlDirection && '1%',
			},
			'& >:last-child': {
				display: 'flex',
				justifyContent: 'center',
				cursor: 'pointer',
				wordBreak: 'break-all',
				flexDirection: 'column',
				textAlign: 'center',
				'& >:last-child': {
					color: 'black',
					fontSize: '16px',
				}
			},
		}
	},
	dueDateHeader: {
		display: 'flex',
		margin: '0 0 0 5%',
		gap: '4%',
		width: '121%',
		alignItems: 'center'
	},
	modalInfo: {
		overflow: 'hidden',
		color: 'green',
		fontSize: '10px',
		position: 'absolute',
		top: '-24px',
		left: '14px',
		width: '147px',
		height: '70px',
		backgroundColor: 'white',
		border: '1.5px solid lightgrey',
		wordBreak: 'break-word',
		alignItems: 'center',
		display: 'flex',
		borderRadius: '10px',
		fontWeight: 'bold',
		boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
		padding: '10px',
		marginLeft: '20px',
		right: 0,
	},
	table: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		padding: '1%',
	},
	teamTaskWrappwer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '1rem',
		padding: '0 1rem',
	},

	teamTaskContainer: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	lessonContainer: {
		borderLeft: ({ isRtlDirection }) =>
			isRtlDirection ? 'none' : `13px solid ${colors.saydoGreen}`,
		borderRight: ({ isRtlDirection }) =>
			isRtlDirection ? `13px solid ${colors.saydoGreen}` : 'none',
		width: '80%',
		height: 'fit-content',
		minHeight: '45px',
		fontFamily: 'Sora, sans-serif',
		fontSize: '15px',
		fontWeight: 500,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
		flexDirection: 'row',

		color: 'grey',
		backgroundColor: '#f5f4f4e8',

		[breakpoints.down('xs')]: {
			width: '81%',
		},
	},
	customLessonSection: {
		width: '22%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[breakpoints.down('xs')]: {
			width: '29%',
		},
	},
	check: {
		display: 'flex',
		flexDirection: 'column',
		fontSize: '9px',
		width: 'ft-content',
		height: '44px'
	},
	deleteIcon: {
		display: 'flex',
		alignItems: 'center'
	}
}))
