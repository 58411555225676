import { FC } from 'react'
import closeIcon from 'assets/closeIcon.svg'

type Props = {
	height?: string
	width?: string
	handleClick: () => void
	className?: string
}

export const CloseIcon: FC<Props> = ({ handleClick, width, height, className }) => {
	return (
		<div onClick={handleClick} className={className}>
			<img
				src={closeIcon}
				style={{
					width: width ? width : '41px',
					height: height ? height : '39px',
					cursor: 'pointer',
				}}
			/>
		</div>
	)
}
