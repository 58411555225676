import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { userSelector } from "redux/user/user-selector"

import { ITeamTask } from "interface/teams/teams-interface"

import IUser from "interface/user/user-interface"

type Props = {
    tasks: ITeamTask<IUser>[]
    setFilteredTeamTasks: (arr: ITeamTask<IUser>[]) => void
}
const UserTeamTasks = ({ tasks, setFilteredTeamTasks }: Props) => {
    const { t } = useTranslation()
    const user = useSelector(userSelector)

    const userTasks = tasks?.filter(t => t.employees_id.find(u => u?._id === user?._id))

    const [completed, notCompleted] = useMemo(() => {
        if (!tasks?.length) return []
        const completed = userTasks.filter(t => t.updatedAt)
        const notCompleted = userTasks.filter(t => !t.updatedAt)
        return [completed, notCompleted]
    }, [tasks])

    const filterCompletedTasks = () => {
        setFilteredTeamTasks(completed!)
    }
    const filterNotCompletedTasks = () => {
        setFilteredTeamTasks(notCompleted!)
    }

    const [completeStatementFirst, numPart, lastPart] =
        t('already_complete', { num: completed?.length }).split(/(\d+)/)
    const [remainingFirst, notCompleteNum, nremainingLast] =
        t('remaining_complete', { num: notCompleted?.length }).split(/(\d+)/)

    return (
        <ul>
            <li>
                {completeStatementFirst}<span style={{ color: 'green' }}>{numPart}</span>{lastPart}

                {!!completed?.length && (<span onMouseOver={filterCompletedTasks}> {t('see')}</span>)}
            </li>
            <li>
                {remainingFirst} <span style={{ color: 'red' }}>{notCompleteNum}</span>{nremainingLast}

                {!!notCompleted?.length && (<span onMouseOver={filterNotCompletedTasks}> {t('see')}</span>)}
            </li>
        </ul>
    )
}

export default UserTeamTasks