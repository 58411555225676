import { FC, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { MessageHeader } from 'components/Debriefing/InitialMessage/messageHeader'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { debriefOnProccesSelector } from '../../redux/debrief/debrief-selector'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'
// import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal'
import { useEventList } from 'components/Debriefing/Learningprocessoutputs'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Tooltip } from '@mui/material'

type ChatBotHeaderProps = {
	isCreator?: boolean
	mtName: string
	mtDescription: string
	goBack: () => void
}
const backIconStyle = { cursor: 'pointer', position: 'static' as any }

export const ChatBotHeader: FC<ChatBotHeaderProps> = props => {
	const { mtName, mtDescription, isCreator = false, goBack } = props
	const debrief = useSelector(debriefOnProccesSelector)
	const renderEventsList = useEventList()
	const classes = useStyles()
	const { t } = useTranslation()
	const [showEvents, setShowEvents] = useState(false)

	const result = debrief?.expectedResult?.achieved
		? t('debrief.achived_result')
		: t('debrief.unachived_result')
	const description = debrief?.expectedResult?.description ?? ''

	const renderItem = (
		<Tooltip title={t('back')}>
			<ArrowBackIcon onClick={goBack} style={backIconStyle} />
		</Tooltip>
	)

	return (
		<MessageHeader
			renderItem={isCreator && renderItem}
			bgColor='rgba(91, 78, 181, 1)'
			marginTop='10px'
		>
			<div className={classes.matchPoint}>
				<div>{result + ': ' + description}</div>
				<div>
					{`${t('Match_Point', { mtName, interpolation: { escapeValue: false } })}:`}
				</div>
				<div>{`"${mtDescription}"`}</div>
				<div
					className={classes.matchPointEvets}
					onClick={() => setShowEvents(true)}
				>
					{t('debrief.show_events')}
				</div>
			</div>
			<ConfirmModalSB
				isOpen={showEvents}
				onClose={() => setShowEvents(false)}
				child={renderEventsList}
				removeButtons
			/>
		</MessageHeader>
	)
}

const useStyles = makeStyles({
	matchPoint: {
		width: 'auto',
		color: 'white',
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',

		position: 'relative',
	},
	matchPointEvets: {
		textDecoration: 'underline',
		fontSize: '0.8rem',
		cursor: 'pointer',

		position: 'absolute',
		left: 0,
		top: '95%',
		bottom: 0,
	},
})
