import { IThemeOptions } from 'theme'
import { makeStyles, Theme } from '@material-ui/core'
import SearchRectangle from 'components/common/SearchRectangle'

import { useTableSearch } from './useTableSearch'
import { Row } from './DebriefTable'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { debounceSearch } from 'utils/debounceSearch'

interface Props {
	setTableData: (data: Row[]) => void
	data: Row[]
	currentTab: number
}
export const TableSearchbar = ({ data, setTableData, currentTab }: Props) => {
	const inputRef = useRef<HTMLInputElement>(null)
	const { t } = useTranslation()
	const classes = useStyles()
	const { handleSearch } = useTableSearch({
		data: data,
		setTableData,
	})
	useEffect(() => {
		handleClear()
	}, [currentTab])

	const handleClear = () => {
		setTableData(data) // reset table data
		if (inputRef.current) inputRef.current.value = ''
	}
	return (
		<SearchRectangle
			inputRef={inputRef}
			handleChange={debounceSearch(handleSearch)}
			placeholder={t('debrief.search_debrief')}
			className={classes.searchBar}
			onClear={handleClear}
		/>
	)
}
export default TableSearchbar
const useStyles = makeStyles<IThemeOptions & Theme>(({ palette }) => ({
	searchBar: {
		'& input:focus': {
			borderColor: `${palette.debrief.main} !important`,
		},
		width: '40%',
	},
}))
