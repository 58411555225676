import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import colors from 'utils/colors'

export type Styles = {
    color?: string,
    isRtlDirection?: boolean
}

export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        maxWidth: '100%',
        height: '450px',
        maxHeight: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '15px',
        overflow:'hidden'
    },
    tableWrapper: {
        width: '100%',
        textAlign: 'center',
        marginRight: '100px',
        padding: '2px'
    },
    tableHeader: {
        // display: 'flex',
        display: 'inline-grid',
        gridTemplateColumns: '195px 890px 195px',
        columnGap: '3px',
        maxHeight: '20%',
        fontSize: 19,
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Sora, sans-serif',
        '& > div': {
            backgroundColor: 'rgba(0, 113, 194, 0.7)',
            display: 'flex',
            height: 39,
            fontWeight: 450,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            color: 'white',
        },
        '& > div:first-child': {
            marginLeft: ({ isRtlDirection }) => (isRtlDirection ? 0 : '5px'),
        },
        '& >:last-child': {
            marginRight: ({ isRtlDirection }) => (isRtlDirection ? 0 : '5px'),
        },
    },
    row: {
        display: 'inline-grid',
        gridTemplateColumns: '195px 890px 195px',
        columnGap: '3px',
        fontSize: 18,
        fontWeight: 450,
        fontFamily: 'Sora, sans-serif',
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    body: {
        '& > div > div': {
            display: 'inline-grid',
            gridTemplateColumns: '195px 890px 195px',
            columnGap: '3px',
            backgroundColor: colors.blue,
            height: 35,
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& > div': {
            marginTop: '3px',
            display: 'flex',
            FontFamily: 'Sora, sans-serif',
            fontWeight: 400,
            fontSize: 18,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.blue
        },
        '& > :first-child': {
            backgroundColor: colors.blue,
            fontWeight: 350,
            justifyContent: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
            textAlign: 'left',
            fontSize: 18,
            alignItems: 'center',
            maxHeight: '100%',
            fontFamily: 'Sora, sans-serif',
            display: 'flex',
            letterSpacing: 0,
            wordBreak: 'initial',
            color: '#46484A',
            paddingLeft: ({ isRtlDirection }) => (isRtlDirection ? 0 : 5),
            paddingRight: ({ isRtlDirection }) => (isRtlDirection ? '5px' : 0),
            height: 35,
        },
        '& >:nth-child(2)': {
            textAlign: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
            fontSize: 19,
            width: '882px',
            direction: ({ isRtlDirection }) => (isRtlDirection ? 'rtl' : 'ltr'),
            flexDirection: ({ isRtlDirection }) => (isRtlDirection ? 'row-reverse' : 'row'),
            justifyContent: ({ isRtlDirection }) => (isRtlDirection ? 'flex-end' : 'flex-start'),
            overflowX: 'hidden',
            alignItems: 'center',
            paddingLeft: ({ isRtlDirection }) => (isRtlDirection ? 0 : '8px'),
            paddingRight: ({ isRtlDirection }) => (isRtlDirection ? '8px' : 0),
            // animationName: 'out',
            // animationDuration: '1.5s',
            // animationFillMode: 'forwards',
            // animationDirection: 'normal',
            // animationTimingFunction: 'ease-in-out',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            // '&:hover': {
            //     animation: 'slidDown 1.350s ease-in-out',
            //     animationFillMode: 'forwards',
            // }
        },

    },
    exceptionWeeks: {
        fontWeight: 650,
        color: 'rgb(255, 70, 98)',
    },
    counterContainer: {
        width: '122px',
        height: '146px',
        float: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
        display: 'flex',
        flexDirection: 'column',
        margin: '2% 2%',
    },
    counterWrapper: {
        width: '110px',
        height: '115px',
        // float: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
        margin: '2% 2%'
    },
    counter: {
        display: 'flex',
        width: '100%',
        height: '55%',
        fontSize: 19,
        fontWeight: 650,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    limitTitle: {
        float: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
        margin: '2% 2%',
        display: 'flex'
    },
    '@global': {
        '@keyframes slidDown': {
            '0%': {
                height: '35px',
                overflow: 'hidden'
            },
            '100%': {
                height: '99%',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                whiteSpace: 'normal',
                textOverflow: 'clip',
            },
        },
        // '@keyframes out': {
        //     '0%': {
        //         height: '99%',
        //         overflow: 'hidden',
        //     },
        //     '100%': {
        //         height: '35px',
        //         overflow: 'hidden',
        //         whiteSpace: 'nowrap',
        //         textOverflow: 'ellipsis',
        //         display: 'inline-block',
        //     }
        // }
    },
}))