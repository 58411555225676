import useStyles from './styled'
import Checkbox from '@mui/material/Checkbox'
import { VisibilityOff as ClosenEye } from '@material-ui/icons'

import eyeShown from 'assets/eye-shown.svg'

type TaskCheckboxProps = {
	shouldShow: Array<string>
	date: Date
	employee_id: string
	toggleShowingTask: () => void
}
const TaskCheckbox = ({ date, employee_id, shouldShow, toggleShowingTask }: TaskCheckboxProps) => {
	const classes = useStyles()

	const includeUser = shouldShow.includes(employee_id)
	const shownEye = (
		<img src={eyeShown} onClick={toggleShowingTask} className={classes.eyeIcon} />
	)
	const closeEye = <ClosenEye className={classes.closenEye} />

	const eye = (
		<div className={classes.shownEye} onClick={toggleShowingTask}>{includeUser ? shownEye : closeEye}</div>
	)

	const checkbox = (
		<Checkbox checked={!shouldShow.includes(employee_id)} onChange={toggleShowingTask} />
	)

	return <div>{!date ? eye : checkbox}</div>
}

export default TaskCheckbox
