import { FC } from 'react'

import './style.css'

import SwitchableFolderIcon from './SwitchableFolderIcon'
import fileImg from 'assets/fileVector.png'

type Props = {
    childIsFile: boolean
    isPrivate: boolean
}
const ItemPrefix: FC<Props> = ({ childIsFile = false, isPrivate = false }) => {
    return (
        <div className='icon-position'>
            {!childIsFile ? (
                <SwitchableFolderIcon isPrivate={isPrivate} />
            ) : (
                <img src={fileImg} alt='file' />
            )}
        </div>
    )
}

export default ItemPrefix