import { Response } from 'interface/api/response-interface'
import { GET, PATCH, POST, PUT, DELETE } from './api-requests'
import { URLS } from './api-urls'
import { TaskUpdatingPayload, OneTaskUpdatePayload } from 'redux/teams/teams-actions'
import { ICreateTeamTask } from 'interface/teams/teams-interface'

export const getTeamTasks =
    async (payload: { order?: number, user_id: string }): Promise<Response> => {
        const { order = 0, user_id } = payload
        const { data, status } = await GET(`${URLS.COMPANY_TEAM}/${order}/${user_id}`)
        return { data, status }
    }
export const createNewTask = async (payload: ICreateTeamTask): Promise<Response> => {
    const { data, status } = await POST(`${URLS.COMPANY_TEAM}`, payload)
    return { data, status }
}
export const updateTeamTasks = async (payload: Partial<TaskUpdatingPayload<string>>): Promise<Response> => {
    const { user_id, team_id, task } = payload
    const { data, status } = await PATCH(`${URLS.COMPANY_TEAM}/${user_id}/${team_id}`, { task })
    return { data, status }
}
export const toggleTaskDone = async (payload: OneTaskUpdatePayload): Promise<Response> => {
    const { user_id, team_id, task_id } = payload
    const { data, status } = await PATCH(`${URLS.COMPANY_TEAM}/done/${user_id}/${team_id}/${task_id}`)
    return { data, status }
}
export const deleteTeamTask = async (payload: OneTaskUpdatePayload): Promise<Response> => {
    const { user_id, team_id, task_id } = payload
    const { data, status } = await DELETE(`${URLS.COMPANY_TEAM}/${user_id}/${team_id}/${task_id}`)
    return { data, status }
}