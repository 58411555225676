import { makeStyles, Theme } from '@material-ui/core'
import IUser from '../../../interface/user/user-interface'
import { receivedFeedbacksSelector } from '../../../redux/feedback/feedback-selector'
import { useSelector, useDispatch } from 'react-redux'
import { receivedPraisesSelector } from '../../../redux/praise/praise-selector'
import { employeesSelector } from '../../../redux/employee/employee.selector'

import LastInsightsCards from './LastInsightsCards'
import { useEffect, useMemo } from 'react'
import { get_feedbacks } from 'redux/feedback/feedback-actions'
import { get_praises } from 'redux/praise/praise-actions'
import { userSelector } from 'redux/user/user-selector'
import { FetchUserFeedbackPraisesPayload } from 'interface/api/get-payload.types'
import { get_employees } from 'redux/employee/employee-actions'
import { FeedbackOutput } from 'interface/bot'
import { FeedbackState } from 'enums/feedback-state/feedback-state.enum'



type MainProp = {
	changeView: (path: string) => void
	currentUserName?: string
}

const MainContentInsights = ({ changeView }: MainProp) => {
	const dispatch = useDispatch()
	const currentUser = useSelector(userSelector)
	useEffect(() => {
		if (currentUser._id) {
			const payload: FetchUserFeedbackPraisesPayload = {
				company_id: currentUser.company_id!,
				user_id: currentUser._id!,
			}
			dispatch(get_employees({ company_id: payload.company_id }))
			dispatch(get_praises(payload))
			dispatch(get_feedbacks(payload))
		}
	}, [currentUser])

	const feedbacks = useSelector(receivedFeedbacksSelector)
	const praises = useSelector(receivedPraisesSelector)
	const employees = useSelector(employeesSelector)

	const info = useMemo(() => {
		return getLastSendersInfo(
			employees,
			praises && praises.length ? praises[0]?.sender._id : '',
			feedbacks && feedbacks.length ? feedbacks[0].sender._id : ''
		)
	}, [employees, feedbacks, praises.length])

	const { lastPriaseSenderInfo, lastFeedbackSenderInfo } = info

	const amountOfBlockages = feedbacks.filter(
		(feedback: FeedbackOutput<IUser>) =>
			feedback.feedbackState !== FeedbackState.sayDo
	).length
	const hasFeedbacks = feedbacks && feedbacks.length
	const hasPraises = praises && praises.length
	return (
		<></>
		// <Grid container xs={11} sm={8} lg={12} className={classes.container}>
		// 	{/* <Grid item xs={12} lg={4}>
		// 		<div className={classes.borderBox}>
		// 			<ArcProgressComponent
		// 				color={'#E8E8E8'}
		// 				title='Coming Soon'
		// 				text={'%'}
		// 				progress={Number(num)}
		// 			/>
		// 		</div>
		// 	</Grid> */}
		// 	<Grid item xs={12} lg={4}>
		// 		<MainContentInsights.LastInsightsCards
		// 			chipName={Type.BLOCKAGE}
		// 			amountOfBlockages={amountOfBlockages}
		// 			senderInfo={{
		// 				...lastFeedbackSenderInfo,
		// 				outputId: hasFeedbacks ? feedbacks[0]._id : '',
		// 			}}
		// 		/>
		// 	</Grid>
		// 	<Grid item xs={12} lg={4}>
		// 		<MainContentInsights.LastInsightsCards
		// 			chipName={Type.PRAISE}
		// 			date={
		// 				hasPraises ? dayjs(new Date(praises[0].time)).format('DD/MM/YYYY') : ''
		// 			}
		// 			senderInfo={{
		// 				...lastPriaseSenderInfo,
		// 				outputId: hasPraises ? praises[0]._id : '',
		// 			}}
		// 		/>
		// 	</Grid>
		// 	<Grid item xs={12} lg={4}>
		// 		<MainContentInsights.LastInsightsCards
		// 			chipName={Type.FEEDBACK}
		// 			date={
		// 				hasFeedbacks
		// 					? dayjs(new Date(feedbacks[0].time)).format('DD/MM/YYYY')
		// 					: ''
		// 			}
		// 			senderInfo={{
		// 				...lastFeedbackSenderInfo,
		// 				outputId: hasFeedbacks ? feedbacks[0]._id : '',
		// 			}}
		// 		/>
		// 	</Grid>
		// </Grid>
	)
}

export default MainContentInsights
MainContentInsights.LastInsightsCards = LastInsightsCards

const getLastSendersInfo = (
	employees: Array<IUser>,
	praiseSenderId: string,
	feedbackSenderId: string
): Record<string, IUser> => {
	let info = {}
	employees.map(employee => {
		if (employee._id === praiseSenderId) {
			info = { ...info, lastPriaseSenderInfo: employee }
		}
		if (employee._id === feedbackSenderId) {
			info = { ...info, lastFeedbackSenderInfo: employee }
		}
	})

	return info
}
