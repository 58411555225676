import { createSelector } from 'reselect'
import { IStoreRootState } from 'interface/redux'

export const feedbackStateSelector = (state: IStoreRootState) => state.feedback

export const feedbackLoadingSelector = createSelector(
	feedbackStateSelector,
	state => state.loading
)
export const feedbackErrorSelector = createSelector(
	feedbackStateSelector,
	state => state.error
)
export const sentFeedbacksSelector = createSelector(
	feedbackStateSelector,
	state => state.sentFeedbacks || []
)

export const receivedFeedbacksSelector = createSelector(
	feedbackStateSelector,
	state => state.receivedFeedbacks || []
)
export const feedbacksDraftSelector = createSelector(
	feedbackStateSelector,
	state => state.drafts
)
export const searchFeedbacksSelector = createSelector(
	feedbackStateSelector,
	state => (state.searchResult?.length ? state.searchResult : [])
)
export const getCurrentFeedbackSelector = createSelector(
	feedbackStateSelector,
	state => state.currentFeedbackQuestion
)

export const getAdditionalFeedbackQuestionSelector = createSelector(
	feedbackStateSelector,
	state => state.additionalFeedbackQuestion
)

export const isAdditionalFeedbackQuestionLoading = createSelector(
	feedbackStateSelector,
	state => state.additionalFeedbackQuestionLoading
)
export const moreSentFeedbacks = createSelector(
	feedbackStateSelector,
	state => state.hasMoreSentFeedbacks
)
export const moreReceivedFeedbacks = createSelector(
	feedbackStateSelector,
	state => state.hasMoreReceivedFeedbacks
)
export const moreDraftsFeedbacks = createSelector(
	feedbackStateSelector,
	state => state.hasMoreDraftsFeedbacks
)
