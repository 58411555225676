import { FC, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useStyles from './styled'

import { isCompanySelectorLoading } from 'redux/company/company.selector'
import { set_company_values } from 'redux/company/company-actions'

import CompanyValues from './CompanyValues'
import Button from 'components/common/Button'

import { IStoreRootState } from 'interface/redux'

const amountOfBoxes = 9

type Props = {
	company_id: string
	progressbar: JSX.Element
}
const CompanyValuesScreen: FC<Props> = ({ company_id, progressbar }) => {
	const values = useSelector(
		(state: IStoreRootState) => state.company.currentCompanyValues
	)
	const [companyValues, setValues] = useState<Array<string>>(
		Array.from(
			{
				length: amountOfBoxes <= values.length ? values.length + 1 : amountOfBoxes,
			},
			v => ''
		)
	)

	const companyDataloading = useSelector(isCompanySelectorLoading)

	const { t } = useTranslation()
	const styles = useStyles()
	const dispatch = useDispatch()

	useEffect(() => {
		values.forEach(value => {
			setValues((prevState: any) => {
				let t = [...prevState]
				t.pop()
				return [value, ...t]
			})
		})
	}, [dispatch])

	const handleCompanyValuesUpdate = async () => {
		if (!companyValues.length) return

		const values = companyValues
			.filter((v: string) => {
				return v.trim() !== ''
			}).reverse()

		setCompanyValues(values)
	}
	const setCompanyValues = (values: string[]) => dispatch(set_company_values({ company_id, values }))

	const divRef = (el: any) => {
		if (!el) return;
		el.getBoundingClientRect()
	}

	return (
		<div className={styles.screenWrapper} ref={divRef}>
			<h1 className={styles.title}>{t('company_values_title')}</h1>

			{companyDataloading ? (
				progressbar
			) : (
				<>
					<div className={styles.container}>

						<CompanyValues
							companyValues={companyValues}
							setCompanyValues={setValues}
						/>

					</div>

					<Button
						className={styles.saveButton}
						width={210}
						onClick={handleCompanyValuesUpdate}
					>
						{t('save')}
					</Button>
				</>
			)}
		</div>
	)
}

export default CompanyValuesScreen
