import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export const useStyles = makeStyles<Theme, { isRtlDirection: boolean }>(
	({ palette, breakpoints }) => ({
		content: {
			height: '60vh',
			overflow: 'auto',
			[breakpoints.down('sm')]: {
				height: 'auto',
			},
		},
		singleChoiceWrapper: {
			display: 'flex',
			flexWrap: 'wrap',
			flexDirection: 'row',
			width: '100%',
			maxWidth: '500px',
			margin: '43px 0 0 0',

			[breakpoints.down('md')]: {
				justifyContent: 'center',
			},
		},

		answerCard: {
			width: '220px',
			boxSizing: 'border-box',
			height: '98px',
			border: '2.8px solid',
			borderRadius: '14px',
			overflowWrap: 'break-word',
			cursor: 'pointer',
			margin: '15px',
			color: palette.text.secondary,
			display: 'flex',
			alignItems: 'center',
			textAlign: 'center',
			justifyContent: 'center',

			'&:hover': {
				boxShadow: '5px 5px 10px rgb(0 113 194 / 19%)',

				border: '2.8px solid #0071C2',
			},

			'&:last-child': {
				marginBottom: 0,
				textAlign: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
				padding: '5%',
			},
			[breakpoints.down('md')]: {
				width: '100%',
				'&:hover': {
					borderColor: 'rgba(49,49,49,0.15)',
				},
			},

			[breakpoints.down('sm')]: {
				fontSize: '0.8rem',
				padding: '11px 0 0 7px',
				alignItems: 'self-start',
				width: '40%',
				flex: '1 1 40%',
			},
		},
		answerCardAlt: {
			color: '#0071C2',
			border: '2.8px solid rgba(49,49,49,0.15)',
		},

		isChosed: {
			borderColor: '#0071C2',
			border: '2.8px solid #0071C2',
			boxShadow: '5px 5px 10px rgb(0 113 194 / 19%)',

			[breakpoints.down('md')]: {
				'&:hover': {
					borderColor: '#0071C2',
					boxShadow: 'none',
				},
			},
		},
		regular: {
			borderColor: 'rgba(49,49,49,0.15)',
		},
	})
)
