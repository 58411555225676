import { UI } from "enums/lessonsManagement"
import {
    ILessonsItems,
    ILessonsFolders,
    ILessonsBase,
    Folder,
    File,
    BaseFolderChild,
} from "interface/lessons-management/LessonsBase.interface"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { userSelector } from "redux/user/user-selector"
import { isFolderType } from "utils/LessonsManagement/isFolder"
import sortLessons from '../utils/sortLessonsDisplay'

type Props = {
    currentFolder?: ILessonsBase<Folder | File> | null
}
const useMainSections = ({ currentFolder }: Props) => {
    const [itemsSctions, setItemsSctions] = useState<ILessonsItems[] | ILessonsFolders[]>([])
    const [isFolder, setIsFolder] = useState(false)

    const { company_id } = useSelector(userSelector)

    useEffect(() => {
        if (!currentFolder) return

        if (isFolderType(currentFolder.type!)) {
            setFolderHierarchy(currentFolder.type.children)
            return
        } else {
            const lessons = sortLessons(currentFolder.type.lessons)

            setMainSections(lessons)
            setIsFolder(prev => false)
            return
        }
    }, [currentFolder, currentFolder?.type])

    const setFolderHierarchy = (children: BaseFolderChild[]): void => {
        const folders = children.filter(c => c.ui === UI.FOLDER)
        const files = children.filter(c => c.ui === UI.FILE)

        const foldersSections = { label: UI.FOLDER, data: [...folders] }
        const filesSections = { label: UI.FILE, data: [...files] }

        if (isHighLevelFolder()) {
            setMainSections([foldersSections])
        } else {
            setMainSections([foldersSections, filesSections])
        }

        setIsFolder(prev => true)
    }

    const isHighLevelFolder = (): boolean => {
        const isMainOrSubmainFolder =
            currentFolder?._id === company_id || currentFolder?.prev === company_id

        return Boolean(isMainOrSubmainFolder)
    }

    const setMainSections = (data: ILessonsItems[] | ILessonsFolders[]) => setItemsSctions(data)

    return { itemsSctions, isFolder }
}

export default useMainSections;