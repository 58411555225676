import { makeStyles, Theme } from '@material-ui/core'
import colors from 'utils/colors'

export const useStyles = makeStyles<
	Theme,
	{ isRtlDirection: boolean; readed?: boolean }
>(({ breakpoints }) => ({
	expanstionInput: {
		width: '90%',
		outline: 'none',
		border: 'none',
		borderBottom: `1px solid ${colors.saydoGreen}`,
		fontSize: '22px',
		cursor: 'pointer',
		paddingLeft: ({ isRtlDirection }) => (isRtlDirection ? 0 : '8px'),
		paddingRight: ({ isRtlDirection }) => (isRtlDirection ? '8px' : 0),
	},
}))
