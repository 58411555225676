import { FC } from 'react'
import { ClassNameMap } from '@mui/material'
import Button from 'components/common/Button'

const ONE_ON_ONE = '1:1'

type Props = {
	styles: ClassNameMap<string>
	handleClick?: () => void
}

const RenderOneOnOneReviewButton: FC<Props> = props => {
	const { styles, handleClick, children = ONE_ON_ONE } = props

	return (
		<div className={styles.reviewBtnWrapper}>
			<Button onClick={handleClick} className={styles?.reviewBtn}>
				{children}
			</Button>
		</div>
	)
}

export default RenderOneOnOneReviewButton
