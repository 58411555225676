import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import { debriefOnProccesSelector } from '../../../redux/debrief/debrief-selector'

import { Debrief } from '../../../interface/debrief/debrief-interface'
import { stepMapComponent } from './stepMapComponent'

type Params = {
	updateDebrief: (
		data: Partial<Debrief>,
		step?: DebriefingSteps | undefined
	) => void
	setDisableNext: React.Dispatch<React.SetStateAction<boolean>>
	setNextData: (value: React.SetStateAction<Partial<Debrief>>) => void
	setParticipantStep: React.Dispatch<
		React.SetStateAction<DebriefingSteps | null>
	>
	nextData: Partial<Debrief>
	wrapperRef: React.RefObject<HTMLDivElement>
	isNextDisabled: boolean
}

export const EMPTY_STATE = {
	content: <></>,
	pageIcon: { src: '', opacity: '' },
	bottomText: '',
	nextButtonText: '',
	prevData: '',
}

export const useTreeLogic = (props: Params) => {
	const debrief: Debrief | null =
		useSelector(debriefOnProccesSelector) ?? ({} as Debrief)

	return useMemo(() => {
		if (!debrief) {
			return (() => EMPTY_STATE)()
		}

		return stepMapComponent[debrief.step!]({ debrief, ...props })
	}, [debrief, debrief.step, props])
}
