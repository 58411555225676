import { useMemo } from 'react'
import { useSelector } from "react-redux"

import { employeesSelector } from "redux/employee/employee.selector"

import IUser from "interface/user/user-interface"
import { ILessonRow } from "interface/debrief/debrief-interface"
import { ITeamTask } from 'interface/teams/teams-interface'

type RecordRow = ILessonRow | ITeamTask<string>

const isDebrief = (record: ILessonRow | ITeamTask<string>): record is ILessonRow => (
    (record as ILessonRow)?.isLeader !== undefined || (record as ILessonRow)?.summary !== undefined
)
type Props = {
    participants?: IUser[]
    record?: RecordRow
}
const useMembersList = ({ participants, record }: Props) => {
    let sharedEmployess: ConcatArray<IUser> = []
    let members_id = [] as any[]

    if (isDebrief(record!)) {
        sharedEmployess = record.sharedEmployess!
        members_id = record.members_id!
    } else {
        members_id = record?.shouldShow!
    }
    const employees = useSelector(employeesSelector)

    const participantsObjects =
        useMemo(() => (participants?.concat(sharedEmployess!)), [sharedEmployess, participants])

    const membersObjects = useMemo(() =>
        employees.filter(emp => members_id?.includes(emp._id)), [members_id, employees]
    )

    return { membersObjects, participantsObjects }
}

export default useMembersList