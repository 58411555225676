import { useState, useContext, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { userSelector } from 'redux/user/user-selector'
import { getBotAnswers } from 'redux/bot/bot-selector'
import { feedbacksDraftSelector } from 'redux/feedback/feedback-selector'
import { draftsPraisesSelector } from 'redux/praise/praise-selector'

import SocketContext from 'context/socket-context'

import {
	get_question_praise,
	toggle_praise_draft,
	create_praise,
	delete_drafted_praise,
} from 'redux/praise/praise-actions'
import {
	get_question_feedback,
	toggle_feedback_output,
	create_feedback,
	delete_drafted_Feedback,
} from 'redux/feedback/feedback-actions'
import { userActions } from 'redux/user/user-reducer'

import { findFeedbackOutputById } from 'utils/outputWrapper/feedback'
import { findPraiseOutputById } from 'utils/outputWrapper/praise'
import { answersOuputMapped } from 'utils/outputs/helpers'

import { replaceString } from 'utils/replaceNameBot'
import { botActions, UserAnswerType } from 'redux/bot/bot-reducer'

import { UrgencyLevel } from 'enums/urgency/urgency.enum'
import { OutputType } from 'screens/BotOutput/index'
import IUser from 'interface/user/user-interface'
import { UserAnswer } from '../ActionButtons/index'
import { BaseCompanyOutput } from 'interface/bot/index'
import { FeedbackOutput, PraiseOutput } from 'interface/bot/index'

export type ToggleOutputDraft = {
	urgency?: UrgencyLevel
	_id: string
	company_id: string
	isDraft: boolean
	contributionExpansion?: string
	impactsExpansion?: Array<string>
}
export const UseClickActions = (
	isPraise?: boolean,
	currentOutputId?: string,
	outputType?: OutputType,
	CONTEXT?: string,
	contributionExpansion?: string,
	impactsExpansion?: string[]
) => {
	const senderInfo = useSelector(userSelector)
	const draftedFeedbacks = useSelector(feedbacksDraftSelector)
	const botAnswers: UserAnswerType = useSelector(getBotAnswers)
	const draftsPraises = useSelector(draftsPraisesSelector)

	const dispatch = useDispatch()
	const history = useHistory()
	const location: any = useLocation()

	const isFeedbackDraft =
		currentOutputId &&
		!isPraise &&
		outputType &&
		outputType === OutputType.FeedbackDraft

	const isPraiseDraft =
		currentOutputId &&
		isPraise &&
		outputType &&
		outputType === OutputType.PraiseDraft

	const socket = useContext(SocketContext)

	const initialBotAnswers = () => dispatch(botActions.removeAllAnswers())
	const initialUserBriefly = () => dispatch(userActions.clearBrieflyInput())
	const clearUserInputsAtBotFlow = () => dispatch(userActions.initInputUser())

	const closeAction = () => {
		const returningScreen = location.state.screenToReturn
		const tabState = location.state?.tabState

		fetchQuestion(isPraise!)

		initialBotAnswers()
		initialUserBriefly()

		history.push(returningScreen, { tabState })
	}

	const addAnswer = useCallback((userAnswer: UserAnswer) => {
		dispatch(botActions.addAnswer(userAnswer))

		fetchQuestion(isPraise!, userAnswer.nextQuestion)
	}, [])

	const fetchQuestion = (isPraise: boolean, questionId?: string | null) => {
		const payload = {
			questionId: questionId ?? 'first',
			language: senderInfo?.statusInfo?.companyLanguage,
		}

		if (isPraise) getQuestionPraise(payload)
		else getQuestionFeedback(payload)
	}
	const getQuestionPraise = (paylaod: any) =>
		dispatch(get_question_praise(paylaod))
	const getQuestionFeedback = (payload: any) =>
		dispatch(get_question_feedback(payload))

	const getPayload = () => {
		if (isFeedbackDraft) {
			const { _id } = findFeedbackOutputById<IUser>(
				draftedFeedbacks,
				currentOutputId
			)
			return { _id, isDraft: false }
		}
		if (isPraiseDraft) {
			const { _id } = findPraiseOutputById<IUser>(draftsPraises, currentOutputId)
			return { _id, isDraft: false }
		}

		return {
			...answersOuputMapped(botAnswers.answers, senderInfo),
			// reason: CONTEXT,
		}
	}

	const getExpansion = () => {
		return {
			contributionExpansion: contributionExpansion ? contributionExpansion : '',
			impactsExpansion: impactsExpansion ? impactsExpansion : [''],
		}
	}

	//Praise flow:
	const sendPraiseOutput = (isDraft: boolean, isOnWall: boolean) => {
		const answersOutput = getPayload()
		if (isPraiseDraft && isDraft) {
			closeAction()
			return
		}

		if (isPraiseDraft && !isDraft) {
			savePraiseInDraft({
				...answersOutput,
				...getExpansion(),
			})
			return
		}

		const payload = {
			...answersOutput,
			isDraft,
			isOnWall: isOnWall,
			...getExpansion(),
		}
		sendNewPraise(payload as PraiseOutput<any>)
	}

	const savePraiseInDraft = (answersOutput: Partial<BaseCompanyOutput<any>>) => {
		const { _id, isDraft } = answersOutput!
		const payload = {
			_id: _id!,
			isDraft: isDraft!,
			company_id: senderInfo.company_id!,
		}

		savingPraiseAtDrafts(payload)

		clearUserInputsAtBotFlow()

		closeAction()
		return
	}
	const savingPraiseAtDrafts = (payload: any) =>
		dispatch(toggle_praise_draft(payload))
	const sendNewPraise = (answersOutput: BaseCompanyOutput<any>) => {
		if (outputType === OutputType.PraiseDraft) return

		const { sender, receiver } = answersOutput!

		const output = {
			...answersOutput,
			sender: sender?._id,
			receiver: receiver?._id,
		}

		clearUserInputsAtBotFlow()

		createNewPraise(output)

		closeAction()
	}
	const createNewPraise = (output: any) => {
		const payload = {
			praise: { ...output } as PraiseOutput<string>,
			company_id: senderInfo.company_id!,
			socket: socket!,
		}
		dispatch(create_praise(payload))
	}

	//Feedback flow:
	const sendFeedbackOutput = (isDraft: boolean, urgency?: UrgencyLevel) => {
		isFeedbackAlreadyDraft(isDraft)

		const answersOutput = getPayload()
		shouldSaveFeedbackAsDraft(isDraft, answersOutput, urgency!)

		const payload = {
			...answersOutput,
			isDraft,
			urgency: urgency!,
			...getExpansion(),
		} as FeedbackOutput<any>
		sendNewFeedback(payload)
	}
	const isFeedbackAlreadyDraft = (isDraft: boolean) => {
		if (isFeedbackDraft && isDraft) closeAction()
		return
	}
	const shouldSaveFeedbackAsDraft = (
		isDraft: boolean,
		answersOutput: any,
		urgency: UrgencyLevel
	) => {
		if (isFeedbackDraft && !isDraft)
			saveFeedbackInDrafts({ ...answersOutput, urgency, ...getExpansion() })
		return
	}
	const saveFeedbackInDrafts = (
		answersOutput: Partial<BaseCompanyOutput<any> & { urgency: UrgencyLevel }>
	) => {
		const { _id, isDraft, urgency } = answersOutput
		const payload = {
			_id: _id!,
			urgency: urgency,
			isDraft: isDraft!,
			company_id: senderInfo.company_id!,
		}
		savingFeedbackAtDrafts(payload)

		clearUserInputsAtBotFlow()
		closeAction()
	}
	const savingFeedbackAtDrafts = (payload: any) =>
		dispatch(toggle_feedback_output(payload))
	const sendNewFeedback = (answersOutput: BaseCompanyOutput<any>) => {
		const sender_id = answersOutput!.sender._id
		const receiver_id = answersOutput!.receiver._id

		const output = {
			...answersOutput,
			sender: sender_id,
			receiver: receiver_id,
		}
		createNewFeedback(output)

		clearUserInputsAtBotFlow()

		closeAction()
	}
	const createNewFeedback = (output: any) => {
		const feedbackPayload = {
			feedback: {
				...output,
			} as FeedbackOutput<string>,
			company_id: senderInfo.company_id!,
		}

		dispatch(create_feedback(feedbackPayload))
	}

	const deleteOutput = () => {
		if (isPraise) deletePraise()
		else deleteFeedback()
	}
	const deletePraise = () => {
		const payload = {
			output_id: currentOutputId!,
			company_id: senderInfo.company_id!,
		}
		dispatch(delete_drafted_praise(payload))

		clearUserInputsAtBotFlow()
	}
	const deleteFeedback = () => {
		const payload = {
			output_id: currentOutputId!,
			company_id: senderInfo.company_id!,
		}
		dispatch(delete_drafted_Feedback(payload))

		clearUserInputsAtBotFlow()
	}
	const sendUserToHomePage = () => history.push('/')

	return {
		addAnswer,
		fetchQuestion,
		deleteOutput,
		closeAction,
		sendFeedbackOutput,
		sendPraiseOutput,
		sendUserToHomePage,
	}
}

export const UseReplaceNameHook = (str: string) => {
	const [strResult, setStrResult] = useState<string>('')
	const _answers = useSelector(getBotAnswers)
	const userSelecor = useSelector(userSelector) ?? {}
	const { personalInfo } = userSelecor ?? {}
	const { firstName = '' } = personalInfo ?? {}
	const name = {
		recipientPlaceholder: _answers?.answers[0] ? _answers?.answers[0].answer : '',
		senderPlaceholder: firstName ? firstName : ' ',
	}

	useEffect(() => {
		setStrResult(replaceString(str ?? '', name))
	}, [str, _answers, userSelecor])
	return strResult
}
