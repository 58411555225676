import { useState } from 'react'
import { useTheme, Theme } from '@material-ui/core/styles'
import StatusInfoCard from '../../components/common/StatusInfoCard/index'
import { useTranslation } from 'react-i18next'
import ModalWrapper from 'components/common/Modal/Wrapper/index'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import IUser from '../../interface/user/user-interface'
import { ILicenses } from 'interface/Licenses'
import { Langs } from '../../enums/app/index'

const POINTS = 315
const AMOUNT_OF_TASKS_TO_COMPLETE = 26

const useStyles = makeStyles<Theme>(({ palette }) => ({
	container: {
		margin: '0 auto',
	},
	filterContainer: {
		marginTop: '50px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	tasksToCompleteTitle: {
		color: palette.text.primary,
		fontSize: '22px',
		fontWeight: 600,
	},
	viewAll: {
		fontFamily: 'Sora, sans-serif',
		cursor: 'pointer',
		textTransform: 'none',
		fontSize: 13,
	},
	viewUncompleted: {
		fontFamily: 'Sora, sans-serif',
		textTransform: 'none',
		fontSize: 13,
		cursor: 'pointer',
	},
	tabs: {
		width: '345px',
		display: 'flex',
		alignSelf: 'center',
		'& > .MuiTabs-scroller': {
			//
			overflow: 'initial !important',
		},
		' & > .MuiTab-root': {
			color: 'red',
		},
		'& .MuiTabs-indicator': {
			width: '46px !important',
			marginLeft: '60px',
			bottom: '9px',
		},
	},
	title: {
		fontFamily: 'Montserrat',
		fontWeight: 'bold',
		fontSize: '24px',

		margin: '19px 0 17px',
		color: palette.primary.main,
	},
	description: {
		margin: '0',
	},
	summary: {
		width: '286px',
		fontSize: '18px',

		textAlign: 'center',
		margin: '26px 0 35px',
		color: palette.text.primary,
	},
	closeButton: {
		height: '50px',
		width: '164px',
		fontSize: '20px',
		cursor: 'pointer',
		border: '1.36px solid rgba(49,49,49,0.84)',
		borderRadius: '7px',
		backgroundColor: 'white',
	},
}))
export type ModalNodeType = {
	remainingLicense?: ILicenses
	company_id?: string
	companyLanguage: Langs
	onSave?: (employees: IUser[]) => void
	onError?: (error: string) => void
	onClick: () => void
}
const TaskContentModalNode: React.FC<ModalNodeType> = ({ onClick }) => {
	const classes = useStyles()
	const { t } = useTranslation()
	return (
		<>
			<h2 id='transition-modal-title' className={classes.title}>
				{t('lets_begain')}
			</h2>
			<p id='transition-modal-description' className={classes.description}>
				“Talk to Tzhaci and see what she meant"
			</p>
			<p id='transition-modal-description' className={classes.description}>
				Due 02/07/2021
			</p>
			<p id='transition-modal-description' className={classes.summary}>
				Alex started the process to complete the task. You will receive a
				notification when they done.
			</p>
			<button onClick={onClick} className={classes.closeButton}>
				{t('close')}
			</button>
		</>
	)
}

const TasksScreen = () => {
	const { t } = useTranslation()
	const theme = useTheme()
	const classes = useStyles()
	const [value, setValue] = useState(0)

	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue)
	}

	return (
		<>
			<StatusInfoCard
				backgroundColor={theme.palette.secondary.main}
				imageSrc={''}
				title={t('completing_tasks')}
				description={t('completing_tasks_desc', { count: POINTS })}
			/>

			<Grid item xs={11} sm={8} className={classes.container}>
				<div className={classes.filterContainer}>
					<div className={classes.tasksToCompleteTitle}>
						{t('tasks_to_complete_plural', { count: AMOUNT_OF_TASKS_TO_COMPLETE })}
					</div>
					<Tabs
						className={classes.tabs}
						value={value}
						indicatorColor='secondary'
						textColor='secondary'
						onChange={handleChange}
					>
						<Tab className={classes.viewAll} label={t('view_all')} />
						<Tab className={classes.viewUncompleted} label={t('view_uncompleted')} />
					</Tabs>
				</div>
			</Grid>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<ModalWrapper ModalNode={TaskContentModalNode} />
			</div>
		</>
	)
}

export default TasksScreen
