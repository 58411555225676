import { useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { sentFeedbacksSelector } from 'redux/feedback/feedback-selector'
import { sentPraisesSelector } from 'redux/praise/praise-selector'
import { userActions } from 'redux/user/user-reducer'

import { UrgencyLevel } from 'enums/urgency/urgency.enum'
import { FeedbackOutput, PraiseOutput } from 'interface/bot'
import IUser from 'interface/user/user-interface'
import { FeedbackState } from 'enums/feedback-state/feedback-state.enum'

type BeforeSendOutputLogic = {
	sendFeedbackOutput: (
		isDraft: boolean,
		urgency?: UrgencyLevel | undefined
	) => void
	sendPraiseOutput: (isDraft: boolean, isOnWall: boolean) => void
	recipientId: string
	isPraise: boolean
	isFeedBackProcess?: boolean
}
export const BeforeSendOutputLogic = (props: BeforeSendOutputLogic) => {
	const {
		sendPraiseOutput,
		isPraise,
		recipientId,
		isFeedBackProcess,
		sendFeedbackOutput,
	} = props

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [loading, setLoading] = useState(true)
	const [alertMessage, setAlertMessage] = useState('')
	const [isOnWall, setIsOnWall] = useState(false)

	const sentPraises = useSelector(sentPraisesSelector)
	const sentFeedbacks = useSelector(sentFeedbacksSelector)

	const dispatch = useDispatch()

	useEffect(() => {
		setTimeout(
			() => {
				setLoading(false)
			},
			isFeedBackProcess ? 0 : 1000
		)
	}, [])

	const toggleModal = useCallback(() => setIsModalOpen(prev => !prev), [])

	const handleSendFeedbackOutput = (
		isDraft: boolean,
		urgencyLevel?: UrgencyLevel | null
	) => {
		if (isDraft) {
			sendFeedbackOutput(isDraft)
			return
		}
		if (!isModalOpen) {
			toggleModal()
			return
		}
		toggleModal()
		sendFeedbackOutput(isDraft, urgencyLevel!)
		dispatch(userActions.clearBrieflyInput())
	}

	const handleClick = (isDraft: boolean) => {
		if (!isPraise) {
			handleSendFeedbackOutput(isDraft)
			return
		}

		sendPraiseOutput(isDraft, isOnWall)
		dispatch(userActions.clearBrieflyInput())
	}

	const isFeedback = (
		comment: FeedbackOutput<IUser> | PraiseOutput<IUser>
	): comment is FeedbackOutput<IUser> => comment.hasOwnProperty('urgency')

	const CheckTheRelevanceOfSendingFeedback = (
		comments: Array<FeedbackOutput<IUser> | PraiseOutput<IUser>>
	) => {
		const previousAction = comments[comments.length - 1]
		const beforeLastAction = comments[comments.length - 2]

		if (!beforeLastAction) return true

		if (isFeedback(previousAction)) {
			if (isFeedback(beforeLastAction)) {
				setAlertMessage(`not_given_before_praise`)
				return false
			}
			return true
		}
		return true
	}

	//TODO: refactor function below:
	const canSendFeedback = useMemo(() => {
		const userToPraisesMap: Record<string, PraiseOutput<IUser>[]> = {}
		const userToFeedbacksMap: Record<string, FeedbackOutput<IUser>[]> = {}
		let has3Blockage = false
		let countBlockages = 0

		sentPraises.forEach(praise => {
			if (!userToPraisesMap[recipientId]) userToPraisesMap[recipientId] = []
			if (praise.receiver._id === recipientId) {
				userToPraisesMap[recipientId] = [...userToPraisesMap[recipientId], praise]
			}
		})

		sentFeedbacks.forEach(feedback => {
			if (!userToFeedbacksMap[recipientId]) userToFeedbacksMap[recipientId] = []
			if (feedback.receiver._id === recipientId) {
				userToFeedbacksMap[recipientId] = [
					...userToFeedbacksMap[recipientId],
					feedback,
				]

				if (feedback.feedbackState !== FeedbackState.sayDo) {
					countBlockages++
				}
			}
		})

		has3Blockage = countBlockages >= 3
		if (!has3Blockage) setAlertMessage('too_many_blockages')

		const feedbacks = userToFeedbacksMap[recipientId] ?? []
		const praises = userToPraisesMap[recipientId] ?? []

		const comments: Array<FeedbackOutput<IUser> | PraiseOutput<IUser>> = [
			...feedbacks,
			...praises,
		].sort(
			(a: any, b: any) =>
				new Date(`${a.createdAt}`).getTime() - new Date(`${b.createdAt}`).getTime()
		)
		console.log({ comments })
		const checkPrivouseComments =
			comments.length >= 1 && //must have any comments
			!has3Blockage && //must have not more than 3 blockages
			CheckTheRelevanceOfSendingFeedback(comments)

		if (!checkPrivouseComments) setAlertMessage('not_given_before_praise')

		const canProvideFeedback = checkPrivouseComments

		let result = canProvideFeedback
			? {
					canSend: true,
			  }
			: {
					canSend: false,
					reason: {
						becauseOfBlockage: has3Blockage,
						becauseOf1PraiseMissing: !has3Blockage,
					},
			  }
		return result
	}, [sentFeedbacks, sentPraises, recipientId])

	return {
		loading,
		handleClick,
		setIsOnWall,
		isOnWall,
		isModalOpen,
		toggleModal,
		canSendFeedback,
		alertMessage,
	}
}
