import Licenses from "components/SuperAdmin/Licenses/Licenses"
import { CircularProgress } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import useStyles from './styled'

import { ILicenses } from "interface/Licenses"

type Props = {
    paidLicense: ILicenses
    remainingLicense: {
        silver: number
        bronze: number
        gold: number
    }
}
const LicensesContainer = ({ paidLicense, remainingLicense }: Props) => {

    const classes = useStyles({})
    const { t } = useTranslation()

    const progressbar = (
        <div className={classes.progressbar}>
            <CircularProgress size={150} />
        </div>
    )
    return (
        <div className={classes.licensesContainer}>

            <div className={`${classes.licenses}`}>
                {paidLicense ? (
                    <div>
                        {t('total_license')}{' '}
                        <Licenses showTotal={false} licenseValue={paidLicense} />
                    </div>
                ) : (
                    progressbar
                )}
            </div>

            <div className={classes.licenses}>
                <div>
                    {t('remaining_license')}{' '}
                    <Licenses showTotal={false} licenseValue={remainingLicense} />
                </div>
            </div>

        </div>
    )
}
export default LicensesContainer