import React from 'react'
import Modal from '@mui/material/Modal'
import GoalCreateEdit, { GoalCreateEditProps } from '../../GoalCreateEdit'
import { IUserGoalState } from 'interface/user/user-interface'

interface ModalProps {
	isOpen: boolean
	onClose?: () => void
	onSaveModal?: (updatedGoal: IUserGoalState) => void
	goalInitialValues: GoalCreateEditProps['goal']
	enableSavingForEmployee: boolean
}

const GoalCreateEditModal: React.FC<ModalProps> = ({
	isOpen,
	onClose,
	onSaveModal,
	enableSavingForEmployee,
	goalInitialValues,
}) => {
	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
		>
			<GoalCreateEdit
				goal={goalInitialValues}
				onSave={onSaveModal}
				onDiscardChanges={onClose}
				enableSavingForEmployee={enableSavingForEmployee}
			/>
		</Modal>
	)
}

export default GoalCreateEditModal
