import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { BaseQuestion, QuestionType } from 'interface/praise/praise-interface'
import classes from './styles.module.css'
import BotSettingsInput from './BotSettingsInput'
import AnswersInputs, { AnswerData } from './AnswersInputs'
import { useEffect, useState } from 'react'
import { set } from 'react-hook-form'
import { OutputSettingType } from 'interface/bot'
interface Props {
	outputTypes: OutputSettingType[]
	id: string
	title: string
	subTitle: string
	backgroundIcon: string
	questionType: QuestionType
	nextQuestion: string
	prevQuestion: string
	skippable: boolean
	answers: AnswerData[]
	questionsData: string[]
	rightDirection: boolean
	onSubmitForm: (data: BaseQuestion) => void
}

const EditorForm = ({
	outputTypes,
	id,
	title,
	subTitle,
	backgroundIcon,
	questionType,
	nextQuestion,
	prevQuestion,
	skippable,
	answers,
	questionsData,
	rightDirection,
	onSubmitForm,
}: Props) => {
	const questionProps = {
		id,
		title,
		subTitle,
		backgroundIcon,
		questionType,
		nextQuestion,
		prevQuestion,
		skippable,
		answers,
	}
	const [state, setState] = useState({
		...questionProps,
	})
	console.log(outputTypes)
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		const question: BaseQuestion = {
			_id: state.id,
			backgroundIcon: state.backgroundIcon,
			title: state.title,
			subtitle: state.subTitle,
			type: state.questionType,
			skipable: state.skippable,
			nextQuestion: state.nextQuestion,
			previousQuestion: state.prevQuestion,
			answers: state.answers,
		}
		onSubmitForm(question)
	}
	useEffect(() => {
		setState({
			...questionProps,
		})
	}, [title, id])
	return (
		<form onSubmit={(e: any) => handleSubmit(e)}>
			<div
				className={classes.answerTextInputs}
				style={{ width: '80%', padding: '0 5% 0 5%' }}
			>
				<div className={classes.answerTitle}>
					<BotSettingsInput
						rtl={rightDirection}
						inputType='title_input'
						inputName='Title'
						width='85%'
						defaultValue={state.title}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setState(prevState => {
								return { ...prevState, title: e.target.value }
							})
						}
					/>
				</div>
				<div style={{ justifyContent: 'flex-start' }} className={classes.editorRow}>
					<BotSettingsInput
						rtl={rightDirection}
						inputType='title_input'
						inputName='Sub title'
						defaultValue={state.subTitle}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setState(prevState => {
								return { ...prevState, subTitle: e.target.value }
							})
						}
					/>

					<BotSettingsInput
						rtl={rightDirection}
						inputType='title_input'
						inputName='icon'
						defaultValue={state.backgroundIcon}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setState(prevState => {
								return { ...prevState, backgroundIcon: e.target.value }
							})
						}
					/>
				</div>
			</div>
			<div style={{ flexDirection: 'column' }} className={classes.editorRow}>
				<BotSettingsInput
					inputType='type_select'
					inputName='Type'
					defaultValue={state.questionType}
					onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
						setState(prevState => {
							return { ...prevState, questionType: e.target.value as QuestionType }
						})
					}
				/>
				<BotSettingsInput
					inputType='question_select'
					inputName='Next Question'
					defaultValue={state.nextQuestion}
					values={questionsData}
					onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
						setState(prevState => {
							return { ...prevState, nextQuestion: e.target.value as string }
						})
					}
				/>
				<BotSettingsInput
					inputType='question_select'
					inputName='Previous Question'
					defaultValue={state.prevQuestion}
					values={questionsData}
					onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
						setState(prevState => {
							return { ...prevState, prevQuestion: e.target.value as string }
						})
					}
				/>
				<div className={classes.skippable}>
					<FormControlLabel
						control={
							<Checkbox
								value={state.skippable}
								onChange={() =>
									setState(prevState => {
										return { ...prevState, skippable: !state.skippable }
									})
								}
							/>
						}
						label='Skippable'
					/>
				</div>
			</div>
			<div className={classes.editorRow}>
				<div className={classes.answersInputs}>
					<AnswersInputs
						rtl={rightDirection}
						update={(answers: AnswerData[]) =>
							setState(prevState => {
								return { ...prevState, answers }
							})
						}
						questionsData={questionsData}
						currentAnswers={state.answers}
						outputTypes={outputTypes}
					/>
				</div>
			</div>
			<div className={classes.updateAnswerButtonContainer}>
				<Button color={'primary'} variant='contained' type='submit'>
					Update question
				</Button>
			</div>
		</form>
	)
}

export default EditorForm
