import { FC, useState } from 'react'
import { RadioDefault, RowRadioButtonsGroup } from './RowRadioButtonsGroup'
import { useTranslation } from 'react-i18next'
import { useStyles } from './useStyles'

export enum PHASE {
	ANALISYS = '',
	ACTIONS = 'actions',
	ASSUMPTION = 'assumption',
	ASSUMPTION_NEED = 'assumptionNeed',
	LESSON = 'lesson',
	SUMMARY = 'summary',
}

export type Options = { enableChatTextInput?: boolean }
type ChatPresetsProps = {
	currentPhase: PHASE
	dataArr: any[]
	handlePresetClick: (preset: string, options?: Options) => void
}
export const ChatPresets: FC<ChatPresetsProps> = props => {
	const [isDone, setIsDone] = useState(RadioDefault.I_Did)

	const { currentPhase, dataArr, handlePresetClick } = props

	const showRadioButtons = currentPhase === PHASE.ACTIONS

	const onChange = (e: any) => {
		setIsDone(e.target.value)
	}
	const { t } = useTranslation()
	const handleClick = (action: string, index: number) => {
		const isWriteBriflyAnswer = dataArr.length - 1 === index
		if (isWriteBriflyAnswer) {
			handlePresetClick('', { enableChatTextInput: true })
			return
		}
		handlePresetClick(` ${showRadioButtons ? t(isDone) : ''} ${action}`)
	}

	const canShowData = dataArr && !!dataArr.length
	const classes = useStyles({})

	return canShowData ? (
		<div className={classes.chatPresetsContainer}>
			{showRadioButtons && (
				<RowRadioButtonsGroup onChange={onChange} isDone={t(isDone)} />
			)}
			<div className={classes.presetAnswersContainer}>
				{dataArr.map((action: any, index: number) => (
					<div
						key={`${action}-${index}`}
						onClick={() => handleClick(t(action), index)}
					>
						{t(action)}
					</div>
				))}
			</div>
		</div>
	) : null
}
