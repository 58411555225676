import _ from 'lodash'

import IUser from 'interface/user/user-interface'
import { ActivityUserAccess } from '../useEmployeesActivities'

export const mapSelectedUsers = (users: IUser[], maxSelectedUsers: number): ActivityUserAccess[] => {
    const mappedUsers = users.map(({
        _id,
        personalInfo: { firstName },
        statusInfo: { permissions = [] }
    }: IUser): ActivityUserAccess => ({
        _id: _id,
        name: firstName,
        permissions
    }))

    return _.take(mappedUsers, maxSelectedUsers)
}