import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { employeesSelector } from "redux/employee/employee.selector"
import { userSelector } from "redux/user/user-selector"

import { BasicSwitches } from "components/common/BasicSwitches"
import DepartmentMembersList from "./DepartmentMembersList"
import DynamicModalInput from "./DynamicModalInput"

import { filterUsers } from "utils/filterTab"

import { ModalContentType } from '../props'
import IUser from "interface/user/user-interface"
import { ModalOperations } from "enums/lessonsManagement"
import { Roles } from "enums/user/user-roles"

import closeIcon from 'assets/closeIcon.svg'
import lockIcon from 'assets/lm-lock.svg'
import unlockIcon from 'assets/lm-unlock.svg'

const ModalContent = ({
    modalInfo,
    permissionsSetList,
    titles,
    selectedSection,
    handleValue,
    closeModal,
    setPermissionsSetList,
    handleSectionSelected
}: ModalContentType) => {
    const [isEditFolderSettings, setIsEditFolderSettings] = useState(false)
    const [switchChecked, setSwitchChecked] = useState(Boolean(permissionsSetList.size))
    const [searchKey, setSearchKey] = useState('')

    const { t } = useTranslation()

    const employees = useSelector(employeesSelector)
    const user = useSelector(userSelector)

    const changeSwitchChecked = () => setSwitchChecked(prev => !prev)

    useEffect(() => {
        if (!switchChecked) setPermissionsSetList(new Set())
    }, [switchChecked])

    const getAvailablePermissions = (): IUser[] => {
        if (isHumanResource()) return employees

        else return employees.filter((member: IUser) => (
            member.statusInfo.department === user.statusInfo.department
        ))
    }
    const isHumanResource = (): boolean => (user.statusInfo.role === Roles.HR)

    const availablePermissions = useMemo((): IUser[] | undefined => {
        const availablePermissions = getAvailablePermissions()
        const userIsTyping = searchKey.trim().length

        if (userIsTyping) return filterUsers(availablePermissions, searchKey)

        return availablePermissions
    }, [employees, searchKey])

    const permissionsStatus = useMemo((): string => {
        return switchChecked ? 'specific_permissions' : 'not_specific_permissions'
    }, [switchChecked])

    const { operation, title } = modalInfo

    useEffect(() => {
        if (!operation) return

        if (
            operation === ModalOperations.EDIT_FOLDER ||
            operation === ModalOperations.EDIT_FILE
        ) {
            setIsEditFolderSettings(prev => true)
        }
    }, [operation])

    const departmentMembersProps = {
        setPermissionsSetList: setPermissionsSetList,
        permissionsSetList: permissionsSetList
    }

    return (
        <div className='modal-content'>

            <div>
                <div className='modal-title'>{t(title)}</div>
                <img src={closeIcon} onClick={closeModal} />
            </div>

            <DynamicModalInput
                handleSectionSelected={handleSectionSelected}
                handleValue={handleValue}
                modalInfo={modalInfo}
                titles={titles}
                selectedSection={selectedSection}
            />

            {isEditFolderSettings &&
                <div className='switch-private'>
                    {<p>{t(permissionsStatus)}</p>}

                    <div>
                        {switchChecked
                            ? <img src={lockIcon} />
                            : <img src={unlockIcon} />
                        }

                        <BasicSwitches
                            color='success'
                            handleClick={changeSwitchChecked}
                            value={switchChecked}
                        />
                    </div>
                </div>
            }

            {switchChecked && isEditFolderSettings &&
                <DepartmentMembersList
                    {...departmentMembersProps}
                    operation={operation}
                    availablePermissions={availablePermissions}
                    setUserInput={setSearchKey}
                />
            }

        </div>
    )
}

export default ModalContent