import { FC, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { AppDirectionContext } from 'context/languages-context'

export const ScreenWrapper: FC<{}> = ({ children }) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	return <div className={classes.screenWrapper}>{children}</div>
}

export type Styles = {
	isRtlDirection?: boolean
}

export const useStyles = makeStyles<Theme, Styles>(({ breakpoints }) => ({
	screenWrapper: {
		direction: ({ isRtlDirection }) => (isRtlDirection ? 'rtl' : 'ltr'),
		width: '100%',
		overflowX: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		[breakpoints.down('sm')]: {
			height: '100vh',
		},
	},
}))
