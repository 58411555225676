import { ILesson } from "interface/lessons-management/LessonsBase.interface"
import { DEFAULT_TITLE } from '../utils/DEFAULT_TITLE_NAME'

export default function (lessons: ILesson[]) {
    const sortedArray = [...lessons].sort(customSort)
    return sortedArray
}

function customSort(current: ILesson, next: ILesson) {
    if (current.label === DEFAULT_TITLE) {
        return -1
    } else if (next.label === DEFAULT_TITLE) {
        return 1
    }
    return 0
}