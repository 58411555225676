import { makeStyles } from "@material-ui/core"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

type Props = {
    canEdit: boolean
    isEdit: boolean
    toggleEditMode: () => void
    handleUpdateText: () => void
    replayMessage: string
    replay: string
}
const TextareaButtons = ({ canEdit, isEdit, replay, replayMessage, toggleEditMode, handleUpdateText }: Props) => {
    const classes = useStyle({})
    const { t } = useTranslation()

    const buttons = useMemo((): JSX.Element => {
        const isDescriptionChanged = replay?.trim() !== replayMessage?.trim()
        const baseBtnStyle = classes.closeAndSaveBtns
        if (isDescriptionChanged) {
            return (<span className={baseBtnStyle} onClick={handleUpdateText}>{t('save')}</span>)
        }

        return (<span
            className={[baseBtnStyle, classes.disabled].join(' ')}
            onClick={toggleEditMode}>
            {t('close')}
        </span>
        )
    }, [replay, replayMessage])
    return (
        <React.Fragment>
            {canEdit &&
                !isEdit ? (
                <div onClick={toggleEditMode} className={classes.replayEditIcon}></div>
            ) : (
                buttons
            )
            }
        </React.Fragment>
    )
}

export default TextareaButtons

const useStyle = makeStyles(() => ({
    closeAndSaveBtns: {
        fontSize: '1.3rem',
        backgroundColor: 'inherit',
        color: 'black'
    },
    replayEditIcon: {
        wordBreak: 'keep-all',
        fontWeight: 'bold',
        cursor: 'pointer',
        fontSize: '0.85rem',
        display: 'flex',
        color: '#000',
        width: '80%',
        height: '30px',
        backgroundColor: '#f2efefba'
    },
    disabled: {
		color: 'grey',
	},

}))