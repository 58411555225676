import Learningprocessoutputs from 'components/Debriefing/Learningprocessoutputs'
import { EMPTY_STATE } from '../useTreeLogic'

//  FINISH | SUMMARY:
export const RenderLearningprocessoutputsSummary = ({ ...props }) => {
	const { setNextData } = props
	const pageIcon = {
		src: '',
		opacity: '',
	}

	const content = <Learningprocessoutputs isSummary setData={setNextData} />

	return { ...EMPTY_STATE, content, pageIcon }
}
