import { Divider } from '@material-ui/core'

import useStyles from './styled'

import ListContainer from './ListContainer'

import IUser from 'interface/user/user-interface'
import { DroppableArea } from './droppable.enum'

type Props = {
    members: IUser[]
    participants: IUser[]
}
const DrawList = ({ members, participants }: Props) => {
    const classes = useStyles()
    return (
        <div className={classes.membersContainer}>

            <ListContainer
                title='Member'
                employeesList={members}
                droppableId={DroppableArea.MEMBERS} />

            <Divider orientation='horizontal' />

            <ListContainer
                title='Participant'
                employeesList={participants}
                droppableId={DroppableArea.PARTICIPANTS} />

        </div>
    )
}
export default DrawList