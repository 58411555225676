export const NO_COMPANY_VALUES_AFFECTED = [
	'No, did not impact company values',
	'No, does not affect company values',
]
// accountability, customer trust, collaboration, excellence, innovation
// אחריות אישית, מיקוד בלקוח, שותפות, מצוינות, חדשנות
export const DEFAULT_VALUES = [
	'accountability',
	'customer_trust',
	'collaboration',
	'excellence',
	'innovation',
]
