import { makeStyles, Theme } from '@material-ui/core/styles'
import { IThemeOptions } from 'theme'

export default makeStyles<IThemeOptions & Theme>(
	({ breakpoints, palette }) => ({
		container: {
			height: '86vh',
			color: palette.text.primary,
			margin: '37px auto 30px',
		},
		mainBoard: {
			display: 'flex',
		},
		onProggresCard: {
			border: '1px solid #dee3ef',
			borderRadius: '10px',
			width: '100%',
			marginInlineStart: '2%',
		},
	})
)
