import { FC, useContext } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { getBotAnswers } from '../../../../redux/bot/bot-selector'
import { userSelector } from '../../../../redux/user/user-selector'
import {
	Question,
	QuestionValue,
} from '../../../../interface/praise/praise-interface'
import { UserAnswer } from '../../ActionButtons/index'
import { replaceString } from '../../../../utils/replaceNameBot'
import { useStyles } from './useStyles'
import { AppDirectionContext } from 'context/languages-context'

type Props = Question<QuestionValue[], string> & {
	currentImpactId?: any
	handleModalOpenNextQuestion: (val: UserAnswer, currentImpactId: any) => void
}

const ModalSingleChoice: FC<Props> = props => {
	const {
		title,
		answers,
		nextQuestion,
		previousQuestion,
		type,
		currentImpactId,
		handleModalOpenNextQuestion,
	} = props
	const _answers = useSelector(getBotAnswers)
	const userSelecor = useSelector(userSelector)
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })

	const recipientName = _answers.answers[0] ? _answers.answers[0].answer : ''
	const senderName = userSelecor?.personalInfo?.firstName
		? userSelecor.personalInfo.firstName
		: ''

	const questionTitle = title
	const previousAnswer = title.split('-')[0]
	const currentTitle = title.split('-')[1]

	const content = (
		<Box className={classes.boxStyles}>
			<Grid container id='modalAnswerCard'>
				{answers?.length &&
					answers?.map(({ title, nextQuestion: _nextQuestion }, id) => {
						const answerTitle = replaceString(title, {
							recipientPlaceholder: recipientName,
							senderPlaceholder: senderName,
						})

						const answer: UserAnswer = {
							id: id + 1,
							answer: questionTitle + ' ' + title,
							previousQuestion,
							nextQuestion: _nextQuestion ? _nextQuestion : nextQuestion!,
							type,
						}

						return (
							<Grid
								item
								key={id}
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<div
									key={id}
									className={classes.answerCard}
									onClick={() => handleModalOpenNextQuestion(answer, currentImpactId)}
								>
									{answerTitle}
								</div>
							</Grid>
						)
					})}
			</Grid>
		</Box>
	)

	return (
		<>
			<div className={classes.wrapper}>
				<span>
					<div className={classes.previousAnswerStyle}>{previousAnswer}</div>
					<div id='botModalTitle' className={classes.titleStyle}>
						{currentTitle}
					</div>
				</span>
				{content}
			</div>
		</>
	)
}
export default ModalSingleChoice
