export enum GoalStatus {
	COMPLETED = 'completed',
	NOT_COMPLETED = 'not_completed',
	HALF_COMPLETED = 'half_completed',
}

export enum GoalItemTypeKey {
	needs = 'needs',
	indexs = 'indexs',
	requiredActions = 'requiredActions',
}

//to be deleted - deprecated by  GoalItemTypeKey
export enum Keys {
	needs = 'needs',
	indexs = 'indexs',
	requiredActions = 'requiredActions',
}
