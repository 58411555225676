import { FC } from 'react'
import _ from 'lodash'

import useStyles from './styled'

import useEmployeesActivities, { RowObject } from './useEmployeesActivities'
import LoadingProgress from 'utils/LoadingProgress'
import { Row } from './ActivityTableRow'
import { Main } from './MainHeader'

import colors from 'utils/colors'

import IUser from '../../interface/user/user-interface'

interface ActivityTable {
	accessByPermission: boolean
	selectedEmployeeId: string
	manager: IUser
}
export const ActivityTable: FC<ActivityTable> = props => {
	const { accessByPermission, ...rest } = props

	const {
		currentUsers,
		isLoading,
		handleBackUsers,
		handleNextUsers,
		tableRows
	} = useEmployeesActivities(rest)

	const classes = useStyles({})

	if (isLoading) (<LoadingProgress />)

	return (
		<div className={classes.wrapper}>
			<Main
				isManager={!accessByPermission}
				pagination={handleNextUsers}
				handlingPaginationBack={handleBackUsers}
				names={currentUsers}
				color={colors.primary}
			/>
			<Row
				criteriaData={{}}
				names={currentUsers}
				RowDescription={''}
				color={colors.secondary}
				subTitle
			/>
			{tableRows.map(({ value: { color, criteriaData, rowDescription } }: RowObject) => (
				<Row
					RowDescription={rowDescription}
					criteriaData={criteriaData}
					color={color}
					names={currentUsers}
				/>
			))}
		</div>
	)
}
