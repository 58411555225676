import { makeStyles, Theme } from '@material-ui/core'
import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import { Debrief } from 'interface/debrief/debrief-interface'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get_user_debriefings } from 'redux/debrief/debrief-actions'
import { userSelector } from 'redux/user/user-selector'
import { IThemeOptions } from 'theme'

interface Props {
	setDisableNext: (isDisabeld: boolean) => void
	debrief: Debrief | undefined
}
export const WelcomeParticipant = ({ setDisableNext, debrief }: Props) => {
	const { _id, company_id } = useSelector(userSelector)
	const { t } = useTranslation()
	const dispatch = useDispatch()
	console.log({ debrief })
	useEffect(() => {
		if (debrief!) return
		company_id &&
			dispatch(get_user_debriefings({ user_id: _id, company_id, pageNumber: 0 }))
	}, [debrief])
	useEffect(() => {
		setDisableNext(!isParticipantStep(debrief?.step))
	}, [debrief?.step])
	const classes = useStyles({})

	return (
		<>
			<div className={classes.container}>
				<div className={classes.explanationText}>
					{t('debrief.welcome_participant_text_1')}
				</div>
				<div className={classes.explanationText}>
					{t('debrief.welcome_participant_text_2')}
				</div>
			</div>
		</>
	)
}

export default WelcomeParticipant

const useStyles = makeStyles<IThemeOptions & Theme>(
	({ breakpoints, palette }) => ({
		container: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
		},
		explanationText: {
			textAlign: 'center',
			margin: '5%',
		},
	})
)

const isParticipantStep = (step: DebriefingSteps | undefined) => {
	return (
		step === DebriefingSteps.SELF_ANALISYS_MESSAGE ||
		step === DebriefingSteps.DEBRIEFING_SELF_ANALISYS_CHAT ||
		step === DebriefingSteps.LEARNING_PROCESS_OUTPUT ||
		step === DebriefingSteps.SUMMARY ||
		step === DebriefingSteps.FINISH
	)
}
