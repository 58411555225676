import { FC } from 'react'
import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core'

import UserAvatar from 'components/common/Avatar'
import { UserAnswer } from '../ActionButtons'

import IUser from 'interface/user/user-interface'

type Props = {
	answer: UserAnswer
	user: IUser
	isSelectedUser?: boolean
	handleSelectedUser: (userInfo: IUser, UserAnswer: UserAnswer) => void
}
type StylesProps = {
	isDisabled?: boolean
	isSelectedUser: boolean
}
export const useStyles = makeStyles<Theme, StylesProps>(
	({ palette, breakpoints }) => ({
		continaer: {
			width: '100%',
			maxWidth: '469px',
			height: '98.05px',

			cursor: 'pointer',
			boxSizing: 'border-box',

			borderStyle: 'solid',
			borderWidth: '3px',
			borderColor: ({ isSelectedUser }) =>
				isSelectedUser ? '#0071C2' : 'rgba(49,49,49,0.15)',
			opacity: ({ isDisabled }) => (isDisabled ? '0.4' : 1),

			borderRadius: '14px',

			paddingInlineEnd: '29px',
			marginBottom: '14px',
			display: 'flex',
			alignItems: 'center',
			backgroundColor: ({ isDisabled }) =>
				isDisabled ? 'rgba(49,49,49,0.22)' : 'white',

			[breakpoints.up('sm')]: {
				'&:hover': {
					borderColor: ({ isDisabled }) =>
						isDisabled ? 'rgba(49,49,49,0.15)' : '#0071C2',
					boxShadow: ({ isSelectedUser }) =>
						isSelectedUser ? '0 5px 5px 0 rgb(0 113 194 / 19%)' : '',
				}
			}
		},
		userName: {
			fontWeight: 'bold',
			fontSize: '22px',
			letterSpacing: 0,
			lineHeight: '27px',
			color: palette.text.primary,
		},
		avatar: {
			height: '64.43px',
			width: '64.4px',

			marginInlineStart: '26px',
			marginInlineEnd: '10px',
		},
	})
)
const UserCard: FC<Props> = ({
	user,
	isSelectedUser,
	handleSelectedUser,
	answer,
}) => {
	const classes = useStyles({ isSelectedUser: isSelectedUser! })

	const {
		statusInfo: { title },
		personalInfo: { firstName, lastName },
	} = user

	return (
		<>
			<div
				id='usersListContainer'
				className={classes.continaer}
				onClick={() => handleSelectedUser(user, answer)}
			>
				<UserAvatar className={classes.avatar} userInfo={user} />
				<div>
					<div className={classes.userName}>
						{firstName} {lastName}
					</div>
					<div>{title}</div>
				</div>
			</div>
		</>
	)
}

export default UserCard
