import { useState } from "react";

const useEditMode = () => {

    const [isEditMode, setIsEditMode] = useState<boolean>(false)
    const [taskToUpdate, setTaskToUpdate] = useState<string>('')

    const toggleShowingEditMode = () => {
        setIsEditMode(prev => !prev)
    }

    const onEditTask = (todo_id: string) => {
        toggleShowingEditMode()
        setTaskToUpdate(todo_id)
    }

    const resetEditMode = () => {
        toggleShowingEditMode()
        setTaskToUpdate('')
    }


    return { resetEditMode, onEditTask, isEditMode, taskToUpdate, setTaskToUpdate, toggleShowingEditMode }
}

export default useEditMode;