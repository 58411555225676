import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@material-ui/core'
import moment from 'moment'

import useStyles from './styled'

import { DirectEmployee } from "interface/exceptions/exceptions-interface"

type EmpRecord = {
    dirEmp: DirectEmployee
}
export const EmployeesRecord: FC<EmpRecord> = props => {
    const { dirEmp } = props

    const classes = useStyles({})
    const { t } = useTranslation()

    const { personalInfo: { firstName, lastName }, last_praise, attached } = dirEmp
    const days = last_praise ? getExceptionInWeeks(last_praise) : getExceptionInWeeks(attached!)

    const title = t('exception_time', { exceptionWeeks: days })

    const tooltip = (
        <Tooltip arrow placement='top' title={title}>
            <span>{days}</span>
        </Tooltip>
    )
    return (
        <>{firstName} {lastName} {' '}<span className={classes.exceptionWeeks}>({tooltip}){', '}</span></>
    )
}

const getExceptionInWeeks = (date: Date) => {
    const AMOUNT_OF_DAYES = 30
    const PLUS_ONE = 1
    const PER_WEEK = 7

    const days = moment().diff(date, "days") - AMOUNT_OF_DAYES

    if (days < AMOUNT_OF_DAYES) return AMOUNT_OF_DAYES - AMOUNT_OF_DAYES

    return Math.floor((days + PLUS_ONE) / PER_WEEK)
}