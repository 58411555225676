import { POST, GET, DELETE, PATCH, PUT } from './api-requests'
import { URLS } from './api-urls'
import { mapDataToQuestionType } from 'utils'
import { IFeedback } from 'interface/feedback/feedback-interface'
import { Response } from 'interface/api/response-interface'
import {
	FeedbackOutput,
	FeedbackOutputUpdate,
	OutputSettingType,
	ReviewOutputPayload,
} from 'interface/bot'
import { FetchUserFeedbackPraisesPayload } from 'interface/api/get-payload.types'
import { ToggleOutputDraft } from '../components/Bot/useHooks/index'
import { BaseQuestion } from 'interface/praise/praise-interface'
import { Langs } from 'enums/app'

export const fetchReceivedFeedbacksByIds = async (params: string) => {
	const data = await GET(`${URLS.COMPANY_FEEDBACKS}/receive?${params}`)
	return data
}

export const fetchSentFeedbacksByIds = async (params: string) => {
	const data = await GET(`${URLS.COMPANY_FEEDBACKS}?${params}`)
	return data
}

export const fetchFeedbackOutputById = async (payload: Record<string, any>) => {
	const { id, isBeforeSend } = payload
	const { data, status } = await GET(
		`${URLS.FEEDBACK_OUTPUT_SETTINGS}/feedback?id=${id}&isBeforeSend=${isBeforeSend}`
	)

	return { data, status }
}
export const fetchFeedbackTabsTemplate = async () => {
	const { data, status } = await GET(
		`${URLS.FEEDBACK_OUTPUT_SETTINGS}/feedback/tabs`
	)
	return { data, status }
}

export const fetchAllFeedbacksFurtherimpact = async () => {
	const { data, status } = await GET(URLS.FEEDBACK_IMPACTS_OUTPUT_SETTINGS)
	return { data: data }
}

export const fetchAllFeedbacksOutputs = async () => {
	const { data, status } = await GET(URLS.FEEDBACK_OUTPUT_SETTINGS)
	return { data, status }
}
export const createFeedbacksOutput = async (
	output: Omit<OutputSettingType, '_id'>
) => {
	const { data, status } = await POST(URLS.FEEDBACK_OUTPUT_SETTINGS, {
		...output,
	})
	return { data, status }
}
export const updateFeedbacksOutput = async (output: OutputSettingType) => {
	const { data, status } = await PUT(URLS.FEEDBACK_OUTPUT_SETTINGS, {
		...output,
	})
	return { data, status }
}

export const deleteFeedback = async (payload: Record<string, string>) => {
	const { data, status } = await DELETE(
		`${URLS.COMPANY_FEEDBACKS}?output_id=${payload.output_id}&company_id=${payload.company_id}`
	)
	return { data, status }
}

export const fetchFeedbacks = async ({
	user_id,
	company_id,
}: FetchUserFeedbackPraisesPayload): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.EMPLOYEE}/feedbacks?company_id=${company_id}&user_id=${user_id}`
	)

	return { data, status }
}

export const loadMoreFeedbacks = async ({
	user_id,
	company_id,
	pageNumber,
	type,
}: FetchUserFeedbackPraisesPayload): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.EMPLOYEE}/loadFeedbacks?company_id=${company_id}&user_id=${user_id}&pageNumber=${pageNumber}&type=${type}`
	)
	return { data, status }
}

export const toggleFeedbackOutputDraft = async (
	feedback: ToggleOutputDraft
): Promise<Response> => {
	const { data, status } = await PATCH(`${URLS.COMPANY_FEEDBACKS}/toggleDraft`, {
		...feedback,
	})
	return { data, status }
}
export const getFeedbackQuestion = async (
	questionId: string | null,
	language: Langs
): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.FEEDBACK}${questionId ? `/${questionId}` : '/first'}`
	)
	const question = await mapDataToQuestionType(data, language)
	return { data: question, status }
}
export const getAllFeedbackQuestions = async (): Promise<Response> => {
	const { data, status } = await GET(`${URLS.FEEDBACK}`)
	return { data, status }
}
export const createFeedback = async (
	feedback: IFeedback
): Promise<Response> => {
	const { data, status } = await POST(URLS.FEEDBACK, feedback)
	return { data, status }
}

export const sendOutputFeedbacks = async <T>(
	payload: FeedbackOutput<T>,
	company_id: string
) => {
	const { data, status } = await PATCH(URLS.COMPANY_FEEDBACKS, {
		company_id,
		feedback: payload,
	})

	return { data, status }
}

export const updateFeedback = async (
	payload: FeedbackOutputUpdate,
	user_id = ''
): Promise<Response> => {
	const { data, status } = await PATCH(
		`${URLS.EMPLOYEE}/feedbacks/${user_id}`,
		payload
	)
	return { data, status }
}

export const updateFeedbackBotQuestion = async (
	payload: BaseQuestion
): Promise<Response> => {
	const { data, status } = await PUT(`${URLS.FEEDBACK}`, payload)
	return { data, status }
}

export const reviewFeedbackToggle = async (
	payload: ReviewOutputPayload
): Promise<Response> => {
	const { data, status } = await PATCH(
		`${URLS.COMPANY_FEEDBACKS}/review`,
		payload
	)
	return { data, status }
}
