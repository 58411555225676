import { createSelector } from 'reselect'
import { IStoreRootState } from 'interface/redux'

export const notificationStateSelector = (state: IStoreRootState) =>
	state.notifications

export const newNotificationsSelector = createSelector(
	notificationStateSelector,
	state => state.newNotifications || []
)

export const oldNotificationsSelector = createSelector(
	notificationStateSelector,
	state => state.oldNotifications || []
)

export const notificationsLengthSelector = createSelector(
	notificationStateSelector,
	state => state.allNotificationsLength
)

export const hasMoreNotifications = createSelector(
	notificationStateSelector,
	state => state.hasMoreNotifications
)
