export const RowDescription = {
	sentPraises: 'Praises_sent',
	sentFeedbacks: 'Feedbacks_sent',
	openViaSent: 'open_blockages_feedbacks_sent',
	receivedPraises: 'Praises_received',
	receivedFeedbacks: 'Feedbacks_received',
	openViaReceived: 'open_blockages_feedbacks_recevied',
	personalLearning: 'personal_learning',
	groupLearning: 'group_learning',
	total: 'Total',
} as const
