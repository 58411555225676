import { useMediaQuery } from '@material-ui/core'

import './style.css'

import FolderButtons from './FolderButtons'
import FileButtons from './FileButtons'
import LMButton from 'components/Debriefing/Lessons-management/lesson-btn/GenericItemButton'

import { LessonsBtnsLable } from 'enums/lessonsManagement/index'
import { ItemLMButtons } from '../props'
import { useSelector } from 'react-redux'
import { userSelector } from 'redux/user/user-selector'
import { setHasInclude } from 'utils/hasSetInclude'

const ItemButtons = ({
	hoverOnItem = false,
	isPrivate = false,
	isFolder,
	isSaveAvailable,
	itemButtonEvents,
	folder_id,
	folderManagers
}: ItemLMButtons) => {
	const {
		toggleEditLesson,
		insertNewLesson,
		deleteFolder,
		deleteLesson,
		editFolder,
		toggleEmptyLesson,
		updateLesson
	} = itemButtonEvents
	const isMobile = useMediaQuery('(max-width:550px)')

	const { _id: user_id } = useSelector(userSelector)

	let btnsStyle = 'btns-menu'
	if (isSaveAvailable.new) btnsStyle += ' new-lesson'

	const handleNewLesson = () => {
		toggleEmptyLesson?.()
		insertNewLesson?.()
	}

	const isManagerFolder = () => setHasInclude(folderManagers!, user_id)

	const btns = isFolder ? (
		<FolderButtons folder_id={folder_id}
			editFolder={editFolder}
			deleteFolder={deleteFolder}
			isPrivate={isPrivate}
		/>
	) : hoverOnItem && isManagerFolder() && (
		<FileButtons
			deleteLesson={deleteLesson}
			folder_id={folder_id}
			toggleEditLesson={toggleEditLesson}
			isSaveAvailable={isSaveAvailable}
		/>
	)

	const newLessonButtons = (
		<>
			{!isMobile &&
				<LMButton
					label={LessonsBtnsLable.CANCEL}
					className='new-lesson'
					handleClick={toggleEmptyLesson} />}
			<LMButton
				label={LessonsBtnsLable.SAVE}
				className='new-lesson'
				handleClick={handleNewLesson}
			/>
		</>
	)

	return (
		<div className={btnsStyle}>
			{isSaveAvailable.new ? newLessonButtons : btns}

			{isSaveAvailable.exist && <LMButton
				label={LessonsBtnsLable.SAVE}
				className='edit-lesson'
				handleClick={() => updateLesson?.(folder_id)}
			/>}
		</div>
	)
}

export default ItemButtons