import { useTranslation } from 'react-i18next'
import { Tabs, Tab } from '@material-ui/core'

import useStyles from './styled'
import { ListType } from './useTablePagination'

const TableTitle = (props: any) => {
	const { value, handleChange, handleSetListType } = props
	const { t } = useTranslation()
	const classes = useStyles({})
	return (
		<div className={classes.titleContainer}>
			<div className={classes.filtersContainer}>
				<div className={classes.text}>{t('EMPLOYEES')}</div>

				<Tabs
					className={classes.tabs}
					value={value}
					indicatorColor='secondary'
					textColor='secondary'
					onChange={handleChange}
				>
					<Tab
						className={classes.viewAll}
						label={t('view_all')}
						onClick={() => handleSetListType(ListType.all)} // setListType('view_all')
					/>
					<Tab
						className={classes.viewManager}
						label={t('view_managers')}
						onClick={() => handleSetListType(ListType.managers)} // setListType('view_managers')
					/>
				</Tabs>
			</div>
		</div>
	)
}

export default TableTitle
