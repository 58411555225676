export enum LessonsBtnsLable {
    NEW_FOLDER = 'new_folder',
    EDIT_FOLDER = 'edit_folder',
    CANCEL = 'cancel',
    PASTE = 'paste_here',
    NEW = 'new',
    NEW_TITLE = 'title',
    NEW_LESSON = 'plus_lesson',
    SAVE = 'save',
    FOLDER_MANAGERS = 'grant_permissions',
    SEND_EMAIL = 'send_email'
}