import flower01 from 'assets/flower-01.svg'
import flower02 from 'assets/flower-02.svg'
import flower03 from 'assets/flower-03.svg'
import flower04 from 'assets/flower-04.svg'
import flower05 from 'assets/flower-05.svg'
import flower06 from 'assets/flower-06.svg'
import flower07 from 'assets/flower-07.svg'

export const numberToilustrationMap: Record<string, string> = {
	'flower-07': flower01,
	'flower-06': flower02,
	'flower-05': flower03,
	'flower-04': flower04,
	'flower-03': flower05,
	'flower-02': flower06,
	'flower-01': flower07,
}
