import { ChangeEvent, FC } from 'react'
import { useSelector } from 'react-redux'
import useStyles from './useStyles'
import { preservUserInputSelector } from 'redux/user/user-selector'
import useOutputFilterRule from 'utils/useOutputFilterRule'

type Props = {
	handleChange: (event: ChangeEvent<HTMLInputElement>) => void
	isRtlDirection?: boolean
}

export const SearchUser: FC<Props> = props => {
	const { isRtlDirection = false, handleChange } = props
	const userInput = useSelector(preservUserInputSelector) // Should not be here it. Should be decoupling

	const classes = useStyles({ isRtlDirection })

	const { selectedEmployeeName } = useOutputFilterRule()
	const isWorkMeeting = !!selectedEmployeeName?.length
	const value = isWorkMeeting ? selectedEmployeeName : userInput

	return (
		<div className={classes.searchInputWrapper}>
			<input
				id='query_testId'
				data-testid='query_testId'
				value={value}
				type='text'
				className={classes.searchInput}
				onChange={handleChange}
			/>
		</div>
	)
}
export default SearchUser
