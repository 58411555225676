import { createSlice } from '@reduxjs/toolkit'
import HttpStatusCode from 'enums/http/HttpStatusCode'

export type ServerError = {
	message: string
	statusCode?: HttpStatusCode
}
export interface requestErrorState {
	error: ServerError
}

const initialState: requestErrorState = {
	error: {} as ServerError,
}

export const requestErrorSlice = createSlice({
	name: 'request-error',
	initialState,
	reducers: {
		setError(state, action) {
			const res = action.payload
			console.log({ res })
			return {
				error: { message: res.message, statusCode: res.code },
			}
		},
		clear(state) {
			return { error: {} as ServerError }
		},
	},
})

export const requestErrorActions = requestErrorSlice.actions
export default requestErrorSlice.reducer
