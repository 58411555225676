import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { questionId } from '../../../interface/bot/index'
import { QuestionType } from '../../../interface/praise/praise-interface'
import IUser from '../../../interface/user/user-interface'
import useStyles from './useStyles'
import useLogic from './useLogic'

const ActionsButton: FC<ActionButtonsType> = props => {
	const {
		answer,
		isEnabled,
		showNextStepButton,
		showPreviousButton,
		onPrevClick,

		...rest
	} = props

	const { handleNextStep, handlePreviousStep } = useLogic({
		...rest,
		answer,
		isEnabled,
	})

	const { t } = useTranslation()
	const classes = useStyles({ isEnabled })

	if (!showNextStepButton && !showPreviousButton) {
		return null
	}

	return (
		<div id='botButtonsContainer' className={classes.container}>
			{showPreviousButton && (
				<button
					className={classes.previousButton}
					onClick={onPrevClick ? onPrevClick : handlePreviousStep}
				>
					{t(`bot.${BotButtonText.previousQuestionTrans}`)}
				</button>
			)}
			{showNextStepButton && (
				<button
					disabled={!isEnabled}
					className={classes.nextButton}
					onClick={() => handleNextStep(answer?.nextQuestion!)}
				>
					{t(`bot.${BotButtonText.nextQuestionTrans}`)}
				</button>
			)}
		</div>
	)
}

export default ActionsButton

export enum BotButtonText {
	previousQuestionTrans = 'previousQuestion',
	nextQuestionTrans = 'nextQuestion',
}

export type ActionButtonsType = {
	type?: QuestionType
	answer?: UserAnswer
	isEnabled?: boolean
	isPraise?: boolean
	showPreviousButton?: boolean
	showNextStepButton?: boolean
	previousQuestionId?: string
	handleSaveFurtherImpacts?: () => void
	removeIds?: () => void
	setOpenModal?: () => void
	setQuestion: React.Dispatch<React.SetStateAction<questionId>>
	onPrevClick?: () => void
}
export type UserAnswer = {
	id: string | number
	nextQuestion: string | null
	answer?: string | null
	date?: Date
	multiChoiceAnswer?: Array<string>
	values?: Array<string>
	previousQuestion?: string | null | undefined
	type: QuestionType | string
	recipient?: IUser | null
	outputType?: string | number
	participants?: IUser[]
	expansionContribution?: string
	expansionImpacts?: string[]
}

export const initialAnswer: UserAnswer = {
	answer: null,
	nextQuestion: null,
	id: '',
	previousQuestion: null,
	multiChoiceAnswer: [],
	participants: [],
	type: '',
	expansionContribution: '',
	expansionImpacts: [],
}
