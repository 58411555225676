import { ChangeEvent, useContext } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { AppDirectionContext } from 'context/languages-context'

interface Props extends IStyleProps {
	focus?: boolean
	defaultValue?: string
	placeholder?: string
	value?: string
	type: string
	name: string
	handleInputChange?: (input: ChangeEvent<HTMLInputElement>) => void
	maxLength?: number
	disabled?: boolean
	isRtlDirection?: boolean
}
const InputBuilder = ({
	focus,
	placeholder,
	type,
	showError,
	name,
	value,
	handleInputChange,
	backGroundColor,
	padding,
	height,
	width,
	borderColor = 'rgba(49, 49, 49, 0.15)',
	maxLength,
	defaultValue,
	disabled,
	disabledColor,
	isRtlDirection: isRtl,
}: Props) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({
		disabledColor,
		showError,
		backGroundColor,
		padding,
		height,
		width,
		borderColor,
		isRtlDirection: isRtl !== undefined ? isRtl : isRtlDirection,
	})

	const styles = {
		border: `1px solid ${showError ? 'red ' : `${borderColor}`}`,
	}

	return (
		<div className={classes.inputContainer}>
			<input
				value={value}
				name={name}
				style={styles}
				disabled={disabled}
				autoFocus={focus}
				defaultValue={defaultValue ? defaultValue : ''}
				maxLength={maxLength}
				autoComplete='off'
				// {...filter}
				className={classes.input}
				// {...register(name, { required })}
				onChange={handleInputChange}
				placeholder={placeholder}
				type={type}
			/>
		</div>
	)
}

export default InputBuilder

interface IStyleProps {
	disabledColor?: string
	showError: string | undefined
	backGroundColor?: string
	padding?: string
	height?: string
	width?: string
	outline?: string
	borderColor?: string
	isRtlDirection?: boolean
}

const useStyles = makeStyles<Theme, IStyleProps>(() => ({
	inputContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	input: {
		direction: ({ isRtlDirection }) => (isRtlDirection ? 'rtl' : 'ltr'),
		fontFamily: 'Sora, sans-serif',
		width: ({ width }) => (width ? `${width} !important` : '80% !important'),
		height: ({ height }) => (height ? height : '2rem'),
		boxSizing: 'border-box',
		padding: ({ padding }) => (padding ? padding : '0 0 0 0.5rem'),
		paddingInlineStart: ({ padding }) => (padding ? '20px' : ''),
		backgroundColor: ({ backGroundColor }) =>
			backGroundColor ? backGroundColor : '#FFFFFF',
		outline: ({ showError }) => (showError ? 'none' : ''),
		border: ({ showError, borderColor }) =>
			borderColor
				? borderColor
				: `1px solid ${showError ? 'red ' : 'rgba(49, 49, 49, 0.15)'}`,
		borderRadius: '4px',
		'&:focus': {
			outline: '2px solid #0071C2',
			border: showError => (showError ? 'red' : 'rgba(49, 49, 49, 0.15)'),
		},

		'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
		},
		'&:disabled': {
			backgroundColor: ({ disabledColor }) => (disabledColor ? disabledColor : ''),
		},
	},
}))
