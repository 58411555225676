import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import MobileMenu from '../Header/Mobile'
import UserInformation, { Notification } from '../Header/Web'
import Logo from './Logo'
import NavigationButtons from './NavigationButtons'
import { Roles } from 'enums/user/user-roles'
import classes from './style.module.css'
import { License } from 'enums/License/License.enum'

interface Props {
	firstName: string
	lastName: string
	title: string
	role: Roles
	license: License
}

const MobileNavigation = React.memo(
	({ firstName, lastName, title, role, license }: Props) => {
		const [isMenuOpen, setIsMenuOpen] = useState(false)
		const handleOpenMobileMenu = () => {
			setIsMenuOpen(prev => !prev)
		}
		// const userInfoProps = { firstName, lastName, title, isMobile: true }

		return (
			<AppBar
				elevation={0}
				className={classes.appBar}
				sx={{
					display: { xs: 'flex', md: 'none' },
					backgroundColor: 'white',
					boxShadow:
						'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;',
				}}
				position='static'
			>
				<Container>
					<Toolbar disableGutters>
						<Box
							style={{
								display: 'flex',

								flex: 1,
								height: '100%',
							}}
							sx={{
								// width: '30vw',
								display: 'flex',
							}}
						>
							<MobileMenu
								isMenuOpen={isMenuOpen}
								handleOpenMobileMenu={handleOpenMobileMenu}
								firstName={firstName}
								lastName={lastName}
								title={title}
							>
								<NavigationButtons
									isMobile
									role={role}
									license={license}
									handleMobileMenu={handleOpenMobileMenu}
								/>
							</MobileMenu>
						</Box>
						<Box
							style={{
								flex: '1 1 70%',
								height: '100%',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<div style={{ flex: 1 }}>
								<Notification isMobile />
							</div>
							<Typography
								style={{
									flex: 1,
								}}
								noWrap
								component='div'
								sx={{
									width: '100%',
									justifyContent: 'flex-end',
									display: 'flex',
								}}
							>
								<Logo mr={0} ml={0} height={'3.5rem'} width='100%'/>
							</Typography>
						</Box>
						{/* 
						<Box
							style={{ backgroundColor: 'blue', flex: 1, height: '100%' }}
							sx={{
								// width: '30vw',
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<UserInformation {...userInfoProps} />
						</Box> */}
					</Toolbar>
				</Container>
			</AppBar>
		)
	}
)
export default MobileNavigation
