import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles<Theme, { isRtlDirection: boolean }>(
	({ breakpoints, palette }) => ({
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			position: 'relative',

			[breakpoints.down('sm')]: {
				'& > span': {
					width: '100%',

					position: 'sticky',
					top: 0,

					background: 'white',
				},
			},
		},

		previousButton: {
			height: '54px',
			width: '224px',
			border: '2.36px solid #949494',
			borderRadius: '13.6px',
			backgroundColor: '#FFFFFF',
			cursor: 'pointer',

			borderColor: '#0071C2',
			color: '#0071C2',
			fontSize: '20.4px',
			fontWeight: 600,
			textAlign: 'center',
		},
		answerCard: {
			textAlign: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
			justifyContent: ({ isRtlDirection }) =>
				isRtlDirection ? 'flex-end' : 'flex-start',

			fontFamily: 'Sora, sans-serif',
			fontSize: '20px',
			width: '320px',
			boxSizing: 'border-box',
			height: '98px',
			border: '2.8px solid rgba(49,49,49,0.15)',
			borderRadius: '14px',
			overflowWrap: 'break-word',
			cursor: 'pointer',
			padding: '0 31px',
			backgroundColor: 'white',
			color: palette.text.primary,

			display: 'flex',

			alignItems: 'center',
			paddingInlineEnd: '31px',
			// justifyContent: 'flex-end',

			[breakpoints.up('sm')]: {
				'&:hover': {
					borderColor: '#0071C2',
					border: '2.8px solid #0071C2',
					boxShadow: '5px 5px 10px rgb(0 113 194 / 19%)',
				},
			},

			[breakpoints.down('sm')]: {
				// padding: 0,
				fontSize: '0.9rem',
				width: '100%',
				paddingTop: '11px',
				paddingInlineStart: '7px',
				alignItems: 'self-start',
			},
		},
		titleStyle: {
			fontFamily: 'Sora, sans-serif',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '1.5rem',

			textAlign: 'center',
			color: '#46484A',
			margin: '17px 0 51px 0',
		},
		previousAnswerStyle: {
			fontFamily: 'Sora, sans-serif',
			fontStyle: 'normal',
			fontSize: '1rem',

			color: '#626466',

			[breakpoints.down('sm')]: {
				textAlign: 'center',
			},
		},
		boxStyles: {
			width: '1287px',
			height: '100%',
			display: 'flex',
			flexDirection: 'row',

			'& > div ': {
				margin: '5px 5px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				width: '100%',
			},
			'& > div > div': {
				margin: '5px 5px',
			},

			[breakpoints.down('sm')]: {
				width: '100%',
				margin: 'auto',
				height: 'auto',
				overflowY: 'auto',

				'& > div > div': {
					flex: '1 1 32%',
				},
			},
		},
	})
)
