export enum INotificationDescription {
	ACTION_PLAN = 'notification.action_plan',
	APPROVED_ACTION_PLAN = 'notification.approved_action_plan',
	FEEDBACK = 'notification.sent_feedback',
	PRAISE = 'notification.sent_praise',
	PRAISE_ON_WALL = 'notification.sent_praise_on_wall',
	SAYDO = 'notification.saydo',
	WELCOME_ABOARD = 'notification.welcome_aboard',
	EXCEPTION = 'notification.todos-exception',
	REPLY_MESSAGE = 'notification.reply_message'
}

export enum NotificationTypes {
	DEBRIEF = 'DEBRIEF',
	FEEDBACK = 'FEEDBACK',
	PRAISE = 'PRAISE',
	GOALS = 'GOALS',
	WELCOME_ABOARD = 'WELCOME_ABOARD',
	TODO = 'TODO',
	TEAM = 'TEAM_TODO'
}

export interface INotification {
	_id: string
	output_id?: string
	receiver_id?: string
	firstName: string
	lastName: string
	date?: Date
	type: NotificationTypes
	isNewNotification: boolean
	imageUrl: string
	description?: INotificationDescription | string
}
export interface INotificationsState {
	currentRequestId: string | undefined
	error: string | null | undefined
	loading: boolean
	newNotifications: INotification[]
	oldNotifications: INotification[]
	allNotificationsLength: number
	hasMoreNotifications: boolean
}

export const NOTIFICATIONS_SKIP_COUNT = 4
