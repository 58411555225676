import { makeStyles, Theme } from '@material-ui/core'
import colors from 'utils/colors'

type Styles = {
	isRtlDirection: boolean
	readed?: boolean
}
export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
	reviewBtnWrapper: { width: 'fit-content' },
	reviewBtn: {
		color: ({ readed }) => (readed ? '#fff' : colors.saydoBlue),
		backgroundColor: ({ readed }) => (readed ? '#0071c2' : '#fff'),
		fontWeight: 600,
		width: '150px',
		margin: '0% 0 3% 0',
		float: ({ isRtlDirection }) => (isRtlDirection ? 'left' : 'right'),
		border: `.7px solid ${colors.boldBlue}`,
		'&:active': {
			backgroundColor: 'lightgrey',
		},
		[breakpoints.down('xs')]: {
			width: '150px !important',
		},
	},
}))
