import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles<Theme>(({ breakpoints, palette }) => ({
	dateWrapper: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '469px',
		width: '100%',
		height: '65vh',
		[breakpoints.down('sm')]: {
			width: '80%',
			height: '55vh',
			gap: '1%',
			marginBottom: '3%',
		},
	},
	previousButton: {
		color: '#0071C2',
		width: '224px',
		border: '2.36px solid #949494',
		cursor: 'pointer',
		height: '54px',
		fontSize: '20.4px',
		textAlign: 'center',
		fontWeight: 600,
		borderColor: '#0071C2',
		borderRadius: '13.6px',
		backgroundColor: '#FFFFFF',

		[breakpoints.down('sm')]: {
			width: '90%',
		},
	},
	container: {
		display: 'flex',
		flex: '1 1 100%',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'white',

		boxSizing: 'border-box',
		maxWidth: '469px',
		width: '100%',
		border: '2.8px solid rgba(49, 49, 49, 0.152716)',
		borderRadius: '14px',
		cursor: 'pointer',

		marginBottom: '15px',

		[breakpoints.up('sm')]: {
			'&:hover': {
				borderColor: '#0071C2',
				border: '2.8px solid #0071C2',
				boxShadow: '5px 5px 10px rgb(0 113 194 / 19%)',
			},
		},

		[breakpoints.down('sm')]: {
			marginBottom: 0,
		},
	},
	iconWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		color: palette.text.secondary,
	},
	icon: {
		width: '47px',
		height: '47px',

		[breakpoints.down('sm')]: {
			display: 'none',
			width: 37,
			height: 37,
		},
	},
}))
