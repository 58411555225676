import { URLS } from './api-urls'
import { Response } from 'interface/api/response-interface'
import { GET, PUT, PATCH, POST } from './api-requests'
import IUser from 'interface/user/user-interface'

export const fetchUserNameByEmail = async (email: string) => {
	const { data, status } = await GET(`${URLS.USER}s?email=${email}`)
	return { data, status }
}

export const getUser = async (
	payload: Record<string, string>
): Promise<Response> => {
	const { user_id, company_id } = payload
	const { data, status } = await GET(
		`${URLS.USER}s?user_id=${user_id}&company_id=${company_id}`
	)
	return { data, status }
}

export const editUser = async (userData: IUser): Promise<Response> => {
	const { data, status } = await PUT(URLS.EMPLOYEE, {
		...userData,
	})

	return { data, status }
}

export const getUserGoals = async (employee_id: string): Promise<Response> => {
	const { data, status } = await GET(`${URLS.GOALS}/${employee_id}`)
	return { data, status }
}

export const allowChangePassword = async (email: string): Promise<Response> => {
	const { data, status } = await PATCH(`${URLS.USER}s`, {
		email,
	})

	return { data, status }
}

export const intervalJobAction = async (payload: { user_id: string, loginAction: boolean }): Promise<Response> => {
	const { loginAction, user_id } = payload
	const { data, status } = await POST(`${URLS.SCHEDULER}/logout/${user_id}?loginAction=${loginAction}`, {})
	return data
}
