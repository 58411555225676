import React from "react"
import { TasksContainerWrapper } from './TasksContainerWrapper'
import { useMobileView } from './BlockagesStatus'
import { useTranslation } from "react-i18next"
import { ActionNameMapScreens } from 'utils/mapUrl'
import { useSelector } from "react-redux"
import { feedbackLoadingSelector } from "redux/feedback/feedback-selector"
import { Card } from 'components/common/MostRecent/SendCard'
import Paths from "enums/routes/path-names"

const MainScreenMobileShortcut = () => {
    const loading = useSelector(feedbackLoadingSelector)

    const { isMobile } = useMobileView()
    const { t } = useTranslation()

    const newPraiseCard = {
        actionName: ActionNameMapScreens.PRAISE,
        screenToReturn: '/dashboard',
    }
    const newFeedbackCard = {
        actionName: ActionNameMapScreens.FEEDBACK,
        screenToReturn: '/dashboard',
    }
    const newDebriefCard = {
        screenToReturn: Paths.DEBRIEF_PROCESS,
        actionName: ActionNameMapScreens.DEBRIEF,
    }


    return (
        <React.Fragment>
            <TasksContainerWrapper
                loading={loading}
                greenChip={isMobile}
                containerDescription={
                    t(ActionNameMapScreens.PRAISE)
                }
            >
                <Card {...newPraiseCard} />
            </TasksContainerWrapper>
            <TasksContainerWrapper
                loading={loading}
                containerDescription={
                    t(ActionNameMapScreens.FEEDBACK)
                }
            >
                <Card {...newFeedbackCard} />
            </TasksContainerWrapper>

            <TasksContainerWrapper
                loading={loading}
                containerDescription={t(ActionNameMapScreens.DEBRIEF)}
            >
                <Card {...newDebriefCard} />
            </TasksContainerWrapper>

        </React.Fragment>

    )
}

export default MainScreenMobileShortcut