import { useContext, useState } from 'react'
import { CircularProgress } from '@material-ui/core'

import useStyles from './styled'

import { AppDirectionContext } from 'context/languages-context'

interface Props {
	onAction?: () => void
	disabled?: boolean
	text: { primary: string; secondary: string }
	icon: any
	leftText: string
	loading: boolean
	switchedOn: boolean
}
const SlideButton = ({
	onAction,
	loading,
	text,
	icon,
	leftText,
	disabled = false,
	switchedOn,
}: Props) => {
	const [actionIsOn, setActionIsOn] = useState(switchedOn)
	const [secText, setSecText] = useState(switchedOn ? text.secondary : '')

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection, actionIsOn, disabled })

	const handleClick = () => {
		if (disabled) return
		onAction && onAction()
		setActionIsOn(true)
		setTimeout(() => {
			setSecText(text.secondary)
		}, 400)
	}
	const loader = (
		<div className={classes.progress}>
			<CircularProgress color='inherit' />
		</div>
	)
	return (
		<>
			<div className={classes.slideButton} onClick={handleClick} >
				<div className={classes.sec}>

					<span className={classes.secText}>
						{secText && loading
							? loader
							: (
								<span className={classes.fade}> {secText}</span>
							)}
					</span>

					<div className={classes.imgCon}>
						<img src={icon} />
					</div>
				</div>

				<div className={classes.pri}>
					<span style={{ marginLeft: leftText }} className={classes.priText}>
						{!actionIsOn && `${text.primary}`}
					</span>
				</div>
			</div>
		</>
	)
}

export default SlideButton