import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { isCompanySelectorLoading } from '../../redux/company/company.selector'
import { employeesActivitiesSelector } from 'redux/company/company.selector'
import { employeesSelector } from '../../redux/employee/employee.selector'
import { get_employees_activities } from 'redux/company/company-actions'

import buildQueryString from '../../utils/buildQueryString'
import { mapSelectedUsers } from './utils/mapSelectedUsers'
import getDirectEmployees from 'utils/getDirectEmployess'
import { RowDescription } from './utils/rowsDescription'
import { getRowColor } from './utils/getRowsColor'

import { FetchEmployeesActivities } from 'interface/api/get-payload.types'
import IUser, { Permissions } from 'interface/user/user-interface'
import { UserActivity } from 'redux/company/company-reducer'

export type ActivityUserAccess = Pick<IUser, '_id'> & { name: string, permissions: Permissions }

export type RowDescriptionKey = keyof typeof RowDescription

export type RowObject = {
	key: string
	value: {
		criteriaData: UserActivity
		color: string
		rowDescription: typeof RowDescription[RowDescriptionKey]
	}
}

interface Props {
	selectedEmployeeId: string
	manager: IUser
}
const usersChunk = 0

const useEmployeesActivities = ({ selectedEmployeeId, manager }: Props) => {
	const [currentUsers, setCurrentUsers] = useState<ActivityUserAccess[]>([])
	const [currentUsersChunk, setCurrentUsersChunk] = useState(usersChunk)

	const maxSelectedUsers = 6

	const dispatch = useDispatch()

	const employees = useSelector(employeesSelector)
	const employeesActivities = useSelector(employeesActivitiesSelector)
	const isCompanyLoading = useSelector(isCompanySelectorLoading)

	useEffect(() => {
		setCurrentUsersChunk(0)
	}, [selectedEmployeeId])

	useEffect(() => {
		if (!currentUsers.length || !manager?.company_id) return

		getEmployeesActivities()
	}, [manager, currentUsers])

	const managerEmployees = getDirectEmployees(employees, manager)

	const getSelectedUser = () => (managerEmployees.find(user => user._id === selectedEmployeeId))

	const managerEmployeesLength = (): number => {
		if (managerEmployees.length < maxSelectedUsers) return managerEmployees.length

		else return maxSelectedUsers
	}

	const restTableUsers = useMemo(() => {
		let remainingUsers = []
		for (let i = currentUsersChunk; i < managerEmployeesLength() + currentUsersChunk; i++) {
			if (selectedEmployeeId === managerEmployees[i]._id) continue
			if (manager._id === managerEmployees[i]._id) continue
			remainingUsers.push(managerEmployees[i])
		}
		return remainingUsers
	}, [managerEmployees, selectedEmployeeId, manager])

	useEffect(() => {
		const selectedUser = getSelectedUser()
		const displayOreder = [selectedUser!, manager, ...restTableUsers].filter(user => user)

		if (displayOreder.length) {
			const selectedUsers = mapSelectedUsers(displayOreder, maxSelectedUsers)

			setCurrentUsers(selectedUsers)
		}
	}, [employees, currentUsersChunk, selectedEmployeeId, manager])


	const getEmployeesActivities = () => {
		const payload: FetchEmployeesActivities = {
			company_id: manager?.company_id!,
			users_id: buildQueryString(currentUsers),
		}

		dispatch(get_employees_activities(payload))
	}

	const tableRows = useMemo((): RowObject[] => {
		if (!Object.keys(employeesActivities).length) return []
		const activitiesCriteria = Object.keys(employeesActivities)

		const rows = activitiesCriteria.map((key) => {
			const rowDescriptionKey = key as RowDescriptionKey
			return {
				key,
				value: {
					criteriaData: employeesActivities[key],
					color: getRowColor(rowDescriptionKey),
					rowDescription: RowDescription[rowDescriptionKey],
				}
			}
		})
		return rows
	}, [employeesActivities])

	const handleNextUsers = () => {
		const reachedMaxChunk = currentUsersChunk + maxSelectedUsers >= managerEmployees.length
		if (reachedMaxChunk) return

		setCurrentUsersChunk(prev => prev + 1)
	}

	const handleBackUsers = () => {
		const reachedStartPoint = currentUsersChunk - 1 < usersChunk
		if (reachedStartPoint) return

		setCurrentUsersChunk(prev => prev - 1)
	}

	return {
		currentUsers,
		employeesActivities,
		isLoading: isCompanyLoading,
		handleBackUsers,
		handleNextUsers,
		tableRows
	}
}
export default useEmployeesActivities