import { AppDirectionContext } from 'context/languages-context'
import { FC, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './useStyles'

type SelfAnalisysInputProps = {
	value: string
	isBtnDisabled?: boolean
	isInputDisabled?: boolean
	onClick: () => void
	onChange: (val: React.ChangeEvent<HTMLInputElement>) => void
}

export const ChatBotSelfAnalisysInput: FC<SelfAnalisysInputProps> = ({
	isBtnDisabled,
	isInputDisabled,
	value,
	onChange,
	onClick,
}) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const { t } = useTranslation()
	const input = useRef<HTMLInputElement>(null)
	useEffect(() => {
		if (value) return
		if (input.current) input.current?.focus()
	}, [isInputDisabled, value])

	return (
		<div className={classes.textInputWrapper}>
			<input
				autoFocus
				ref={input}
				value={value}
				disabled={isInputDisabled}
				onChange={onChange}
				placeholder={t('Write_here')}
			/>
			<div
				role='button'
				style={{
					color: isBtnDisabled ? '' : '#5B4EB5',
				}}
				onClick={() => !isBtnDisabled && onClick()}
			>
				{t('send')}
			</div>
		</div>
	)
}
