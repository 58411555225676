import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
	boxesExtent: {
		height: '100%',
		width: '100%',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'justify-content',
		gap: '5%',

		'& > div': {
			color: 'white',
			width: '100%',
			flex: 1,
			cursor: 'pointer',
			borderRadius: '14px',
			fontSize: '1rem',

			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},

		[breakpoints.up('md')]: {
			'align-items': 'center',
			'& > div': {
				width: '50%',
				fontSize: '1rem',
			},
		},
	},
	wrapper: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		width: '100%',
		[breakpoints.down('sm')]: {
			width: '85%',
		},
	},
	boxWrapper: {
		// height: '55vh',
		display: 'flex',
		height: '100%',
		justifyContent: 'space-around',
		flexDirection: 'column',
		alignItems: 'center',
		[breakpoints.down('sm')]: {
			height: '43vh',
		},
	},
	slider: {
		marginTop: '5%',
		'& > .MuiSlider-mark': {
			height: 30,
			marginTop: 20,
			backgroundColor: '#A9A9A9',
		},
		'& > .MuiSlider-markActive': {
			height: 30,
			marginTop: 20,
			backgroundColor: '#6495ED',
		},
		'& > .MuiSlider-markLabel': { marginTop: 20, fontSize: 16 },

		[breakpoints.down('sm')]: {
			marginTop: 15,
		},
	},
	dartIconStyle: {
		width: '120px',
		height: '120px',
		marginTop: '20px',
	},
	imageWrapper: {
		display: 'flex',
		height: '100%',
		alignItems: 'center',
		// marginTop: '10%',
	},
}))
