import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from '../useStyles'
import LoginForm from 'components/LoginForm'
import { Avatar } from '@material-ui/core'
import { SolveLoginLogo } from '../SolveLoginLogo'

type Props = {
	userName: string
	userEmail: string
	showHasAcceptedPolicyTerm?: boolean
}

const avatarFullNameContainer: React.CSSProperties = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	maxWidth: '383px',
	marginBottom: '5%',
}
const avatarStyles: React.CSSProperties = {
	width: '74px',
	height: '74px',
	backgroundColor: '#34AB53',
	fontSize: '28px',
	fontWeight: 'bold',
}
const welcomeDiv = {
	fontWeight: 'bold',
	fontSize: '36px',
	color: '#46484a',
}

export const ChangePassword: FC<Props> = ({ userName, ...rest }) => {
	const classes = useStyles()
	const { t } = useTranslation()
	return (
		<div className={classes.loginContainer}>
			<SolveLoginLogo />
			{userName && (
				<div style={avatarFullNameContainer}>
					<Avatar style={avatarStyles}>{userName.charAt(0).toUpperCase()}</Avatar>
					<div style={welcomeDiv}>
						{t('Welcome')} {userName.toUpperCase()}
					</div>
				</div>
			)}

			<h1 className={classes.loginTitle}>{t('change_your_password')}</h1>

			<div className={classes.formContainer}>
				<LoginForm
					{...{
						displayAcceptedPolicyTerm: rest.showHasAcceptedPolicyTerm,
						isChangePasswordScreen: true,
						userChangingPasswordEmail: rest.userEmail,
					}}
				/>
			</div>
		</div>
	)
}
