import { useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux"

import { teamSelector } from 'redux/teams/teams-selector'
import { userSelector } from 'redux/user/user-selector'
import {
    get_team_tasks,
    create_team_task,
    update_team_tasks,
    toggle_task_done,
    delete_task
} from 'redux/teams/teams-actions'

import { SortingOrder } from 'enums/teams/sortingOrder.enum'
import IUser from 'interface/user/user-interface'
import { ITeamTask } from 'interface/teams/teams-interface'
import { employeesSelector } from 'redux/employee/employee.selector'

type Props = {
    managerGoalsScreen?: boolean
}
const useTeamsRequests = ({ managerGoalsScreen }: Props) => {
    const user = useSelector(userSelector)
    const { _id: team_id } = useSelector(teamSelector)
    const employees = useSelector(employeesSelector)

    const dispatch = useDispatch()

    const getTeamTasks = useCallback(
        (tasksSortingOrder: SortingOrder) => {
            const user_id = managerGoalsScreen ? user._id : getDirectManager()!
            dispatch(get_team_tasks({ order: tasksSortingOrder, user_id }))
        }, [user])

    const createTeamTask = useCallback(
        (task: ITeamTask<IUser>) => {
            dispatch(create_team_task({ task, team_id, user_id: user._id }))
        }, [user, team_id])

    const updateTeamTasks = useCallback(
        (task: ITeamTask<IUser>) => {
            dispatch(update_team_tasks({ user_id: user._id, team_id, task }))
        }, [user, team_id])

    const toggleTaskDoneByUser = useCallback(
        (task_id: string) => {
            dispatch(toggle_task_done({ user_id: user._id, team_id, task_id }))
        }, [user, team_id])

    const deleteTask = useCallback(
        (task_id: string) => {
            if (!team_id) return
            dispatch(delete_task({ user_id: user._id, team_id, task_id }))
        }, [user, team_id])

    const getDirectManager = () => {
        const unassigned = 'unassigned'
        const isUnassigned = user.statusInfo.directManager.trim() === unassigned
        if (isUnassigned) return user._id
        else return employees.find(u => user.statusInfo.directManager === u.securityInfo.email)?._id
    }

    return { getTeamTasks, createTeamTask, updateTeamTasks, toggleTaskDoneByUser, deleteTask }
}

export default useTeamsRequests