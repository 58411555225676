import { FC, useState, useCallback, useRef, useEffect, useContext } from 'react'
import SocketContext from 'context/socket-context'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ClickAwayListener, IconButton } from '@material-ui/core'

import { newNotificationsSelector } from 'redux/notifications/notifications-selector'
import { userSelector } from 'redux/user/user-selector'
import { setNotification } from 'redux/notifications/notifications-reducer'
import { get_praises } from 'redux/praise/praise-actions'
import { get_feedbacks } from 'redux/feedback/feedback-actions'

import Notifications from 'components/Notifications'
import UserAvatar from 'components/common/Avatar'
import UserPersonalMenu from '../PersonalProfileMenu'
import { Events } from 'screens/Main/index'

import {
	INotification,
	NotificationTypes,
} from 'interface/notifications/notifications-interface'
import notificationRead from 'assets/notificationsRead.svg'
import notificationsUnread from 'assets/notificationsUnread.svg'
import arrowDownIcon from 'assets/arrowDownIcon.svg'
import arrowUp from 'assets/arrowUp.svg'
import { Icon, UserInformationType } from '../'

const useStyles = makeStyles<Theme>(() => ({
	userInformation: {
		userSelect: 'none',
		display: 'flex',
	},
	userNameAndRoleContainer: {
		alignSelf: 'center',
		color: '#46484A',
	},
	userName: {
		fontSize: 14,
		fontWeight: 'bold',
		textTransform: 'capitalize',
	},
	userRoleContainer: {
		'& > div::first-letter': {
			textTransform: 'uppercase',
		},
	},
	userRole: {
		width: '100%',
		fontSize: 11,
		display: 'flex',
		justifyContent: 'space-between',
	},
	userRoleNotPointer: {
		fontSize: 13,
		display: 'flex',
		justifyContent: 'space-between',
	},
	role: {
		cursor: 'pointer',
	},
	cardsDropdownContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		'& > div:nth-of-type(1)': {
			cursor: 'pointer',
		},
	},
	iconsContainer: {
		position: 'relative',
		display: 'flex',
	},
	icon: {
		width: 22,
		height: 22,
	},
	notificationsIconButton: {
		padding: 0,
		marginBottom: '-4px',
	},
	notificationsIcon: {
		width: '22px',
		height: '22px',
	},
	userImageStyle: {
		height: '40px',
		width: '40px',
	},
	arrowDesktop: {
		height: '60%',
		width: 'fit-content',
	},
	arrowMobile: {
		marginLeft: '8px',
		height: '150%',
		width: '15px',
	},
	backdrop: {
		position: 'absolute',
		right: '0',
		top: '0',
		backgroundColor: 'tranparent',
		height: '100vh',
		width: '100vw',
	},
}))

const UserInformation: FC<UserInformationType> = ({ isMobile }) => {
	const [isEditingCardOpen, setIsEditingCardOpen] = useState(false)

	const classes = useStyles()
	const icon = isEditingCardOpen ? arrowUp : arrowDownIcon
	const cardsDropdownContainerRef = useRef<HTMLDivElement>(null)

	const userinfo = useSelector(userSelector)

	const handleCloseMenu = useCallback(() => {
		setIsEditingCardOpen(false)
	}, [])

	const handleMenuToggle = useCallback(
		event => {
			if (!isEditingCardOpen) {
				event.stopPropagation()
				setIsEditingCardOpen(true)
				return
			}
			setIsEditingCardOpen(false)
		},

		[setIsEditingCardOpen, isEditingCardOpen]
	)
	return (
		<div
			style={{
				cursor: isEditingCardOpen ? 'unset' : 'pointer',
				paddingInlineEnd: '5%',
			}}
			className={classes.userInformation}
			onClick={handleMenuToggle}
		>
			<UserAvatar className={classes.userImageStyle} userInfo={userinfo} />
			{/* <div
				style={{ margin: isMobile ? '0 2px 0 2px' : '0 9px 0 9px' }}
				className={classes.userNameAndRoleContainer}
			> */}
			<div style={{ position: 'relative' }}>
				{/* <div className={classes.userName}>
						{!isMobile && `${firstName} ${lastName}`}
					</div>
					<div className={classes.userRole}>
				<div className={classes.role}>{!isMobile && `${title}`}</div> */}
				{/* <Icon
								icon={icon}
								style={isMobile ? classes.arrowMobile : classes.arrowDesktop}
								alt='arrow Icon'
								regularIcon
								/>
							</div> */}
				{isEditingCardOpen && (
					<div className={!isEditingCardOpen ? classes.role : ''}>
						<div className={classes.backdrop} onClick={handleCloseMenu}></div>
						<ClickAwayListener
							disableReactTree
							onClickAway={isEditingCardOpen && !isMobile ? handleCloseMenu : () => {}}
						>
							<div
								className={classes.cardsDropdownContainer}
								ref={cardsDropdownContainerRef}
								onClick={(e: any) => {
									e.stopPropagation()
								}}
							>
								{/* {isExtraUserInfoOpen && (
										<UserExtraInformation
											setIsExtraUserInfoOpen={setIsExtraUserInfoOpen}
											setIsEditingCardOpen={setIsEditingCardOpen}
											currentUserAbbriviatedName={getUserInitials(firstName, lastName)}
											currentUserFullName={`${firstName} ${lastName}`}
											title={title}
										/>
									)} */}
								<UserPersonalMenu setIsEditingCardOpen={setIsEditingCardOpen} />
							</div>
						</ClickAwayListener>
					</div>
				)}
			</div>
			{/* </div> */}
			{/* <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '3vw' }}>
				<div>
					Lvl<span style={{ color: '#C197DA' }}>1</span> &nbsp;&nbsp;&nbsp;&nbsp;750
					<span style={{ color: '#C197DA' }}> XP</span>
				</div>
				<ProgressBar
					completed={800}
					maxCompleted={1000}
					customLabel=' '
					width='10vw'
					height='1vh'
					bgColor='#C197DA'
					baseBgColor='#CBCBCB'
				/>
				<div style={{ fontSize: '14px', marginLeft: 'auto' }}>
					<span style={{ color: '#C197DA', fontSize: '14px' }}>300 </span>
					to the next level
				</div>
			</div> */}
		</div>
	)
}

export default UserInformation
type NotificationProps = {
	isMobile?: boolean
}
export const Notification: FC<NotificationProps> = ({ isMobile }) => {
	const [showNotifications, setShowNotifications] = useState(false)
	const newNotifications = useSelector(newNotificationsSelector)
	const user = useSelector(userSelector)
	const classes = useStyles()
	const dispatch = useDispatch()
	const socket = useContext(SocketContext)

	const handlePrivateEvent = (notification: INotification) => {
		// here do the private events objectives.
		if (notification.receiver_id === user._id) {
			if (notification.type === NotificationTypes.FEEDBACK) {
				const payload = {
					company_id: user.company_id!,
					user_id: user._id,
				}
				dispatch(get_feedbacks(payload))
			}

			if (notification.type === NotificationTypes.PRAISE) {
				const payload = {
					company_id: user.company_id!,
					user_id: user._id,
				}
				dispatch(get_praises(payload))
			}
			dispatch(setNotification(notification))
		}
	}

	useEffect(() => {
		socket?.on(Events.PRIVATE_EVENT, handlePrivateEvent)

		return () => {
			// before the component is destroyed
			// unbind all event handlers used in this component
			socket?.off(Events.PRIVATE_EVENT, handlePrivateEvent)
		}
	}, [socket, user._id, handlePrivateEvent])

	const handleCloseNotifications = useCallback(e => {
		setShowNotifications(false)
	}, [])
	const handleOpenNotifications = useCallback(
		() => setShowNotifications(prev => !prev),
		[]
	)
	const notificationIcon =
		newNotifications.length > 0 ? notificationsUnread : notificationRead

	return (
		<>
			<div
				className={classes.iconsContainer}
				style={{ marginTop: isMobile ? '' : '0.6rem' }}
			>
				<ClickAwayListener onClickAway={handleCloseNotifications}>
					<div style={{ position: 'relative', marginRight: 7 }}>
						<IconButton
							onClick={handleOpenNotifications}
							className={classes.notificationsIconButton}
						>
							<Icon
								style={classes.notificationsIcon}
								icon={notificationIcon}
								alt='notification icon'
							/>
						</IconButton>
						{showNotifications && (
							<Notifications closeNotification={handleCloseNotifications} />
						)}
					</div>
				</ClickAwayListener>
			</div>
		</>
	)
}
