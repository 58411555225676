import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { companyTodosSelector } from 'redux/todos/todos.selector'
import {
	get_user_todos,
	load_more_tasks,
	delete_task,
	restore_task,
	update_todos,
	create_todos,
	tasks_viewed,
	update_task_status,
} from 'redux/todos/todos-actions'
import { userSelector } from 'redux/user/user-selector'

import IUser from 'interface/user/user-interface'
import { Task } from 'interface/todos/todos-interface'
import { useManagerObject } from 'utils/getManager'
import { TodoStatusOptions } from 'enums/todos/todos.enum'

const useTodoReuqests = (
	selectedEmployee: IUser,
	isManagerViewing?: boolean
) => {
	const dispatch = useDispatch()

	const { company_id, _id: user_id } = useSelector(userSelector)
	const userTodos = useSelector(companyTodosSelector)
	const manager = useManagerObject(selectedEmployee)

	const [sender, receiver] = useMemo(() => {
		const receiver = isManagerViewing ? selectedEmployee?._id : manager._id
		const sender = isManagerViewing ? manager._id : selectedEmployee?._id
		return [sender, receiver]
	}, [isManagerViewing, selectedEmployee, userTodos])

	const fetchUserTodos = () =>
		dispatch(get_user_todos({ user_id: selectedEmployee?._id }))

	const lazyloadTodos = (pageNumber: number, managerSide: boolean) =>
		dispatch(
			load_more_tasks({
				pageNumber,
				todos_id: userTodos?._id!,
				managerSide: !!managerSide!,
			})
		)

	const createNewTodos = (todo: Task, managerSide: boolean) =>
		dispatch(
			create_todos({
				company_id,
				sender,
				receiver,
				todo: {
					employee: selectedEmployee?._id,
					manager: manager._id,
					...userTodos,
					tasks: [todo],
				},
				user_id: managerSide ? manager._id : selectedEmployee._id,
			})
		)

	const updateTasks = (todo: Task, managerSide?: boolean) =>
		dispatch(
			update_todos({
				user_id: managerSide ? manager._id : selectedEmployee._id,
				company_id,
				todos_id: userTodos?._id!,
				receiver: receiver ? receiver : sender,
				sender,
				todo,
			})
		)

	const deleteTask = (todo_id: string) =>
		dispatch(delete_task({ company_id, todo_id, receiver, sender }))

	const restoreTask = (todo_id: string) =>
		dispatch(restore_task({ company_id, todo_id, sender, receiver }))

	const viewedTasks = (viewer: string) =>
		dispatch(tasks_viewed({ company_id, todos_id: userTodos?._id!, viewer }))

	const changeTaskStatus = (status: TodoStatusOptions, todo_id: string) =>
		dispatch(update_task_status({ company_id, todo_id, status }))

	return {
		fetchUserTodos,
		lazyloadTodos,
		createNewTodos,
		updateTasks,
		deleteTask,
		restoreTask,
		viewedTasks,
		changeTaskStatus
	}
}

export default useTodoReuqests
