import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
import colors from 'utils/colors'

interface StyleProps {
	isDisabled: boolean
}

export default makeStyles<Theme, StyleProps>(({ palette, breakpoints }) => ({
	container: {
		boxSizing: 'border-box',
		width: '533px',
		margin: '0',
		[breakpoints.down('lg')]: {
			width: '30%',
		},
		[breakpoints.down('md')]: {
			width: '50%',
		},
		[breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	wrapper: {
		width: '100%',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		marginTop: '24px',
	},
	passwordEyeBtn: {
		position: 'absolute',
		top: '39px',
		right: '6px',
		outline: 'none',
		border: 'none',
		backgroundColor: 'inherit',
		cursor: 'pointer',
		padding: '10px',
		[breakpoints.down('lg')]: {
			top: '33px',
		},
		[breakpoints.down('xs')]: {
			top: '31px',
		},
	},
	passwordEye: {
		width: '20px',
		height: '16px',
	},
	button: {
		height: '54px',
		border: 'none',
		borderRadius: '10px',
		color: palette.common.white,
		backgroundColor: ({ isDisabled }) =>
			isDisabled ? palette.action.disabled : palette.secondary.main,
		cursor: ({ isDisabled }) => (isDisabled ? 'auto' : 'pointer'),
		fontFamily: 'Sora, sans-serif',
		fontSize: '18px',
		fontWeight: 600,
		lineHeight: '22px',
		[breakpoints.down('lg')]: {
			height: '48px',
			fontSize: '16px',
		},
	},
	cantLoginWrapper: {
		fontSize: '14px',
		fontWeight: 'bold',
		lineHeight: '18px',
		textAlign: 'center',
		marginTop: '30px',
		[breakpoints.down('lg')]: {
			fontSize: '13px',
			marginTop: '25px',
		},
		'& span:first-child': {
			color: palette.secondary.main,
		},
	},
	hrLink: {
		color: `${palette.secondary.main}!important `,
		cursor: 'pointer',
	},
	error: {
		color: palette.error.main,
		marginTop: '10px',
		[breakpoints.down('lg')]: {
			fontSize: '13px',
			marginBottom: 0,
			marginTop: '8px',
		},
	},
	passwordRules: {
		marginTop: '2%',
		fontSize: '0.8rem',
	},
	langs: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		'& >p': {
			cursor: 'pointer',
			color: colors.saydoGreen,
			'&:hover': {
				color: 'black'
			},
			'& >:first-child': {
				marginLeft: '34% 11% 0 1%'
			}
		}
	}
}))
