import { FC, useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import useStyles from './styles'
import { AppDirectionContext } from 'context/languages-context'

interface Props {
	title: string
	description: string | JSX.Element
	imageSrc: string
	textColor?: string
	backgroundColor: string
	imageWidth?: number
	imageHight?: number
	boxShadow?: string
	isDashboard?: boolean
}
const LONG_TEXT = 80
const StatusInfoCard: FC<Props> = ({
	title,
	description,
	imageSrc,
	textColor,
	backgroundColor,
	imageHight,
	imageWidth,
	isDashboard = false,
	boxShadow,
}) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const isLongText = `${description}`.length >= LONG_TEXT

	const classes = useStyles({
		isRtlDirection,
		backgroundColor,
		boxShadow,
		textColor,
		imageHight,
		imageWidth,
		isDashboard,
		isLongText
	})

	return (
		<Grid item xs={11} sm={8} className={classes.container}>
			<Typography className={classes.title}>{title}</Typography>
			<div className={classes.descriptionContainer}>
				<span>{description}</span>
			</div>
			<img
				src={imageSrc}
				className={classes.image} />
		</Grid>
	)
}

export default StatusInfoCard
