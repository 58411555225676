import { FC, useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import { Theme } from '@mui/material'
import UserAvatar from 'components/common/Avatar'
import chatIcon from 'assets/chatIcon.svg'
import { AppDirectionContext } from '../../context/languages-context'

type RenderChatCardProps = {
	text: string
	isChatQuestion?: boolean
	userImg?: string
}
const stylesRtl = {
	left: '-32px',
	// right: '-32px',
}
const stylesLtr = {
	// left: '-32px',
	right: '-32px',
}
export const RenderChatCard: FC<RenderChatCardProps> = props => {
	const { isRtlDirection } = useContext(AppDirectionContext)

	const { userImg, text = '', isChatQuestion } = props

	const classes = useStyles({ isChatQuestion })

	const questionStyles = isRtlDirection ? stylesLtr : stylesRtl
	const refularStyle = isRtlDirection ? stylesRtl : stylesLtr

	const chatQuestionStyles = isChatQuestion ? questionStyles : refularStyle

	return (
		<div className={classes.chatBotCard}>
			<div style={chatQuestionStyles}>
				<UserAvatar className={classes.avatar} previewImage={userImg || chatIcon} />
			</div>
			{text}
		</div>
	)
}

export const useStyles = makeStyles<
	Theme,
	Pick<RenderChatCardProps, 'isChatQuestion'>
>({
	chatBotCardWrapper: {
		display: 'flex',
		flexDirection: 'column',
	},

	chatBotCard: {
		position: 'relative',

		width: 'fit-content',
		maxWidth: '310.71px',

		padding: '20px',
		marginTop: '2%',
		wordBreak: 'break-word',

		alignSelf: ({ isChatQuestion }) => (isChatQuestion ? 'start' : 'end'),

		background: ({ isChatQuestion }) =>
			isChatQuestion ? 'rgba(199, 193, 241, 0.1)' : 'transparent',
		border: '1px solid #D8D8D8',
		borderRadius: '16.8px',

		'& > div': {
			position: 'absolute',
			bottom: 0,
		},
	},
	avatar: {
		width: '28px',
		height: '28px',
	},
})
