import classes from '../../FeedbackProcess/ActionPlan/styles.module.css'
import calendarIcon from '../../../assets/purpleCalendarIcon.svg'
import purpleX from 'assets/purpleX.svg'
import purpleV from 'assets/purpleV.svg'
import dayjs from 'dayjs'
import { makeStyles, TextareaAutosize, Theme } from '@material-ui/core'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppDirectionContext } from 'context/languages-context'
import DatePicker from 'components/Bot/Modal/DatePicker'
import { CloseIcon } from 'components/common/CloseIcon'
import { IThemeOptions } from 'theme'
interface Props {
	onUpdateEvent: (value: string | Date, property: string) => void
	onClose: () => void
	onSave: () => void
	isFocus?: boolean
	event: any
	hideCalendar?: boolean
}
const EventInput = ({
	event,
	hideCalendar,
	onUpdateEvent,
	onClose,
	onSave,
}: Props) => {
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
	const { t } = useTranslation()
	const [focus, setFocus] = useState(false)
	const maximumMessageLength = 250
	const { isRtlDirection } = useContext(AppDirectionContext)
	const innerClasses = useInnerStyles({ isRtlDirection })

	const inputRef = useRef<any>(null)

	useEffect(() => {
		inputRef && inputRef.current.focus() // set the input to Focus
		inputRef.current.selectionStart = inputRef.current.value.length //place the cursor at the end of the row
	}, [])

	const today = new Date()
	const dayClickHandler = (day: Date) => {
		if (day > today) return
		onUpdateEvent(day, 'date')
		setIsDatePickerOpen(false)
	}

	const displayCalanderIcon = (
		<img
			className={`${classes.calenderIcon} ${innerClasses.calenderIcon}`}
			src={calendarIcon}
			onClick={e => {
				e.stopPropagation()
				setIsDatePickerOpen(true)
			}}
		/>
	)
	return (
		<div className={innerClasses.eventInput}>
			{!isDatePickerOpen && (
				<>
					<div
						style={{ margin: '0', width: '100%' }}
						className={
							isRtlDirection ? `${classes.action} ${classes.rtl}` : classes.action
						}
					>
						{!hideCalendar ? null : displayCalanderIcon}{' '}
						{/**TODO: Fix this line it doesnt make sense */}
						<TextareaAutosize
							ref={inputRef}
							minRows={1}
							aria-label='action'
							value={event.description.length ? event.description : ''}
							onChange={(e: any) => onUpdateEvent(e.target.value, 'description')}
							className={`${classes.actionInput} ${innerClasses.actionInput}`}
							placeholder={t('debrief.event_input_placeholder')}
							maxLength={maximumMessageLength}
							onFocus={() => setFocus(true)}
							onBlur={() => setFocus(false)}
						/>
						<div className={`${innerClasses.buttonsContainer}`}>
							<div
								className={innerClasses.button}
								onClick={() => onClose()}
								title='Delete'
							>
								<img src={purpleX} alt='Delete' />
							</div>
							<div
								className={innerClasses.button}
								onClick={() => onSave()}
								title='Save'
							>
								<img src={purpleV} alt='delete' />
							</div>
						</div>
					</div>
					<div
						style={{
							width: '98%',
							textAlign: isRtlDirection ? 'left' : 'right',
						}}
						className={classes.dueDate}
					>
						{focus && event.description.length === maximumMessageLength && (
							<div>{t('max_task_input_length')} </div>
						)}
						{/* {event.date === undefined
							? t('debrief.no_event_date')
							: t('debrief.event_input_date') +
							  ' ' +
							  dayjs(event.date).format('DD/MM/YYYY')} */}
					</div>
				</>
			)}
			{isDatePickerOpen && (
				<>
					<div
						onClick={e => {
							e.stopPropagation()
						}}
						className={classes.datepicker}
					>
						<div className={classes.closeButton}>
							<CloseIcon
								width='1.5rem'
								height='1.5rem'
								handleClick={() => {
									setIsDatePickerOpen(false)
								}}
							/>
						</div>
						<DatePicker
							disabledDays={{ after: today }}
							selectedDay={new Date()}
							handleDayClick={(day: Date) => dayClickHandler(day)}
						/>
					</div>
				</>
			)}
		</div>
	)
}

export default EventInput
const useInnerStyles = makeStyles<
	Theme & IThemeOptions,
	{ isRtlDirection: boolean }
>(({ breakpoints, palette }) => ({
	calenderIcon: {
		backgroundColor: '#f4f3fc !important',
		border: '1px solid #D8D8D8',
		width: '2.5rem',
		height: '2.5rem',
		margin: '0',
		marginInlineStart: ({ isRtlDirection }) =>
			isRtlDirection ? '2% !important' : '0 !important',
		marginInlineEnd: ({ isRtlDirection }) =>
			isRtlDirection ? '0 !important' : '2% !important',
	},
	eventInput: {
		direction: ({ isRtlDirection }) => (isRtlDirection ? 'ltr' : 'unset'),
		paddingTop: '8px',
		alignSelf: 'center',
		width: '100%',
		zIndex: 2,
	},
	button: {
		display: 'flex',
		'& img': {
			backgroundColor: 'transparent',
			width: '1.47rem',
			height: '1.47rem',
		},
	},
	actionInput: {
		textAlign: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
		borderRadius: ({ isRtlDirection }) =>
			isRtlDirection ? '0 10px 10px 0' : '10px 0 0 10px',
		height: 'auto !important',
		backgroundColor: '#f4f3fc',
		paddingBottom: '3.5%',
		border: '1px solid #D8D8D8',
		borderRight: ({ isRtlDirection }) =>
			isRtlDirection ? '1px solid #D8D8D8' : 'none',
		borderLeft: ({ isRtlDirection }) =>
			isRtlDirection ? 'none' : '1px solid #D8D8D8',
	},
	buttonsContainer: {
		borderRadius: ({ isRtlDirection }) =>
			isRtlDirection ? '10px 0 0 10px' : '0 10px 10px 0',
		border: '1px solid #D8D8D8',
		backgroundColor: '#f4f3fc',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: ({ isRtlDirection }) =>
			isRtlDirection ? 'row-reverse' : 'row',
		borderRight: ({ isRtlDirection }) =>
			isRtlDirection ? 'none' : '1px solid #D8D8D8',
		borderLeft: ({ isRtlDirection }) =>
			isRtlDirection ? '1px solid #D8D8D8' : 'none',
	},
}))
