import { FC } from 'react'

import Switch from '@mui/material/Switch'
import { CustomSwitchColor } from './props'

type BasicSwitchesProps = {
	value: boolean
	handleClick: (val: boolean) => void
	color?: CustomSwitchColor
}

export const BasicSwitches: FC<BasicSwitchesProps> = ({
	value,
	handleClick,
	color
}) => {
	return (
		<div>
			<Switch
				color={color ? color : 'primary'}
				{...label}
				checked={Boolean(value)}
				onChange={e => {
					handleClick(!!Boolean(e.target.checked))
				}}
			/>
		</div>
	)
}

const label = { inputProps: { 'aria-label': 'Switch demo' } }
