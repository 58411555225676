import { ChangeEvent, FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import './style.css'

import AppropriateInputArea from './AppropriateInputArea'
import { LessonsManagementInputType } from '../props'

const LessonsManagementInput = ({
	itemDescription,
	label,
	isEdit,
	className,
	onValueChanged,
	maxLength
}: LessonsManagementInputType) => {
	const { t } = useTranslation()
	const inputRef = useRef<any>(null)

	useEffect(() => {
		if (!inputRef.current) return

		inputRef.current.value = itemDescription
		inputRef.current.selectionStart = itemDescription?.length
	}, [itemDescription])

	const handleChanage = ({
		target: { value },
	}: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
		if (!inputRef.current) return

		inputRef.current!.value = value
		onValueChanged?.(inputRef.current!.value)
	}

	const inputElem = (
		<AppropriateInputArea
			inputRef={inputRef}
			className={className}
			textareaInput={isEdit}
			handleChanage={handleChanage}
			maxLength={maxLength}
		/>)

	if (isEdit) return inputElem

	return (
		<LessonsManagementInput.Label label={t(`${label}`)}>
			{inputElem}
		</LessonsManagementInput.Label>
	)
}

type LabelProps = {
	label: string
}
const LabelInput: FC<LabelProps> = ({ label, children }) => {
	return (
		<label>
			{label}
			{children}
		</label>
	)
}
LessonsManagementInput.Label = LabelInput

export default LessonsManagementInput
