import useStyles from '../styles'
import CompanyBuilder from '../../CompanyBuilder/Builder'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../common/Button'
import xIcon from '../../../assets/xIcon.svg'
import { useEffect } from 'react'
import { get_employees } from 'redux/employee/employee-actions'
import { send_emails, send_email_to_role } from 'redux/company/company-actions'
import { employeesSelector } from 'redux/employee/employee.selector'
import { Roles } from 'enums/user/user-roles'
import IUser from 'interface/user/user-interface'
import { Company } from 'interface/company/company-interface'
import { IStoreRootState } from 'interface/redux'

interface Props {
	company: any
	onClose: () => void
	error: string
	onMsg: (status: boolean, msg: string) => void
}
const EditEmployees = ({ onClose, company, onMsg, error }: Props) => {
	const employees = useSelector(employeesSelector)
	const isHrNotified = useSelector(
		(state: IStoreRootState) => state.company.settings.isHrNotified
	)
	const loading = useSelector((state: IStoreRootState) => state.company.loading)

	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(get_employees({ company_id: company._id }))
	}, [])
	const classes = useStyles({})
	console.log(company)
	const onLaunch = async () => {
		const hrArray = employees.filter((e: IUser) => e.statusInfo.role === Roles.HR)
		const hrEmails = hrArray.map((hr: IUser) => hr.securityInfo.email)

		if (isHrNotified || company.settings.isHrNotified) {
			onMsg(false, `You already notified the HR managers.`)
			return
		}
		if (!hrEmails.length) {
			onMsg(false, `Could not find HR email on the employee list.`)
			return
		}
		dispatch(send_email_to_role({ company_id: company._id, role: Roles.HR }))
	}
	useEffect(() => {
		console.log(error)
		error && onMsg(false, `Server error - could not send HR emails ${error}`)
	}, [error])

	useEffect(() => {
		!loading && isHrNotified && onMsg(false, `Email sent to the HR managers`)
	}, [dispatch])

	return (
		<>
			<button
				className={`${classes.actionButton} ${classes.closeButton}`}
				onClick={onClose}
			>
				<img src={xIcon} />
			</button>
			<button
				className={classes.actionButton}
				onClick={() => {
					onLaunch()
				}}
			>
				<span className={classes.actionName}> Launch </span>
			</button>
			<CompanyBuilder
				companyLanguage={company.language}
				paidLicense={company.license}
				companyName={company.companyName}
				company_id={company._id}
				height='35vh'
			/>

			{/* <div
				style={{ width: '100%', justifyContent: 'center' }}
				className={`${classes.flexCenter} ${classes.formButtonsContainer}`}
			>
				<Button onClick={onClose}>Back</Button>
			</div> */}
		</>
	)
}

export default EditEmployees
