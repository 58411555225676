import { FC } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

type IconPropsType = {
	icon: string
	alt: string
	greyBackground?: boolean
	regularIcon?: boolean
	style?: string
	isUploadFile?: boolean
	handleClick?: () => void
	uploadFileClick?: (image: string) => void
}
export const Icon: FC<IconPropsType> = ({
	icon,
	alt,
	greyBackground,
	regularIcon,
	style,
	isUploadFile,
	handleClick,
	uploadFileClick,
}) => {
	const classes = useStyles()

	const handleChange = (e: any) => {
		if (e.target.files.length) {
			uploadFileClick!(e.target.files[0])
		}
	}

	const styles = [
		regularIcon ? classes.regularIcon : classes.iconContainer,
		greyBackground && classes.greyBackground,
		handleClick && classes.clickable,
	]
		.filter(Boolean)
		.join(' ')

	return (
		<div className={styles}>
			{!isUploadFile ? (
				<img src={icon} onClick={handleClick} alt={alt} className={style} />
			) : (
				<label htmlFor='upload-button'>
					<img src={icon} onClick={handleClick} alt={alt} className={style} />
					<input
						id='upload-button'
						style={{ display: 'none' }}
						onChange={handleChange}
						type='file'
					/>
				</label>
			)}
		</div>
	)
}
export type UserInformationType = {
	firstName: string
	lastName: string
	title: string
	isMobile: boolean
}

export type HeaderPropsTypes = {
	firstName: string
	lastName: string
	title: string
	handleOpenMobileMenu: () => void
	isMenuOpen: boolean
}
const useStyles = makeStyles<Theme>(() => ({
	regularIcon: {
		width: 'auto',
		height: 'auto',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	iconContainer: {
		height: '40px',
		width: '40px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&:not(:last-child)': {
			marginInlineEnd: 41,
		},
	},
	clickable: {
		cursor: 'pointer',
		width: 'auto',
	},
	greyBackground: {
		backgroundColor: '#E9E8F1',
	},
}))
