import { createAsyncThunk } from '@reduxjs/toolkit'
import { Req } from 'enums/req/req.enum'
import { IResponseGetNotifications } from 'interface/api/post-response'
import {
	INotification,
	NOTIFICATIONS_SKIP_COUNT,
} from 'interface/notifications/notifications-interface'
import { IStoreRootState } from 'interface/redux'
import { STATUS } from 'interface/redux/response-payload'
import {
	updateNotificationOld,
	loadNotifications,
	fetchNotifications,
	fetchNotificationsV2,
	loadNotificationsV2,
} from '../../api/notificarions'

import {
	mockNewNotifications,
	mockOldNotifications,
} from './mock-notifications'

export const getNotifications = createAsyncThunk(
	'notifications/getNotifications',
	async (
		{ skip, receiverId }: Record<string, any>,
		{ getState, requestId, rejectWithValue }
	): Promise<IResponseGetNotifications> => {
		const state = getState() as IStoreRootState

		const { loading, currentRequestId } = state.notifications
		if (!loading || requestId !== currentRequestId)
			throw rejectWithValue(STATUS.TOO_MANY_REQUESTS)

		const response = await fetchNotifications({ skip, receiverId })

		if (response.status === Req.failed)
			throw rejectWithValue('server faild to load')

		// const {
		// 	data: {
		// 		newNotifications = [],
		// 		oldNotifications = [],
		// 		allNotificationsLength = 0,
		// 	},
		// } = response.data
		const take = NOTIFICATIONS_SKIP_COUNT
		// let { oldNotifications, newNotifications } = state.notifications

		// newNotifications = [
		// 	...newNotifications,
		// 	...mockNewNotifications.slice(skip, take + skip),
		// ]

		// oldNotifications = [
		// 	...oldNotifications,
		// 	...mockOldNotifications.slice(skip, take + skip),
		// ]

		return {
			newNotifications: response.data[0].newNotifications,
			oldNotifications: response.data[0].oldNotifications,
			allNotificationsLength: response.data[0].allNotificationsLength,
			status: 'success',
		}
	}
)
export const getNotificationsV2 = createAsyncThunk(
	'notifications/getNotifications',
	async (
		{ skip, receiverId }: Record<string, any>,
		{ getState, requestId, rejectWithValue }
	): Promise<IResponseGetNotifications> => {
		const state = getState() as IStoreRootState

		const { loading, currentRequestId } = state.notifications
		if (!loading || requestId !== currentRequestId)
			throw rejectWithValue(STATUS.TOO_MANY_REQUESTS)

		const response = await fetchNotificationsV2({ skip, receiverId })

		if (response.status === Req.failed)
			throw rejectWithValue('server faild to load')

		// const {
		// 	data: {
		// 		newNotifications = [],
		// 		oldNotifications = [],
		// 		allNotificationsLength = 0,
		// 	},
		// } = response.data
		const take = NOTIFICATIONS_SKIP_COUNT
		// let { oldNotifications, newNotifications } = state.notifications

		// newNotifications = [
		// 	...newNotifications,
		// 	...mockNewNotifications.slice(skip, take + skip),
		// ]

		// oldNotifications = [
		// 	...oldNotifications,
		// 	...mockOldNotifications.slice(skip, take + skip),
		// ]

		return {
			newNotifications: response.data[0].newNotifications,
			oldNotifications: response.data[0].oldNotifications,
			allNotificationsLength: response.data[0].allNotificationsLength,
			status: 'success',
		}
	}
)

export const setNotificationOld = createAsyncThunk(
	'notifications/setNotificationOld',
	async (
		{ id: notificationId, receiver_id }: Record<string, string>,
		{ getState, requestId, rejectWithValue }
	): Promise<IResponseGetNotifications> => {
		const state = getState() as IStoreRootState
		const { loading, currentRequestId } = state.notifications
		// if (!loading || requestId !== currentRequestId)
		// 	throw rejectWithValue(STATUS.TOO_MANY_REQUESTS)

		const response = await updateNotificationOld(notificationId, {
			isNewNotification: false,
			receiver_id,
		})

		if (response.status === Req.failed)
			rejectWithValue('faild to update notification')

		let notification = {} as INotification
		let updatedOldNotifications
		const updatedNewNotifications = state.notifications.newNotifications.filter(
			item => {
				if (item._id === notificationId) {
					notification = item
				}
				return item._id !== notificationId
			}
		)
		if (notification) {
			updatedOldNotifications = [
				{ ...notification, isNewNotification: false },
				...state.notifications.oldNotifications,
			]
		}

		return {
			newNotifications: updatedNewNotifications,
			oldNotifications: updatedOldNotifications,
			status: 'success',
		}
	}
)

export const load_more_notifications = createAsyncThunk(
	'notifications/loadMoreNotifications',
	async (
		payload: { receiverId: string; pageNumber: number },
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await loadNotificationsV2(payload)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
