import { IconButton } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import lockIcon from '../../../../assets/lm-lock.svg'

import './style.css'

import FolderOption from './FolderOptions'
import AppPopover from 'utils/popover/AppPopover'
import usePopover from '../lesson-btn/usePopover'

import { ItemFolderActionsType } from '../props'

function FolderButtons({ isPrivate, deleteFolder, editFolder, folder_id }: ItemFolderActionsType) {
    const overridIconsStyle = 'mui-icons'
    const { closePopover, id, open, togglePopover, anchorEl } = usePopover()

    return (
        <div className='folder-icons'>

            {isPrivate && <img src={lockIcon} className={overridIconsStyle} />}

            {!isPrivate && <IconButton className={overridIconsStyle} aria-describedby={id} onClick={togglePopover}>
                <MoreVert className={overridIconsStyle} aria-describedby={id} />
            </IconButton>}

            <AppPopover anchorEl={!isPrivate ? anchorEl : null} close={closePopover} open={open} id={id}>
                <FolderOption editFolder={editFolder} deleteFolder={deleteFolder} folder_id={folder_id} />
            </AppPopover>

        </div>
    )
}

export default FolderButtons