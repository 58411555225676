import React, {
	ChangeEvent,
	FC,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import { AppDirectionContext } from 'context/languages-context'

import useStyles from './useStyles'

import { botActions } from '../../redux/bot/bot-reducer'

import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'
import { Header } from './Header'
import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'
// import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal'

import DoneIcon from '@material-ui/icons/Done'
import editIcon from 'assets/editIcon.svg'
import okayIcon from 'assets/okayIcon.svg'
import saveEmailIcon from 'assets/saveIcon2.svg'
import sendEmailIcon from 'assets/sendIcon2.svg'
import garbageBinIcon from 'assets/garbageBinIcon.svg'
import { QuestionType } from '../../interface/praise/praise-interface'
import redOkayIcon from 'assets/redOkayIcon.svg'
import sendIconNew from 'assets/sendIcon3.svg'
type Props = {
	width?: string
	children: React.ReactNode
}
type ContextTextType = {
	label: string
	text: string
	icon?: string | null
	canEdit?: boolean
}
type ContextPointsType = {
	beforeSend?: boolean
	text: string
}

const EventDate: FC<{ isPraise: boolean; time: string }> = props => {
	const { isPraise, time } = props

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const { t } = useTranslation()

	if (!time) return null

	const formattedDate = isRtlDirection ? hebrewDateFormat(time) : time
	return (
		<div className={classes.eventDate}>
			{t(isPraise ? 'sending_praise_date' : 'sending_feedback_date')}
			<div className={classes.date}>{formattedDate}</div>
		</div>
	)
}

type ActionsSectionsProps = {
	msg?: string
	isDisabled?: boolean
	isDraftDisabled: boolean
	disabledReason?: {
		becauseOfBlockage: boolean
		becauseOf1PraiseMissing: boolean
	}
	senderName: string
	isPraise: boolean
	handleSendOutput: (isDraft: boolean) => void
	closeAction: () => void
	deleteAction?: () => void
	isDraftOutput?: boolean
	alertMessage: string
}
const ActionsSections: FC<ActionsSectionsProps> = ({
	alertMessage,
	deleteAction,
	closeAction,
	msg,
	isPraise,
	senderName,
	isDisabled,
	isDraftDisabled,
	disabledReason,
	handleSendOutput,
	isDraftOutput,
}) => {
	const [modalAction, setModalAction] = useState('')

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const { t } = useTranslation()

	const textStyle = { fontSize: '0.8rem' }
	const Text = <span style={textStyle}>{t(`${alertMessage}`)}</span>

	const sendOutputLabel = isPraise ? 'send_the_praise' : 'send_the_feedback'

	const handleClick = (actionType: string) => setModalAction(actionType)

	const deleteDraft = () => {
		deleteAction && deleteAction()
		closeAction()
	}

	const onSecondaryAction = () => {
		if (isDraftOutput) {
			handleClick('delete')
		} else if (!isDraftDisabled) {
			handleSendOutput(true)
		}
	}
	const isDeleteModalAction = modalAction === 'delete'

	const getModalText = () => {
		if (isDeleteModalAction) return getModalDeleteDraftText()

		return generateCantSendText()
	}

	const getModalDeleteDraftText = () => t('sure_you_want_to_delete_draft')
	const generateCantSendText = () => {
		const { becauseOf1PraiseMissing = false, becauseOfBlockage = false } =
			disabledReason ?? {}
		if (!becauseOf1PraiseMissing && !becauseOfBlockage) return ''

		const firstName = { firstName: senderName }
		if (disabledReason?.becauseOf1PraiseMissing) {
			return t('can’t_send_feedback_1_praise', firstName)
		}

		return t('can’t_send_feedback_blockage', firstName)
	}

	const modalText = getModalText()

	const modalCloseText = isDeleteModalAction ? t('back') : t('bot.gotIt')
	const modalConfirmAction = isDeleteModalAction
		? () => deleteDraft()
		: undefined

	const isDisabledStyle = { opacity: isDisabled ? 0.4 : 1 }
	const isDraftDisabledStyle = { opacity: isDraftDisabled ? 0.4 : 1 }

	return (
		<>
			<div className={classes.buttonsContainer}>
				<Tooltip title={isDisabled ? Text : ''} placement='top'>
					<div
						id='sendActionButton'
						className={classes.actionButton}
						onClick={() =>
							isDisabled ? handleClick('cant_send') : handleSendOutput(false)
						}
					>
						<img
							// style={isDisabledStyle}
							src={sendIconNew}
							className={classes.buttonIcon}
						/>

						<div
							// style={isDisabledStyle}
							className={classes.buttonText}
						>
							{t(sendOutputLabel)}{' '}
						</div>
					</div>
				</Tooltip>

				<div className={classes.actionButton} onClick={() => onSecondaryAction()}>
					<embed
						src={isDraftOutput ? garbageBinIcon : saveEmailIcon}
						className={classes.buttonIcon}
						style={isDraftDisabledStyle}
					/>

					<div style={isDraftDisabledStyle} className={classes.buttonText}>
						{isDraftOutput ? t('delete_draft') : t('save_as_draft')}
					</div>
				</div>
			</div>
			<ConfirmModalSB
				text={modalText}
				closeButtonText={modalCloseText}
				confirmButtonText={t('delete')}
				onClose={() => setModalAction('')}
				onConfirm={modalConfirmAction}
				isOpen={modalAction ? true : false}
			/>
		</>
	)
}

export enum Color {
	red = 'red',
	green = 'green',
}

const Icon = {
	[Color.red]: redOkayIcon,
	[Color.green]: okayIcon,
}

const getIconByColor = (color?: Color) => {
	if (!color) return null
	return Icon[color]
}
type OkayIconProps = {
	color: Color
}

export const OkayIcon: FC<OkayIconProps> = props => {
	const { color } = props

	const icon = getIconByColor(color)

	return <>{icon && <img src={icon} alt='thumbupIcon' />}</>
}

const ContextPoints: FC<ContextPointsType> = ({ text, children }) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ margin: true, isRtlDirection })

	return (
		<div className={classes.textWrapper}>
			{children}
			<div>{text}</div>
		</div>
	)
}

const getTexareaBgStyle = (icon: string, isRtlDirection: boolean) => ({
	backgroundImage: `url( ${icon} )`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'contain',
	backgroundPosition: isRtlDirection ? 'left' : 'right',
})
export const ContextText: FC<ContextTextType> = props => {
	const { text, icon, label, canEdit = false } = props

	const [isEdit, setIsEdit] = useState(false)
	const [userInput, setUserInput] = useState(text)

	const textareaRef = useRef<HTMLTextAreaElement>(null)

	useEffect(() => {
		if (!isEdit || !textareaRef.current) return

		textareaRef.current.selectionStart = text?.length
	}, [isEdit])

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const windowWidth = useWindowWidth()
	const mobileWidth = 600
	const isMobile = mobileWidth >= windowWidth

	const handleChange = ({
		target: { value },
	}: ChangeEvent<HTMLTextAreaElement>) => {
		setUserInput(value)
	}

	const handleUpdateText = () => {
		dispatch(
			botActions.updateTextOutputAnswer({
				type: QuestionType.TextArea,
				answer: userInput,
			})
		)

		toggleEditMode()
	}

	const toggleEditMode = () => setIsEdit(prev => !prev)

	const saveBtnStyle = useMemo((): string => {
		const isDescriptionChanged = text?.trim() !== userInput?.trim()
		const baseBtnStyle = classes.editIcon
		if (isDescriptionChanged) return baseBtnStyle

		return `${baseBtnStyle} ${classes.desabled}`
	}, [text, userInput])

	const texareaBgStyle =
		icon && isMobile ? getTexareaBgStyle(icon, isRtlDirection) : {}
	return (
		<div className={classes.contextTextWrapper}>
			<div>{label}</div>

			<div style={texareaBgStyle} className={classes.textInput}>
				{isEdit ? (
					<textarea
						className={classes.textareaOutput}
						onChange={handleChange}
						value={userInput}
						autoFocus
						ref={textareaRef}
						rows={4}
					/>
				) : (
					<div>{userInput}</div>
				)}

				{canEdit && (
					<>
						{!isEdit && (
							<span onClick={toggleEditMode} className={classes.editIcon}>
								{t('edit')}
							</span>
						)}

						{isEdit && (
							<span onClick={handleUpdateText} className={saveBtnStyle}>
								{t('save')}
							</span>
						)}
					</>
				)}
			</div>
		</div>
	)
}

const Context: FC<{}> = ({ children }) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })

	return <div className={classes.text}>{children}</div>
}
const ContextWrapper: FC<{}> = ({ children }) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })

	return <div className={classes.contentBody}>{children}</div>
}

const BotOutput = ({ children, width = '871px' }: Props) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ width, isRtlDirection })

	return <div className={classes.containerWrapper}>{children}</div>
}
export default BotOutput

BotOutput.Header = Header
BotOutput.Context = Context
BotOutput.Text = ContextText
BotOutput.ContextWrapper = ContextWrapper
BotOutput.ContextPoints = ContextPoints
BotOutput.ActionsSections = ActionsSections
BotOutput.EventDate = EventDate

export function hebrewDateFormat(date: string) {
	const enDate = new Date(date)
	const monthDay = enDate.getDate().toString()
	let month = enDate.getMonth().toString()
	let weekDay = enDate.getDay().toString()
	const year = enDate.getFullYear().toString()

	switch (month) {
		case '0':
			month = 'ינואר'
			break
		case '1':
			month = 'פברואר'
			break
		case '2':
			month = 'מרץ'
			break
		case '3':
			month = 'אפריל'
			break
		case '4':
			month = 'מאי'
			break
		case '5':
			month = 'יוני'
			break
		case '6':
			month = 'יולי'
			break
		case '7':
			month = 'אוגוסט'
			break
		case '8':
			month = 'ספטמבר'
			break
		case '9':
			month = 'אוקטובר'
			break
		case '10':
			month = 'נובמבר'
			break
		case '11':
			month = 'דצמבר'
			break
		default:
			break
	}
	switch (weekDay) {
		case '0':
			weekDay = 'ראשון'
			break
		case '1':
			weekDay = 'שני'
			break
		case '2':
			weekDay = 'שלישי'
			break
		case '3':
			weekDay = 'רביעי'
			break
		case '4':
			weekDay = 'חמישי'
			break
		case '5':
			weekDay = 'שישי'
			break
		case '6':
			weekDay = 'שבת'
			break
		default:
			break
	}

	return 'יום' + ' ' + weekDay + ', ' + monthDay + ' ' + month + ', ' + year
}
