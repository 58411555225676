import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ManagerGoals from 'components/ManagerGoals'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { employeesSelector } from 'redux/employee/employee.selector'
import { userSelector } from 'redux/user/user-selector'
import { get_employees } from 'redux/employee/employee-actions'

import { PERMISSION_KEYS } from 'components/common/Header/Mobile/constants'

import IUser from 'interface/user/user-interface'
import { IUserStatusInfo } from '../../interface/user/user-interface'

const progressBarStyles = {
	width: 'fit-content',
	margin: '15% auto',
}
const noEmployeesTextStyles = {
	fontSize: '120%',
	padding: '2% 0',
	paddingInlineStart: '17%',
}
const ManagerGoalsScreen = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const manager: IUser = useSelector(userSelector)
	const employees = useSelector(employeesSelector)

	const { statusInfo = {} } = manager ?? {}
	const { permissions = [] } = statusInfo as IUserStatusInfo

	useEffect(() => {
		dispatch(get_employees({ company_id: manager.company_id! }))
	}, [manager._id])

	const accessByPermission = permissions?.includes(
		PERMISSION_KEYS.employeePerformance
	)

	const noEmployeesText = (
		<p style={noEmployeesTextStyles}>{t('no_employees_assigned')}</p>
	)

	const progressbar = (
		<div style={progressBarStyles}>
			<CircularProgress size={150} />
		</div>
	)

	return employees.length > 0 ? (
		<ManagerGoals
			accessByPermission={accessByPermission}
			progressbar={progressbar}
			employees={employees}
		/>
	) : (
		noEmployeesText // make it inside the component under the header
	)
}

export default ManagerGoalsScreen
