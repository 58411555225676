import { FC, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { AppDirectionContext } from 'context/languages-context'

import { useStyles } from '../useStyles'

import { Loader } from 'components/common/Loader'
import BotOutput from 'components/BotOutput/index'
import UserAvatar from 'components/common/Avatar'

import okayIcon from 'assets/okayIcon.svg'

import { OutputWrapperProps } from 'interface/bot'
import RenderOneOnOneReviewButton from 'components/BotOutput/RenderOneOnOneReviewButton'
import { useStyles as innerStyles } from '../useStyles'
const ViewingOutput: FC<OutputWrapperProps & { isPraise: boolean }> = props => {
	const [loading, setLoading] = useState(true)

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection, readed: props.readed })
	const innerClasses = innerStyles({ isRtlDirection, readed: props.readed })
	const { t } = useTranslation()

	useEffect(() => {
		setTimeout(() => {
			setLoading(false)
		}, 1000)
	}, [])

	const {
		replay,
		isPraise,
		sender,
		receiver,
		DATE,
		VALUES,
		CONTEXT,
		CONTRIBUTION,
		FREE_TEXT_LABEL,
		FREE_TEXT,
		IMPACTS,
		impactsExpansion = [],
		contributionExpansion = '',
		handleToggleReview,
	} = props

	if (loading) return <Loader />

	return (
		<>
			<BotOutput>
				<BotOutput.Header
					isViewScreen
					headerText={isPraise ? t('i_see_you') : t('i_need_you')}
				/>

				<BotOutput.ContextWrapper>
					<div className={classes.greetings}>
						<div className={classes.greetingSender}>
							{t('hi', {
								username: sender?.personalInfo.firstName,
								interpolation: { escapeValue: false },
							})}

							<UserAvatar className={classes.avatarStyles} userInfo={sender} />
						</div>

						<div className={classes.toRecipient}>
							{t(
								isPraise
									? 'The_employee_you_are_sending_praise'
									: 'The_employee_you_are_sending_feedback',
								{ userName: receiver?.personalInfo?.firstName }
							)}

							<div className={classes.recepientName}>
								{receiver?.personalInfo.firstName}
							</div>

							<UserAvatar className={classes.avatarStyles} userInfo={receiver} />
						</div>
					</div>

					<BotOutput.EventDate time={DATE} isPraise={isPraise} />

					<div className={classes.eventTitleContainer}>
						{t(isPraise ? 'What_the_praise_is_about' : 'What_the_feedback_is_about')}

						<div className={classes.eventTitle}>{CONTEXT}</div>
					</div>

					{FREE_TEXT && <BotOutput.Text label={FREE_TEXT_LABEL} text={FREE_TEXT} />}

					{CONTRIBUTION && (
						<div className={classes.successContributionContainer}>
							{!isPraise
								? t('contribution_to_success_feedback', {
										userName: receiver?.personalInfo.firstName,
										interpolation: { escapeValue: false },
								  })
								: t('contribution_to_success_praise', {
										userName: receiver?.personalInfo.firstName,
										interpolation: { escapeValue: false },
								  })}
							<BotOutput.ContextPoints text={CONTRIBUTION} />

							{contributionExpansion.trim() !== '' && (
								<BotOutput.Text text={contributionExpansion} label={''} />
							)}
						</div>
					)}

					{IMPACTS.length ? (
						<div className={classes.furtherImpactContainer}>
							<div>{t('further_affect')}</div>

							{IMPACTS.map((impact, key) => (
								<>
									<BotOutput.ContextPoints key={key} text={impact} />

									{!!impactsExpansion.length && impactsExpansion?.[key!] && (
										<div className={classes.textInput}>{impactsExpansion?.[key!]}</div>
									)}
								</>
							))}
						</div>
					) : null}

					{VALUES ? (
						<div className={classes.contributionCompanyValuesContainer}>
							{isPraise
								? t('contribution_to_company_values')
								: t('impact_on_company_values')}

							{VALUES?.map((value: any, key: any) => (
								<div key={key} className={classes.textWrapper}>
									<img src={okayIcon} alt='thumbupIcon' />

									<div className={classes.value}>
										{t(isPraise ? 'conduct_value' : 'conduct_value_feedback', {
											username: receiver?.personalInfo.firstName,
											value: value,
											interpolation: { escapeValue: false },
										})}
									</div>
								</div>
							))}
						</div>
					) : null}
				</BotOutput.ContextWrapper>
				<div
					className={classes.textInput}
					style={{ direction: isRtlDirection ? 'rtl' : 'ltr' }}
				>
					{!replay?.trim() && <div>{t('receiver_comment')}</div>}

					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<div>{replay}</div>
					</div>
				</div>
			</BotOutput>
			<RenderOneOnOneReviewButton
				{...{ styles: classes, handleClick: handleToggleReview }}
			/>
		</>
	)
}

export default ViewingOutput
