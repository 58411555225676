// --------------------------------------------
//  RECONSTRUCTION_MESSAGE:

import InitialMessage from 'components/Debriefing/InitialMessage'
import { EMPTY_STATE } from '../useTreeLogic'
import { EXPECTED_RESULT, CHRONOLOGICAL_EVENTS } from '../stepMapComponent'

export const RenderReconstructionMessageChoice = ({ ...props }) => {
	const { debrief, updateDebrief } = props

	const pageIcon = {
		src: 'heartIcon',
		opacity: '12%',
	}
	const content = (
		<InitialMessage
			setData={updateDebrief}
			prevStep={EXPECTED_RESULT}
			nextStep={CHRONOLOGICAL_EVENTS}
			msgData={
				debrief?.expectedResult?.achieved
					? RECONSTRUCTION_ACHIEVED_MESSAGE_DATA
					: RECONSTRUCTION_UNACHIEVED_MESSAGE_DATA
			}
		/>
	)
	return { ...EMPTY_STATE, content, pageIcon }
}
export const msgSteps = ['1', '2', '3', '4']

const RECONSTRUCTION_ACHIEVED_MESSAGE_DATA = {
	type: 'reconstruction_msg_achieved',
	steps: msgSteps,
}
const RECONSTRUCTION_UNACHIEVED_MESSAGE_DATA = {
	type: 'reconstruction_msg_unachieved',
	steps: msgSteps,
}
