import { FC } from 'react'
import { useSelector } from 'react-redux'

import { debriefOnProccesSelector } from 'redux/debrief/debrief-selector'
import { userSelector } from 'redux/user/user-selector'

import { SelfAnalisysChatBot } from 'screens/DebriefingSelfAnalisys/index'
import WaitingParticipant from 'components/Debriefing/WaitingParticipant'
import WelcomeParticipant from '../../WelcomeParticipant/index'

import { checkIfUserFinishedBot } from 'utils/checkIfUserFinishedBot'

import { SELF_ANALISYS_MESSAGE, SUMMARY } from '../stepMapComponent'
import { Debrief } from 'interface/debrief/debrief-interface'
import { shouldUserAccessSelfAnalisysChat } from '../../../../utils/shouldUserAccessSelfAnalisysChat'

type Props = {
	userIsParticipant: any
	updateDebrief: any
	setParticipantStep: any
	setDisableNext: any
	step: any
}

export const ParticipantsTree: FC<Props> = props => {
	const debrief: Debrief | null =
		useSelector(debriefOnProccesSelector) ?? ({} as Debrief)

	const user = useSelector(userSelector)

	const finishedBot = !!checkIfUserFinishedBot(user._id, debrief.events!)
	const {
		userIsParticipant,
		updateDebrief,
		setParticipantStep,
		setDisableNext,
		step,
	} = props

	if (finishedBot) return <WaitingParticipant />

	if (shouldUserAccessSelfAnalisysChat(debrief.events, step)) {
		return (
			<SelfAnalisysChatBot
				debrief={debrief}
				isParticipant={userIsParticipant}
				setParticipantStep={setParticipantStep}
				setData={updateDebrief}
				prevStep={SELF_ANALISYS_MESSAGE}
				nextStep={SUMMARY}
			/>
		)
	}

	return <WelcomeParticipant debrief={debrief} setDisableNext={setDisableNext} />
}
