import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { /*useDispatch,*/ useSelector } from 'react-redux'
import { makeStyles, Theme } from '@material-ui/core'
import { IThemeOptions } from 'theme'
import dayjs from 'dayjs'

// import { userSelector } from 'redux/user/user-selector'
import {
	debriefLoadingSelector,
	hasMoreDebriefsSelector,
} from 'redux/debrief/debrief-selector'

import InfiniteScroll from 'react-infinite-scroll-component'
import makeButtonStyles from '../../common/Tabs/Row/styles'
import Tabs from '../../common/Tabs'

import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import { Debrief } from 'interface/debrief/debrief-interface'

interface Props {
	debriefings: Debrief[]
	handleClick: (debrief: Debrief) => void
}
const INCREMENT_BY_ONE = 1
export const MobilePanels = ({ debriefings, handleClick }: Props) => {
	// const dispatch = useDispatch()
	const { t } = useTranslation()

	const classes = useStyles()
	const hasMore = useSelector(hasMoreDebriefsSelector)
	const loading = useSelector(debriefLoadingSelector)

	// const { _id, company_id } = useSelector(userSelector)

	const [pageNumber, setPageNumber] = useState<number>(0)
	const [currentTab, setCurrentTab] = useState<number>(0)
	const [currentDebriefings, setCurrrentDebriefings] =
		useState<Debrief[]>(debriefings)

	const baseButtonClasses = makeButtonStyles({
		isPraiseButton: false,
		expanded: true,
		isRtlDirection: false,
	})

	const nextPage = () => {
		let nextPageNumber = 0
		nextPageNumber = pageNumber + INCREMENT_BY_ONE
		setPageNumber(nextPageNumber)
		// dispatch(get_user_debriefings({ user_id: _id, company_id, pageNumber: nextPageNumber }))
	}

	const endMessage = <p style={{ textAlign: 'center' }}>end</p>
	const loader = <h4 style={{ margin: '2% 2%' }}>{t('loading...')}</h4>
	const infiniteScrollHeight = window.innerWidth > 400 ? 371 : 530

	const panels = [
		<InfiniteScroll
			dataLength={currentDebriefings.length}
			next={nextPage}
			hasMore={hasMore}
			endMessage={endMessage}
			loader={loading && loader}
			height={infiniteScrollHeight}
		>
			{currentDebriefings.map((debrief: Debrief) => {
				const { code, _id, createdAt, expectedResult, step } = debrief
				const isSummary = step === DebriefingSteps.FINISH
				return (
					<>
						<div key={code} className={classes.row}>
							<div className={classes.rowData}>
								{t('debrief.tab_date')}
								<span className={classes.bold}>
									{dayjs(createdAt).format('DD/MM/YYYY')}
								</span>
							</div>
							{currentTab === 0 && (
								<div className={`${classes.bold} ${classes.rowData}`}>
									{expectedResult?.achieved
										? t('debrief.tab_outcome_not_achieved')
										: t('debrief.tab_outcome_achieved')}
								</div>
							)}
							<div>“{expectedResult?.description}”</div>
							<button
								className={
									!isSummary
										? `${baseButtonClasses.modalButton} ${classes.debriefButton}`
										: `${baseButtonClasses.modalButton} ${classes.debriefFinishButton}`
								}
								onClick={() => handleClick(debrief)}
							>
								{isSummary ? t('debrief.summary_button') : t('debrief.continue_button')}
							</button>
						</div>
					</>
				)
			})}
		</InfiniteScroll>,
	]
	const onTabChange = (tabNum: number) => {
		switch (tabNum) {
			case 0:
				setCurrrentDebriefings(debriefings)
				break
			case 1:
				setCurrrentDebriefings(
					debriefings.filter(
						(debrief: Debrief) => debrief.expectedResult?.achieved === true
					)
				)
				break
			case 2:
				setCurrrentDebriefings(
					debriefings.filter(
						(debrief: Debrief) => debrief.expectedResult?.achieved === false
					)
				)
				break

			default:
				break
		}
		setCurrentTab(tabNum)
	}

	return (
		<>
			<Tabs
				tabClass={classes.tab}
				className={classes.tabs}
				title={t('debrief.my_lessons')}
				panels={[panels[0], panels[0], panels[0]]}
				onTabChange={(tabNum: number) => onTabChange(tabNum)}
				tabState={currentTab}
				tabs={[
					{ label: t('debrief.all') },
					{ label: t('debrief.tab_achieved') },
					{ label: t('debrief.tab_not_achieved') },
				]}
			/>
		</>
	)
}
export default MobilePanels

const useStyles = makeStyles<IThemeOptions & Theme>(
	({ breakpoints, palette }) => ({
		tabs: {
			'& .MuiTabs-indicator': {
				backgroundColor: `${palette.debrief.main} !important`,
			},
		},
		tab: {
			width: '33%',
			minWidth: '0',
		},
		row: {
			padding: '5%',
			borderBottom: '1px solid #DEE3EF',
			display: 'flex',
			flexDirection: 'column',
			'& button': {
				alignSelf: 'flex-end',
			},
		},
		bold: {
			fontWeight: 'bold',
		},
		rowData: {
			margin: '2% 0 2% 0',
		},
		debriefFinishButton: {
			fontSize: '90%',
			'&:hover': {
				backgroundColor: palette.debrief.shadow,
			},
			backgroundColor: palette.debrief.dark,
		},
		debriefButton: {
			fontSize: '90%',
			'&:hover': {
				backgroundColor: palette.debrief.shadow,
			},
			backgroundColor: '#7c6fd9',
		},
	})
)
