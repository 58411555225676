import { Response } from 'interface/api/response-interface'
import {
	CreateEventDto,
	DebriefDTO,
	DebriefEventDTO,
	ExtraDispatchPayload,
	Lesson,
	BotAnswersPayload,
	FindDebriefPayload,
} from 'interface/debrief/debrief-interface'
import { DELETE, GET, PATCH, POST, PUT } from './api-requests'
import { URLS } from './api-urls'
import { ShareDebriefDto } from '../interface/debrief/debrief-interface'
import { PdfDebriefPayload } from 'redux/debrief/debrief-actions'

export const createDebrief = async (
	debrief: Partial<DebriefDTO>
): Promise<Response> => {
	const { data, status } = await POST(URLS.DEBRIEF, debrief)
	return { data, status }
}
export const deleteDbreif = async (payload: {
	company_id: string
	_id: string
}): Promise<Response> => {
	const { data, status } = await DELETE(
		`${URLS.DEBRIEF}?company_id=${payload.company_id}&_id=${payload._id}`
	)
	return { data, status }
}
export const fetchUserDebriefings = async (userInfo: {
	user_id: string
	company_id: string
	pageNumber: number
}): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.EMPLOYEE}/debriefings/${userInfo.pageNumber}?company_id=${userInfo.company_id}&user_id=${userInfo.user_id}`
	)
	return { data, status }
}

export const updateDebrief = async (
	debrief: Partial<DebriefDTO>
): Promise<Response> => {
	const { data, status }: any = await PATCH(`${URLS.DEBRIEF}`, debrief)
	return { data, status }
}

export const shareDebrief = async (
	debrief: ShareDebriefDto
): Promise<Response> => {
	const { data, status }: any = await PATCH(`${URLS.DEBRIEF}/share`, debrief)
	return { data, status }
}

export const createDebriefEvent = async (
	event: ExtraDispatchPayload
): Promise<Response> => {
	const { data, status } = await POST(`${URLS.DEBRIEF}/event`, event)
	return { data, status }
}

export const updateDebriefEvent = async (eventData: {
	company_id: string
	debrief_id: string
	event: DebriefEventDTO
}): Promise<Response> => {
	const { data, status } = await PATCH(`${URLS.DEBRIEF}/event`, eventData)
	return { data, status }
}

export const updateMatchpointLesson = async (payload: {
	company_id: string
	event_id: string
	debrief_id?: string
	lessonData: Lesson
}): Promise<Response> => {
	const { data, status } = await PATCH(`${URLS.DEBRIEF}/lesson`, payload)
	return { data, status }
}

export const createMatchpointLesson = async (payload: {
	company_id: string
	debrief_id?: string
	event_id: string
	lessonData: Lesson
}): Promise<Response> => {
	console.log(payload)
	const { data, status } = await POST(`${URLS.DEBRIEF}/lesson`, payload)
	return { data, status }
}

export const deleteMatchpointLesson = async (payload: {
	company_id: string
	lesson_id: string
}): Promise<Response> => {
	const { data, status } = await DELETE(
		`${URLS.DEBRIEF}/lesson?company_id=${payload.company_id}&lesson_id=${payload.lesson_id}`
	)
	return { data, status }
}

export const updateEventLocation = async (locationData: {
	company_id: string
	debrief_id: string
	source: number
	destination: number
}): Promise<Response> => {
	const { data, status } = await PUT(`${URLS.DEBRIEF}/event/order`, locationData)
	return { data, status }
}
export const deleteDebriefEvent = async (eventData: {
	company_id: string
	debrief_id: string
	event_id: string
}): Promise<Response> => {
	const { data, status } = await DELETE(
		`${URLS.DEBRIEF}/event?company_id=${eventData.company_id}&debrief_id=${eventData.debrief_id}&event_id=${eventData.event_id}`
	)
	return { data, status }
}

export const createMatchpointSummary = async (
	payload: CreateEventDto
): Promise<Response> => {
	const { data, status } = await POST(
		`${URLS.DEBRIEF}/summaries/${payload.company_id}`,
		payload
	)
	return { data, status }
}

export const updateBotAnswers = async (
	payload: BotAnswersPayload
): Promise<Response> => {
	const { data, status } = await PATCH(`${URLS.DEBRIEF}/summaries`, payload)
	return { data, status }
}

export const findOneDebrief = async ({
	company_id,
	debrief_id,
	user_id,
}: FindDebriefPayload): Promise<Response> => {
	console.log({ company_id }, { debrief_id })
	const { data, status } = await GET(
		`${URLS.EMPLOYEE}/${company_id}/${debrief_id}/${user_id}`
	)
	return { data, status }
}

export const exportDebrief = async (payload: PdfDebriefPayload): Promise<Response> => {
	const { data, status } = await POST(`${URLS.DEBRIEF}/export`, { payload })
	return { data, status }
}
