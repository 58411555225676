import React, { useEffect, useState } from 'react'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import { Fab } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import Divider from '@mui/material/Divider'
import { CreateForm } from './CreateOutputForm'

type RenderItem = {
	isRtl: boolean
	placeholder: string
	name: string
	str: string
	handleEditingChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const RenderItem = ({
	placeholder,
	name,
	str,
	isRtl,
	handleEditingChange,
}: RenderItem) => {
	const [isOnEdit, setIsOnEdit] = useState(false)

	useEffect(() => {
		if (!str) setIsOnEdit(true)
	}, [str])

	const RenderItemNotOnEdit = (
		<>
			<ListItem
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					margin: '10px 0',
				}}
			>
				<div>
					<b>{placeholder}:</b>
					<ListItemText primary={str} />
				</div>
				<div>
					<Fab onClick={() => setIsOnEdit(true)} color='secondary' aria-label='edit'>
						<EditIcon />
					</Fab>
				</div>
			</ListItem>
			<Divider />
		</>
	)

	return (
		<div
			style={{
				height: '10vh',
				padding: '0 5%',
			}}
		>
			{!isOnEdit ? (
				RenderItemNotOnEdit
			) : (
				<CreateForm
					isRtl={isRtl}
					label=''
					width='100%'
					values={{ [name]: str }}
					onChange={handleEditingChange}
				/>
			)}
		</div>
	)
}
