import { PHASE } from './InitialChatPreset'
const summary = {
	what_was_done: '',
	assumption: '',
	Whats_was_you_need: '',
	lessons: [],
}
export const initialAnswer = {
	event_id: '',
	isDone: false,
	currentPhase: PHASE.ACTIONS,
	currentAnswer: '',
	answers: [],
	summary: summary,
}

export const dummyMatchPoints = [
	{
		title: 'one',
		mtDescription:
			'Sasha understood he need to take the car from Moshe and drive rapidly to the client who was waiting for 30 min already',
	},
	{
		title: 'two',
		mtDescription: 'Sasha understood he need to take the car from Moshe',
	},
	{
		title: 'two',
		mtDescription: 'Sasha understood he need to take the car from Moshe',
	},
]

export const backgroundIconsMapNames = [
	'heartIcon',
	'surveyResults',
	'conversationIcon',
]
export const mtDes =
	'Sasha understood he need to take the car from Moshe and drive rapidly to the client who was waiting for 30 min already'

export const assumption = 'assumption'
export const assumptionNeed = 'assumptionNeed'
export const what_is_the_lesson = 'what_is_the_lesson'
export const more_lessons = 'more_lessons'

export const actions = [
	'Set_clear_goals',
	'Designate_a_leader_responsible',
	'Make_full_preparation',
	'Share_all_the_information_with_the_right_people',
	'React_to_information_feedback_request',
	'Follow_procedures_policy_process',
	'Set_expectations',
	'Monitor_&_control',
	'Other_action_write_briefly',
]
const needs = [
	'Save_my_energy',
	'Increase_myself',
	'Feel_more_confident',
	'Feel_belonging',
	'Preserve_my_reputation',
	'Feel_meaningful',
	'To_learn_&_develop_myself',
	'Perform_&_achieve_my_goals',
	'Other_write_briefly',
]

export const presets: Record<string, any> = {
	actions: actions,
	assumptionNeed: needs,
}
