import classes from './styles.module.css'
import StepsBar from 'components/HRsettings/StepsBar'
import {
	FC,
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { CloseIcon } from 'components/common/CloseIcon'
import { DashboardPaths } from 'enums/routes/path-names'
import { userSelector } from 'redux/user/user-selector'
import { OutputType } from 'screens/BotOutput'
import Confirmation from 'components/FeedbackProcess/Confirmation'
import Review from 'components/FeedbackProcess/Review'
import ActionPlan, { Actions } from 'components/FeedbackProcess/ActionPlan'
import { FeedbackState } from 'enums/feedback-state/feedback-state.enum'
import { feedbackLoadingSelector } from 'redux/feedback/feedback-selector'
import { update_feedback } from 'redux/feedback/feedback-actions'
import { review_feedback_work_meet } from 'redux/feedback/feedback-actions'
import { Loader } from 'components/common/Loader'
import { BlockageAction, FeedbackOutputWrapperProps } from 'interface/bot'
import { useTranslation } from 'react-i18next'
import { AppDirectionContext } from 'context/languages-context'
import { uniqeId } from 'utils/helpers'
// feedback_summation
const initialAnswersState = {
	q1: null,
	q2: null,
	q3: null,
	q4: null,
	agreed: false,
}
const agreedAnswersState = {
	q1: true,
	q2: true,
	q3: true,
	q4: true,
	agreed: true,
}
const useSteps = () => {
	const { t } = useTranslation()
	const steps = [
		{
			label: t('feedback_confirmation'),
			value: 0,
		},
		{
			label: t('action_plan'),
			value: 1,
		},
	]
	return [steps]
}

const FeedbackProcess: FC<FeedbackOutputWrapperProps> = props => {
	const dispatch = useDispatch()
	const [steps] = useSteps()
	const { isRtlDirection } = useContext(AppDirectionContext)

	const answersState =
		props.FEEDBACK_STATE === FeedbackState.viewed ||
		props.FEEDBACK_STATE === FeedbackState.start
			? initialAnswersState
			: agreedAnswersState
	const [blockageOpened, setBlockageOpen] = useState(
		props.FEEDBACK_STATE === FeedbackState.sayDo
	)

	const [answers, setAnswers] = useState(answersState)
	const [formFocus, setFormFocus] = useState(false)
	const [openDatePicker, setOpenDatepicker] = useState('')
	const user = useSelector(userSelector)
	const history = useHistory()
	const location: any = useLocation()
	const loading = useSelector(feedbackLoadingSelector)

	const {
		id: feedbackId,
		outputScreenType,
		screenToReturn,
		tabState,
	}: any = location.state
	const componentProps = {
		...{ ...props, outputId: feedbackId },
	}
	const isReceiver = outputScreenType === OutputType.FeedbackReciever
	const containerRef = useRef<HTMLDivElement | null>(null)

	const [actions, setActions] = useState<Actions>({
		canSend: false,
		sender: getInitialState(componentProps.SENDER_ACTIONS),
		receiver: getInitialState(componentProps.RECEVIER_ACTIONS),
	})
	const recevierHasAction =
		actions?.receiver[0] && actions?.receiver[0]?.description.length > 0

	const [step, setStep] = useState(recevierHasAction ? 1 : 0)
	useLayoutEffect(() => {
		if (!containerRef.current) return
		// const scrollContainer = containerRef.current
		// scrollContainer.scrollTop = scrollContainer.scrollHeight

		// containerRef.current.scrollTo(containerRef.current.scrollHeight
		const rootEl = document.getElementById('root')
		if (rootEl?.scrollTop) {
			rootEl.scrollTop = rootEl?.scrollHeight
		}
		// objDiv.scrollTop = objDiv.scrollHeight;)
		// return () => {}
	}, [containerRef])

	useEffect(() => {
		const rootEl = document.getElementById('root')
		rootEl?.scrollTo(0, 0)
	}, [step])

	const update = (participant: string, actions: BlockageAction[]) => {
		setActions((prevState: Actions) => {
			return {
				...prevState,
				canSend: !prevState.canSend ? true : prevState.canSend,
				[participant]: actions,
			}
		})
	}

	const disableSend = () => {
		setActions((prevState: Actions) => {
			return {
				...prevState,
				canSend: false,
			}
		})
	}

	const updateProcess = (
		processState: FeedbackState,
		senderActions: BlockageAction[] = [],
		receiverActions: BlockageAction[] = []
	) => {
		disableSend()

		dispatch(
			update_feedback({
				isReceiver,
				company_id: user.company_id!,
				_id: feedbackId,
				feedbackState: processState,
				senderActions,
				receiverActions,
				user_id: user._id,
			})
		)
	}

	useEffect(() => {
		props.FEEDBACK_STATE === FeedbackState.start &&
			isReceiver &&
			updateProcess(FeedbackState.viewed)
	}, [isReceiver])

	useEffect(() => {
		setBlockageOpen(props.FEEDBACK_STATE === FeedbackState.sayDo)
	}, [props.FEEDBACK_STATE])

	useEffect(() => {
		if (answers.q1 && answers.q2 && answers.q3 && answers.q4 && !answers.agreed) {
			updateProcess(FeedbackState.agreed)
			setAnswers(prevState => {
				return {
					...prevState,
					agreed: true,
				}
			})
			setStep(1)
		}
	}, [answers])

	const handleActionPlan = (
		senderActions: BlockageAction[],
		recevierActions: BlockageAction[]
	) => {
		const processState = getNextProcessState(isReceiver, props.FEEDBACK_STATE)
		updateProcess(processState, senderActions, recevierActions)
	}

	const handleAnswers = (answer: boolean | null, question: string) => {
		setAnswers(prevState => {
			return question === 'reset'
				? { ...initialAnswersState, agreed: false }
				: {
						...prevState,
						[question]: answer,
				  }
		})
	}

	const handleClose = useCallback(() => {
		if (screenToReturn) {
			const returningScreen = screenToReturn
			history.push(returningScreen, { tabState })
		}
	}, [location, history])

	function getInitialState(currentActions: BlockageAction[] | undefined) {
		return currentActions && currentActions.length > 0
			? currentActions
			: isReceiver
			? [generateNewAction()]
			: []
	}
	const handleToggleReview = () => {
		const payload = { company_id: user.company_id!, output_id: feedbackId }

		dispatch(review_feedback_work_meet(payload))
		handleClose()
	}
	//

	const render = () => {
		switch (step) {
			case 0:
				return isReceiver ? (
					<Confirmation
						{...componentProps}
						formFocus={formFocus}
						resetFocus={() => setFormFocus(false)}
						setAnswers={handleAnswers}
						answers={answers}
					/>
				) : (
					<Review {...componentProps} handleToggleReview={handleToggleReview} />
				)
			case 1:
				return (
					<ActionPlan
						onBlockageOpen={setBlockageOpen}
						blockageOpened={blockageOpened}
						isReceiver={isReceiver}
						openDatePicker={openDatePicker}
						onDatePickerOpen={setOpenDatepicker}
						handleActionPlan={handleActionPlan}
						actions={actions}
						update={update}
						disableSend={disableSend}
						handleToggleReview={handleToggleReview}
						{...componentProps}
					/>
				)

			default:
				return <span>no step defined</span>
		}
	}
	return (
		<div className={classes.mainConatiner} onClick={() => setOpenDatepicker('')}>
			<div
				className={
					isRtlDirection
						? `${classes.topContainer} ${classes.rtl}`
						: classes.topContainer
				}
			>
				<div className={classes.stepBar}>
					<StepsBar
						barWidth='30vw'
						tabWidth='50%'
						stepsData={steps}
						currentStep={step}
						onStepChange={(step: number) => {
							if (step === 1 && !answers.agreed && isReceiver) {
								setFormFocus(true)
								return
							}
							setStep(step)
						}}
					/>
				</div>
				<div className={classes.closeButton}>
					<CloseIcon handleClick={handleClose} />
				</div>
			</div>
			<div className={classes.content} ref={containerRef}>
				{loading ? <Loader size={150} height={'70vh'} /> : render()}
			</div>
		</div>
	)
}

export default FeedbackProcess

function getNextProcessState(
	isRecevier: boolean,
	currentProcessState: FeedbackState
) {
	let processState
	if (isRecevier) {
		//recevier
		switch (currentProcessState) {
			case FeedbackState.agreed:
				processState = FeedbackState.sent
				break
			case FeedbackState.sent:
				processState = FeedbackState.sent
				break
			case FeedbackState.confirmed:
				processState = FeedbackState.sent
				break
			default:
				processState = FeedbackState.viewed
		}
	} else {
		//sender
		switch (currentProcessState) {
			case FeedbackState.sent:
				processState = FeedbackState.confirmed
				break
			case FeedbackState.confirmed:
				processState = FeedbackState.sayDo
				break
			default:
				processState = FeedbackState.viewed
		}
	}
	return processState
}
export function generateNewAction() {
	return {
		_id: uniqeId(),
		description: '',
	}
}
