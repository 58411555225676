import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

export type Styles = {
	isRtlDirection?: boolean
	notification?: string
}
export const useStyles = makeStyles<Theme, Styles>(({ breakpoints }) => ({
	screenWrapper: {
		direction: ({ isRtlDirection }) => (isRtlDirection ? 'rtl' : 'ltr'),
		width: '100%',
		overflowX: 'hidden',
		display: 'flex',
		flexDirection: 'column',
		[breakpoints.down('sm')]: {
			height: '100vh',
		},
	},
	questionTitle: {
		color: '#46484A',
		fontFamily: 'Sora, sans-serif',
		fontSize: '2rem',
		overflowWrap: 'break-word',
		maxWidth: '727px',
		width: '100%',
		textAlign: 'center',
		fontWeight: 'bold',
		margin: '7.2% 0 9%',
		[breakpoints.down('md')]: {
			margin: '3.2% 0 4%',
			fontSize: '1.5em',
		},
		[breakpoints.down('sm')]: {
			width: '80%',
			margin: '3% 0',
			// fontSize: '0.5em',
		},
	},

	container: {
		maxWidth: '657px',
		width: '100%',
		height: '86vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		alignSelf: 'center',
		[breakpoints.down('md')]: {
			maxWidth: '100%',
		},
		[breakpoints.down('sm')]: {
			justifyContent: 'space-between',
			// height: 'auto',
			flex: 1,
		},
	},

	backgroundImageStyle: {
		width: '600px',
		height: '600px',
		backgroundPosition: 'bottom left',
		position: 'fixed',
		left: '-134px',
		opacity: '6%',
		bottom: '-74px',
		zIndex: -1,

		[breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	subTitle: {
		color: '#646464',
		marginBottom: '45px',
		textAlign: 'center',
		[breakpoints.down('xs')]: {
			padding: '0 5% 0 5%',
			marginBottom: '2%',
		},
	},
}))
