import EventsList from 'components/Debriefing/EventsList'
import { EMPTY_STATE } from '../useTreeLogic'

//  CHRONOLOGICAL_EVENTS:
export const RenderChronologicalEventsChoice = ({ ...props }) => {
	const {
		debrief,
		updateDebrief,
		wrapperRef,
		isNextDisabled,
		setDisableNext,
		setNextData,
	} = props

	const pageIcon = {
		src: 'tapIcon',
		opacity: '',
	}
	const content = (
		<EventsList
			wrapperRef={wrapperRef}
			isNextDisabled={isNextDisabled}
			setDisableNext={setDisableNext}
			onMatchPointChoice={false}
			setData={setNextData}
			updateData={updateDebrief}
			debrief={debrief}
		/>
	)

	return { ...EMPTY_STATE, content, pageIcon }
}
