import { useContext, useState } from 'react'

import { AppDirectionContext } from 'context/languages-context'

import useStyles from './styled'

import {
	ItemDescription,
	ItemMembers,
	ItemDate
} from 'utils/generic-task-items/index'
import LessonButtons from './LessonRowButtons'
import BotAnalysisModal from '../self-analysis/SelfAnalysis'
import BotflowAnswer from '../self-analysis/BotflowAnswer'
import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'

import IUser from 'interface/user/user-interface'
import {
	ILessonRow,
	Lesson,
	Summary,
} from 'interface/debrief/debrief-interface'
import { useLessonsLogic } from 'components/Debriefing/Learningprocessoutputs/useLessonsHook'
import MembersList from '../members-list'
import { useTranslation } from 'react-i18next'
import { BotAnswerAnalysis } from 'enums/debriefing/debriefings-analysis.enum'
import useMembersList from './useMembersList'
import ConfirmModalSB from '../../../common/confirmModalSB/ConfirmModal'
import { useDispatch } from 'react-redux'
import { userActions } from 'redux/user/user-reducer'
import { useHistory } from 'react-router-dom'
import { DashboardPaths } from 'enums/routes/path-names'

type LessonRowProps = {
	toggleShowEmptyLesson: () => void
	onDelete?: (_id: string) => void
	updateAnalysis?: (answer: string, sction: BotAnswerAnalysis) => void
	matchPointColor: string
	allowEditAccess: boolean
	creator: IUser
	lesson?: ILessonRow
	participants?: IUser[]
	shouldViewAnalysis?: boolean
	mp_id: string
}
const LessonRow = ({
	toggleShowEmptyLesson,
	updateAnalysis,
	onDelete,
	matchPointColor,
	lesson,
	allowEditAccess,
	creator,
	participants,
	mp_id,
	shouldViewAnalysis,
}: LessonRowProps) => {
	const [showMembersList, setShowMembersList] = useState<boolean>(false)
	const [acceptDeleteLesson, setAcceptDeleteLesson] = useState<boolean>(false)
	const [isBotAnalysisOpen, setsetIsBotAnalysisOpen] = useState<boolean>(false)

	const {
		description = '',
		date,
		_id = '',
		isLeader = true,
		summary = { assumption: '', actionAnswer: '' },
	} = lesson ?? ({} as ILessonRow)

	const history = useHistory()
	const dispatch = useDispatch()
	const openMembersAndParticipants = () => allowEditAccess && setShowMembersList(prev => true)
	const closeMembersModal = () => setShowMembersList(false)
	const toggleAcceptDeleteLessonModal = () => setAcceptDeleteLesson(prev => !prev)
	const toggleBotAnalysisModal = () => setsetIsBotAnalysisOpen(prev => !prev)
	const handleDeleteLesson = () => onDelete!(_id!)

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection, matchPointColor })
	const { t } = useTranslation()

	const { update: updateLesson, save: patchNewLesson } = useLessonsLogic(
		{ event_id: mp_id } as Summary
	)

	const handleSaveLesson = (description: string) => {
		toggleShowEmptyLesson()

		const lessonDetails = { ...lesson, description, members_id: [creator._id] } as Lesson
		if (_id) {
			updateLesson(lessonDetails)
			return
		}
		patchNewLesson(lessonDetails)
	}

	const windowWidth = useWindowWidth()
	const smallMobildWidth = 844
	const mediumMobildWidth = 896
	const midLargMobileWidth = 915
	const isMobile =
		smallMobildWidth >= windowWidth ||
		mediumMobildWidth >= windowWidth ||
		midLargMobileWidth >= windowWidth

	const updateDate = (day: Date) => {
		if (!lesson) return

		const { _id, description, members_id } = lesson
		const payload: Lesson = { _id, description, members_id, date: day }

		updateLesson(payload)
	}
	const handleCopiedLesson = () => {
		dispatch(userActions.lessonIsCopied(_id))
		history.push(DashboardPaths.LESSONS_MANAGEMENT)
	}
	const { membersObjects, participantsObjects } = useMembersList({
		participants,
		record: lesson,
	})

	const updateMembersList = (list: string[]) => {
		if (!lesson) return

		const { _id, description } = lesson
		const payload: Lesson = { _id, description, members_id: list, date: date }
		updateLesson(payload)
	}

	const { actionAnswer, assumption } = summary
	const displayAnalysis = (
		<>
			{' '}
			<BotflowAnswer
				title={t('conclusion_what_was_done')}
				botAnswer={actionAnswer}
				saveDescription={answer =>
					updateAnalysis!(answer, BotAnswerAnalysis.ACTION_ANSWER)
				}
			/>
			<BotflowAnswer
				title={t('conclusion_what_was_assumption')}
				botAnswer={assumption}
				saveDescription={answer =>
					updateAnalysis!(answer, BotAnswerAnalysis.ASSUMPTION)
				}
			/>
		</>
	)
	const selfAnalysisNode = shouldViewAnalysis ? displayAnalysis : t('cant_view')
	return (
		<>
			<div className={classes.lessonContainer}>
				<ItemDescription
					handleSaveInput={handleSaveLesson}
					allowEditAccess={allowEditAccess}
					matchPointColor={matchPointColor}
					_id={_id!}
					description={description}
					label={'lesson'}
				/>

				{!isMobile && (
					<div className={classes.customLessonSection}>
						<ItemMembers
							creator={creator}
							matchPointColor={matchPointColor}
							openMembersAndParticipants={openMembersAndParticipants}
							membersObjects={membersObjects}
						/>

						<ItemDate
							matchPointColor={matchPointColor}
							date={date}
							isLeader={isLeader}
							updateDate={updateDate}
						/>

						{!isMobile && _id && (
							<span className={classes.deleteSpan}>
								<LessonButtons
									onCopiedLesson={handleCopiedLesson}
									allowEditAccess={allowEditAccess}
									matchPointColor={matchPointColor}
									toggleBotAnalysisModal={toggleBotAnalysisModal}
									toggleAcceptDeleteLessonModal={toggleAcceptDeleteLessonModal}
								/>
							</span>
						)}
					</div>
				)}
			</div>
			<ConfirmModalSB
				removeButtons
				isOpen={isBotAnalysisOpen}
				child={
					<BotAnalysisModal
						closeModal={toggleBotAnalysisModal}
						node={selfAnalysisNode}
					/>
				}
			/>
			<ConfirmModalSB
				text={t('delete_lesson')}
				isOpen={acceptDeleteLesson}
				onClose={toggleAcceptDeleteLessonModal}
				onConfirm={handleDeleteLesson}
				closeButtonText={t('no')}
				confirmButtonText={t('yes')}
			/>
			<MembersList
				updateMembersList={updateMembersList}
				isOpen={showMembersList}
				onClose={closeMembersModal}
				members={membersObjects}
				participants={participantsObjects!}
			/>
		</>
	)
}

export default LessonRow
