import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles<Theme, { isRtlDirection: boolean }>(
	({ palette, breakpoints }) => ({
		personalInfoContainer: {
			maxWidth: 353,
			width: '38vh',
			backgroundColor: '#FFFFFF',
			borderRadius: '6px',
			color: '#fff',
			padding: '26px 26px 25px 37px',
			position: 'absolute',
			top: '55px',
			right: ({ isRtlDirection }) => (isRtlDirection ? '-365px' : '-34px'),
			zIndex: 12,
			boxShadow: '0 5px 47px 0 rgba(210,214,233,0.59)',
			display: 'flex',
			flexDirection: 'column',
			// width: '100%',
			'&:after': {
				content: '""',
				position: 'absolute',
				right: '80%',
				left: '80%',
				top: -10,
				borderTop: 'none',
				borderRight: '15px solid transparent',
				borderLeft: '15px solid transparent',
				borderBottom: '15px solid #FFFFFF',
			},
			[breakpoints.down('sm')]: {
				position: 'fixed',
				right: 0,
				left: 0,
				top: 50,
				overflow: 'auto',
				width: '85vw',
			},
		},
		mobileError: {
			fontSize: '85%',
			padding: '2% 0 2% 0',
			color: 'red',
		},
		headerSection: {
			width: '100%',
			display: 'flex',
		},
		cameraIcon: {
			cursor: 'pointer',
			position: 'absolute',
			bottom: '-8px',
			right: 8,
		},
		avatarContainer: {
			position: 'relative',
		},
		userAvatar: {
			width: 100,
			height: 100,
			borderRadius: '50%',
		},
		inputContainer: {
			'& input': {
				width: ({ isRtlDirection }) =>
					isRtlDirection ? 'auto !important' : 'unset',
			},
			width: '100%',
			marginBottom: 24,
			marginTop: 24,
		},
		inputLabel: {
			color: '#000000',
			fontSize: 16,
			marginBottom: 13,
		},
		dropdownContainer: {
			flexDirection: ({ isRtlDirection }) =>
				isRtlDirection ? 'row-reverse' : 'row',
			justifyContent: ({ isRtlDirection }) =>
				isRtlDirection ? 'flex-end' : 'flex-start',
			display: 'flex',
		},

		input: {
			height: 45,
		},
		userInfo: {
			alignSelf: 'center',
			margin: ({ isRtlDirection }) =>
				isRtlDirection ? 'auto 5% auto auto' : 'auto auto auto 5%',

			'& > *': {
				color: 'rgb(70, 72, 74)',
			},
			'& h2': {
				marginTop: '0',
				marginBottom: '1%',
				fontSize: '100%',
			},
			'& h3': {
				marginTop: '0.5%',
				marginBottom: '0',
				fontWeight: 500,
				fontSize: '90%',
			},
		},
		updateButton: {
			marginTop: '2%',
			[breakpoints.down('sm')]: {
				marginTop: '5%',
				width: '77% !important',
			},
		},
		changePassword: {
			padding: '2%',
			color: '#0071C2',
			fontSize: 14,
			alignSelf: 'flex-start',
			// borderBottom: '1px solid #0071C2',
			cursor: 'pointer',
		},
		signOut: {
			padding: '2%',
			color: '#0071C2',
			textAlign: 'left',
			fontSize: 14,
			cursor: 'pointer',
		},
		bottomContainer: {
			justifyContent: 'space-between',
			display: 'flex',
		},
	})
)

export default useStyles
