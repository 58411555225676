import { Row } from './DebriefTable'

type Props = {
	data: Row[]
	setTableData: (data: Row[]) => void
}
export const useTableSearch = ({ data, setTableData }: Props) => {
	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchValue = e.target.value.toLowerCase()
		const check = (fullValue: string) => {
			return fullValue.toLowerCase().includes(searchValue)
		}
		let result: Row[] = []
		data.forEach((row: Row) => {
			if (
				check(row.date) ||
				check(row.number.toString()) ||
				check(row.result) ||
				check(row.creator) ||
				check(row.description)
				/*check(row.department) ||
				check(row.sub_department)*/
			) {
				result.push(row)
			}
		})
		setTableData(result)
	}

	return {
		handleSearch,
	}
}
