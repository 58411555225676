import React from 'react'
import { ClickAwayListener } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { lessonCopiedSelector } from 'redux/user/user-selector'
import { userActions } from 'redux/user/user-reducer'

import './style.css'

import useFoldersNavigation from './useFoldersNavigation'
import useModal from '../modal/useModal'
import StickyBar from '../stickyBar/StickyBar'
import Main from '../main/index'
import CompanyFoldersModal from '../modal/index'

const CompanyFoldersManagement = () => {
	const itemSelectedFromStore = useSelector(lessonCopiedSelector)
	const { currentFolder, navigate } = useFoldersNavigation()

	const dispatch = useDispatch()

	const abortCopy = () => dispatch(userActions.lessonIsCopied(''))

	const {
		content: modalContent,
		openModal,
		handleClosePopup,
		handleCreateFile,
		handleCreateFolder,
		handleEditFolder,
		handleCreateTitle,
		onAcceptModal,
		modalBtnLabel,
		toggleEmptyLesson,
		showEmptyLesson,
		insertNewLesson,
		handleDeleteFolder,
		isAcceptConfirm,
		updateTitle,
		updateLesson,
		handleValue,
		handleDeleteLesson
	} = useModal({ currentFolder })

	const handleModalEvents = {
		createFile: handleCreateFile,
		createFolder: handleCreateFolder,
		editFolder: handleEditFolder,
		createTitle: handleCreateTitle,
		toggleEmptyLesson: toggleEmptyLesson,
		deleteFolder: handleDeleteFolder,
		deleteLesson: handleDeleteLesson,
		updateTitle: updateTitle,
		updateLesson: updateLesson,
		lessonDescription: handleValue
	}

	const buttonsStyle = 'stick-buttons'

	return (
		<React.Fragment>
			<StickyBar
				currentFolder={currentFolder}
				navigate={navigate}
				{...handleModalEvents}
			/>

			<Main
				currentFolder={currentFolder}
				navigate={navigate}
				showEmptyLesson={showEmptyLesson}
				insertNewLesson={insertNewLesson}
				{...handleModalEvents}
			/>

			{itemSelectedFromStore && (
				<StickyBar
					isHeader={false}
					handleClose={abortCopy}
					currentFolder={currentFolder}
					onLessonPasted={insertNewLesson} />
			)}

			<ClickAwayListener onClickAway={handleClosePopup}>
				<CompanyFoldersModal
					child={modalContent}
					isOpen={openModal}
					onConfirm={onAcceptModal}
					isConfirmAccepted={isAcceptConfirm}
					onClose={handleClosePopup}
					closeButtonText='close'
					buttonsStyle={buttonsStyle}
					confirmButtonText={modalBtnLabel}
				/>
			</ClickAwayListener>
		</React.Fragment>
	)
}

export default CompanyFoldersManagement