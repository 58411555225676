import {
	FC,
	memo,
	useContext,
	useEffect,
	useRef,
	useState,
	useLayoutEffect,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'

import classes from './styles.module.css'
import Header from '../Header'
import IUser from 'interface/user/user-interface'
import noActionsIcon from 'assets/noActionPlanIcon.svg'
import calendarIcon from '../../../assets/calendarIcon.svg'
import plusIcon from '../../../assets/plusIcon.svg'
import blockageIcon from '../../../assets/blockageIcon.svg'
import openBlockageIcon from '../../../assets/openBlockageIcon.svg'
import { renderParticipant } from '../Confirmation'
import { uniqeId } from 'utils/helpers'
import ActionInput from './ActionInput'
import Button from 'components/common/Button'
import { BlockageAction, FeedbackOutputWrapperProps } from 'interface/bot'

import { FeedbackState } from 'enums/feedback-state/feedback-state.enum'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'
// import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal'
import DatePicker from 'components/Bot/Modal/DatePicker'
import { CloseIcon } from 'components/common/CloseIcon'
import ContactNow from '../ContactNow/ContactNow'
import { feedbackErrorSelector } from 'redux/feedback/feedback-selector'
import { feedbackActions } from 'redux/feedback/feedback-reducer'
import SaydoButtonContent from 'components/common/SaydoButtonContent/SaydoButtonContent'
import { useTranslation } from 'react-i18next'
import { AppDirectionContext } from 'context/languages-context'
import { generateNewAction } from 'screens/FeedbackProcess/FeedbackProcess'
import { deleteTask } from 'api/task'
import {
	ActionPlanButtonEnum,
	ButtonWithScrollDownOption,
} from '../ButtonWithScrollDownOption'
import { getButtonWithScrollText } from 'utils/getButtonWithScrollText'

interface Props {
	isReceiver: boolean
	onBlockageOpen: (isOpen: boolean) => void
	blockageOpened: boolean
	receiver: IUser
	openDatePicker: string
	onDatePickerOpen: (id: string) => void
	handleActionPlan: (
		senderActions: BlockageAction[],
		recevierActions: BlockageAction[]
	) => void
	update: (participant: string, actions: BlockageAction[]) => void
	disableSend: () => void
	actions: Actions
	handleToggleReview: () => void
}

const ActionPlan: FC<FeedbackOutputWrapperProps & Props> = props => {
	const {
		sender,
		receiver,
		onDatePickerOpen,
		handleActionPlan,
		onBlockageOpen,
		update,
		disableSend,
		blockageOpened,
		openDatePicker,
		isReceiver,
		URGENCYLEVEL,
		FEEDBACK_STATE,
		actions,
		handleToggleReview,
	} = props

	const { t } = useTranslation()

	const error = useSelector(feedbackErrorSelector)
	const dispatch = useDispatch()

	const { isRtlDirection } = useContext(AppDirectionContext)

	const [planError, setPlanError] = useState('')
	const receiverName = receiver?.personalInfo.firstName
	const senderName = sender?.personalInfo.firstName
	const isSender = !isReceiver
	const planSent = FEEDBACK_STATE === FeedbackState.sent

	const planConfirmed = FEEDBACK_STATE === FeedbackState.confirmed
	const readOnly = isSender || blockageOpened
	const handlePlan = () => {
		const clearEmpty = (actions: BlockageAction[]) => {
			return actions.filter(action => action.description.trim() !== '')
		}
		const senderActions = clearEmpty(actions.sender)
		const receiverActions = clearEmpty(actions.receiver)
		if (receiverActions.length === 0) {
			setPlanError(t('action_plan_error'))
			disableSend()
			return
		}
		handleActionPlan(senderActions, receiverActions)
	}

	useEffect(() => {
		if (error) {
			setPlanError(error)
			dispatch(feedbackActions.clearError())
			onBlockageOpen(true)
		}
	}, [error])

	const updateActionDate = (dueDate: Date, participant: string, id: string) => {
		const actions = [...getParticipantActions(participant)]
		const updatedActions = actions.map((action: BlockageAction) => {
			if (action._id === id) return { ...action, dueDate }
			return action
		})
		update(participant, updatedActions)
	}

	const updateActionDescription = (
		description: string,
		participant: string,
		id: string
	) => {
		//	section2Ref.current?.scrollIntoView({ behavior: 'smooth' })
		const actions = [...getParticipantActions(participant)]
		const updatedActions = actions.map((action: BlockageAction) => {
			if (action._id === id) return { ...action, description } //if its exist action so update it
			return action // if it's new action return it
		})
		update(participant, updatedActions)
	}
	const deleteAction = (participant: string, id: string) => {
		const actions = [...getParticipantActions(participant)]
		deleteTask({ company_id: sender.company_id, task_id: id })

		const updatedActions = actions.filter((action: BlockageAction) => {
			// dispatch delete action to tasks service!

			return action._id !== id
		})
		update(participant, updatedActions)
	}
	const resetAction = (participant: string, id: string) => {
		const actions = [...getParticipantActions(participant)]
		const updatedActions = actions.map((action: BlockageAction) => {
			if (action._id === id) return { _id: action._id, description: '' }
			return action
		})
		update(participant, updatedActions)
	}
	const setNewAction = (participant: string) => {
		const actions = [...getParticipantActions(participant), generateNewAction()]
		update(participant, actions)
	}
	const containerRef = useRef<any>()

	useLayoutEffect(() => {
		if (!containerRef?.current) return
		containerRef.current.scrollIntoView({ behavior: 'smooth' })
		return () => {}
	}, [containerRef?.current])

	const noActionPlan =
		actions.sender.length === 0 && actions.receiver.length === 0 && isSender
	const options = { senderName }
	const getParticipantActions = (participant: string) => {
		return participant === Plan.sender ? actions.sender : actions.receiver
	}
	const renderPlusButton = (
		participant: string,
		isRtlDirection: boolean = false
	) => {
		const participantActions = getParticipantActions(participant)
		//	const ref = participant === Plan.sender ? section1Ref : section2Ref

		return (
			participantActions[participantActions.length - 1].description && (
				<div
					style={{ flexDirection: isRtlDirection ? 'row-reverse' : 'row' }}
					className={classes.action}
					onClick={() => {
						//	ref.current?.scrollIntoView({ behavior: 'smooth' })
						setNewAction(participant)
					}}
				>
					<img
						onClick={e => e.stopPropagation()}
						style={{ opacity: 0, cursor: 'unset' }}
						className={classes.calenderIcon}
						src={calendarIcon}
					/>

					<div className={`${classes.actionInput} ${classes.plusInput}`}>
						{t('save')}
						{/* <img src={plusIcon} className={classes.plusIcon} /> */}
					</div>
				</div>
			)
		)
	}
	const renderSection = (participant: string, isRtlDirection: boolean) => {
		const participantRole = participant === Plan.sender ? sender : receiver
		const participantActions = getParticipantActions(participant)
		const isDatePickerOpen = participantActions.find(
			action => action._id === openDatePicker
		)
		const today = new Date()
		//const ref = participant === Plan.sender ? section1Ref : section2Ref
		return (
			<div className={classes.actionsSection}>
				<div
					className={
						isRtlDirection
							? `${classes.stickToTop} ${classes.rtl}`
							: classes.stickToTop
					}
				>
					{renderParticipant(participantRole, false)}
				</div>
				<div className={classes.content}>
					{!isDatePickerOpen &&
						participantActions.map((action: BlockageAction) => (
							<ActionInput
								readOnly={readOnly}
								participant={participant}
								action={action}
								onDatePickerOpen={onDatePickerOpen}
								reset={resetAction}
								updateDescription={updateActionDescription}
								deleteAction={deleteAction}
								deleteOption={participantActions.length > 1}
							/>
						))}
					{isDatePickerOpen && (
						<>
							<div
								onClick={e => {
									e.stopPropagation()
								}}
								className={classes.datepicker}
							>
								<div className={classes.closeButton}>
									<CloseIcon
										width='1.5rem'
										height='1.5rem'
										handleClick={() => onDatePickerOpen('')}
									/>
								</div>
								<DatePicker
									disabledDays={{ before: today }}
									selectedDay={new Date()}
									handleDayClick={(day: Date) => {
										if (day.getDate() < today.getDate()) return ''
										updateActionDate(day, participant, openDatePicker)
										onDatePickerOpen('')
									}}
								/>
							</div>
						</>
					)}
					{!readOnly &&
						!isDatePickerOpen &&
						renderPlusButton(participant, isRtlDirection)}
				</div>
			</div>
		)
	}

	const buttonText = getButtonWithScrollText({
		noActionPlan,
		blockageOpened,
		planConfirmed,
		isSender,
		planSent,
		isReceiver,
		actions,
	})
	return (
		<div
			className={
				isRtlDirection
					? `${classes.ActionPlanContainer} ${classes.rtl}`
					: classes.ActionPlanContainer
			}
		>
			<Header
				blockageOpened={blockageOpened}
				title={
					blockageOpened
						? t('well_done', {
								name: isSender ? senderName : receiverName,
								interpolation: { escapeValue: false },
						  })
						: isSender
						? senderName + ','
						: receiverName + ','
				}
				paragraph={
					blockageOpened
						? [
								t('blockage_removed', {
									name: isSender ? receiverName : senderName,
									interpolation: { escapeValue: false },
								}),
						  ]
						: isSender && noActionPlan
						? [
								t('action_plan_will_appear_here', {
									receiverName,
									interpolation: { escapeValue: false },
								}),
						  ]
						: isSender
						? [
								t('this_is_the_action_plan_you_received', {
									receiverName,
									interpolation: { escapeValue: false },
								}),
						  ]
						: [
								t('build_action_plan', {
									senderName,
									interpolation: { escapeValue: false },
								}),
								t('action_plan_step_1', {
									senderName,
									interpolation: { escapeValue: false },
								}),
								t('action_plan_step_2', {
									senderName,
									interpolation: { escapeValue: false },
								}),
								t('action_plan_step_3', {
									senderName,
									interpolation: { escapeValue: false },
								}),
						  ]
				}
				urgency={URGENCYLEVEL}
				renderReviewBtn={true}
				readed={props.readed}
				toggleReviewBtn={handleToggleReview}
			/>

			{noActionPlan ? (
				<>
					<div className={classes.noActionPlan}>
						<h2>
							{t('waiting_for_action_plan_text_1', {
								receiverName,
								interpolation: { escapeValue: false },
							})}{' '}
						</h2>
						<h3>{t('waiting_for_action_plan_text_2')} 😃</h3>
					</div>
					<img className={classes.noActionPlanImg} src={noActionsIcon} />
				</>
			) : (
				<div className={classes.actionsSections}>
					{renderSection(Plan.receiver, isRtlDirection)}
					<img
						className={classes.blockageImg}
						src={blockageOpened ? openBlockageIcon : blockageIcon}
					/>
					{renderSection(Plan.sender, isRtlDirection)}
				</div>
			)}

			{!noActionPlan && !blockageOpened ? (
				<ButtonWithScrollDownOption>
					<Button
						className={classes.planButton}
						onClick={handlePlan}
						isInverted={isSender ? !planSent && !planConfirmed : !actions.canSend}
						width={250}
					>
						{planConfirmed && isSender ? (
							<SaydoButtonContent whiteIcon />
						) : planSent && isSender ? (
							t(ActionPlanButtonEnum.PlanApproved)
						) : (
							t(ActionPlanButtonEnum.SendAction)
						)}
					</Button>
				</ButtonWithScrollDownOption>
			) : (
				<ButtonWithScrollDownOption text={buttonText} options={options} />
			)}

			{planSent && isSender && (
				<div className={classes.contactNow}>
					<ContactNow
						formText={false}
						title={true}
						contactInfo={{
							name: receiverName,
							mobile: receiver?.personalInfo.mobile,
						}}
					/>
				</div>
			)}
			<ConfirmModalSB
				isOpen={planError ? true : false}
				onClose={() => setPlanError('')}
				text={planError}
				closeButtonText={t('ok_got_it')}
			/>
		</div>
	)
}

export default ActionPlan

export enum Plan {
	ongoingDueDate = 'On going',
	sender = 'sender',
	receiver = 'receiver',
}

export interface Actions {
	canSend: boolean
	sender: BlockageAction[]
	receiver: BlockageAction[]
}
