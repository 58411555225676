import { useRef, useEffect, ChangeEvent, KeyboardEvent, useContext } from 'react'
import { ClickAwayListener, TextareaAutosize } from '@material-ui/core'

import useStyles from './styled'

import { AppDirectionContext } from 'context/languages-context'

import { teamsActions } from 'redux/teams/teams-reducer'
import { useDispatch } from 'react-redux'

type InputProps = {
    handleClickAway: () => void
    handleSaveInput: (description: string) => void
    matchPointColor?: string
    description: string
}
function ItemInput(props: InputProps) {
    const { handleSaveInput, handleClickAway, matchPointColor, description = '', } = props

    const dispatch = useDispatch()
    const { isRtlDirection } = useContext(AppDirectionContext)
    const inputRef = useRef<HTMLTextAreaElement>(null)

    useEffect(() => {
        dispatch(teamsActions.clearUpdateProcess())
        if (!inputRef.current) return

        inputRef.current.value = description
        inputRef.current.selectionStart = description.length
    }, [description])

    const classes = useStyles({ isRtlDirection, matchPointColor })

    const handleChangeDescription = ({
        target: { value },
    }: ChangeEvent<HTMLTextAreaElement>) => {
        if (!inputRef.current) return

        inputRef.current!.value = value
    }

    const handleKeyDown = ({
        key
    }: KeyboardEvent<HTMLTextAreaElement>) => {
        if (!isEnterKeyDown(key)) return

        handleSaveInput(inputRef?.current!.value)
    }

    const isEnterKeyDown = (key: string) =>
        key === 'Enter' && isDescriptionChanged()

    const isDescriptionChanged = () =>
        inputRef?.current!.value.trim() && inputRef?.current!.value !== description

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <TextareaAutosize
                aria-label='item-input'
                autoFocus
                className={classes.lessonInputField}
                ref={inputRef}
                onChange={handleChangeDescription}
                onKeyDown={handleKeyDown} />
        </ClickAwayListener>
    )
}

export default ItemInput