import classes from './styles.module.css'
import blockageClosedIcon from 'assets/feedbackGreen.svg'
import blockageClosedIconWhite from 'assets/feedbackWhite.svg'
import { useContext } from 'react'
import { AppDirectionContext } from 'context/languages-context'

interface Props {
	whiteIcon?: boolean
}

const SaydoButtonContent = ({ whiteIcon }: Props) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	return (
		<div
			style={{
				flexDirection: isRtlDirection ? 'row-reverse' : 'row',
				color: whiteIcon ? 'white' : '#34ab53',
			}}
			className={classes.blockageClosedContainer}
		>
			I Trust You
			{/* Say
			<img
				className={classes.blockageClosedIcon}
				src={whiteIcon ? blockageClosedIconWhite : blockageClosedIcon}
			/>
			Do */}
		</div>
	)
}

export default SaydoButtonContent
