import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { botActions } from 'redux/bot/bot-reducer'
import { QuestionType } from '../../../interface/praise/praise-interface'

import { ActionButtonsType } from 'components/Bot/ActionButtons'
import { UseClickActions } from '../useHooks/index'
import { OutputType } from 'screens/BotOutput'

import Paths from 'enums/routes/path-names'
import { isSameMonth } from 'utils/helpers'

export const finishingIds = [
	'6148711ca228d89a71ef9abe',
	'617fe609b23c5d64143a0f5d',
]

const useLogic = (props: ActionButtonsType) => {
	const {
		type,
		isPraise,
		answer,
		isEnabled,
		previousQuestionId,
		setQuestion,
		removeIds,
		setOpenModal,
		handleSaveFurtherImpacts,
	} = props
	const location: any = useLocation()
	const history = useHistory()
	const dispatch = useDispatch()
	const { fetchQuestion } = UseClickActions()

	const sendUserToOutput = () => {
		fetchQuestion(isPraise!)
		history.push({
			pathname: Paths.OUTPUT,
			state: {
				screenToReturn: location.state.screenToReturn,
				outputScreenType: isPraise
					? OutputType.PraiseSender
					: OutputType.FeedbackSender,
				isPraise: isPraise!,
			},
		})
	}

	const addUserAnswer = (qustionId: string) => {
		dispatch(botActions.addAnswer(answer!))
		if (finishingIds.includes(qustionId)) {
			sendUserToOutput()
			// check if setQuestion bellow is relevant when user is dispatched to output!
			setQuestion(qustionId)
			return
		}

		const nextQuestion =
			qustionId ?? answer?.nextQuestion ? answer?.nextQuestion : ''

		fetchQuestion(isPraise!, nextQuestion)
	}

	const goToPreviousStep = () => {
		const isDateQuestionType = type === QuestionType.DateChoice
		const previousStep = previousQuestionId
			? previousQuestionId
			: answer?.previousQuestion

		if (isDateQuestionType && isSameMonth()) {
			// check if setQuestion bellow is relevant when user is dispatched to output!
			setQuestion(null)
			return
		}

		fetchQuestion(!!isPraise, previousStep)
		dispatch(botActions.removeAnswer())
	}

	const goToNextStep = (qustionId: string) => {
		const userAnswer = answer?.answer?.trim()
		const userMultiChoiceAnswer = answer?.multiChoiceAnswer
		const multiAnswers = userMultiChoiceAnswer?.filter(Boolean)
		const hasMultiAnswer = multiAnswers?.length
		const hasCompanyValues = answer?.values?.length

		const isAbleToContinue = userAnswer || hasCompanyValues || isEnabled

		if (hasMultiAnswer) return setOpenModal && setOpenModal()

		// check another implementationgor the code bellow!
		if (handleSaveFurtherImpacts) {
			const nextQuestion =
				qustionId ?? answer?.nextQuestion ? answer?.nextQuestion : ''

			fetchQuestion(isPraise!, nextQuestion)

			handleSaveFurtherImpacts()
			return
		}

		if (!isAbleToContinue) return
		addUserAnswer(qustionId)
		removeIds?.()
	}

	return { handleNextStep: goToNextStep, handlePreviousStep: goToPreviousStep }
}

export default useLogic

// export const useThrottle = (callback: any, timeOut: number) => {
// 	const [activeAction, setActiveAction] = useState(null)

// 	return (...params: any) => {
// 		if (!activeAction) {
// 			setActiveAction(callback)
// 			callback(...params)

// 			setTimeout(() => {
// 				setActiveAction(null)
// 			}, timeOut)
// 		}
// 	}
// }
