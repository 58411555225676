import { FC, useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { ModalNodeType } from 'screens/Tasks'
import ModalContentInfo from '../Content'
import IUser from 'interface/user/user-interface'
import { Langs } from '../../../../enums/app/index'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',

			backgroundColor: 'rgba(0, 0, 0, 0.1)',
			webkitTapHighlightColor: 'transparent',
		},
		paper: {
			backgroundColor: theme.palette.background.paper,

			boxShadow: theme.shadows[5],
			padding: '0 33px 0 49px',

			border: '1px solid #313131',
			borderRadius: '28px',
		},
		openModalTrigger: {
			cursor: 'pointer',
		},
		title: {
			fontFamily: 'Montserrat',
			fontWeight: 'bold',
			fontSize: '24px',

			margin: '19px 0 17px',
			color: theme.palette.primary.main,
		},
	})
)

export type LetsBeginModalProp = {
	remainingLicense?: { silver: number; bronze: number; gold: number }
	companyLanguage?: Langs
	company_id?: string
	onSave?: (employees: IUser[]) => void
	onError?: (error: string) => void
	openModalTriggerText?: string
	ModalNode: React.FC<ModalNodeType>
	// children
}

const ModalWrapper: FC<LetsBeginModalProp> = ({
	remainingLicense,
	onError,
	onSave,
	company_id,
	ModalNode,
	openModalTriggerText,
	companyLanguage,
}) => {
	const classes = useStyles()

	const [open, setOpenModal] = useState(false)

	const handleOpen = () => {
		setOpenModal(true)
	}

	const handleClose = () => {
		setOpenModal(false)
	}

	return (
		<div>
			<div onClick={handleOpen} className={classes.openModalTrigger}>
				{openModalTriggerText ?? 'Open Lets begin modal'}
			</div>
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				className={classes.modal}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<div className={classes.paper}>
						<ModalContentInfo
							companyLanguage={companyLanguage}
							remainingLicense={remainingLicense}
							company_id={company_id}
							onError={onError}
							onSave={onSave}
							ModalNode={ModalNode}
							closeModal={handleClose}
						/>
					</div>
				</Fade>
			</Modal>
		</div>
	)
}

export default ModalWrapper
