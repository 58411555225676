import { Button } from '@material-ui/core'
import { width } from '@mui/system'
import { OutputSettingType } from 'interface/bot'
import { useEffect, useState } from 'react'
import { ChangeHandler } from 'react-hook-form'
import { uniqeId } from 'utils/helpers'
import BotSettingsInput from './BotSettingsInput'
import classes from './styles.module.css'

export interface AnswerData {
	id: string
	icon: string
	nextQuestion: string
	subtitle: string
	title: string
	outputType: string
}
interface Props {
	rtl: boolean
	outputTypes: OutputSettingType[]
	currentAnswers: AnswerData[] | undefined
	questionsData: string[]
	update: (answers: AnswerData[]) => void
}
function newAnswer() {
	return {
		id: uniqeId(),
		icon: '',
		nextQuestion: '',
		subtitle: '',
		title: '',
		outputType: '',
	}
}
const AnswersInputs = ({
	outputTypes,
	currentAnswers,
	questionsData,
	rtl,
	update,
}: Props) => {
	const [answers, setAnswers] = useState<AnswerData[] | undefined>(
		currentAnswers ? [...currentAnswers] : undefined
	)

	useEffect(() => {
		setAnswers(currentAnswers)
	}, [currentAnswers])
	const deleteAnswer = (id: string) => {
		const updatedAnswers = answers!.filter(
			(answer: AnswerData) => answer.id !== id
		)
		updatedAnswers && update(updatedAnswers)
	}

	const addAnswerInput = () => {
		answers !== undefined
			? setAnswers(prevState => [...prevState!, newAnswer()])
			: setAnswers([newAnswer()])
	}

	const updateValue = (
		id: string,
		property: string,
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const updatedAnswers = answers?.map((answer: AnswerData) => {
			if (answer.id === id) {
				let value = e.target.value
				if (property === 'outputType') {
					let type = outputTypes.filter(type => type.title === e.target.value)[0]
					if (type) {
						value = type._id
					}
				}
				return { ...answer, [property]: value }
			}
			return answer
		})
		updatedAnswers && update(updatedAnswers)
	}

	return (
		<>
			<h3>Answers</h3>
			<div className={classes.addAnswerButtonContainer}>
				<Button color={'secondary'} variant='contained' onClick={addAnswerInput}>
					Add answer
				</Button>
			</div>
			{answers &&
				answers.map((answer: AnswerData) => {
					const outputType = outputTypes.filter(
						type => type._id === answer.outputType
					)[0]
					console.log(answer.outputType)
					console.log(outputTypes)
					return (
						<div key={answer.id} className={classes.answerInput}>
							<div className={classes.answerTextInputs}>
								<Button
									className={classes.deleteButton}
									disabled
									onClick={() => deleteAnswer(answer.id)}
									style={{ backgroundColor: false ? 'tomato' : 'grey', color: 'white' }} // until button will work!
								>
									Delete
								</Button>
								<div className={classes.answerTitle}>
									<BotSettingsInput
										rtl={rtl}
										inputType='title_input'
										width='90%'
										inputName='title'
										defaultValue={answer.title}
										onChange={e => updateValue(answer.id, 'title', e)}
									/>
								</div>
								<div
									style={{ justifyContent: 'flex-start' }}
									className={classes.editorRow}
								>
									<BotSettingsInput
										rtl={rtl}
										inputType='title_input'
										width='50%'
										inputName='icon'
										defaultValue={answer.icon}
										onChange={e => updateValue(answer.id, 'icon', e)}
									/>
									<BotSettingsInput
										rtl={rtl}
										inputType='title_input'
										width='50%'
										inputName='Sub title'
										defaultValue={answer.subtitle}
										onChange={e => updateValue(answer.id, 'subtitle', e)}
									/>
								</div>
							</div>
							<BotSettingsInput
								inputType='question_select'
								inputName='Next Question'
								defaultValue={answer.nextQuestion}
								values={questionsData}
								onChange={e => updateValue(answer.id, 'nextQuestion', e)}
							/>
							<BotSettingsInput
								inputType='question_select'
								inputName='Output Type'
								defaultValue={outputType ? outputType.title : ''}
								values={outputTypes.map(type => type.title)}
								onChange={e => updateValue(answer.id, 'outputType', e)}
							/>
						</div>
					)
				})}
		</>
	)
}

export default AnswersInputs
