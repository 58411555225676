import { Langs } from 'enums/app'
import {
	Answers,
	BaseQuestion,
	QuestionValue,
	Text,
} from 'interface/praise/praise-interface'
import IUser from 'interface/user/user-interface'
import { Link, links } from 'components/common/Header/Mobile/constants'
import { Roles } from 'enums/user/user-roles'
import { License } from 'enums/License/License.enum'

export const filteredList = (role: Roles, licence = ''): Link[] => {
  return links.filter(link => {
    const permittedAuthLevels = link.authLevels.map((authLevel) => authLevel.toLowerCase() as Roles)

    const normalizedRole = role?.toLowerCase() as Roles

    if (!link.licenceType?.length && permittedAuthLevels.includes(normalizedRole)) return true

		const licenceAuthLink = link.licenceType?.includes(
			licence.trim().toLowerCase() as License
		)
		const hasLicenceAuth =
			link.licenceType?.length && licenceAuthLink && permittedAuthLevels.includes(normalizedRole)

		return hasLicenceAuth
	})
}

const stringsMapToOpenModal: Array<string> = [
	'Another way. How? Write briefly Continue',
	'Other personal goal - describe briefly',
]

export const generateGreetings = (hours: number): string => {
	let greeting = 'Hello'

	if (hours >= 6 && hours < 13) {
		greeting = 'good_morning'
	} else if (hours >= 13 && hours < 19) {
		greeting = 'good_afternoon'
	} else if (hours >= 19 && hours < 22) {
		greeting = 'good_evening'
	} else if (hours >= 22 || hours < 6) {
		greeting = 'good_night'
	}
	return greeting
}
export const mapUserMapToUsers = (userMap: Record<string, IUser>) => {
	let usersList: Array<IUser> = []
	for (let userKey in userMap) {
		usersList = [...usersList, userMap[userKey]]
	}
	return usersList
}

export const mapDataToQuestionType = (
	question: BaseQuestion<Answers, Text>,
	language: Langs
): BaseQuestion<Array<QuestionValue>, string> => {
	const default_language = 'en'
	return {
		...question,
		title: question.title[language ?? default_language],
		answers: question.answers[language ?? default_language],
		subtitle: question.subtitle[language ?? default_language],
	}
}

export const shouldOpenModal = (str: string): boolean => {
	return stringsMapToOpenModal.includes(str)
}

export const getUserInitials = (
	firstName: string = '',
	lastName: string = ''
): string => {
	return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
}

export const getGreetingCardDescription = (
	t: Function,
	tasksCount: number,
	feedbacksCount: number
): string => {
	let description: string

	if (tasksCount) {
		if (tasksCount > 1) {
			description = t('tasks_to_complete_plural', { count: tasksCount })
		} else {
			description = t('tasks_to_complete')
		}
		if (feedbacksCount > 1) {
			description += t('plus_feedback_recevied_plural', {
				count: feedbacksCount,
			})
		} else if (feedbacksCount) {
			description += t('plus_feedback_recevied')
		}
	} else if (feedbacksCount > 1) {
		description = t('feedbacks_received_plural', { count: feedbacksCount })
	} else if (feedbacksCount) {
		description = t('feedbacks_received')
	} else {
		description = t('no_tasks')
	}

	return description
}
