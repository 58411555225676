import { FC, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { AppDirectionContext } from 'context/languages-context'

import useStyles from './styles'

import {
	sentFeedbacksSelector,
	moreDraftsFeedbacks,
	moreReceivedFeedbacks,
	moreSentFeedbacks,
	feedbackLoadingSelector,
} from 'redux/feedback/feedback-selector'

import { useLogic } from './useLogic'
import { Type, OutputType } from '../BotOutput/index'

import CustomTab from 'components/common/Tabs/Row'
import MostRecent from 'components/common/MostRecent'
import Tabs from 'components/common/Tabs'
import SearchUser from 'components/common/SearchUser'

import Paths, { DashboardPaths } from 'enums/routes/path-names'
import { FeedbackState } from 'enums/feedback-state/feedback-state.enum'
import { setNotificationOld } from 'redux/notifications/notifications-action'
import { userSelector } from 'redux/user/user-selector'
import { ActionNameMapScreens } from 'utils/mapUrl'

const FeedbacksScreen: FC = () => {
	const dispatch = useDispatch()
	const [lastPanel, setLastPanel] = useState('')

	const classes = useStyles()
	const history = useHistory()
	const { t } = useTranslation()
	const { state }: any = useLocation()
	const { isRtlDirection } = useContext(AppDirectionContext)

	const hasMoreSentFeedbacks = useSelector(moreSentFeedbacks)
	const hasMoreReceivedFeedbacks = useSelector(moreReceivedFeedbacks)
	const hasMoreDraftsFeedbacks = useSelector(moreDraftsFeedbacks)
	const sentFeedbacks = useSelector(sentFeedbacksSelector)
	const isLoading = useSelector(feedbackLoadingSelector)
	const user = useSelector(userSelector)
	useEffect(() => {
		if (!sessionStorage.getItem('id')) return
		const id = sessionStorage.getItem('id') || ''
		setLastPanel(id)
	}, [])

	const {
		currentTab,
		feedbacksDraftMapped,
		handleChangeCurrentTab,
		handleSearchTextChange,
		lastReceived,
		lastSent,
		sentFeedbacMapped,
		receivedFeedbackMapped,
		nextPage,
	} = useLogic({ state })

	const infiniteScrollHeight = window.innerWidth > 400 ? 371 : 530
	const loading = <h4 className={classes.loader}>{'loading...'}</h4>
	const noFeedbacks = <h4 style={{ margin: '2% 2%' }}>{t('no_feedbacks')}</h4>
	const endMessage = (
		<p style={{ textAlign: 'center' }}>
			<b>{t('end_list')}</b>
		</p>
	)

	const panels = [
		<InfiniteScroll
			dataLength={0}
			next={() => nextPage(OutputType.FeedbackReciever)}
			hasMore={hasMoreReceivedFeedbacks}
			loader={isLoading ? loading : noFeedbacks}
			endMessage={endMessage}
			height={infiniteScrollHeight}
		>
			{receivedFeedbackMapped.map(
				({ id, imageUrl, description, date, name, readed, feedbackState }) => {
					let buttonText
					let buttonClass
					let buttonIcon
					switch (feedbackState) {
						case FeedbackState.sayDo:
							buttonText = ''
							buttonIcon = 'blockage_closed'
							buttonClass = classes.buttonGreen
							break
						case FeedbackState.start:
							buttonText = t('view_feedback')
							break
						default:
							buttonText = t('in_process')
							buttonClass = classes.buttonTransparent
					}

					return (
						<>
							<CustomTab
								lastPanelDisplay={lastPanel}
								key={id}
								id={id}
								imageUrl={imageUrl}
								date={date}
								name={name}
								handleClick={() => {
									dispatch(setNotificationOld({ id, receiver_id: user._id }))

									history.push(Paths.RECEIVED_FEEDBACK_PROCESS, {
										id,
										type: Type.Feedback,
										screenToReturn: DashboardPaths.FEEDBACKS,
										outputScreenType: OutputType.FeedbackReciever,
										tabState: 0,
										session: sessionStorage.setItem('id', id),
									})
								}}
								description={description}
								modalButtonText={buttonText}
								buttonClass={buttonClass}
								buttonIcon={buttonIcon}
								type='feedbacks'
								readed={readed}
							/>
						</>
					)
				}
			)}
		</InfiniteScroll>,
		<InfiniteScroll
			dataLength={sentFeedbacks.length}
			next={() => nextPage(OutputType.FeedbackViewing)}
			hasMore={hasMoreSentFeedbacks}
			loader={isLoading ? loading : noFeedbacks}
			endMessage={endMessage}
			height={infiniteScrollHeight}
		>
			{sentFeedbacMapped.map(
				({ id, imageUrl, date, name, description, readed, feedbackState }) => {
					let buttonText
					let buttonClass
					let buttonIcon = ''
					switch (feedbackState) {
						case FeedbackState.sayDo:
							buttonText = ''
							buttonIcon = 'blockage_closed'
							buttonClass = classes.buttonGreen
							break
						case FeedbackState.start:
							buttonText = t('view_feedback')
							break
						default:
							buttonText = t('in_process')
							buttonClass = classes.buttonTransparent
							buttonIcon = 'viewed'
					}

					return (
						<CustomTab
							lastPanelDisplay={lastPanel}
							key={id}
							id={id}
							handleClick={() => {
								dispatch(setNotificationOld({ id, receiver_id: user._id }))
								console.log({ id })
								history.push(Paths.SENT_FEEDBACK_PROCESS, {
									id,
									type: Type.Feedback,
									screenToReturn: DashboardPaths.FEEDBACKS,
									outputScreenType: OutputType.FeedbackViewing,
									tabState: 1,
									session: sessionStorage.setItem('id', id),
								})
							}}
							imageUrl={imageUrl}
							date={date}
							name={name || ''}
							description={description}
							userRequest={''}
							eventSummary={''}
							modalButtonText={buttonText}
							buttonClass={buttonClass}
							buttonIcon={buttonIcon}
							type='feedbacks'
							readed={readed}
						/>
					)
				}
			)}
		</InfiniteScroll>,
		<InfiniteScroll
			dataLength={feedbacksDraftMapped.length}
			next={() => nextPage(OutputType.FeedbackDraft)}
			hasMore={hasMoreDraftsFeedbacks}
			loader={isLoading ? loading : noFeedbacks}
			endMessage={endMessage}
			height={infiniteScrollHeight}
		>
			{feedbacksDraftMapped.map(({ id, imageUrl, date, name, description }) => (
				<CustomTab
					lastPanelDisplay={lastPanel}
					key={id}
					id={id}
					handleClick={() =>
						history.push(`/output/${id}`, {
							type: Type.Feedback,
							screenToReturn: DashboardPaths.FEEDBACKS,
							outputScreenType: OutputType.FeedbackDraft,
							tabState: 2,
							session: sessionStorage.setItem('id', id),
						})
					}
					imageUrl={imageUrl}
					date={date}
					name={name || ''}
					description={description}
					userRequest={''}
					eventSummary={''}
					modalButtonText={t('view_feedback')}
					type='feedbacks'
				/>
			))}
		</InfiniteScroll>,
	]

	return (
		<Grid item xs={11} sm={8} className={classes.container}>
			<MostRecent
				type={Type.Feedback}
				lastSentOutputId={lastSent._id!}
				lastReceivedOutputId={lastReceived._id!}
				actionName={ActionNameMapScreens.FEEDBACK}
				sentCoins={0}
				receivedCoins={0}
				recipient={lastSent.name}
				sender={lastReceived.name}
				sentDescription={lastSent.CONTEXT}
				receivedDescription={lastReceived.CONTEXT}
				sentDate={lastSent.date}
				receivedDate={lastReceived.date}
			/>
			<SearchUser
				handleChange={e => handleSearchTextChange(e, currentTab)}
				isRtlDirection={isRtlDirection}
			/>

			<Tabs
				title='feedbacks'
				panels={panels}
				tabState={state?.tabState}
				onTabChange={(tabNum: any) => handleChangeCurrentTab(tabNum)}
				tabs={[
					{ label: t('received') },
					{ label: t('sent') },
					{ label: t('draft') },
				]}
			/>
		</Grid>
	)
}

export default FeedbacksScreen
