import { FC, useState, useContext, useEffect, useMemo } from 'react'
import { CircularProgress } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useStyles from './styled'

import { AppDirectionContext } from 'context/languages-context'

import { userSelector } from 'redux/user/user-selector'
import { teamSelector } from 'redux/teams/teams-selector'

import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'
import TeamTask from './TeamTask'
import useTeamsRequests from './useTeamsRequest'
import InfoTooltip from 'utils/infoTooltip/informationTooltip'
import { getFullName } from 'components/HRsettings/DepartmentBuilder/builderHelper'
import useOutputFilterRule from 'utils/useOutputFilterRule'

import { SortingOrder } from 'enums/teams/sortingOrder.enum'
import SocketContext from 'context/socket-context'
import { TeamTodosEvents } from 'enums/teams/team-events.enum'
import useTeamSocketRespond from './useTeamSocket'
import useManageTasks from './useManageTasks'

import IUser from 'interface/user/user-interface'
import UserAvatar from 'components/common/Avatar'

type Props = {
	managerGoalsScreen?: boolean
}

const Team = ({ managerGoalsScreen }: Props) => {
	const [displayEmptyRecord, setDisplayEmptyRecord] = useState(false)
	const [tasksSortingOrder, setTasksSortingOrder] = useState(SortingOrder.ALL)
	const [showInfoModel, setShowInfoModel] = useState(false)

	const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })

	const socket = useContext(SocketContext)

	const user = useSelector(userSelector)
	const team = useSelector(teamSelector)

	const toggleShowEmptyTask = () => setDisplayEmptyRecord(prev => !prev)
	const closeEmptyTask = () => setDisplayEmptyRecord(prev => false)
	const toggleModalInfo = () => setShowInfoModel(prev => !prev)

	const { wsCreateTask } = useTeamSocketRespond()
	const { getTeamTasks, deleteTask: removeTask } = useTeamsRequests({
		managerGoalsScreen,
	})

	const { counterDomElements, filteredTeamTasks, resetFilteredTasks } =
		useManageTasks({ tasks: team.tasks })
	const { byEmployee } = useOutputFilterRule()

	useEffect(() => {
		if (!user._id) return

		getTeamTasks(tasksSortingOrder)

		return () => {
			resetFilteredTasks()
		}
	}, [user._id, tasksSortingOrder])

	useEffect(() => {
		socket?.on(TeamTodosEvents.CREATED_TASK, wsCreateTask)

		return () => {
			socket?.off(TeamTodosEvents.CREATED_TASK, wsCreateTask)
		}
	}, [user._id, socket, wsCreateTask])

	const managerInfo = useMemo(() => {
		if (!team.manager) return
		else return managerGoalsScreen ? t('you') : getFullName(team.manager)
	}, [team.manager])

	const windowWidth = useWindowWidth()
	const smallMobildWidth = 844
	const mediumMobildWidth = 896
	const midLargMobileWidth = 915
	const isMobile =
		smallMobildWidth >= windowWidth ||
		mediumMobildWidth >= windowWidth ||
		midLargMobileWidth >= windowWidth

	const sortingOrderIncrease = () => {
		team?.tasks?.length &&
			setTasksSortingOrder((prev: SortingOrder) => {
				switch (prev) {
					case SortingOrder.ALL:
						return SortingOrder.COMPLETE
					case SortingOrder.COMPLETE:
						return SortingOrder.NOT_COMPLETE
					case SortingOrder.NOT_COMPLETE:
						return SortingOrder.ALL
					default:
						return prev
				}
			})
	}

	const sortOrderLabel = useMemo(() => {
		return {
			[SortingOrder.ALL]: '',
			[SortingOrder.COMPLETE]: t('completed'),
			[SortingOrder.NOT_COMPLETE]: t('not_completed'),
		}[tasksSortingOrder]
	}, [tasksSortingOrder])

	const progressbar = (
		<p>
			<CircularProgress size={25} />
		</p>
	)
	const modalChild = <p>{t('team_task_info')}</p>
	const showAllTeamTasks =
		Boolean(team.tasks?.length) &&
		!Boolean(filteredTeamTasks?.length) &&
		!byEmployee

	return (
		<div className={classes.container}>

			<div className={classes.membersList}>
				{!!team?.members_id?.length && team.members_id.map((m: IUser, index: number) => {
					if (m._id == user._id) return

					return (<UserAvatar userInfo={m} key={index} />)
				})}
			</div>

			{team.manager ? (
				<p>
					{t('team_manager')}: {managerInfo}
				</p>
			) : (
				progressbar
			)}

			{!!team?.tasks?.length && !byEmployee && (
				<div className={classes.tasksCounter} onMouseOut={resetFilteredTasks}>
					{counterDomElements}
				</div>
			)}

			<div className={classes.title}>
				<p onClick={toggleShowEmptyTask}>{t('task')} +</p>
				{!isMobile && (
					<div>
						<div>
							<span>{t('owner')}</span>
						</div>
						<div>
							<div className={classes.dueDateHeader}>
								<span onClick={sortingOrderIncrease}>{t('task_due_date')}</span>
								<InfoTooltip
									isOpen={showInfoModel}
									text={modalChild}
									toggleModal={toggleModalInfo}
								/>
							</div>
							<span>{sortOrderLabel}</span>
						</div>
					</div>
				)}
			</div>

			<TeamTask.TasksWrapper>
				{displayEmptyRecord && (
					<TeamTask.TaskContainer>
						<Team.CreateTask toggleBtnTask={closeEmptyTask} />
					</TeamTask.TaskContainer>
				)}

				{showAllTeamTasks
					? team.tasks.map((task, index) => (
						<TeamTask
							manager_id={team?.manager?._id}
							key={index}
							isMobile={isMobile}
							participants={team.members_id}
							onDelete={removeTask}
							task={task}
							toggleShowEmptyTask={closeEmptyTask}
						/>
					))
					: filteredTeamTasks?.map((task, index) => (
						<TeamTask
							manager_id={team?.manager?._id}
							key={index}
							isMobile={isMobile}
							participants={team.members_id}
							onDelete={removeTask}
							task={task}
							toggleShowEmptyTask={closeEmptyTask}
						/>
					))}

				{!showAllTeamTasks && !filteredTeamTasks?.length && <p>{t('not_share')}</p>}
			</TeamTask.TasksWrapper>
		</div>
	)
}

export default Team

const CreateTask: FC<{ toggleBtnTask: () => void }> = ({ toggleBtnTask }) => {
	return <TeamTask toggleShowEmptyTask={toggleBtnTask!} />
}

Team.CreateTask = CreateTask
