import { useMemo } from 'react'
import { AddBoxOutlined, CreateOutlined, Folder } from '@material-ui/icons'
import { SecuritySharp } from '@material-ui/icons'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined'

import { LessonsIconLable } from 'enums/lessonsManagement/index'

type Props = {
	icon?: LessonsIconLable
}
const DrawIcon = ({ icon = LessonsIconLable.NONE }: Props) => {
	const displayIcon = useMemo(() => {
		return {
			[LessonsIconLable.NONE]: '',
			[LessonsIconLable.PENCIL]: <CreateOutlined />,
			[LessonsIconLable.PLUS_FOLDER]: <Folder />,
			[LessonsIconLable.PLUS]: <AddBoxOutlined />,
			[LessonsIconLable.SHIELD]: <AdminPanelSettingsOutlinedIcon />,
			[LessonsIconLable.CONVERSATION]: <MarkunreadOutlinedIcon />,
		}[icon!]
	}, [icon])

	const drawIcon = useMemo(() => {
		if (typeof displayIcon === 'string') {
			if (displayIcon == '') return <></>

			return <img src={displayIcon} alt={`${displayIcon}-icon`} />
		} else return displayIcon
	}, [displayIcon])

	return <span>{drawIcon}</span>
}

export default DrawIcon
