import { DashboardPaths } from 'enums/routes/path-names'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import makeBotStyles from '../../Bot/ActionButtons/useStyles'
import { useInnerStyles } from '../ActionButtons/index'
interface Props {}
export const WaitingParticipant = ({}: Props) => {
	const { t } = useTranslation()
	const botClasses = makeBotStyles()
	const actionButtonClasses = useInnerStyles({})
	const history = useHistory()
	return (
		<div className={botClasses.waitingParticipantWrapper}>
			<div>
				{t('debrief.waiting_participant')} <br />
				{t('debrief.waiting_participant_sub_title')}
			</div>
			<button
				onClick={() => history.go(-1)}
				className={`${botClasses.nextButton} ${actionButtonClasses.nextButton}`}
			>
				{t('back')}
			</button>
		</div>
	)
}

export default WaitingParticipant
