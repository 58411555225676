import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import classes from './styles.module.css'

import Header from '../Header'
import BeforeSendOutput from 'screens/BotOutput/beforeSending'

import { FeedbackOutputWrapperProps } from 'interface/bot'

const Review: FC<FeedbackOutputWrapperProps> = props => {
	const { t } = useTranslation()
	const { sender, receiver, URGENCYLEVEL, handleToggleReview } = props
	const receiverName = receiver?.personalInfo.firstName
	const senderName = sender?.personalInfo.firstName

	return (
		<div className={classes.reviewContainer}>
			<Header
				title={t('hi_user', {
					user: senderName,
					interpolation: { escapeValue: false },
				})}
				explanation={t('this_is_the_feedback_you_sent', {
					receiverName,
					interpolation: { escapeValue: false },
				})}
				paragraph={[``]}
				urgency={URGENCYLEVEL}
			/>
			<BeforeSendOutput
				handleToggleReview={handleToggleReview}
				{...props}
				width='100%'
				isFeedBackProcess
				isPraise={false}
			/>
		</div>
	)
}

export default Review
