import { StyleSheet, Text } from "@react-pdf/renderer"
import { AppDirectionContext } from "context/languages-context"
import { useContext } from "react"
import { useTranslation } from "react-i18next"

type Props = {
    matchPointColor: string
    description: string
}
const PdfLesson = ({ matchPointColor, description }: Props) => {
    const { isRtlDirection } = useContext(AppDirectionContext)

    const { t } = useTranslation()

    const borderLeft = isRtlDirection ? 'none' : `13px solid ${matchPointColor}`
    const borderRight = isRtlDirection ? `13px solid ${matchPointColor}` : 'none'
    const textAlign = isRtlDirection ? 'right' : 'left'
    const descriptionWidth = isRtlDirection ? '540px' : '650px'
    return (
        <Text style={[styles.lessonContainer, { borderLeft, borderRight, textAlign }]}>
            <Text style={{ color: matchPointColor }}>
                {t('lesson')}{': '}
            </Text>
            <Text style={{ display: 'flex', width: descriptionWidth }}>{description}</Text>
        </Text>
    )
}

export default PdfLesson

const styles = StyleSheet.create({
    lessonContainer: {
        width: '96%',
        minHeight: '45px',
        fontSize: '15px',
        fontWeight: 500,
        padding: '13px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 auto 1px auto',
        flexDirection: 'row',
        color: 'grey',
        backgroundColor: '#f5f4f4e8',
        fontFamily: 'Rubik'

    },
    description: {
        height: '100%',
        borderRight: '1px solid white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Rubik'
    },
})