import { FC } from 'react'
import { IUserGoalState } from 'interface/user/user-interface'
import classes from '../Goal/style.module.css'
import { BottomSectionItem } from './BottomSectionItem'
import { getIconByName, Text } from 'utils/Goals/getIconByName'

export const GoalCardBottomSection: FC<{ goal: IUserGoalState }> = ({
	goal,
}) => {
	return (
		<div className={classes.values}>
			<BottomSectionItem
				count={goal.blockages}
				icon={getIconByName(Text.BLOCKAGES)}
				naming={`${Text.BLOCKAGES}s`}
			/>
			<BottomSectionItem
				count={goal.praises.length}
				icon={getIconByName(Text.PRAISES)}
				naming={`${Text.PRAISES}_IU`}
			/>
			<BottomSectionItem
				count={goal.feedbacks.length}
				icon={getIconByName(Text.FEEDBACKS)}
				naming={`${Text.FEEDBACKS}_IU`}
			/>
		</div>
	)
}
