import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
	container: {
		width: '100%',
		maxWidth: '516px',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		[breakpoints.down('sm')]: {
			margin: '0 4%',
			height: '83vh',
		},
	},
	headerSection: {
		marginTop: '20px',
		padding: '15px ',
		display: 'flex',
		flexDirection: 'column',
		background: '#0071C2',
		borderRadius: '14px',
		[breakpoints.down('sm')]: {
			margin: '0 3% 0 3%',
		},
	},
	fistInstruction: {
		// margin: '10px 0 5px 0',
		fontFamily: 'Sora, sans-serif',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '1rem',
		color: '#46484A',
		[breakpoints.down('sm')]: {
			padding: '0 3%',
			margin: '30px 2.5% 0 2.5%',
		},
	},
	secondInstruction: {
		fontFamily: 'Sora, sans-serif',
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '1rem',
		color: '#46484A',
		[breakpoints.down('sm')]: {
			padding: '0 3%',
			margin: '30px 2.5% 0 2.5%',
		},
	},
	recepientUserDetails: {
		display: 'flex',
	},
	avatar: {
		width: '64px',
		height: '64px',

		[breakpoints.down('sm')]: {
			width: '46px',
			height: '46px',
		},
	},
	recepientUserDetailsNamAndRole: {
		marginInlineStart: '16px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	recepientUserDetailsName: {
		// fontFamily: 'Lucida Grande',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '1rem',
		color: '#FFFFFF',
	},
	recepientUserDetailsRole: {
		// fontFamily: 'Lucida Grande',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '0.5rem',
		color: '#FFFFFF',
	},

	YouWantToPraise: {
		margin: '19px 0 10px',

		fontFamily: 'Sora, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '1rem',
		color: '#FFFFFF',
		[breakpoints.down('sm')]: {
			margin: 0,
		},
	},
	thatsgreat: {
		fontFamily: 'Sora, sans-serif',
		fontStyle: 'normal',
		fontWeight: 300,
		fontSize: '1rem',

		color: '#FFFFFF',
	},
	checkboxAction: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '22px',
	},
	heartIconStyle: {
		width: '79px',
		height: '79px',
	},
	bottomSection: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: '10px 0 20px 0',

		[breakpoints.down('sm')]: {
			margin: 0,
			height: '16vh',
			justifyContent: 'center',
		},
	},

	iconWrapper: {
		[breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	instruction: {
		fontWeight: 700,
	},
	checkboxNote: {
		marginLeft: '10px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',

		[breakpoints.down('sm')]: {
			justifyContent: 'space-around',
		},
	},
	nextButton: {
		height: '54px',
		width: '224px',
		borderRadius: '13.6px',
		borderColor: 'none',
		backgroundColor: '#0071C2',
		cursor: 'pointer',

		border: 0,
		color: '#FFFFFF',
		textAlign: 'center',
		fontSize: '1rem',
		fontWeight: 700,

		'&:hover': {
			backgroundColor: '#3F8FC9',
		},

		[breakpoints.down('sm')]: {
			width: '160px',
		},
	},
	previousButton: {
		height: '54px',
		width: '224px',
		border: '2.36px solid #1C73C9',
		borderRadius: '13.6px',
		backgroundColor: '#FFFFFF',
		cursor: 'pointer',
		color: '#0071C2',
		fontSize: '1rem',
		textAlign: 'center',

		fontWeight: 600,

		[breakpoints.down('sm')]: {
			width: '160px',
		},
	},
}))
