import { Roles } from "enums/user/user-roles"
import IUser from "interface/user/user-interface"

type HrUtilType = {
    users: IUser[]
    ids: string[]
}
export const getHRDepartment = (employees: IUser[]): HrUtilType => {
    const hrDepartment = employees.filter((e: IUser) => e.statusInfo.role === Roles.HR)

    return {
        users: hrDepartment,
        ids: hrDepartment.map(e => e._id)
    }
}