import { useCallback } from "react"
import { pdf } from "@react-pdf/renderer"
import { Provider, useDispatch, useSelector } from 'react-redux'
import store from "redux/store"

import { export_debrief } from "redux/debrief/debrief-actions"


import { Debrief } from "interface/debrief/debrief-interface"
import { userSelector } from "redux/user/user-selector"

type Props = {
    debrief: Debrief | null
    receivers: string[]
    reactPdfInstance: JSX.Element
}
const Export = ({ debrief, receivers, reactPdfInstance }: Props) => {
    const dispatch = useDispatch()
    const { company_id, _id: sender } = useSelector(userSelector)

    const handleGeneratePdf = async () => {
        return new Promise((resolve, reject) => {

            const PDFContent = pdf(<Provider store={store}>{reactPdfInstance}</Provider>)

            PDFContent.toBlob().then((blob) => {
                const reader = new FileReader()

                reader.readAsDataURL(blob)

                reader.onloadend = () => {
                    const base64 = reader.result

                    resolve(base64)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }

    const handleSendPdf = async () => {
        await handleGeneratePdf()
            .then(handleSendPdfToServer)
            .catch((err) => console.log(err))
    }

    const handleSendPdfToServer = useCallback((pdfData) => {
        const payload = {
            company_id,
            blob: pdfData,
            receivers: receivers,
            debrief_id: debrief?._id!,
            sender
        }
        console.log({payload})
        dispatch(export_debrief(payload))
    }, [receivers, debrief])

    return { handleSendPdf }
}

export default Export