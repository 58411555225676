import { configureStore } from '@reduxjs/toolkit'
import employeeReducer from './employee/employee-reducer'
import userReducer from './user/user-reducer'
import feedbackReducer from './feedback/feedback-reducer'
import notificationsReducer from './notifications/notifications-reducer'
import praiseReducer from './praise/praise-reducer'
import companyReducer from './company/company-reducer'
import botReducer from './bot/bot-reducer'
import requestErrorReducer from './requestError/request-error-reducer'
import debriefReducer from './debrief/debrief-reducer'
import TodosReducer from './todos/todos.reducer'
import TeamsReducer from './teams/teams-reducer'
// import { enhancer } from 'addon-redux'

export default configureStore({
	reducer: {
		bot: botReducer,
		employee: employeeReducer,
		feedback: feedbackReducer,
		notifications: notificationsReducer,
		user: userReducer,
		praise: praiseReducer,
		company: companyReducer,
		debrief: debriefReducer,
		requestError: requestErrorReducer,
		todo: TodosReducer,
		teams: TeamsReducer
	},
})
