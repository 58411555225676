import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { userSelector } from '../../../redux/user/user-selector'
import IUser from '../../../interface/user/user-interface'

import { employeesSelector } from '../../../redux/employee/employee.selector'
import { get_employees } from '../../../redux/employee/employee-actions'
import { Roles } from 'enums/user/user-roles'
import { filter } from 'utils/filter'
import { isNotCurrentUser } from 'utils/isNotCurrentUser'

const listCapacity = 21

type Props = {
	currentParticipants: IUser[]
}

const useHandlers = ({ currentParticipants }: Props) => {
	const users = useSelector(employeesSelector)
	const connectedUser = useSelector(userSelector)

	const [listInitilaitzed, setListInitilaitzed] = useState(false)
	const [userText, setUserText] = useState('')
	const [participants, setParticipants] = useState<IUser[]>(
		filter(currentParticipants, user => user._id !== connectedUser._id)
	)

	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(get_employees({ company_id: connectedUser.company_id! })) // get 1st chunck of 10 users then bring whatever user ask ( on search / on scroll)
	}, [])

	const addParticipant = useCallback(
		(user: IUser) => {
			setParticipants((prev: IUser[]) => [...prev, user])
		},
		[participants]
	)
	const removeParticipant = useCallback(
		(id: string) => {
			const updatedParticipants = participants.filter(
				participant => participant._id !== id
			)
			setParticipants(updatedParticipants)
		},
		[participants]
	)

	const handleUsersListClick = useCallback(
		(user: IUser) => {
			const chosedParticipantsId = participants.map(participant => participant._id)

			if (chosedParticipantsId.includes(user._id)) {
				removeParticipant(user._id)
				return
			}

			addParticipant(user)
		},
		[participants]
	)

	const handleSearchTextChange = ({
		target: { value },
	}: React.ChangeEvent<HTMLInputElement>) => {
		setUserText(value)
	}

	const usersList = useMemo(() => {
		let searchResults: IUser[] = []

		const usersData = {
			all: users,
			current: connectedUser,
		}
		const filter = (filter: UserFilter, condition: boolean) => {
			if (condition)
				return removeDuplicates<IUser>(
					[...searchResults, ...filterUsers(usersData, filter)],
					'_id'
				)
			return []
		}
		if (!userText) {
			searchResults = filterUsers(usersData, UserFilter.SELF_DIRECT_MANAGER)
			const isManager =
				connectedUser.statusInfo.role === Roles.HR ||
				connectedUser.statusInfo.role === Roles.MANAGER
			const maxResults = searchResults.length < listCapacity
			searchResults = filter(UserFilter.MANAGER_EMPLOYEES, isManager)
			searchResults = filter(UserFilter.COLLEAGUES, maxResults)
			searchResults = filter(UserFilter.SUB_DEPARTMENT_MEMBERS, maxResults)
			searchResults = filter(UserFilter.DEPARTMENT_MEMBERS, maxResults)
			searchResults = filter(UserFilter.A_Z, maxResults)
		}

		if (userText.length > 1) {
			searchResults = closestMatch([...users], userText)
		}
		return searchResults.filter(user => isNotCurrentUser(user, connectedUser))
	}, [users, connectedUser, userText, listCapacity])

	useEffect(() => {
		usersList.length && !listInitilaitzed && setListInitilaitzed(true)
	}, [usersList])

	return {
		listInitilaitzed,
		handleClick: handleUsersListClick,
		handleSearchTextChange,
		usersList: usersList.slice(0, listCapacity),
		participants,
	}
}

export default useHandlers



export const closestMatch = (arr: IUser[], searchkey: string) => {
	let matchedKeys = []

	for (let i = 0; i < arr.length; i++) {
		const name =
			arr[i].personalInfo.firstName.toLowerCase() + //IF THERE IS NO LAST NAME --> WILLL FAIL CAUSE OF UNDEFINED!!
			' ' +
			arr[i].personalInfo.lastName.toLowerCase()
		if (name.match(searchkey.toLowerCase())) {
			matchedKeys.push(arr[i])
		}
	}
	return matchedKeys
}

const filterUsers = (
	data: { all: IUser[]; current: IUser },
	filter: UserFilter
) => {
	let results = []
	if (filter === UserFilter.A_Z) {
		results = [...data.all]
			// .sort((a, b) =>
			// 	a.personalInfo.firstName > b.personalInfo.firstName ? 1 : -1
			// )
			.slice(0, listCapacity)
	} else {
		results = data.all.filter((user: IUser) => {
			let filterCondition = false
			switch (filter) {
				case UserFilter.SELF_DIRECT_MANAGER:
					filterCondition =
						user.securityInfo.email === data.current.statusInfo.directManager
					break
				case UserFilter.MANAGER_EMPLOYEES:
					filterCondition =
						user.statusInfo.directManager === data.current.securityInfo.email
					break
				case UserFilter.COLLEAGUES:
					filterCondition =
						user.statusInfo.directManager === data.current.statusInfo.directManager
					break
				case UserFilter.SUB_DEPARTMENT_MEMBERS:
					filterCondition =
						user.statusInfo.subDepartment === data.current.statusInfo.subDepartment
					break
				case UserFilter.DEPARTMENT_MEMBERS:
					filterCondition =
						user.statusInfo.department === data.current.statusInfo.department
					break

				default:
					return false
			}

			return filterCondition
		})
	}
	return [...results]
}

function removeDuplicates<T>(arr: T[], property: string): T[] {
	const uniqeValues: T[] = []
	const entitiy: any = {}
	arr.forEach((element: any) => {
		if (!entitiy[element[property]]) {
			entitiy[element[property]] = true
			uniqeValues.push(element)
		}
	})
	return uniqeValues
}
enum UserFilter {
	SELF_DIRECT_MANAGER,
	MANAGER_EMPLOYEES,
	COLLEAGUES,
	SUB_DEPARTMENT_MEMBERS,
	DEPARTMENT_MEMBERS,
	A_Z,
}
