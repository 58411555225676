import { Company } from 'interface/company/company-interface'

export function formReducer(state: any, action: { type: string; value: any }) {
	switch (action.type) {
		case 'SET_CONTACTS':
			const updatedContacts = action.value
			return {
				...state,
				contacts: updatedContacts,
				formErrors: { ...state.formErrors, contacts: [] },
			}
		case 'SET_LICENSES':
			const updatedLicenses = action.value
			return {
				...state,
				licenses: updatedLicenses,
				formErrors: { ...state.formErrors, licenses: [] },
			}

		case 'SET_PROPERTY':
			const property = action.value
			return {
				...state,
				[property.name]: property.value,
				formErrors: { ...state.formErrors, [property.name]: false },
			}
		case 'SET_LANGUAGE':
			const language = action.value
			return {
				...state,
				language,
			}
		case 'FORM_RESTART':
			return {
				...state,
				isValid: false,
			}
		case 'FORM_VALIDATION': {
			let contactsErrors: Array<string> = []
			state.contacts.map((contact: any) => {
				for (const property in contact) {
					switch (property) {
						case 'name':
							if (contact.name === '') {
								contactsErrors.push(`name/${contact.rowKey}`)
							}
							break
						case 'phone':
							if (contact.phone === '') {
								contactsErrors.push(`phone/${contact.rowKey}`)
							}
							break
						case 'email':
							if (contact.email === '') {
								contactsErrors.push(`email/${contact.rowKey}`)
							}
							break
						case 'role':
							if (contact.role === '') {
								contactsErrors.push(`role/${contact.rowKey}`)
							}
							break
						default:
							break
					}
				}
			})

			let nameError = state.name == '' ? true : false
			let feeError = state.fee == '' ? true : false
			let idError = state.id == '' ? true : false

			return {
				...state,
				formErrors: {
					...state.formErrors,
					contacts: [...contactsErrors],
					name: nameError,
					fee: feeError,
					id: idError,
				},
				isValid: contactsErrors.length === 0 && !nameError && !feeError && !idError,
			}
		}
		default:
			throw new Error('no form action: check form reducer')
	}
}

export function buildCompanyObject(state: any) {
	return {
		_id: state._id,
		companyName: state.name,
		annualFee: state.fee,
		companyId: state.id,
		language: state.language,
		contacts: state.contacts,
		license: state.licenses,
	} as Company
}
export const initialFormState = {
	name: '',
	id: '',
	fee: '',
	language: 'en',
	contacts: [],
	license: {
		bronze: 0,
		silver: 0,
		gold: 0,
	},
	formErrors: {},
	isValid: false,
}

export const mainInputsData = [
	{ id: 'company_name', name: 'name', title: 'Company Name', type: 'text' },
	{ id: 'company_id', name: 'id', title: 'Company ID', type: 'number' },
	{ id: 'annual_fee', name: 'fee', title: 'Annual fee', type: 'number' },
]
