import React, { FC, Dispatch, SetStateAction } from 'react'
import useStyle from './styled'

import CompanyValueInput from './CompanyValueInput'

type Props = {
	setCompanyValues: Dispatch<SetStateAction<Array<string>>>
	companyValues: Array<string>
}
const CompanyValues: FC<Props> = ({
	setCompanyValues,
	companyValues,
}) => {

	const onAddcubeHandler = () => {
		setCompanyValues(prevState => {
			const t = [...prevState]
			t.splice(-1, 1)
			return t.includes('') ? [...prevState] : [...prevState, '']
		})
	}

	const styles = useStyle()

	return (
		<div className={styles.companyValuesContainer}>

			{companyValues.map((value, index) => {
				const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
					setCompanyValues(values => {
						const newValues = [...values]
						newValues[index] = event.target.value
						return newValues
					})

				return (
					<CompanyValueInput
						onBlur={() => { }}
						onAddCube={onAddcubeHandler}
						isDisabled={
							companyValues[index - 1] === '' &&
								companyValues[index + 1] === '' &&
								index !== 0
								? true
								: false
						}
						key={index}
						onChange={onChange}
						value={value}
						isAddButton={index === companyValues.length - 1}
					/>
				)
			})}

		</div>
	)
}

export default CompanyValues
