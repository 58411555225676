import { Debrief } from '../interface/debrief/debrief-interface'
type Payload<T> = {
	event_id?: string
	data: T
}

/**
 * The function accepts current debrief and payload.
 * Maps trough the events and insert the new summary into a event.
 */

export default <T>(debriefOnProcess: Debrief, payload: Payload<T>) => {
	const { event_id, data } = payload

	const events = debriefOnProcess.events.map(event => {
		if (event._id === event_id) {
			const currentSummaries = !!event.summaries?.length ? event.summaries : []
			return { ...event, summaries: [...currentSummaries, data] }
		}
		return event
	})

	return { ...debriefOnProcess, events } as Debrief
}
