import { FC } from 'react'
import { Button, Checkbox, Fab, FormControlLabel } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import StepsBar from 'components/HRsettings/StepsBar'
import { BaseQuestion, QuestionType } from 'interface/praise/praise-interface'
import { useEffect, useReducer, useRef, useState } from 'react'
import BotSettingsLogic from './BotSettingsLogic'
import classes from './styles.module.css'
import { Loader } from 'components/common/Loader'
import { reducer, initialState } from './reducer'
import BotSettingsInput from './BotSettingsInput'
import { Langs } from 'enums/app'
import AnswersInputs, { AnswerData } from './AnswersInputs'
import { uniqeId } from 'utils/helpers'
import { updateFeedbackBotQuestion } from 'api/feedback'
import { fetchAllPraisesOutputs, updatePraiseBotQuestion } from 'api/praise'
import { Tooltip } from '@mui/material'
import { getAllFeedbackQuestions } from 'api/feedback'
import { getAllPraiseQuestions } from 'api/praise'
import EditorForm from './EditorForm'
import { OutputSettingType } from 'interface/bot'
import { fetchAllFeedbacksOutputs } from '../../api/feedback'
import {
	startFeedbackAutomation,
	startPraiseAutomation,
} from 'api/bot-automation'

export const SettingWrapper: FC<{ height?: string }> = ({
	height,
	children,
}) => {
	return (
		<div
			className={classes.mainContainer}
			style={{ height: height ? height : '' }}
		>
			{children}
		</div>
	)
}
interface CollectionType {
	data: BaseQuestion[]
	outputTypes: OutputSettingType[]
}
enum TabState {
	praise = 1,
	feedback = 2,
}

const BotSettings = () => {
	const editorRef = useRef<HTMLDivElement>(null)
	const [state, dispatch] = useReducer(reducer, initialState)
	const [sendingData, setSendingData] = useState(false)
	const [collectionsData, setCollectionsData] = useState({
		feedback: { data: [], outputTypes: [] },
		praise: { data: [], outputTypes: [] },
	})

	const selectedCollection: CollectionType =
		state.collectionTypeValue == 1
			? collectionsData.praise
			: collectionsData.feedback
	const selectedLanguage: Langs = state.selectedLanguage
	const questionsData = selectedCollection.data.map(
		(question: BaseQuestion) =>
			`${question.title[selectedLanguage]} (${question._id})`
	)
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		const getCollectionData = async () => {
			setLoading(true)
			const feedbackCollectionData = await getAllFeedbackQuestions()
			const praiseCollectionData = await getAllPraiseQuestions()
			const praisesOutput = await fetchAllPraisesOutputs()
			const feedbacksOutput = await fetchAllFeedbacksOutputs()

			setLoading(false)
			return {
				feedback: {
					data: feedbackCollectionData.data,
					outputTypes: feedbacksOutput.data,
				},
				praise: {
					data: praiseCollectionData.data,
					outputTypes: praisesOutput.data,
				},
			}
		}
		try {
			if (!sendingData) {
				getCollectionData().then(data => setCollectionsData(data))
			}
		} catch (e) {
			console.log(e)
		}
	}, [sendingData])
	useEffect(() => {
		const q: BaseQuestion = selectedCollection.data.filter(
			(question: BaseQuestion) => question._id === state.id
		)[0]

		if (q) {
			chooseQuestion(q)
		}
	}, [state.selectedLanguage])

	const questionList = (
		<ul>
			{selectedCollection.data.map((question: BaseQuestion) => (
				<li key={question._id} className={classes.question}>
					<span>
						<Tooltip title={question.title.en}>
							<span className={classes.id}>[{question._id}]</span>
						</Tooltip>
						{question.title[selectedLanguage]}
					</span>
					<div className={classes.editButtonContainer}>
						<Fab
							className={classes.editButton}
							color='secondary'
							aria-label='edit'
							onClick={() => chooseQuestion(question)}
						>
							<EditIcon />
						</Fab>
					</div>
				</li>
			))}
		</ul>
	)
	const getIdFromQuestionData = (questioData: string) => {
		let result = ''
		const matches = questioData.match(/\((.*?)\)/)
		if (matches) {
			result = matches[1]
		}
		return result
	}
	const chooseQuestion = (question: BaseQuestion) => {
		let q
		const prev: string = questionsData.filter((questionData: string) => {
			const id = getIdFromQuestionData(questionData)
			return id === question.previousQuestion
		})[0]

		const next: string = questionsData.filter((questionData: string) => {
			const id = getIdFromQuestionData(questionData)
			return id === question.nextQuestion
		})[0]
		let defaultAnswers = []
		const answers = question.answers[selectedLanguage].map((answer: any) => {
			const nextQuestion = questionsData.filter((questionData: string) => {
				const id = getIdFromQuestionData(questionData)
				return id === answer.nextQuestion
			})[0]
			return {
				...answer,
				id: uniqeId(),
				nextQuestion: nextQuestion ? nextQuestion : '',
			}
		})

		if (!answers.length) {
			defaultAnswers = question.answers.en.map((answer: any) => {
				const nextQuestion = questionsData.filter((questionData: string) => {
					const id = getIdFromQuestionData(questionData)
					return id === answer.nextQuestion
				})[0]
				return {
					...answer,
					id: uniqeId(),
					title: '',
					subTitle: '',
					nextQuestion: nextQuestion ? nextQuestion : '',
				}
			})
		}
		q = {
			...question,
			backgroundIcon: question.backgroundIcon ?? '',
			nextQuestion: next ? next : '',
			previousQuestion: prev ? prev : '',
			answers: answers.length === 0 ? defaultAnswers : answers,
		}
		dispatch({
			type: 'SET_QUESTION_TO_EDIT',
			value: q,
		})
		editorRef.current?.scrollIntoView()
	}

	const handleSubmit = async (data: BaseQuestion) => {
		const q: BaseQuestion = selectedCollection.data.filter(
			(question: BaseQuestion) => question._id === state.id
		)[0]
		setSendingData(true)
		if (q) {
			const nextQuestion = getIdFromQuestionData(data.nextQuestion!)
			const previousQuestion = getIdFromQuestionData(data.previousQuestion!)
			const answersData = [...data.answers]
			const answers = answersData.map((answer: AnswerData) => {
				return {
					icon: answer.icon ? answer.icon.trim() : '',
					nextQuestion: getIdFromQuestionData(answer.nextQuestion),
					subtitle: answer.subtitle ? answer.subtitle.trim() : '',
					title: answer.title ? answer.title.trim() : '',
					outputType: answer.outputType ? answer.outputType : '',
				}
			})
			const updatedQuestion: BaseQuestion = {
				...q,
				backgroundIcon: data.backgroundIcon,
				title: { ...q.title, [selectedLanguage]: data.title.trim() },
				subtitle: { ...q.subtitle, [selectedLanguage]: data.subtitle.trim() },
				type: data.type,
				skipable: data.skipable,
				nextQuestion,
				previousQuestion,
				answers: { ...q.answers, [selectedLanguage]: answers },
			}
			const update =
				state.collectionTypeValue == 1
					? updatePraiseBotQuestion
					: updateFeedbackBotQuestion
			const { data: requestData, status } = await update(updatedQuestion)

			chooseQuestion(updatedQuestion)
		}
		setSendingData(false)
	}
	const handleMove = (direction: boolean) => () => {
		const selectedCollectionSlice: BaseQuestion[] = Array.from(
			selectedCollection.data
		)
		const q: BaseQuestion = selectedCollectionSlice.filter(
			(question: BaseQuestion) => question._id === state.id
		)[0]
		const currentIndex = selectedCollectionSlice.indexOf(q)
		const nextQuestion =
			selectedCollectionSlice[direction ? currentIndex + 1 : currentIndex - 1]

		if (!nextQuestion) {
			alert('end of list')
			return
		}

		chooseQuestion(nextQuestion)
	}

	const handleStartAutomation = () => {
		if (state.collectionTypeValue === TabState.praise) {
			startPraiseAutomation()
			return
		}
		startFeedbackAutomation()
	}

	const languages: any[] = Object.values(Langs)
	const languagesSelectors = languages.map((language: Langs) => (
		<div
			onClick={() => {
				dispatch({ type: 'SET_LANGUAGE', value: language })
			}}
			className={classes.languageSelector}
			style={{
				border: `3px solid ${
					state.selectedLanguage === language ? `#0071c2` : `#46484a`
				} `,
			}}
		>
			{language}
		</div>
	))

	const AutomateBot = (
		<button onClick={handleStartAutomation}>
			{state.collectionTypeValue === TabState.praise
				? 'Test Praise bot'
				: 'Test Feedback bot'}
		</button>
	)

	return (
		<SettingWrapper>
			<div ref={editorRef} className={classes.botSettings}>
				<div>
					{AutomateBot}
					<StepsBar
						barWidth='25vw'
						tabWidth='50%'
						stepsData={[
							{ label: 'Praises Bot', value: 1 },
							{ label: 'Feedbacks Bot', value: 2 },
						]}
						onStepChange={value =>
							dispatch({
								type: 'CHOOSE_COLLECTION_VALUE',
								value,
							})
						}
						currentStep={state.collectionTypeValue}
					/>
				</div>
				{loading || sendingData ? (
					<Loader />
				) : (
					<>
						<h2>{state.id ? state.id : 'Please Choose Question'}</h2>
						{state.id && (
							<div className={classes.editor}>
								<div className={classes.directionButtonContainer}>
									<Button
										color={'secondary'}
										onClick={handleMove(true)}
										variant='contained'
									>
										back
									</Button>
									<Button
										color={'secondary'}
										onClick={handleMove(true)}
										variant='contained'
									>
										Next
									</Button>
								</div>

								<EditorForm
									rightDirection={state.selectedLanguage === Langs.he}
									outputTypes={selectedCollection.outputTypes}
									id={state.id}
									title={state.title}
									subTitle={state.subTitle}
									backgroundIcon={state.backgroundIcon}
									questionType={state.questionType}
									nextQuestion={state.nextQuestion}
									prevQuestion={state.prevQuestion}
									skippable={state.skippable}
									answers={state.answers}
									questionsData={questionsData}
									onSubmitForm={(data: BaseQuestion) => handleSubmit(data)}
								/>
							</div>
						)}

						<div className={classes.questionList}>{questionList}</div>
					</>
				)}
			</div>
			<div className={classes.languages}>{languagesSelectors}</div>
		</SettingWrapper>
	)
}

export default BotSettings
