import { PropsWithChildren } from 'react'
import { IUserGoalItem } from 'interface/user/user-interface'
import { GOALITEMSTATUS } from 'components/Goals/GoalModal/GoalsTable/types'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTranslation } from 'react-i18next'

type GoalFormItemProps = PropsWithChildren<{
	initialState: IUserGoalItem
	onChange?(item: IUserGoalItem): void
	onDelete(itemid: string |undefined ): void
	enableUpdate:boolean;
}>

function GoalFormItem({ initialState, onChange, onDelete,enableUpdate }: GoalFormItemProps) {
  const { t } = useTranslation()
  function change(updatedValues: Partial<IUserGoalItem>) {
    onChange?.({ ...initialState, ...updatedValues })
  }

	return (
		<div className='form-item'>
			<textarea
				className='input-field'
				disabled={!enableUpdate}
				defaultValue={initialState.need}
				onChange={e => change({ need: e.target.value })}
			/>
			<label className='checkbox-wrapper'>
				{t('done')}:
				<input
					type='checkbox'
					className='checkbox'
					disabled={!enableUpdate}
					defaultChecked={initialState.status === GOALITEMSTATUS.CHECKED}
					onChange={e => change({
            status: e.target.checked ? GOALITEMSTATUS.CHECKED : GOALITEMSTATUS.UNCHECKED
          })}

				/>
			</label>
			{enableUpdate && <><div>
				<DeleteIcon onClick={()=> onDelete(initialState._id)} className='button-delete' />
			</div></>}
		</div>
	)
}

export default GoalFormItem
