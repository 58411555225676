import { ReactNode, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import classes from './styles.module.css'

import {
	companyTodosSelector,
	hasMoreTasksSelector,
} from 'redux/todos/todos.selector'

import TodoInputBox from './TodoInputBox'
import PresentTask from './PresentTask'

import IUser from 'interface/user/user-interface'

import { filterAlgorithem } from './filterAlgorithem'
import { useManagerObject } from 'utils/getManager'
import { Task } from 'interface/todos/todos-interface'
import TodoDatePicker from './TodoDatePicker'
import useCalendar from './useCalendar'
import useDeletedTasks from './useDeletedTasks'
import useTodoReuqests from './useTodos'

export enum User {
	manager = 'manager',
	employee = 'employee',
}

type Props = {
	managerSide?: boolean
	RenderHeader: ReactNode
	isManagerViewing: boolean
	selectedEmployee: IUser
	payload: any
}

const INCREMENT_BY_ONE = 1
const EACH_CHUNCK_LENGTH = 10
export const RenderSection = (parentProps: Props) => {
	const {
		managerSide,
		RenderHeader,
		isManagerViewing,
		selectedEmployee,
		payload,
	} = parentProps
	const { t } = useTranslation()

	const [todosChunkNumber, setTodosChunkNumber] = useState(0)

	const userTodos = useSelector(companyTodosSelector)
	const hasMoreTasks = useSelector(hasMoreTasksSelector)

	const { tasks } = userTodos

	const { lazyloadTodos, deleteTask } = useTodoReuqests(selectedEmployee, isManagerViewing)

	const manager = useManagerObject(selectedEmployee)

	const loader = <h4 className={classes.loader}> {t('loading...')} </h4>

	const {
		deletedIcon,
		emptyEmployeesTasks,
		emptyManagerTasks,
		showDeletedEmployeeTasks,
	} = useDeletedTasks({ isManagerViewing, payload, selectedEmployee, tasks })

	const {
		isCalendarOpen,
		toggleShowingCalendar,
		handleSetDueDate,
		dueDate,
		resetCalendarState,
		resetDueDate,
	} = useCalendar()

	const openCalendar = () => toggleShowingCalendar()

	const nextPage = () => {
		if (isShowAllTasks()) return
		let nextPageNumber = 1
		nextPageNumber = todosChunkNumber + INCREMENT_BY_ONE
		loadMoreTasks(nextPageNumber)
		setTodosChunkNumber(nextPageNumber)
	}
	const isShowAllTasks = () => tasks?.length < EACH_CHUNCK_LENGTH

	const loadMoreTasks = (nextPageNumber: number) =>
		lazyloadTodos(nextPageNumber, managerSide!)

	const onDeleteTask = (todo_id: string) => deleteTask(todo_id)

	const onDueDateSelected = (day: Date) => {
		handleSetDueDate(day)
		toggleShowingCalendar()
	}

	const props = {
		dueDate: dueDate!,
		userTodos: { ...userTodos, manager: manager?._id! },
		selectedEmployee,
		payload,
		isManagerViewing,
		setTodosChunkNumber,
		onDueDateSelected,
		resetCalendarState,
		openCalendar,
		resetDueDate,
	}

	return (
		<div className={classes.actionsSection}>
			{isManagerViewing && !managerSide && deletedIcon}
			{RenderHeader}
			<div className={classes.actionSection_contents}>
				<div className={classes.content}>
					<TodoInputBox managerSide={managerSide!} {...props} />
					{!managerSide ? emptyEmployeesTasks : emptyManagerTasks}
				</div>

				<div className={classes.content}>
					{!!tasks?.length && (
						<InfiniteScroll
							dataLength={tasks.length}
							next={nextPage}
							hasMore={hasMoreTasks}
							loader={hasMoreTasks ?? loader}
							height={300}
							style={{ overflowX: 'hidden', marginTop: '10px' }}
						>
							{filterAlgorithem(
								userTodos,
								isManagerViewing!,
								managerSide!,
								showDeletedEmployeeTasks
							)!.map((todo: Task) => (
								<PresentTask
									onOpenCalendar={openCalendar}
									isManagerAction={managerSide}
									managerSide={managerSide!}
									onDeleteTask={onDeleteTask}
									todo={todo}
									key={todo._id}
									{...props}
								/>
							))}
						</InfiniteScroll>
					)}
				</div>

				{isCalendarOpen && (
					<TodoDatePicker
						handleCloseCalendar={toggleShowingCalendar}
						handleDaySelected={handleSetDueDate}
					/>
				)}
			</div>
		</div>
	)
}
