import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles<Theme>(({ palette }) => ({
	container: {
		color: palette.text.primary,
		margin: '37px auto 30px',
	},
	loader: {
		marginLeft: '23px',
	},
	buttonGreen: {
		backgroundColor: '#34ab53 !important',
		border: '2px solid #34AB53 !important',
		color: 'white !important',
		fontWeight: 900,
		'&:hover': {
			color: '#34ab53 !important',
			backgroundColor: 'transparent !important',
		},
	},
	buttonTransparent: {
		backgroundColor: 'transparent !important',
		border: '2px solid rgb(52, 171, 83) !important',
		color: 'rgb(52, 171, 83) !important',
		'&:hover': {
			color: 'white !important',
			backgroundColor: 'rgb(52, 171, 83) !important ',
		},
	},
}))
