import IUser from 'interface/user/user-interface'
import { Roles } from 'enums/user/user-roles'
import { IUserPersonalInfo } from 'interface/user/user-interface'

export function getManagers(employeesArr: Array<IUser>) {
	return employeesArr.filter(
		(employee: IUser) =>
			employee.statusInfo.role === Roles.MANAGER ||
			employee.statusInfo.role === Roles.HR
	)
}

export function findDuplicates(employeesArr: Array<IUser>) {
	const employeesFullNames = employeesArr.map(
		employee =>
			employee.personalInfo.firstName + ' ' + employee.personalInfo.lastName
	)
	let sorted_arr = employeesFullNames.slice().sort()
	let results = []
	for (let i = 0; i < sorted_arr.length - 1; i++) {
		if (sorted_arr[i + 1] == sorted_arr[i]) {
			results.push(sorted_arr[i])
		}
	}
	return results
}

export function getFullName(employee: IUser) {
	const { personalInfo = {} as IUserPersonalInfo } = employee
	const { firstName = '', lastName = '' } = personalInfo
	return firstName + ' ' + lastName
}
