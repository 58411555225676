import { FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { getGoalTextAreaProps } from '../../../utils/Goals/getGoalTextAreaProps'
import classes from 'components/Goals/Goal/style.module.css'
import { TeaxtAreaInput } from './TeaxtArea'

// Enums
export enum GOAL_LABEL {
	description = 'goal_description',
	indicator = 'Indicator',
}

// Types
export type GoalCardTextArea = {
	label: GOAL_LABEL
	editMode: boolean
	goalDesc: string
	datepickerIsOpen: boolean
	setGoalDesc: (value: string) => void
	setOpenDatepicker: (isOpen: boolean) => void
}

export const GoalCardTextArea: FC<GoalCardTextArea> = props => {
	const { datepickerIsOpen, setOpenDatepicker, setGoalDesc } = props
	const { t } = useTranslation()

	const onClick = () => {
		datepickerIsOpen && setOpenDatepicker(false)
	}
	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
		setGoalDesc(e.target.value)

	const goalTextAreaProps = { onClick, onChange, ...getGoalTextAreaProps(props) }

	return (
		<>
			<div className={classes.label}>{t(props.label)}</div>
			<TeaxtAreaInput {...goalTextAreaProps} />
		</>
	)
}
