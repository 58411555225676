import classes from './styles.module.css'
import cellIcon from '../../../assets/cellPhoneIcon.svg'
import { useTranslation } from 'react-i18next'
interface Props {
	contactInfo: { name: string; mobile: string }
	title?: boolean
	formText?: boolean
}
const ContactNow = ({ contactInfo, title, formText = true }: Props) => {
	const { t } = useTranslation()
	return (
		<div className={classes.contactMainContainer}>
			{title && (
				<div>
					{t(formText ? 'contact_now_text_1' : 'contact_now_text_3', {
						name: contactInfo.name,
					})}
				</div>
			)}
			<div
				style={{
					paddingTop: title ? '0.5%' : '5%',
					width: title ? 'auto' : '14rem',
				}}
				className={classes.contact}
			>
				<div className={classes.contactInfo}>
					<b>
						{t(formText ? 'contact_now_text_2' : 'contact_now_text_4', {
							name: contactInfo.name,
						})}
					</b>
					<div className={classes.mobile}>{contactInfo.mobile}</div>
					<img src={cellIcon} />
				</div>
			</div>
		</div>
	)
}

export default ContactNow
