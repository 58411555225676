import { makeStyles } from "@material-ui/core"

export default makeStyles(() => ({
    infoImg: {
        height: '22px',
        width: '22px'
    },
    tooltip: {
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
        backgroundColor: '#fff',
        color: 'black',
        wordBreak: 'break-word'
    },
}))