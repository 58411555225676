import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import colors from 'utils/colors'

export type Styles = {
    color?: string
}

export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
    header: {
        textAlign: 'center',
        fontWeight: 650,
        fontFamily: 'Sora, sans-serif',
        fontSize: '31px'
    },
    companyDetails: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '22px',
        fontWeight: 'normal',
        textAlign: 'center',
        justifyContent: 'space-evenly',
        marginTop: '10px'
    },
    usersCounter: {
        color: colors.emeraldGreen,
        fontSize: '22px',
        fontFamily: 'Sora, sans-serif',
        fontWeight: 350
    },
    date: {
        color: colors.boldBlue,
        fontWeight: 'normal'
    },
    tooltip: {
        fontSize: '13px',
        fontWeight: 'normal',
        fontFamily: 'Sora, sanes-serif'
    },
    wrapper: {
        width: '100%',
        textAlign: 'center',
        maxWidth: 800,
        marginRight: 'auto',
        marginLeft: 'auto',
        border: '1.5px solid black',
        padding: '2px'
    },
    tableHeader: {
        display: 'flex',
        gap: '3px',
        maxHeight: '20%',
        fontSize: 15,
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Sora, sans-serif',
        '& > div': {
            backgroundColor: colors.boldBlue,
            display: 'flex',
            flex: 1,
            height: 28,
            fontWeight: 600,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            color: 'white',
            gap: '3px'
        },
        '& >:first-child': {
            marginLeft: '5px'
        },
        '& >:last-child': {
            marginRight: '5px'
        },
    },
    row: {
        display: 'flex',
        fontSize: 18,
        fontWeight: 600,
        fontFamily: 'Sora, sans-serif',
        paddingLeft: '5px',
        paddingRight: '5px',
        // backgroundColor: ({ color }) => color,
        '& > div': {
            width: '100%',
        },
        '&:hover': {
            boxShadow: `1px 0px 0px 4px ${colors.boldBlue}`,
            fontWeight: 'bold'
        },
    },
    body: {
        '& > div > div': {
            flex: 1,
            backgroundColor: colors.blue,
            height: 22,
        },
        '& > div': {
            marginTop: '3px',
            display: 'flex',
            gap: '3px',
            FontFamily: 'Sora, sans-serif',
            fontWeight: 400,
            fontSize: 18,
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
                fontWeight: 'bold'
            }
            // backgroundColor: ({ color }) => color,
        },
        '& >:first-child': {
            backgroundColor: colors.blue,
            fontWeight: 400,
            justifyContent: 'left',
            textAlign: 'left',
            fontSize: 16,
            maxWidth: 190.5,
            marginRight: '3px',
            maxHeight: '100%',
            fontFamily: 'Sora, sans-serif',
            display: 'flex',
            letterSpacing: 0,
            wordBreak: 'initial',
            color: '#46484A',
            paddingLeft: 5,
            height: 22,
        },
    },
    chartContainer: {
        marginLeft: '150px',
        marginRight: 'auto'
    },
    chartTitle: {
        marginLeft: '16px',
        marginBottom: 0,
        marginRight: '16px',
        width: '100px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    titleFont: {
        fontSize: 15,
        fontWeight: 350
    }
}))