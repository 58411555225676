import { Draggable, Droppable } from 'react-beautiful-dnd'

import useStyles from './styled'

import UserAvatar from 'components/common/Avatar'

import IUser from 'interface/user/user-interface'
import { DroppableArea } from './droppable.enum'

type Props = {
    title: string
    employeesList: IUser[]
    droppableId: DroppableArea
}
const ListContainer = ({ title, employeesList, droppableId }: Props) => {
    const classes = useStyles()
    console.log({ employeesList })
    return (
        <Droppable droppableId={droppableId}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={classes.listContainer} >

                    <h2>{`${title}s`}</h2>

                    <div className={classes.avatarsList} >

                        {employeesList.map((emp: IUser, index: number) => (
                            <Draggable index={index} draggableId={`${emp._id}-${title}`} key={`${emp._id}`} >
                                {(provided, spanshot) => (
                                    <UserAvatar
                                        key={index}
                                        provided={provided}
                                        parentRef={provided.innerRef}
                                        userInfo={emp} />
                                )}
                            </Draggable>))}

                    </div>

                </div>
            )}
        </Droppable>
    )
}
export default ListContainer