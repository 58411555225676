import { MenuItem, Select } from '@material-ui/core'
import Input from 'components/common/Input'
import { QuestionType } from 'interface/praise/praise-interface'
import classes from './styles.module.css'

interface Props {
	inputType: string
	inputName: string
	defaultValue: string
	values?: string[]
	width?: string
	rtl?: boolean
	onChange: (e: any) => void
}
const BotSettingsInput = ({
	inputType,
	inputName,
	defaultValue,
	values,
	width,
	rtl = true,
	onChange,
}: Props) => {
	const getInputProps = () => {
		return {
			label: inputName + ' ',
			type: 'text',
			name: inputName,
			htmlFor: inputName,
			height: '2rem',
			width: width ? width : '15vw',
			rightDirection: rtl ? true : false,
		}
	}
	const getInput = () => {
		return (
			<div className={classes.input}>
				<Input value={defaultValue} onChange={onChange} {...getInputProps()} />
			</div>
		)
	}

	const getTypeSelect = () => {
		const QuestionTypes: any[] = Object.values(QuestionType)
		const typeSelectMenuItems = QuestionTypes.map(type => (
			<MenuItem value={type}>{type}</MenuItem>
		))

		return (
			<div className={classes.select}>
				<span>{inputName}</span>
				<Select value={defaultValue} onChange={onChange}>
					{typeSelectMenuItems}
				</Select>
			</div>
		)
	}
	const getSelect = () =>
		values && (
			<div className={classes.select}>
				<span>{inputName}</span>
				<Select value={defaultValue} onChange={onChange}>
					{values.map(value => (
						<MenuItem value={value}>{value}</MenuItem>
					))}
				</Select>
			</div>
		)

	let content
	switch (inputType) {
		case 'type_select':
			content = getTypeSelect()
			break
		case 'question_select':
			content = getSelect()
			break
		case 'title_input':
			content = getInput()
			break
		default:
			break
	}
	return <>{content}</>
}

export default BotSettingsInput
