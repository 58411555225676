import { FC } from "react"
import DatePicker from 'components/Bot/Modal/DatePicker'
import { CloseIcon } from 'components/common/CloseIcon'
import classes from './styles.module.css'

type Props = {
    handleCloseCalendar: () => void
    handleDaySelected: (day: Date) => void

}
export const TodoDatePicker: FC<Props> = ({
    handleCloseCalendar,
    handleDaySelected,
}) => {
    const today = new Date()
    return (
        <div className={classes.datePicker}>
            <div className={classes.closeButton}>
                <CloseIcon
                    width='1.5rem'
                    height='1.5rem'
                    handleClick={handleCloseCalendar}
                />
            </div>
            <DatePicker
                disabledDays={{ before: today }}
                selectedDay={today}
                handleDayClick={handleDaySelected}
            />
        </div>
    )
}
export default TodoDatePicker