import { FC } from 'react'
import { useSelector } from 'react-redux'
import Modal from '@material-ui/core/Modal'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

import Button from 'components/common/Button'
import { employeesSelector } from 'redux/employee/employee.selector'

const useModalStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: 'absolute',
			width: 500,
			textAlign: 'center',
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		buttonsWrapper: {
			fontSize: 13,
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',
		},
	})
)


function getModalStyle() {
	const top = 50
	const left = 50

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	}
}

type CustomizedModalProps = {
	isModalOpen: boolean
	employeeToDeletId: string
	handleCloseModal: () => void
	handleDeleteEmployee: (employeeId: string) => void
}
const CustomizedModal: FC<CustomizedModalProps> = ({
	isModalOpen,
	employeeToDeletId,
	handleCloseModal,
	handleDeleteEmployee,
}) => {
	const classes = useModalStyles()
	const modalStyle = getModalStyle()

	const employees = useSelector(employeesSelector)

	const employeeFullName = employeeToDeletId
		? employees
			.filter(employee => employee._id === employeeToDeletId)
			.map(({ personalInfo: { firstName, lastName } }) => firstName + ' ' + lastName
			)[0]
		: 'All of the employees of the company?'

	const child = (
		<div style={modalStyle} className={classes.paper}>
			<p>{`Are you sure want to delete ${employeeFullName}?`}</p>
			<div className={classes.buttonsWrapper}>
				<Button width={239} onClick={() => handleDeleteEmployee(employeeToDeletId)}>
					Yes
				</Button>
				<Button width={239} onClick={handleCloseModal}>
					No
				</Button>
			</div>
		</div>
	)

	return (
		<div>
			<Modal
				open={isModalOpen}
				onClose={handleCloseModal}
				aria-labelledby='simple-modal-title'
				aria-describedby='simple-modal-description'
			>
				{child}
			</Modal>
		</div>
	)
}

export default CustomizedModal
