import { FC, useContext, useState, KeyboardEvent } from 'react'

import classes from './styles.module.css'
import { AppDirectionContext } from 'context/languages-context'

import TodoTextArea from './TodoTextArea'
import calendarImg from 'assets/calendarIcon.svg'
import CheckSharpIcon from '@material-ui/icons/CheckSharp'

import { Task, TasksAction } from 'interface/todos/todos-interface'
import IUser from '../../interface/user/user-interface'
import { useTranslation } from 'react-i18next'
import useTodoReuqests from './useTodos'

type Props = {
	openCalendar: () => void
	resetCalendarState: () => void
	toggleShowingEditMode?: () => void
	resetDueDate: () => void
	isManagerViewing: boolean
	managerSide: boolean
	dueDate: Date | undefined
	selectedEmployee: IUser
	userTodos: TasksAction
	isEditMode?: boolean
	taskToUpdate?: string
}
const TodoInputBox: FC<Props> = props => {
	const {
		dueDate,
		selectedEmployee,
		userTodos,
		isEditMode,
		taskToUpdate,
		managerSide,
		isManagerViewing,
		resetDueDate,
		openCalendar,
		toggleShowingEditMode,
	} = props

	const { tasks, _id: todos_id } = userTodos
	const { t } = useTranslation()
	const task = tasks?.find(task => task._id === taskToUpdate)

	const initCurrentTask = () => {
		return {
			description: task?.description,
			_id: taskToUpdate ? taskToUpdate : undefined,
			isManagerAction: !!managerSide,
		} as Task
	}
	const [currentTask, setCurrentTask] = useState<Task>(initCurrentTask())

	const { isRtlDirection } = useContext(AppDirectionContext)

	const resetTaskState = () => {
		setCurrentTask({ description: '' } as Task)
		resetDueDate()
	}
	const { updateTasks, createNewTodos } = useTodoReuqests(
		selectedEmployee,
		isManagerViewing
	)

	const createNewTask = () => {
		currentTask.dueDate = dueDate
		isEditMode && toggleShowingEditMode!()

		if (!currentTask.description) return
		if (todos_id) {
			console.log({ currentTask })
			updateTasks(currentTask, managerSide)
			resetTaskState()
			return
		}

		createNewTodos(currentTask, managerSide)

		resetTaskState()
	}

	const handleDescription = ({
		target: { value },
	}: React.ChangeEvent<HTMLTextAreaElement>) => {
		setCurrentTask({
			description: value,
			isManagerAction: !!managerSide,
			_id: taskToUpdate ? taskToUpdate : '',
		} as Task)
	}
	const handleKeyDown = ({
		key
	}: KeyboardEvent<HTMLTextAreaElement>) => {
		if (key === 'Enter') {
			setCurrentTask({
				description: currentTask.description + '\n',
				isManagerAction: !!managerSide,
				_id: taskToUpdate ? taskToUpdate : '',
			} as Task)
		}
	}
	const calendarIcon = (
		<img
			src={calendarImg}
			className={classes.calenderIcon}
			onClick={openCalendar}
		/>
	)
	const checkSharp = (
		<div className={classes.checkIcon}>
			<CheckSharpIcon onClick={createNewTask} fontSize='large' />
		</div>
	)

	return (
		<div>
			<TodoTextArea
				handleKeyDown={handleKeyDown}
				renderCheckIcon={checkSharp}
				currentTask={currentTask.description}
				dueDate={dueDate}
				onDescriptionChange={handleDescription}
				renderCalendarIcon={calendarIcon!}
				isEditMode={isEditMode!}
			/>

			<div
				className={isRtlDirection ? classes.action : classes.rtlAction}
				onClick={createNewTask}
			>
				{!isEditMode && (
					<div className={`${classes.actionInput} ${classes.plusInput}`}>
						<div className={classes.saveButton}>{t('save')}</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default TodoInputBox
