import { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextareaAutosize } from '@material-ui/core'
import dayjs from 'dayjs'
import moment from 'moment'
import classes from './styles.module.css'

import { AppDirectionContext } from 'context/languages-context'

import { BlockageAction } from 'interface/bot'

import trashIcon from 'assets/trashIcon2.svg'
import calendarIcon from '../../../assets/calendarIcon.svg'

interface Props {
	reset: (participant: string, id: string) => void
	updateDescription: (
		description: string,
		participant: string,
		id: string
	) => void
	deleteAction: (participant: string, id: string) => void
	onDatePickerOpen: (id: string) => void
	participant: string
	action: BlockageAction
	deleteOption: boolean
	readOnly: boolean
}
enum COLORS {
	red = 'red',
	regular = 'rgba(70, 72, 74, 1)',
}
const maximumMessageLength = 250

const ActionInput = (props: Props) => {
	const {
		participant,
		action,
		deleteOption,
		readOnly,
		reset,
		onDatePickerOpen,
		updateDescription,
		deleteAction,
	} = props

	const { t } = useTranslation()
	const [focus, setFocus] = useState(false)

	const { dueDate } = action
	const dueDateFormat = dayjs(dueDate).format('DD/MM/YYYY')
	const passedDueDate = moment.duration(moment().diff(dueDate)).days()
	const taskDueDateText = `${t('task_due_date')} ${dueDateFormat}`
	const onGoingText = t('on_going_task')
	const displayDueDate = dueDate ? taskDueDateText : onGoingText
	const isDueDatePassed = !Math.abs(passedDueDate) && dueDate
	const dueDatePassedColor = isDueDatePassed ? COLORS.red : COLORS.regular

	const { isRtlDirection } = useContext(AppDirectionContext)

	const handelCalenderClick = (
		e: React.MouseEvent<HTMLImageElement, MouseEvent>
	) => {
		e.stopPropagation()
		onDatePickerOpen(action._id)
	}

	const handleTrashClick = () => {
		if (!deleteOption) {
			reset(participant, action._id)
			return
		}
		deleteAction(participant, action._id)
	}

	const rednerActions = (
		<>
			<Calender onClick={handelCalenderClick} />
			<Trash onClick={handleTrashClick} />
		</>
	)
	return (
		<>
			<div
				className={
					isRtlDirection ? `${classes.action} ${classes.rtl}` : classes.action
				}
			>
				{!readOnly && rednerActions}

				<TextareaAutosize
					aria-label='action'
					// disabled={readOnly}
					value={action.description}
					onChange={e => updateDescription(e.target.value, participant, action._id)}
					className={classes.actionInput}
					placeholder={t('action_plan_input_placeholder')}
					maxLength={maximumMessageLength}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
				/>
			</div>
			<div className={classes.dueDate} style={{ color: dueDatePassedColor }}>
				{focus && action.description.length === maximumMessageLength && (
					<div>{t('max_task_input_length')} </div>
				)}
				{displayDueDate}
			</div>
		</>
	)
}

export default ActionInput

type Actions = {
	onClick?: React.MouseEventHandler<HTMLImageElement>
}

const Calender: FC<Actions> = ({ onClick }) => {
	return (
		<img className={classes.calenderIcon} src={calendarIcon} onClick={onClick} />
	)
}

const Trash: FC<Actions> = ({ onClick }) => {
	return (
		<div className={classes.trashButton} onClick={onClick} title='Delete'>
			<img src={trashIcon} alt='delete' />
		</div>
	)
}
