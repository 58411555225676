import { createAsyncThunk } from '@reduxjs/toolkit'
import { alertList, forgotPasswordV2, signIn } from 'api/auth'
import { editUser, getUser, getUserGoals, intervalJobAction } from 'api/user'

import { Req } from 'enums/req/req.enum'
import { IStoreRootState } from 'interface/redux'
import IUser from '../../interface/user/user-interface'

export const sign_in_user = createAsyncThunk(
	'user/signIn',
	async (
		payload: { email: string; password: string; changePassword?: boolean },
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await signIn(payload)
			if (status === Req.failed) throw new Error(data.message)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const reset_user_alerts = createAsyncThunk(
	'user/alertList',
	async (user_id: string, { rejectWithValue }) => {
		try {
			const { data, status } = await alertList(user_id)
			if (status === Req.failed) throw new Error(data.message)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const edit_user_action = createAsyncThunk(
	'user/editUser',
	async (payload: IUser, { rejectWithValue }) => {
		try {
			await editUser(payload)
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const get_user_goals = createAsyncThunk(
	'user/getUserGoals',
	async (employee_id: string, { rejectWithValue }) => {
		try {
			const { status, data } = await getUserGoals(employee_id)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { user } = getState() as IStoreRootState
			return user.loading === false
		},
	}
)

export const get_user = createAsyncThunk(
	'user/getUser',
	async (
		payload: { user_id: string; company_id: string },
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await getUser(payload)
			if (status === Req.failed) throw new Error(data.message)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const interval_job = createAsyncThunk(
	'user/intervalJob',
	async (
		payload: { user_id: string; loginAction: boolean },
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await intervalJobAction(payload)
			if (status == Req.failed) throw new Error(data.message)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const reset_password = createAsyncThunk(
	'user/resetPassword',
	async (email: string, { rejectWithValue }) => {
		try {
			const { data, status } = await forgotPasswordV2(email)
			if (status == Req.failed) throw new Error(data)
			return { data, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

// export const get_wall_praises = createAsyncThunk(
// 	'praise/getPraise',
// 	async (questionId: string | null, { rejectWithValue }) => {
// 		try {
// 			const { data, status } = await getQuestionPraise(questionId)

// 			if (status === Req.failed) throw new Error(data)

// 			return data
// 		} catch (err) {
// 			return rejectWithValue(err)
// 		}
// 	}
// )
