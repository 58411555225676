import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

export enum RadioDefault {
	I_Did = 'I_Did',
	I_Didnt = 'I_Didn’t',
}

type RowRadioButtonsProps<T> = {
	isDone?: T
	onChange?: (e: any) => void
}

export const RowRadioButtonsGroup: FC<RowRadioButtonsProps<{}>> = props => {
	const { isDone, onChange } = props

	const { t } = useTranslation()

	const I_Did = t(RadioDefault.I_Did)
	const I_Did_Not = t(RadioDefault.I_Didnt)

	return (
		<FormControl>
			<RadioGroup
				row
				aria-labelledby='demo-row-radio-buttons-group-label'
				name='row-radio-buttons-group'
				onChange={onChange}
				value={isDone}
			>
				<FormControlLabel value={I_Did} control={<Radio />} label={I_Did} />
				<FormControlLabel value={I_Did_Not} control={<Radio />} label={I_Did_Not} />
			</RadioGroup>
		</FormControl>
	)
}
