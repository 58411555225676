import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles({
	loadingWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
})
interface Props {
	size?: number
	height?: string
}
export const Loader = ({ size, height }: Props) => {
	const classes = useStyles()
	return (
		<div
			className={classes.loadingWrapper}
			style={{ height: height ? height : '100vh' }}
		>
			<CircularProgress size={size ? size : undefined} />
		</div>
	)
}
