import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles<Theme>(({ breakpoints, palette }) => ({
	boxStyles: {
		width: '1470px',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		// breakpoint start
		[breakpoints.between('sm', 'md')]: {
			alignItems: 'center',
		},

		[breakpoints.down('sm')]: {
			width: '100%',
			height: 'auto',

			overflowY: 'auto',
		},
		// breakpoint end

		'& .MuiGrid-container': {
			justifyContent: 'center',
			gap: '1%',
			height: '100%',
			// breakpoint start
			[breakpoints.between('sm', 'md')]: {
				width: '65%',
			},
			// breakpoint end
		},

		'& > div > div': {
			// margin: '5px 5px',
			display: 'flex',
			flexDirection: 'column',

			[breakpoints.between('sm', 'md')]: {
				flex: '1 1 33%',
			},

			[breakpoints.down('sm')]: {
				width: '46%',
			},
		},
	},

	answerCard: {
		color: palette.text.secondary,
		fontFamily: 'Sora, sans-serif',
		fontSize: '1rem',
		padding: '0 31px',
		width: '469px',
		boxSizing: 'border-box',
		height: '98px',
		border: '2.8px solid rgba(49,49,49,0.15)',

		borderRadius: '14px',
		overflowWrap: 'break-word',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		textAlign: 'start',
		backgroundColor: 'white',

		[breakpoints.up('md')]: {
			'&:hover': {
				borderColor: '#0071C2',
				border: '2.8px solid #0071C2',
				boxShadow: '5px 5px 10px rgb(0 113 194 / 19%)',
			},
		},
		[breakpoints.between('sm', 'md')]: {
			height: '100%',
		},

		[breakpoints.down('md')]: {
			// fontSize: "1.5rem",
			paddingTop: '11px',
			paddingInlineStart: '7px',
			alignItems: 'self-start',
			width: '100%',
		},
	},
	isChosed: {
		borderColor: '#0071C2',
		border: '2.8px solid #0071C2',
		boxShadow: '5px 5px 10px rgb(0 113 194 / 19%)',

		[breakpoints.down('sm')]: {
			fontSize: '0.9rem',
		},
	},
	answerCardAlt: {
		color: '#0071C2',
		border: '2.8px solid rgba(49,49,49,0.15)',
	},

	regular: {
		borderColor: 'rgba(49,49,49,0.15)',

		[breakpoints.down('sm')]: {
			fontSize: '0.9rem',
		},
	},
	subtitleStyle: {
		color: '#646464',
		marginBottom: '45px',

		[breakpoints.down('xs')]: {
			marginBottom: '1%',
		},
	},
	previousButton: {
		height: '54px',
		width: '224px',
		border: '2.36px solid #949494',
		borderRadius: '13.6px',
		backgroundColor: '#FFFFFF',
		cursor: 'pointer',

		marginLeft: '14px',
		borderColor: '#0071C2',
		color: '#0071C2',
		fontSize: '1rem',
		textAlign: 'center',
	},
}))
