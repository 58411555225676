import { createSelector } from 'reselect'
import { IStoreRootState } from 'interface/redux'
import IUser from 'interface/user/user-interface'
import { IUsersState } from 'redux/user/user-reducer'

export const userStateSelector = (state: IStoreRootState) =>
	state.user as IUsersState

export const userCompanyId = createSelector(
	userStateSelector,
	state => state.user.company_id
)
export const userErrorSelector = createSelector(
	userStateSelector,
	state => state.formError
)
export const serverErrorSelector = createSelector(
	userStateSelector,
	state => state.error
)
export const userLoggedInSelector = createSelector(
	userStateSelector,
	state => state.isLoggedIn
)
export const userLastLangSelector = createSelector(
	userStateSelector,
	state => state.lastLang
)
export const userLoadingSelector = createSelector(
	userStateSelector,
	state => state.loading
)
export const userSelector = createSelector(
	userStateSelector,
	state => (state.user as IUser) || {}
)
export const getUserGoals = createSelector(userStateSelector, state => {
	return state.user.statusInfo.goals
})
export const preservUserInputSelector = createSelector(
	userStateSelector,
	state => state?.inputUserRef || ''
)
export const brieflySelector = createSelector(
	userStateSelector,
	state => state.brifelyRef || {}
)
export const selectedEmployeeSelector = createSelector(
	userStateSelector,
	state => state.outputFilterRule
)

export const userSuccessMessage = createSelector(
	userStateSelector,
	state => state.message || ''
)

export const lessonCopiedSelector = createSelector(
	userStateSelector,
	state => state.lessonCopied || ''
)
