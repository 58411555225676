import { useTranslation } from 'react-i18next'
import Button from 'components/common/Button'
import useStyles from './styles'

type Props = {
    label?: string
    isCloseButton?: boolean
    acceptConfirm?: boolean
    onClose?: () => void
    onDiscard?: () => void
    onConfirm?: () => void
}
const ConfirmModalButton = ({ label, isCloseButton, acceptConfirm, onClose, onDiscard, onConfirm }: Props) => {
    const { t } = useTranslation()
    const defaultLabel = isCloseButton ? t('no') : t('yes')
    const hasCondition = acceptConfirm !== undefined
    const classes = useStyles({})

    const onConfirmModal = () => {
        if (!hasCondition) {
            onConfirm!()
            onClose!()
        } else {
            if (acceptConfirm) {
                onConfirm!()
                onClose!()
            }
        }
    }
    return <Button
        className={classes.lmModalBtn}
        height={45}
        onClick={onConfirmModal}
    >{label ? t(label) : defaultLabel}</Button>

}

export default ConfirmModalButton