import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles<Theme>(({ palette, breakpoints }) => ({
	singleChoiceWrapper: {
		display: 'flex',
		width: '500px',
		flexDirection: 'row',
		margin: '43px 0 44px 0',
		flexWrap: 'wrap',
		[breakpoints.down('sm')]: {
			height: 'auto',
			margin: 'auto',
		},
	},
	answerCard: {
		width: '220px',
		boxSizing: 'border-box',
		height: '98px',
		border: '1.4px solid',
		borderRadius: '14px',
		overflowWrap: 'break-word',
		padding: '28px 31px 0 31px',
		cursor: 'pointer',
		margin: '15px',
		color: palette.text.secondary,

		'&:last-child': {
			marginBottom: 0,
		},
	},
	isChosed: {
		borderColor: '#0071C2',
		border: '2.8px solid #0071C2',
		boxShadow: '5px 5px 10px rgb(0 113 194 / 19%)',
	},
	regular: {
		borderColor: 'rgba(49,49,49,0.15)',
	},
}))
