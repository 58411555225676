import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
	getCurrentPraiseQuestionSelectore,
	praisesLoadingSelector,
} from 'redux/praise/praise-selector'

import AnswerGroupTree from 'components/Bot/AnswerGroupTree/index'
import BotHeader from 'components/common/Header/BotHeader'
import { UseClickActions, UseReplaceNameHook } from 'components/Bot/useHooks'
import {
	getCurrentFeedbackSelector,
	feedbackLoadingSelector,
} from '../../redux/feedback/feedback-selector'
import { useStyles } from './useStyles'
import { BackgroundImage } from 'components/Bot/BackgroundImage'
import { Loader } from '../../components/common/Loader/index'
import { AppDirectionContext } from '../../context/languages-context'
import {
	Question,
	QuestionValue,
} from '../../interface/praise/praise-interface'
import { userSelector } from 'redux/user/user-selector'
import { GET, POST } from 'api/api-requests'
import { URLS } from 'api/api-urls'
import { Req } from 'enums/req/req.enum'
import { setItem } from 'utils/localStorage'
import { ScreenWrapper } from '../../components/Bot/ScreenWrapper/index'
import { userActions } from 'redux/user/user-reducer'

export type Step = {
	nextQuestion: string | null
	previousQuestion: string | null
}

enum BotPath {
	praise = '/dashboard/bot/praise',
}

const BotScreen = () => {
	const dispatch = useDispatch()
	const [questionStep, setQuestion] = useState<string | null>(null)
	const [isAbleToSkipFurtherImpact, setIsAbleToSkipFurtherImpact] =
		useState(false)

	const { pathname } = useLocation()

	const isPraise = BotPath.praise === pathname
	const question: Question<Array<QuestionValue>, string> | null = useSelector(
		isPraise ? getCurrentPraiseQuestionSelectore : getCurrentFeedbackSelector
	)
	const isLoading = useSelector(
		isPraise ? praisesLoadingSelector : feedbackLoadingSelector
	)
	const user = useSelector(userSelector)

	const { isRtlDirection } = useContext(AppDirectionContext)

	const { fetchQuestion } = UseClickActions()

	useEffect(() => {
		fetchQuestion(isPraise, questionStep)
	}, [questionStep, isPraise])

	useEffect(() => {
		const createLog = async () => {
			const company: any = await GET(`${URLS.COMPANY}/${user?.company_id}`)
			const createLogResponse: any = await POST(URLS.BOT_LOGS, {
				senderName: `${company.data.companyName} ${user?.personalInfo?.firstName} ${user?.personalInfo?.lastName}`,
			})
			if (createLogResponse.status === Req.success)
				setItem('logId', createLogResponse.data)
		}
		createLog()
		dispatch(userActions.clearBrieflyInput())
	}, [user?.personalInfo?.firstName])

	const title = UseReplaceNameHook(question ? question.title : '')
	const classes = useStyles({ isRtlDirection })

	if (!question) return <Loader />

	return (
		<>
			<BotScreen.ScreenWrapper>
				<BotHeader isPraise={isPraise} />
				{isLoading ? (
					<Loader />
				) : (
					<div className={classes.container}>
						<>
							{title && <div className={classes.questionTitle}>{title}</div>}
							<AnswerGroupTree
								isPraise={isPraise}
								setQuestion={setQuestion}
								currentQuestion={question}
								isAbleToSkipFurtherImpact={isAbleToSkipFurtherImpact}
								setIsAbleToSkipFurtherImpact={setIsAbleToSkipFurtherImpact}
							/>
						</>
					</div>
				)}
			</BotScreen.ScreenWrapper>
			{!isLoading && (
				<BotScreen.BackgroundImage image={question.backgroundIcon!} />
			)}
		</>
	)
}

export default BotScreen
BotScreen.BackgroundImage = BackgroundImage
BotScreen.ScreenWrapper = ScreenWrapper
