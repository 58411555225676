import { Draggable } from 'react-beautiful-dnd'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ListItem from '@material-ui/core/ListItem'

export interface ListItem {
	id: string
	element: JSX.Element
}
export type DraggableListItemProps = {
	disableDrag: boolean
	item: ListItem
	index: number
}
const DraggableListItem = ({
	item,
	index,
	disableDrag,
}: DraggableListItemProps) => {
	const classes = useStyles()
	return (
		<Draggable isDragDisabled={disableDrag} draggableId={item.id} index={index}>
			{(provided, snapshot) => (
				<ListItem
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className={
						snapshot.isDragging
							? `${classes.draggingListItem} ${classes.listItem}`
							: classes.listItem
					}
				>
					{item.element}
				</ListItem>
			)}
		</Draggable>
	)
}
export default DraggableListItem

const useStyles = makeStyles({
	draggingListItem: {
		'&>div': {
			boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
		},
	},
	listItem: {},
})
