import React, { FC, useContext } from 'react'
import Button from '../../Button'
import InputBuilder from '../../../CompanyBuilder/Builder/InputBuilder'
import { useTranslation } from 'react-i18next'
import DropDownBuilder from 'components/CompanyBuilder/Builder/DropDownBuilder'
import useStyles from './useStyles'
import useLogic from './useLogic'
import { userActions } from 'redux/user/user-reducer'
import { useDispatch } from 'react-redux'
import { AppDirectionContext } from 'context/languages-context'
import UploadAvatar from '../UploadAvatar'

type UserPersonalMenuProps = {
	setIsEditingCardOpen: React.Dispatch<React.SetStateAction<boolean>>
}
const DISALLOW_SPECIAL_CHARS_AND_DIGITS_REGEX =
	/[*|":<>[\]{}%^!#%^`\\()';@&\d\s$]/
const ALLOWED_DIGITS = /[^0-9.]/g
const ALLOWED_EMAIL_REGEX =
	/^[A-Za-z0-9\.\-]{3,}@[A-Za-z0-9\-]{1,}(\.[a-z]{2,})+$/

export const ALLOWED_PRESET: Array<any> = [
	'050',
	'051',
	'052',
	'053',
	'054',
	'055',
	'056',
	'058',
	'059',
	'9725',
	'3706',
]
const UserPersonalMenu = (props: UserPersonalMenuProps) => {
	const { setIsEditingCardOpen } = props
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const {
		handleNewEmployeeSubmition,
		handleChangePasswordClick,
		handleUploadFile,
		handleChange,
		handleSignout,
		disabled,
		user,
		errors,
		previewImage,
		values,
	} = useLogic({ hanaldeToggleCard: () => setIsEditingCardOpen(prev => !prev) })
	return (
		<form onSubmit={handleNewEmployeeSubmition}>
			<div className={classes.personalInfoContainer}>
				<div className={classes.headerSection}>
					{/* Avater with Camera */}
					<UserPersonalMenu.UploadAvatar
						previewImage={previewImage}
						handleUplingFile={handleUploadFile}
					/>
					<div className={classes.userInfo}>
						<h2>
							{user?.personalInfo.firstName + ' ' + user?.personalInfo.lastName}
						</h2>
						<h3>{user?.statusInfo.title}</h3>
						<h3>{user?.securityInfo.email}</h3>
					</div>
				</div>

				<div className={classes.inputContainer}>
					<div className={classes.inputLabel}>{t('cell')}</div>

					<div className={classes.dropdownContainer}>
						<DropDownBuilder
							handleChange={handleChange}
							defaultValue={values.mobile_prefix}
							name='mobile_prefix'
							values={ALLOWED_PRESET}
							backGroundColor='#ECEBF3'
							maxWidth={80}
							height='45px'
							top='auto'
						/>

						<div
							style={
								isRtlDirection
									? { marginInlineEnd: 7, width: 'auto' }
									: { marginInlineStart: 7, width: '100%' }
							}
						>
							<InputBuilder
								value={values?.mobile}
								handleInputChange={handleChange}
								maxLength={8}
								type='number'
								name='mobile'
								showError={errors.mobile}
								backGroundColor='#ECEBF3'
								padding='14px 0 13px 20px'
								height='45px'
								width='69%'
								isRtlDirection={false}
							/>
						</div>
					</div>
					{errors.mobile && (
						<div className={classes.mobileError}>{t(errors.mobile)}</div>
					)}
					<Button
						className={classes.updateButton}
						isInverted={disabled}
						borderRadius='4px'
						bgColor='#0071C2'
						type='submit'
						width={80}
					>
						{t('Update')}
					</Button>
				</div>

				<div className={classes.bottomContainer}>
					<div className={classes.signOut} onClick={handleSignout}>
						{t('sign_out')}
					</div>
					<div
						className={classes.changePassword}
						onClick={() => {
							dispatch(userActions.saveUserLanguage())
							handleChangePasswordClick()
						}}
					>
						{t('change_password')}
					</div>
				</div>
			</div>
		</form>
	)
}

export default UserPersonalMenu

UserPersonalMenu.UploadAvatar = UploadAvatar
