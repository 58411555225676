import { FC, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Avatar, Typography } from '@material-ui/core'

import { userSelector } from 'redux/user/user-selector'

import { AppDirectionContext } from 'context/languages-context'

import useStyles from './styles'

import useOutputNavigation from 'utils/useOutputNavigation'

import { getUserInitials } from 'utils'
import { getRelativeTime } from 'utils/formatTime'
import { updatePraiseOutputState } from 'api/praise'

import { PraiseState } from 'enums/praise-state/praise-state.enum'
import { UpdatePraisePatch } from 'interface/api/get-payload.types'
import {
	NotificationTypes,
	INotification,
} from 'interface/notifications/notifications-interface'

interface Props extends INotification {
	setNotificationOld: Function
	close: (e?: any) => void
}
const Notification: FC<Props> = props => {
	const {
		_id,
		output_id,
		imageUrl,
		firstName,
		lastName,
		description,
		date,
		type,
		isNewNotification,
		setNotificationOld,
		close: closeNotifications,
	} = props

	const { company_id, personalInfo: { firstName: receiverName } } = useSelector(userSelector)
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isHeb: isRtlDirection })

	const { t } = useTranslation()

	const output = {
		_id,
		output_id,
		imageUrl,
		firstName,
		lastName,
		description,
		date,
		type,
		isNewNotification
	} as INotification

	const { goEndpoint } = useOutputNavigation()

	const onNotificationClicked = () => {
		const isPraise = type === NotificationTypes.PRAISE

		if (isNewNotification) {
			setNotificationOld(_id)

			if (isPraise) updatePraiseState()
		}

		goEndpoint(output)

		closeNotifications()
	}
	const updatePraiseState = () => {
		const praise: UpdatePraisePatch = {
			_id: output_id!,
			company_id: company_id!,
			praiseState: PraiseState.VIEWED,
		}

		updatePraiseOutputState(praise)
	}

	return (
		<div
			onClick={onNotificationClicked}
			className={`${classes.itemContainer} ${isNewNotification ? classes.newNotificationContainer : ''}`}
		>
			<Avatar alt='avatar' src={imageUrl} className={classes.avatar}>
				{!imageUrl && (
					<p className={classes.avatarName}>
						{getUserInitials(firstName, lastName)}
					</p>
				)}
			</Avatar>
			<div className={classes.content}>
				<Typography
					className={classes.name}
				>{`${firstName} ${lastName}`}</Typography>
				<Typography className={classes.description}>
					"
					{t(description ?? '', {
						senderName: firstName,
						receiverName,
						interpolation: { escapeValue: false },
					})}
					"
				</Typography>
			</div>
			<Typography className={classes.date}>
				{getRelativeTime(date, t, isRtlDirection)}
			</Typography>
		</div>
	)
}

export default Notification

/* addition code that can use in notification component:
type === NotificationTypes.FEEDBACK ? (
					<div className={classes.feedbackLink} onClick={navigationManager}>
						{t('view_feedback')}
					</div>
				) : (
					<div className={classes.feedbackLink} onClick={navigationManager}>
						{t('view_praise')}
					</div>
				)} 

				<Link
					  component={RouterLink}
					  onClick={feedbackLinkHandler}
					  className={classes.feedbackLink}
					  to={`/feedback/:${_id}`}
				 >
					  {t('view_full_feedback')}
				 </Link>
*/