import { useState, useRef, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import TextField from '@mui/material/TextField'
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import { Card } from '@material-ui/core'
import { Search as SearchIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

import { AppDirectionContext } from 'context/languages-context'

import useStyles from './styles'

import IUser from 'interface/user/user-interface'

interface Props {
	onSelect: (idx: number) => void
	onRowCreate: (employee: IUser, type: boolean) => Object
	managers: any
	employees: IUser[]
	defaultValue: Object
}
const ManagersBox = ({
	onSelect,
	onRowCreate,
	managers,
	employees,
}: Props) => {
	const [value, setValue] = useState<{ value: string; idx: number } | null>(managers[0])
	const [inputValue, setInputValue] = useState<string>('')

	const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)

	const classes = useStyles({ isRtlDirection, isManagersBox: true })

	const inputRef = useRef<HTMLInputElement | null>(null)

	const onFocusSearchArea = useCallback(() => () => {
		if (inputRef.current) {
			inputRef.current.focus()
			setValue(prev => {
				return { value: '', idx: prev ? prev.idx : 0 }
			})
		}
	}, [inputRef])

	const popupIcon = (<ExpandMoreIcon
		fontSize={'large'}
		className={classes.popupIcon}
	/>)

	const isOptionEqualToValue = (option: any, value: any) => {
		if (value.value === '') return true
		return option.value === value.value
	}
	const renderInput = (params: AutocompleteRenderInputParams) => (
		<TextField
			inputRef={inputRef}
			onClick={setNewValue}
			onBlur={onBlurInput}
			placeholder={t('type_to_search')}
			{...params}
			InputProps={{ ...params.InputProps, className: classes.f }}
		/>
	)
	const setNewValue = () => setValue(prevValue => {
		return { value: '', idx: prevValue ? prevValue.idx : 0 }
	})
	const onBlurInput = () => setValue(prevValue => {
		return {
			value: managers[prevValue ? prevValue.idx : 0].value,
			idx: prevValue ? prevValue.idx : 0,
		}
	})
	const handleChangeValue = (
		event: any,
		newValue: { value: string; idx: number } | null
	) => {
		setValue(newValue)
		newValue && onSelect(newValue.idx)
	}
	const handleChangeInput = (event: any, newInputValue: string) => {
		setInputValue(newInputValue)
	}

	return (
		<div className={classes.cardContainer}>
			<Card className={classes.card}>

				<div
					style={{ flexDirection: isRtlDirection ? 'row-reverse' : 'row' }}
					className={classes.cardTitle}
				>
					<div className={classes.icon}>
						<SearchIcon onClick={onFocusSearchArea} />
					</div>

					<div style={{ width: '100%' }}>
						{
							<Autocomplete
								noOptionsText={'No Managers'}
								popupIcon={popupIcon}
								isOptionEqualToValue={isOptionEqualToValue}
								blurOnSelect
								value={value}
								onChange={handleChangeValue}
								inputValue={inputValue}
								onInputChange={handleChangeInput}
								className={classes.textField}
								options={managers}
								getOptionLabel={option => option.value}
								renderInput={renderInput}
							/>
						}
					</div>
				</div>

				<div className={classes.cardContent}>
					{employees.map((employee: IUser) => {
						const row = onRowCreate(employee, false)
						return row
					})}
				</div>

			</Card>
		</div>
	)
}
export default ManagersBox
