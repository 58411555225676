import { useDispatch, useSelector } from "react-redux"

import { teamsActions } from "redux/teams/teams-reducer"
import { teamSelector } from "redux/teams/teams-selector"

import { ITeamTask } from "interface/teams/teams-interface"

type SocketPayload = ITeamTask<string> & {
    team_id?: string
}

const useTeamSocketRespond = (current_task?: string) => {

    const dispatch = useDispatch()
    const { _id, members_id } = useSelector(teamSelector)

    const handleCreateTask = (task: SocketPayload) => {
        if (task.team_id !== _id) return
        delete task.team_id

        dispatch(teamsActions.updateTeamMembersByNewTask({ task, participants: members_id }))
    }

    const handleUpdateTask = (task: SocketPayload) => {
        if (task._id !== current_task) return
        delete task.team_id

        dispatch(teamsActions.updateTaskDetails({ taskInfo: task, participants: members_id }))
    }

    const dispatchTaskDoneToParticipants = (task: SocketPayload) => {
        if (task._id !== current_task) return

        dispatch(teamsActions.markTaskDone({ taskInfo: task, participants: members_id }))
    }

    const dispatchTaskDeleted = (payload: { task_id: string, team_id: string }) => {
        if (payload.team_id !== _id) return

        dispatch(teamsActions.dispatchDeletedTask(payload.task_id))
    }

    return {
        wsCreateTask: handleCreateTask,
        wsUpdate: handleUpdateTask,
        wsDoneTask: dispatchTaskDoneToParticipants,
        wsDeleteTask: dispatchTaskDeleted
    }
}

export default useTeamSocketRespond;