import { POST, GET, DELETE, PATCH, PUT } from './api-requests'
import { URLS } from './api-urls'

import { Response } from 'interface/api/response-interface'
import { Company } from 'interface/company/company-interface'
import { Roles } from 'enums/user/user-roles'

import { FetchEmployeesActivities } from 'interface/api/get-payload.types'
import { TaskSection } from 'enums/task/tasks.enum'
import { ExpansionPayload } from 'interface/bot/index'

export const getCompanyPraises = async (
	payload: Record<string, unknown>
): Promise<Response> => {
	const { companyId, pageNumber = 0 } = payload
	const { data, status } =
		await GET(`${URLS.COMPANY_PRAISES}/${companyId}?pageNumber=${pageNumber}`)
	return { data, status }
}

export const fetchEmployeesActivities = async ({
	company_id,
	users_id,
}: FetchEmployeesActivities): Promise<Response> => {
	const { data, status } =
		await GET(`${URLS.COMPANY}/activities/${company_id}?user_id=${users_id}`)
	return { data, status }
}

export const updatePraiseReactions = async (
	company_id: string,
	praise_id: string,
	reactions: { like: string[]; love: string[]; clap: string[] }
): Promise<Response> => {
	const { data, status } = await PUT(URLS.COMPANY_PRAISES, {
		company_id,
		praise_id,
		reactions,
	})
	return { data, status }
}

export const updateCompanyActivation = async (
	payload: Record<string, any>
): Promise<Response> => {
	const { data, status, code } = await PUT(`${URLS.COMPANY}/isActivate`, {
		...payload,
	})
	return { data, status, code }
}

export const getCompanies = async (): Promise<Response> => {
	const { data, status } = await GET(`${URLS.COMPANY}`)
	return { data, status }
}

export const deleteCompany = async (_id: string): Promise<Response> => {
	const { data, status } = await DELETE(`${URLS.COMPANY}/${_id}`)
	return { data, status }
}

export const sendEmails = async (
	emailArray: Array<string>
): Promise<Response> => {
	const { data, status } = await POST(`${URLS.COMPANY}/email`, {
		emails: [...emailArray],
	})
	return { data, status }
}

export const createCompany = async (company: Company): Promise<Response> => {
	const { data, status } = await POST(`${URLS.COMPANY}`, {
		...company,
	})
	return { data, status }
}

export const updateCompany = async (company: Company): Promise<Response> => {
	const { data, status } = await PATCH(`${URLS.COMPANY}`, {
		...company,
	})
	return { data, status }
}

export const getCompanyValues = async (
	company_id: string
): Promise<Response> => {
	const { data, status } = await GET(`${URLS.COMAPNY_VALUES}/${company_id}`)
	return { data, status }
}
export const getCompanyDepartments = async (
	company_id: string
): Promise<Response> => {
	const { data, status } = await GET(`${URLS.COMAPNY_DEPARTMENTS}/${company_id}`)
	return { data, status }
}

export const getDepartmentSubDepartments = async (payload: {
	company_id: string
	department: string
}): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.COMAPNY_DEPARTMENTS}/subdepartments/${payload.company_id}/${payload.department}`
	)
	return { data, status }
}
export const getCompanySettings = async (
	company_id: string
): Promise<Response> => {
	const { data, status } = await GET(`${URLS.COMPANY_LAUNCHER}/${company_id}`)
	return { data, status }
}

export const setCompanyValues = async (
	company_id: string,
	values: string[]
): Promise<Response> => {
	const { data, status } = await POST(`${URLS.COMAPNY_VALUES}`, {
		company_id,
		companyValues: values,
	})
	return { data, status }
}

export const getCompanyLicense = async (
	company_id: string
): Promise<Response> => {
	const { data, status } = await GET(`${URLS.COMPANY_LICENSE}/${company_id}`)
	return { data, status }
}

export const setSmsNotifications = async (
	company_id: string,
	sms: boolean
): Promise<Response> => {
	const { data, status } = await PATCH(`${URLS.COMPANY_LAUNCHER}`, {
		company_id,
		sms,
	})
	return { data, status }
}
export const sendEmailToRole = async (
	company_id: string,
	role: Roles
): Promise<Response> => {
	const { data, status } = await POST(`${URLS.COMPANY_LAUNCHER}`, {
		company_id,
		role,
	})
	return { data, status }
}

export const getCompanyActivityForSuperAdmin = async (
	company_id: string
): Promise<Response> => {
	const { data, status } = await GET(`${URLS.COMPANY}/superadmin/${company_id}`)
	return { data, status }
}

export const getSummariesActivities = async (): Promise<Response> => {
	const { data, status } = await GET(`${URLS.COMPANY}/superadmin/summaries`)
	return { data, status }
}
export const getCompanyActivityForSuperAdminV2 = async (
	company_id: string
): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.COMPANY}/superadmin/v2/${company_id}`
	)
	return { data, status }
}
export const getCompanyChart = async (
	company_id: string
): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.COMPANY}/superadmin/chart/${company_id}`
	)
	return { data, status }
}

export const getCompanyExceptions = async (
	company_id: string
): Promise<Response> => {
	const { data, status } = await GET(`${URLS.COMPANY_EXCEPTIONS}/${company_id}`)
	return { data, status }
}
export const getNewExceptions = async (
	company_id: string,
	hr_id: string
): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.COMPANY_EXCEPTIONS}/new/${company_id}/${hr_id}`
	)
	return { data, status }
}
export const getUserBaseInsight = async (
	company_id: string,
	employee_id: string
): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.COMPANY_TASKS}/${company_id}/${employee_id}`
	)
	return { data, status }
}
export const getUserBaseInsightV2 = async (
	company_id: string,
	employee_id: string
): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.COMPANY_TASKS}/V2/${company_id}/${employee_id}`
	)
	return { data, status }
}
export const loadMoreTasks = async (
	company_id: string,
	employee_id: string,
	pageNumber: number,
	type: TaskSection
): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.COMPANY_TASKS}/load/${company_id}/${employee_id}/${pageNumber}?type=${type}`
	)
	return { data, status }
}
export const toggleTaskShow = async (
	company_id: string,
	task_id: string,
	employee_id: string
): Promise<Response> => {
	const { data, status } = await PATCH(
		`${URLS.COMPANY_TASKS}/${company_id}?employee_id=${employee_id}&task_id=${task_id}`
	)
	return { data, status }
}

export const updateOuputExpansions = async (
	payload: ExpansionPayload
): Promise<Response> => {
	const { data, status } = await PATCH(
		`${URLS.COMPANY_PRAISES}/expansions`,
		payload
	)
	return { data, status }
}
export const updateOuputExpansionsFeedback = async (
	payload: ExpansionPayload
): Promise<Response> => {
	const { data, status } = await PATCH(
		`${URLS.COMPANY_FEEDBACKS}/expansions`,
		payload
	)
	return { data, status }
}
export const updateReplayMessage = async (
	payload: { company_id: string, output_id: string, replayMessage: string }
): Promise<Response> => {
	const { data, status } = await PATCH(`${URLS.COMPANY_PRAISES}/replay`, payload)

	return { data, status }
}