import { RenderChosePath } from './RenderChosePath'
import { RenderInitialMessage } from './RenderInitialMessage'
import { RenderParticipantsChoice } from './RenderParticipantsChoice'
import { RenderGroupMessageChoice } from './RenderGroupMessageChoice'
import { RenderExpectedResultChoice } from './RenderExpectedResultChoice'
import { RenderReconstructionMessageChoice } from './RenderReconstructionMessageChoice'
import { RenderChronologicalEventsChoice } from './RenderChronologicalEventsChoice'
import { RenderMatchPointsChoice } from './RenderMatchPointsChoice'
import { RenderSelfAnalisysMessageChoice } from './RenderSelfAnalisysMessageChoice'
import { RenderSelfAnalisysChatBotChoice } from './RenderSelfAnalisysChatBotChoice'
import { RenderLearningprocessoutputsSummary } from './RenderLearningprocessoutputsSummary'
import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import { EMPTY_STATE } from './useTreeLogic'

export const CHOOSE_DEPARTMENT = DebriefingSteps.CHOOSE_DEPARTMENT
export const WRITE_DEPARTMENT = DebriefingSteps.WRITE_DEPARTMENT
export const CHOOSE_SUB_DEPARTMENT = DebriefingSteps.CHOOSE_SUB_DEPARTMENT
export const WRITE_SUB_DEPARTMENT = DebriefingSteps.WRITE_SUB_DEPARTMENT
export const CHOOSE_GOAL = DebriefingSteps.CHOOSE_GOAL //no goals for now.
export const CHOOSE_PATH = DebriefingSteps.CHOOSE_PATH
export const CHOOSE_VALUE = DebriefingSteps.CHOOSE_VALUE
export const EXPECTED_RESULT = DebriefingSteps.EXPECTED_RESULT
export const INITIAL_MESSAGE = DebriefingSteps.INITIAL_MESSAGE
export const RECONSTRUCTION_MESSAGE = DebriefingSteps.RECONSTRUCTION_MESSAGE
export const CHRONOLOGICAL_EVENTS = DebriefingSteps.CHRONOLOGICAL_EVENTS
export const MATCHPOINT_CHOICE = DebriefingSteps.MACTHPOINT_CHOICE
export const MATCHPOINT_CONFIRMATION = DebriefingSteps.MATCHPOINT_CONFIRMATION
export const SELF_ANALISYS_MESSAGE = DebriefingSteps.SELF_ANALISYS_MESSAGE
export const SUMMARY = DebriefingSteps.SUMMARY
export const FINISH = DebriefingSteps.FINISH
export const DEBRIEFING_SELF_ANALISYS_CHAT =
	DebriefingSteps.DEBRIEFING_SELF_ANALISYS_CHAT
export const LEARNING_PROCESS_OUTPUT = DebriefingSteps.LEARNING_PROCESS_OUTPUT
export const PARTICIPANTS_CHOICE = DebriefingSteps.PARTICIPANTS_CHOICE
export const GROUP_PROCESS_MESSAGE = DebriefingSteps.GROUP_PROCESS_MESSAGE

export const steps_list = {
	CHOOSE_DEPARTMENT,
	CHOOSE_GOAL,
	CHOOSE_PATH,
	CHOOSE_SUB_DEPARTMENT,
	CHOOSE_VALUE,
	CHRONOLOGICAL_EVENTS,
	DEBRIEFING_SELF_ANALISYS_CHAT,
	EXPECTED_RESULT,
	FINISH,
	GROUP_PROCESS_MESSAGE,
	INITIAL_MESSAGE,
	LEARNING_PROCESS_OUTPUT,
	MATCHPOINT_CHOICE,
	MATCHPOINT_CONFIRMATION,
	PARTICIPANTS_CHOICE,
	RECONSTRUCTION_MESSAGE,
	SELF_ANALISYS_MESSAGE,
	SUMMARY,
	WRITE_DEPARTMENT,
	WRITE_SUB_DEPARTMENT,
}

export const stepMapComponent = {
	[CHOOSE_PATH]: RenderChosePath,
	[INITIAL_MESSAGE]: RenderInitialMessage,
	[PARTICIPANTS_CHOICE]: RenderParticipantsChoice,
	[GROUP_PROCESS_MESSAGE]: RenderGroupMessageChoice,
	[EXPECTED_RESULT]: RenderExpectedResultChoice,
	[RECONSTRUCTION_MESSAGE]: RenderReconstructionMessageChoice,
	[CHRONOLOGICAL_EVENTS]: RenderChronologicalEventsChoice,
	[MATCHPOINT_CHOICE]: RenderMatchPointsChoice,
	[SELF_ANALISYS_MESSAGE]: RenderSelfAnalisysMessageChoice,
	[DEBRIEFING_SELF_ANALISYS_CHAT]: RenderSelfAnalisysChatBotChoice,
	[SUMMARY]: RenderLearningprocessoutputsSummary,
	[FINISH]: RenderLearningprocessoutputsSummary,
	[CHOOSE_DEPARTMENT]: () => EMPTY_STATE,
	[CHOOSE_SUB_DEPARTMENT]: () => EMPTY_STATE,
	[CHOOSE_SUB_DEPARTMENT]: () => EMPTY_STATE,
	[CHOOSE_GOAL]: () => EMPTY_STATE,
	[CHOOSE_VALUE]: () => EMPTY_STATE,
	[LEARNING_PROCESS_OUTPUT]: () => EMPTY_STATE,
	[CHOOSE_DEPARTMENT]: () => EMPTY_STATE,
	[MATCHPOINT_CONFIRMATION]: () => EMPTY_STATE,
	[WRITE_DEPARTMENT]: () => EMPTY_STATE,
	[WRITE_SUB_DEPARTMENT]: () => EMPTY_STATE,
}
