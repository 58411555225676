import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import colors from 'utils/colors'

export type Styles = {
    color?: string,
    firstColumn?: boolean
}

export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
    header: {
        textAlign: 'center',
        fontWeight: 650,
        fontFamily: 'Sora, sans-serif',
        fontSize: '31px'
    },
    companyDetails: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '22px',
        fontWeight: 'normal',
        textAlign: 'center',
        justifyContent: 'space-evenly',
        marginTop: '10px'
    },
    usersCounter: {
        color: colors.emeraldGreen,
        fontSize: '22px',
        fontFamily: 'Sora, sans-serif',
        fontWeight: 350,
    },
    date: {
        color: colors.boldBlue,
        fontWeight: 'normal'
    },
    tooltip: {
        fontSize: '13px',
        fontWeight: 'normal',
        fontFamily: 'Sora, sanes-serif'
    },
    wrapper: {
        marginTop: '10px',
        width: '90%',
        overflow: 'scroll',
        overflowX: 'scroll',
        display: 'flex',
        marginRight: 'auto',
        marginLeft: 'auto',
        // '& >*': {
        //     animation: ({ firstColumn }) => (firstColumn ? 'none' : "slideDown .350s ease-in-out"),
        //     animationDuration: '32.5s',
        // },
        // '& >div:nth-child(2)': {
        //     animationDelay: '0s',
        // },
        // '& >div:nth-child(3)': {
        //     animationDelay: '2.5s',
        // },
        // '& >div:nth-child(4)': {
        //     animationDelay: '5s',
        // },
        // '& >div:nth-child(5)': {
        //     animationDelay: '7.5s',
        // },
        // '& >div:nth-child(6)': {
        //     animationDelay: '10s',
        // },
        // '& >div:nth-child(7)': {
        //     animationDelay: '12.5s',
        // },
        // '& >div:nth-child(8)': {
        //     animationDelay: '15s',
        // },
        // '& >div:nth-child(9)': {
        //     animationDelay: '17.5s',
        // },
        // '& >div:nth-child(10)': {
        //     animationDelay: '20s',
        // },
        // '& >div:nth-child(11)': {
        //     animationDelay: '22.5s',
        // },
        // '& >div:nth-child(12)': {
        //     animationDelay: '25s',
        // },
        // '& >div:nth-child(13)': {
        //     animationDelay: '27.5s',
        // },
        // '& >div:nth-child(14)': {
        //     animationDelay: '30s',
        // },
        // '& >div:nth-child(15)': {
        //     animationDelay: '32.5s',
        // },
    },
    tableColumn: {
        fontSize: '18px',
        maxWidth: '205px',
        maxHeight: '23%',
        textAlign: 'center',
        alignItems: 'center',
        fontFamily: 'Sora, sans-serif',
        fontWeight: 350,
        alignContent: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginRight: '3px',
        animation: ({ firstColumn }) => (firstColumn ? 'none' : "fadeIn .850s ease-in-out"),
        animationDuration: '32.5s',
        color: ({ firstColumn }) => (firstColumn ? "#000000" : "#814827"),
        '& >:first-child': {
            textOverflow: 'ellipsis',
            overflowY: 'auto',
            overflowX: 'hidden',
            height: '50px',
            color: '#ffffff',
            maxHeight: '60px',
            maxWidth: ({ firstColumn }) => (firstColumn ? '200px' : '150px'),
            width: ({ firstColumn }) => (firstColumn ? '200px' : '100px'),
            fontWeight: 400,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            letterSpacing: ({ firstColumn }) => (firstColumn ? '4px' : 0),
            borderRadius: '3px',
        },
        '& > div:nth-child(odd)': {
            backgroundColor: 'rgb(115 145 200 / 0.8)',

        },
        '& > div:nth-child(even)': {
            backgroundColor: 'rgb(189 198 217 / 0.6)',
        },
        '& > div': {
            margin: '3px 0 3px 0',
            backgroundColor: colors.blue,
            height: '40px',
            maxHeight: '50px',
            width: ({ firstColumn }) => (firstColumn ? '200px' : '100px'),
            maxWidth: ({ firstColumn }) => (firstColumn ? '200px' : '150px'),
            display: 'flex',
            justifyContent: ({ firstColumn }) => (firstColumn ? 'start' : 'center'),
            alignItems: 'center',
            paddingLeft: '5px',
            borderTopLeftRadius: ({ firstColumn }) => (firstColumn ? '3px' : 0),
            borderBottomLeftRadius: ({ firstColumn }) => (firstColumn ? '3px' : 0),
        },
    },
    '@global': {
        '@keyframes fadeIn': {
            '0%': {
                opacity: 0,
                // height: '10px',
                // position: 'absolute',
                // top: '-100%',
                // left: '20.5%'
            },
            '100%': {
                opacity: 1,
                // position: 'absolute',
                // top: '25%',
                // height: '40px',
                // left: '20.5%'
            },
        },
    },
    excelIcon: {
        width: 35,
        height: 35,
        cursor: 'pointer'

    }
}))