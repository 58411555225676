import { FC } from 'react'
import moment from 'moment'

import useStyles from './styled'
import './style.scss'

import TaskCheckbox from '../TaskCheckbox/TaskCheckbox'
import { IBaseInsight } from 'interface/baseInsight/baseInsight'
import useOutputNavigation from 'utils/useOutputNavigation'

interface Props extends IBaseInsight {
	employee_id: string
	toggleShowingTask: (task_id: string, includeUser: boolean) => void
}
const Task: FC<Props> = props => {
	const {
		description,
		date,
		shouldShow,
		employee_id,
		toggleShowingTask,
		_id: task_id,
		source,
		output_id = '',
		sourceOptions
	} = props
	const task: Partial<IBaseInsight> = {
		description,
		date,
		shouldShow,
		_id: task_id,
		source,
		output_id,
		sourceOptions
	}
	const { goEndpoint } = useOutputNavigation()
	const includeUser = shouldShow.includes(employee_id)

	const classes = useStyles({ shouldShow, employee_id })

	const EXCEPTION_LIMIT_DAYS = 1
	const SCROLL_TEXT_ON_LENGTH_DESCRIPTION = 90

	const dueDatePassed =
		moment.duration(moment().diff(moment(date))).days() >= EXCEPTION_LIMIT_DAYS

	const isLongDescription =
		description.length >= SCROLL_TEXT_ON_LENGTH_DESCRIPTION

	const dateStyle = dueDatePassed ? classes.date : ''

	return (
		<div className={classes.container}>

			<TaskCheckbox
				toggleShowingTask={() => toggleShowingTask(task_id, includeUser)}
				shouldShow={shouldShow}
				date={date!}
				employee_id={employee_id}
			/>

			<div
				className={classes.description}
				id={isLongDescription ? 'scroll-text' : ''}
				onClick={() => goEndpoint(task)}
			>
				{description}
			</div>

			<div className={dateStyle}>
				{date ? moment(date!).format('DD/MM/YYYY') : null}
			</div>

		</div>
	)
}
export default Task
