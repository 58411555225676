import { Debrief } from '../interface/debrief/debrief-interface'

type Payload<T> = {
	event: T
}
/**
 * The function accepts current debrief data and payload.
 * Returns new event with the old events.
 */

export default <T>(debriefOnProcess: Debrief, payload: Payload<T>) => {
	const { event } = payload

	const { events } = debriefOnProcess
	const existingDebriefEvents = events.length ? [...events] : []

	return {
		...debriefOnProcess,
		events: [...existingDebriefEvents, event],
	}
}
