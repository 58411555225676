import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Question, QuestionType } from 'interface/praise/praise-interface'
import TextArea from '../TextArea'
import DateChoice from '../DateChoice'
import SingleChoice from '../SingleChoice'
import SingleChoiceAlt from '../SingleChoiceAlt'
import MultipleChoice from '../MultipleChoice'
import GoalsChoice from '../GoalsChoice/index'
import { userSelector } from '../../../redux/user/user-selector'
import UsersList from '../UsersList/'
import Range from '../Range/index'
import DatePickerChoice from '../DatePickerChoice/index'
import SingleChoiceAltGrid from '../SingleChoiceAltGrid/'
import FeedbackRangeQuestionChoice from '../FeedbackRangeQuestionChoice/index'
import RangeOptionChoice from '../RangeChoice/index'
import MultipleChoiceGrid from '../multipleChoiceGrid/index'
import BotOnboarding from '../BotOnboarding/index'
import CompanyValuesChoice from '../CompanyValuesChoice'
import InitialMessage from '../InitialMessage/index'
import { QuestionValue } from '../../../interface/praise/praise-interface'

type AnswerGroupTreePropsType = {
	isPraise: boolean
	currentQuestion: Question<QuestionValue[], string>
	setQuestion: React.Dispatch<React.SetStateAction<string | null>>
	isAbleToSkipFurtherImpact: boolean
	setIsAbleToSkipFurtherImpact: React.Dispatch<React.SetStateAction<boolean>>
}

const AnswerGroupTree: FC<AnswerGroupTreePropsType> = ({
	currentQuestion,
	isPraise,
	setQuestion,
	isAbleToSkipFurtherImpact,
	setIsAbleToSkipFurtherImpact,
}) => {
	const { personalInfo } = useSelector(userSelector) ?? {}
	const { firstName = '', lastName = '' } = personalInfo ?? {}

	const { type } = currentQuestion

	const props = {
		setQuestion,
		isPraise,
		currentUserName: `${firstName.toUpperCase()} ${lastName}`,
		...currentQuestion,
	}

	if (!personalInfo) return <div>Loading...</div>

	if (type === QuestionType.FirstQuestion) {
		return <BotOnboarding {...props} />
	}
	if (type === QuestionType.InitialMessage) {
		return <InitialMessage {...{ ...props }} />
	}
	if (type === QuestionType.UsersList) {
		return <UsersList {...props} />
	}

	if (type === QuestionType.DateChoice) {
		return <DateChoice {...props} />
	}
	if (type === QuestionType.TextArea) {
		return <TextArea {...props} />
	}
	if (type === QuestionType.SingleChoice) {
		return <SingleChoice {...props} />
	}
	if (
		type === QuestionType.SingleChoiceAltRegular ||
		type === QuestionType.Reason
	) {
		return <SingleChoiceAlt {...props} />
	}

	if (type === QuestionType.SingleChoiceAlt) {
		return <SingleChoiceAltGrid {...props} />
	}

	if (type === QuestionType.MultipleChoice) {
		return <MultipleChoice {...props} />
	}

	if (type === QuestionType.MultipleChoiceGrid) {
		return (
			<MultipleChoiceGrid
				isAbleToSkipFurtherImpact={isAbleToSkipFurtherImpact}
				{...props}
			/>
		)
	}

	if (type === QuestionType.CompanyValuesChoice) {
		return <CompanyValuesChoice {...props} />
	}

	if (type === QuestionType.Range) {
		return <Range {...props} />
	}

	if (type === QuestionType.RangeOption) {
		return <RangeOptionChoice {...props} />
	}

	if (type === QuestionType.GoalsChoice) {
		return (
			<GoalsChoice
				setIsAbleToSkipFurtherImpact={setIsAbleToSkipFurtherImpact}
				{...props}
			/>
		)
	}

	if (type === QuestionType.DatePickerChoice) {
		return <DatePickerChoice {...props} />
	}

	if (type === QuestionType.FeedbackRangeQuestion) {
		return <FeedbackRangeQuestionChoice {...props} />
	}
	return <div>DEFAULT_COMPONENT_VALUE</div>
}

export default AnswerGroupTree
