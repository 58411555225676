import { Keys } from "enums/user/user-goal-status"

export type GoalInfo = {
	employee_id: string
	goal_id: string
}

export enum GOALITEMSTATUS {
	UNCHECKED,
	CHECKED
}

export enum GoalItemSaveStatus {
	TOCREATE,
	TOUPDATE,
	TODELETE
}

export enum GoalItemAction { //to determine what was the user interaction on the popup
	CREATE = 'CREATE',
	UPDATE = 'UPDATE',
	DELETE = 'DELETE',
	NONE = 'NONE',
  }

export type ListItemType = {
	status: GOALITEMSTATUS
	need: string
}

export type GoalPayload = {
	employee_id: string
	goal_id: string
	action: ListItemType & { key: Keys }
}


