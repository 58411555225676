import { FC, useContext, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'components/common/Loader'
import DebriefTree from 'components/Debriefing/DebriefTree'
import Header from 'components/Debriefing/Header'
import { AppDirectionContext } from 'context/languages-context'
import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import { Debrief } from 'interface/debrief/debrief-interface'
import { debriefActions } from 'redux/debrief/debrief-reducer'
import {
	debriefLoadingSelector,
	debriefOnProccesSelector,
} from 'redux/debrief/debrief-selector'
import { userSelector } from 'redux/user/user-selector'
import { getItem } from 'utils/localStorage'
import { useStyles } from '../Bot/useStyles'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/common/ErrorFallback'

const DebriefingProcessScreen: FC = () => {
	useStartDebrief()

	const { isRtlDirection } = useContext(AppDirectionContext)

	const debrief: Debrief | null = useSelector(debriefOnProccesSelector)
	const isLoading = useSelector(debriefLoadingSelector)
	const user = useSelector(userSelector)

	const wrapperRef = useRef<HTMLDivElement>(null)

	const props = { user, isRtlDirection, wrapperRef, debrief: debrief! }
	const classes = useStyles({ isRtlDirection })
	const showTree = debrief && user?._id

	return (
		<ErrorBoundary
			FallbackComponent={ErrorFallback}
			onError={(e: any) => {
				console.log({ e })
			}}
		>
			<div ref={wrapperRef} className={classes.screenWrapper}>
				<Header />
				{isLoading ? (
					<Loader size={150} height={'70vh'} />
				) : showTree ? (
					<DebriefTree {...props} />
				) : (
					<DefaultDisplay />
				)}
			</div>
		</ErrorBoundary>
	)
}

const DefaultDisplay = () => {
	return (
		<div
			style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
		>
			Unable to display debrief.
		</div>
	)
}

const ErrorWrapper = (Comp: FC) => {
	return (
		<ErrorBoundary
			FallbackComponent={ErrorFallback}
			onError={(e: any) => {
				console.log({ e })
			}}
		>
			<Comp />
		</ErrorBoundary>
	)
}

const HOTDebriefingProcessScreen = ErrorWrapper(DebriefingProcessScreen)

export default DebriefingProcessScreen

const useStartDebrief = () => {
	const debrief: Debrief | null = useSelector(debriefOnProccesSelector)
	const user = useSelector(userSelector)

	const dispatch = useDispatch()

	useEffect(() => {
		if (!debrief) {
			const cachedDebrief = getItem('debrief')
			const newDebreif = {
				creator: user,
				step: DebriefingSteps.CHOOSE_PATH,
			} as Debrief

			const debriefToSet =
				cachedDebrief && 'company_id' in cachedDebrief ? cachedDebrief : newDebreif

			dispatch(debriefActions.setDebriefOnProcess(debriefToSet))
		}
	}, [user])
}
