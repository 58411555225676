import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userLoggedInSelector, userSelector } from './redux/user/user-selector'
import { get_question_praise } from 'redux/praise/praise-actions'
import { get_question_feedback } from 'redux/feedback/feedback-actions'
// import { get_employees } from 'redux/employee/employee-actions'
import { get_company_values } from 'redux/company/company-actions'
import { useBeforeunload } from 'react-beforeunload'
import { getItem, setItem } from 'utils/localStorage'
import { userActions } from 'redux/user/user-reducer'
import { getBotAnswers } from 'redux/bot/bot-selector'
import { botActions, UserAnswerType } from './redux/bot/bot-reducer'
import { Debrief } from './interface/debrief/debrief-interface'
import { debriefOnProccesSelector } from 'redux/debrief/debrief-selector'

//this logic should happen after login in the right place. right now happening from app.
const AppLogic = () => {
	const dispatch = useDispatch()
	const user = useSelector(userSelector)
	const isLoggedIn = useSelector(userLoggedInSelector)
	const getBotAnswerSelector: UserAnswerType = useSelector(getBotAnswers)
	const debriefOnProcces: Debrief | null = useSelector(debriefOnProccesSelector)

	useEffect(() => {
		const getAppdata = async () => {
			const companyId = user.company_id!
			dispatch(get_company_values(companyId!))

			dispatch(
				get_question_praise({
					questionId: 'first',
					language: user.statusInfo.companyLanguage,
				})
			)
			dispatch(
				get_question_feedback({
					questionId: 'first',
					language: user.statusInfo.companyLanguage,
				})
			)
		}
		if (isLoggedIn && !user.statusInfo.isSuperAdmin) getAppdata()
	}, [isLoggedIn])

	useBeforeunload(() => {
		const { _id, company_id } = user
		'_id' in user && setItem('user', { _id, company_id })

		// Note: if there is a praise or feedback output at the state
		// 		 we persist it in storage before loading screen
		getBotAnswerSelector?.answers.length &&
			setItem('output-data', getBotAnswerSelector)

		// Note: if there is a debrief at the state
		// 		 we persist it in storage before loading screen
		debriefOnProcces && setItem('debrief', debriefOnProcces)
	})

	return null
}
export default AppLogic
