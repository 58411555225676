enum Paths {
	LOGIN = '/',
	ON_BOARDING = '/onBoarding',
	COMPANY_BUILDER = '/backOffice/company-builder',
	DASHBOARD = '/dashboard',
	BOT = '/dashboard/bot',
	DEBRIEF_PROCESS = '/debriefings/process',
	OUTPUT = '/output/:id?',
	CHANGE_PASSWORD = '/change-password/:email?',
	RECEIVED_FEEDBACK_PROCESS = '/received-feedback-process',
	SENT_FEEDBACK_PROCESS = '/sent-feedback-process',
	PRIVACY_POLICY_EG = '/static/media/privacy-policy.1292875c6785fa25ee44.pdf',
	TERMS_SERVICE_EG = '/static/media/terms-of-use.2823399eff50d7abaa99.pdf',
	TERMS_SERVICE_HE = '/static/media/terms-of-use.2823399eff50d7abaa99.pdf',
	PRIVACY_POLICY_HE = '/static/media/privacy-policy.1292875c6785fa25ee44.pdf',
}

export enum DashboardPaths {
	MAIN = '/dashboard',
	TASKS = '/dashboard/tasks',
	FEEDBACKS = '/dashboard/feedbacks',
	DEBRIEFINGS = '/dashboard/debriefings',
	GOALS = '/dashboard/goals',
	PRAISES = '/dashboard/praises',
	CONTACTUS = '/dashboard/contact-us',
	SETTINGS = '/dashboard/settings',
	MANAGER_GOALS = '/dashboard/manager-goals',
	SUPER_ADMIN_COMPANIES = '/dashboard/companies',
	SUPER_ADMIN_BOT_SETTINGS = '/dashboard/bot-settings',
	SUPER_ADMIN_OUTPUT_SETTING = '/dashboard/output/settings',
	SUPER_COMPANY_TABLE = '/dashboard/companies/:id?',
	SUPER_ADMIN_COMPANIES_SUMMARIES = '/dashboard/companies/summaries',
	LESSONS_MANAGEMENT = '/dashboard/lessons-management',
}

export default Paths
