import { useContext } from 'react'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IRoute } from 'interface/routes/IRoute'
import { userLoggedInSelector, userSelector } from 'redux/user/user-selector'
import Paths from 'enums/routes/path-names'
import SuperAdminScreen from 'screens/SuperAdminScreen'

import { userActions } from 'redux/user/user-reducer'
import { useEffect } from 'react'
import { getItem } from './localStorage'
import { useHistory } from 'react-router'
import { ChangePassword } from '../components/common/LoginAndChangePassword/ChangePasswordNode/index'
import { getFullName } from '../components/HRsettings/DepartmentBuilder/builderHelper'
import { Langs } from '../enums/app/index'
import { AppDirectionContext } from '../context/languages-context'
import { inisializeI18n } from 'i18n/config'

const PrivateRoute = (route: IRoute) => {
	const isLoggedIn = useSelector(userLoggedInSelector)
	const user = useSelector(userSelector)
	const { toggleDirection } = useContext(AppDirectionContext)

	const history = useHistory()
	const dispatch = useDispatch()

	const { statusInfo, securityInfo } = user
	const { hasAcceptPolicyTerm = true, email } = securityInfo ?? {}
	const { companyLanguage = '', isSuperAdmin = false } = statusInfo ?? {}

	const storedUser = getItem('user')

	useEffect(() => {
		const shouldLogOut =
			(!storedUser && !isLoggedIn) ||
			(storedUser && !('_id' in storedUser) && !isLoggedIn)

		if (shouldLogOut) {
			dispatch(userActions.signOutUser())
			history.push(Paths.LOGIN)
		}
	}, [history, isLoggedIn, storedUser])

	inisializeI18n(companyLanguage ?? Langs.en) // handle app lang initialization
	toggleDirection(companyLanguage !== Langs.en) // handle toggling from rtl to ltr direction

	if (!hasAcceptPolicyTerm)
		return <ChangePassword userEmail={email} userName={getFullName(user)} />

	return isSuperAdmin ? <SuperAdminScreen /> : <Route {...route} />
}

export default PrivateRoute
