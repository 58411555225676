import { FC, useState } from 'react'
import ActionsButton from 'components/Bot/ActionButtons'
import { AnswersGroupProp } from '../../../interface/bot/index'
import { initialAnswer, UserAnswer } from '../ActionButtons/index'
import Box from '@mui/material/Box'
import { useStyles } from './useStyles'
import { SelectedOval } from './SelectedOval/index'

const RangeOptionChoice: FC<AnswersGroupProp> = ({
	setQuestion,
	nextQuestion,
	previousQuestion,
}) => {
	const [selectedAnswer, setSelectedAnswer] = useState<UserAnswer>({
		...initialAnswer,
		nextQuestion: nextQuestion!,
		previousQuestion: previousQuestion!,
	})
	const classes = useStyles()
	const handleClick = (answer: UserAnswer) => {
		setSelectedAnswer(prevAnswer => ({ ...prevAnswer, ...answer }))
	}
	const numberMapToString: Record<number, string> = {
		1: 'First time',
		2: 'Low',
		3: 'Medium',
		4: 'High',
	}
	return (
		<div>
			<Box sx={{ height: 300 }}>
				<div className={classes.line}>
					<div className={classes.container}>
						{Array.from({ length: 4 }).map((_, i) => {
							const ovalKey = i + 1
							const { id } = selectedAnswer
							const isSelected = id === ovalKey

							const answer: UserAnswer = {
								...selectedAnswer,
								id: ovalKey,
								answer: numberMapToString[ovalKey],
							}
							return (
								<div key={ovalKey} className={classes.ovalWrapper}>
									<div className={classes.oval} onClick={() => handleClick(answer)}>
										{isSelected && <SelectedOval />}
									</div>
									<div className={classes.ovalIndicator}>
										{numberMapToString[ovalKey]}
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</Box>
			<ActionsButton setQuestion={setQuestion} answer={selectedAnswer} />
		</div>
	)
}

export default RangeOptionChoice
