import { createSlice } from '@reduxjs/toolkit'
import {
	Debrief,
	DebriefEvent,
	Summary,
} from 'interface/debrief/debrief-interface'
import {
	create_debrief_event,
	create_matchpoint_lesson,
	create_matchpoint_summary,
	create_new_debrief,
	delete_debreif,
	delete_debrief_event,
	delete_matchpoint_lesson,
	get_user_debriefings,
	update_debrief,
	update_debrief_event,
	update_event_location,
	update_matchpoint_lesson,
	update_analysis_answer,
	share_debrief,
	get_one_debrife,
} from './debrief-actions'

import createMatchpointLesson from '../../utils/createMatchpointLesson'
import createMatchpointSummary from 'utils/createMatchpointSummary'
import createDebriefEvent from 'utils/createDebriefEvent'
import updateDebriefLesson from 'utils/updateMatchpointLesson'

export type IDebriefState = {
	loading: boolean
	error: string
	debriefOnProcess: Debrief | null
	userDebriefings: Debrief[]
	debriefEventOnEdit: string | null
	hasMoreDebriefs: boolean
}

const initialState: IDebriefState = {
	loading: false,
	error: 'string',
	debriefOnProcess: null,
	userDebriefings: [],
	debriefEventOnEdit: null,
	hasMoreDebriefs: true,
}

export const debriefSlice = createSlice({
	name: 'debrief',
	initialState,
	reducers: {
		setDebriefOnProcess(state, action) {
			return {
				...state,
				debriefOnProcess: action.payload,
			}
		},
		clearDebriefOnProcess(state) {
			return {
				...state,
				debriefOnProcess: null,
			}
		},
		updateBotAnalysis(state, action) {
			const test = action.payload
			console.log(state.debriefOnProcess)
		},
		setDebriefEventOnEdit(state, action) {
			return {
				...state,
				debriefEventOnEdit: action.payload,
			}
		},
		clearDebriefEventOnEdit(state) {
			return {
				...state,
				debriefEventOnEdit: null,
			}
		},
		clearError(state) {
			return {
				...state,
				error: '',
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(get_user_debriefings.fulfilled, (state, action) => {
			const debriefs = action.payload.data
			if (state.debriefOnProcess?._id) {
				state.debriefOnProcess = debriefs.find(
					(debrief: Debrief) => debrief._id === state.debriefOnProcess?._id
				)
			}
			const pageNumber = action.payload.pageNumber

			if (pageNumber) {
				state.userDebriefings = state.userDebriefings.concat(debriefs)
			} else {
				state.userDebriefings = debriefs
			}

			if (!debriefs.length) state.hasMoreDebriefs = false
			state.loading = false
		})
		builder.addCase(get_user_debriefings.pending, state => {
			state.loading = true
		})
		builder.addCase(get_user_debriefings.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		//update_debrief
		builder.addCase(update_debrief.fulfilled, (state, action) => {
			console.log(action.payload)
			//state.debriefOnProcess = { ...state.debriefOnProcess, ...action.payload }
			state.loading = false
		})
		builder.addCase(update_debrief.pending, state => {
			state.loading = true
		})
		builder.addCase(update_debrief.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})
		// share_debrief
		builder.addCase(share_debrief.fulfilled, (state, action) => {
			if (!state.debriefOnProcess) return

			state.debriefOnProcess._id = action.payload._id
			state.debriefOnProcess.sharedEmployess =
				action.payload.updatedSharedParticipants

			state.loading = false
		})
		builder.addCase(share_debrief.pending, state => {
			state.loading = true
		})
		builder.addCase(share_debrief.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})
		//create_new_debrief
		builder.addCase(create_new_debrief.fulfilled, (state, action) => {
			state.debriefOnProcess = action.payload
			state.userDebriefings = [...state.userDebriefings, action.payload]
			state.loading = false
		})
		builder.addCase(create_new_debrief.pending, state => {
			state.loading = true
		})
		builder.addCase(create_new_debrief.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		//delete_debreif
		builder.addCase(delete_debreif.fulfilled, (state, action) => {
			const updatedDebreifing = [...state.userDebriefings].filter(
				deb => deb._id !== action.payload._id
			)
			state.userDebriefings = updatedDebreifing
			state.loading = false
		})
		builder.addCase(delete_debreif.pending, state => {
			state.loading = true
		})
		builder.addCase(delete_debreif.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		//create_debrief_event
		builder.addCase(create_debrief_event.fulfilled, (state, action) => {
			state.loading = false

			if (!state.debriefOnProcess) return

			const { debriefOnProcess: debrief } = state
			const event = { event: action.payload }

			state.debriefOnProcess = createDebriefEvent(debrief, event)
		})

		builder.addCase(create_debrief_event.pending, state => {
			state.loading = true
		})

		builder.addCase(create_debrief_event.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		//update_debrief_event
		builder.addCase(update_debrief_event.fulfilled, (state, action) => {
			if (state.debriefOnProcess) {
				const updatedEvent = action.payload
				const updatedEvents = state.debriefOnProcess.events.map(
					(event: DebriefEvent) => {
						if (event._id === updatedEvent._id) {
							return updatedEvent
						}
						return event
					}
				)
				state.debriefOnProcess.events = updatedEvents
			}
			state.loading = false
		})

		builder.addCase(update_debrief_event.pending, state => {
			state.loading = true
		})

		builder.addCase(update_debrief_event.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		//delete_debrief_event
		builder.addCase(delete_debrief_event.fulfilled, (state, action) => {
			if (state.debriefOnProcess) {
				const deleteEventId = action.payload
				const updatedEvents = [
					...state.debriefOnProcess.events.filter(
						(e: DebriefEvent) => e._id !== deleteEventId
					),
				]
				state.debriefOnProcess.events = updatedEvents
			}
			state.loading = false
		})
		builder.addCase(delete_debrief_event.pending, state => {
			state.loading = true
		})
		builder.addCase(delete_debrief_event.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		//update_event_location
		builder.addCase(update_event_location.fulfilled, (state, action) => {
			if (state.debriefOnProcess) {
				const reorderdIds: string[] = action.payload
				let obj: Record<string, DebriefEvent> = {}
				state.debriefOnProcess.events.forEach((event: any) => {
					obj = { ...obj, [event._id]: event }
				})
				const relocatedEvents = reorderdIds.map((eventId: string) => ({
					...obj[eventId],
				}))
				state.debriefOnProcess.events = relocatedEvents
			}
			state.loading = false
		})
		builder.addCase(update_event_location.pending, state => {
			state.loading = false
		})
		builder.addCase(update_event_location.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})
		//create_matchpoint_summary
		builder.addCase(create_matchpoint_summary.fulfilled, (state, action) => {
			state.loading = false

			if (!state.debriefOnProcess) return

			const payload = action.payload
			const { debriefOnProcess: debrief } = state
			const updatedDebrief = createMatchpointSummary(debrief, payload)

			state.debriefOnProcess = updatedDebrief
		})
		builder.addCase(create_matchpoint_summary.pending, state => {
			state.loading = false
		})
		builder.addCase(create_matchpoint_summary.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})
		//update_matchpoint_lesson
		builder.addCase(update_matchpoint_lesson.fulfilled, (state, action) => {
			state.loading = false

			if (!state.debriefOnProcess) return

			const { debriefOnProcess } = state
			const { events } = debriefOnProcess as Debrief
			const { payload } = action
			const updatedEvents = updateDebriefLesson(events, payload)

			state.debriefOnProcess.events = updatedEvents
		})
		builder.addCase(update_matchpoint_lesson.pending, state => {
			state.loading = true
		})
		builder.addCase(update_matchpoint_lesson.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		// create_matchpoint_lesson
		builder.addCase(create_matchpoint_lesson.fulfilled, (state, action) => {
			if (state.debriefOnProcess) {
				const { debriefOnProcess } = state

				const {
					payload: { data, ...rest },
				} = action

				const updatedEvents = createMatchpointLesson(debriefOnProcess, data, {
					...rest,
				})
				console.log({ updatedEvents })
				state.debriefOnProcess.events = updatedEvents
			}
			state.loading = false
		})
		builder.addCase(create_matchpoint_lesson.pending, state => {
			state.loading = false
		})
		builder.addCase(create_matchpoint_lesson.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		//delete_matchpoint_lesson
		builder.addCase(delete_matchpoint_lesson.fulfilled, (state, action) => {
			if (state.debriefOnProcess) {
				const updatedEvents = state.debriefOnProcess.events.map(
					(event: DebriefEvent) => {
						if (event._id === action.payload.event_id) {
							const summaries = event.summaries?.map((summary: Summary) => {
								if (summary.event_id === action.payload.summary_id) {
									const updatedLessons = summary.lessons.filter(
										lesson => lesson._id !== action.payload.data
									)
									return { ...summary, lessons: updatedLessons }
								}
								return summary
							})
							return { ...event, summaries }
						}
						return event
					}
				)
				state.debriefOnProcess.events = [...updatedEvents]
			}
			state.loading = false
		})
		builder.addCase(delete_matchpoint_lesson.pending, state => {
			state.loading = true
		})
		builder.addCase(delete_matchpoint_lesson.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		//update bot-analysis answers from summary
		builder.addCase(update_analysis_answer.fulfilled, (state, action) => {
			const { event_id, actionAnswer, assumption } = action.payload.data
			const fieldToUpdate = () => {
				if (assumption) return { assumption: assumption }
				else return { actionAnswer: actionAnswer }
			}
			const value = fieldToUpdate()
			console.log(value)
			if (state.debriefOnProcess) {
				const updatedEvents = state.debriefOnProcess.events.map(
					(event: DebriefEvent) => {
						if (event._id === event_id) {
							const summaries = event.summaries?.map((summary: Summary) => {
								if (summary.event_id === event_id) {
									if (assumption) summary.assumption = assumption
									else summary.actionAnswer = actionAnswer

									return summary
								}
								return summary
							})
							return { ...event, summaries }
						}
						return event
					}
				)
				state.debriefOnProcess.events = [...updatedEvents]
			}
		})
		builder.addCase(update_analysis_answer.pending, (state, action) => {
			state.loading = false
		})
		builder.addCase(update_analysis_answer.rejected, (state, action) => {
			state.loading = false
		})

		builder.addCase(get_one_debrife.fulfilled, (state, action) => {
			state.loading = false
			const debrief = action.payload
			state.debriefOnProcess = debrief
		})
		builder.addCase(get_one_debrife.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(get_one_debrife.rejected, (state, action) => {
			state.loading = false
		})
	},
})
export const debriefActions = debriefSlice.actions
export default debriefSlice.reducer
