import { FC } from 'react'
import { backgroundIconsMap } from 'utils/iconsMapper'
import { useStyles } from 'screens/Bot/useStyles'

export const BackgroundImage: FC<{ opacity?: string; image: string }> = ({
	image,
	opacity = '',
}) => {
	const classes = useStyles({})
	image =
		backgroundIconsMap[
			image ? image : backgroundIconsMap.inWhichContextBackgroundIcon
		]
	return (
		<img
			style={{ opacity: opacity ? opacity : '6%' }}
			className={classes.backgroundImageStyle}
			src={image}
		/>
	)
}
