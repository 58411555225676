import { UI } from "enums/lessonsManagement"
import { BaseFolderChild, Folder, ILessonsBase, File } from "interface/lessons-management/LessonsBase.interface"
import { useState } from "react"
import { isFolderType } from "utils/LessonsManagement/isFolder"
import useCacheFolders from "../root/useCacheFolders"

type Props = {
    currentFolder?: ILessonsBase<Folder | File> | null
}
const useSelectedFolder = ({ currentFolder }: Props) => {
    const [selectedFolder, setSelectedFolder] = useState<Partial<BaseFolderChild>>()

    const { getFolderFromCache } = useCacheFolders({ currentFolder })

    const getSelectedFolder = (_id?: string | null): BaseFolderChild | undefined => {
        const cachedFolder = getFolderFromCache(_id!)
        if (cachedFolder) return getItemByType(cachedFolder)

        else return getNestedFolder(_id)
    }
    const getItemByType = (cachedFolder?: ILessonsBase<Folder | File>): BaseFolderChild => {
        if (isFolderType(cachedFolder?.type!)) {
            const folder = { ...cachedFolder, ui: UI.FOLDER } as BaseFolderChild
            setSelectedFolder(folder)

            return folder
        }
        const file = { ...cachedFolder, ui: UI.FILE } as BaseFolderChild
        setSelectedFolder(file)
        return file
    }
    const getNestedFolder = (_id?: string | null): BaseFolderChild | undefined => {
        if (isFolderType(currentFolder?.type!)) {
            const folder = currentFolder?.type.children.find(f => f._id === _id)
            setSelectedFolder(folder)
            return folder
        }
    }

    return { selectedFolder, setSelectedFolder, getSelectedFolder }
}

export default useSelectedFolder