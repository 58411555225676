import { GoalStatus } from 'enums/user/user-goal-status'
import { useState } from 'react'

enum StateOption {
	description = 'description',
	date = 'date',
	validationError = 'validationError',
	status = 'status',
	datepickerIsOpen = 'datepickerIsOpen',
}

type GoalState = {
	[StateOption.description]: string
	[StateOption.date]?: Date
	[StateOption.validationError]: string
	[StateOption.status]: GoalStatus
	[StateOption.datepickerIsOpen]: boolean
}

export const useGoalStateLogic = () => {
	const [state, seGoalState] = useState<GoalState>(() => ({} as GoalState))

	const setValidationState = (error = '') => {
		seGoalState(prevState => ({
			...prevState,
			[StateOption.validationError]: error,
		}))
	}
	const setGoalDescription = (value: string) => {
		seGoalState(prevState => ({
			...prevState,
			[StateOption.description]: value,
		}))
	}
	const setGoalDate = (date?: Date) => {
		seGoalState(prevState => ({
			...prevState,
			[StateOption.date]: date,
		}))
	}
	const setGoalStatus = (status: GoalStatus) => {
		seGoalState(prevState => ({
			...prevState,
			[StateOption.status]: status,
		}))
	}

	const setOpenDatepicker = (isOpen: boolean) => {
		seGoalState(prevState => ({
			...prevState,
			[StateOption.datepickerIsOpen]: isOpen,
		}))
	}

	return {
		state,
		setValidationState,
		setGoalDescription,
		setGoalDate,
		setGoalStatus,
		setOpenDatepicker,
	}
}
