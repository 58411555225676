import { FC, useState, useCallback, useEffect, useContext } from 'react'
import ActionsButton from '../ActionButtons'
import { initialAnswer, UserAnswer } from '../ActionButtons/index'
import { AnswersGroupProp } from '../../../interface/bot/index'
import { useStyles } from './useStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { OutputType } from '../../../screens/BotOutput/index'
import Paths from 'enums/routes/path-names'
import { get_question_praise } from 'redux/praise/praise-actions'
import { get_question_feedback } from 'redux/feedback/feedback-actions'
import { get_company_values } from 'redux/company/company-actions'
import { userSelector } from '../../../redux/user/user-selector'
import { IStoreRootState } from '../../../interface/redux/index'
import { QuestionValue } from '../../../interface/praise/praise-interface'
import { DEFAULT_VALUES } from './constants'
import { useTranslation } from 'react-i18next'
import { AppDirectionContext } from 'context/languages-context'
import ScrollableContent from 'components/common/ScrollableContent/ScrollableContent'

const CompanyValuesChoice: FC<AnswersGroupProp> = props => {
	const {
		answers,
		previousQuestion,
		setQuestion,
		nextQuestion,
		subtitle,
		type,
		isPraise,
	} = props

	const currentUser = useSelector(userSelector)
	const companyValuesString = useSelector(
		(state: IStoreRootState) => state.company.currentCompanyValues
	)

	const [chosenIds, setChosenIds] = useState<Array<number>>([])
	const [answer, setAnswer] = useState<UserAnswer>({
		...initialAnswer,
		previousQuestion: previousQuestion!,
		nextQuestion: nextQuestion!,
		type,
	})

	const history = useHistory()
	const location: any = useLocation()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		dispatch(get_company_values(currentUser.company_id!))
	}, [])

	const mappedComapnyValues = companyValuesMapAnswer(
		companyValuesString.length
			? companyValuesString
			: DEFAULT_VALUES.map(value => t(value))
	)

	const setUserAnswer = (newIds: number[]) => {
		setChosenIds(newIds)
		setAnswer(previousAnswer => ({
			...previousAnswer,
			nextQuestion: nextQuestion!,
			values: newIds.map(
				(answerId: number) => mappedComapnyValues[answerId].title
			),
		}))
	}

	const addAnswer = (id: number) => setUserAnswer([id])

	const removeAnswer = useCallback(
		(id: number) =>
			setUserAnswer(chosenIds.filter(existedId => existedId !== id)),
		[chosenIds]
	)

	const handleClick = useCallback(
		(id: number) => {
			const isChosed = chosenIds.includes(id)

			if (isChosed) {
				removeAnswer(id)
				return
			}

			addAnswer(id)
		},
		[chosenIds]
	)

	const handleNoImpactClick = () => {
		const questionPayload = {
			questionId: 'first',
			language: currentUser.statusInfo.companyLanguage,
		}

		if (isPraise) {
			dispatch(get_question_praise(questionPayload))
		} else {
			dispatch(get_question_feedback(questionPayload))
		}

		history.push({
			pathname: Paths.OUTPUT,
			state: {
				screenToReturn: location.state.screenToReturn,
				outputScreenType: isPraise
					? OutputType.PraiseSender
					: OutputType.FeedbackSender,
				isPraise: isPraise!,
			},
		})
	}
	const answersFlatted = [...mappedComapnyValues, ...answers]
	const content = !!answersFlatted.length && (
		<div className={classes.content}>
			<div className={classes.singleChoiceWrapper} id='companyValueAnswerCard'>
				{answersFlatted.map(({ title }, id) => {
					const isChosed = chosenIds.includes(id)

					const noCompanyValue = answersFlatted.length - 1 === id

					const style = [
						classes.answerCard,
						isChosed ? classes.isChosed : classes.regular,
						noCompanyValue && classes.answerCardAlt,
					]
						.filter(Boolean)
						.join(' ')

					const handleValueClick = () =>
						noCompanyValue ? handleNoImpactClick() : handleClick(id)

					return (
						<div className={style} onClick={handleValueClick}>
							{title}
						</div>
					)
				})}
			</div>
		</div>
	)

	return (
		<>
			<div>{subtitle}</div>
			{content && <ScrollableContent content={content} />}
			<ActionsButton
				showNextStepButton
				showPreviousButton
				previousQuestionId={previousQuestion}
				isPraise={isPraise}
				isEnabled={!!answer.values?.length}
				removeIds={() => setChosenIds([])}
				answer={answer}
				setQuestion={setQuestion}
			/>
		</>
	)
}

export default CompanyValuesChoice

const companyValuesMapAnswer = (
	valuesFromServer: Array<string>
): Array<QuestionValue> => {
	const values = valuesFromServer.map((value, i) => ({
		title: value,
		subtitle: '',
		icon: '',
		nextQuestion: '',
	}))

	return values
}
