import { Text } from '@react-pdf/renderer'
import { Lesson, Summary } from 'interface/debrief/debrief-interface'
import { useTranslation } from 'react-i18next'
import colors from 'utils/colors'
import PdfLesson from './FileLesson'

type Props = {
    num: number
    description: string
    summaries?: Summary[]
}
const PdfMatchpoint = ({ num, description, summaries }: Props) => {
    const { t } = useTranslation()
    const matchPointColor = {
        [1]: colors.saydoGreen,
        [2]: colors.boldPurple,
        [3]: colors.boldBlue,
    }[num] as string
    return (
        <>
            <Text style={{ color: matchPointColor, fontSize: '12px', margin: '2%' }}>
                {t('mp')}{num}:{' '}
                <Text style={{ color: 'black' }}>{description}</Text>
            </Text>

            {summaries?.map((summary: Summary) => (
                summary.lessons.map((lesson: Lesson) => (
                    <PdfLesson matchPointColor={matchPointColor} description={lesson.description} />
                ))
            ))}

        </>
    )
}

export default PdfMatchpoint