import { DebriefEvent } from '../interface/debrief/debrief-interface'
import { DebriefingSteps } from '../enums/debriefing/debriefing-steps.enum'
import { DEBRIEFING_SELF_ANALISYS_CHAT } from '../components/Debriefing/DebriefTree/stepMapComponent'

export const shouldUserAccessSelfAnalisysChat = (
	matchPoints: Array<DebriefEvent>,
	step: DebriefingSteps
) => {
	// first check if the current debrief is at DEBRIEFING_SELF_ANALISYS_CHAT step.
	let hasAccess = false
	if (step === DEBRIEFING_SELF_ANALISYS_CHAT) return (hasAccess = true)

	// if there is any summary in summaries array.
	matchPoints.forEach(e => {
		e?.summaries?.forEach(s => {
			if (s?.event_id) {
				hasAccess = true
			}
		})
	})

	return hasAccess
}
