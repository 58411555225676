import { FC, useContext } from 'react'
import { Grid } from '@material-ui/core'

import { AppDirectionContext } from 'context/languages-context'

import AppSkeleton from 'utils/skeleton/AppSkeleton'
import { TasksContainer } from 'utils/Task/index'
import useStyles from './style'
import { useMobileView } from './BlockagesStatus'

type Props = {
	loading?: boolean
	containerDescription?: string
	greenChip?: boolean
	handleClick?: () => void
}
export const TasksContainerWrapper: FC<Props> = props => {
	const {
		loading = false,
		containerDescription = '',
		greenChip,
		children,
		handleClick
	} = props

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const { isMobile } = useMobileView()
	const values = isMobile ? { hideChip: true } : { height: 85 }
	return (
		<Grid item sm={6}>
			{loading ? (
				<AppSkeleton width='95%' height={85} times={1} />
			) : (
				<TasksContainer
					handleClick={handleClick}
					width='98%'
					containerDescription={containerDescription}
					tasksSection={greenChip}
					{...values}
				>
					<div className={classes.displayStatus}>{children}</div>
				</TasksContainer>
			)}
		</Grid>
	)
}
