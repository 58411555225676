import {
	FC,
	useState,
	useContext,
	useLayoutEffect,
	useRef,
	useMemo,
} from 'react'
import {
	Avatar,
	Accordion,
	Typography,
	AccordionSummary,
	AccordionDetails,
} from '@material-ui/core'
import Skeleton from '@mui/material/Skeleton'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import useStyles from './styles'

import { AppDirectionContext } from 'context/languages-context'

import { feedbackLoadingSelector } from 'redux/feedback/feedback-selector'
import { praisesLoadingSelector } from 'redux/praise/praise-selector'

import SaydoButtonContent from 'components/common/SaydoButtonContent/SaydoButtonContent'
import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'
import { getUserInitials } from 'utils'

import { updatePraiseOutputState } from 'api/praise'

import { UpdatePraisePatch } from 'interface/api/get-payload.types'
import { PraiseState } from 'enums/praise-state/praise-state.enum'
import viewedIcon from 'assets/viewedIcon.svg'
import { useSelector } from 'react-redux'
import {
	getPraiseStateReceiverSwitch,
	getPraiseStateSenderSwitch,
} from 'utils/Tabs/TabsRow'

interface Props {
	handleClick?: () => any
	id: string
	imageUrl: string
	name: string
	date: string
	description: string
	coins?: number
	eventSummary?: string
	userRequest?: string
	modalButtonText: string
	buttonClass?: string
	buttonIcon?: string
	type: string
	lastPanelDisplay?: string
	isSendTab?: boolean
	readed?: boolean
	replay?: string
	praiseState?: PraiseState
}

const Row: FC<Props> = ({
	id,
	imageUrl,
	name,
	date,
	description,
	readed,
	handleClick: handleDisplayOutputClick,
	buttonClass,
	eventSummary,
	userRequest,
	modalButtonText,
	buttonIcon,
	type,
	replay,
	lastPanelDisplay,
	isSendTab,
	praiseState,
}) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const { t } = useTranslation()

	const feedbackLoading = useSelector(feedbackLoadingSelector)
	const praiseLoading = useSelector(praisesLoadingSelector)

	const isLoading = useMemo(
		() => feedbackLoading || praiseLoading,
		[feedbackLoading, praiseLoading]
	)

	const rowRef = useRef<HTMLDivElement>(null)

	const isPraise = useMemo(() => type === 'praises', [type])

	const classes = useStyles({
		isPraiseButton: isPraise,
		expanded: false,
		// cursor: type === 'feedbacks' ? 'pointer' : 'initial',
		isRtlDirection,
		praiseState,
	})

	useLayoutEffect(() => {
		const EXTRA_MARGIN_PADDING = 600
		if (lastPanelDisplay === id) {
			if (rowRef.current) {
				const rowRefOffsetHeight = rowRef.current.offsetTop

				rowRef.current.parentElement?.scrollTo({
					top: rowRefOffsetHeight - EXTRA_MARGIN_PADDING,
					behavior: 'smooth',
				})
			}
		}
	}, [lastPanelDisplay])

	const getNameParts = () => {
		const fullNameArr = name.split(' ')
		const firstName = fullNameArr[0]
		const lastName = fullNameArr[1]
		return { initials: getUserInitials(firstName, lastName), firstName }
	}

	const handlePraiseStateSwitch = () => {
		if (!isSendTab) return getPraiseStateReceiverSwitch(modalButtonText)!

		return getPraiseStateSenderSwitch(modalButtonText)!
	}

	const updatePraiseState = () => {
		const praise = {
			_id: id,
			praiseState: handlePraiseStateSwitch(),
		} as UpdatePraisePatch

		updatePraiseOutputState(praise)
	}

	const handleButtonClick = () => {
		handleDisplayOutputClick?.()
	}

	const windowWidth = useWindowWidth()
	const mobileWidth = 600
	const isMobile = mobileWidth >= windowWidth

	const modalButton = (
		<div className={classes.topRightContainer}>
			<button
				className={`${classes.modalButton} ${buttonClass ? buttonClass : ''}`}
				onClick={handleButtonClick}
			>
				{buttonIcon === 'blockage_closed' && <SaydoButtonContent />}
				{modalButtonText}{' '}
				{buttonIcon === 'viewed' && (
					<img className={classes.viewedIcon} src={viewedIcon} />
				)}
			</button>
			{!isMobile && readed && <p className={classes.indication}>1:1</p>}
		</div>
	)

	const avatarSkeleton = (
		<Skeleton animation='wave' variant='circular' width={40} height={40} />
	)

	return (
		<Accordion
			ref={rowRef}
			className={classes.accordion}
			square
			onClick={handleButtonClick}
		>
			<AccordionSummary
				aria-controls={`${id}-content`}
				id={`${id}-header`}
				className={
					lastPanelDisplay == id
						? [classes.accordionSummary, classes.flash].join(' ')
						: classes.accordionSummary
				}
			>
				<div className={classes.details} data-testid='row-details'>
					<div className={classes.details}>
						<div className={classes.userDetails}>
							{!isLoading ? (
								<Avatar className={classes.avatar} src={imageUrl}>
									<Typography className={classes.avatarInitials}>
										{getNameParts().initials}
									</Typography>
								</Avatar>
							) : (
								avatarSkeleton
							)}

							<div className={classes.detailsTextContainer}>
								<Typography className={classes.name}>{name}</Typography>
								<div>
									<>
										<span className={classes.dateOn}>{t('on')}</span>{' '}
										<span className={classes.date}>
											{dayjs(date).format('DD/MM/YYYY')}
										</span>
									</>
								</div>
							</div>
						</div>

						{isMobile && readed && <p className={classes.indication}>1:1</p>}
					</div>
					{!isMobile && modalButton}
				</div>

				<Typography className={classes.description}>"{description}"</Typography>

				{isMobile && modalButton}

				{replay?.trim() && (
					<p className={classes.replay}>
						<span>{t('comment')}</span> {replay}
					</p>
				)}
			</AccordionSummary>
			<AccordionDetails className={classes.accordionDetails}>
				{eventSummary && (
					<div className={classes.eventSummary}>
						<Typography className={classes.accordionDetailsTitle}>
							{t('event_summary')}
						</Typography>
						<Typography className={classes.accordionDetailsDescription}>
							{eventSummary}
						</Typography>
					</div>
				)}
				{userRequest && (
					<div>
						<Typography className={classes.accordionDetailsTitle}>
							{t('user_needs', {
								name: getNameParts().firstName,
								interpolation: { escapeValue: false },
							})}
						</Typography>
						<Typography className={classes.accordionDetailsDescription}>
							{userRequest}
						</Typography>
					</div>
				)}
			</AccordionDetails>
		</Accordion>
	)
}

export default Row
