import { makeStyles } from "@material-ui/core"
import { Theme } from "@mui/material"

type Styles = {
    isRtlDirection: boolean
    matchPointColor?: string
    isLeader?: boolean
}
export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
    avatarGroup: {
        direction: 'ltr',
        '& >:first-child': {
            marginLeft: '-8px !important',
            width: '30px',
            height: '30px',
            [breakpoints.down('xs')]: {
                marginLeft: '7% !important',
            },
        }
    },
    avatar: {
        width: '30px',
        height: '30px',
    },
    ownerImage: {
        padding: '0 8px 0 8px',
        borderRight: '1px solid white',
        width: ({ isRtlDirection }) => (isRtlDirection ? '64px' : '32px'),
        [breakpoints.down('xs')]: {
            padding: 'none',
        },
    },
    date: {
        paddingLeft: '8px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        cursor: ({ isLeader }) => (isLeader ? 'pointer' : 'default'),
        [breakpoints.down('xs')]: {
            whiteSpace: 'normal',
        },
    },
}))