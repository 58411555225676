import { createSlice } from '@reduxjs/toolkit'
import { Question, QuestionValue } from 'interface/praise/praise-interface'
import {
	get_praises,
	fetch_praises_drafts,
	fetch_company_praises,
	update_praise_reactions,
	fetch_company_praises_templates,
	load_more_praises,
	update_praise_expansions,
	update_replay_message,
	fetch_sent_company_praises_byIds,
	fetch_received_company_praises_byIds,
} from './praise-actions'
import {
	BaseContext,
	PraiseOutput,
	WallOfPraiseOutput,
} from 'interface/bot/index'
import IUser from '../../interface/user/user-interface'
import { WallOfPraiseData } from '../../interface/bot/index'
import {
	create_praise,
	get_additional_praise_question,
	get_question_praise,
	review_praise_work_meet,
} from './praise-actions'
import { filterUserOutputs } from './filterUserOutputs'
import { PraiseAndFeedbacksType } from 'enums/praise-state/praise-state.enum'

export type PraiseState = {
	currentRequestId: undefined | string
	loading: boolean
	additionalPraiseQuestionLoading?: boolean
	error: string
	companyPraises: PraiseOutput<IUser>[]
	drafts: PraiseOutput<IUser>[]
	sentPraises: PraiseOutput<IUser>[]
	hasMoreSentPraises: boolean
	receivedPraises: PraiseOutput<IUser>[]
	searchResult: PraiseOutput<IUser>[]
	hasMoreReceivedPraises: boolean
	hasMoreDraftsPraises: boolean
	hasMoreOnWallPraises: boolean
	currentpraiseQuestion: Question<QuestionValue[], string> | null
	additionalPraiseQuestion: Question<QuestionValue[], string> | null
	templates: WallOfPraiseData<BaseContext, WallOfPraiseOutput> | null
}

const initialState: PraiseState = {
	drafts: [],
	sentPraises: [],
	companyPraises: [],
	receivedPraises: [],
	searchResult: [],
	error: '',
	loading: false,
	templates: null,
	currentRequestId: '',
	currentpraiseQuestion: null,
	additionalPraiseQuestion: null,
	additionalPraiseQuestionLoading: false,
	hasMoreSentPraises: true,
	hasMoreReceivedPraises: true,
	hasMoreDraftsPraises: true,
	hasMoreOnWallPraises: true,
}

export const praiseSlice = createSlice({
	name: 'praise',
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(
			fetch_company_praises_templates.fulfilled,
			(state, action) => {
				const templates = action.payload
				state.templates = { ...templates }
				state.loading = false
			}
		)
		builder.addCase(fetch_company_praises_templates.pending, (state, action) => {
			if (!state.loading) {
				state.loading = false
				state.currentRequestId = action.meta.requestId!
			}
		})
		builder.addCase(fetch_company_praises_templates.rejected, (state, action) => {
			if (action.meta.requestId === state.currentRequestId) {
				state.loading = false
				state.currentRequestId = ''
				state.error = action.error.message!
			}
		})

		builder.addCase(fetch_company_praises.fulfilled, (state, action) => {
			const praises = action.payload.data
			if (!praises.length) state.hasMoreOnWallPraises = false
			state.companyPraises = filterUserOutputs(
				state.companyPraises.concat(praises)
			)
			state.loading = false
		})
		builder.addCase(fetch_company_praises.pending, (state, action) => {
			if (!state.loading) {
				state.loading = false
				state.currentRequestId = action.meta.requestId!
			}
		})
		builder.addCase(fetch_company_praises.rejected, (state, action) => {
			if (action.meta.requestId === state.currentRequestId) {
				state.loading = false
				state.currentRequestId = ''
				state.error = action.error.message!
			}
		})

		builder.addCase(
			fetch_received_company_praises_byIds.fulfilled,
			(state, action) => {
				const praises = action.payload.data as any

				if (!praises.length) state.hasMoreOnWallPraises = false
				state.searchResult = praises
				state.loading = false
			}
		)

		builder.addCase(
			fetch_received_company_praises_byIds.pending,
			(state, action) => {
				if (!state.loading) {
					state.loading = false
					state.currentRequestId = action.meta.requestId!
				}
			}
		)
		builder.addCase(
			fetch_received_company_praises_byIds.rejected,
			(state, action) => {
				if (action.meta.requestId === state.currentRequestId) {
					state.loading = false
					state.currentRequestId = ''
					state.error = action.error.message!
				}
			}
		)
		builder.addCase(
			fetch_sent_company_praises_byIds.fulfilled,
			(state, action) => {
				const praises = action.payload.data as any

				if (!praises.length) state.hasMoreOnWallPraises = false
				state.searchResult = praises
				state.loading = false
			}
		)

		builder.addCase(fetch_sent_company_praises_byIds.pending, (state, action) => {
			if (!state.loading) {
				state.loading = false
				state.currentRequestId = action.meta.requestId!
			}
		})
		builder.addCase(
			fetch_sent_company_praises_byIds.rejected,
			(state, action) => {
				if (action.meta.requestId === state.currentRequestId) {
					state.loading = false
					state.currentRequestId = ''
					state.error = action.error.message!
				}
			}
		)

		builder.addCase(fetch_praises_drafts.fulfilled, (state, action) => {
			console.log({ fetch_praises_drafts: action.payload })
			// state.drafts = action.payload
			state.loading = false
		})
		builder.addCase(fetch_praises_drafts.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
				state.currentRequestId = action.meta.requestId!
			}
		})
		builder.addCase(fetch_praises_drafts.rejected, (state, action) => {
			if (action.meta.requestId === state.currentRequestId) {
				state.loading = false
				state.currentRequestId = ''
				state.error = action.error.message!
			}
		})

		builder.addCase(get_praises.fulfilled, (state, action) => {
			const { sentMapped, receivedMapped, drafts } = action.payload
			if (!drafts.length) {
				state.drafts = []
				state.hasMoreDraftsPraises = false
			}
			if (!receivedMapped.length) {
				state.receivedPraises = []
				state.hasMoreReceivedPraises = false
			}
			if (!sentMapped.length) {
				state.sentPraises = []
				state.hasMoreSentPraises = false
			}
			state.receivedPraises = filterUserOutputs(
				state.receivedPraises.concat(receivedMapped as PraiseOutput<IUser>[])
			)
			state.sentPraises = filterUserOutputs(
				state.sentPraises.concat(sentMapped as PraiseOutput<IUser>[])
			)
			const reducerDrafts = filterUserOutputs(
				state.drafts.concat(drafts as PraiseOutput<IUser>[])
			)
			console.log({ draftsInReducer: reducerDrafts })

			state.drafts = reducerDrafts
			state.loading = false
		})
		builder.addCase(get_praises.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
				state.currentRequestId = action.meta.requestId!
			}
		})
		builder.addCase(get_praises.rejected, (state, action) => {
			if (action.meta.requestId === state.currentRequestId) {
				state.loading = false
				state.currentRequestId = ''
				state.error = action.error.message!
			}
		})

		builder.addCase(load_more_praises.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
				state.currentRequestId = action.meta.requestId!
			}
		})
		builder.addCase(load_more_praises.fulfilled, (state, action) => {
			state.loading = false
			const {
				payload,
				praises: { sentMapped, receivedMapped, drafts },
			} = action.payload

			if (payload.type === PraiseAndFeedbacksType.SENT) {
				if (!sentMapped.length) state.hasMoreSentPraises = false
				else
					state.sentPraises = filterUserOutputs(
						state.sentPraises.concat(sentMapped as PraiseOutput<IUser>[])
					)
			}
			if (payload.type === PraiseAndFeedbacksType.RECEIVED) {
				if (!receivedMapped.length) state.hasMoreReceivedPraises = false
				else
					state.receivedPraises = filterUserOutputs(
						state.receivedPraises.concat(receivedMapped as PraiseOutput<IUser>[])
					)
			}
			if (payload.type === PraiseAndFeedbacksType.DRAFT) {
				if (!drafts.length) state.hasMoreDraftsPraises = false
				else {
					console.log({ draftsReducer: drafts })
					state.drafts = filterUserOutputs(
						state.drafts.concat(drafts as PraiseOutput<IUser>[])
					)
				}
			}
		})
		builder.addCase(load_more_praises.rejected, (state, action) => {
			if (action.meta.requestId === state.currentRequestId) {
				state.loading = false
				state.currentRequestId = ''
				state.error = action.error.message!
			}
		})

		builder.addCase(create_praise.fulfilled, (state, action) => {
			state.loading = false
		})
		builder.addCase(create_praise.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
				state.currentRequestId = action.meta.requestId!
			}
		})

		builder.addCase(create_praise.rejected, (state, action) => {
			if (action.meta.requestId === state.currentRequestId) {
				state.loading = false
				state.currentRequestId = ''
				state.error = action.error.message!
			}
		})

		builder.addCase(get_question_praise.fulfilled, (state, action) => {
			const question = action.payload

			state.currentpraiseQuestion = question
			state.loading = false
		})
		builder.addCase(get_question_praise.pending, (state, action) => {
			state.loading = true
		})

		builder.addCase(get_question_praise.rejected, (state, action) => {
			state.loading = false
			state.currentRequestId = ''
			state.error = action.error.message!
		})

		builder.addCase(get_additional_praise_question.fulfilled, (state, action) => {
			const question = action.payload
			state.additionalPraiseQuestion = question
			state.additionalPraiseQuestionLoading = false
		})
		builder.addCase(get_additional_praise_question.pending, (state, action) => {
			state.additionalPraiseQuestionLoading = true
		})
		builder.addCase(get_additional_praise_question.rejected, (state, action) => {
			state.additionalPraiseQuestionLoading = false
		})

		builder.addCase(update_praise_reactions.fulfilled, (state, action) => {
			state.companyPraises = state.companyPraises.map(p => {
				if (p._id === action.payload.praise_id) {
					p.reactions = action.payload.reactions
				}
				return p
			})
		})
		builder.addCase(update_praise_reactions.pending, (state, action) => {
			//state.loading = true
		})
		builder.addCase(update_praise_reactions.rejected, (state, action) => {
			//state.loading = false
		})

		builder.addCase(review_praise_work_meet.fulfilled, (state, action) => {
			state.loading = false
			const outputIdToUpdate = action.payload?.output_id
			console.log({ here: 'in reducer!', outputIdToUpdate })
			const currentStatus = action.payload?.data
			console.log({ currentStatus })
			const updatedSentPraises = state.sentPraises.map(
				(praise: PraiseOutput<IUser>) => {
					if (praise._id === outputIdToUpdate) {
						return { ...praise, readed: currentStatus }
					}
					return praise
				}
			) as PraiseOutput<IUser>[]
			const updateReceivedPraises = state.receivedPraises.map(
				(praise: PraiseOutput<IUser>) => {
					if (praise._id === outputIdToUpdate) {
						return { ...praise, readed: currentStatus }
					}
					return praise
				}
			) as PraiseOutput<IUser>[]
			state.receivedPraises = updateReceivedPraises
			state.sentPraises = updatedSentPraises
		})
		builder.addCase(review_praise_work_meet.pending, state => {
			state.loading = true
		})
		builder.addCase(review_praise_work_meet.rejected, (state, action) => {
			state.error = `${action.payload}`
			state.loading = false
		})

		builder.addCase(update_praise_expansions.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(update_praise_expansions.fulfilled, (state, action) => {
			state.loading = false

			const praise_id = action.payload?.payload.praise_id
			const newExpansionValue = Object.values(
				action.payload?.payload.expanstionToUpdate
			)[0]
			let arrayToUpdate =
				action.payload?.payload.type === 'draft' ? state.drafts : state.sentPraises

			const updateExpansionContributions = arrayToUpdate.map(
				(praise: PraiseOutput<IUser>) => {
					if (praise._id === praise_id) {
						return {
							...praise,
							expansionContribution: newExpansionValue,
						}
					}
					return praise
				}
			)
			const updateExpansionImpacts = arrayToUpdate.map(
				(praise: PraiseOutput<IUser>) => {
					if (praise._id === praise_id) {
						return {
							...praise,
							expansionsImapcts: newExpansionValue,
						}
					}
					return praise
				}
			)

			// if(updateExpansionContributions.length) return state.drafts = updateExpansionContributions
		})
		builder.addCase(update_praise_expansions.rejected, (state, action) => {
			state.loading = false
		})

		builder.addCase(update_replay_message.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(update_replay_message.fulfilled, (state, action) => {
			const output = action.payload?.data

			const updateReceivedPraises = state.receivedPraises.map(
				(praise: PraiseOutput<IUser>) => {
					if (praise._id === output?.output_id) {
						return { ...praise, replay: output.replayMessage }
					}
					return praise
				}
			) as PraiseOutput<IUser>[]
			state.receivedPraises = updateReceivedPraises
		})
		builder.addCase(update_replay_message.rejected, (state, action) => {
			state.loading = false
		})
	},
})

export const praiseActions = praiseSlice.actions
export default praiseSlice.reducer
