import React, { useState, ChangeEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

import useStyles from './styled'

import Plus from '../../../assets/plusIcon.svg'

type Props = {
	onAddCube?: () => void
	onBlur?: () => void
	onChange: ChangeEventHandler<HTMLInputElement>
	value: string
	isAddButton: boolean
	isDisabled: boolean
}
const CompanyValueInput: React.FC<Props> = ({
	onAddCube,
	onChange,
	onBlur,
	value,
	isAddButton,
	isDisabled,
}) => {
	const [isTouched, setIsTouched] = useState<boolean>(false)

	const { t } = useTranslation()

	const styles = useStyles()
	const onBlurInput = () => {
		onBlur?.()
		setIsTouched(false)
	}
	const changeTouched = () => setIsTouched(true)

	const plusCard = (<div
		className={`${styles.inputStyle} ${styles.plusButton}`}
		onClick={() => onAddCube && onAddCube()}
	>
		<img src={Plus} />
	</div>)

	const inputElement = (<input
		disabled={isDisabled}
		defaultValue={value}
		className={styles.inputStyle}
		onChange={onChange}
		onFocus={changeTouched}
		onBlur={onBlurInput}
		placeholder={isTouched ? '' : t('add_value')}
	/>)

	return isAddButton ? plusCard : inputElement
}

export default CompanyValueInput
