import colors from 'utils/colors'
import { RowDescriptionKey } from "../useEmployeesActivities"

export const getRowColor = (rowDescriptionKey: RowDescriptionKey): string => {
    const colorMapping: { [key in RowDescriptionKey]: string } = {
        sentPraises: colors.lightgreen,
        sentFeedbacks: colors.blue,
        openViaSent: colors.alert,
        receivedPraises: colors.lightgreen,
        receivedFeedbacks: colors.blue,
        openViaReceived: colors.alert,
        personalLearning: colors.purple,
        groupLearning: colors.purple,
        total: colors.white,
    }
    return colorMapping[rowDescriptionKey]
}