import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { userSelector } from "redux/user/user-selector"

type Props = {
    permissions?: string[]
}
const usePrivateSection = ({ permissions }: Props) => {
    const [isPrivate, setIsPrivate] = useState<boolean>(false)
    
    const user = useSelector(userSelector)

    useEffect(() => {
        if (!permissions?.length) return setIsPrivate(false)

        const userIncludesInPermissionsList = permissions?.includes(user._id)

        setIsPrivate(Boolean(userIncludesInPermissionsList) ? false : true)
    }, [permissions, user._id])

    const toggleIsPriavte = () => setIsPrivate(prev => !prev)
    const resetPriavte = () => setIsPrivate(prev => false)
    return { isPrivate, toggleIsPriavte, resetPriavte }
}

export default usePrivateSection