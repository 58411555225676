import classes from './styles.module.css'
import soonIcon from 'assets/urgencySoonIcon.svg'
import laterIcon from 'assets/urgencyLaterIcon.svg'
import asapIcon from 'assets/urgencyAsapIcon.svg'
import feedbackIcon from 'assets/feedback.svg'
import thumbupIcon from 'assets/thumpupIcon.svg'
import { UrgencyLevel } from 'enums/urgency/urgency.enum'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppDirectionContext } from 'context/languages-context'
import RenderOneOnOneReviewButton from 'components/BotOutput/RenderOneOnOneReviewButton'
import useInnerStyles from 'screens/BotOutput/beforeSending/innerStyles'

interface Props {
	blockageOpened?: boolean
	title?: string
	explanation?: string
	paragraph?: string[]
	urgency?: string
	readed?: boolean
	renderReviewBtn?: boolean
	toggleReviewBtn?: () => void
}
const Header = ({
	title,
	explanation,
	paragraph,
	urgency,
	blockageOpened,
	toggleReviewBtn,
	renderReviewBtn,
	readed,
}: Props) => {
	console.log({ readed })
	const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const innerStyles = useInnerStyles({ isRtlDirection, readed })
	const [showP, setShowP] = useState(blockageOpened ? true : false)

	const getUrgencyData = () => {
		let text = ''
		let icon = ''
		switch (urgency) {
			case UrgencyLevel.soon:
				text = t('soon_urgency')

				icon = soonIcon
				break
			case UrgencyLevel.later:
				text = t('later_urgency')

				icon = laterIcon
				break
			default:
				text = t('asap_urgency')

				icon = asapIcon
				break
		}
		return { text, icon }
	}
	const urgencyData = getUrgencyData()
	return (
		<div
			className={
				isRtlDirection ? `${classes.header} ${classes.rtl}` : classes.header
			}
		>
			<div
				style={{ justifyContent: !blockageOpened ? 'space-between' : 'center' }}
				className={classes.sectionsContainer}
			>
				<section
					style={{
						width: !blockageOpened ? 'fit-content' : '100%',
						textAlign: !blockageOpened ? 'left' : 'center',
					}}
					className={classes.info}
				>
					{title && !blockageOpened && <h1>{title}</h1>}

					{blockageOpened && (
						<div className={classes.blockageOpenTitleContainer}>
							{' '}
							<h1 className={classes.blockageOpenTitle}> {title}</h1>{' '}
						</div>
					)}

					{explanation && <h2>{explanation}</h2>}

					{paragraph && (
						<p
							style={{
								textAlign: blockageOpened
									? 'center'
									: isRtlDirection
									? 'right'
									: 'left',
								display: showP ? 'block' : '',
								fontSize: '120%',
							}}
						>
							{paragraph.length < 2 ? (
								paragraph[0]
							) : (
								<ul>
									{' '}
									{paragraph.map(bullet => (
										<li>{bullet}</li>
									))}
								</ul>
							)}
						</p>
					)}

					{blockageOpened && (
						<img className={classes.thumbupIcon} src={thumbupIcon} />
					)}
				</section>
				{!blockageOpened && (
					<section className={classes.urgency}>
						{t('urgency_title')}
						<img src={urgencyData.icon} />
						<b>{urgencyData.text}</b>
					</section>
				)}
			</div>

			<div className={classes.bottomHeader}>
				<div className={classes.label}>
					{t('i_need_you')}
				</div>
				{renderReviewBtn && (
					<RenderOneOnOneReviewButton
						{...{ styles: innerStyles, handleClick: toggleReviewBtn }}
					/>
				)}
			</div>

			{!blockageOpened && paragraph && paragraph[0].length > 0 && (
				<button
					className={classes.expandButton}
					onClick={() => setShowP(prevState => !prevState)}
				>
					{!showP ? t('more_details') : t('fold_back')}
				</button>
			)}
		</div>
	)
}

export default Header
