import { Text, StyleSheet } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

type Props = {
    creator: string
    code?: string
    createdAt?: Date
}
const DebriefInfo = ({ code, createdAt, creator }: Props) => {
    const { t } = useTranslation()
    return (
        <>
            <Text style={styles.text}>
                {t('debrief.debriefNum')} {code}
            </Text>
            <Text style={styles.text}>
                {t('created_on')}: {dayjs(createdAt).format('DD.MM.YYYY')}
            </Text>
            <Text style={styles.text}>
                {t('creator')}: {creator}
            </Text>
        </>
    )
}

export default DebriefInfo

const styles = StyleSheet.create({
    text: {
        color: 'black',
        fontWeight: 'thin',
        fontSize: '14px',
        padding: '2%',
        fontFamily: 'Rubik'
    },
})