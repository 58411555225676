import React, { useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { AppDirectionContext } from "context/languages-context"

import { userSelector } from "redux/user/user-selector"
import { feedbackLoadingSelector, receivedFeedbacksSelector } from "redux/feedback/feedback-selector"

import useStyles from './style'

import { TasksContainerWrapper } from './TasksContainerWrapper'

import useOutputNavigation from "utils/useOutputNavigation"
import { filter } from 'utils/filter'
import {
    getColleaguesGoalsCardDescription,
    getMyGoalsCardDescription,
} from 'screens/Goals'

import blockage from 'assets/blockage.svg'
import openBlockageIcon from 'assets/openBlockageIcon.svg'

import { FeedbackState } from "enums/feedback-state/feedback-state.enum"

const MainScreenDashboardShortcut = () => {
    const user = useSelector(userSelector)
    const loading = useSelector(feedbackLoadingSelector)
    const receivedFeedbacks = useSelector(receivedFeedbacksSelector)

    const { isRtlDirection } = useContext(AppDirectionContext)
    const classes = useStyles({ isRtlDirection })
    
    const { goEndpoint } = useOutputNavigation()
    const { t } = useTranslation()
    
    const sumOfBlocksAgainstColleagues = useMemo(
        () =>
            filter(
                receivedFeedbacks,
                ({ feedbackState }) => feedbackState !== FeedbackState.sayDo
            ).length,
        [receivedFeedbacks.length]
    )
    const sumOfBlockages = useMemo(() => {
        let sum = 0
        const userGoals = user?.statusInfo?.goals
        const goals = userGoals?.length ? userGoals.filter(g => !g.archive) : []

        for (const goal of goals) sum += goal.blockages

        return sum
    }, [user?.statusInfo?.goals.length])


    const groupBlockage = (
        <>
            <p>
                {t(getColleaguesGoalsCardDescription(sumOfBlocksAgainstColleagues)!, {
                    amountOfBlockages: sumOfBlocksAgainstColleagues,
                })}
            </p>
            <img
                src={sumOfBlocksAgainstColleagues >= 1 ? blockage : openBlockageIcon}
                className={classes.blockageImg}
            />
        </>
    )
    const personalBlockage = (
        <>
            <p>
                {t(getMyGoalsCardDescription(sumOfBlockages)!, {
                    count: sumOfBlockages,
                })}
            </p>
            <img
                src={sumOfBlockages >= 1 ? blockage : openBlockageIcon}
                className={classes.blockageImg}
            />
        </>
    )

    const handleBlockageNavigation = (type: BlockagesGoal) => {
        if (type === BlockagesGoal.Group) {
            goEndpoint(BlockagesGoal.Group, { block: Boolean(sumOfBlocksAgainstColleagues) })
        } else {
            goEndpoint(BlockagesGoal.Personal, { block: Boolean(sumOfBlockages) })
        }
    }

    return (
        <React.Fragment>
            <TasksContainerWrapper
                handleClick={() => handleBlockageNavigation(BlockagesGoal.Group)}
                loading={loading}
                greenChip={false}
                containerDescription={t(BlockagesGoal.Group)}
            >
                {groupBlockage}
            </TasksContainerWrapper>
            <TasksContainerWrapper
                handleClick={() => handleBlockageNavigation(BlockagesGoal.Personal)}
                loading={loading}
                containerDescription={t(BlockagesGoal.Personal)}
            >
                {personalBlockage}
            </TasksContainerWrapper>

        </React.Fragment>
    )
}

export default MainScreenDashboardShortcut
export enum BlockagesGoal {
    Group = 'colleagues_goals',
    Personal = 'my_goals',
}