import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    create_lessons_file,
    create_lessons_folder,
    delete_file,
    delete_folder,
    edit_the_file,
    edit_the_folder,
    paste_lesson,
    delete_file_lesson,
    create_title,
    get_file,
    create_new_lesson,
    update_lesson_description,
    defined_folder_managers
} from 'redux/company/company-actions'

import { lessonCopiedSelector, userSelector } from 'redux/user/user-selector'
import { userActions } from 'redux/user/user-reducer'

import {
    ILessonsBase,
    Folder,
    File,
    BaseFolderChild,
    IInsertLesson,
    DeleteLessonInFileParams,
    IAttacheLesson,
    UpdateLessonType,
} from 'interface/lessons-management/LessonsBase.interface'

type ApiErrorType = { message: string, stack: string }

type Props = {
    currentFolder?: ILessonsBase<Folder | File> | null
    userInput?: string
    selectedFolder?: Partial<BaseFolderChild>
    permissionsSetList?: Set<string>
    selectedSection: { title: string, title_id: string, lessons: string[] }
    getTitleIdOfLesson: (lesson_id: string) => string | undefined
    openPopup: () => void
}

const useLMRequests = ({
    userInput,
    currentFolder,
    selectedFolder,
    permissionsSetList,
    selectedSection,
    getTitleIdOfLesson,
    openPopup
}: Props) => {
    const [errorWhileRequest, setErrorWhileRequest] = useState('')

    const dispatch = useDispatch()
    const { company_id, _id: user_id } = useSelector(userSelector)
    const copiedLessonId = useSelector(lessonCopiedSelector)

    const getCurrentFile = () => dispatch(get_file({ company_id, file_id: currentFolder?._id! }))

    const itemUntitled = () => (userInput?.trim())

    const createFolder = () => {
        if (!itemUntitled()) return

        const folder = {
            ...getItemProperties(),
            type: { children: [] },
        }
        dispatch(create_lessons_folder(folder))
    }

    const createFile = () => {
        if (!itemUntitled()) return

        const file = {
            ...getItemProperties(),
            type: { lessons: [] },
        }
        dispatch(create_lessons_file(file))
    }

    const getItemProperties = () => {
        return {
            name: userInput?.toLowerCase(),
            permissions: [],
            prev: currentFolder?._id,
            company_id
        }
    }

    const editFolder = () => {
        const folder = {
            company_id,
            _id: selectedFolder ? selectedFolder?._id! : currentFolder?._id!,
            permissions: Array.from(permissionsSetList!),
            name: getUpdatedName(),
            prev: currentFolder?.prev!,
            type: currentFolder?.type!
        }

        dispatch(edit_the_folder(folder))
    }

    const editFile = () => {
        const file = {
            company_id,
            _id: selectedFolder ? selectedFolder?._id! : currentFolder?._id!,
            permissions: Array.from(permissionsSetList!),
            name: getUpdatedName(),
            prev: currentFolder?.prev!,
            type: currentFolder?.type!
        }

        dispatch(edit_the_file(file))
    }

    const insertLesson = () => {
        const { title } = getUpdatedTitle()
        if (!title) return

        if (copiedLessonId) attachedExistLesson()

        else attachedNewLesson()
    }
    const attachedExistLesson = () => {
        const lesson = {
            company_id,
            lessonBaseId: currentFolder?._id,
            ...getUpdatedTitle(),
        } as IAttacheLesson
        console.log({ lesson })
        dispatch(paste_lesson(lesson))
        dispatch(userActions.lessonIsCopied(''))
    }
    const attachedNewLesson = () => {
        const lesson = {
            company_id,
            lessonBaseId: currentFolder?._id,
            description: userInput,
            title_id: selectedSection.title_id,
        } as IInsertLesson

        dispatch(create_new_lesson(lesson))
    }

    const updateLesson = async (_id?: string | null) => {
        const lesson = {
            company_id,
            lesson_id: _id,
            lessonBaseId: currentFolder?._id,
            description: userInput,
            title_id: getTitleIdOfLesson(_id!)
        } as IInsertLesson
        try {
            updateLessonDescriptionStages(lesson)
        } catch (err) {
            handlingErrorPath(err)
        }
    }
    const updateLessonDescriptionStages = async (lesson: IInsertLesson): Promise<void> => {
        dispatch(update_lesson_description(lesson))

        setTimeout(() => {
            getCurrentFile()
        }, 1000)
    }

    const createTitle = async () => {
        if (!itemUntitled()) return

        const lesson = {
            company_id,
            lessonBaseId: currentFolder?._id!,
            title: userInput!
        }
        try {
            await createNewTitleStages(lesson)
        } catch (err) {
            handlingErrorPath(err)
        }
    }

    const createNewTitleStages = async (lesson: UpdateLessonType): Promise<void> => {
        dispatch(create_title(lesson))

        setTimeout(() => {
            getCurrentFile()
        }, 1000)
    }

    const updateTitle = async (title: string, title_id: string) => {
        const lesson = {
            company_id,
            lessonBaseId: currentFolder?._id!,
            title,
            _id: title_id
        }
        try {
            updateTitleStages(lesson)
        } catch (err) {
            handlingErrorPath(err)
        }
    }
    const updateTitleStages = async (lesson: UpdateLessonType): Promise<void> => {
        dispatch(create_title(lesson))

        setTimeout(() => {
            getCurrentFile()
        }, 1000)
    }

    const deleteFolder = () => {
        dispatch(delete_folder({ company_id, _id: selectedFolder?._id! }))
    }

    const deleteFile = () => {
        dispatch(delete_file({ company_id, _id: selectedFolder?._id! }))
    }

    const deleteFileLesson = async (_id: string) => {
        const id = getTitleIdOfLesson(_id)
        const payload = {
            _id: id,
            company_id,
            lesson_id: _id,
            lessonBaseId: currentFolder?._id
        } as DeleteLessonInFileParams

        try {
            deleteLessonStages(payload)
        } catch (err) {
            handlingErrorPath(err)
        }
    }

    const deleteLessonStages = async (payload: DeleteLessonInFileParams): Promise<void> => {
        dispatch(delete_file_lesson(payload))

        setTimeout(() => {
            getCurrentFile()
        }, 1000)
    }

    const handlingErrorPath = (err: unknown) => {
        const appError: ApiErrorType = err as ApiErrorType
        setErrorWhileRequest(appError.message)
        openPopup()

        setTimeout(() => {
            setErrorWhileRequest('')
        }, 1000)
    }

    const getUpdatedName = (): string => {
        if (userInput?.trim()) return userInput

        else if (selectedFolder?.name?.trim()) return selectedFolder.name

        else return currentFolder?.name!
    }

    const getUpdatedTitle = () => {
        if (selectedSection?.title_id) {
            const lessons: string[] = selectedSection.lessons.map(lesson_id => lesson_id)
            lessons.push(copiedLessonId)

            return {
                _id: selectedSection?.title_id,
                title: selectedSection?.title,
                lessons
            }
        }
        else return { title: userInput?.trim()!, lessons: [copiedLessonId] }
    }

    return {
        createFolder,
        createFile,
        editFolder,
        editFile,
        createTitle,
        deleteFolder,
        deleteFile,
        insertLesson,
        deleteFileLesson,
        updateTitle,
        getUpdatedTitle,
        updateLesson,
        errorWhileRequest
    }
}

export default useLMRequests