import { createSelector } from 'reselect'
import { IStoreRootState } from 'interface/redux'
import { ILessonsBase } from 'interface/lessons-management/LessonsBase.interface'

export const companyStateSelector = (state: IStoreRootState) => state.company

export const companyValuesSelector = createSelector(
	companyStateSelector,
	state => state.currentCompanyValues || []
)
export const employeesActivitiesSelector = createSelector(
	companyStateSelector,
	state => state.employeesActivities || {}
)
export const isCompanySelectorLoading = createSelector(
	companyStateSelector,
	state => state.loading
)
export const companyTotalActivitiesSelector = createSelector(
	companyStateSelector,
	state => state.companyActivity || []
)
export const companyPraisesActivitySelector = createSelector(
	companyStateSelector,
	state => state.companyPraisesActivity || []
)
export const companyFeedbacksActivitySelector = createSelector(
	companyStateSelector,
	state => state.companyFeedbacksActivity || []
)
export const companyPersLearningActivitySelector = createSelector(
	companyStateSelector,
	state => state.companyPersLearningActivity || []
)
export const companyGroupLearningActivitySelector = createSelector(
	companyStateSelector,
	state => state.companyGroupLearningActivity || []
)
export const companyManagerTodosActivitySelector = createSelector(
	companyStateSelector,
	state => state.companyManagerTodosActivity || []
)
export const companyEmployeesTodosActivitySelector = createSelector(
	companyStateSelector,
	state => state.companyEmployeesTodosActivity || []
)
export const companyTotalActivitySelector = createSelector(
	companyStateSelector,
	state => state.companyTotalActivity || []
)
export const allCompaniesActivitiesSelector = createSelector(
	companyStateSelector,
	state => state.allCompaniesActivities || []
)
export const companyNameSelector = createSelector(
	companyStateSelector,
	state => state.companyName || ''
)
export const numOfEmployeesSelector = createSelector(
	companyStateSelector,
	state => state.numOfEmployees || 0
)
export const companyJoiningDateSelector = createSelector(
	companyStateSelector,
	state => state.companyJoiningDate || ''
)
export const chartDataSelector = createSelector(
	companyStateSelector,
	state => state.companyChartData || []
)
export const exceptionsSelector = createSelector(
	companyStateSelector,
	state => state.companyExceptions || []
)
export const newExceptionsSelector = createSelector(
	companyStateSelector,
	state => state.hasNewExceptions || false
)
export const getUserBaseInsights = createSelector(
	companyStateSelector,
	state => state.baseInsight || []
)
export const hasMoreBaseInsight = createSelector(
	companyStateSelector,
	state => state.hasMoreBaseInsight || true
)
export const isBaseInsightLoading = createSelector(
	companyStateSelector,
	state => state.baseInsight.isLoading || false
)
export const companyLessonsSelector = createSelector(
	companyStateSelector,
	state => state.companyLessons || {} as ILessonsBase
)
