import { ChangeEvent, FC } from "react"
import TodoStatusDropdown from "./TodoStatus"
import classes from './styles.module.css'
import { TodoStatusOptions } from "enums/todos/todos.enum"

type Props = {
    status: TodoStatusOptions
    updateStatus: (e: ChangeEvent<HTMLSelectElement>) => void
}
const TodoButtons: FC<Props> = ({ updateStatus, status, children }) => {

    return (
        <div className={classes.todoHeader}>
            <TodoStatusDropdown name={status} defaultValue={status} handleChange={updateStatus} />

            {children}

        </div>
    )
}

export default TodoButtons