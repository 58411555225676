import io, { Manager } from 'socket.io-client'
import baseUrl from '../api/base-url'

const ENDPOINT = baseUrl.development

const socket = io(ENDPOINT, {
	autoConnect: true,
	reconnection: false,
})

let patch = require('socketio-wildcard')(Manager)

export default socket