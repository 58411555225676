import { AppDirectionContext } from 'context/languages-context'
import Paths from 'enums/routes/path-names'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import copyright from '../../assets/copyright.svg'
import classes from './style.module.css'

const links: any = {
	[Paths.TERMS_SERVICE_EG]:
		'../../documents/SAYDO_END_USER_SOFTWARE_LICENSE_AGREEMENT.pdf',
	[Paths.TERMS_SERVICE_HE]: '../../documents/terms-of-use.pdf',
	[Paths.PRIVACY_POLICY_EG]: '../../documents/website-privacy-policy-SayDo.pdf',
	[Paths.PRIVACY_POLICY_HE]: '../../documents/privacy-policy.pdf',
}

const getTermsServiceUrlAccroudingToRtlDirection = (isRtl = false) => {
	if (!isRtl) {
		return Paths.TERMS_SERVICE_EG
	}

	return Paths.TERMS_SERVICE_HE
}
const getPrivacyPolicyUrlAccroudingToRtlDirection = (isRtl = false) => {
	if (!isRtl) {
		return Paths.PRIVACY_POLICY_EG
	}

	return Paths.PRIVACY_POLICY_HE
}

const openTab = (url: Paths) => {
	const link = links[url]
	window.open(url, link)
}

const Footer = () => {
	const currentYear = new Date().getFullYear()
	const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)

	const anchorStyle = {
		padding: '0 5px',
		textDecorationLine: 'underline',
		outline: 'none',
		color: 'blue',
		border: 'none',
		backgroundColor: 'inherit',
		fontSize: ' 1.5vh',
		cursor: 'pointer',
	}

	const policyUrl = getPrivacyPolicyUrlAccroudingToRtlDirection(isRtlDirection)
	const termsUrl = getTermsServiceUrlAccroudingToRtlDirection(isRtlDirection)

	return (
		<footer id={'footer'} className={classes.footer}>
			<img src={copyright} />
			<span>SOLVE {currentYear}. All rights reserved.</span>
			<span style={{ marginLeft: '8px', display: 'flex' }}>
				<button onClick={() => openTab(policyUrl)} style={anchorStyle}>
					{t('privacy_policy')}
				</button>
				<button onClick={() => openTab(termsUrl)} style={anchorStyle}>
					{t('terms_service')}
				</button>
			</span>
		</footer>
	)
}

export default Footer
