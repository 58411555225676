import {
	FocusEventHandler,
	ChangeEventHandler,
	FC,
	useContext,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme } from '@material-ui/core'
import { AppDirectionContext } from 'context/languages-context'
import { Tooltip } from '@mui/material'

interface Props {
	height?: string
	padding?: string
	label?: string
	type: string
	name: string
	htmlFor: string
	onChange?: ChangeEventHandler
	value?: string | number
	error?: string
	width?: string
	border?: string
	rightDirection?: boolean
	defaultValue?: string | number
	textarea?: boolean
	tooltipTitle?: { emoji: string; title: string }[]
}

const useStyles = makeStyles<Theme>(({ palette, breakpoints }) => ({
	label: {
		color: palette.text.primary,
		fontSize: '14px',
		letterSpacing: '0',
		lineHeight: '18px',
		[breakpoints.down('lg')]: {
			// fontSize: '13px',
			lineHeight: '16px',
			fontSize: '16px',
		},
	},
	input: {
		fontFamily: 'Sora, sans-serif',
		height: '50px',
		padding: '0 20px 0 20px',
		marginTop: '13px',
		borderRadius: '10px',
		outline: 'none',
		border: `1px solid ${palette.action.disabled}`,
		boxShadow:
			'inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255,1)',
		[breakpoints.down('lg')]: {
			height: '45px',
			marginTop: '10px',
			fontSize: '16px',
		},
	},
	error: {
		color: palette.error.main,
		marginTop: '10px',
		[breakpoints.down('lg')]: {
			fontSize: '13px',
			marginBottom: 0,
			marginTop: '8px',
		},
	},
}))

const Input: FC<Props> = ({
	height,
	padding,
	label,
	type,
	name,
	htmlFor,
	width,
	onChange,
	value,
	defaultValue,
	error,
	textarea,
	border,
	rightDirection,
	tooltipTitle,
}) => {
	const classes = useStyles()
	const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const [showTooltipTitle, setShowTooltip] = useState(false)
	return (
		<>
			{label && (
				<label htmlFor={htmlFor} className={classes.label}>
					{t(label)}
				</label>
			)}
			{textarea ? (
				<textarea
					style={{
						border: border,
						padding: '6%',
						height: '50px',
						resize: 'none',
					}}
					className={classes.input}
					name={name}
					rows={2}
					cols={20}
					onChange={onChange}
					value={value}
					defaultValue={defaultValue}
				/>
			) : (
				<Tooltip
					open={showTooltipTitle}
					title={
						tooltipTitle && tooltipTitle.length > 0 ? (
							<div>
								{tooltipTitle.map(title => (
									<h3>
										<span>{title.emoji} </span>
										{title.title}
									</h3>
								))}
							</div>
						) : (
							''
						)
					}
					arrow={true}
					placement='right'
				>
					<input
						style={{
							direction: isRtlDirection ? 'rtl' : 'ltr',
							border: border,
							width: width,
							padding: padding,
							height: height,
						}}
						className={classes.input}
						type={type}
						name={name}
						value={value}
						defaultValue={defaultValue}
						onChange={onChange}
						onFocus={() => (tooltipTitle ? setShowTooltip(true) : () => {})}
						onBlur={() => (tooltipTitle ? setShowTooltip(false) : () => {})}
						autoComplete='off'
					/>
				</Tooltip>
			)}
		</>
	)
}

export default Input
