import { ChangeEvent, useContext, useEffect, useRef } from 'react'
import { useStyles } from '../../Bot/TextArea/useStyles'
import { AppDirectionContext } from 'context/languages-context'

interface Props {
	defaultValue: string
	placeholder: string
	handleChange: (value: ChangeEvent<HTMLTextAreaElement>) => void
	maxLength?: number,
	autoFocus?: boolean
	value?: any
}
const TextArea = ({
	placeholder,
	defaultValue,
	handleChange,
	maxLength,
	autoFocus,
	value,
}: Props) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const cursor = useRef<HTMLTextAreaElement>(null)

	useEffect(() => {
		if (cursor?.current) {
			cursor.current.selectionStart = cursor.current.value.length
			cursor.current?.focus()
		}
	}, [value])
	
	return (
		<textarea
			ref={cursor}
			value={value}
			autoFocus={autoFocus}
			defaultValue={defaultValue}
			style={{ width: '100%', height: '80%', margin: 'auto auto 3% auto' }}
			onChange={handleChange}
			placeholder={placeholder}
			className={classes.textArea}
			rows={5}
			cols={33}
			maxLength={maxLength ? maxLength : 300}
		></textarea>
	)
}

export default TextArea
