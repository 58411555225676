import { useEffect, useState } from 'react'
import useStyles from '../../components/SuperAdmin/styles'
import EditCompany from '../../components/SuperAdmin/EditCompany'
import CreateCompany from '../../components/SuperAdmin/CreateCompany'
import Companies from '../../components/SuperAdmin/Companies'
import EditEmployees from 'components/SuperAdmin/EditEmployees'
import { Company } from 'interface/company/company-interface'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'
// import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal'
import { useDispatch, useSelector } from 'react-redux'
import { get_companies } from 'redux/company/company-actions'
import { CircularProgress } from '@material-ui/core'
import { ErrorBoundary } from 'react-error-boundary'

import { ErrorFallback } from 'components/common/ErrorFallback'
import { IStoreRootState } from '../../interface/redux/index'

const initialModalState = { onConfirm: undefined, msg: '' }

const CompaniesManagement = () => {
	const dispatch = useDispatch()
	const companies = useSelector((state: any) => state.company.companies)
	const loading = useSelector((state: any) => state.company.loading)
	const error = useSelector((state: any) => state.company.error)
	const requestError = useSelector(
		(state: IStoreRootState) => state.requestError.error
	)
	const classes = useStyles({})
	const [contentState, setContentState] = useState('companies')
	const [companyOnEdit, setCompanyOnEdit] = useState<Company | undefined>()
	const [modalState, setModalState] =
		useState<{ onConfirm: any; msg: string }>(initialModalState)

	const onChangeContent = (content: string) => setContentState(content)
	useEffect(() => {
		if (!companies.length) dispatch(get_companies())
	}, [dispatch])
	const progressbar = (
		<div
			style={{
				width: 'fit-content',
				margin: '15% auto',
			}}
		>
			<CircularProgress size={150} />
		</div>
	)
	const renderSuperAdminContent = () => {
		const handleModal = (onConfirm: any, msg: string) => {
			setModalState({ onConfirm: onConfirm, msg: msg })
		}
		switch (contentState) {
			case 'companies':
				return (
					<Companies
						error={error}
						companies={companies}
						onMsg={handleModal}
						onEditEmployees={(company: Company) => {
							onChangeContent('edit_employees')
							setCompanyOnEdit(company)
						}}
						onEdit={company => {
							onChangeContent('edit')
							setCompanyOnEdit(company)
						}}
						onCreate={() => onChangeContent('create')}
					/>
				)
			case 'edit':
				return (
					<EditCompany
						error={error}
						onMsg={handleModal}
						company={companyOnEdit}
						onClose={() => onChangeContent('companies')}
					/>
				)
			case 'create':
				return (
					<CreateCompany
						error={error}
						onMsg={handleModal}
						onClose={() => onChangeContent('companies')}
					/>
				)
			case 'edit_employees':
				return (
					<EditEmployees
						error={error}
						onMsg={handleModal}
						company={companyOnEdit}
						onClose={() => onChangeContent('companies')}
					/>
				)
			default:
				return <div>No content.</div>
		}
	}
	const content = renderSuperAdminContent()
	return (
		<>
			<ErrorBoundary
				FallbackComponent={ErrorFallback}
				onError={(e: any) => {
					console.log({ e })
				}}
			>
				{loading ? (
					progressbar
				) : (
					<div className={classes.mainContainer}>{content}</div>
				)}
				<ConfirmModalSB
					onClose={() => setModalState(initialModalState)}
					onConfirm={modalState.onConfirm}
					text={requestError.message ?? modalState.msg}
					isOpen={requestError.message ?? modalState.msg ? true : false}
				/>
			</ErrorBoundary>
		</>
	)
}

export default CompaniesManagement
