import { useTranslation } from 'react-i18next'
import { FC, useRef, useLayoutEffect } from 'react'
import classes from '../ActionPlan/styles.module.css'

export enum ActionPlanButtonEnum {
	SendAction = 'send_action_plan',
	PlanApproved = 'plan_approved',
	OpenBlockageExplain = 'open_blockage_explained',
	WaitingForApproval = 'waiting_for_sender_approval',
	SenderApprovalPlan = 'sender_approved_plan',
}

type ButtonProps = {
	text?: ActionPlanButtonEnum
	options?: { senderName: string }
}

export const ButtonWithScrollDownOption: FC<ButtonProps> = ({
	text,
	options,
	children,
}) => {
	const { t } = useTranslation()
	const containerRef = useRef<any>()

	useLayoutEffect(() => {
		if (!containerRef?.current) return
		containerRef.current.scrollIntoView({ behavior: 'smooth' })
		return () => {}
	}, [containerRef?.current])

	const useTranslationOptions = {
		...options,
		interpolation: { escapeValue: false },
	}

	const styles = text
		? {}
		: { width: '100%', display: 'flex', justifyContent: 'center' }

	children = text ? t(text, useTranslationOptions) : children

	return (
		<div
			className={text ? classes.buttonNotification : ''}
			style={styles}
			ref={containerRef}
		>
			{children}
		</div>
	)
}
