import { FC, useContext, useEffect, useState } from 'react'
import { AnswersGroupProp } from 'interface/bot'
import ActionsButton, { UserAnswer } from 'components/Bot/ActionButtons'
import SearchRectangle from '../../common/SearchRectangle'
import UserCard from './userCard'
import useHandlers from './useHandlers'
import { useStyles } from './useStyles'
import { QuestionType } from 'interface/praise/praise-interface'
import { AppDirectionContext } from '../../../context/languages-context'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { Loader } from 'components/common/Loader'

const UsersList: FC<AnswersGroupProp> = ({
	isPraise,
	type,
	previousQuestion,
	nextQuestion,
	setQuestion,
}) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const { t } = useTranslation()
	const {
		handleClick,
		handleSearchTextChange,
		usersList,
		answer,
		listInitilaitzed,
	} = useHandlers({ setQuestion, nextQuestion, isPraise, type })
	const selectedUsersId = answer?.participants?.map(user => user._id)

	return (
		<>
			<SearchRectangle
				handleChange={handleSearchTextChange}
				className={classes.searchWrapper}
			/>

			<div className={classes.usersListContainer}>
				{usersList.length ? (
					usersList.map(user => {
						const {
							personalInfo: { firstName },
							securityInfo: { email },
						} = user
						const isSelectedUser = selectedUsersId?.includes(user._id)
						const answer: UserAnswer = {
							answer: firstName,
							id: user._id,
							nextQuestion: nextQuestion ? nextQuestion : null,
							previousQuestion,
							type,
						}

						return (
							<UserCard
								key={email}
								answer={answer}
								user={user}
								isSelectedUser={isSelectedUser}
								handleSelectedUser={handleClick}
							/>
						)
					})
				) : !listInitilaitzed ? (
					<Loader size={55} height={'auto'} />
				) : (
					<div>{t('bot.user_was_not_found')}</div>
				)}
			</div>
			{type !== QuestionType.FirstQuestion && (
				<ActionsButton
					isPraise={isPraise}
					isEnabled={!!answer.participants?.length}
					answer={answer}
					setQuestion={setQuestion}
					showNextStepButton
				/>
			)}
		</>
	)
}

export default UsersList

type PropsScrollIndicator = {
	container: HTMLElement
}

const ScrollIndicator: React.FC<PropsScrollIndicator> = props => {
	const { container } = props

	const [isScrollNeeded, setScrollValue] = useState<boolean>(true)
	const [isScrollable, setScrollable] = useState<boolean>(false)

	const handleScroll = (): void => {
		const scrollDiv = container
		const result =
			scrollDiv.scrollTop < scrollDiv.scrollHeight - scrollDiv.clientHeight ||
			scrollDiv.scrollTop === 0

		setScrollValue(result)
	}

	const checkIfScrollable = (el: HTMLElement) => {
		return el && el.scrollHeight > el.offsetHeight
	}

	useEffect(() => {
		setScrollable(checkIfScrollable(container))
		container.addEventListener('scroll', handleScroll)
		return () => container.removeEventListener('scroll', handleScroll)
	}, [container, handleScroll])

	const scrollabale = isScrollable && isScrollNeeded
	return scrollabale ? (
		<div
			style={{
				backgroundColor: 'red',
				position: 'sticky',
				top: '50%',
				width: '100%',
				height: 0,
				borderTop: ' 10px solid red',
			}}
		>
			Scroll
		</div>
	) : null
}
