export const initialEmployeesState = {
	unAssignList: [],
	assignList: [],
}

export function employeesReducer(
	state: any,
	action: { type: string; value: any }
) {
	switch (action.type) {
		case 'UNLIST_FROM_ASSIGN':
			const employeeToUnListFromAssign = action.value
			const updatedAssignList = state.assignList.filter(
				(id: string) => id !== employeeToUnListFromAssign
			)
			return {
				...state,
				assignList: updatedAssignList,
			}
		case 'UNLIST_FROM_UNASSIGN':
			const employeeToUnListFromUnAssign = action.value
			const updatedUnAssignList = state.unAssignList.filter(
				(id: string) => id !== employeeToUnListFromUnAssign
			)
			return {
				...state,
				unAssignList: updatedUnAssignList,
			}
		case 'LIST_ON_ASSIGN':
			const assignlistEmployee = action.value
			return {
				...state,
				assignList: [...state.assignList, assignlistEmployee],
			}

		case 'LIST_ON_UNASSIGN':
			const unassignListEmployee = action.value
			return {
				...state,
				unAssignList: [...state.unAssignList, unassignListEmployee],
			}
		case 'RESTART_UNASSIGN_LIST':
			return {
				...state,
				unAssignList: [],
			}
		case 'RESTART_ASSIGN_LIST':
			return {
				...state,
				assignList: [],
			}
		// case 'ASSIGN':
		// 	const currentManagerEmail = action.value
		// 	const updatedWithAssignedEmployees = state.employees.map(
		// 		(employee: IUser) => {
		// 			if (state.assignList.includes(employee._id)) {
		// 				employee.statusInfo.directManager = currentManagerEmail
		// 			}
		// 			return employee
		// 		}
		// 	)
		// 	return {
		// 		...state,
		// 		employees: updatedWithAssignedEmployees,
		// 		assignList: [],
		// 	}
		// case 'UNASSIGN':
		// 	const updatedWithUnassignedEmployees = state.employees.map(
		// 		(employee: IUser) => {
		// 			if (state.unAssignList.includes(employee._id)) {
		// 				employee.statusInfo.directManager = 'unassigned'
		// 			}
		// 			return employee
		// 		}
		// 	)
		// 	return {
		// 		...state,
		// 		employees: updatedWithUnassignedEmployees,
		// 		unAssignList: [],
		// 	}

		default:
			throw new Error('no employees action: check employees reducer')
	}
}
