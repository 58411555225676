import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(({ palette, breakpoints }) => ({
	container: {
		overflow: 'hidden',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '97vh',
		overflowY: 'auto',
		margin: 0,

		[breakpoints.up('lg')]: {
			justifyContent: 'center',
		},
		[breakpoints.down('sm')]: {
			boxSizing: 'border-box',
			padding: '0 20px',
			height: '100vh',
		},
	},
	loginContainer: {
		overflow: 'none',
		// margin: '285px 0 0 110px',
		width: '100%',
		marginInlineStart: '5rem',
		[breakpoints.down('lg')]: {
			marginTop: '1rem',

			// margin: '220px 0 0 80px',
		},
		[breakpoints.down('sm')]: {
			marginInlineStart: '3rem',
		},
		[breakpoints.down('xs')]: {
			margin: 0,
			marginInlineStart: 0,
		},
	},
	loginTitle: {
		fontSize: '36px',
		fontWeight: 700,
		lineHeight: '45px',
		color: palette.text.primary,
		margin: 0,
		[breakpoints.down('lg')]: {
			fontSize: '29px',
		},
	},
	loginDesc: {
		height: '42px',
		width: '449px',
		color: palette.text.primary,
		fontSize: '16px',
		letterSpacing: 0,
		lineHeight: '21px',
		fontWeight: 400,
		[breakpoints.down('lg')]: {
			fontSize: '13px',
			width: '355px',
			lineHeight: '18px',
		},
	},
	formContainer: {
		width: '100%',
		margin: '50px 0 0 0',
		[breakpoints.down('lg')]: {
			marginTop: '25px',
		},
	},
	logoWrapper: {
		// position: 'absolute',
		// top: '-1%',
		// left: '70px',

		[breakpoints.down('lg')]: {
			top: '60px',
			left: '80px',
		},

		[breakpoints.up('xs')]: {
			marginBottom: '1.5rem',
		},

		[breakpoints.down('xs')]: {
			// top: '32px',
			left: 'calc(50% - 70px)',

			display: 'flex',
			justifyContent: 'center',
			marginTop: '1rem',
		},
	},
	logo: {
		// height: '57px',
		// width: 0,
		width: '15rem',

		// [breakpoints.down('md')]: {
		// 	// height: '140px',
		// 	width: '10vw',
		// },

		[breakpoints.down('lg')]: {
			width: '10rem',
		},
	},
	solveLogo: {
		display: 'flex',
		position: 'absolute',
		top: '42%',
		left: '62%',
		width: '200px',
	},
	backgroundImage: {
		position: 'absolute',
		zIndex: -1,
		width: '85%',
		top: '-36%',
		right: '-11.5%',
		[breakpoints.down('md')]: {
			width: '125%',
			top: '-30%',
			right: '-68%',
		},
		[breakpoints.down('xs')]: {
			display: 'block',
			width: '185%',
			position: 'initial',
			margin: '-35% 0 0 -45%',
		},
	},
	userMessage: {
		color: 'green',
		marginTop: 25,
		textAlign: 'center',
		float: 'left',
	},
	anchor: {
		textDecoration: 'none',
	},
}))
