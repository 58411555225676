import { useTranslation } from 'react-i18next'

import './style.css'

import DrawIcon from './DrawIcon'

import { LMButtonType } from '../props'

const GenericItemButton = ({
    label,
    icon,
    disabled = false,
    handleClick,
    className,
    aria_describedby
}: LMButtonType) => {
    const { t } = useTranslation()

    let btnClass = `lessons-btn ${label.toLowerCase().replace('_', '-')}`
    if (disabled) btnClass += ' disabled'

    return (
        <div
            className={!className ? btnClass : [btnClass, className].join(' ')}
            onClick={!disabled ? handleClick : () => { }}
            tabIndex={disabled ? -1 : 0}
            aria-disabled={disabled}
            aria-describedby={aria_describedby}>

            {icon && <DrawIcon icon={icon} />}

            <p>{t(`${label}`)}</p>

        </div>
    )
}
export default GenericItemButton