import classes from '../style.module.css'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Link } from 'components/common/Header/Mobile/constants'
import React, { FC, useContext, useEffect, useState } from 'react'
import { AppDirectionContext } from 'context/languages-context'
type NavigationButtonProps = {
	isActivePage?: boolean
	handleButtonClick: (link: string) => void
	isMobile: boolean
}
const NavigationButton: FC<Link & NavigationButtonProps> = React.memo(
	({
		link,
		linkName,
		inActiveLink,
		NavIcon,
		isMobile,
		isActivePage,
		handleButtonClick,
	}) => {
		const { isRtlDirection } = useContext(AppDirectionContext)
		const { t } = useTranslation()
		const buttonTextStyle = { color: inActiveLink ? '#cecece' : '#46484a' }
		return isMobile ? (
			<div
				className={classes.linkWrapper}
				onClick={() => {
					if (!inActiveLink && !isActivePage) handleButtonClick(link)
				}}
			>
				<div
					style={{
						margin: isRtlDirection ? '0 0 0 26px' : '0 26px 0 0',
						border: isActivePage ? '1px solid rgb(52, 171, 83)' : '1px solid #DEE3EF',
						boxShadow: isActivePage
							? ' rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
							: 'none',
						backgroundColor: isActivePage ? 'rgb(52, 171, 83)' : 'white',
					}}
					className={classes.iconLinkWrapper}
				>
					<NavIcon color={isActivePage ? '' : '#34AB53'} />
				</div>
				<div style={{ ...buttonTextStyle }} className={classes.sideNavLink}>
					{t(linkName)}
				</div>
			</div>
		) : (
			<Button
				disabled={inActiveLink}
				className={classes.navButton}
				sx={{
					'&.MuiButtonBase-root:hover': {
						bgcolor: '#f8f8f9',
					},
				}}
				onClick={() => {
					if (!inActiveLink && !isActivePage) handleButtonClick(link)
				}}
				variant={undefined}
			>
				<div
					className={classes.navbarIcon}
					style={{
						boxShadow: isActivePage
							? ' rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
							: 'none',
						backgroundColor: isActivePage ? '#34AB53' : 'unset',
					}}
				>
					<NavIcon color={isActivePage ? 'white' : '#34AB53'} />
				</div>
				<div
					style={{
						width: '100%',
					}}
				>
					<div
						className={classes.linkText}
						style={{
							...buttonTextStyle,
							fontWeight: isActivePage ? 'bolder' : 500,
						}}
					>
						{t(linkName)}
					</div>
				</div>
			</Button>
		)
	}
)

export default NavigationButton
