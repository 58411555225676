import { INewUserInitState } from 'interface/user/user-interface'
import * as XLSX from 'xlsx'

export const readXlFile = (file: File) => {
	const promise = new Promise((resolve, reject) => {
		const fileReader = new FileReader()
		fileReader.readAsArrayBuffer(file)
		fileReader.onload = event => {
			const bufferArray = event.target?.result
			const employeesXl = XLSX.read(bufferArray, { type: 'buffer' })
			const employeesXlSheetName = employeesXl.SheetNames[0]
			const employeesXlSheet = employeesXl.Sheets[employeesXlSheetName]
			const employeesToArray: Array<INewUserInitState> = XLSX.utils.sheet_to_json(
				employeesXlSheet
			)
			resolve(employeesToArray)
		}
		fileReader.onerror = error => {
			reject(error)
		}
	})

	return promise
}
