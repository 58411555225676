import CompanyFoldersManagement from 'components/Debriefing/Lessons-management/root'
import { Grid } from '@material-ui/core'

import './style.css'

const CompanyLessonsScreen = () => {
	return (
		<Grid xs={11} sm={8} className='container'>
			<CompanyFoldersManagement />
		</Grid>
	)
}

export default CompanyLessonsScreen
