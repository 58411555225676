import { ActionPlanButtonEnum } from 'components/FeedbackProcess/ButtonWithScrollDownOption'

interface ButtonWithScrollDownInput<T = boolean> {
	noActionPlan: T
	blockageOpened: T
	planConfirmed: T
	isSender: T
	planSent: T
	isReceiver: T
	actions: Record<any, any>
}

export function getButtonWithScrollText({
	noActionPlan,
	blockageOpened,
	planConfirmed,
	isSender,
	planSent,
	isReceiver,
	actions,
}: ButtonWithScrollDownInput) {
	if (planConfirmed && isReceiver && !actions.canSend && !blockageOpened) {
		return ActionPlanButtonEnum.SenderApprovalPlan
	}

	if (planSent && isReceiver && !blockageOpened) {
		return ActionPlanButtonEnum.WaitingForApproval
	}

	if (planConfirmed && isSender) {
		return ActionPlanButtonEnum.OpenBlockageExplain
	}
}
