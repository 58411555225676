import { ChangeEvent, useEffect, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { Avatar } from '@material-ui/core'

import useStyles from '../styles'

import { update_company } from 'redux/company/company-actions'
import { companyActions } from 'redux/company/company-reducer'

import { formReducer, buildCompanyObject, mainInputsData } from '../formHelper'
import Button from '../../common/Button'
import Licenses from '../Licenses/Licenses'
import Contacts from '../Contacts/Contacts'
import EditCard from './EditCard'
import { AvailableLangs } from '../SelectLanguageList/index'

import xIcon from '../../../assets/xIcon.svg'
import { Company, Contact } from 'interface/company/company-interface'
import { License } from 'enums/License/License.enum'

interface Props {
	error: string
	company: any
	onClose: () => void
	onMsg: (onConfirm: any, msg: string) => void
}
const EditCompany = ({ onClose, company, onMsg, error }: Props) => {
	const initialFormState = {
		_id: company._id,
		name: company.companyName,
		id: company.companyId,
		fee: company.annualFee,
		contacts: company.contacts,
		licenses: company.license,
		formErrors: {},
		isValid: false,
	}
	const [formState, dispatchForm] = useReducer(formReducer, initialFormState)

	const dispatch = useDispatch()
	const classes = useStyles({})

	useEffect(() => {
		if (error) {
			onMsg(false, `Server error - could not update company ${error} `)
			dispatchForm({ type: 'FORM_RESTART', value: '' })
			dispatch(companyActions.removeError())
		}
	}, [error])
	useEffect(() => {
		const updateCurrentCompany = async () => {
			const updatedCompany = buildCompanyObject(formState)
			dispatch(update_company(updatedCompany))
			onClose()
		}
		if (formState.isValid) {
			updateCurrentCompany()
		}
	}, [formState.isValid])

	const onFormSubmit = async (e: any) => {
		e.preventDefault()
		dispatchForm({ type: 'FORM_VALIDATION', value: null })
	}

	const companyLang = AvailableLangs[company.language ?? 'en']

	const handleSetProperties = ({ target: { value } }: ChangeEvent<HTMLInputElement>, name: string) => {
		dispatchForm({
			type: 'SET_PROPERTY',
			value: { name: name, value },
		})
	}
	const handleSetContacts = (contacts: Contact[]) =>
		dispatchForm({ type: 'SET_CONTACTS', value: contacts })

	const handleSetLicense = (licenses: License[]) =>
		dispatchForm({ type: 'SET_LICENSES', value: licenses })

	const getDefaultValue = (name: string, company: Company) => {
		return {
			['name']: company.companyName,
			['id']: company.companyId,
			['fee']: company.annualFee
		}[name]
	}
	return (
		<>
			<div>
				<form onSubmit={onFormSubmit} className={classes.editForm}>

					<button
						className={`${classes.actionButton} ${classes.closeButton}`}
						onClick={onClose}
					>
						<img src={xIcon} />
					</button>

					<div className={`${classes.flexCenter} ${classes.editHeader}`}>

						<div className={classes.editCompanyMainDetails}>

							<Avatar
								style={{ width: '90px', height: '90px' }}
								alt={company.companyName}
							/>

							<span>
								<h1>{company.companyName}</h1>
								<div>Company ID: {company.companyId}</div>
								<span>{formState.error}</span>
							</span>

							<span>language: ({companyLang})</span>

						</div>
					</div>

					<EditCard text='view and edit information'>
						<div className={classes.editInputsSection}>
							<div className={`${classes.flexCenter} ${classes.mainDetails}`}>

								{mainInputsData.map(input => (
									<div className={classes.inputContainer} key={input.id}>
										<label htmlFor={input.id}>{input.title}</label>
										<input
											disabled={input.id === 'company_name'}
											id={input.id}
											type={input.type}
											placeholder={input.title}
											style={{
												border: formState.formErrors[input.name]
													? '2px solid #E55757'
													: '2px solid #A5A5A5',
											}}
											onChange={(e) => handleSetProperties(e, input.name)}
											defaultValue={getDefaultValue(input.name, company)}
										/>
										<span>
											{formState.formErrors[input.name] ? `invalid ${input.name}` : ''}
										</span>
									</div>
								))}

							</div>
						</div>
					</EditCard>

					<EditCard text={'view and edit contacts'}>
						<div style={{ width: '100%' }}>
							<Contacts
								errors={formState.formErrors.contacts}
								onInputChange={handleSetContacts}
								contacts={company.contacts}
							/>
						</div>
					</EditCard>
					<EditCard text={'view and edit licenses'}>
						<div style={{ width: '100%' }}>
							<Licenses
								onEdit={true}
								licenseValue={company.license}
								setValues={handleSetLicense}
							/>
						</div>
					</EditCard>
					<div className={`${classes.flexCenter} ${classes.formButtonsContainer}`}>
						<Button onClick={onClose}>Cancel</Button>
						<Button type='submit'>Save</Button>
					</div>
				</form>
			</div>
		</>
	)
}

export default EditCompany
