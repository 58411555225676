import { makeStyles, Theme } from '@material-ui/core/styles'
import colors from 'utils/colors'


export default makeStyles<Theme, { isRtlDirection: boolean }>(
	({ palette, breakpoints }) => ({
		container: {
			width: '100%',
		},
		mostRecentTitle: {
			fontSize: '22px',
			fontWeight: 600,
			marginBottom: '20px',
			[breakpoints.down('sm')]: {
				margin: '5% 0 0 0',
			},
		},
		cardsContainer: {
			width: '100%',
			height: '250px',
			display: 'flex',
			[breakpoints.down('sm')]: {
				flexDirection: 'column',
				height: 'initial',
			},
		},
		card: {
			marginInlineEnd: '18px',
			[breakpoints.down('sm')]: {
				marginInlineEnd: '0',
				marginLeft: 0,
				marginTop: '11px',
			},
		},
		lessonsManagement: {
			color: '#46484a',
			border: '1px solid #DEE3EF',
			cursor: 'pointer',
			width:'30%',
			height: '80%',
			display: 'flex',
			padding: '20px 22px 30px',
			fontSize: '14px',
			boxShadow: 'rgb(0 0 0 / 4%) 2px 2px',
			borderRadius: '10px',
			flexDirection: 'column',
			alignItems: 'center',
			margin: '0 1.5%',

			'& >p':{
				fontWeight: 700,
			},
			'&:hover': {
				padding: '19px 21px 29px',
				border: `2px solid ${palette.secondary.main}`,
			},
			[breakpoints.down('sm')]: {
				marginTop: '1%',
				width:'86%',
				'&:hover': {
					border: `1px solid ${palette.action.disabled}`,
				},
			},
		},
		folderIcon: {
			width: '150px',
			height: '150px',
			color: '#5B4EB5'
		}
	})
)
