import { makeStyles, Theme } from "@material-ui/core"

export default makeStyles<Theme, { isRtlDirection: boolean, actionIsOn: boolean, disabled: boolean }>(() => ({
    slideButton: {
        fontSize: '110%',
        cursor: 'pointer',
        borderRadius: '10px',

        margin: '1%',
        width: '300px',
        height: '80px',
        display: 'flex',
        overflow: 'hidden',
        backgroundColor: ({ disabled }) => (disabled ? '#8080804a' : ''),
        opacity: ({ disabled }) => (disabled ? 0.4 : 1),
        boxShadow: ({ disabled }) => (disabled ? 'grey 0 0 0 3px' : '#0071C2 0 0 0 3px')
    },
    pri: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: ' 0 10px 10px 0 ',
    },
    imgCon: {
        padding: '0 10px 0 10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        width: '80px',
        height: '80px',
        backgroundColor: ({ disabled }) => (disabled ? '#8080804a' : 'rgba(128, 128, 128, -0.71)'),
        opacity: ({ disabled }) => (disabled ? 0.4 : 1)
    },
    sec: {
        fontWeight: 'bolder',
        boxShadow: 'inset #0071C2 0 0 0 15px',
        transition: 'all 0.65s ',
        width: ({ actionIsOn }) => (actionIsOn ? '300px' : '0px'),
        '& img': {
            margin: '0 2px 0 2px',
            width: '56px',
            height: '56px',
        },
        backgroundColor: '#0071C2',
        display: 'flex',

        alignItems: 'flex-end',
    },
    priText: {
        width: '100%',
        margin: 'auto',
        fontWeight: 'bold',
    },
    secText: {
        margin: ({ isRtlDirection }) =>
            isRtlDirection ? 'auto 8% auto auto' : 'auto auto auto 8%',
        color: 'white',
        width: '100%',
    },
    fade: {
        animation: 'fadeIn .800s ease-in-out',
        animationDuration: '1.5s',
    },
    progress: {
        marginTop: '2.5%',
    },
    '@global': {
        '@keyframes fadeIn': {
            '0%': {
                opacity: 0,
            },
            '100%': {
                opacity: 1,
            },
        },
    },
}))