import { useState, ChangeEvent, KeyboardEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { userSelector } from 'redux/user/user-selector'
import { update_praise_expansions } from 'redux/praise/praise-actions'
import { initialAnswer, UserAnswer } from 'components/Bot/ActionButtons'
import { update_feedback_expansions } from 'redux/feedback/feedback-actions'

const useExpansions = (
	currentOutputId: string,
	isDraft: boolean,
	impactsExpansionData?: Array<string>,
	contributionExpansionsData?: string,
	isPraise?: boolean
) => {
	const [contributionExpansion, setContributionExpansion] = useState<string>(
		contributionExpansionsData!
	)

	const [impactsExpansion, setImpactsExpansions] = useState<Array<string>>(
		impactsExpansionData!
	)

	const [answer, setAnswer] = useState<UserAnswer>({
		...initialAnswer,
		expansionContribution: contributionExpansion,
		expansionImpacts: impactsExpansion,
	})
	const currentUserInfo = useSelector(userSelector)

	const dispatch = useDispatch()

	const onChangeExpansion = (
		section: ExpansionsEnum,
		{ target: { value } }: ChangeEvent<HTMLInputElement>,
		key?: number
	) => {
		if (section === ExpansionsEnum.IMPACTS) {
			let expansionsImpactsArray = [...impactsExpansion]
			expansionsImpactsArray[key!] = value
			setImpactsExpansions(expansionsImpactsArray)
		}
		if (section === ExpansionsEnum.CONTRIBUTION) {
			const contributionValue = value
			setContributionExpansion(contributionValue)
		}
	}

	const saveNewExpansion = (
		section: ExpansionsEnum,
		e?: KeyboardEvent<HTMLInputElement>
	) => {
		if (currentOutputId === ':id?') return
		if (e && !isEnterKeyDown(e?.key!)) return

		const expanstionToUpdate = {
			[ExpansionsEnum.CONTRIBUTION]: {
				expansionContribution: contributionExpansion,
			},
			[ExpansionsEnum.IMPACTS]: { expansionImpacts: impactsExpansion },
		}[section]

		const payload = {
			company_id: currentUserInfo.company_id,
			output_id: currentOutputId,
			expanstionToUpdate,
			type: isDraft ? 'draft' : 'sent',
		}
		updateExpansions(payload)
	}
	const isEnterKeyDown = (key: string) => {
		if (key === 'Enter') return true
		return false
	}
	// TODO:
	// Dispatch saving action to by feedback or praise -> OK
	// add in server feedback service the option to save expention -> OK
	// display expention on feedback receiver screen
	// test functionality

	const updateExpansions = (payload: any) => {
		const updateExpansionsFn = isPraise
			? update_praise_expansions
			: update_feedback_expansions

		dispatch(updateExpansionsFn(payload))
	}

	return {
		onChangeExpansion,
		saveNewExpansion,
		contributionExpansion,
		impactsExpansion,
	}
}

export default useExpansions

export enum ExpansionsEnum {
	CONTRIBUTION = 'contribution',
	IMPACTS = 'impacts',
}
