import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles<Theme, { isRtlDirection: boolean }>(
	({ breakpoints, palette }) => ({
		wrapper: {
			width: '469px',
			height: '80vh',
			overflow: 'auto',
			overflowX: 'hidden',
			'& > div': {
				display: 'flex',
			},

			[breakpoints.down('sm')]: {
				'& > div': {
					justifyContent: 'center',
				},
				display: 'flex',
				padding: 0,
				width: '100%',
				height: 'auto',
				overflow: 'auto',
			},
		},
		answerCard: {
			fontFamily: 'Sora, sans-serif',
			fontSize: '1rem',
			boxSizing: 'border-box',
			height: '98px',
			border: '2.8px solid rgba(49,49,49,0.15)',
			borderRadius: '14px',
			overflowWrap: 'break-word',
			cursor: 'pointer',

			backgroundColor: 'white',
			color: palette.text.secondary,

			display: 'flex',

			alignItems: 'center',
			padding: '0 12px 0 21px',
			'&:hover': {
				borderColor: '#0071C2',
				border: '2.8px solid #0071C2',
				boxShadow: '5px 5px 10px rgb(0 113 194 / 19%)',
			},

			[breakpoints.down('sm')]: {
				'&:hover': {
					border: '2.8px solid rgba(49, 49, 49, 0.152716)',
					boxShadow: 'none',
				},
			},
		},
		isChosed: {
			borderColor: '#0071C2',
			border: '2.8px solid #0071C2',
			boxShadow: '5px 5px 10px rgb(0 113 194 / 19%)',
		},
		regular: {
			borderColor: 'rgba(49,49,49,0.15)',
		},
		contextIconStyle: {
			width: '40px',
			height: '40px',
			marginRight: ({ isRtlDirection }) => (isRtlDirection ? '0' : '18px'),
			marginLeft: ({ isRtlDirection }) => (isRtlDirection ? '18px' : '0'),
		},
		previousButton: {
			color: '#0071C2',
			width: '224px',
			border: '2.36px solid #949494',
			cursor: 'pointer',
			height: '54px',
			fontSize: '20.4px',
			textAlign: 'center',
			fontWeight: 600,
			borderColor: '#0071C2',
			borderRadius: '13.6px',
			backgroundColor: '#FFFFFF',
		},
	})
)
