import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import IUser from 'interface/user/user-interface'
import { setItem } from 'utils/localStorage'
import {
	get_user,
	get_user_goals,
	sign_in_user,
	reset_password,
} from './user-actions'
import { create_goal_action } from 'redux/employee/employee-actions'

export type OutputFilterRule = {
	byEmployee?: string
	bySaydo?: boolean
	byBlockage?: boolean
}

const DEFAULT_ERROR_MESSAGE =
	'There was a problem logging in. Check your email and password or create an account.'

type BrifelyRef = Record<number, string>

export interface IUsersState {
	user: IUser
	formError: {
		field: string
		error: string
		status: number
	} | null
	error: string
	loading: boolean
	isLoggedIn: Boolean
	lastLang: string
	inputUserRef: string
	brifelyRef: BrifelyRef
	outputFilterRule: OutputFilterRule
	message: JSX.Element | string
	lessonCopied: string
}
const initialState: IUsersState = {
	loading: false,
	formError: null,
	error: '',
	user: {} as IUser,
	isLoggedIn: false,
	lastLang: '',
	inputUserRef: '',
	brifelyRef: {},
	outputFilterRule: { byEmployee: '', bySaydo: false, byBlockage: false },
	message: '',
	lessonCopied: '',
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser(state, action) {
			const user = action.payload
			return {
				...state,
				user,
			}
		},
		saveUserLanguage(state) {
			return {
				...state,
				lastLang: state.user.statusInfo.companyLanguage,
			}
		},
		signOutUser(state) {
			localStorage.removeItem('token')
			localStorage.removeItem('user')
			return {
				...state,
				loading: false,
				isLoggedIn: false,
				user: {} as IUser,
				inputUserRef: '',
			}
		},
		signInUser(state, action: PayloadAction<IUser>) {
			const user = action.payload
			setItem('token', user.securityInfo.token)
			return {
				...state,
				loading: false,
				isLoggedIn: true,
				user,
			}
		},
		editUser(state, action: PayloadAction<IUser>) {
			const user = action.payload
			return {
				...state,
				user,
			}
		},
		setError(state, action) {
			return {
				...state,
				error: action.payload,
			}
		},
		clearUserError(state) {
			return {
				...state,
				error: '',
			}
		},
		saveInputUser(state, action) {
			const input = action.payload
			return {
				...state,
				inputUserRef: input,
			}
		},
		initInputUser(state) {
			return {
				...state,
				inputUserRef: '',
			}
		},
		brieflyInput(state, action) {
			const answer = action.payload
			const { index, text } = answer
			return {
				...state,
				brifelyRef: {
					...state.brifelyRef,
					[index]: text,
				},
			}
		},
		clearBrieflyInputByIndex(state, action) {
			const answer = action.payload
			const { index } = answer
			let brieflyObject: BrifelyRef = { ...state.brifelyRef }

			brieflyObject[index - 1] = ''
			console.log({ index })
			return {
				...state,
				brifelyRef: {
					...brieflyObject,
				},
			}
		},

		clearBrieflyInput(state) {
			return {
				...state,
				brifelyRef: {},
			}
		},
		resetOutputFilter(state) {
			return {
				...state,
				outputFilterRule: { byEmployee: '', bySaydo: false, byBlockage: false },
			}
		},
		filterOutputsByEmployee(state, action) {
			const { byBlockage, byEmployee, bySaydo } =
				action.payload as OutputFilterRule
			return {
				...state,
				outputFilterRule: { byEmployee: byEmployee },
			}
		},
		filterOutputsBySaydo(state) {
			return {
				...state,
				outputFilterRule: { bySaydo: true },
			}
		},
		filterOutputsByBlockages(state) {
			return {
				...state,
				outputFilterRule: { byBlockage: true },
			}
		},
		resetMessage(state) {
			return {
				...state,
				message: '',
			}
		},
		lessonIsCopied(state, action) {
			const lesson_id = action.payload
			return {
				...state,
				lessonCopied: lesson_id,
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(create_goal_action.fulfilled, (state, action) => {
			const _action = action.payload
			// state.user = {
			// 	...state.user,
			// 	statusInfo: {
			// 		...state.user.statusInfo,
			// 		goalInfo: state.user.goalInfo.push(_action),
			// 	},
			// }
			state.loading = false
		})
		builder.addCase(create_goal_action.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(create_goal_action.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		// get_user
		builder.addCase(get_user.fulfilled, (state, action) => {
			state.isLoggedIn = true
			state.user = action.payload
			setItem('token', action.payload.securityInfo.token)
			state.loading = false
		})

		builder.addCase(get_user.pending, (state, action) => {
			state.loading = true
		})

		builder.addCase(get_user.rejected, (state, action: any) => {
			state.loading = false
			state.error = action.error.message
		})
		builder.addCase(sign_in_user.fulfilled, (state, action) => {
			state.isLoggedIn = true
			state.user = action.payload
			setItem('token', action.payload.securityInfo.token)
			state.loading = false
		})

		builder.addCase(sign_in_user.pending, (state, action) => {
			state.loading = true
		})

		builder.addCase(sign_in_user.rejected, (state, action: any) => {
			const { payload } = action ?? {}
			const { message = '' } = payload

			state.loading = false
			state.error = message.length ? message : DEFAULT_ERROR_MESSAGE
		})

		//get_user_goals
		builder.addCase(get_user_goals.fulfilled, (state, action) => {
			state.user.statusInfo.goals = [...action.payload]
			state.loading = false
		})
		builder.addCase(get_user_goals.pending, state => {
			state.loading = true
		})
		builder.addCase(get_user_goals.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		//reset password
		builder.addCase(reset_password.fulfilled, (state, action) => {
			state.loading = false
			state.message = action.payload.data
		})
		builder.addCase(reset_password.pending, state => {
			state.loading = true
		})
		builder.addCase(reset_password.rejected, (state, action) => {
			state.error = `${action.payload}`.split(':')[1]
			state.loading = false
		})
	},
})

export const userActions = userSlice.actions
export default userSlice.reducer
