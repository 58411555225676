import { FC, useContext } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Chip, Grid, GridSize, makeStyles, Theme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { IThemeOptions } from 'theme'
import { FolderOpen } from '@material-ui/icons'

import useStyles from '../../common/MostRecent/styles'
import useCardtStyles from '../../common/Card/styles'

import { AppDirectionContext } from 'context/languages-context'

import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'
import SendCard from 'components/common/MostRecent/SendCard'

import Paths from 'enums/routes/path-names'
import { Debrief } from 'interface/debrief/debrief-interface'
import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'

interface Props {
	handleClick: (debrief: Debrief) => void
	lessonsManagement: () => void
	lastGroupDebrief: Debrief
}
const MostRecent: FC<Props> = ({
	lastGroupDebrief,
	lessonsManagement,
	handleClick,
}) => {
	const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })

	const mostRecent = (
		<Typography className={classes.mostRecentTitle}>
			{t('debrief.most_recent')}
		</Typography>
	)

	const sendCard = (
		<SendCard
			screenToReturn={Paths.DEBRIEF_PROCESS}
			actionName={t('start_new_debriefing')}
		/>
	)

	const windowWidth = useWindowWidth()
	const mobileWidth = 600
	const isMobile = mobileWidth >= windowWidth

	return (
		<div className={classes.container}>
			<div className={classes.cardsContainer}>

				{isMobile && sendCard}

				<div className={classes.lessonsManagement} onClick={lessonsManagement}>
					<FolderOpen classes={{ root: classes.folderIcon }} />
					<p>{t('lessons_management')}</p>
				</div>

				{isMobile && mostRecent}

				<Card
					onClick={() => handleClick(lastGroupDebrief)}
					sm={12}
					md={5}
					cardClassName={classes.card}
					chipClassName={classes.chip}
					chipLabel={t('debrief.group')}
					lastDebrief={lastGroupDebrief}
				/>

				{!isMobile && sendCard}

			</div>
		</div>
	)
}

interface CardProps {
	cardClassName?: string
	chipClassName: string
	chipLabel: string
	lastDebrief: Debrief
	sm?: GridSize
	md?: GridSize
	onClick: () => void
}
const Card: FC<CardProps> = ({
	cardClassName,
	chipClassName,
	chipLabel,
	lastDebrief,
	sm,
	md,
	onClick,
}) => {
	const { t } = useTranslation()
	const date = lastDebrief && lastDebrief.createdAt

	const innerClasses = useCardInnerStyles()
	const classes = useCardtStyles({ date })

	const description = lastDebrief && lastDebrief.expectedResult?.description

	const lastDebriefLabel = lastDebrief?.step === DebriefingSteps.FINISH
		? t('debrief.card_summary')
		: t('debrief.card_continue')

	return (
		<Grid
			onClick={onClick}
			item
			sm={sm}
			md={md}
			className={`${cardClassName} ${classes.container} ${innerClasses.container}`}
		>
			<div className={`${classes.chipAndCoins} ${innerClasses.chips}`}>
				<Chip
					label={chipLabel}
					color={'primary'}
					className={`${classes.chip} ${chipClassName}`}
				/>
			</div>

			<p className={classes.date}>
				{date ? dayjs(date).format('DD/MM/YYYY') : '""'}
			</p>

			<p className={classes.description}>
				{lastDebrief && <div>{t('debrief.result_card')}</div>}
				{description
					? description
					: chipLabel === t('debrief.personal')
						? t(`debrief.personal_empty`)
						: t(`debrief.group_empty`)}
			</p>

			<div className={classes.toFromContainer}>
				{lastDebrief && (
					<div className={innerClasses.actionDescription}>
						{lastDebriefLabel}
					</div>
				)}
			</div>
		</Grid>
	)
}

export default MostRecent

const useCardInnerStyles = makeStyles<IThemeOptions & Theme>(
	({ breakpoints, palette }) => ({
		chips: {
			'& .MuiChip-root': {
				backgroundColor: palette.debrief.main,
			},
		},
		container: {
			'&:hover': {
				border: `2px solid ${palette.debrief.main}`,
			},
		},
		actionDescription: {
			color: palette.debrief.main,
			textDecoration: 'underline',
		},
	})
)
