import classes from './style.module.css'
import 'react-day-picker/lib/style.css'
import { GoalStatus } from 'enums/user/user-goal-status'
import { useTranslation } from 'react-i18next'

interface Props {
	status: GoalStatus
	editMode: boolean
	setStatus: (status: GoalStatus) => void
}
export const StatusSelect = ({ status, editMode, setStatus }: Props) => {
	const { t } = useTranslation()
	return (
		<select
			onClick={e => e.stopPropagation()}
			value={status}
			className={
				editMode ? `${classes.select}` : `${classes.select} ${classes.disabeld}`
			}
			style={{
				backgroundColor: editMode ? 'rgb(245, 245, 245)' : 'transparent',
				outline: 'none',
				borderBottom: `2px solid  ${
					status === GoalStatus.COMPLETED
						? '#34ab53'
						: status === GoalStatus.NOT_COMPLETED
						? '#ff4662'
						: status === GoalStatus.HALF_COMPLETED
						? '#0071c2'
						: '#ff4662'
				}`,
				pointerEvents: !editMode ? 'none' : 'unset',
				margin: '5% 0 0 auto',
			}}
			onChange={e => {
				e.stopPropagation()
				setStatus(e.target.value as GoalStatus)
			}}
		>
			<option value={GoalStatus.NOT_COMPLETED}>{t('incomplete')}</option>
			<option value={GoalStatus.COMPLETED}>{t('complete')}</option>
			<option value={GoalStatus.HALF_COMPLETED}>{t('partial')}</option>
		</select>
	)
}

export default StatusSelect
