import { createSlice } from '@reduxjs/toolkit'
import IUser from 'interface/user/user-interface'
import { ITeamTask, Team } from 'interface/teams/teams-interface'
import {
    get_team_tasks,
    update_team_tasks,
    toggle_task_done,
    delete_task,
    create_team_task
} from './teams-actions'
import { convertUsers } from 'utils/convertUsers'

export interface TeamsState {
    error: string
    loading: boolean
    team: Team<IUser>
    hasMoreTasks: boolean
    updated: boolean
}

const initialState: TeamsState = {
    error: '',
    loading: false,
    team: {} as Team<IUser>,
    hasMoreTasks: true,
    updated: false
}

export const teamsSlice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        clearUpdateProcess(state) {
            return {
                ...state,
                updated: false,
            }
        },
        updateTeamMembersByNewTask(state, action) {
            const { task, participants } = action.payload
            const taskConvertedByUsers = {
                ...task,
                employees_id: convertUsers(task.employees_id, participants),
                shouldShow: convertUsers(task.shouldShow, participants),
                isNew: convertUsers(task.isNew, participants),
                creator: participants.find((emp: IUser) => emp._id === task.creator)
            } as ITeamTask<IUser>
            state.updated = false
            state.team = { ...state.team, tasks: [taskConvertedByUsers, ...state.team.tasks] }
        },
        updateTaskDetails(state, action) {
            const { taskInfo, participants } = action.payload
            const taskConvertedByUsers = {
                ...taskInfo,
                employees_id: convertUsers(taskInfo.employees_id, participants),
                shouldShow: convertUsers(taskInfo.shouldShow, participants),
                isNew: convertUsers(taskInfo.isNew, participants),
                creator: participants.find((emp: IUser) => emp._id === taskInfo.creator)
            } as ITeamTask<IUser>
            const updatedTasksList = state.team.tasks.map((task: ITeamTask<IUser>) => {
                if (task._id == taskConvertedByUsers._id) return taskConvertedByUsers

                return task
            })
            state.updated = true
            state.team.tasks = updatedTasksList
        },
        markTaskDone(state, action) {
            const { taskInfo, participants } = action.payload

            const taskConvertedByUsers = {
                ...taskInfo,
                employees_id: convertUsers(taskInfo.employees_id, participants),
                shouldShow: convertUsers(taskInfo.shouldShow, participants),
                isNew: convertUsers(taskInfo.isNew, participants),
                creator: participants.find((emp: IUser) => emp._id === taskInfo.creator)
            } as ITeamTask<IUser>
            const updatedTasksList = state.team.tasks.map((task: ITeamTask<IUser>) => {
                if (task._id == taskConvertedByUsers._id) return taskConvertedByUsers

                return task
            })
            state.updated = true
            state.team.tasks = updatedTasksList
        },
        dispatchDeletedTask(state, action) {
            const task_id = action.payload

            state.team.tasks = [...state.team.tasks.filter((task: ITeamTask<IUser>) => task._id !== task_id)]
        }

    },
    extraReducers: builder => {
        builder.addCase(get_team_tasks.fulfilled, (state, action) => {
            state.loading = false

            const team = action.payload.data
            state.team = team
        })
        builder.addCase(get_team_tasks.pending, state => {
            state.loading = true
        })
        builder.addCase(get_team_tasks.rejected, (state, action) => {
            state.error = `${action.payload}`
            state.loading = false
        })

        builder.addCase(create_team_task.fulfilled, (state, action) => {
            state.loading = false
            const newTask = action.payload.data
            console.log({ newTask })
            state.team.tasks = state.team.tasks
            state.updated = false
        })
        builder.addCase(create_team_task.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(create_team_task.rejected, (state, action) => {
            state.error = `${action.payload}`
            state.loading = false
        })

        builder.addCase(update_team_tasks.fulfilled, (state, action) => {
            state.loading = false
            const taskInfo = action.payload.data
            const updatedTasksList = state.team.tasks.map((task: ITeamTask<IUser>) => {
                if (task._id == taskInfo._id) return taskInfo

                return task
            })
            state.updated = true
            state.team.tasks = updatedTasksList
        })
        builder.addCase(update_team_tasks.pending, state => {
            state.loading = true
        })
        builder.addCase(update_team_tasks.rejected, (state, action) => {
            state.error = `${action.payload}`
            state.loading = false
        })

        builder.addCase(toggle_task_done.fulfilled, (state, action) => {
            state.loading = false
            const updatedTask = action.payload.data

            state.team.tasks = state.team.tasks.map((task: ITeamTask<IUser>) => {
                if (task._id == updatedTask._id) return updatedTask

                return task
            })
        })
        builder.addCase(toggle_task_done.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(toggle_task_done.rejected, (state, action) => {
            state.error = `${action.payload}`
            state.loading = false
        })

        builder.addCase(delete_task.fulfilled, (state, action) => {
            const deleted_task_id = action.payload.data

            state.team.tasks = [...state.team.tasks.filter((task: ITeamTask<IUser>) => task._id !== deleted_task_id)]
            state.loading = false
        })
        builder.addCase(delete_task.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(delete_task.rejected, (state, action) => {
            state.loading = false
            state.error = `${action.error}`
        })
    }
})

export const teamsActions = teamsSlice.actions
export default teamsSlice.reducer
