import { makeStyles } from '@material-ui/core'
import { Theme } from '@mui/material'
import colors from 'utils/colors'

export type Styles = {
	matchPointColor: string
	isRtlDirection: boolean
	isLeader?: boolean
}
export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
	matchpointWrapper: {
		width: '64%',
		// height: '-webkit-fill-available',

		position: 'relative',
		left: ({ isRtlDirection }) => (isRtlDirection ? 0 : '20%'),
		right: ({ isRtlDirection }) => (isRtlDirection ? '18%' : 0),
		display: 'flex',
		//justifyContent: 'space-around',
		flexDirection: 'column',

		direction: ({ isRtlDirection }) => (isRtlDirection ? 'rtl' : 'ltr'),

		backgroundColor: '#fff',

		fontFamily: 'Sora, sans-serif',
		fontSize: '14px',
		[breakpoints.down('xs')]: {
			width: '94%',
			left: ({ isRtlDirection }) => 0, // (isRtlDirection ? 0 : '.5%')
			right: ({ isRtlDirection }) => 0, // (isRtlDirection ? '.5%' : 0)
		},
	},
	matchpointTitles: {
		height: '45px',

		padding: '10px',

		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',

		fontSize: '19px',
		'& span': {
			color: ({ matchPointColor }) => (matchPointColor ? matchPointColor : 'grey'),
		},
		'& >:last-child': {
			width: '14%',
			[breakpoints.down('xs')]: {
				width: '300px',
			},
		},
	},
	mpDescription: {
		width: ({ isRtlDirection }) => (!isRtlDirection ? '70%' : '75%'),

		'& >p': {
			// direction: ({ isRtlDirection }) => (isRtlDirection ? 'ltr' : 'rtl'),
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},
	},
	addNewLesson: {
		cursor: 'pointer',
		fontSize: '16px',
		margin: '0.5% 0 1% 1.5%',
		width: 'fit-content',
		color: ({ matchPointColor }) => (matchPointColor ? matchPointColor : 'grey'),
	},
	lessonContainer: {
		borderLeft: ({ isRtlDirection, matchPointColor }) =>
			isRtlDirection ? 'none' : `13px solid ${matchPointColor}`,
		borderRight: ({ isRtlDirection, matchPointColor }) =>
			isRtlDirection ? `13px solid ${matchPointColor}` : 'none',
		width: '96%',
		height: '45px',

		fontFamily: 'Sora, sans-serif',
		fontSize: '15px',
		fontWeight: 500,

		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
		margin: '0 auto 1px auto',
		flexDirection: 'row',

		color: 'grey',
		backgroundColor: '#f5f4f4e8',
	},
	description: {
		width: ({ isRtlDirection }) => (isRtlDirection ? '540px' : '650px'),
		height: '100%',

		borderRight: '1px solid white',
		paddingLeft: ({ isRtlDirection }) => (isRtlDirection ? 0 : '8px'),
		paddingRight: ({ isRtlDirection }) => (isRtlDirection ? '8px' : 0),
		display: 'flex',
		alignItems: 'center',
		[breakpoints.down('xs')]: {
			// width:
		},
		'& >span': {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			'&>span': {
				color: ({ matchPointColor }) =>
					matchPointColor ? matchPointColor : 'grey',
			},
		},
	},
	lessonInputField: {
		width: ({ isRtlDirection }) => (isRtlDirection ? '540px' : '650px'),
		border: 'none',
		outline: 'none',
		backgroundColor: 'inherit',
		caretColor: ({ matchPointColor }) =>
			matchPointColor ? matchPointColor : 'default',
		fontSize: '14px',
		paddingLeft: '8px',
		color: ({ matchPointColor }) => (matchPointColor ? matchPointColor : 'grey'),
		[breakpoints.down('xs')]: {
			// width:
		},
	},
	closeBtn: {
		width: '25px',
		height: '25px',
		cursor: 'pointer',
		[breakpoints.down('xs')]: {
			width: '20px',
			height: '20px',
		},
	},
	customLessonSection: {
		width: '22%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[breakpoints.down('xs')]: {
			width: '29%',
		},
	},
	ownerImage: {
		padding: '0 8px 0 8px',
		borderRight: '1px solid white',
		width: ({ isRtlDirection }) => (isRtlDirection ? '64px' : '32px'),
		[breakpoints.down('xs')]: {
			padding: 'none',
		},
	},
	avatarGroup: {
		direction: 'ltr',
		'& >:first-child': {
			// marginLeft: '-8px !important',
			width: '30px',
			height: '30px',
			[breakpoints.down('xs')]: {
				marginLeft: '7% !important',
			},
		},
	},
	avatar: {
		width: '30px',
		height: '30px',
	},
	mpInputField: {
		height: '100%',
		width: 'fit-content',
		outline: 'none',
		borderTop: 'none',
		borderRight: 'none',
		borderBottom: '1px solid',
		borderLeft: 'none',
		marginLeft: '1%',
		fontSize: '15px',
		fontFamily: 'Sora, sans-serif',
		caretColor: ({ matchPointColor }) =>
			matchPointColor ? matchPointColor : 'grey',
		borderBottomColor: ({ matchPointColor }) =>
			matchPointColor ? matchPointColor : 'grey',
		[breakpoints.down('xs')]: {
			marginLeft: 0,
		},
	},
	date: {
		paddingLeft: '8px',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		cursor: ({ isLeader }) => (isLeader ? 'pointer' : 'default'),
		[breakpoints.down('xs')]: {
			whiteSpace: 'normal',
		},
	},
	backgroundDeleteIcon: {
		backgroundColor: ({ matchPointColor }) =>
			matchPointColor ? matchPointColor : '#8EC5FC',
		backgroundImage: 'linear - gradient(62deg, #8EC5FC 0 %, #E0C3FC 100 %)',
	},
	deleteSpan: {
		position: 'relative',
		right: '0',
		width: '24px',
	},
}))
