import React, { useContext, useEffect, useState } from 'react'

import classes from './style.module.css'
import 'react-day-picker/lib/style.css'

import { IUserGoalState } from 'interface/user/user-interface'
import DatePicker from 'components/Bot/Modal/DatePicker'

import { useTranslation } from 'react-i18next'
import { AppDirectionContext } from 'context/languages-context'

import trashIcon from 'assets/trashIcon2.svg'
import archiveIcon from 'assets/archiveIcon.svg'
import restoreIcon from 'assets/restoreIcon.svg'

import { GOAL_LABEL, GoalCardTextArea } from '../GoalCardTextArea/index'
import { GoalCardBottomSection } from '../GoalCardBottomSection'
import { GoalCardDateSection } from '../GoalCardDateSection'

import { useGoalStateLogic } from './useGoalStateLogic'
import GoalCreateEditModal from '../GoalCreateEdit/GoalCreateEditModal'

interface Props {
	employee_id?: string
	goal: IUserGoalState
	goalNumber: number
	isOnEdit: boolean
	isNewEntry:boolean
	enableSavingForEmployee:boolean //this is a marker to hide save/discard and make all fields as readonly when opened from Employee Performance screen = false for employee screen true for manager screen.
	setItemOnEdit?: (itemId: string) => void
	onDelete?: (goalId: string) => void
	onToggleArchive?: (goalId: string) => void
	onSaveGoal: (updatedGoal: IUserGoalState) => void;
}

export const Goal = (props: Props) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const { t } = useTranslation()

	const {
		employee_id,
		goal,
		goalNumber,
		setItemOnEdit,
		isOnEdit: editMode,
		onDelete,
		onSaveGoal,
		onToggleArchive,
	} = props

	const {
		state,
		setValidationState,
		setGoalDescription,
		setGoalDate,
		setGoalStatus,
		setOpenDatepicker,
	} = useGoalStateLogic()
	const [isOpenGoalCreateEditModal, setOpenGoalCreateEditModal] = useState(props.isNewEntry)
	const handleOpen = () =>  !editMode && setOpenGoalCreateEditModal(true)

	const {
		datepickerIsOpen,
		status,
		validationError,
		description: goalDesc,
		date: goalDate,
	} = state

	useEffect(() => {
		if (!editMode) {
			setGoalStatus(goal.status)
			setGoalDescription(goal.description)
			setGoalDate(goal.dueDate)
		}
	}, [editMode])

	useEffect(() => {
		validationError &&
			setTimeout(() => {
				setValidationState()
			}, 700)
	}, [validationError])

	const closeEditMode = () => {
		if (!datepickerIsOpen) return
		setOpenDatepicker(false)
	}

	const editModeClose = (e: React.MouseEvent) => {
		if (editMode) {
			e.stopPropagation()
			closeEditMode()
		}
	}

	const onEditHandler = () => {
		closeEditMode()

		if (!goalDesc.trim() || !goalDate) {
			setValidationState(t('empty_field'))
			return
		}

		const changedDescription = goalDesc === goal.description
		const changedGoal = goalDate === goal.dueDate
		const changedStatus = status === goal.status

		const unChangedGoal = changedDescription && changedGoal && changedStatus

		if (unChangedGoal) {
			setItemOnEdit && setItemOnEdit('')
			return
		}

		const goalData: IUserGoalState = {
			...goal,
			description: goalDesc.trim(),
			dueDate: goalDate,
			status: status,
		}

		//if (goalDesc && goalDate) onSave && onSave(goalData)

		setItemOnEdit && setItemOnEdit('')
	}

	const openEditorHandler = () => {
		closeEditMode()
		setItemOnEdit && setItemOnEdit(goal._id)
	}

	const handleDateClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation()
		setOpenDatepicker(!datepickerIsOpen)
	}
	const goalHasDeadLine = goalDate !== undefined

	const deadLineIsOver = goalHasDeadLine ? isDeadLineIsDue(goalDate) : false

	const style = {
		backgroundColor: editMode ? '#f5f5f5' : 'transparent',
		color: deadLineIsOver ? 'tomato' : '#46484a',
	}
	const today = new Date()
	const NEW_GOAL = 'new'

	return (
		<>
			<div
				onClick={e => {
					editModeClose(e)
				}}
				className={isRtlDirection ? `${classes.goal} ${classes.rtl}` : classes.goal}
				style={{
					backgroundColor: deadLineIsOver
						? 'rgb(243.5, 243.5, 243.5)'
						: 'transparent',
				}}
			>
				{/*onSave &&*/ !editMode && (
					<>
						{goal._id !== NEW_GOAL && goal.archive && (
							<div
								className={classes.cardActionButton}
								onClick={() => onDelete && onDelete(goal._id)}
								title={t('Delete')}
							>
								<img
									style={{ opacity: 0.75 }}
									onClick={() => onDelete && onDelete(goal._id)}
									src={trashIcon}
									alt='delete'
								/>
							</div>
						)}
						{goal._id !== NEW_GOAL && (
							<div
								className={classes.cardActionButton}
								style={{
									right: isRtlDirection ? 'unset' : '',
									left: goal.archive ? '53%' : '45%',
								}}
								onClick={() => onToggleArchive && onToggleArchive(goal._id)}
								title={goal.archive ? t('restore') : t('archive')}
							>
								<img
									style={{ opacity: 0.75 }}
									onClick={() => onToggleArchive && onToggleArchive(goal._id)}
									src={goal.archive ? restoreIcon : archiveIcon}
									alt='restore'
								/>
							</div>
						)}
					</>
				)}

				<div className={classes.topContainer}>
					<div className={classes.goalNumber}>{goalNumber}</div>
					<div className={classes.editButtonsContainer}>
						{editMode && (
							<div
								className={classes.button}
								onClick={() => setItemOnEdit && setItemOnEdit('')}
							>
								{t('Discard')}
							</div>
						)}
						{/*onSave && (
							<div
								className={classes.button}
								onClick={() => (!editMode ? openEditorHandler() : onEditHandler())}
							>
								{validationError ? (
									<span className={classes.buttonError}>{validationError}</span>
								) : !editMode ? (
									t('Edit')
								) : (
									t('Save')
								)}
							</div>
								)*/}
					</div>
				</div>

				<div className={classes.mainContainer} onClick={handleOpen}>
					<div className={classes.description}>
						<GoalCardTextArea
							{...{
								label: GOAL_LABEL.description,
								editMode,
								goalDesc,
								datepickerIsOpen,
								setOpenDatepicker,
								setGoalDesc: setGoalDescription,
							}}
						/>
					</div>

					{datepickerIsOpen && editMode && (
						<div
							onClick={e => {
								e.stopPropagation()
							}}
							className={classes.datepicker}
						>
							<DatePicker
								disabledDays={{ before: today }}
								selectedDay={new Date()}
								handleDayClick={(day: Date) => {
									setGoalDate(day)
									setOpenDatepicker(false)
								}}
							/>
						</div>
					)}

					<GoalCardDateSection
						style={style}
						setStatus={status}
						editMode={editMode}
						status={status}
						goalDate={goalDate}
						handleDateClick={handleDateClick}
					/>
				</div>
				<GoalCardBottomSection goal={goal} />
			</div>

      <GoalCreateEditModal
        goalInitialValues={goal}
        onClose={() => setOpenGoalCreateEditModal(false)}
        isOpen={isOpenGoalCreateEditModal}
        onSaveModal={goal => {
          setOpenGoalCreateEditModal(false)
          onSaveGoal(goal)
        }}
        enableSavingForEmployee={props.enableSavingForEmployee}
      />
		</>
	)
}

export default Goal

const isDeadLineIsDue = (d: Date) =>
	new Date().setHours(0, 0, 0, 0) > new Date(d).setHours(0, 0, 0, 0)
