import React, { useState, useCallback, useContext } from 'react'
import './GoalCreateEdit.css'
import { IUserGoalItem, IUserGoalState } from 'interface/user/user-interface'
import { GoalStatus } from 'enums/user/user-goal-status'
import { GOALITEMSTATUS, GoalItemAction } from '../GoalModal/GoalsTable/types'
import GoalFormItem from './GoalCreateEditModal/GoalFormItem'
import { AppDirectionContext } from 'context/languages-context'
import { useTranslation } from 'react-i18next'

export interface GoalCreateEditProps {
	goal: IUserGoalState
	onDiscardChanges?: () => void //callback to close the popup without changes
	onSave?(updatedGoal: IUserGoalState): void
	enableSavingForEmployee: boolean
}

const GoalCreateEdit: React.FC<GoalCreateEditProps> = ({
	goal,
	enableSavingForEmployee,
	onDiscardChanges,
	onSave,
}) => {
  const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const [goalDescription, setGoalDescription] = useState(goal.description)
	const [dueDate, setEndDate] = useState(goal.dueDate)
	const [status, setStatus] = useState(goal.status)
	const [isSaveDisabled, enableSaveButton] = useState(false)
	// goal info
	const [managerNeeds, setManagerNeeds] = useState(goal.goalInfo?.needs || [])
	const [index, setIndex] = useState(goal.goalInfo?.indexs || [])
	const [actionPlan, setActionPlan] = useState(goal.goalInfo?.requiredActions || [])

	const handleSave = () => {

		function removeObjectIDForNewEntries(obj: IUserGoalItem) {
			// Check if _id exists and starts with "new"
			//if (obj._id && obj._id.startsWith("new")) {
				if (obj._id) { //remove always
				const { _id, ...rest } = obj;
				return rest; // Return object without _id
			}
			return obj; // Return the object unchanged if condition is not met
		}

		const updatedGoal = {
			...goal,
			dueDate,
			description: goalDescription,
			status,
			goalInfo: {
				needs: managerNeeds.map(removeObjectIDForNewEntries),
				indexs: index.map(removeObjectIDForNewEntries),
				requiredActions: actionPlan.map(removeObjectIDForNewEntries),
			},
		}
		console.log('updatedGoal: ', updatedGoal);
		onSave?.(updatedGoal)
	}

	const handleAddItem = useCallback(
		(setState: React.Dispatch<React.SetStateAction<IUserGoalItem[]>>) => {
			setState(prevItems => {
				let arrLength: number = prevItems.length

				return [
					...prevItems,
					//this one is for the useGoalsv2
					//{need: '', status: GOALITEMSTATUS.UNCHECKED, action: GoalItemAction.CREATE},

					//this one is for saveing entire goal object.
					{_id: "new_" + (arrLength++).toString(), need: '', status: GOALITEMSTATUS.UNCHECKED}
				]
			})
		},
		[]
	)

	const handleDeleteItem = (
		_id: number,
		setState: React.Dispatch<React.SetStateAction<IUserGoalItem[]>>
	) => {
		//the below is for useGoals2 - to determine which items to be deleted - for now not needed but should stay commented as reference
		//setState(prevItems => prevItems.map(item => item._id === _id ? { ...item, action: GoalItemAction.DELETE } : item));

		//below setstate is used for the whole goal update (like done on Mangers screen)
		//this for now is used for both screens (employee and manager)
		//but could be changed if in the future this saving of entire array of goals will be suboptimal. then you should switch to useGoalsv2 and refactor the code accordingly.
		//setState(prevItems => prevItems.filter(gitem => gitem._id !== _id))

		//remove by index.
		setState(prevItems => prevItems.filter((item, index) => index !== _id))
		enableSaveButton(true) //enableSaveButton
	}

	const handleUpdateItem = (
		updatedItem: IUserGoalItem,
		_id:number,//index
		setState: React.Dispatch<React.SetStateAction<IUserGoalItem[]>>
	) => {

		setState(prevItems => {
			const changedItemState = prevItems.map((item, index) =>index === _id ? updatedItem : item)
			enableSaveButton(true)
			return changedItemState
		})
	}

	const renderFormItem = (
		items: IUserGoalItem[],
		setItems: React.Dispatch<React.SetStateAction<IUserGoalItem[]>>,
		label: string,
		enableItemEdit: boolean
	) => (
		<div className='form-group'>
			<label className='form-label'>
				{enableItemEdit && (
					<button className={`button button-save ${isRtlDirection && 'rtl'}`} onClick={() => handleAddItem(setItems)}>
						+
					</button>
				)}
				{label}:
			</label>

			{items
			//.filter(item => !item.action || item.action !== GoalItemAction.DELETE) //filter only those that are not marked as DELETE
			.map((item: IUserGoalItem, index: number) => (
				<GoalFormItem
					initialState={{_id: index.toString(), ...item}}
					key={index}
					onChange={updatedItem => handleUpdateItem(updatedItem,index, setItems)}
					onDelete={() => handleDeleteItem(index, setItems)}
					enableUpdate={enableItemEdit}
				/>
			))}
		</div>
	)

  console.log('asddsa', t('due_summary'));

	return (
		<div className='form-container' style={{
      direction: isRtlDirection ? 'rtl' : 'ltr',
    }}>
			<h1>{t('goal_table')}</h1>

			<div className='form-group'>
				<label htmlFor='goal-input' className='form-label'>
					{t('goal_description')}<span className='error-message'>*</span>:
				</label>
				<textarea
					id='goal-input'
					name='goal-input'
					disabled={!enableSavingForEmployee}
					value={goalDescription}
					onChange={e => {
						setGoalDescription(e.target.value)
						enableSaveButton(true)
					}}
					className='textarea-field'
				/>
			</div>

			<div className='form-group form-group--horizontal'>
        <div>
          <label htmlFor='dateInput' className='form-label'>
            {t('due_date')}<span className='error-message'>*</span>:
          </label>
          <input
            type='date'
            id='dateInput'
            name='dateInput'
            disabled={!enableSavingForEmployee}
            value={dueDate ? new Date(dueDate).toISOString().split('T')[0] : ''}
            onChange={e => {
              setEndDate(new Date(e.target.value))
              enableSaveButton(true)
            }}
            className='input-field'
          />
        </div>

        <div>
          <label htmlFor='statusInput' className='form-label'>
            {t('status')}<span className='error-message'>*</span>:
          </label>
          <select
            id='statusInput'
            name='statusInput'
            disabled={!enableSavingForEmployee}
            value={status}
            onChange={e => {
              setStatus(e.target.value as GoalStatus)
              enableSaveButton(true)
            }}
            className='input-field-select'
          >
            <option value=''>{t('select_status')}</option>
            <option value='not_completed'>{t('incomplete')}</option>
            <option value='completed'>{t('complete')}</option>
            <option value='half_completed'>{t('partial')}</option>
          </select>
        </div>
			</div>

			<div className='form-scrollable'>
				{renderFormItem(managerNeeds, setManagerNeeds, t('manager_needs'), true)} {/* True means you can always edit and change the items, you can make it condiational with  enableSavingForEmployee */}
				{renderFormItem(index, setIndex, t('success_index'), true)}
				{renderFormItem(actionPlan, setActionPlan, t('action_plan'), true)}
			</div>
			{enableSavingForEmployee && (
				<p className='error-message'>
					<span className='error-message'>*</span>Mandatory field
				</p>
			)}

			<div className='form-buttons'>
        <button
          className={`save-button ${!isSaveDisabled ? 'disabled' : ''} ${isRtlDirection && 'rtl'}`}
          onClick={handleSave}
          disabled={!isSaveDisabled}
        >
          {t('save')}
        </button>
				<button className={`discard-button ${isRtlDirection && 'rtl'}`} onClick={onDiscardChanges}>
          {t('Discard')}
				</button>
			</div>
		</div>
	)
}

export default GoalCreateEdit
