import { Box, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"

import './style.css'
import { useSelector } from "react-redux"
import { userSelector } from "redux/user/user-selector"
import { Roles } from "enums/user/user-roles"
import { companyLessonsSelector } from "redux/company/company.selector"

type Props = {
    folder_id?: string | null
    editFolder?: (_id?: string | null) => void
    deleteFolder?: (_id?: string | null) => void
}
const FolderOption = ({ deleteFolder, editFolder, folder_id }: Props) => {
    const { t } = useTranslation()

    const user = useSelector(userSelector)
    const companyFolders = useSelector(companyLessonsSelector)

    const userRole = user.statusInfo.role
    const isHightLevelFolder = companyFolders._id === user.company_id

    const enableManagerToDelete = userRole === Roles.MANAGER && !isHightLevelFolder

    const enableHrToDelete = userRole === Roles.HR

    const showDeleteOption = enableHrToDelete || enableManagerToDelete

    return (
        <Box className='folder-options'>
            {/* <Button onClick={shareFolder}>share</Button> */}
            <Button onClick={() => editFolder?.(folder_id)}>{t('edit')}</Button>
            {showDeleteOption && <Button onClick={() => deleteFolder?.(folder_id)}>{t('delete')}</Button>}
        </Box>
    )
}

export default FolderOption