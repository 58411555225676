import { ModalOperations } from "enums/lessonsManagement/index"

type Key = 'placeholder' | 'label'
export type nameRecord = Record<Key, string>

export const modalOperationsMapped = new Map<ModalOperations, ModalHeadersTypes>([
    [ModalOperations.NEW_TITLE, {
        name: [
            {
                placeholder: '',
                label: ModalOperations.NEW_TITLE
            }
        ],
        title: 'create_title'
    }],

    [ModalOperations.EDIT_FOLDER, {
        name: [
            {
                placeholder: 'folder_name',
                label: 'new_folder'
            }],
        title: ModalOperations.EDIT_FOLDER
    }],

    [ModalOperations.EDIT_FILE, {
        name: [
            {
                placeholder: 'file_name',
                label: 'new_file'
            }],
        title: ModalOperations.EDIT_FILE
    }],

    [ModalOperations.NEW_FOLDER, {
        name: [
            {
                placeholder: '',
                label: ModalOperations.NEW_FOLDER
            }],
        title: 'create_folder'
    }],

    [ModalOperations.NEW_FILE, {
        name: [
            {
                placeholder: '',
                label: ModalOperations.NEW_FILE
            }],
        title: 'create_file'
    }],

    [ModalOperations.NEW_LESSON, {
        name: [
            {
                placeholder: '',
                label: 'choose_title'
            },
        ],
        title: 'attach_to_title'
    }],

    [ModalOperations.ATTACHED, {
        name: [
            {
                placeholder: "chosse_title",
                label: 'choose_title_label'
            },
            {
                placeholder: '',
                label: 'choose_title'
            },
        ],
        title: 'attach_to_title'
    }],

    [ModalOperations.DELETE_FOLDER, {
        name: [
            {
                placeholder: '',
                label: 'delete'
            },
        ],
        title: 'critical_delete_folder'
    }],

    [ModalOperations.DELETE_FILE, {
        name: [
            {
                placeholder: '',
                label: 'delete'
            },
        ],
        title: 'critical_delete_file'
    }]
])


export function getModalHeaders(operation: ModalOperations, placeholder?: string, label?: string): ModalHeadersTypes {
    const mapping = modalOperationsMapped.get(operation)!

    if (mapping && placeholder) {
        mapping.name.forEach((name: nameRecord) => {
            name.placeholder = placeholder
        })
        return mapping
    }
    if (mapping && label) {
        if (ModalOperations.DELETE_FOLDER) {
            mapping.name.forEach((name: nameRecord) => {
                name.label = `to confirm, type ${label} in the box below`
            })
            return mapping
        }
        return mapping
    }
    return mapping
}

type ModalHeadersTypes = {
    name: nameRecord[]
    title: string
}