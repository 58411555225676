import { FC, useMemo } from 'react'
import useStyles from './style'
import logo from 'assets/Logowithtext.svg'
import { getCalcedPercents } from 'utils/progressCalculator'
import { useSelector } from 'react-redux'
import { getBotAnswers } from 'redux/bot/bot-selector'
import { UseClickActions } from '../../../Bot/useHooks/index'
// @ts-ignore
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'
import { CloseIcon } from 'components/common/CloseIcon'

const D = <div></div>
type LineProgressBarType = {
	probability: string
}
export const LineProgressBar: FC<LineProgressBarType> = ({ probability }) => {
	const classes = useStyles()
	return (
		<div className={classes.pregressContainer}>
			<Progress
				percent={probability}
				status='success'
				theme={{
					default: {
						symbol: D,
						color: '#34AB53',
					},
					success: {
						symbol: D,
						color: '#34AB53',
					},
				}}
			/>
		</div>
	)
}

const BotHeader: FC<{ isPraise: boolean }> = ({ isPraise }) => {
	const classes = useStyles()
	const { answers } = useSelector(getBotAnswers)
	const calculatedStepToPercent = useMemo(
		() => getCalcedPercents(answers.length),
		[answers]
	)
	const { closeAction: handleClose } = UseClickActions(isPraise)

	return (
		<div className={classes.botHeaderWrapper}>
			<div id='botHeader' className={classes.conatainer}>
				<img
					className={classes.logo}
					onClick={() => handleClose()}
					src={logo}
					alt='SolveLogo'
				/>
				<CloseIcon handleClick={handleClose} />
			</div>
			<div className={classes.progressBarWrpaper}>
				<div className={classes.progressBar}>
					<LineProgressBar probability={calculatedStepToPercent.toString()} />
				</div>
				{/* <div className={classes.percentSign}>{calculatedStepToPercent}%</div> */}
			</div>{' '}
		</div>
	)
}

export default BotHeader
