export enum URLS {
	DEBRIEF = 'api/debriefing',
	AUTHENTICATE = 'api/auth',
	SCHEDULER = 'api/schedulerCron',
	USER = 'api/user',
	EMPLOYEE = 'api/employee',
	GOALS = 'api/goals',
	TASK = 'api/task',
	FEEDBACK = 'api/feedback',
	PRAISE = 'api/praise',
	NOTIFICATION = 'api/notification',
	COMPANY = 'api/company',
	COMPANY_TODOS = 'api/todos',
	COMPANY_PRAISES = 'api/companyPraises',
	COMPANY_FEEDBACKS = 'api/companyFeedbacks',
	COMAPNY_VALUES = 'api/values',
	COMAPNY_DEPARTMENTS = 'api/departments',
	COMPANY_LICENSE = 'api/license',
	COMPANY_EXCEPTIONS = 'api/exceptions',
	COMPANY_LAUNCHER = 'api/companyLauncher',
	CONTACT_US = 'api/contact',
	PRAISE_OUTPUT_SETTINGS = 'api/praise-output',
	FEEDBACK_OUTPUT_SETTINGS = 'api/feedback-output',
	PRAISE_IMPACTS_OUTPUT_SETTINGS = 'api/praise-impacts',
	FEEDBACK_IMPACTS_OUTPUT_SETTINGS = 'api/feedback-impacts',
	BOT_AUTOMATION = 'bot-automation',
	BOT_LOGS = 'api/botlogs',
	COMPANY_TASKS = 'api/tasks',
	COMPANY_TEAM = 'api/company-team-todos',
	COMPANY_LESSONS = 'api/lessons-folders-management'
}
