import { FC } from 'react'
import { ScreenWrapper } from '../../components/common/LoginAndChangePassword/index'

const LoginScreen: FC = () => {
	return (
		<ScreenWrapper>
			<ScreenWrapper.Login />
		</ScreenWrapper>
	)
}

export default LoginScreen
