import { createSelector } from 'reselect'
import { IStoreRootState } from 'interface/redux'

export const praiseStateSelector = (state: IStoreRootState) => state.praise

export const praisesLoadingSelector = createSelector(
	praiseStateSelector,
	state => state.loading
)

export const companyPraisesSelector = createSelector(
	praiseStateSelector,
	state => state.companyPraises || []
)

export const companyPraisesTemplatesSelector = createSelector(
	praiseStateSelector,
	state => state.templates || null
)

export const sentPraisesSelector = createSelector(
	praiseStateSelector,
	state => state.sentPraises || []
)

export const receivedPraisesSelector = createSelector(
	praiseStateSelector,
	state => state.receivedPraises || []
)
export const draftsPraisesSelector = createSelector(
	praiseStateSelector,
	state => (state.drafts?.length ? state.drafts : [])
)

export const searchPraisesSelector = createSelector(
	praiseStateSelector,
	state => (state.searchResult?.length ? state.searchResult : [])
)

export const isAdditionalPraiseQuestionLoading = createSelector(
	praiseStateSelector,
	state => state.additionalPraiseQuestionLoading
)

// export const allReceivedPraisesLengthSelector = createSelector(
// 	praiseStateSelector,
// 	state => state.allReceivedPraisesLength
// )

export const getCurrentPraiseQuestionSelectore = createSelector(
	praiseStateSelector,
	state => state.currentpraiseQuestion
)

export const getaAdditionalPraiseQuestionSelector = createSelector(
	praiseStateSelector,
	state => state.additionalPraiseQuestion
)
export const moreSentPraisesSelector = createSelector(
	praiseStateSelector,
	state => state.hasMoreSentPraises
)
export const moreReceivedPraisesSelector = createSelector(
	praiseStateSelector,
	state => state.hasMoreReceivedPraises
)
export const moreDraftsPraisesSelector = createSelector(
	praiseStateSelector,
	state => state.hasMoreDraftsPraises
)
export const moreOnWallPraisesSelector = createSelector(
	praiseStateSelector,
	state => state.hasMoreOnWallPraises
)
