import { useTranslation } from "react-i18next"
import classes from './styles.module.css'
import dayjs from "dayjs"
import { getDaysDiff } from "utils/getDiffDates"
import { useContext } from "react"
import { AppDirectionContext } from "context/languages-context"

type Props = {
    dueDate: Date | undefined
    createdAt: Date | undefined
}
const TodoTimes = ({ createdAt, dueDate }: Props) => {
    const { t } = useTranslation()
    const { isRtlDirection } = useContext(AppDirectionContext)

    const dueDateFormat = dayjs(dueDate).format('DD/MM/YYYY')
    const displayDueDate = dueDate ?
        `${t('task_due_date')} ${dueDateFormat}`
        : t('on_going_task')

    const createOn = dayjs(createdAt).format('DD.MM.YY')

    const TODAY_DATE = new Date(Date.now()).toISOString()
    const amountOfDaysLeft = getDaysDiff(TODAY_DATE, `${dueDate}`)
    const taskIsStillValidDate = amountOfDaysLeft > 0
    const dueDatePassed =
        taskIsStillValidDate || !dueDate ? 'rgba(70, 72, 74, 1)' : 'red'

    return (
        <div className={!isRtlDirection ? classes.todoTimes : [classes.todoTimes, classes.todoTimesRtl].join(' ')}>

            <p>{t('created_on')} <span>{createOn}</span></p>

            <span style={{ color: dueDatePassed }}>{displayDueDate}</span>

        </div>
    )
}

export default TodoTimes