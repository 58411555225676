export const theFeedbackIsAboutReceiver = {
	1: '%NAME% wants to provide feedback about the response she received from you %CONTEXT% %DATE%.',
	2: '%NAME% wants to provide feedback on the response she expected to receive from you.',
	3: '%NAME% wants to provide feedback on the level of collaboration with her %CONTEXT% %DATE%.',
	4: '%NAME% wants to provide feedback on the collaboration she expected to receive from you %CONTEXT% %DATE%.',
	5: '%NAME% wants to provide feedback about an unkept agreement with her %DATE%.',
	6: '%NAME% wants to provide feedback about not delivering on time %DATE%.',
	7: '%NAME% wants to provide feedback about not following procedure %DATE%.',
	8: '%NAME% wants to provide feedback about a goal was not met %DATE%.',
	9: '%NAME% wants to provide feedback on something you said %CONTEXT% %DATE%.',
	10: '%NAME% wants to provide feedback on something you wrote %CONTEXT% %DATE%.',
	11: '%NAME% wants to provide feedback because you %REASON% during %CONTEXT% %DATE%.',
	12: '%NAME% wants to provide feedback about %REASON% during %CONTEXT%  %DATE%.',
	13: '%NAME% wants to provide feedback that you %REASON% during a %CONTEXT% %DATE%.',
	14: '%NAME% wants to provide feedback about partial information sharing during a %CONTEXT% %DATE%.',
	15: '%NAME% wants to provide feedback about the delayed sharing of information during a %CONTEXT% %DATE%.',
	16: '%NAME% wants to provide feedback about the retrospective sharing of information after it was shared first with someone else during a %CONTEXT% %DATE%.',
	17: '%NAME% wants to provide feedback about not sharing information.',
	18: '%NAME% wants to provide feedback about receiving a partial report during %CONTEXT% %DATE%.',
	19: '%NAME% wants to provide feedback about receiving a report late during a %CONTEXT% %DATE%.',
	20: '%NAME% wants to provide feedback about receiving a report retrospectively after it was passed on to someone else during a %CONTEXT% %DATE%.',
	21: '%NAME% wants to provide feedback about not receiving a report.',
	22: '%NAME% wants to provide feedback about not being involved in the decision-making process under her responsibility.',
	23: '%NAME% wants to provide feedback about being partially involved in the decision-making process under her responsibility during a %CONTEXT% %DATE%.',
	24: '%NAME% wants to provide feedback about praise expected to be received by %PARTICIPANTS% during a %CONTEXT%  %DATE%.',
	25: '%NAME% wants to provide feedback about feedback not received %PARTICIPANTS% during a %CONTEXT%  %DATE%.',
	26: '%NAME% wants to provide feedback about feedback and praise not received by %PARTICIPANTS% during a %CONTEXT%  %DATE%.',
	27: '%NAME% wants to provide feedback about unclear praise received by %PARTICIPANTS% during a %CONTEXT%  %DATE%.',
	28: '%NAME% wants to provide feedback about unclear feedback received by %PARTICIPANTS% during a %CONTEXT%  %DATE%.',
	29: '%NAME% wants to provide feedback about unclear feedback and praise received by %PARTICIPANTS% during a %CONTEXT%  %DATE%.',
	30: '%NAME% wants to provide feedback about setting expectations partially during a %CONTEXT% %DATE%.',
	31: '%NAME% wants to provide feedback about expectations not set during a %CONTEXT% %DATE%.',
}

export const feedbackFreeTextDescriptionsReceiver = {
	1: 'Description of the response or outcome received from %NAME%',
	2: '',
	3: 'Description of the collaboration with %NAME%',
	4: '',
	5: 'The agreement you had',
	6: 'The deadline set',
	7: 'The procedure',
	8: 'The goal',
	9: 'The things %NAME% said',
	10: 'The things %NAME% wrote',
	11: '',
	12: '',
	13: '',
	14: 'The information which was partially shared',
	15: 'The information which was shared by delay',
	16: 'The information which was shared retrospectively',
	17: 'The information which was not shared',
	18: 'The subject of the report',
	19: 'The subject of the report',
	20: 'The subject of the report',
	21: 'The subject of the report',
	22: 'The decision made',
	23: 'The decision made',
	24: 'The subject on which you expected praise',
	25: 'The subject on which you expected feedback',
	26: 'The subject on which you expected praise and feedback',
	27: 'The subject on which you were praised unclearly',
	28: 'The subject on which you were given unclear feedback',
	29: 'The subject on which you were given unclear praise and feedback',
	30: 'The subject of the expectations partially set together',
	31: 'The subject of the expectations that were not set together',
}

export const feedbackContributionToSuccessReceiver = {
	1: 'According to %NAME%, because of your response to %NAME%, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	2: "According to %NAME%, because she didn't receive a response, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.",
	3: 'According to %NAME%, because of the level of collaboration between you, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	4: 'According to %NAME%, because there was not enough collaboration, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%',
	5: 'According to %NAME%, because she thinks the agreement was not kept, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%',
	6: 'According to %NAME%, because she thinks the delivery was not on time, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	7: 'According to %NAME%, because she thinks the procedure was not followed, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	8: 'According to %NAME%, because she thinks a goal was not met, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	9: 'According to %NAME%, because of what you said, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	10: 'According to %NAME%, because of what you wrote, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	11: 'According to %NAME%, because you (x), her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	12: 'According to %NAME%, because you (x), her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	13: 'According to %NAME%, because you (x) in a (y), her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	14: 'According to %NAME%, because the information was shared partially, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	15: 'According to %NAME%, because there was a delay in sharing information, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	16: 'According to %NAME%, because there was a retrospective sharing of information after it was shared first with someone else, her ability to achieve her goal goal decreased by %PERCENT%%.',
	17: 'According to %NAME%, because information was not shared, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	18: 'According to %NAME%, because she received a partial report, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	19: 'According to %NAME%, because she received a report late, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	20: 'According to %NAME%, because she received a report retrospectively after it was passed on to someone else, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	21: "According to %NAME%, because she didn't receive a report, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.",
	22: 'According to %NAME%, because she was not involved in the decision-making process, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	23: 'According to %NAME%, because she was only partially involved in the decision-making process, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	24: 'According to %NAME%, because praise was not given, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	25: 'According to %NAME%, because feedback was not received, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	26: 'According to %NAME%, because no feedback and praise was received, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	27: 'According to %NAME%, because unclear praise was given, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	28: 'According to %NAME%, because unclear feedback was received, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	29: 'According to %NAME%, because unclear feedback and praise were received, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	30: 'According to %NAME%, because expectations were not set, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
	31: 'According to %NAME%, because expectations were set partially, her ability to achieve her goal (%GOAL%) decreased by %PERCENT%%.',
}

export const furtherImpactFeedbackReceiver: Record<string, string> = {
	energy: '%NAME% made you expend unnecessary energy in relation to %IMPACTED%',
	confidence: '%NAME% reduced your sense of security in relation to %IMPACTED%',
	'further apart': '%NAME% made you feel further apart',
	reputation: "%NAME% harmed your professional reputation %IMPACTED%'s eyes.",
	meaningful: '%NAME% made you feel less meaningful',
	belonging:
		'%NAME% reduced your feeling of belonging in relation to %IMPACTED%',
	responsibility:
		'%NAME% reduced your ability to initiate and take on more responsibility in front of %IMPACTED%',
	develop: '%NAME% reduced your ability to learn and develop yourself',
}
