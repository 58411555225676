import {
	BaseCompanyOutput,
	Context,
	OutputBeforeAfter,
	PraiseOutput,
} from '../../interface/bot/index'
import { furtherImpact } from './praiseBeforeSend'
import { QuestionType } from 'interface/praise/praise-interface'

import { furtherImpactFeedback } from './feedbackBeforeSend'

import IUser from '../../interface/user/user-interface'

import { furtherImpactPraiseReceiver } from './praiseReceiver'
import { furtherImpactFeedbackReceiver } from './feedbackReceiver'
import { hebrewDateFormat } from 'components/BotOutput'
import { OutputType } from 'screens/BotOutput'
import { PraiseAndFeedbacksType } from 'enums/praise-state/praise-state.enum'

type BuildReplace = Omit<
	BaseCompanyOutput<unknown>,
	'isDraft' | 'createdAt' | '_id'
> & {
	sender: IUser
	receiver: IUser
}

export const getParticipantsName = (
	participants: IUser[],
	connectivityWord: string = 'and'
): string => {
	const participantsFirstName = participants?.map((participant, i, row) => {
		if (i + 1 === row.length) {
			return `${connectivityWord} ${participant.personalInfo.firstName}`
		}
		if (i + 1 === 1) {
			return `${participant.personalInfo.firstName}`
		}

		return `,${participant.personalInfo.firstName}`
	})
	return participantsFirstName ? participantsFirstName.join(' ') : 'you'
}

export const getListType = (type: OutputType) => {
	if (type === OutputType.PraiseViewing) {
		return PraiseAndFeedbacksType.SENT
	}
	if (type === OutputType.PraiseReciever) {
		return PraiseAndFeedbacksType.RECEIVED
	}
	if (type === OutputType.PraiseDraft) {
		return PraiseAndFeedbacksType.DRAFT
	}
	return PraiseAndFeedbacksType.SENT
}

export const getReplacementObj = (praises: Array<PraiseOutput<IUser>>) => {
	const FIRST = 0
	if (!praises[FIRST]) return { contributions: '' } as any

	return {
		...praises[FIRST],
		sender: praises[FIRST]?.sender,
		receiver: praises[FIRST]?.sender,
	}
}

// before send: %PERCENT% the chances of achieving your goal %GOAL%.
// receiver:
export const buildReplacment = <T extends BuildReplace>(
	userAnswer: T,
	isReciever?: boolean,
	isRtlDirection?: boolean,
	connectivityWord: string = 'and'
) => {
	const {
		contributions = '',
		contributionExpansion,
		impactsExpansion,
	} = userAnswer
	const hasRontributions = contributions.length
	const splitBy = '&percent='
	const contributionsArr = hasRontributions
		? userAnswer.contributions?.split(splitBy)
		: []

	const [GOAL = '', PERCENT = ''] = Array.from(contributionsArr)

	const senderName = userAnswer?.sender
		? userAnswer.sender.personalInfo?.firstName
		: ''

	const receiverName = userAnswer?.receiver
		? userAnswer.receiver?.personalInfo?.firstName ?? ''
		: ''

	const USERNAME = isReciever ? senderName : receiverName

	const participantsFirstName = getParticipantsName(
		userAnswer?.participants ?? [],
		connectivityWord
	)

	const date = new Date(userAnswer?.time ?? '').toDateString()

	return {
		'%REASON%': userAnswer?.reason ?? '',
		'%NAME%': USERNAME,
		'%CONTEXT%': '',
		'%GOAL%': GOAL,
		'%PERCENT%': PERCENT ? PERCENT : '',
		'%PARTICIPANTS%': participantsFirstName ? participantsFirstName : ' ',
		'%IMPACTED%': '',
		'%DATE%': isRtlDirection ? hebrewDateFormat(date) : date,
	}
}

export const replaceFunction = (
	mapper: Record<number | string, string>,
	replacements: Record<string, string>,
	key: keyof OutputBeforeAfter | string | number
) => {
	if (!mapper || !mapper[key]) return ''
	return mapper[key].replace(/%\w+%/g, function (all: string) {
		return replacements[all] || all
	})
}
const getImpactTextBeforeMap = (
	isReceiver: boolean,
	isPraise: boolean
): Record<number, string> => {
	const isSenderPraiseImpact = !isReceiver && isPraise
	const isReceiverPraiseImpact = Boolean(isReceiver) && isPraise
	const isSenderFeedbackImpact = !isReceiver && !isPraise

	if (isSenderPraiseImpact) return furtherImpact
	if (isReceiverPraiseImpact) return furtherImpactPraiseReceiver
	if (isSenderFeedbackImpact) return furtherImpactFeedback

	return furtherImpactFeedbackReceiver
}

const furtherImpactMapper = (
	isPraise: boolean,
	impacts: Array<string>,
	replacements: Record<string, string>,
	isReceiver?: boolean
): Record<string, string> => {
	const impact = getImpactTextBeforeMap(Boolean(isReceiver), isPraise)
	const furtherImpacts = Object.keys(impact)
	const hasImpacts = impacts && impacts.length
	return hasImpacts
		? impacts.reduce((acc, current) => {
				if (current) {
					const impacted = current.split('?')[current.split('?').length - 1]
					for (let i = 1; i < furtherImpacts.length; i++) {
						if (current.includes(furtherImpacts[i])) {
							return {
								...acc,
								[furtherImpacts[i]]: replaceFunction(
									impact,
									{ ...replacements, '%IMPACTED%': impacted },
									furtherImpacts[i]
								),
							}
						}
					}
				} else {
					return {}
				}

				return { ...acc }
		  }, {})
		: []
}

export const mapOutputContext = <T extends BaseCompanyOutput<any>>(
	isPraise: boolean,
	replacements: Record<string, string>,
	outputBeforeMap: T,
	isReceiver?: boolean
) => {
	if (!outputBeforeMap) {
		return { CONTEXT: '', CONTRIBUTION: '', FREE_TEXT_LABEL: '', IMPACTS: [] }
	}
	const { furtherImpact } = outputBeforeMap
	const IMPACTS = Object.values(
		furtherImpactMapper(isPraise, furtherImpact, replacements, isReceiver)
	)
	return { IMPACTS }
}

export const answersOuputMapped = (answers: Array<any>, senderInfo: IUser) => {
	let output: Partial<BaseCompanyOutput<any>> = {
		sender: senderInfo,
	}
	console.log({ answers })
	answers.map((answer: any) => {
		if (answer.outputType) output.outputType = answer.outputType
		switch (answer.type) {
			case QuestionType.FirstQuestion: {
				output.receiver = answer.recipient
				break
			}
			case QuestionType.DateChoice: {
				output.time = answer.date
				break
			}
			case QuestionType.DatePickerChoice: {
				output.time = new Date(answer.answer!)
				break
			}
			case QuestionType.Reason: {
				//  check the reason where
				const outputTypeIndex = answers.findIndex(answer => answer.outputType)
				output.reason = answers[outputTypeIndex + 1].answer!
				break
			}
			case QuestionType.SingleChoiceAlt: {
				const text = getInWhatFrameWorkText(answers)

				output.context = text.answer?.length ? text.answer : answer.answer!
				break
			}
			case QuestionType.TextArea: {
				const text = getTextArea(answers)
				output.text = text.answer
				break
			}
			case QuestionType.SingleChoice: {
				return { time: answer.answer }
			}
			case QuestionType.MultipleChoice: {
				return { time: answer.answer }
			}
			case QuestionType.MultipleChoiceGrid: {
				if (!output.furtherImpact) {
					output.furtherImpact = Array<string>()
				}
				output.furtherImpact?.push(...answer.multiChoiceAnswer!)
				break
			}
			case QuestionType.UsersList: {
				output.participants = answer.participants

				break
			}
			case QuestionType.RangeOption: {
				return { time: answer.answer }
			}
			case QuestionType.Range: {
				const hasRangeAnswer = answer.answer !== undefined
				if (hasRangeAnswer) {
					// Extremely reduced the chances of achieving your goal
					output.contributions = output.contributions + '&percent=' + answer.answer!
				}
				break
			}
			case QuestionType.GoalsChoice: {
				output.goal_id = answer.id
				output.contributions = answer.answer!
				break
			}
			case QuestionType.CompanyValuesChoice: {
				output.values = answer.values
				break
			}
			case QuestionType.ExpansionsContribution: {
				output.contributionExpansion = answer.contributionExpansion
				break
			}
			case QuestionType.ExpansionsImpacts: {
				output.impactsExpansion = [...answer.impactsExpansion]
				break
			}
			default: {
				return {}
			}
		}
	})
	return output
}

const frameworksList = [
	'Another framework. Which one?',
	'Another way.',
	Context.ANOTHER_WAY_HE,
	Context.ANOTHER_WAY,
]

const getInWhatFrameWorkText = (answers: Array<Record<any, any>>) =>
	answers.reduce((acc, cur, currentIndex, arr: Array<Record<any, any>>) => {
		if (
			cur.type === QuestionType.TextArea &&
			frameworksList.includes(arr[currentIndex - 1]?.answer) &&
			arr[currentIndex - 1]?.type === QuestionType.SingleChoiceAlt
		) {
			return { answer: cur.answer }
		}
		return { ...acc }
	}, {})

const getTextArea = (answers: Array<Record<any, any>>) =>
	answers.reduce((acc, current, index: number, arr: Array<Record<any, any>>) => {
		if (
			current.type === QuestionType.TextArea &&
			!frameworksList.includes(arr[index - 1]?.answer)
		) {
			return { answer: current.answer }
		}
		return { ...acc }
	}, {})
