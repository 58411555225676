import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import RestartAltIcon from '@material-ui/icons/RotateLeft'

import { AppDirectionContext } from '../../context/languages-context'

import { useStyles } from './useStyles'

import useChatLogic from './useChatLogic'
import {
	Debrief,
	DebriefEvent,
} from '../../interface/debrief/debrief-interface'
import { DebriefingSteps } from '../../enums/debriefing/debriefing-steps.enum'
import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'
import { useMatchPointsList } from 'utils/useMatchPointsList'
import { ChatBotAnswers } from './chatBotPreviousAnswer'
import {
	SELF_ANALISYS_MESSAGE,
	MATCHPOINT_CHOICE,
} from '../../components/Debriefing/DebriefTree/stepMapComponent'
import { SELF_ANALISYS_MESSAGE_DATA } from 'components/Debriefing/DebriefTree/RenderSelfAnalisysMessageChoice'
import { InitialMessageContainer } from 'components/Debriefing/InitialMessage/initialMessageContainer'
import { ChatBotHeader } from './ChatBotHeader'
import { ChatBotSelfAnalisysInput } from './SelfAnalisysInput'

import { isSameMonth } from 'utils/helpers'
import { presets } from './utils'

import { PHASE } from './InitialChatPreset'

type Props<T> = {
	debrief?: Debrief
	prevStep?: DebriefingSteps
	nextStep?: DebriefingSteps
	isParticipant?: boolean
	setParticipantStep?: (step: DebriefingSteps) => void
	setData?: (data: Partial<Debrief>, step?: DebriefingSteps | undefined) => void
}

export type ChatAnswer = {
	userAnswer: string
	chatNextQuestion: string
	showConclusionCard?: boolean
	phase: PHASE
	isDone: boolean
}
export type SummaryInfo = {
	what_was_done: string
	assumption: string
	Whats_was_you_need: string
	lessons: string[]
}
export type AnswerState<T, Answer> = {
	isDone: boolean
	currentPhase: PHASE
	currentAnswer: T
	answers: Answer[]
	summary: SummaryInfo
}

export const SelfAnalisysChatBot: FC<Props<any>> = props => {
	const matchPoints = useMatchPointsList()
	const chatLogic = useChatLogic({
		matchPoints,
		...props,
	})

	const {
		answerState,
		currentMatchPoint,
		isInputDisabled,
		getPreviousQuestion,
		restartMatchPoint,
		handleInputChange,
		handleEndOfMatchPoint,
		handleIDontKnowWhyClick,
		handlePresetClick,
		handleChatButtonClick,
	} = chatLogic

	const { isDone, currentAnswer, currentPhase, answers, summary } = answerState
	const windowWidth = useWindowWidth()
	const mobileWidth = 600
	const isMobile = mobileWidth >= windowWidth
	const { isRtlDirection } = useContext(AppDirectionContext)
	const { t } = useTranslation()
	const classes = useStyles({
		isRtlDirection,
		isEndSelfAnalisysDisabled: Boolean(!answers.length),
	})

	const EndSelfAnalisys = (Boolean(!answers.length) || !isDone) && (
		<div className={classes.endSelfAnalisys} onClick={handleIDontKnowWhyClick}>
			{t('I_dont_know_why')}
		</div>
	)

	const prevStepButtonSyle = {
		color: !!answers.length ? '' : 'white',
		cursor: !!answers.length ? '' : 'default',
	}

	const endOfMatchPointbuttonText =
		currentMatchPoint < matchPoints.length - 1 ? 'Next_Match_Point' : 'Done'

	return (
		<>
			{currentMatchPoint <= matchPoints.length - 1 && (
				<InitialMessageContainer>
					<div className={classes.chatWrapper}>
						<ChatBotHeader
							isCreator={!props.isParticipant}
							mtName={`${currentMatchPoint + 1}`}
							mtDescription={matchPoints[currentMatchPoint].description}
							goBack={() => {
								const previousStep = isSameMonth(SELF_ANALISYS_MESSAGE_DATA.type)
									? MATCHPOINT_CHOICE
									: SELF_ANALISYS_MESSAGE

								props.setData?.({}, previousStep)
							}}
						/>
						<ChatBotAnswers
							presetData={presets[currentPhase]}
							currentPhase={currentPhase}
							answers={answers}
							summary={summary}
							handlePresetClick={handlePresetClick}
						/>
						<ChatBotSelfAnalisysInput
							value={currentAnswer}
							onChange={handleInputChange}
							onClick={handleChatButtonClick}
							isInputDisabled={isInputDisabled}
							isBtnDisabled={!currentAnswer}
						/>
						<div className={classes.buttonsContainer}>
							<div style={prevStepButtonSyle} onClick={getPreviousQuestion}>
								{t('Previous_question')}
							</div>

							{EndSelfAnalisys}
							{isMobile ? (
								<RestartAltIcon
									style={{ width: 32, height: 32, marginRight: 32 }}
									onClick={restartMatchPoint}
								/>
							) : (
								<div onClick={restartMatchPoint}>{t('Restart_bot')}</div>
							)}
						</div>
						{!!summary.lessons.length && (
							<button onClick={handleEndOfMatchPoint}>
								{t(endOfMatchPointbuttonText)}
							</button>
						)}
					</div>
				</InitialMessageContainer>
			)}
		</>
	)
}
