import { useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Route, Switch, useHistory } from 'react-router-dom'
import NavigationBar from 'components/common/NavigationBar'
import Wrapper from 'components/common/ScreenWrapper'
import * as tfa from '@zodash/2fa'
import { adminRoutes } from '../../utils/routes'
import { useDispatch } from 'react-redux'
import { userActions } from '../../redux/user/user-reducer'

const authWrappingStyles = {
	width: '100%',
	position: 'relative' as any,
}
const closeStyles = {
	position: 'absolute' as any,
	top: '5%',
	left: '90%',
	cursor: 'pointer',
}

const SuperAdminScreen = () => {
	const [isAuth, setIsAuth] = useState(false)

	const classes = useStyles()
	const history = useHistory()
	const dispatch = useDispatch()

	const handleSubmit = (result: boolean) => {
		setIsAuth(result)
	}
	const handleBackToLogin = () => {
		dispatch(userActions.signOutUser())
		history.push('/')
	}

	if (!isAuth) {
		return (
			<div style={authWrappingStyles}>
				<div style={closeStyles} onClick={handleBackToLogin}>
					Back to login
				</div>
				<AuthSuperAdmin handleSubmit={handleSubmit} />
			</div>
		)
	}

	return (
		<Wrapper>
			<div style={{ userSelect: 'text' }}>
				<NavigationBar isRtl={false} />
				<Grid item xs={12} sm={12} className={classes.container}>
					<Switch>
						{adminRoutes.map((route, index) => (
							<Route {...route} key={index} />
						))}
					</Switch>
				</Grid>
			</div>
		</Wrapper>
	)
}

export default SuperAdminScreen

type AuthSuperAdmin = {
	handleSubmit: (result: boolean) => void
}

const AuthSuperAdmin = ({ handleSubmit }: AuthSuperAdmin) => {
	const onSubmit = async (e: any) => {
		e.preventDefault()

		const verification = await tfa.verify(
			e.target[0].value,
			process.env.REACT_APP_SUPER_ADMIN_KEY!
		)

		if (!verification) {
			return
		}
		handleSubmit(verification)
	}

	const classes = useStyles()

	return (
		<form className={classes.formStyles} onSubmit={onSubmit}>
			<label htmlFor='code' className={classes.labelStyles}>
				Enter Code:
			</label>
			<div>
				<input id='code' type='text' />
				<button type='submit'>Enter</button>
			</div>
		</form>
	)
}

const useStyles = makeStyles({
	container: { margin: '1rem auto 0', flexWrap: 'wrap' },
	formStyles: {
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	labelStyles: {
		fontSize: '1.5rem',
		marginBottom: '10px',
	},
})
