import { Langs } from 'enums/app'
import { FeedbackState } from 'enums/feedback-state/feedback-state.enum'
import { GoalStatus } from 'enums/user/user-goal-status'
import IUser from 'interface/user/user-interface'
import { OutputType } from 'screens/BotOutput'
import { Question, QuestionValue } from '../praise/praise-interface'
import { PraiseState } from '../../enums/praise-state/praise-state.enum'

export type questionId = string | null
export type OutputBeforeAfter = {
	freeTextDescriptions: Record<Langs, string>
	contributionToSuccess: Record<Langs, string>
	aboutContext: Record<Langs, string>
}

export enum Context {
	ANOTHER_WAY = 'Another way',
	ONE_ON_ONE = 'One-on-one',
	PHONE = 'Phone call',
	MEETING = 'Meeting',
	CONVERSATION = 'Casual conversation',
	EMAIL = 'Email',
	INSTANT_MESSAGE = 'Instant message',
	SOCIAL_MEDIA = 'Social media',
	SAY_DO_SYSTEM = 'Solve system',
	NOT_RELEVANT = 'Not relevant',
	NO_CONTEXT = '',
	ANOTHER_WAY_HE = 'במסגרת אחרת. באיזו מסגרת?',
	ONE_ON_ONE_HE = 'בארבע עיניים',
	PHONE_HE = 'בשיחת טלפון',
	MEETING_HE = 'בישיבה',
	CONVERSATION_HE = 'בשיחת מסדרון',
	EMAIL_HE = 'במייל',
	INSTANT_MESSAGE_HE = 'בוואטסאפ',
	SOCIAL_MEDIA_HE = 'ברשת חברתית',
	SAY_DO_SYSTEM_HE = 'במערכת Solve',
	NOT_RELEVANT_HE = 'לא רלוונטי',
	NO_CONTEXT_HE = '',
}

export type BaseContext = Record<Context, Record<Langs, string>>
export type OutputSettingType = {
	_id: string
	title: string
	beforeSend: OutputBeforeAfter
	afterSend?: OutputBeforeAfter
	context?: BaseContext
}
export interface WallOfPraiseOutput {
	contributionTemplate: Record<Langs, string>
	reasonTemplate: Record<Langs, string>
}

export type WallOfPraiseData<C, P> = Record<
	string,
	{
		context: C
		wallOfPraise: P
	}
>

export interface PriaseOutputSettingType extends OutputSettingType {
	wallOfPraise: WallOfPraiseOutput
}

//  replacements
export type OutputMap = {
	_id?: string
	'%NAME%': string
	'%GOAL%': string
	outputType: number
	'%CONTEXT%': string
	'%PERCENT%': string
	values: Array<string>
	furtherImpact: Array<string>
}

export interface BlockageAction {
	_id: string
	dueDate?: Date
	description: string
}

export enum UrgencyLevel {
	later = 'LATER',
	soon = 'SOON',
	asap = 'ASAP, please',
}

export interface Goal {
	id: string
	dueDate?: Date
	description: string
	praises: string[]
	feedbacks: string[]
	blockages: number
	archive: boolean
	status: GoalStatus
}

// <- Update outputDto from server->
export interface BaseCompanyOutput<T> {
	_id: string
	sender: T
	receiver: T
	reason: string
	time: Date
	outputType: string
	goal_id?: string
	text: string
	contributions: string
	furtherImpact: Array<string>
	impactsExpansion?: Array<String>
	contributionExpansion?: string
	values: Array<string>
	context: string
	createdAt: Date
	isDraft: boolean
	participants?: Array<IUser>
	readed?: boolean
	replay?: string,
}

export interface CommentLine<T> extends BaseCompanyOutput<T> {
	createdAt: Date
}

export interface FeedbackOutputUpdate {
	company_id: string
	_id: string
	feedbackState: FeedbackState
	senderActions: BlockageAction[]
	receiverActions: BlockageAction[]
}

export interface FeedbackOutput<T> extends BaseCompanyOutput<T> {
	urgency: UrgencyLevel
	feedbackState?: FeedbackState
	senderActions?: BlockageAction[]
	receiverActions?: BlockageAction[]
}

export interface PraiseOutput<T> extends BaseCompanyOutput<T> {
	isOnWall: boolean
	praiseState?: PraiseState
	reactions?: {
		like: string[]
		love: string[]
		clap: string[]
	}
}

export type AnswersGroupProp = Question<QuestionValue[], string> & {
	isPraise?: boolean
	setQuestion: React.Dispatch<React.SetStateAction<string | null>>
}
export interface OutputWrapperProps {
	CONTEXT: string
	CONTRIBUTION: string
	FREE_TEXT_LABEL: string
	FREE_TEXT: string
	IMPACTS: string[]
	VALUES: string[]
	DATE: string
	sender: IUser
	receiver: IUser
	impactsExpansion?: Array<string>
	contributionExpansion?: string
	readed?: boolean
	replay?: string
	currentOutputId?: string
	closeAction: () => void
	handleToggleReview?: () => void
}

export interface FeedbackOutputWrapperProps extends OutputWrapperProps {
	URGENCYLEVEL: UrgencyLevel
	FEEDBACK_STATE: FeedbackState
	RECEVIER_ACTIONS: BlockageAction[]
	SENDER_ACTIONS: BlockageAction[]
	handleToggleReview?: () => void
}

export type ComponentRenderingProps = OutputWrapperProps & {
	// delete this?
	outputId: string
}
export interface WrapperType {
	outputId: string
	screenType: OutputType
}

export interface FeedbackWrapperType extends WrapperType {
	render: (args: FeedbackOutputWrapperProps) => JSX.Element
}
export interface PraiseWrapperType extends WrapperType {
	render: (args: OutputWrapperProps) => JSX.Element
}

export interface ReviewOutputPayload {
	company_id: string
	output_id: string
}
export interface ExpansionPayload {
	company_id: string
	praise_id: string
	type: string
	[x: string]: any
}
