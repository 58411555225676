import { FC, useRef } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import { AnswersGroupProp } from '../../../interface/bot/index'
import { UserAnswer } from '../ActionButtons/index'
import ActionsButton from 'components/Bot/ActionButtons'
import BotModal from '../Modal/index'
import useMultipleChoiceLogic from './useHandlers'

import { useStyles } from './useStyles'
import ScrollableContent from 'components/common/ScrollableContent/ScrollableContent'

type ImpactsProps = AnswersGroupProp & {
	isAbleToSkipFurtherImpact: boolean
}
const MultipleChoiceGrid: FC<ImpactsProps> = props => {
	const {
		isPraise,
		answers,
		subtitle,
		nextQuestion,
		previousQuestion,
		type,
		isAbleToSkipFurtherImpact,
		setQuestion,
	} = props

	const filterdAnswers = isAbleToSkipFurtherImpact
		? answers
		: answers.slice(0, -1)

	const useLogicPayload = {
		setQuestion,
		isPraise,
		nextQuestion,
		previousQuestion,
		isAbleToSkipFurtherImpact,
		answers: filterdAnswers!,
		type,
	}

	const logicResponse = useMultipleChoiceLogic(useLogicPayload)

	const {
		answer,
		additionalPraiseQuestionSelector,
		currentImpactId,
		chosenIds,
		isEnabled,
		openPopUpModal,
		handleClick,
		replaceTitle,
		setChosenIds,
		handleCloseModal,
		setopenPopUpModal,
		handleModalOpenNextQuestion,
		handleSaveFurtherImpacts,
	} = logicResponse

	const classes = useStyles()

	const content = (
		<Box className={classes.boxStyles}>
			<Grid container id='MultipleChoiceGridAnswerCard'>
				{filterdAnswers?.map(({ title, nextQuestion: _nextQuestion }, index) => {
					const nextStep = _nextQuestion ? _nextQuestion : nextQuestion!
					const isChosed = chosenIds.includes(index)
					const showNotImpacted =
						isAbleToSkipFurtherImpact && index === answers.length - 1

					const style = [
						classes.answerCard,
						isChosed ? classes.isChosed : classes.regular,
						showNotImpacted && classes.answerCardAlt,
					]
						.filter(Boolean)
						.join(' ')

					const answer: UserAnswer = {
						id: index + 1,
						answer: title,
						previousQuestion,
						nextQuestion: nextStep,
						type,
					}

					title = replaceTitle(title)
					return (
						<Grid item key={index}>
							<div
								key={index}
								className={style}
								onClick={() => handleClick(index, answer.nextQuestion!)}
							>
								{title}
							</div>
						</Grid>
					)
				})}
			</Grid>
		</Box>
	)

	return (
		<>
			<div className={classes.subtitleStyle}>{subtitle}</div>
			<ScrollableContent content={content} />
			<ActionsButton
				answer={answer}
				isPraise={isPraise}
				isEnabled={isEnabled}
				handleSaveFurtherImpacts={handleSaveFurtherImpacts}
				removeIds={() => setChosenIds([])}
				setOpenModal={() => setopenPopUpModal(true)}
				setQuestion={setQuestion}
				showNextStepButton
				type={type}
				showPreviousButton
				previousQuestionId={previousQuestion}
			/>
			{openPopUpModal && additionalPraiseQuestionSelector && (
				<BotModal
					handleClose={handleCloseModal}
					isOpen={openPopUpModal}
					next={answer.nextQuestion!}
					previous={answer.previousQuestion!}
				>
					<BotModal.ModalSingleChoice
						{...{
							...additionalPraiseQuestionSelector,
							handleModalOpenNextQuestion,
							currentImpactId,
							type,
						}}
					></BotModal.ModalSingleChoice>
				</BotModal>
			)}
		</>
	)
}

export default MultipleChoiceGrid
