import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import classes from '../Goal/style.module.css'

type Props = {
	icon?: string
	count: number
	naming: string
}

export const BottomSectionItem: FC<Props> = props => {
	const { count, naming, icon } = props

	const { t } = useTranslation()

	return (
		<div className={`${classes.value} ${classes[naming]}`}>
			<img src={icon} alt={naming} />
			<div className={classes.text}>{t(naming)}</div>
			<div className={classes.count}>{count}</div>
		</div>
	)
}
