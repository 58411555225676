import { FC, useState } from 'react'
import Slider from '@mui/material/Slider'
import ActionsButton, { initialAnswer } from '../ActionButtons'
import Box from '@mui/material/Box'
import { UserAnswer } from '../ActionButtons/index'
import { backgroundIconsMap } from '../../../utils/iconsMapper'
import { UseReplaceNameHook } from '../useHooks'
import { AnswersGroupProp } from '../../../interface/bot/index'
import { desktopMarks } from './constants'
import { useStyles } from './useStyles'
import { useWindowWidth,MOBILE_WIDTH } from 'components/common/Header/useHooks/useWindowWidth'
import { useTranslation,  } from 'react-i18next'

enum RangeStep {
	min = 0,
	max = 100,
}

enum ExtentOptions {
	none,
	small,
	medium,
	large,
}

enum Extents {
	small = 'bot.goal_small_extent',
	medium = 'bot.goal_medium_extent',
	large = 'bot.goal_large_extent',
	small_output = 'bot.goal_large_extent_feedback_output',
	medium_output = 'bot.goal_large_extent_feedback_output',
	large_output = 'bot.goal_large_extent_feedback_output',
}

const replaceTitle = (title: string) => {
	return UseReplaceNameHook(title)
}

function valuetext(value: number) {
	return `${value}%`
}
const Range: FC<AnswersGroupProp> = ({
	title,
	setQuestion,
	nextQuestion,
	previousQuestion,
	type,
	isPraise,
}) => {
	const [levelState, setLevel] = useState(ExtentOptions.none)

	const classes = useStyles()
	const { t } = useTranslation()
	const [userAnswer, setAnswer] = useState<UserAnswer>({
		...initialAnswer,
		nextQuestion: nextQuestion!,
		previousQuestion: previousQuestion!,
		type,
		answer: RangeStep.min.toString(),
	})

	const handleOnChangeSlide = (
		// event: Event,
		value: any
		// activeThumb: number
	) => {
		const { level, ext } = value
		if (ext === levelState) {
			setLevel(prev => ExtentOptions.none)
			return
		}

		setLevel(prev => ext)
		console.log(t(level.toString()))
		setAnswer(previousAnswer => ({
			...previousAnswer,
			answer: t(level.toString()),
		}))
	}

	const windowWidth = useWindowWidth()

	const isMobile = MOBILE_WIDTH >= windowWidth
	const marks = isMobile ? [] : desktopMarks
	const valueLabelDisplay = isMobile ? 'on' : 'auto'
	const imageExtraStyle = {
		display: isMobile ? 'none' : '',
	}
	title = replaceTitle(title)

	const small = `${Extents.small}_${isPraise ? 'praise' : 'feedback'}`
	const medium = `${Extents.medium}_${isPraise ? 'praise' : 'feedback'}`
	const large = `${Extents.large}_${isPraise ? 'praise' : 'feedback'}`

	const smallStyle = {
		border: levelState === ExtentOptions.small ? '3px solid rgb(10 18 4)' : '',
		backgroundColor: isPraise
			? 'rgba(157, 191, 129, 1)'
			: 'rgba(39, 148, 225, 1)',
	}
	const mediumStyle = {
		border: levelState === ExtentOptions.medium ? '3px solid rgb(10 18 4)' : '',
		backgroundColor: isPraise ? 'rgba(102, 152, 58, 1)' : 'rgba(28, 115, 201, 1)',
	}
	const largeStyle = {
		border: levelState === ExtentOptions.large ? '3px solid rgb(10 18 4)' : '',
		backgroundColor: isPraise ? 'rgba(58, 98, 25, 1)' : 'rgba(30, 76, 122, 1)',
	}

	return (
		<div className={classes.wrapper}>
			<Box className={classes.boxWrapper}>
				{/* <Slider
					id='slider'
					aria-label='Small steps'
					defaultValue={0}
					getAriaValueText={valuetext}
					valueLabelFormat={valuetext}
					step={10}
					onChange={handleOnChangeSlide}
					marks={marks}
					min={0}
					max={100}
					valueLabelDisplay={valueLabelDisplay}
					className={classes.slider}
				/> */}

				<div className={classes.boxesExtent}>
					<div
						style={smallStyle}
						onClick={() =>
							handleOnChangeSlide({ level: small, ext: ExtentOptions.small })
						}
					>
						{t(small)}
					</div>
					<div
						style={mediumStyle}
						onClick={() =>
							handleOnChangeSlide({ level: medium, ext: ExtentOptions.medium })
						}
					>
						{t(medium)}
					</div>
					<div
						style={largeStyle}
						onClick={() =>
							handleOnChangeSlide({ level: large, ext: ExtentOptions.large })
						}
					>
						{t(large)}
					</div>
				</div>
				<div style={imageExtraStyle} className={classes.imageWrapper}>
					<img
						className={classes.dartIconStyle}
						src={backgroundIconsMap['dartBoardIcon']}
						alt='dartBoardIcon'
					/>
				</div>
			</Box>
			<ActionsButton
				isPraise={isPraise}
				previousQuestionId={previousQuestion}
				isEnabled={levelState !== ExtentOptions.none}
				answer={userAnswer}
				setQuestion={setQuestion}
				showNextStepButton
				showPreviousButton
			/>
		</div>
	)
}

export default Range
