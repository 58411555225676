import { makeStyles, Theme } from '@material-ui/core'


export const useStyles = makeStyles<Theme, { isRtlDirection: boolean }>(
    ({ breakpoints, palette }) => ({
        modalTitle: {
            color: '#46484A',
        },
        reactorsModalContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            '& h1': {
                marginTop: 0,
                color: '#46484A',
                fontWeight: 500,
            },
            '& img': {
                margin: '9px 4px 0 4px',
                width: '25px',
                height: '25px',
            },
        },
        reactorsContainer: {
            height: '25vh',
            overflow: 'auto',
            '& div': {
                textAlign: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
                padding: '1%',
            },
        },
        container: {
            margin: 'auto',
            marginBottom: '3vh',
        },
        title: {
            fontSize: ' 22px',
            color: palette.text.primary,
            fontWeight: 'bold',
            margin: '1.5rem 0 18px',
            fontFamily: 'Sora, sans-serif',
            flex: '100%',
        },
        loader: {
            display: 'flex',
            height: '50vh',
            alignItems: 'center',
            justifyContent: 'center',
        },
        wallOfPraseWrapper: {
            boxSizing: 'border-box',
            width: ' 100%',
            border: `1px solid ${palette.action.disabled} `,
            borderRadius: ' 10px',
            marginBottom: '10px',
        },
        wallOfPraiseBox: {
            boxShadow: 'rgb(0 0 0 / 4%) 2px 2px',
            border: '1px solid #DEE3EF',
            borderRadius: '10px',
            maxHeight: '75vh',
            overflow: 'overlay',
            overflowX: 'hidden',
            // '&::-webkit-scrollbar-track': {
            //  '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
            //  borderRadius: '10px',
            //  backgroundColor: '#F5F5F5 !important',
            // },
            // '&::-webkit-scrollbar': {
            //  width: '0.5rem',
            //  backgroundColor: '#F5F5F5 !important',
            // },
            // '&::-webkit-scrollbar-thumb': {
            //  borderRadius: '10px',
            //  '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,.3) !important',
            //  backgroundColor: '#34A352 !important',
            // },
        },
    })
)
