import { useContext, useEffect, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { useDispatch, useSelector } from 'react-redux'
import { Tab, Tabs, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { AppDirectionContext } from 'context/languages-context'

import useInnerStyle from './tableInnerStyles'
import makeButtonStyles from '../../common/Tabs/Row/styles'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'

import { delete_debreif } from 'redux/debrief/debrief-actions'
import { userSelector } from 'redux/user/user-selector'

import TableSearchbar from './TableSearchbar'
import {
	GetColName,
	getData,
	tableButtonDef,
	tableCreatorDef,
	tableDateDef,
	tableDescriptionDef,
	tableNumberDef,
	tableResultDef,
	// tableDepartmentDef,
	// tableSubDepartmentDef,
} from './tableUtils'
import { useDebriefingTabs } from './useDebriefingTabs'

import deleteIcon from 'assets/deleteIcon.svg'

import { DebriefingPaths } from 'enums/debriefing/debriefing-paths.enum'
import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import { Debrief } from 'interface/debrief/debrief-interface'
import { setNotificationOld } from 'redux/notifications/notifications-action'
import ConfirmModal from 'components/common/confirmModalSB/ConfirmModal'

export interface Row {
	button: string
	creator: string
	date: string
	description: string
	number: string
	result: string
	/*department: string
	sub_department: string*/
}

interface Props {
	handleClick: (debrief: Debrief) => void
	debriefings: Debrief[]
}
export const DebriefTable = ({ debriefings, handleClick }: Props) => {
	const [openModal, setOpenModal] = useState(false)
	const [currentDebriefings, setCurrrentDebriefings] = useState<Array<Row>>([])
	const [useDebriefId, setUseDebriefId] = useState<string>('')

	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const innerClasses = useInnerStyle()
	const gridRef = useRef<any>()
	const { _id, company_id } = useSelector(userSelector)

	const closeModal = () => setOpenModal(false)

	const filter = (
		debrief: Debrief,
		path: DebriefingPaths,
		isPersonal: boolean
	) =>
		isPersonal
			? _id === debrief.creator?._id && debrief.path === path
			: debrief.path === path

	const { currentTab, reset, filterByPath } = useDebriefingTabs<[]>({
		data: debriefings as [],
		filter,
		options: { isRtlDirection },
	})

	useEffect(() => {
		setCurrrentDebriefings(getData(debriefings, isRtlDirection))
	}, [debriefings])

	const setTableData = (row: Row[]) => setCurrrentDebriefings(row)

	const handleRowClick = (code: string) => {
		const debrief = getDebiefByCode(code)
		if (!debrief) return

		const { _id: id } = debrief

		setDebriefAsViewed(id)

		handleClick(debrief)
	}

	const setDebriefAsViewed = (notification_id: string) => {
		dispatch(setNotificationOld({ notification_id, receiver_id: _id }))
	}

	const handleDeleteClick = (code: string) => {
		const debrief = getDebiefByCode(code)
		if (!debrief) return

		setUseDebriefId(debrief._id)
		setOpenModal(true)
	}
	const getDebiefByCode = (code: string) => (
		debriefings.find(debrief => debrief.code === code)
	)

	const deleteDebrief = () => {
		dispatch(delete_debreif({ company_id, _id: useDebriefId }))
		clearDebriefId()
	}
	const clearDebriefId = () => setUseDebriefId('')

	const baseButtonClasses = makeButtonStyles({
		isPraiseButton: false,
		expanded: true,
		isRtlDirection: false,
	})

	const containerStyle = {
		width: '100%',
		height: '100%',
	}
	const gridStyle = {
		height: '100%',
		width: '100%',
	}

	const defaultColDef = {
		flex: 1,
		resizable: true,
	}

	const columnDefs = [
		{
			...tableNumberDef,
			headerName: GetColName('number_field'),
		},
		{
			...tableDateDef,
			headerName: GetColName('date_field'),
		},
		{
			...tableResultDef,
			headerName: GetColName('result_field'),
		},
		{
			...tableDescriptionDef,
			headerName: GetColName('description_field'),

			cellRendererFramework: (row: any) => (
				<Tooltip
					classes={{ popper: innerClasses.tooltip }}
					title={row.data.description}
				>
					<span className={innerClasses.description}>{row.data.description}</span>
				</Tooltip>
			),
		},
		{
			...tableCreatorDef,
			headerName: GetColName('creator_field'),
		},
		{
			...tableButtonDef,
			cellRendererFramework: (row: any) => {
				const debrief = debriefings.find(
					debrief => debrief.code === row.data.number
				)

				const isSummary = debrief?.step === DebriefingSteps.FINISH

				const tableButtonLabel = isSummary ? t('debrief.summary_button') : t('debrief.continue_button')

				const isCreator = debrief?.creator?._id === _id

				return (
					<>
						<button
							className={
								!isSummary
									? `${baseButtonClasses.modalButton} ${innerClasses.debriefButton}`
									: `${baseButtonClasses.modalButton} ${innerClasses.debriefFinishButton}`
							}
							onClick={() => handleRowClick(row.data.number)}
						>
							{tableButtonLabel}
						</button>

						{isCreator && (
							<img
								onClick={() => handleDeleteClick(row.data.number)}
								style={{ cursor: 'pointer', marginInlineStart: '14%' }}
								src={deleteIcon}
								alt='deleteIcon'
							/>
						)}
					</>
				)
			},
		},
	]

	return (
		<>
			<div style={containerStyle}>
				<div className={innerClasses.tableHeader}>
					<TableSearchbar // bug on search input
						currentTab={currentTab}
						data={currentDebriefings}
						setTableData={setTableData}
					/>

					<Tabs className={innerClasses.tabs} value={currentTab}>
						<Tab
							label={t('debrief.all')}
							onClick={() => {
								reset()
								setCurrrentDebriefings(getData(debriefings, isRtlDirection))
							}}
						/>
						<Tab
							label={t('debrief.personal')}
							onClick={() => {
								const filteredResult = filterByPath(DebriefingPaths.PERSONAL)
								setCurrrentDebriefings(filteredResult)
							}}
						/>
						<Tab
							label={t('debrief.group')}
							onClick={() => {
								const filteredResult = filterByPath(DebriefingPaths.GROUP)
								setCurrrentDebriefings(filteredResult)
							}}
						/>
					</Tabs>
				</div>
				<div style={gridStyle} className={`ag-theme-alpine ${innerClasses.table}`}>
					<AgGridReact
						ref={gridRef}
						gridOptions={{
							enableRtl: isRtlDirection,
							suppressCellSelection: true,
							rowHeight: 70,
						}}
						rowData={currentDebriefings}
						rowStyle={{ color: '#46484a' }}
						columnDefs={columnDefs}
						defaultColDef={defaultColDef}
					></AgGridReact>
				</div>
			</div>
			<ConfirmModal
				isOpen={openModal}
				onClose={closeModal}
				onConfirm={deleteDebrief}
				text={t('delete_debrief')}
				confirmButtonText='yes'
				closeButtonText='no' />

		</>
	)
}
export default DebriefTable
