import { FC } from 'react'
import makeBotStyles from '../../Bot/ActionButtons/useStyles'
import { useInnerStyles } from './index'

type ActionButtonProp = {
	classes?: string
	isButtonDisabled?: boolean
	displayButton?: boolean
	handleClick?: () => void
}

export const ActionButton: FC<ActionButtonProp> = props => {
	const { isButtonDisabled, displayButton, handleClick, children, classes } =
		props
	const buttonClasses = makeBotStyles()
	const innerClasses = useInnerStyles({ isNextDisabeld: isButtonDisabled })

	if (!displayButton) {
		return null
	}
	const styles = classes
		? classes
		: `${buttonClasses.nextButton} ${innerClasses.nextButton}`

	return (
		<button disabled={isButtonDisabled} className={styles} onClick={handleClick}>
			{children}
		</button>
	)
}
