import { Theme, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles<Theme, { isRtlDirection: boolean }>(
	({ breakpoints }) => ({
		textAreaContainer: {
			height: '100%',
			maxWidth: '470px',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',

			'&>img': { width: '20%', marginTop: '10%' },

			[breakpoints.down('md')]: {
				'& > img': {
					display: 'none',
				},
			},

			[breakpoints.down('sm')]: {
				width: '80%',
				height: '43vh',
			},
		},
		textArea: {
			width: '100%',
			resize: 'none',
			height: '171px',
			outline: 'none',
			border: '2px solid rgba(49,49,49,0.45)',
			borderRadius: '13.6px',
			color: '#46484A',
			fontFamily: 'Sora, sans-serif',
			fontSize: '20px',

			padding: ({ isRtlDirection }) =>
				isRtlDirection ? '23px 26px 0 0' : '23px 0 0 26px',
		},
	})
)
