import { useContext } from 'react'
import { Grid } from '@material-ui/core'

import { AppDirectionContext } from 'context/languages-context'

import useStyles from './style'

import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'
import MainMobileScreenRectagles from './MainMobileScreenRectagles'
import MainDashboardScreenRectagles from './MainDashboardScreenRectagles'

export const useMobileView = () => {
	const windowWidth = useWindowWidth()
	const mobileWidth = 600
	const isMobile = mobileWidth >= windowWidth

	return { isMobile }
}

const BlockagesStatus = () => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const { isMobile } = useMobileView()

	return (
		<Grid container className={classes.blockagesContainer}>
			{isMobile ? <MainMobileScreenRectagles /> : <MainDashboardScreenRectagles />}
		</Grid>
	)
}

export default BlockagesStatus
