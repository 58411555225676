import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import UserAvatar from 'components/common/Avatar'
import chatIcon from 'assets/chatIcon.svg'

type Props = {
	what_was_done: string
	assumption: string
	Whats_was_you_need: string
}

type RenderConclusionCardProp = {
	question: string
	text?: string
}

const ConclusionCard: FC<Props> = props => {
	const { t } = useTranslation()
	const classes = useStyles()

	const { what_was_done, assumption, Whats_was_you_need } = props

	return (
		<div className={classes.container}>
			<h3>{t('conclusion_title')}</h3>
			<RenderConclusionCard
				question={t('conclusion_what_was_done')}
				text={what_was_done}
			/>
			<RenderConclusionCard
				question={t('conclusion_what_was_assumption')}
				text={assumption}
			/>
			{/* <RenderConclusionCard
				question={t('conclusion_what_was_need')}
				text={Whats_was_you_need}
			/> */}
		</div>
	)
}

const RenderConclusionCard: FC<RenderConclusionCardProp> = ({
	text,
	question,
}) => {
	const classes = useStyles()

	return (
		<div className={classes.renderConclusionCard}>
			<div className={classes.renderConclusionQuestionCard}>
				<UserAvatar className={classes.avatar} previewImage={chatIcon} />
				<div>{question}</div>
			</div>
			<div>
				<div className={classes.renderConclusionCardAnswer}>
					<div>{text}</div>
				</div>
				<UserAvatar className={classes.avatar} previewImage={chatIcon} />
			</div>
		</div>
	)
}

export default ConclusionCard

const useStyles = makeStyles({
	avatar: {
		width: '28px',
		height: '28px',
		alignSelf: 'end',
	},

	container: {
		width: '106%',
		padding: '11px 0',

		textAlign: 'center',

		backgroundColor: 'rgba(199, 193, 241, 0.1)',

		borderRadius: '16.8px',
		border: '1px solid #D8D8D8',

		marginTop: '2%',

		'& >h3': {
			color: '#46484A',
		},
	},
	renderConclusionCard: {
		display: 'flex',
		flexDirection: 'column',
		'& > div': {
			alignItems: 'center',
			display: 'flex',
			marginInlineStart: '5%',
			marginInlineEnd: '5%',
			marginBottom: '10px',
		},

		'& > div:last-child': {
			alignSelf: 'end',
		},
	},
	renderConclusionQuestionCard: {
		alignSelf: 'flex-start',

		'& > div:last-child': {
			marginLeft: '2px',
		},
	},
	renderConclusionCardAnswer: {
		alignSelf: 'flex-end',

		background: '#FFFFFF',
		border: '1px solid #D8D8D8',
		borderRadius: '10px',

		padding: '10px 10px',

		textAlign: 'start',
		marginRight: '2%',
		'& > div:first-child': {
			// wordBreak: 'break-word',
			width: 'fit-content',
			marginRight: '2px',
		},
	},
})
