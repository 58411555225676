import { FC, useContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { AppDirectionContext } from '../../../context/languages-context'

import classes from './style.module.css'

import { requestErrorActions } from 'redux/requestError/request-error-reducer'
import { userSelector } from 'redux/user/user-selector'

// import ConfirmModal from '../ConfirmModal/ConfirmModal'
import ConfirmModalSB from '../confirmModalSB/ConfirmModal'

import { IStoreRootState } from 'interface/redux'

const rtlLangs = ['he']

const Wrapper: FC = ({ children }) => {
	const dispatch = useDispatch()

	const { toggleDirection } = useContext(AppDirectionContext)

	const requestError = useSelector((state: IStoreRootState) => state.requestError.error)
	const user = useSelector(userSelector)

	const companyLangugae = useSelector(
		(state: IStoreRootState) =>
			state.user.user?.statusInfo?.companyLanguage ?? 'en'
	)

	useEffect(() => {
		toggleDirection(rtlLangs.includes(companyLangugae))
	}, [companyLangugae])

	const closeErrorWindow = () => dispatch(requestErrorActions.clear())

	if (!user) return <div>Loading...</div>

	return (
		<AppDirectionContext.Consumer>
			{({ isRtlDirection }) => (
				<div
					className={classes.wrapper}
					style={{ direction: isRtlDirection ? 'rtl' : 'ltr' }}
				>
					<div className={classes.container}>{children}</div>
					{/* <ConfirmModalSB
						onClose={() => closeErrorWindow}
						onConfirm={undefined}
						text={requestError!}
						isOpen={requestError ? true : false}
					/> */}
				</div>
			)}
		</AppDirectionContext.Consumer>
	)
}

export default Wrapper

// const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
// 	wrapper: {
// 		width: '99.8vw',
// 		marginTop: '10vh',
// 		display: 'flex',
// 		flexDirection: 'column',
// 		height: '90vh',
// 		overflowY: 'auto',

// 		[breakpoints.down('sm')]: {
// 			marginTop: '10vh',
// 		},
// 		[breakpoints.down('md')]: {
// 			marginTop: '10vh',
// 		},
// 		[breakpoints.down('lg')]: {
// 			marginTop: '14vh',
// 		},
// 	},

// 	container: {
// 		overflowY: 'initial',
// 		[breakpoints.up('md')]: {
// 			width: '100%',
// 			marginInlineStart: 'auto',
// 		},
// 	},
// }))
