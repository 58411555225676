import { FC, useContext, useState, useMemo } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { AppDirectionContext } from 'context/languages-context'

import useStyles from './styled'

import { getDaysDiff } from 'utils/getDiffDates'
import DatePicker from 'components/Bot/Modal/DatePicker'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'

type ItemDateProps = {
    updateDate: (daye: Date) => void
    date: Date | undefined
    isLeader: boolean
    matchPointColor: string
}

const ItemDate: FC<ItemDateProps> = props => {
    const { updateDate, isLeader, date, matchPointColor } = props
    const { isRtlDirection } = useContext(AppDirectionContext)

    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)

    const classes = useStyles({ isRtlDirection, isLeader, matchPointColor })

    const convertedDateForamt = useMemo(() => {
        if (date) return dayjs(date).format('DD.MM.YY')

        return t('on_going_task')
    }, [date])

    const today = new Date()

    const markPassedTask = (): string => {
        const regularColor = 'rgba(70, 72, 74, 1)'
        const red = 'red'

        if (!date) return regularColor
        const TODAY_DATE = new Date(Date.now()).toISOString()
        const isoDateFormat = new Date(`${date}`).toISOString()

        const amountOfDaysLeft = getDaysDiff(TODAY_DATE, isoDateFormat)
        const itemIsStillValidDate = amountOfDaysLeft > 0

        if (!itemIsStillValidDate) return red

        return regularColor
    }

    const handleCloseModal = () => setIsOpen(prev => false)
    const handleOpenModal = () => setIsOpen(prev => true)

    const handleDateDoubleClick = () => isLeader && handleOpenModal()

    const handleDayClick = (day: Date) => {
        updateDate(day)
        handleCloseModal()
    }

    const DateSpan = (
        <span onDoubleClick={handleDateDoubleClick}>{convertedDateForamt}</span>
    )
    const Calendar = (
        <ConfirmModalSB
            onClose={handleCloseModal}
            isOpen={isOpen}
            child={
                <DatePicker
                    disabledDays={{ before: today }}
                    handleDayClick={handleDayClick}
                    selectedDay={new Date(convertedDateForamt!)}
                />
            }
            removeButtons
        />
    )

    return <div className={classes.date} style={{ color: markPassedTask() }}>{isOpen ? Calendar : DateSpan}</div>
}

export default ItemDate