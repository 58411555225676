import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles'
export const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
	feedbackrangewrapper: {
		width: '100%',
		height: '80vh',

		[breakpoints.down('sm')]: {
			width: '78%',
		},
	},
	container: {
		width: '100%',
		height: '50vh',

		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	slider: {
		'& > .MuiSlider-mark': {
			height: 30,
			marginTop: 20,
			backgroundColor: '#A9A9A9',
		},
		'& > .MuiSlider-markActive': {
			height: 30,
			marginTop: 20,
			backgroundColor: '#6495ED',
		},
		'& > .MuiSlider-markLabel': { marginTop: 20, fontSize: 16 },
	},
	indicators: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '25px',
	},
	indicator: {
		color: '#46484A',
		fontFamily: 'Sora, sans-serif',
		fontSize: '14px',
	},
}))
