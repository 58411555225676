import React, { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { filteredList } from 'utils'
import { Link } from '../Header/Mobile/constants'
import NavigationButton from './NavigationButton'

import { Roles } from 'enums/user/user-roles'
import { License } from 'enums/License/License.enum'

export const NavigationButtons = React.memo(({
	isMobile,
	role,
	license,
	handleMobileMenu
}: NavigationButtonsProps) => {

	const history = useHistory()
	const location = useLocation()

	const handleLinkClick = (link: string) => {
		handleMobileMenu && handleMobileMenu()

		history.push(link)
	}
	const links = useMemo(() => filteredList(role, license), [role, license])

	const navigationLinks = links.map((link: Link) => {
		const isActivePage = location.pathname === link.link
		return (
			<NavigationButton
				isActivePage={isActivePage}
				isMobile={isMobile}
				{...link}
				key={link.link}
				handleButtonClick={handleLinkClick}
			/>
		)
	})
	return (
		<>
			{navigationLinks}
			{/* <Notification /> */}
		</>
	)
}
)
type NavigationButtonsProps = {
	handleMobileMenu?: () => void
	isMobile: boolean
	role: Roles
	license: License
}
export default NavigationButtons
