import { BaseCompanyOutput } from 'interface/bot'
import IUser from 'interface/user/user-interface'
import { getFullName } from '../components/HRsettings/DepartmentBuilder/builderHelper'
import { replaceFunction, buildReplacment } from './outputs/helpers'
import { ConstructedOutputTab } from '../screens/Feedbacks/useTabsTemplates'
import { Langs } from 'enums/app'

export enum TabType {
	sent = 'SENT',
	receive = 'RECEIVE',
	draft = 'DRAFT',
}

export type TabsData<T extends BaseCompanyOutput<IUser>> = Omit<
	T,
	'_id' | 'outputType' | 'time' | 'reason' | 'sender' | 'receiver' | 'context' | 'readed' | 'replay'
> & {
	id: string
	imageUrl: string
	date: string
	name: string
	description: string
	readed: boolean
	replay?: string
}
export const outputTabsDataMap = <T extends BaseCompanyOutput<IUser>>(
	type: TabType,
	outputs: Array<T>,
	tabsTemplate: ConstructedOutputTab = {},
	connectivityWord?: string
): Array<TabsData<T>> => {
	if (!outputs || !outputs.length) return []

	const isReceiver = type === TabType.receive
	const { template = {}, contextTemplate = {} }: any = tabsTemplate ?? {}

	return outputs.map(output => {
		const { _id, outputType, time, reason, sender, receiver, context, replay, readed, ...rest } =
			output
		const companyLangage = sender.statusInfo?.companyLanguage ?? ''

		const _context =
			contextTemplate && contextTemplate[context]
				? contextTemplate[context][companyLangage]
				: ' '

		const outputTemplate = outputType in template ? template[outputType] : {}

		const t = template
			? isReceiver
				? outputTemplate.afterSend?.aboutContext
				: outputTemplate.beforeSend?.aboutContext
			: {}

		const replacements = buildReplacment(
			{
				...output,
			},
			isReceiver,
			companyLangage === Langs.he,
			connectivityWord
		)
		const text = replaceFunction(
			t,
			{ ...replacements, '%CONTEXT%': _context },
			companyLangage
		)
		const name = getFullName(isReceiver ? sender : receiver)
		const userImageUrl = isReceiver
			? sender.personalInfo?.imgSrc!
			: receiver.personalInfo?.imgSrc!

		return {
			...rest,
			id: _id!,
			name,
			imageUrl: userImageUrl,
			date: `${time}`,
			description: text,
			readed: readed!,
			replay: replay
		}
	})
}
