import { FC } from 'react'
import { useStyles } from '../useStyles'
import DoneIcon from '@material-ui/icons/Done'

export const SelectedOval: FC<{}> = () => {
	const classes = useStyles()
	return (
		<div className={classes.selected}>
			<DoneIcon />
		</div>
	)
}
