import Button from 'components/common/Button'
import { useTranslation } from 'react-i18next'

type Props = {
    label?: string
    isCloseButton?: boolean
    acceptConfirm?: boolean
    onClose?: () => void
    onDiscard?: () => void
    onConfirm?: () => void
}
const ConfirmModalButton = ({ label, isCloseButton, acceptConfirm, onClose, onDiscard, onConfirm }: Props) => {
    const { t } = useTranslation()
    const defaultLabel = isCloseButton ? t('no') : t('yes')
    const hasCondition = acceptConfirm !== undefined
    const onCloseModal = () => {
        onClose!()
        onDiscard && onDiscard()
    }

    const onConfirmModal = () => {
        if (!hasCondition) {
            onConfirm!()
            onClose!()
        } else {
            if (acceptConfirm) {
                onConfirm!()
                onClose!()
            }
        }
    }
    return <Button onClick={isCloseButton ? onCloseModal : onConfirmModal}>{label ? t(label) : defaultLabel}</Button>

}

export default ConfirmModalButton