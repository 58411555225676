import classes from './styles.module.css'
import AnswerButtons from './AnswerButtons'
import okayIcon from '../../../assets/okayIcon.svg'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { AppDirectionContext } from 'context/languages-context'

interface Props {
	info?: JSX.Element
	questionString: string
	question: string
	handleAnswer: (answer: boolean | null, question: string) => void
	contactInfo: { name: string; mobile: string }
	answer: boolean | null
	showNotification: boolean
	isBlur: boolean
}
const Question = ({
	info,
	questionString,
	question,
	answer,
	contactInfo,
	handleAnswer,
	showNotification,
	isBlur,
}: Props) => {
	const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)
	return (
		<div
			className={
				isRtlDirection ? `${classes.question} ${classes.rtl}` : classes.question
			}
		>
			<div className={classes.info}>
				{info}
				<div className={classes.answer}>
					<div className={classes.content}>
						<img className={classes.okayIcon} src={okayIcon} />
						<h2>{questionString}</h2>
						{/* <p>{t('continue_conversation')}</p> */}
					</div>
					<div style={{ opacity: isBlur ? 0.5 : 1 }}>
						<AnswerButtons
							showNotification={showNotification}
							contactInfo={contactInfo}
							handleAnswer={handleAnswer}
							question={question}
							answer={answer}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Question
