import { FC, useContext } from 'react'
import DayPicker from 'react-day-picker'

import 'react-day-picker/lib/style.css'
import { makeStyles } from '@material-ui/styles'
import { AppDirectionContext } from 'context/languages-context'
type DisableDays = {
	after?: Date
	before?: Date
}
type Props = {
	disabledDays: DisableDays
	selectedDay: Date
	handleDayClick: (day: Date) => void
}

const useStyles = makeStyles({
	datePickerStyle: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		border: '1.4px solid rgba(49,49,49,0.15)',
		borderRadius: '6px',
		height: '345px',
	},
})

const DatePicker: FC<Props> = ({
	handleDayClick,
	selectedDay,
	disabledDays,
}) => {
	const classes = useStyles()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const dateData = isRtlDirection
		? {
			...hebrewData,
		}
		: {}
	return (
		<DayPicker
			{...dateData}
			disabledDays={{
				after: disabledDays.after! ?? '',
				before: disabledDays.before! ?? '',
			}}
			className={classes.datePickerStyle}
			onDayClick={day => handleDayClick(day)}
			selectedDays={selectedDay}
		/>
	)
}
export default DatePicker

const hebrewMonths = [
	'ינואר',
	'פברואר',
	'מרץ',
	'אפריל',
	'מאי',
	'יוני',
	'יולי',
	'אוגוסט',
	'ספטמבר',
	'אוקטובר',
	'נובמבר',
	'דצמבר',
]
const hebrewDays = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת']
const hebrewDaysShort = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש']
const hebrewData = {
	months: hebrewMonths,
	weekdaysLong: hebrewDays,
	weekdaysShort: hebrewDaysShort,
}
