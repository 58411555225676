import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import ModalDropdownOptions from "./ModalDropdownOptions"
import ModalInput from './LessonsManagementInput'
import { nameRecord } from "utils/ModalLessonsManagementLabels"
import { DEFAULT_TITLE } from '../utils/DEFAULT_TITLE_NAME'

import { DynamicInputType } from '../props'
import { ModalOperations } from "enums/lessonsManagement"
import { setHasInclude } from 'utils/hasSetInclude'
import { useSelector } from 'react-redux'
import { userSelector } from 'redux/user/user-selector'
import { ConversationEnum } from 'enums/lessonsManagement/EmailConversation.enum'
import { getEmailTypes } from '../utils/emailCoverstaionTypes'

const DynamicModalInput = ({
    modalInfo,
    titles,
    selectedSection,
    handleSectionSelected,
    handleValue,
}: DynamicInputType) => {
    const { t } = useTranslation()

    const { operation, name } = modalInfo

    const defaultTitle = useMemo(() => (
        titles?.filter(title => title.title === DEFAULT_TITLE)
    ), [titles])

    const { _id: user_id } = useSelector(userSelector)

    const inputBoxStyle = operation === ModalOperations.DELETE_FOLDER ? 'alert' : ''
    const emailInputBox = 'email-box-input'

    if (operation === ModalOperations.DELETE_LESSON) return (<p>{t('delete_lesson')}</p>)

    return (
        <React.Fragment>
            {name?.map(({ label, placeholder }: nameRecord, index: number) => {

                if (operation === ModalOperations.ATTACHED || operation === ModalOperations.NEW_LESSON) {
                    return (<ModalDropdownOptions
                        options={titles}
                        onSelect={handleSectionSelected}
                    />)
                }


                else if (!selectedSection) return <ModalInput
                    itemDescription={placeholder}
                    label={label}
                    onValueChanged={handleValue}
                    className={inputBoxStyle}
                    key={index}
                />
            })}
        </React.Fragment>
    )
}

export default DynamicModalInput