export const thePraiseIsAbout: Record<number, string> = {
	1: 'A response or outcome you received from %NAME% %CONTEXT%',
	2: 'Collaboration with %NAME% held %CONTEXT%',
	3: 'A promise or agreement that %NAME% kept',
	4: '%NAME% delivered on time',
	5: '%NAME% worked according to procedure',
	6: '%NAME% met her goal',
	7: 'Something %NAME% said %CONTEXT%',
	8: 'Something %NAME% wrote in %CONTEXT%',
	9: 'A gesture %NAME% made %CONTEXT% (%REASON%)',
	10: '%NAME%’s conduct during a %CONTEXT% (%REASON%)',
	11: "%NAME%'s communication style during %CONTEXT% (%REASON%)",
	12: 'Information sharing by %NAME% %CONTEXT%',
	13: 'A report by %NAME% given %CONTEXT%',
	14: '%NAME% involved you in the decision-making process under your responsibility',
	15: 'Praise that %NAME% gave you %CONTEXT%',
	16: 'Praise that %NAME% gave to %PARTICIPANTS% %CONTEXT%',
	17: 'Feedback that %NAME% gave you %CONTEXT% ',
	18: 'Feedback that %NAME% gave to %PARTICIPANTS% %CONTEXT%',
	19: 'Praise and feedback that %NAME% gave you %CONTEXT% ',
	20: 'Praise and feedback that %NAME% gave to %PARTICIPANTS% %CONTEXT%',
	21: '%NAME% set expectations',
}
export const praiseFreeTextDescriptions: Record<number, string> = {
	1: 'Description of the response or outcome received from %NAME%',
	2: 'Description of the collaboration with %NAME%',
	3: 'The agreement you had',
	4: 'The deadline set',

	5: 'The procedure followed',
	6: 'The goal that was met',
	7: 'The things %NAME% said',
	8: 'The things %NAME% wrote',
	12: 'The information shared',
	13: 'The subject of the report',
	14: 'The decision made',
	15: 'The subject of the praise',
	17: 'The subject of the feedback',
	19: 'The subject of the praise and feedback',
	21: 'The subject of the expectations set',
}

export const praiseContributionToSuccess: Record<number, string> = {
	1: '%NAME%’s response or outcome improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	2: '%NAME%’s collaboration improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	3: '%NAME% keeping to her agreement improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	4: '%NAME% delivering on time improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	5: '%NAME% working according to procedure improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	6: '%NAME% meeting her goal improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	7: 'What %NAME% said improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	8: 'What %NAME% wrote improved your ability to achieve the goal (%GOAL%) by %PERCENT%%.',
	9: '%NAME%’s gesture during the meeting improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	10: '%NAME%’s conduct during the meeting improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	11: '%NAME%’s communication style during the meeting improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	12: 'Information sharing by %NAME% improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	13: '%NAME%’s report improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	14: '%NAME% involving you in the decision-making process improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	15: '%NAME%’s praise improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	17: '%NAME%’s feedback improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	19: '%NAME%’s praise and feedback improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	21: 'Setting expectations with %NAME% improved your ability to achieve the goal (%GOAL%) by %PERCENT%%',
}
export const mapContext: Record<string, string> = {
	'Casual conversation': 'in a casual conversation',
	'Phone call': 'on the phone',
	'Social media': 'on Social media',
	'Say=Do system': 'in the Solve system',
	'Instant message': 'by instant message',
	meeting: 'in a meeting',
	Email: 'by email',
}

export const furtherImpact: Record<string, string> = {
	energy:
		'%NAME% contributed to saving or increasing your energy level in relation to %IMPACTED%',
	security: '%NAME% grew your sense of security in relation to %IMPACTED%',
	'feel closer': '%NAME% made you feel closer to her.',
	professional: '%NAME% improved your professional standing in %IMPACTED% eyes.',
	meaningful: '%NAME% made you feel more meaningful.',
	belonging:
		'%NAME% increased your feeling of belonging in relation to %IMPACTED%',
	responsibility:
		'%NAME% enabled you to initiate and take on more responsibility in front of %IMPACTED%',
	develop: '%NAME% empowered you to learn and develop yourself',
}
