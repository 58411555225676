import { makeStyles } from "@material-ui/core"

export default makeStyles(({ breakpoints }) => ({
    closeBtn: {
        display: 'flex',
        float: 'right',
        width: 25,
        height: 25,
        cursor: 'pointer',
        border: '0.1px solid grey',
        borderRadius: '50%',
    },
    membersModal: {
        outline: 'none',

        [breakpoints.down('sm')]: {
            width: '77% !important',
        },
    },
    membersContainer: {
        width: '-webkit-fill-available',
        height: '550px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    listContainer: {
        width: 'fit-content',
        height: '50%',
        display: 'flex',
        flexDirection: 'column-reverse',
        '&>h2': {
            textAlign: 'center',
        }
    },
    avatarsList: {
        width: '97%',
        height: '100%',
        margin: 'auto',
        // display: 'grid',
        // gridTemplateColumns: 'repeat(4, 1fr)',
        '& >*': {
            top: 'auto !important',
            left: 'auto !important'
        }
    },
}))