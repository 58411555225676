import { Debrief } from 'interface/debrief/debrief-interface'
import { FC, useEffect } from 'react'
import useStyles from './styles'
import { Grid } from '@material-ui/core'
import Paths, { DashboardPaths } from 'enums/routes/path-names'
import { useHistory } from 'react-router-dom'
import { debriefActions } from 'redux/debrief/debrief-reducer'
import { useDispatch, useSelector } from 'react-redux'
import { get_user_debriefings } from 'redux/debrief/debrief-actions'
import {
	debriefLoadingSelector,
	userDebriefingsSelector,
} from 'redux/debrief/debrief-selector'
import { userSelector } from 'redux/user/user-selector'
import DebriefTable from '../../components/Debriefing/DebriefTable/DebriefTable'
import { Loader } from 'components/common/Loader'

import MostRecent from 'components/Debriefing/MostRecent'
import { DebriefingPaths } from 'enums/debriefing/debriefing-paths.enum'
import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'
import MobilePanels from 'components/Debriefing/MobilePanels/MobilePanels'
import { setNotificationOld } from 'redux/notifications/notifications-action'

const DebriefingScreen: FC = () => {
	const userDebriefings = useSelector(userDebriefingsSelector)
	const { _id, company_id } = useSelector(userSelector)
	const history = useHistory()
	const classes = useStyles()
	const loading = useSelector(debriefLoadingSelector)
	const dispatch = useDispatch()

	const windowWidth = useWindowWidth()
	const mobileWidth = 600
	const isMobile = mobileWidth >= windowWidth

	useEffect(() => {
		dispatch(debriefActions.setDebriefOnProcess(null))
		company_id &&
			dispatch(get_user_debriefings({ user_id: _id, company_id, pageNumber: 0 }))
	}, [company_id, _id])

	const handleTabClick = (debrief: Debrief) => {
		const { _id: id } = debrief

		dispatch(setNotificationOld({ id, receiver_id: _id }))
		dispatch(debriefActions.setDebriefOnProcess(debrief))
		history.push(Paths.DEBRIEF_PROCESS)
	}
	const openLessonsManagement = (): void => {
		history.push(DashboardPaths.LESSONS_MANAGEMENT)
	}
	const getMostRecent = (path: DebriefingPaths) => {
		const pathResults = userDebriefings.filter(
			(debrief: Debrief) => debrief.path === path
		)
		return pathResults[pathResults.length - 1]
	}

	const data = {
		handleClick: handleTabClick,
		debriefings: userDebriefings,
	}
	const view = isMobile ? 'mobile' : 'desktop'
	const style = { height: '60%' }

	return (
		<Grid item xs={11} sm={8} className={classes.container}>
			{loading ? (
				<Loader size={150} height={'70vh'} />
			) : (
				<>
					<MostRecent
						handleClick={handleTabClick}
						lessonsManagement={openLessonsManagement}
						lastGroupDebrief={getMostRecent(DebriefingPaths.GROUP)}
					/>

					<div style={style}>
						{
							{
								desktop: <DebriefTable {...data} />,
								mobile: <MobilePanels {...data} />,
							}[view]
						}
					</div>
				</>
			)}
		</Grid>
	)
}

export default DebriefingScreen
