import React, { FC } from 'react'

type TeaxtArea = {
	label: string
	value: string
	onClick: () => void
	onChange: React.ChangeEventHandler<HTMLTextAreaElement>
	disabled: boolean
	style: any
	className: string
}

export const TeaxtAreaInput: FC<TeaxtArea> = props => {
	return (
		<>
			<textarea autoFocus {...props} />
		</>
	)
}
