import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import colors from 'utils/colors'
export type Styles = {
	isRtlDirection: boolean
	containerHeight?: string | number
	scroll: boolean
	tasksContainer: boolean
	tasksSection: boolean
	width?: string
	margin?: string
}
export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
	container: {
		border: '1px solid rgba(49, 49, 49, 0.152716)',
		height: ({ containerHeight }) =>
			containerHeight ? containerHeight : '150px',
		width: ({ width }) => (width ? width : '66.666667%'),
		margin: ({ margin }) => (margin ? margin : '10px auto'),
		borderRadius: '8px',
		display: 'flex',
		flexDirection: 'column',
		overflow: ({ scroll }) => (scroll ? 'scroll' : 'hidden'),
		padding: '10px 0 0 10px',
		boxSizing: 'border-box',

		[breakpoints.down('md')]: {
			padding: '10px 0 0 0',
			'padding-inline-start': '10px',
		},
		'& >:last-child': {
			height: '-webkit-fill-available',
		},
	},
	containerDescription: {
		color: 'white',
		display: 'flex',
		justifyContent: 'center',
		padding: '0.5% 2.8%',
		backgroundColor: ({ tasksSection }) =>
			tasksSection ? colors.saydoGreen : colors.saydoBlue,
		width: 'fit-content',
		position: 'relative',
		borderRadius: '15px',
		top: ({ tasksSection }) => (tasksSection ? '4%' : '17%'),
		left: ({ isRtlDirection }) => (isRtlDirection ? 0 : '4px'),
		right: ({ isRtlDirection }) => (isRtlDirection ? '4px' : 0),
		fontSize: '13px',
		fontWeight: 300,
		[breakpoints.down('xs')]: {
			width: 'fit-content',
		},
	},
}))
