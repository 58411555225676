import { Task } from "interface/todos/todos-interface"
import { IBaseInsight } from "interface/baseInsight/baseInsight"

export const filterUserOutputs = <T extends Task>(tasks: Array<T>): Array<T> => {

    tasks = tasks.reduce((acc: T[], current) => {
        const ids = acc.map(a => a._id)
        if (!ids.includes(current._id)) return [...acc, current]

        return acc
    }, [])
    return tasks
}

export const avoidDuplicationOfTasks = <T extends IBaseInsight>(outputs: Array<T>): Array<T> => {

    outputs = outputs.reduce((accumulator: T[], current) => {
        const tasks_ids = accumulator.map(t => t._id)
        if (!tasks_ids.includes(current._id)) return [...accumulator, current]

        return accumulator
    }, [])
    return outputs
}