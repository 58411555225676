import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@material-ui/core/styles'
import { Tabs, Tab } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { AppDirectionContext } from 'context/languages-context'

import classes from 'components/ManagerGoals/style.module.css'

import { get_user_goals } from 'redux/user/user-actions'
import { userLoadingSelector, userSelector } from 'redux/user/user-selector'

import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'

import Goal from 'components/Goals/Goal'
import StatusInfoCard from 'components/common/StatusInfoCard'
import { Loader } from 'components/common/Loader'
import { Todos } from 'components/Todos/index'

import goalsSvg from 'assets/goalsCardSvg.svg'
import Team from 'components/Teams'
import { getItem, setItem } from 'utils/localStorage'
import { IUserGoalState } from 'interface/user/user-interface'
import { set_employee_goals } from 'redux/employee/employee-actions'
import useGoalsv2 from 'components/Goals/GoalModal/GoalsTable/useGoals2'

enum Section {
	GOALS,
	TODO,
	TEAMS,
}
type Tab = {
	label: string
}
const tabsState: Tab[] = [
	{
		label: 'goals',
	},
	{
		label: 'todos',
	},
	{
		label: 'teams',
	},
]

const GoalsScreen: FC<{}> = () => {
	const [currentNum, setNum] = useState(0)

	const displayTodosSection = currentNum === Section.TODO
	const displayTeamsSection = currentNum === Section.TEAMS
	const displayGoalsSection = currentNum === Section.GOALS

	const dispatch = useDispatch()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const { t } = useTranslation()
	const theme = useTheme()

	const user = useSelector(userSelector)
	// const loading = useSelector(userLoadingSelector)

	const windowWidth = useWindowWidth()
	const mobileWidth = 1300
	const isMobile = mobileWidth >= windowWidth



	useEffect(() => {
		const takeUserTodos = getItem('nav-todo')
		const taskUserTeams = getItem('nav-team')

		if (takeUserTodos) setNum(Section.TODO)
		if (taskUserTeams) setNum(Section.TEAMS)

		return () => {
			setItem('nav-todo', false)
			setItem('nav-team', false)
		}
	}, [])



	const userGoals = user?.statusInfo?.goals
	const goals = userGoals?.length ? userGoals.filter(g => !g.archive) : []

	useEffect(() => {
		dispatch(get_user_goals(user._id))
	}, [user._id, goals,userGoals])

	const noGoalsText = <p className={classes.noGoals}>{t('no_goals')}</p>
	let sumOfBlockages = 0
	for (const goal of goals) sumOfBlockages += goal.blockages

	const unassignedEmployee = user?.statusInfo?.directManager! === 'unassigned'
	const userManagerOfHimself =
		user?.statusInfo?.directManager === user?.securityInfo?.email
	const tabsWrapper = {
		margin: '0 auto 1%',
		width: '69.3vw',
		display: 'flex',
		justifyContent: 'flex-end',
	}


	const setGoal =
		(goal: IUserGoalState) => {
			let updatedGoals = []
			if (goal._id === 'new') {
				updatedGoals = [...userGoals, { ...goal }]
			} else {
				updatedGoals = userGoals.map(g => (g._id === goal._id ? goal : g))
			}
			applyDispatch(updatedGoals);

		}


	const applyDispatch = (updatedGoals: IUserGoalState[]) => {
		dispatch(
			set_employee_goals({
				id: user._id,
				goals: updatedGoals,
			})
		)
	}
	const reset = () => {
        dispatch(get_user_goals(user._id));
	   console.log('reset view');
    }
	const {handleSaveAction} = useGoalsv2({
		employee_id : user._id,
		isEditing:true,
		userGoals: goals,
		reset
	})


	return (
		<>
			<StatusInfoCard
				boxShadow='2px 2px 5px rgba(0, 0, 0, 0.1)'
				backgroundColor={theme.palette.primary.dark}
				imageSrc={goalsSvg}
				title={t('hi', {
					username: user?.personalInfo?.firstName,
					interpolation: { escapeValue: false },
				})}
				description={t(getMyGoalsCardDescription(sumOfBlockages)!, {
					count: sumOfBlockages,
				})}
			/>

			{/* {loading ? (
				<Loader size={150} height={'60vh'} />
			) : ( */}
				(<div
					style={{
						marginTop: '0.8%',
						justifyContent: displayTodosSection || isMobile ? 'center' : 'flex-start',
						flexDirection: displayTodosSection ? 'column' : 'row',
					}}
					className={
						isRtlDirection ? `${classes.goals} ${classes.rtl}` : classes.goals
					}
				>
					<div style={tabsWrapper}>
						<Tabs
							className={classes.tabs}
							value={currentNum}
							indicatorColor='secondary'
							textColor='secondary'
						>
							{tabsState.map(({ label }, index) => (
								<Tab
									label={t(label)}
									onClick={() => {
										setNum(index)
									}}
								/>
							))}
						</Tabs>
					</div>
					{displayTodosSection && (
						<Todos
							selectedEmployee={user!}
							shouldHideManagerSide={unassignedEmployee}
						/>
					)}

					{displayTeamsSection && !unassignedEmployee && !userManagerOfHimself && (
						<Team />
					)}


          {displayGoalsSection &&
					  goals.map((goal, idx) => (
              <Goal
                isOnEdit={false}
                goal={goal}
                goalNumber={idx + 1}
                key={goal._id}
                employee_id={user._id}
                isNewEntry={false}
                onSaveGoal={setGoal}
                enableSavingForEmployee={false} //enable full saving with goal edit - true only for managers - here on employee view it's false.
					  	/>
					))}
				</div>
			)
		</>
	)
}
export default GoalsScreen

enum BLOCKAGE {
	NO_BLOCKAGE,
	ONE_BLOCKAGE,
	BLOCKAGES,
}

export const getColleaguesGoalsCardDescription = (n = 0) => {
	if (n >= 2) return 'your_help_blockages'
	return {
		[BLOCKAGE.NO_BLOCKAGE]: 'initialBlockage',
		[BLOCKAGE.ONE_BLOCKAGE]: 'your_help_blockage',
	}[n]
}

export const getMyGoalsCardDescription = (n = 0) => {
	if (n >= 2) return 'goals_blockages'

	return {
		[BLOCKAGE.NO_BLOCKAGE]: 'no_goals_blockages',
		[BLOCKAGE.ONE_BLOCKAGE]: 'goals_blockage_singular',
	}[n]
}
