import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles<Theme>(({ palette }) => ({
	container: {
		color: palette.text.primary,
		margin: '37px auto 30px',
	},
	loader: {
		marginLeft: '23px',
	},
	buttonGreen: {
		backgroundColor: 'transparent !important',
		border: '2px solid #34AB53 !important',
		fontWeight: 900,
		'&:hover': {
			backgroundColor: '#EDF3EF !important ',
		},
	},
	buttonTransparent: {
		backgroundColor: 'transparent !important',
		border: '2px solid #0071C2 !important',
		color: '#0071C2 !important',
		'&:hover': {
			backgroundColor: '#F3F9FF !important ',
		},
	},
}))
