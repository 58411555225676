import InitialMessage from 'components/Debriefing/InitialMessage'
import { EMPTY_STATE } from '../useTreeLogic'
import {
	DEBRIEFING_SELF_ANALISYS_CHAT,
	MATCHPOINT_CHOICE,
} from '../stepMapComponent'

//  SELF_ANALISYS_MESSAGE:
export const RenderSelfAnalisysMessageChoice = ({ ...props }) => {
	const { updateDebrief } = props

	const pageIcon = {
		src: 'heartIcon',
		opacity: '',
	}
	const content = (
		<InitialMessage
			setData={updateDebrief}
			prevStep={MATCHPOINT_CHOICE}
			nextStep={DEBRIEFING_SELF_ANALISYS_CHAT}
			msgData={SELF_ANALISYS_MESSAGE_DATA}
		/>
	)

	return { ...EMPTY_STATE, content, pageIcon }
}

export const SELF_ANALISYS_MESSAGE_DATA = {
	type: 'self_analisys_msg',
	steps: ['1'],
}

// const SELF_ANALISYS_ACHIEVED_MESSAGE_DATA = {
// 	type: 'self_analisys_msg_achieved',
// 	steps: msgSteps,
// }
// const SELF_ANALISYS_UNACHIEVED_MESSAGE_DATA = {
// 	type: 'self_analisys_msg_unachieved',
// 	steps: msgSteps,
// }
