import { FC } from 'react'
import ActionsButton from 'components/Bot/ActionButtons'
import { iconNameToIconMap } from 'utils/iconsMapper'
import { AnswersGroupProp } from '../../../interface/bot/index'
import { useStyles } from './useStyles'
import { UseClickActions } from '../useHooks'
const YESTERDAY = 'Yesterday'
const DateChoice: FC<AnswersGroupProp> = props => {
	const {
		isPraise,
		answers,
		previousQuestion,
		setQuestion,
		nextQuestion,
		type,
	} = props

	const classes = useStyles()
	const { addAnswer } = UseClickActions(isPraise)

	return (
		<>
			<div className={classes.dateWrapper} id='dateWrapper'>
				{answers?.length &&
					answers.map(({ title, nextQuestion: dateNextQuestion }, id) => {
						const date = new Date()
						if (title === YESTERDAY) date.setDate(date.getDate() - 1)
						const answer = {
							id,
							nextQuestion: dateNextQuestion ? dateNextQuestion : nextQuestion!,
							date,
							type,
						}
						return (
							<div
								key={id}
								className={classes.container}
								onClick={() => addAnswer(answer)}
							>
								<div className={classes.iconWrapper}>
									<img
										className={classes.icon}
										src={iconNameToIconMap['regularCalendarButtonIcon']}
										alt='calendarButtonIcon'
									/>
									{title}
								</div>
							</div>
						)
					})}
			</div>
			<ActionsButton
				isEnabled
				type={type}
				showPreviousButton
				isPraise={isPraise}
				previousQuestionId={previousQuestion}
				setQuestion={setQuestion}
			/>
		</>
	)
}

export default DateChoice
