import { makeStyles } from "@material-ui/core"
import { Theme } from '@mui/material'

export default makeStyles(() => ({
    shownEye: {
        cursor: 'pointer',
    },
    // checkbox: {
    //     color:'lightgrey',
    //     filter: 'invert(16%) sepia(61%) saturate(8%) hue-rotate(322deg) brightness(100%) contrast(103%)'
    // },
    eyeIcon: {
        filter: 'invert(57%) sepia(5%) saturate(38%) hue-rotate(29deg) brightness(88%) contrast(91%)'
    },
    closenEye: {
        filter: 'invert(16%) sepia(61%) saturate(8%) hue-rotate(322deg) brightness(100%) contrast(103%)'
    }
}))