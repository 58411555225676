import { useDispatch } from "react-redux"
import { create_goal_action, delete_goal_action, edit_goal_action } from "redux/employee/employee-actions"
import { GoalInfo, GoalItemAction, GoalPayload, ListItemType } from "./types"
import { Keys } from "enums/user/user-goal-status"
import { IUserGoalItem, IUserGoalState } from "interface/user/user-interface"

type Props = {
    employee_id:string
    isEditing: boolean
    reset: () => void
    userGoals: IUserGoalState[]
    //userGoalsDTO: IUserGoalStateDTO[]
}

//This was created to make sure we can save only the goal item, without updating the entire array of goals. 
// for the moment it turns out that this is not needed after all. but it can be useful in the future as it is suboptimal to always refresh whole 
// array of user goals (with arrays of sub-goals for every update.)

const useGoalsv2 = ({ reset, employee_id, userGoals}: Props) => {
    const dispatch = useDispatch()

    const isEmptyDescription = (need: string): boolean => !need.trim()

    //omit the action item when saving as this is not part of the database scheme.. (may cause problems when saving if present - just in case)
    const prepareGoalsForAPI = (goals: IUserGoalItem[]): Omit<IUserGoalItem, 'action'>[] => {
        return goals.map(({ action, ...rest }) => rest);
      };


    //edit methods
    const handleEditGoalItems = (goal_id: string, goalItems : IUserGoalItem[], goalType : Keys) => {
        goalItems.forEach((goalItem) => {
            console.log ('updating goal item :' + goalItem._id);
            console.log(goalItem);
            dispatchEditGoalItem(goal_id, goalItem, goalType);
        });
    }
    const dispatchEditGoalItem = (goal_id: string, goalItem: IUserGoalItem,goalType: Keys) => {
        
        const payload: GoalPayload = {
            action: {...goalItem , key: goalType },
            employee_id,
            goal_id
        }
        console.log('GoalEditPayload');
        console.log(payload);
        dispatch(edit_goal_action(payload))
    }

//delete methods

const handleDeleteGoalItems = (goal_id: string, goalItems : IUserGoalItem[], goalType : Keys) => {
    console.log ('running handleDeleteGoalItems :' + goalItems?.length);
    goalItems?.forEach((goalItem) => {
        console.log ('deleting goal item :' + goalItem._id);
        //console.log(goalItem);
        dispatchDeleteItem(goal_id, goalItem, goalType);
    });

}
const dispatchDeleteItem = (goal_id:string, goalItem : IUserGoalItem , goalKeyType: Keys) => {
    //const selectedListItem = getListItem(index)
    console.log ('running dispatchDeleteItem :' + goalItem._id);
    const payload: GoalPayload = {
        action: { ...goalItem , key: goalKeyType },
        employee_id,
        goal_id
    }
    console.log ('dispatch(delete_goal_action(payload)) for: ' + goalItem._id);
    console.log ('payload');
    console.log (payload);
    dispatch(delete_goal_action(payload))
}
    //create methods
    const handleCreateGoalItems = (goal_id: string,goalItems : IUserGoalItem[] , goalType: Keys) => {

        goalItems?.forEach((goalItem) => {

            if (isEmptyDescription(goalItem.need)) return;
            console.log ('creating goal item :' + goalItem._id );
            console.log(goalItem);
        
            dispatchCreateGoalItem(goal_id, goalItem, goalType);
        });

    }
    const dispatchCreateGoalItem = (goal_id: string,goalItem: IUserGoalItem,goalType: Keys) => {
        const payload: GoalPayload = {
            action: { status: goalItem.status,need: goalItem.need , key: goalType },
            employee_id,
            goal_id
        }
        dispatch(create_goal_action(payload))
    }
   //general saving method to handle create and edit
    const handleSaveActionPerGoalType =  (goal_id: string, goalItems: IUserGoalItem[] | undefined, goalKeyType:Keys) =>{
        const ItemsToEdit =  (goalItems?.filter(el => el.action == GoalItemAction.UPDATE) || []);
        const ItemsToCreate = (goalItems?.filter(el => el.action == GoalItemAction.CREATE) || []);
        const ItemsToDelete = (goalItems?.filter(el => el.action == GoalItemAction.DELETE) || []);


        
        //we don't need to compare previous list of goal items with new.. we use the goalitemaction instead.

      /* const InitialGoal: IUserGoalState = userGoals.filter(ug => ug._id === goal_id)[0];
        let ItemsToDelete:  IUserGoalItem[] | undefined =[];
        switch (goalKeyType){
            case Keys.indexs:
                ItemsToDelete = ( InitialGoal.goalInfo?.indexs?.filter(el => !goalItems?.includes(el)) || []);
                break;
            case Keys.needs:
                ItemsToDelete = ( InitialGoal.goalInfo?.needs?.filter(el => !goalItems?.includes(el))  || []);
                break;
            case Keys.requiredActions:
                ItemsToDelete = (InitialGoal.goalInfo?.requiredActions?.filter(el => !goalItems?.includes(el)) || []);

        }
*/

        if(ItemsToEdit?.length >0 ){
            console.log('ItemsToEdit: ' + goalKeyType);
            console.log(ItemsToEdit)
           
           
            handleEditGoalItems(goal_id, ItemsToEdit,goalKeyType);
        }
        if(ItemsToCreate?.length >0){
            console.log('ItemsToCreate: '+ goalKeyType);
            console.log(ItemsToCreate)

            //const goalsForAPI = prepareGoalsForAPI(ItemsToCreate);
            handleCreateGoalItems(goal_id, ItemsToCreate,goalKeyType);
        }
        if(ItemsToDelete?.length >0){
            console.log('ItemsToDelete: '+ goalKeyType);
            console.log(ItemsToDelete)
            //const goalsForAPI = prepareGoalsForAPI(ItemsToDelete);
            handleDeleteGoalItems(goal_id, ItemsToDelete,goalKeyType);
        }


    };

    const handleSaveAction = (userGoal: IUserGoalState) => {
      console.log('saving goalInfo items using useGoalsv2 for object:', userGoal)
      handleSaveActionPerGoalType(userGoal._id, userGoal.goalInfo?.needs,Keys.needs);
      handleSaveActionPerGoalType(userGoal._id, userGoal.goalInfo?.requiredActions,Keys.requiredActions);
      handleSaveActionPerGoalType(userGoal._id,userGoal.goalInfo?.indexs,Keys.indexs);
      reset()
    }

    /*const handleEditGola = (action: ListItemType) => {
        const payload: GoalPayload = {
            action: { ...action, userGoalItemKey },
            employee_id,
            goal_id
        }
        dispatch(edit_goal_action(payload))

        reset()
    }*/


   /* const handleCheckboxChange = (
        { target: { checked } }: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const selectedListItem = getListItem(index)
        const payload: GoalPayload = {
            action: { ...selectedListItem, status: +checked, key },
            employee_id,
            goal_id
        }
        dispatch(edit_goal_action(payload))

        reset()
    }*/

    /*const handleDeleteActionById = (index: number) => () => {
        const selectedListItem = getListItem(index)
        const payload: GoalPayload = {
            action: { ...selectedListItem, key },
            employee_id,
            goal_id
        }
        dispatch(delete_goal_action(payload))

        reset()
    }*/

    //return { handleCheckboxChange, handleDeleteActionByIndex, handleSaveAction, handleEditGoalItems }
    return { handleSaveAction }
}

export default useGoalsv2
