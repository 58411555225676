import regularCalendarButtonIcon from 'assets/calendarButtonIcon.svg'
import dartBoardIcon from 'assets/dartBoardIcon.svg'
import twoUsersInterviewMeetingIcon from 'assets/twoUsersInterviewMeetingIcon.svg'
import noteBookIcon from 'assets/noteBookIcon.svg'
import heartIcon from 'assets/heartIcon.svg'
import inFourEyesIcon from 'assets/inFourEyesIcon.svg'
import meetingIcon from 'assets/meetingIcon.svg'
import corridorCallIcon from 'assets/corridorCallIcon.svg'
import phoneCallIcon from 'assets/phoneCallIcon.svg'
import socialNetworkIcon from 'assets/socialNetworkIcon.svg'
import whatsappIcon from 'assets/whatsappIcon.svg'
import emailIcon from 'assets/emailIcon.svg'
import otherIcon from 'assets/otherIcon.svg'
import SayDoIcon from 'assets/logo.svg'
import solveLogo from 'assets/Logowithtext.svg'

import notRelevantIcon from 'assets/notRelevantIcon.svg'
import CasualconversationIcon from 'assets/CasualconversationIcon.svg'
import rocketIcon from 'assets/launchIcon.svg'
import surveyResults from 'assets/surveyResults.svg'
import conversationIcon from 'assets/conversationIcon.svg'
// backgrounds icons imports
import calendarBackgroundIcon from 'assets/calendarBackgroundIcon.svg'
import goalBackgroundIcon from 'assets/goalBackgroundIcon.svg'
import userSearchBackgroundIcon from 'assets/userSearchBackgroundIcon.svg'
import likeIcon from 'assets/likeIcon.svg'
import inWhichContextBackgroundIcon from 'assets/inWhichContextBackgroundIcon.svg'
import tapIcon from 'assets/tapIcon.svg'
import frameworkIcon from 'assets/frameworkIcon.svg'
import matchpointIcon from 'assets/matchpointBackground.svg'

export const iconNameToIconMap: Record<string, string> = {
	regularCalendarButtonIcon: regularCalendarButtonIcon,
	twoUsersInterviewMeetingIcon: twoUsersInterviewMeetingIcon,
	inWhichContextBackgroundIcon: inWhichContextBackgroundIcon,
	inFourEyesIcon: inFourEyesIcon,
	socialNetworkIcon: socialNetworkIcon,
	whatsappIcon: whatsappIcon,
	emailIcon: emailIcon,
	meetingIcon: meetingIcon,
	corridorCallIcon: corridorCallIcon,
	phoneCallIcon: phoneCallIcon,
	otherIcon: otherIcon,
	SayDoIcon: SayDoIcon,
	SolveIcon: solveLogo,
	notRelevantIcon: notRelevantIcon,
	CasualconversationIcon: CasualconversationIcon,
}

export const backgroundIconsMap: Record<string, string> = {
	goalBackgroundIcon: goalBackgroundIcon,
	calendarBackgroundIcon: calendarBackgroundIcon,
	userSearchBackgroundIcon: userSearchBackgroundIcon,
	dartBoardIcon: dartBoardIcon,
	twoUsersInterviewMeetingIcon: twoUsersInterviewMeetingIcon,
	likeIconBackgroundIcon: likeIcon,
	noteBookBackgroundIcon: noteBookIcon,
	inWhichContextBackgroundIcon: inWhichContextBackgroundIcon,
	heartIcon: heartIcon,
	frameworkIcon: frameworkIcon,
	rocketIcon: rocketIcon,
	surveyResults: surveyResults,
	conversationIcon: conversationIcon,
	tapIcon: tapIcon,
	matchpointIcon: matchpointIcon,
}
