import { FC, forwardRef } from 'react'
import { Avatar } from '@material-ui/core'

import { getUserInitials } from 'utils'

import { IUserPersonalInfo } from 'interface/user/user-interface'
import IUser from 'interface/user/user-interface'
import { DraggableProvided } from 'react-beautiful-dnd'

type UserAvatarProps = {
	userInfo?: IUser
	name?: string
	className?: string
	previewImage?: string
	parentRef?: any
	provided?: DraggableProvided
}
const UserAvatar: FC<UserAvatarProps> = (props) => {
	const { name, className, userInfo, previewImage, parentRef, provided } = props

	const { personalInfo = {} } = userInfo ? userInfo : {}
	const {
		firstName = '',
		lastName = '',
		imgSrc = '',
	} = personalInfo as IUserPersonalInfo
	return (
		<AvatarTest
			ref={parentRef}
			className={className}
			provided={provided}
			src={previewImage ? previewImage : imgSrc} >
			{name
				? getUserInitials(name.split(' ')[0], name.split(' ')[1])
				: getUserInitials(firstName, lastName)}
		</AvatarTest>
	)
}
export default UserAvatar

const AvatarTest = forwardRef<any, any>((props, ref) => {
	const { provided = {} } = props
	return (
		<Avatar
			innerRef={ref}
			data-card='userExtraInfoContainer'
			{...props}
			{...provided?.draggableProps}
			{...provided?.dragHandleProps}
		>
			{props.children}
		</Avatar>
	)
})