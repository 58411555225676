import { FC } from 'react'
import classes from '../Goal/style.module.css'
import { useTranslation } from 'react-i18next'
import StatusSelect from '../Goal/StatusSelect'
import dayjs from 'dayjs'

type Props = {
	style: Object
	setStatus: any
	editMode: any
	status: any
	goalDate?: Date
	handleDateClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const GoalCardDateSection: FC<Props> = props => {
	const { handleDateClick, setStatus, status, editMode, goalDate, style } = props
	const { t } = useTranslation()

	return (
		<div className={`${classes.date} ${classes.section}`}>
			<div className={classes.label}>{t('due_date')}</div>
			<div onClick={handleDateClick} style={style} className={classes.content}>
				{!goalDate ? 'dd/mm/yyyy' : dayjs(goalDate).format('DD/MM/YYYY')}
			</div>
			<StatusSelect status={status} setStatus={setStatus} editMode={editMode} />
		</div>
	)
}
