import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export type Styles = {
    color?: string
    totalRow?: boolean
    subTitle?: boolean
    isRtlDirection?: boolean
}

export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
    wrapper: {
        width: '100%',
        textAlign: 'center',
        border: '2px solid rgba(49, 49, 49, 0.15)',
        boxShadow: '2px 2px 5px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: 15,
        maxWidth: 1051.26,
        [breakpoints.down('xs')]: {
            width: '85%',
        },
        '& >div:nth-child(2)': {
            height: 44.41
        }
    },
    header: {
        display: 'flex',
        maxHeight: '20%',
        height: 75.31,
        fontSize: 18,
        fontWeight: 600,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontFamily: 'Sora, sans-serif',
        borderTopRightRadius: 15,
        borderTopLeftRadius: 15,
        [breakpoints.down('sm')]: {
            fontSize: 7,
            height: 35.31,
            fontWeight: 875
        },
        backgroundColor: ({ color }) => color,
        '& > div': {
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            borderRight: '1.5px solid rgba(170, 170, 170, 1)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
        },
        '& >:first-child': {
            maxWidth: 205,
            borderRight: ({ isRtlDirection }) => (isRtlDirection ? 'none' : '1px solid rgba(170, 170, 170, 1)')
        },
        '& >:last-child': {
            borderRight: ({ isRtlDirection }) => (isRtlDirection ? '1px solid rgba(170, 170, 170, 1)' : 'none')
        }
    },
    row: {
        display: 'flex',
        // maxHeight: 75.31,
        // maxHeight: 75.31,
        fontSize: 18,
        fontWeight: 600,
        fontFamily: 'Sora, sans-serif',
        backgroundColor: ({ color }) => color,
        [breakpoints.down('sm')]: {
            fontSize: 9,
            height: 45
        },
        '& > div': {
            width: '100%',
        },
    },
    body: {
        '& > div > p': {
            paddingLeft: ({ isRtlDirection }) => (isRtlDirection ? 0 : 12.3),
            paddingRight: ({ isRtlDirection }) => (isRtlDirection ? 12.3 : 0),
            [breakpoints.down('sm')]: {
                paddingLeft: 4,
                wordBreak: 'break-word',
                fontSize: 7
            }
        },
        '& > div > div': {
            flex: 1,
            '&:last-child': {
                borderRight: 'none',
            },
        },
        '& > div': {
            display: 'flex',
            FontFamily: 'Sora, sans-serif',
            fontWeight: 600,
            fontSize: ({ subTitle }) => (subTitle ? 14 : 18),
            color: '#000000',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            backgroundColor: ({ color }) => color,
            borderRight: '1.5px solid rgba(170, 170, 170, 1)',
            borderBottom: '1px solid rgba(170, 170, 170, 0.1)',
            [breakpoints.down('sm')]: {
                fontSize: ({ subTitle }) => (subTitle ? 6 : 11),
                wordBreak: 'break-word',
                fontWeight: 875
            },
            '&:last-child': {
                borderRight: ({ isRtlDirection }) => (isRtlDirection ? '1.5px solid rgba(170, 170, 170, 1)' : 'none')
            },
        },
        '& >:first-child': {
            fontWeight: 700,
            justifyContent: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
            textAlign: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
            fontSize: '14px',
            backgroundColor: ({ color }) => color,
            // fontSize: 15,
            maxWidth: 205,
            maxHeight: '100%',
            fontFamily: 'Sora, sans-serif',
            // fontWeight: 400,
            display: 'flex',
            // justifyContent: 'left',
            alignItems: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
            letterSpacing: 0,
            borderRight: ({ isRtlDirection }) => (isRtlDirection ? 'none' : '1px solid rgba(170, 170, 170, 1)'),
            wordBreak: 'initial',
            borderBottom: ({ totalRow }) => (totalRow ? 'none' : '1px solid rgba(170, 170, 170, 0.1)'),
            color: '#46484A',
            // paddingLeft: 5,
            height: 63.72,
            [breakpoints.down('sm')]: {
                fontSize: 9,
                wordBreak: 'break-all'
            },
            '&:last-child': {
                borderRight: ({ isRtlDirection }) => (isRtlDirection ? '1px solid rgba(170, 170, 170, 1)' : 'none'),
            }
        }
    },
    icon: {
        position: 'inherit',
        cursor: 'pointer',
        left: '67%',
        top: 370,
        opacity: 0.1,
        marginLeft: ({ isRtlDirection }) => (isRtlDirection ? 0 : 6),
        '& > .MuiSvgIcon-root': {
            [breakpoints.down('sm')]: { //resize the arrow icon on small device
                width: 9,
                height: 15
            },
        },
        '&:hover': {
            opacity: 1
        }
    }
}))

