export const theFeedbackIsAbout = {
	1: 'A response or outcome you received from %NAME% %CONTEXT%',
	2: "A response or outcome you didn't receive from %NAME%",
	3: 'Collaboration with %NAME% held %CONTEXT%',
	4: 'There was no collaboration with %NAME%',
	5: "A promise or agreement from %NAME%'s side was not kept",
	6: '%NAME% did not deliver on time',
	7: '%NAME% did not work according to procedure',
	8: '%NAME% did not meet her goal',
	9: 'Something %NAME% said %CONTEXT%',
	10: 'Something %NAME% wrote %CONTEXT%',
	11: 'A gesture %NAME% made %CONTEXT% (%REASON%)',
	12: '%NAME%’s conduct during a %CONTEXT% (%REASON%)',
	13: "%NAME%'s communication style %CONTEXT% (%REASON%)",
	14: 'Partial information sharing by %NAME% %CONTEXT%',
	15: 'Delayed information sharing by %NAME% %CONTEXT%',
	16: '%NAME% shared information retrospectively %CONTEXT%',
	17: "%NAME% didn't share information",
	18: 'A partial report by %NAME% given %CONTEXT%',
	19: 'A report delivered late by %NAME% %CONTEXT%',
	20: 'A report delivered retrospectively by %NAME% %CONTEXT%',
	21: "%NAME% didn't deliver a report",
	22: "%NAME% didn't involve you in the decision-making process under her responsibility",
	23: '%NAME% partially involved you in the decision-making process under her responsibility %CONTEXT%',
	24: 'Praise you expected %NAME% to give %PARTICIPANTS%.',
	25: 'Feedback you expected %NAME% to give %PARTICIPANTS%.',
	26: 'Praise and feedback you expected %NAME% to give %PARTICIPANTS%.',
	27: 'Unclear praise that %NAME% gave to %PARTICIPANTS% %CONTEXT%',
	28: 'Unclear feedback that %NAME% gave to %PARTICIPANTS% %CONTEXT%',
	29: 'Unclear praise and feedback that %NAME% gave to %PARTICIPANTS% %CONTEXT%',
	30: '%NAME% only partially set expectations %CONTEXT%',
	31: '%NAME% did not set expectations',
}

export const feedbackFreeTextDescriptions = {
	1: 'Description of the response or outcome received from %NAME%',
	2: '',
	3: 'Description of the collaboration with %NAME%',
	4: '',
	5: 'The agreement you had',
	6: 'The deadline set',
	7: 'The procedure',
	8: 'The goal',
	9: 'The things %NAME% said',
	10: 'The things %NAME% wrote',
	11: '',
	12: '',
	13: '',
	14: 'The information which was partially shared',
	15: 'The information which was shared by delay',
	16: 'The information which was shared retrospectively',
	17: 'The information which was not shared',
	18: 'The subject of the report',
	19: 'The subject of the report',
	20: 'The subject of the report',
	21: 'The subject of the report',
	22: 'The decision made',
	23: 'The decision made',
	24: 'The subject on which you expected praise',
	25: 'The subject on which you expected feedback',
	26: 'The subject on which you expected praise and feedback',
	27: 'The subject on which you were praised unclearly',
	28: 'The subject on which you were given unclear feedback',
	29: 'The subject on which you were given unclear praise and feedback',
	30: 'The subject of the expectations partially set together',
	31: 'The subject of the expectations that were not set together',
}

export const feedbackContributionToSuccess = {
	1: '%NAME%’s response or outcome harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	2: '%NAME%’s lack of response or outcome harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	3: '%NAME%’s collaboration harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	4: '%NAME%’s lack of collaboration harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	5: '%NAME% not keeping to her agreement harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	6: '%NAME% not delivering on time harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	7: '%NAME% not working according to procedure harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	8: '%NAME% not meeting her goal harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	9: 'What %NAME% said harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	10: 'What %NAME% wrote harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%.',
	11: '%NAME%’s gesture during the meeting harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	12: '%NAME%’s conduct during the meeting harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	13: '%NAME%’s communication style during the meeting harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	14: '%NAME%’s conduct when sharing information harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	15: '%NAME%’s conduct when sharing information harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	16: '%NAME%’s conduct when sharing information harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	17: '%NAME% not sharing information harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	18: '%NAME%’s conduct when delivering her report harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	19: '%NAME%’s conduct when delivering her report harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	20: '%NAME%’s conduct when delivering her report harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	21: '%NAME% not delivering her report harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	22: '%NAME% not involving you in the decision-making process harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	23: '%NAME% involving you partially in the decision-making process harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	24: '%NAME% not praising harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	25: '%NAME% not providing feedback harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	26: '%NAME% not praising and providing feedback harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	27: '%NAME%’s unclear praise harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	28: '%NAME%’s unclear feedback harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	29: '%NAME%’s unclear praise and feedback harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	30: 'Not setting expectations with %NAME% harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
	31: 'Setting partial expectations with %NAME% harmed your ability to achieve the goal (%GOAL%) by %PERCENT%%',
}

export const furtherImpactFeedback: Record<string, string> = {
	energy: '%NAME% made you expend unnecessary energy in relation to %IMPACTED%',
	confidence: '%NAME% reduced your sense of security in relation to %IMPACTED%',
	'further apart': '%NAME% made you feel further apart',
	reputation: "%NAME% harmed your professional reputation %IMPACTED%'s eyes.",
	meaningful: '%NAME% made you feel less meaningful',
	belonging:
		'%NAME% reduced your feeling of belonging in relation to %IMPACTED%',
	responsibility:
		'%NAME% reduced your ability to initiate and take on more responsibility in front of %IMPACTED%',
	develop: '%NAME% reduced your ability to learn and develop yourself',
}
