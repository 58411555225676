import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import { Styles } from 'screens/Bot/useStyles'

export const useStyles = makeStyles<Theme, Styles>(({ breakpoints }) => ({
	searchWrapper: {
		width: '71%',
		[breakpoints.down('sm')]: {
			width: '95%',
		},
	},

	usersListContainer: {
		overflowY: 'auto',
		height: '336.15px',
		paddingInlineStart: '4px',
		marginTop: '5%',
		maxWidth: '469px',
		width: '100%',
		// boxShadow: '3px 3px 15px 0 rgb(0 0 0 / 20%)',
		padding: '20px',

		[breakpoints.down('sm')]: {
			padding: 0,
			paddingInlineStart: 0,
			width: '95%',
			height: '-webkit-fill-available',
			overflowY: 'auto',

			'&::-webkit-scrollbar': {
				width: 0,
			},
		},
	},

	actionsContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		width: '100%',
		marginBottom: '-105px',
	},
	nextButton: {
		height: '54px',
		width: '224px',
		borderRadius: '13.6px',
		borderColor: 'none',
		backgroundColor: '#0071C2',
		cursor: 'pointer',

		border: 0,
		color: '#FFFFFF',
		textAlign: 'center',
		fontSize: '20.4px',
		fontWeight: 700,

		'&:hover': {
			backgroundColor: '#3F8FC9',
		},
	},
	previousButton: {
		height: '54px',
		width: '224px',
		border: '2.36px solid #949494',
		borderRadius: '13.6px',
		backgroundColor: '#FFFFFF',
		cursor: 'pointer',

		borderColor: '#0071C2',
		color: '#0071C2',
		textAlign: 'center',
		fontSize: '20.4px',
		fontWeight: 600,
	},
	checkboxWrapper: {
		margin: '2% 0',
	},
}))
