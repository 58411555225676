import { useDispatch, useSelector } from 'react-redux'

import { getBotAnswers } from 'redux/bot/bot-selector'

import { OutputType } from 'screens/BotOutput'

import { userSelector } from 'redux/user/user-selector'
import { answersOuputMapped, buildReplacment } from 'utils/outputs/helpers'
import { UserAnswerType } from 'redux/bot/bot-reducer'
import {
	feedbacksDraftSelector,
	searchFeedbacksSelector,
} from '../../redux/feedback/feedback-selector'
import {
	receivedFeedbacksSelector,
	sentFeedbacksSelector,
} from '../../redux/feedback/feedback-selector'

import {
	FeedbackOutput,
	FeedbackOutputWrapperProps,
	FeedbackWrapperType,
} from '../../interface/bot/index'
import IUser from 'interface/user/user-interface'

import { useFeedbackOutputData } from './useFeedbackOutputData'
import { AppDirectionContext } from 'context/languages-context'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { get_feedbacks } from '../../redux/feedback/feedback-actions'
import { getItem } from 'utils/localStorage'
import { useLocation } from 'react-router-dom'

export const FeedbackWrapper = (props: FeedbackWrapperType) => {
	const { outputId, screenType, render } = props
	const location = useLocation<any>()
	const tabState = location.state?.tabState

	const currentUserInfo = useSelector(userSelector)
	const receivedFeedbacks = useSelector(receivedFeedbacksSelector)
	const sentFeedbacks = useSelector(sentFeedbacksSelector)
	const draftedFeedbacks: any = useSelector(feedbacksDraftSelector)
	const botAnswers: UserAnswerType = useSelector(getBotAnswers)
	const searchFeedbacksResult = useSelector(searchFeedbacksSelector)

	const isFeedbackReciever = screenType === OutputType.FeedbackReciever
	const isViewingFeedbackScreen = screenType === OutputType.FeedbackViewing
	const isFeedbackDraftBeforeSend = screenType === OutputType.FeedbackDraft

	const dispatch = useDispatch()
	useEffect(() => {
		if (
			!receivedFeedbacks.length &&
			!sentFeedbacks.length &&
			!draftedFeedbacks.length &&
			currentUserInfo._id
		) {
			dispatch(
				get_feedbacks({
					company_id: currentUserInfo?.company_id!,
					user_id: currentUserInfo?._id,
				})
			)
		}
	}, [currentUserInfo])

	const receivedFeedback = useMemo(() => {
		const feedback = findFeedbackOutputById(searchFeedbacksResult, outputId)
		if (!feedback) return findFeedbackOutputById(receivedFeedbacks, outputId)

		return feedback
	}, [receivedFeedbacks, searchFeedbacksResult, outputId])

	const sentFeedback = useMemo(() => {
		const feedback = findFeedbackOutputById(searchFeedbacksResult, outputId)

		if (!feedback) return findFeedbackOutputById(sentFeedbacks, outputId)
		return feedback
	}, [sentFeedbacks, outputId])
	const draftFeedback = useMemo(
		() => findFeedbackOutputById<IUser>(draftedFeedbacks, outputId),
		[draftedFeedbacks, searchFeedbacksResult, outputId]
	)
	const feedbackSentHasoutput = !tabState && sentFeedback?._id === outputId

	const outputBeforeMap = useMemo(() => {
		if (isViewingFeedbackScreen || feedbackSentHasoutput) {
			return sentFeedback
		}

		if (isFeedbackReciever) {
			return receivedFeedback
		}

		if (isFeedbackDraftBeforeSend) {
			return draftFeedback
		}
		const hasAnswers = botAnswers.answers.length

		const outputs = hasAnswers
			? botAnswers.answers
			: getItem('output-data').answers
		const mappedOutputFromBot = answersOuputMapped(outputs, currentUserInfo)

		return mappedOutputFromBot as FeedbackOutput<IUser>
	}, [
		isFeedbackReciever,
		isViewingFeedbackScreen,
		isFeedbackDraftBeforeSend,
		receivedFeedback,
		sentFeedback,
		draftFeedback,
		botAnswers,
		currentUserInfo,
		feedbackSentHasoutput,
	])

	// const outputBeforeMap = outputBeforeMapFunc()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const { t } = useTranslation()
	const connectivityWord = t('and')
	const replacements = buildReplacment(
		{
			...outputBeforeMap,
			receiver: outputBeforeMap?.receiver,
			sender: outputBeforeMap?.sender,
		},
		isFeedbackReciever,
		isRtlDirection,
		connectivityWord
	)

	const useFeedbackOutputDataPayload = {
		currentUserInfo,
		isReciever: isFeedbackReciever,
		replacements,
		outputBeforeMap,
	}

	const outputData = useFeedbackOutputData(useFeedbackOutputDataPayload)
	const { CONTEXT, CONTRIBUTION, FREE_TEXT_LABEL, IMPACTS } = outputData
	const { time = '', impactsExpansion = [] } = outputBeforeMap ?? {}
	const componentData: FeedbackOutputWrapperProps = {
		readed: outputBeforeMap?.readed ?? false,
		URGENCYLEVEL: outputBeforeMap?.urgency,
		CONTEXT,
		CONTRIBUTION,
		IMPACTS: IMPACTS ?? [],
		FREE_TEXT_LABEL,
		VALUES: outputBeforeMap?.values,
		FREE_TEXT: outputBeforeMap?.text,
		DATE: new Date(outputBeforeMap?.time).toDateString(),
		FEEDBACK_STATE: outputBeforeMap?.feedbackState!,
		RECEVIER_ACTIONS: outputBeforeMap?.receiverActions!,
		SENDER_ACTIONS: outputBeforeMap?.senderActions!,
		impactsExpansion: impactsExpansion as [],
		contributionExpansion: outputBeforeMap?.contributionExpansion,
		receiver: outputBeforeMap?.receiver,
		sender: outputBeforeMap?.sender,
		closeAction: () => {},
	}
	return render({ ...componentData })
}

export const findFeedbackOutputById = <T>(
	output: FeedbackOutput<T>[],
	outputId: string
): FeedbackOutput<T> => {
	return output.filter(output => output._id === outputId)[0]
}

/// the useEffect aboe needs to be refactoe for a hook later (to fit both praise/feedbacks)
