import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import { Debrief } from 'interface/debrief/debrief-interface'
import { useDispatch, useSelector } from 'react-redux'
import {
	create_new_debrief,
	update_debrief,
} from 'redux/debrief/debrief-actions'
import { debriefActions } from 'redux/debrief/debrief-reducer'
import { DebriefDTO } from '../../../interface/debrief/debrief-interface'
import { userSelector } from '../../../redux/user/user-selector'

interface Props {
	debrief: Debrief
	isParticipant: boolean
}
const useDebriefActions = ({ debrief, isParticipant }: Props) => {
	const dispatch = useDispatch()
	const user = useSelector(userSelector)

	const updateLocaly = (data: Partial<Debrief>) => {
		//update only redux - dont send req and create on server
		dispatch(debriefActions.setDebriefOnProcess(data))
	}
	const createDebrief = (data: Partial<Debrief>) => {
		//initial creation of a debrief (return from server with creation date and id)
		const { participants = [], sharedEmployess, ...rest } = data
		const { _id: creator_id = '', company_id = '' } = user

		const payload: Partial<DebriefDTO> = {
			...rest,
			creator: creator_id,
			company_id,
			participants: participants?.map(p => p._id),
		}

		dispatch(create_new_debrief(payload))
	}
	const updateDebrief = (data: Partial<Debrief>, step?: DebriefingSteps) => {
		//will happen within every step finish and will update according to the step the debrief is.
		const localData = { ...debrief, ...data, step } //for local update(only redux)
		if (
			(step && unsavedSteps.includes(step)) ||
			(step === DebriefingSteps.MACTHPOINT_CHOICE && isParticipant)
		) {
			//in case we just want to save it localy without server request
			updateLocaly(localData)
			return
		}
		const isCreationStep = step === DebriefingSteps.GROUP_PROCESS_MESSAGE

		if ((isCreationStep && !debrief.createdAt) || !debrief._id) {
			//in case of creation on a certian step (of creation)
			createDebrief(localData)
			return
		}
		//in all other cases will update redux + server with the new data only.

		const {
			participants = [],
			sharedEmployess: _sharedEmployess,
			...rest
		} = data ?? ({} as Partial<Debrief>)
		const { sharedEmployess: sharedEmployess, ...restDebrief } = debrief
		const { _id: creator_id = '', company_id = '' } = user

		const payload = {
			...restDebrief,
			...rest,
			_id: debrief._id,
			creator: creator_id,
			company_id,
			participants: participants?.map(p => p._id),
		}
		// fix line bellow!
		const updatedData: Partial<DebriefDTO> = step
			? {
				...payload,
				step: step,
			}
			: payload
		console.log({ updatedData })
		dispatch(update_debrief(updatedData))
	}

	return { updateDebrief }
}

export default useDebriefActions

const unsavedSteps = [
	DebriefingSteps.INITIAL_MESSAGE,
	DebriefingSteps.PARTICIPANTS_CHOICE,
	DebriefingSteps.CHOOSE_PATH,
	DebriefingSteps.EXPECTED_RESULT,
]
