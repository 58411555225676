import useStyles from '../useStyles'

import solvelogocropped from 'assets/solvelogocropped.png'

export const SolveLoginLogo = () => {
	const classes = useStyles()
	return (
		<div className={classes.logoWrapper}>
			<img
				src={solvelogocropped}
				alt='logo'
				className={classes.logo}
				data-testid='logoImage'
			/>
		</div>
	)
}
