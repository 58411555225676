import { FC, useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

import { AppDirectionContext } from 'context/languages-context'

import useStyles from './styles'

import SendCard from 'components/common/MostRecent/SendCard'
import Card from 'components/common/Card'
import { Type } from 'screens/BotOutput'
import { useWindowWidth } from '../Header/useHooks/useWindowWidth'

import { DashboardPaths } from 'enums/routes/path-names'
import { ActionNameMapScreens } from 'utils/mapUrl'

interface Props {
	type: Type
	actionName: ActionNameMapScreens
	sentCoins: number
	receivedCoins: number
	recipient?: string
	sender?: string
	sentDate: string
	receivedDate: string
	sentDescription: string
	receivedDescription: string
	lastSentOutputId: string
	lastReceivedOutputId: string
}
const MostRecent: FC<Props> = ({
	type,
	actionName,
	sentCoins,
	receivedCoins,
	recipient = '',
	sender = '',
	sentDescription,
	receivedDescription,
	sentDate,
	receivedDate,
	lastSentOutputId,
	lastReceivedOutputId,
}) => {
	const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })

	const screenToReturn =
		type === Type.Feedback ? DashboardPaths.FEEDBACKS : DashboardPaths.PRAISES

	const mostRecent = (
		<Typography className={classes.mostRecentTitle}>
			{t(type === Type.Feedback ? 'most_recent_feedbacks' : 'most_recent_praises')}
		</Typography>
	)

	const sendCard = (
		<SendCard screenToReturn={screenToReturn} actionName={actionName} />
	)

	const windowWidth = useWindowWidth()
	const mobileWidth = 600
	const isMobile = mobileWidth >= windowWidth

	return (
		<div className={classes.container}>
			{!isMobile && mostRecent}
			<div className={classes.cardsContainer}>
				{isMobile && sendCard}
				{isMobile && mostRecent}
				<Card
					type={type}
					outputId={lastSentOutputId}
					sm={12}
					md={5}
					cardClassName={classes.card}
					chipClassName={classes.chip}
					chipLabel={t('sent')}
					coins={sentCoins}
					date={sentDate}
					description={sentDescription}
					name={recipient}
					sent
				/>
				<Card
					type={type}
					outputId={lastReceivedOutputId}
					sm={12}
					md={5}
					cardClassName={classes.card}
					chipClassName={classes.chip}
					chipLabel={t('received')}
					coins={receivedCoins}
					date={receivedDate}
					description={receivedDescription}
					name={sender}
				/>
				{!isMobile && sendCard}
			</div>
		</div>
	)
}

export default MostRecent
