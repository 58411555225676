import { Summary, DebriefEvent } from '../interface/debrief/debrief-interface'

export default (events: DebriefEvent[], payload: any) => {
	const updatedEventId = payload.event_id
	console.log({ payload })
	return events.map(event => {
		if (event._id === updatedEventId) {
			event.summaries?.map((summary: Summary) => {
				if (summary.event_id === payload.event_id) {
					const updatedLessons = summary.lessons.map(lesson => {
						if (lesson._id === payload.data._id) {
							return payload.data
						}
						return lesson
					})
					summary.lessons = [...updatedLessons]
				}
			})
		}
		return event
	})
}
