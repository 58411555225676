import { useState, useMemo } from "react"
import { Task } from "interface/todos/todos-interface"
import IUser from "interface/user/user-interface"
import { useDispatch, useSelector } from 'react-redux'
import { load_deleted_employee_tasks } from "redux/todos/todos-actions"
import { loadingTodos } from "redux/todos/todos.selector"
import classes from './styles.module.css'
import { useTranslation } from "react-i18next"
import { TodoState } from "enums/todos/todos.enum"

type Props = {
    isManagerViewing: boolean
    payload: any,
    selectedEmployee: IUser
    tasks: Task[]
}
const useDeletedTasks = ({ isManagerViewing, payload, selectedEmployee, tasks }: Props) => {
    const [shouldDispalyDeletedEmployeeTasks, setShouldDisplayDeletedEmployeeTasks] = useState<boolean>(false)

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const loading = useSelector(loadingTodos)

    const handleDisplayDeletedTasks = () => setShouldDisplayDeletedEmployeeTasks(prev => !prev)

    const showDeletedEmployeeTasks = isManagerViewing && shouldDispalyDeletedEmployeeTasks

    const handleFatchDeletedEmployeeTasks = () => {
        dispatch(
            load_deleted_employee_tasks({ user_id: selectedEmployee._id })
        )
    }

    const icon = <button
        className={!shouldDispalyDeletedEmployeeTasks ? classes.displayDeleteBtn : classes.displayDeleteBtnActive}
        onClick={handleDisplayDeletedTasks}
    />

    const loadOldDeletedTaskBtn =
        (<div className={classes.loadDeletedBtnWrapper}><button className={classes.loadDeletedTasks} onClick={handleFatchDeletedEmployeeTasks}>
            {loading ? t('loading...') : t('load_deleted_tasks')}</button></div>)

    const deletedTasks = tasks?.filter((task: Task) => !task.isManagerAction && task.state === TodoState.deleted)
    const employeeTasks = tasks?.filter((task: Task) => !task.isManagerAction && task.state !== TodoState.deleted)

    const emptyEmployeesTasks = useMemo(() => {
        if (!employeeTasks?.length) return (<p>{t('empty_active_employee_tasks')}</p>)

        if (shouldDispalyDeletedEmployeeTasks && !deletedTasks.length) {
            return loadOldDeletedTaskBtn
        }
    }, [tasks, shouldDispalyDeletedEmployeeTasks, loading])

    const emptyManagerTasks = useMemo(() => {
        const managerTasks = tasks?.filter((task: Task) => task.isManagerAction)
        if (!managerTasks?.length) return (<p>{t('empty_manager_tasks')}</p>)
    }, [tasks])

    return { deletedIcon: icon, emptyEmployeesTasks, emptyManagerTasks, showDeletedEmployeeTasks }
}

export default useDeletedTasks;