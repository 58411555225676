import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { debriefOnProccesSelector } from 'redux/debrief/debrief-selector'
import { Debrief } from 'interface/debrief/debrief-interface'
import { useMemo } from 'react'
import {
	RECONSTRUCTION_MESSAGE,
	FINISH,
	steps_list,
} from 'components/Debriefing/DebriefTree/stepMapComponent'

type Title = {
	title: string
	subTitle?: string
	infoTitle1?: string
	shrinkMainTitle?: boolean
}
const MAX_MATCH_POINTS = 3
const EMPTY_STATE = { title: '', subTitle: '', infoTitle1: '' }
const ACHIVED = 'debrief.achived_result'
const UNACHIVED = 'debrief.unachived_result'
const ACHIVED_AFFECT = 'debrief.achieved_effect'
const UNACHIVED_AFFECT = 'debrief.unachieved_effect'

export const useTitle = (): Title => {
	const debief: Debrief | null = useSelector(debriefOnProccesSelector)
	const { t } = useTranslation()

	return useMemo(() => {
		if (!debief) return EMPTY_STATE

		const { step, creator, expectedResult, events = [] } = debief

		if (!step) return EMPTY_STATE

		const isExpectedResultAchieved = expectedResult?.achieved
		const expectedResultDescription = expectedResult?.description
		const achivedText = isExpectedResultAchieved ? ACHIVED : UNACHIVED
		const effect = isExpectedResultAchieved ? ACHIVED_AFFECT : UNACHIVED_AFFECT
		const eventsCount = events.length

		const args = {
			name: creator?.personalInfo?.firstName,
			expectedResultDescription: `"${expectedResultDescription}"` ?? '',
			expectedResultAchieved: t(achivedText),
			effect: t(effect),
			choose_matchpoint_text_placeholder:
				eventsCount > MAX_MATCH_POINTS ? t('debrief.up_to_3_matchpoints') : '',
			interpolation: { escapeValue: false },
		}

		const { title, subTitle = '', ...rest } = titles[step] as Title

		return {
			title: t(title, args),
			subTitle: t(subTitle, args),
			...rest,
		}
	}, [debief?.step])
}

const {
	CHOOSE_DEPARTMENT,
	CHOOSE_GOAL,
	CHOOSE_PATH,
	CHOOSE_SUB_DEPARTMENT,
	CHOOSE_VALUE,
	CHRONOLOGICAL_EVENTS,
	DEBRIEFING_SELF_ANALISYS_CHAT,
	EXPECTED_RESULT,
	GROUP_PROCESS_MESSAGE,
	INITIAL_MESSAGE,
	LEARNING_PROCESS_OUTPUT,
	MATCHPOINT_CHOICE,
	MATCHPOINT_CONFIRMATION,
	PARTICIPANTS_CHOICE,
	SELF_ANALISYS_MESSAGE,
	SUMMARY,
	WRITE_DEPARTMENT,
	WRITE_SUB_DEPARTMENT,
} = steps_list

const titles = {
	[CHOOSE_PATH]: {
		title: 'debrief.start_a_debriefing',
		subTitle: 'debrief.select_debrief_type',
	},
	[INITIAL_MESSAGE]: EMPTY_STATE,
	[PARTICIPANTS_CHOICE]: {
		title: 'debrief.select_participants',
		subTitle: 'debrief.select_participants_sub_title',
	},
	[EXPECTED_RESULT]: {
		title: 'debrief.expected_result',
		subTitle: '',
		infoTitle1: '',
		shrinkMainTitle: true,
	},
	[CHOOSE_GOAL]: EMPTY_STATE,
	[CHOOSE_VALUE]: {
		title: 'debrief.value_reference',
		subTitle: 'values_choice_sub',
	},
	[CHRONOLOGICAL_EVENTS]: {
		title: 'debrief.chronological_events',
		subTitle: 'debrief.chronological_events_explained',
	},
	[MATCHPOINT_CHOICE]: {
		title: 'debrief.choose_matchpoint',
		subTitle: 'debrief.choose_matchpoint_text',
	},
	[MATCHPOINT_CONFIRMATION]: {
		title: 'debrief.matchpoint_confirmation',
		subTitle: 'debrief.matchpoint_confirmation_explained',
	},
	[SELF_ANALISYS_MESSAGE]: EMPTY_STATE,
	[DEBRIEFING_SELF_ANALISYS_CHAT]: EMPTY_STATE,
	[LEARNING_PROCESS_OUTPUT]: {
		title: 'debrief.learning_process_outputs',
		subTitle: 'debrief.learning_process_outputs_sub',
		shrinkMainTitle: true,
	},
	[SUMMARY]: {
		title: 'debrief.summary',
		subTitle: 'debrief.learning_process_outputs_sub',
		shrinkMainTitle: true,
	},

	[FINISH]: {
		title: 'debrief.summary',
		subTitle: 'debrief.learning_process_outputs_sub',
		shrinkMainTitle: true,
	},
	[GROUP_PROCESS_MESSAGE]: EMPTY_STATE,
	[CHOOSE_DEPARTMENT]: EMPTY_STATE,
	[CHOOSE_SUB_DEPARTMENT]: EMPTY_STATE,
	[WRITE_DEPARTMENT]: EMPTY_STATE,
	[WRITE_SUB_DEPARTMENT]: EMPTY_STATE,
	[RECONSTRUCTION_MESSAGE]: EMPTY_STATE,
}
