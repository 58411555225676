import { FC, useRef, useState } from 'react'
import ActionsButton, { UserAnswer } from '../ActionButtons/index'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { getBotAnswers } from 'redux/bot/bot-selector'
import { userSelector } from '../../../redux/user/user-selector'
import { AnswersGroupProp } from '../../../interface/bot/index'
import { useStyles } from './useStyles'
import { useSelector } from 'react-redux'
import { replaceString } from 'utils/replaceNameBot'
import { UseClickActions } from '../useHooks/index'
import ScrollableContent from 'components/common/ScrollableContent/ScrollableContent'

const SingleChoiceAlt: FC<AnswersGroupProp> = ({
	isPraise,
	setQuestion,
	answers,
	nextQuestion,
	previousQuestion,
	type,
}) => {
	const _answers = useSelector(getBotAnswers)
	const userSelecor = useSelector(userSelector)
	const classes = useStyles()
	const recipientName = _answers.answers[0] ? _answers.answers[0].answer : ''
	const senderName = userSelecor?.personalInfo?.firstName
		? userSelecor.personalInfo.firstName
		: ''
	const { addAnswer } = UseClickActions(isPraise)

	const content = (
		<Box className={classes.wrapper}>
			<Grid
				container
				rowSpacing={2}
				columnSpacing={{ xs: 2, sm: 2, md: 3.5 }}
				id='answerCard'
			>
				{answers?.map(({ title, nextQuestion: _nextQuestion, outputType }, id) => {
					const answerTitle = replaceString(title, {
						recipientPlaceholder: recipientName,
						senderPlaceholder: senderName,
					})
					const answer: UserAnswer = {
						id,
						answer: answerTitle,
						nextQuestion: _nextQuestion ? _nextQuestion : nextQuestion!,
						type,
						outputType: outputType,
					}

					return (
						<Grid item xs={12} md={6} className={classes.answerCardWrapper} key={id}>
							<div
								key={id}
								className={classes.answerCard}
								onClick={() => addAnswer(answer)}
							>
								{answerTitle}
							</div>
						</Grid>
					)
				})}
			</Grid>
		</Box>
	)
	return (
		<>
			<ScrollableContent content={content} />
			<ActionsButton
				isEnabled
				type={type}
				isPraise={isPraise}
				showPreviousButton
				previousQuestionId={previousQuestion}
				setQuestion={setQuestion}
			/>
		</>
	)
}

export default SingleChoiceAlt
