import { License } from 'enums/License/License.enum'
import { DashboardPaths } from 'enums/routes/path-names'
import { Roles } from 'enums/user/user-roles'
import {
	DashboardNavIcon,
	FeedbacksNavIcon,
	GoalsNavIcon,
	PraisesNavIcon,
	DebriefingsNavIcon,
	KCINavIcon,
	ContactUsIcon,
	HRSettingsIcon,
	ManagerGoalsIcon,
} from '../../../NavigationBar/SvgIcons/index'

export enum PERMISSION_KEYS {
	employeePerformance = 'EMPLOYEE_PERFORMANCE',
	lessonsManagement = 'LESSONS_MANAGEMENT'
}

export type Link = {
	linkName: string
	link: string
	NavIcon: React.FC<any>
	inActiveLink?: boolean
	authLevels: Roles[]
	licenceType?: License[]
	permissions?: any[]
}

export const links: Array<Link> = [
	{
		linkName: 'main',
		link: DashboardPaths.MAIN,
		NavIcon: DashboardNavIcon,
		authLevels: [Roles.EMPLOYEE, Roles.HR, Roles.MANAGER],
		permissions: [],
	},
	{
		linkName: 'i_see_you',
		link: DashboardPaths.PRAISES,
		NavIcon: PraisesNavIcon,
		authLevels: [Roles.EMPLOYEE, Roles.HR, Roles.MANAGER],
		permissions: [],
	},
	{
		linkName: 'i_need_you',
		link: DashboardPaths.FEEDBACKS,
		NavIcon: FeedbacksNavIcon,
		authLevels: [Roles.EMPLOYEE, Roles.HR, Roles.MANAGER],
		permissions: [],
	},
	{
		linkName: 'performance',
		link: DashboardPaths.GOALS,
		NavIcon: GoalsNavIcon,
		authLevels: [Roles.EMPLOYEE, Roles.HR, Roles.MANAGER],
		permissions: [],
	},
	{
		linkName: 'emp_performance',
		link: DashboardPaths.MANAGER_GOALS,
		NavIcon: ManagerGoalsIcon,
		authLevels: [Roles.HR, Roles.MANAGER],
		permissions: [PERMISSION_KEYS.employeePerformance],
	},
	{
		linkName: "start_new_learn_debrief",
		link: DashboardPaths.DEBRIEFINGS,
		NavIcon: DebriefingsNavIcon,
		//	inActiveLink: true, //MUTE_DEB
		authLevels: [Roles.EMPLOYEE, Roles.HR, Roles.MANAGER],
		licenceType: [License.silver, License.gold],
		permissions: [],
	},
	{
		linkName: 'HR_settings',
		link: DashboardPaths.SETTINGS,
		NavIcon: HRSettingsIcon,
		authLevels: [Roles.HR],
		permissions: [],
	},
	{
		linkName: 'contactUs',
		link: DashboardPaths.CONTACTUS,
		NavIcon: ContactUsIcon,
		authLevels: [Roles.EMPLOYEE, Roles.HR, Roles.MANAGER],
		permissions: [],
	},
	{
		linkName: 'kci',
		link: 'kci',
		inActiveLink: true,
		NavIcon: KCINavIcon,
		authLevels: [Roles.EMPLOYEE, Roles.HR, Roles.MANAGER],
		permissions: [],
	},
	{
		linkName: 'companies',
		link: DashboardPaths.SUPER_ADMIN_COMPANIES,
		NavIcon: DashboardNavIcon,
		authLevels: [Roles.SUPER_ADMIN],
		permissions: [],
	},
	{
		linkName: 'bot_settings',
		link: DashboardPaths.SUPER_ADMIN_BOT_SETTINGS,
		authLevels: [Roles.SUPER_ADMIN],
		NavIcon: HRSettingsIcon,
		permissions: [],
	},
	{
		linkName: 'output settings',
		link: DashboardPaths.SUPER_ADMIN_OUTPUT_SETTING,
		authLevels: [Roles.SUPER_ADMIN],
		NavIcon: DashboardNavIcon,
		permissions: [],
	},
	{
		linkName: 'summaries',
		link: DashboardPaths.SUPER_ADMIN_COMPANIES_SUMMARIES,
		authLevels: [Roles.SUPER_ADMIN],
		NavIcon: DashboardNavIcon,
		permissions: [],
	},
]