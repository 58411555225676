import React, { useContext } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AppDirectionContext } from 'context/languages-context'

export type TableCellProps = {
	content: React.ReactNode
	size: string
	active?: boolean
}
type TableRowProps = {
	isHeader?: boolean
	cells?: Array<TableCellProps>
	onToggleAddNewRow?: () => void
	AddNewRowIsOpen?: boolean
}
const useStyles = makeStyles({
	header: {
		fontSize: 13,
		color: '#8D9296',
	},
	tableRow: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		borderBottom: '1px solid #E0E0E0',
		boxSizing: 'border-box',
		padding: '0 50px',
		// backgroundColor: ({ cells, isHeader }: TableRowProps) => (cells?.map(({ active }) => (active ? 'grey' : 'blue')))
	},
	tableRowContent: ({ cells }: TableRowProps) => ({
		width: '100%',
		display: 'grid',
		columnGap: '10px',
		gridTemplateColumns: cells?.map(({ size }) => `minmax(0, ${size})`).join(' '),
	}),
})
const useCellStyles = makeStyles<Theme>(({ palette }) => ({
	tableCell: {
		height: '63px',
		display: 'flex',
		alignItems: 'center',
		color: palette.text.primary,
	},
}))

const TableCell: React.FC<TableCellProps> = ({ content, active }) => {
	const classes = useCellStyles({ active })

	return <div className={classes.tableCell}>{content}</div>
}

const TableRow: React.FC<TableRowProps> = props => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const { t } = useTranslation()
	const { cells, isHeader, onToggleAddNewRow, AddNewRowIsOpen } = props
	const classes = useStyles(props)
	const styles = [classes.tableRow, isHeader && classes.header]
		.filter(Boolean)
		.join(' ')
	const addButtonStyle = {
		margin: isRtlDirection ? '0 3rem 0 auto' : '0 auto 0 3rem',
		cursor: 'pointer',
		fontSize: 18,
		width: 'fit-content',
		color: '#0071C2',
		height: 'fit-content',
	}
	const handleOpenAddNewRow = () => {
		onToggleAddNewRow && onToggleAddNewRow()
	}
	const headerStyle = isHeader
		? {
			zIndex: 1,
			backgroundColor: 'white',
		}
		: {}
	return (
		<div
			style={{
				position: isHeader ? 'sticky' : 'relative',
				top: 0,
				zIndex: 0,
				...headerStyle,
			}}
		>
			{isHeader && (
				<div onClick={handleOpenAddNewRow} style={{ ...addButtonStyle }}>
					{!AddNewRowIsOpen && t('add_new')}
				</div>
			)}
			<div className={styles}>
				<div className={classes.tableRowContent}>
					{cells?.map((cell, index) => (
						<span
							style={{
								fontWeight: isHeader ? 'bolder' : undefined,
								fontSize: isHeader ? 14 : undefined,
							}}
						>
							<TableCell {...cell} key={index} />
						</span>
					))}
				</div>
			</div>
		</div>
	)
}

export default TableRow
