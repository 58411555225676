import { Modal, Box } from '@material-ui/core'
import useStyles from './styled'
import ModalButtonsContainer from './ModalButtonsContainer'
import { useContext } from 'react'
import { AppDirectionContext } from 'context/languages-context'

type Prop = {
	isOpen: boolean
	child?: JSX.Element | undefined
	removeButtons?: boolean
	text?: string | JSX.Element | null
	closeButtonText?: string
	confirmButtonText?: string
	onClose?: () => void
	onDiscard?: () => void
	onConfirm?: () => void | undefined
	buttonsStyle?: string
	modalStyle?: string
	isConfirmAccepted?: boolean
}
const ConfirmModal = (props: Prop) => {
	const {
		isOpen,
		child,
		text,
		removeButtons,
		closeButtonText,
		confirmButtonText,
		modalStyle,
		buttonsStyle,
		isConfirmAccepted,
	} = props

	const { onConfirm, onClose, onDiscard } = props

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const buttonsProps = {
		isRtlDirection,
		onClose,
		onConfirm,
		onDiscard,
		closeButtonText,
		confirmButtonText,
		buttonsStyle,
	}
	return (
		<Modal
			open={isOpen}
			onClose={() => {
				onClose && onClose()
				onDiscard && onDiscard()
			}}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
		>
			<Box
				className={
					!modalStyle
						? classes.contentModalStyle
						: [classes.contentModalStyle, modalStyle].join(' ')
				}
			>
				<div>
					{text && <p className={classes.text}>{text}</p>}
					{child}
				</div>

				{!removeButtons && (
					<ModalButtonsContainer
						{...buttonsProps}
						acceptConfirm={isConfirmAccepted}
					/>
				)}
			</Box>
		</Modal>
	)
}
export default ConfirmModal
