import { FindDebriefPayload } from "interface/debrief/debrief-interface"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { get_one_debrife } from "redux/debrief/debrief-actions"
import { debriefActions } from "redux/debrief/debrief-reducer"
import { userDebriefingsSelector } from "redux/debrief/debrief-selector"

export const useDebriefOnProcess = () => {
	const debriefings = useSelector(userDebriefingsSelector)
	const dispatch = useDispatch()

	const setDebriefOnProcess = useCallback(
		({ company_id, debrief_id, user_id }: FindDebriefPayload) => {
			const currentDebrief = debriefings.find(debrief => debrief._id == debrief_id)
			if (currentDebrief) {
				dispatch(debriefActions.setDebriefOnProcess(currentDebrief))
				return
			}

			dispatch(get_one_debrife({ company_id, debrief_id, user_id }))
		},
		[debriefings]
	)
	return setDebriefOnProcess
}