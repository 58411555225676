import { DashboardPaths } from 'enums/routes/path-names'

import Main from 'screens/Main'
import FeedbacksScreen from 'screens/Feedbacks'
import GoalsScreen from 'screens/Goals'
import PraisesScreen from 'screens/Praises'
import TasksScreen from 'screens/Tasks'
import ContactUs from 'components/ContactUs'
import HrSettingsScreen from 'screens/HRSettings'
import DebriefingScreen from 'screens/Debriefing'
import ManagerGoalsScreen from 'screens/ManagerGoals'

import { IRoute } from 'interface/routes/IRoute'
import LessonsManagementScreen from 'screens/LessonsManagement/index'

const dashboardRoutes: IRoute[] = [
	{
		path: DashboardPaths.MAIN,
		component: Main,
		exact: true,
	},
	{
		path: DashboardPaths.FEEDBACKS,
		component: FeedbacksScreen,
		exact: true,
	},
	{
		path: DashboardPaths.PRAISES,
		component: PraisesScreen,
		exact: true,
	},
	{
		path: DashboardPaths.GOALS,
		component: GoalsScreen,
		exact: true,
	},
	{
		path: DashboardPaths.TASKS,
		component: TasksScreen,
		exact: true,
	},
	{
		path: DashboardPaths.CONTACTUS,
		component: ContactUs,
		exact: true,
	},
	{
		path: DashboardPaths.SETTINGS,
		component: HrSettingsScreen,
		exact: true,
	},
	{
		path: DashboardPaths.DEBRIEFINGS,
		component: DebriefingScreen,
		exact: true,
	},
	{
		path: DashboardPaths.MANAGER_GOALS,
		component: ManagerGoalsScreen,
		exact: true,
	},
	{
		path: DashboardPaths.LESSONS_MANAGEMENT,
		component: LessonsManagementScreen,
		exact: true
	}
]
export default dashboardRoutes
