import { FC, useMemo } from 'react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { Chip, Grid, GridSize } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import useStyles from './styles'

import { praisesLoadingSelector } from 'redux/praise/praise-selector'

import { OutputType, Type } from 'screens/BotOutput/index'
import Paths, { DashboardPaths } from 'enums/routes/path-names'
import { feedbackLoadingSelector } from 'redux/feedback/feedback-selector'
import AppSkeleton from 'utils/skeleton/AppSkeleton'

interface Props {
	type?: Type
	outputId?: string
	cardClassName?: string
	chipClassName: string
	chipLabel: string
	coins: number
	date: string
	description: string
	name: string
	sent?: boolean
	sm?: GridSize
	md?: GridSize
}

const Card: FC<Props> = ({
	type,
	cardClassName,
	chipClassName,
	chipLabel,
	outputId,
	date,
	description,
	sent,
	name,
	sm,
	md
}) => {
	const classes = useStyles({ date })
	const { t } = useTranslation()
	const history = useHistory()

	const feedbackLoading = useSelector(feedbackLoadingSelector)
	const praiseLoading = useSelector(praisesLoadingSelector)
	const isLoading = useMemo(() => (praiseLoading || feedbackLoading), [feedbackLoading, praiseLoading])

	const isFeedback = type === Type.Feedback

	const getScreenType = (): OutputType => {
		if (!isFeedback) return sent ? OutputType.PraiseViewing : OutputType.PraiseReciever

		return sent ? OutputType.FeedbackViewing : OutputType.FeedbackReciever
	}

	const sendUserToFeedbackProcess = () => {
		const URL = sent
			? Paths.SENT_FEEDBACK_PROCESS
			: Paths.RECEIVED_FEEDBACK_PROCESS

		history.push(URL, {
			id: outputId,
			type: Type.Feedback,
			screenToReturn: DashboardPaths.FEEDBACKS,
			outputScreenType: getScreenType(),
		})
	}

	const sendUserToPraiseScreen = () => {
		history.push(`/output/${outputId}`, {
			type,
			screenToReturn: DashboardPaths.PRAISES,
			outputScreenType: getScreenType(),
		})
	}

	const handleCardClick = () => {
		if (outputId) {
			if (isFeedback) {
				sendUserToFeedbackProcess()
				return
			}

			sendUserToPraiseScreen()
		}
	}

	return (
		<Grid
			onClick={handleCardClick}
			item
			sm={sm}
			md={md}
			className={`${cardClassName} ${classes.container}`}
		>
			{!isLoading
				? <div className={classes.chipAndCoins} data-testid="test">
					<Chip
						label={chipLabel}
						color={isFeedback ? 'secondary' : 'primary'}
						className={`${classes.chip} ${chipClassName}`}
					/>
				</div>
				: <AppSkeleton width={42} height={24} times={1} />}

			{!isLoading
				? <p className={classes.date}>
					{date ? dayjs(date).format('DD/MM/YYYY') : '""'}
				</p>
				: <AppSkeleton className={classes.date} width={150} height={15} times={1} />}

			{!isLoading
				? <p className={classes.description}>{description}</p>
				: <AppSkeleton width={250} height={40} times={1} />}

			{!isLoading
				? <div className={classes.toFromContainer}>
					{name ? (
						<>
							<span className={classes.toFrom}>
								{sent ? t('sent_to') : t('received_from')}
							</span>
							<span className={classes.name}>{name}</span>
						</>
					) : (
						''
					)}
				</div>
				: <AppSkeleton width={100} height={19} times={1} />}

		</Grid>
	)
}

export default Card
