import { FC, useContext } from 'react'
import { AppDirectionContext } from "context/languages-context"
import { DirectEmployee, ManagerException } from "interface/exceptions/exceptions-interface"
import useStyles from './styled'
import { EmployeesRecord } from './EmployeesRecord'

type ExcRow = {
    manager: ManagerException
}
export const Row: FC<ExcRow> = props => {
    const { manager } = props

    const { isRtlDirection } = useContext(AppDirectionContext)
    const classes = useStyles({ isRtlDirection })

    const { personalInfo: { firstName, lastName }, employees } = manager

    const totalExceptions = getTotalExceptions(employees)

    return (
        <div className={[classes.row, classes.body].join(' ')}>
            <div>{firstName + ' ' + lastName}</div>

            <div>{employees.map((emp: DirectEmployee) => (
                <EmployeesRecord dirEmp={emp} />))}
            </div>

            <div>{totalExceptions}</div>

        </div>
    )
}
const getTotalExceptions = (employees: DirectEmployee[]) => {
    let count = 0
    for (const { exceptions } of employees) {
        count += exceptions
    }
    return count
}