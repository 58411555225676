import { makeStyles, Theme } from '@material-ui/core'
import ScrollableContent, {
	useScroll,
} from 'components/common/ScrollableContent/ScrollableContent'

import { useTranslation } from 'react-i18next'
import { IThemeOptions } from 'theme'
import { backgroundIconsMap } from 'utils/iconsMapper'
import { useStyles } from '../../Bot/SingleChoice/useStyles'

export interface DebriefSingleChoiceAnswer {
	title: string
	icon?: string
	glowed?: boolean
	selected?: boolean
}

interface Props {
	backgroundIcon?: string
	answers: DebriefSingleChoiceAnswer[]

	handleAnswerChoice: (value: string) => void
}
export const SingleChoice = ({
	answers,
	backgroundIcon,
	handleAnswerChoice,
}: Props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const innerClasses = useInnerStyles()
	const content = (
		<div className={classes.singleChoiceWrapper}>
			{answers.map((answer: DebriefSingleChoiceAnswer) => {
				return (
					<div
						style={{ color: answer.glowed ? '#017CC9' : '#46484A' }}
						className={
							answer.selected
								? `${classes.answerCard} ${innerClasses.answerCard} ${innerClasses.selected}`
								: `${classes.answerCard} ${innerClasses.answerCard} `
						}
						onClick={() => handleAnswerChoice(answer.title)}
					>
						{answer.icon && (
							<img style={{ marginInlineEnd: '5%' }} src={answer.icon} />
						)}
						{answer.title}
					</div>
				)
			})}
		</div>
	)

	return (
		<>
			<ScrollableContent content={content} />
			{backgroundIcon && (
				<div className={classes.imageWrapper}>
					<img
						className={classes.icon}
						src={backgroundIconsMap[backgroundIcon]}
						alt='backgroundIcon'
					/>
				</div>
			)}
		</>
	)
}

export default SingleChoice
const useInnerStyles = makeStyles<IThemeOptions & Theme>(
	({ breakpoints, palette }) => ({
		selected: {
			borderColor: palette.debrief.main,
			boxShadow: `5px 5px 10px ${palette.debrief.shadow}`,
		},
		answerCard: {
			'&:hover': {
				borderColor: palette.debrief.main,
				boxShadow: `5px 5px 10px ${palette.debrief.shadow}`,
			},
			[breakpoints.down('sm')]: {
				width: '92%',
			},
		},
	})
)
