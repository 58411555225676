import { useContext } from 'react'

import { AppDirectionContext } from 'context/languages-context'

import classes from './styles.module.css'

interface Props {
	onStepChange: (step: number) => void
	barWidth: string
	tabWidth: string
	stepsData: { label: string; value: number }[]
	currentStep: number
}
const StepsBar = ({
	onStepChange,
	currentStep,
	stepsData,
	tabWidth,
	barWidth,
}: Props) => {

	const handleStepClick = (stepValue: number) => {
		onStepChange(stepValue)
	}

	const { isRtlDirection } = useContext(AppDirectionContext)

	return (
		<div>
			<div className={classes.tabContainer} style={{ width: barWidth }}>

				{stepsData.map(step => {
					const isActive = step.value === currentStep
					const border = isActive ? '0' : '1px solid #EBEBEB'

					let style = {}
					if (step.value === 0) {
						isRtlDirection
							? (style = {
								padding: '1.2% 1.2% 1.2% 4.5%',
								borderLeft: border,
							})
							: (style = {
								padding: '1.2% 1.2% 1.2% 4.5%',
								borderRight: border,
							})
					} else if (step.value === stepsData.length - 1) {
						style = { padding: '1.2% 4.5% 1.2% 1.2%' }
					} else {
						isRtlDirection
							? (style = { padding: '1.2%', borderLeft: border })
							: (style = { padding: '1.2%', borderRight: border })
					}

					return (
						<div
							style={{ ...style, width: tabWidth }}
							className={isActive ? `${classes.tab} ${classes.active}` : classes.tab}
							onClick={() => handleStepClick(step.value)}
						>
							{step.label}
						</div>
					)
				})}

			</div>
		</div>
	)
}

export default StepsBar
