import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import arrowDownIcon from '../../../assets/arrowDown.svg'
import { useWindowWidth } from '../Header/useHooks/useWindowWidth'

interface Props {
	content: any
	contentLength?: number
}

const ScrollableContent = ({ content, contentLength }: Props) => {
	const contentRef = useRef<HTMLDivElement | null>(null)
	const classes = useStyles()
	const { t } = useTranslation()
	const windowWidth = useWindowWidth()
	const mobileWidth = 600
	const isMobile = mobileWidth >= windowWidth
	const { shouldRenderIndicator, handleScroll } = useScroll(
		contentRef,
		contentLength
	)

	const mobileScrollContainer = (
		<div className={classes.scrollContainer}>
			<div
				className={classes.contentWrapper}
				onScroll={handleScroll}
				ref={contentRef}
			>
				{content}
			</div>
			{shouldRenderIndicator && (
				<div className={classes.scrollNotification}>
					<div>{t('scroll_down')}</div>
					{<img src={arrowDownIcon} />}
				</div>
			)}
		</div>
	)
	return isMobile ? mobileScrollContainer : content
}

export default ScrollableContent
export const useScroll = (
	contentRef: React.MutableRefObject<HTMLDivElement | null>,
	contentLength: number | undefined
) => {
	const [shouldRenderIndicator, setShouldRenderIndicator] = useState(false)
	const handleScroll = useCallback(() => {
		const { scrollTop = 0 } = contentRef.current ?? {}
		setShouldRenderIndicator(!scrollTop)
	}, [])
	useLayoutEffect(() => {
		if (contentLength) {
			const contentWrapper = contentRef.current
			contentWrapper?.scrollTo({ top: contentWrapper?.scrollHeight })
		}
	}, [contentLength])
	useLayoutEffect(() => {
		const { scrollHeight = 0, offsetHeight = 0 } = contentRef.current ?? {}
		setShouldRenderIndicator(scrollHeight > offsetHeight)
	}, [contentRef.current?.children])
	return { shouldRenderIndicator, handleScroll }
}
const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
	contentWrapper: {
		overflowY: 'auto',
		width: '100%',
	},
	scrollContainer: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		height: '40%',

		[breakpoints.down('sm')]: {
			height: '100%',
			position: 'relative',
			flex: 1,
			overflowY: 'hidden',
		},
	},
	scrollNotification: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		color: '#46484a',
		width: '100%',
		backgroundColor: 'white',
		display: 'flex',
		alignItems: 'center',
		borderTop: '2.8px solid rgba(49, 49, 49, 0.152716)',
		justifyContent: 'center',
		outline: '2px solid white',
		padding: '1%',
		height: '6.5%',
		'& > div': {
			margin: ' 0 2% 0 2%',
		},
		'& > img': {
			width: 'fit-content',
		},
	},
}))
