import { FC, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import classes from './styles.module.css'

import blockageIcon from '../../../assets/blockageIcon.svg'
import redOkayIcon from '../../../assets/redOkayIcon.svg'

import {
	companyPraisesTemplatesSelector,
	receivedPraisesSelector,
} from 'redux/praise/praise-selector'

import UserAvatar from 'components/common/Avatar'
import Header from '../Header'
import Question from './Question'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'
// import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal'

import { FeedbackOutputWrapperProps } from 'interface/bot'
import IUser from 'interface/user/user-interface'

import { wallOfPraiseReplacementMap } from 'components/Dashboard/WallOfPraise/PraiseCard'

import { AppDirectionContext } from 'context/languages-context'

import { buildReplacment } from 'utils/outputs/helpers'
import { Context, PraiseOutput } from '../../../interface/bot/index'
import { Langs } from '../../../enums/app/index'
import {
	fetch_company_praises,
	fetch_company_praises_templates,
	get_praises,
} from 'redux/praise/praise-actions'
import { ContextText } from 'components/BotOutput'

interface Props {
	receiver: IUser
	setAnswers: (answer: boolean | null, question: string) => void
	answers: Record<any, boolean | null>
	formFocus: boolean
	resetFocus: () => void
}

const Confirmation: FC<FeedbackOutputWrapperProps & Props> = props => {
	const {
		resetFocus,
		formFocus,
		setAnswers,
		answers,
		sender,
		receiver,
		CONTEXT,
		FREE_TEXT_LABEL,
		FREE_TEXT,
		IMPACTS,
		CONTRIBUTION,
		URGENCYLEVEL,
		VALUES,
		DATE,
	} = props
	const templates = useSelector(companyPraisesTemplatesSelector)
	const receivedPraises = useSelector(receivedPraisesSelector)

	const [isNotifications, setIsNotifications] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const q1Ref = useRef<HTMLDivElement>(null)
	const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const dispatch = useDispatch()

	useEffect(() => {
		if (formFocus) {
			q1Ref.current?.scrollIntoView({ behavior: 'smooth' })
			resetFocus()
		}
	}, [formFocus])

	useEffect(() => {
		if (answers.q1 && answers.q2 && answers.q3 && !answers.agreed) {
			setIsModalOpen(true)
		}
	}, [answers])

	useEffect(() => {
		if (sender?._id && !receivedPraises.length) {
			dispatch(
				get_praises({
					company_id: sender?.company_id!,
					user_id: sender?._id,
				})
			)
			dispatch(fetch_company_praises_templates())
		}
	}, [sender, receivedPraises])

	if (!sender?._id || !receiver?._id || !templates)
		return <div>Loading data...</div>

	const [praise = {} as PraiseOutput<IUser>] = receivedPraises?.filter(
		praise => praise.sender._id === sender._id
	)

	const connectivityWord = t('and')
	const isReciever = true
	const rtlDirection = false

	const { '%PARTICIPANTS%': participants, '%REASON%': reason } = buildReplacment(
		praise,
		isReciever,
		rtlDirection,
		connectivityWord
	)

	const senderName = sender.personalInfo.firstName
	const receiverName = receiver.personalInfo.firstName

	const cntx = praise?.outputType
		? templates![praise.outputType].context[praise.context as Context]
		: ({} as Record<string, Langs>)

	const _context = cntx ?? {}

	const language = sender.statusInfo.companyLanguage

	const getLastPraiseMapped = () => {
		const { reasonOfPraise, contribution } = wallOfPraiseReplacementMap({
			senderName,
			receiverName,
			contributions: praise?.contributions ?? '',
			reason,
			participants,
			templates: templates![praise.outputType]?.wallOfPraise ?? {},
			context: _context[language] ? _context[language] : ' ',
			language: language,
		})
		return reasonOfPraise + '. ' + contribution
	}

	const handleAnswer = (answer: boolean | null, question: string) => {
		setAnswers(answer, question)
		if (question === 'q3') {
			setIsNotifications(true)
		}
	}

	const questionProps = {
		isBlur: answers.agreed!,
		showNotification: isNotifications,
		contactInfo: {
			name: senderName,
			mobile: sender.personalInfo.mobile,
		},
		handleAnswer: handleAnswer,
	}

	const hasImpacts = props?.impactsExpansion?.length
	const { impactsExpansion = [] } = props
	const redOkay = <img className={classes.redOkayIcon} src={redOkayIcon} />

	const q1Info = (
		<>
			<div className={classes.infoTitle}>{t('event_details')}</div>
			<div className={classes.infoRow}>
				{redOkay}
				{CONTEXT}
			</div>
			{(FREE_TEXT_LABEL || FREE_TEXT) && (
				<>
					{FREE_TEXT_LABEL && (
						<div className={classes.infoRow}>
							{redOkay}
							{FREE_TEXT_LABEL}
						</div>
					)}
					<div className={classes.freeText}>{FREE_TEXT}</div>
				</>
			)}
		</>
	)

	const q2Info = (
		<>
			<div className={classes.infoTitle}>
				{t('impact_on_sender_goals', {
					senderName,
					interpolation: { escapeValue: false },
				})}
			</div>
			{CONTRIBUTION && (
				<div className={classes.infoRow}>
					{redOkay}
					{CONTRIBUTION}
				</div>
			)}
			{props?.contributionExpansion ? (
				<ContextText label='' text={props?.contributionExpansion} />
			) : null}
			{IMPACTS?.length > 0 && (
				<>
					<div className={classes.infoTitle}>{t('further_impact')}</div>
					{IMPACTS.map((impact, index) => (
						<>
							<div className={classes.infoRow}>
								{redOkay}
								{impact} <br />
							</div>
							{!!hasImpacts && impactsExpansion[index] && (
								<ContextText label='' text={impactsExpansion[index]} />
							)}
						</>
					))}
				</>
			)}
			{VALUES?.length > 0 && (
				<>
					{/* <div className={classes.infoTitle}>
						{t('contribution_to_success_feedback', { userName: senderName })}
					</div> */}

					<div className={classes.infoRow}>
						{redOkay}
						{t('conduct_value_receiver_feedback', {
							username: senderName,
							value: VALUES.join(','),
							interpolation: { escapeValue: false },
						})}
					</div>
				</>
			)}{' '}
		</>
	)

	return (
		<div
			className={
				isRtlDirection
					? `${classes.confirmationContainer} ${classes.rtl}`
					: classes.confirmationContainer
			}
		>
			<Header
				title={t('hi_user', {
					user: receiverName,
					interpolation: { escapeValue: false },
				})}
				explanation={t('received_feedback', {
					senderName,
					interpolation: { escapeValue: false },
				})}
				paragraph={[t('read_details')]}
				urgency={URGENCYLEVEL}
			/>

			{praise !== undefined && (
				<div className={classes.lastPraise}>
					{t('last_praise_received', {
						senderName,
						interpolation: { escapeValue: false },
					})}

					<br />
					<span>{getLastPraiseMapped()}</span>
				</div>
			)}
			<div className={classes.participants}>
				{renderParticipant(receiver)}
				<img className={classes.blockageImg} src={blockageIcon} />
				{renderParticipant(sender)}
			</div>
			<div className={classes.divider}></div>
			<div
				className={
					isRtlDirection
						? `${classes.confirmationActions} ${classes.rtl}`
						: classes.confirmationActions
				}
				style={{
					pointerEvents: answers.agreed ? 'none' : 'unset',
				}}
			>
				<div className={classes.borderCircle}></div>
				<div ref={q1Ref}>
					<Question
						answer={answers.q1}
						question='q1'
						{...questionProps}
						questionString={t('clear_event_details')}
						info={q1Info}
					/>
				</div>
				<Question
					answer={answers.q2}
					question='q2'
					{...questionProps}
					questionString={t('clear_sender_affect', {
						senderName,
						interpolation: { escapeValue: false },
					})}
					info={q2Info}
				/>

				<Question
					{...questionProps}
					answer={answers.q3}
					question='q3'
					questionString={t('would_you_help_sender', {
						senderName,
						interpolation: { escapeValue: false },
					})}
				/>
				<ConfirmModalSB
					onConfirm={() => {
						handleAnswer(true, 'q4')
					}}
					onClose={() => {
						setIsModalOpen(false)
					}}
					onDiscard={() => {
						setAnswers(true, 'reset')
						setIsNotifications(false)
					}}
					isOpen={isModalOpen}
					text={t('confirm_feedback')}
					closeButtonText={t('confirm_feedback_button_close')}
					confirmButtonText={t('confirm_feedback_button_confirm')}
				/>
				<div className={classes.borderCircle}></div>
			</div>
		</div>
	)
}

export default Confirmation

export const renderParticipant = (
	participant: IUser,
	framed: boolean = true,
	small: boolean = false
) => {
	const participantWrapperClassNmae = framed
		? `${classes.participant} ${classes.framed}`
		: classes.participant

	const avatarStyles = small
		? `${classes.avatar} ${classes.small}`
		: classes.avatar

	const fontSizeStyle = { fontSize: small ? '70%' : '90%' }

	return (
		<div style={fontSizeStyle} className={participantWrapperClassNmae}>
			<UserAvatar className={avatarStyles} userInfo={participant} />
			<div className={classes.userInfo}>
				<div className={classes.fullName}>
					{participant?.personalInfo.firstName +
						' ' +
						participant?.personalInfo.lastName}
				</div>
				<div className={classes.title}>{participant?.statusInfo.title}</div>
			</div>
		</div>
	)
}
