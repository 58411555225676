import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import {
    AddCircleOutlineOutlined,
    RemoveCircleOutlineOutlined
} from '@material-ui/icons'

import './style.css'

import { userSelector } from "redux/user/user-selector"

import { setHasInclude } from "utils/hasSetInclude"
import colors from "utils/colors"
import UserAvatar from "components/common/Avatar"
import { getFullName } from "components/HRsettings/DepartmentBuilder/builderHelper"

import IUser from "interface/user/user-interface"

type Props = {
    user: IUser
    selectedAccessUsers: Set<string>
    updatePermissionsList: (_id: string) => void
}
const SelectionAccessCard = ({ user, selectedAccessUsers, updatePermissionsList }: Props) => {
    const { _id: currentUserId } = useSelector(userSelector)
    const { t } = useTranslation()

    const userFullname = user._id !== currentUserId ? getFullName(user) : t('you')

    const isSelected = useMemo(() => (
        setHasInclude(selectedAccessUsers, user._id)
    ), [selectedAccessUsers, user])

    const removedIconColor = { color: colors.lightgrey }

    return (
        <div className='user-card' onClick={() => updatePermissionsList(user._id)}>
            <UserAvatar userInfo={user} />

            <div className='user-info'>
                <p className='name'>{userFullname}</p>
                <p className='email'>{user.securityInfo.email}</p>
            </div>

            <div>
                {isSelected
                    ? <AddCircleOutlineOutlined />
                    : <RemoveCircleOutlineOutlined style={removedIconColor} />}
            </div>
        </div>
    )
}

export default SelectionAccessCard 