import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { lessonCopiedSelector } from 'redux/user/user-selector'

import './style.css'

import fileUpload from 'assets/fileUpload.svg'
import { userActions } from 'redux/user/user-reducer'
import editIcon from 'assets/lessons-edit.svg'
import deleteIcon from 'assets/lessons-delete.svg'
import { ItemFileActionsType } from '../props'

const FileButtons = ({ folder_id, toggleEditLesson, deleteLesson }: ItemFileActionsType) => {
    const copiedLessonId = useSelector(lessonCopiedSelector)
    const dispatch = useDispatch()

    const onLessonCopied = () => {
        dispatch(userActions.lessonIsCopied(folder_id))
    }

    const overridIconsStyle = 'mui-icons'

    return (
        <>
            <div>
                {!copiedLessonId && (<img
                    src={fileUpload}
                    className={overridIconsStyle}
                    alt='copy-lesson'
                    onClick={onLessonCopied} />)
                }
                <img src={editIcon} onClick={toggleEditLesson} className={overridIconsStyle} />
                <img src={deleteIcon} onClick={() => deleteLesson?.(folder_id!)} className={overridIconsStyle} />
            </div>
        </>
    )
}

export default FileButtons