export enum SOURCE {
	FEEDBACK_ACTION_PLAN = 'feedback_action_plan',
	LESSON = 'lesson',
	TODO = 'todo',
	TEAM = 'team'
}

export enum TaskSection {
	GENERAL = 'my_action_items',
	LESSONS = 'my_action_lessons',
}
