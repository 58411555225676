import { useState } from 'react'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'

type SelectLanguageListProps = {
	paddingTop?: string
	selectedLang: (lang: string) => void
}
export const AvailableLangs: Record<string, string> = {
	en: 'Enlish',
	he: 'Hebrew',
	fr: 'Franch',
}
export default function SelectLanguageList({
	selectedLang,
	paddingTop,
}: SelectLanguageListProps) {
	const [open, setOpen] = useState(false)
	const [currentLanguage, setLanguage] = useState(AvailableLangs['en'])

	const handleClick = () => {
		setOpen(!open)
	}

	const handleSelectedLang = (lang: string) => {
		setLanguage(AvailableLangs[lang])
		selectedLang(lang)
		handleClick()
	}

	return (
		<Box
			sx={{
				paddingTop: paddingTop ? '3%' : '',
				width: '100%',
				maxWidth: 360,
				bgcolor: 'background.paper',
			}}
		>
			<ListItemButton onClick={handleClick}>
				Select language ({currentLanguage})
			</ListItemButton>

			<Collapse
				style={{
					backgroundColor: 'white',
					position: 'absolute',
					width: '100%',
					maxWidth: 360,
					zIndex: 3
				}}
				in={open}
				timeout='auto'
				unmountOnExit
			>
				<List component='div' disablePadding>
					{Array.from(Object.keys(AvailableLangs), (langKey, index) => {
						return (
							<ListItem
								style={{ border: '1px solid #bdbdbd' }}
								onClick={() => handleSelectedLang(langKey)}
								key={index}
								component='div'
								disablePadding
							>
								<ListItemButton>
									<ListItemText primary={AvailableLangs[langKey]} />
								</ListItemButton>
							</ListItem>
						)
					})}
				</List>
			</Collapse>
		</Box >
	)
}