import { BaseCompanyOutput } from '../interface/bot/index'
import IUser from '../interface/user/user-interface'

export const splitAlgorithm = (
	data: Array<BaseCompanyOutput<IUser>>,
	currentUserId: string
): Record<string, Array<BaseCompanyOutput<IUser>>> => {
	let outputs: Record<string, Array<BaseCompanyOutput<IUser>>> = {
		sentMapped: [],
		receivedMapped: [],
		drafts: [],
	}

	if (!data.length) return outputs // if no data we reset!

	for (let outputIndex = 0; outputIndex < data.length; outputIndex++) {
		const output = data[outputIndex]
		const isSender = output.sender._id === currentUserId
		const isSenderOutput = !output.isDraft && isSender
		const isDraftOutput = output.isDraft && isSender

		if (!isSender) outputs.receivedMapped.push(output)
		if (isSenderOutput) outputs.sentMapped.push(output)
		if (isDraftOutput) outputs.drafts.push(output)
	}

	return outputs
}

/* Old version!
export const splitAlgorithm = (
	data: Array<BaseCompanyOutput<IUser>>,
	currentUserId: string
): Record<string, Array<BaseCompanyOutput<IUser>>> => {
	if (data.length) {
		const drafts = data.filter(output => {
			if (output.isDraft && output.sender._id === currentUserId) {
				return output
			}
		})

		const sentMapped = data.reduce(
			(
				sentOutputsAcc: BaseCompanyOutput<IUser>[],
				currentOutput: BaseCompanyOutput<IUser>
			) => {
				if (!currentOutput.isDraft && currentOutput.sender._id === currentUserId) {
					return [...sentOutputsAcc, currentOutput]
				}

				return [...sentOutputsAcc]
			},
			[]
		)
		const receivedMapped = data?.reduce(
			(
				receivedOutputsAcc: BaseCompanyOutput<IUser>[],
				currentOutput: BaseCompanyOutput<IUser>
			) => {
				if (currentOutput.sender._id !== currentUserId) {
					return [...receivedOutputsAcc, currentOutput]
				}

				return [...receivedOutputsAcc]
			},
			[]
		)
		return { sentMapped, receivedMapped, drafts }
	}

	return { sentMapped: [], receivedMapped: [], drafts: [] }
}
*/
