import { FC, useReducer, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useStyles } from './learningOutputStyles'
import makeBotStyles from '../../Bot/ActionButtons/useStyles'

import {
	debriefLoadingSelector,
	debriefOnProccesSelector,
} from 'redux/debrief/debrief-selector'
import { userSelector } from 'redux/user/user-selector'

import { get_user_debriefings } from 'redux/debrief/debrief-actions'

import {
	reducer,
	SummeryHeader,
	initialState,
	ACTION_TYPES,
} from './SummeryHeader'
import ConfirmModalSB from '../../common/confirmModalSB/ConfirmModal'
import CreatorAvatar from './CreatorAvatar'
import UsersModal from './UsersListModal'
import SummeryTable from '../SummeryTable/index'
import { Loader } from 'components/common/Loader'
import { useInnerStyles } from '../ActionButtons/index'
import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'

import { Debrief } from 'interface/debrief/debrief-interface'
import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import { DebriefingPaths } from 'enums/debriefing/debriefing-paths.enum'
import { AppDirectionContext } from 'context/languages-context'

const MOBILE_WIDTH = 600

type Props = {
	isSummary?: boolean
	setData?: (data: Partial<Debrief>, step?: DebriefingSteps | undefined) => void
	setEnableNext?: () => void
}
const Learningprocessoutputs: FC<Props> = props => {
	const [state, dispatchLocalActions] = useReducer(reducer, initialState)

	const dispatch = useDispatch()
	const { t } = useTranslation()
	const classes = useStyles()

	const laoding = useSelector(debriefLoadingSelector)
	const user = useSelector(userSelector)
	const debrief: Debrief | null =
		useSelector(debriefOnProccesSelector) ?? ({} as Debrief)

	const { isShareListOpen, showEvents } = state
	const { _id, company_id } = user
	const { isSummary } = props

	const buttonClasses = makeBotStyles()
	const buttonInnerClasses = useInnerStyles({})
	const renderEventsList = useEventList()

	const windowWidth = useWindowWidth()
	const isMobile = MOBILE_WIDTH >= windowWidth

	const isGroup = debrief.path === DebriefingPaths.GROUP
	const isCreator = debrief.creator?._id === user._id
	const showShareIcon = isCreator && !isMobile
	const enableRfresh = isGroup && isCreator && company_id
	const canEdit = isCreator && debrief.step !== DebriefingSteps.FINISH

	useEffect(() => {
		props.setEnableNext && props.setEnableNext()
	}, [debrief])

	const refreshMatchpointsData = () => {
		if (!debrief || !enableRfresh) return

		// NOTE:here refresh Match points Data!
		dispatch(get_user_debriefings({ user_id: _id, company_id, pageNumber: 0 }))
	}

	const handleShowEventsClick = () =>
		dispatchLocalActions?.({ type: ACTION_TYPES.TOGGLE_SHOW_EVENTS })
	const handleToggleShowIcon = () =>
		dispatchLocalActions?.({ type: ACTION_TYPES.TOGGLE_SHARE_ICON })
	return (
		<>
			<div className={classes.container}>
				{!isSummary && <CreatorAvatar creator={debrief.creator} />}

				{isSummary && (
					<SummeryHeader
						canEdit={canEdit}
						showShareIcon={showShareIcon}
						dispatch={dispatchLocalActions}
					/>
				)}

				{enableRfresh && (
					<button
						className={`${classes.refreshButton} ${buttonClasses.nextButton} ${buttonInnerClasses.nextButton}`}
						onClick={refreshMatchpointsData}
					>
						{t('debrief.refresh')}
					</button>
				)}
				{laoding ? (
					<Loader size={150} height={'70vh'} />
				) : (
					<SummeryTable
						isLeader={isCreator}
						users={[...debrief?.participants!, debrief.creator!]}
					/>
				)}
			</div>

			<UsersModal
				handleClickAway={handleToggleShowIcon}
				isOpen={isShareListOpen}
			/>

			<ConfirmModalSB
				isOpen={showEvents}
				onClose={handleShowEventsClick}
				child={renderEventsList}
				removeButtons
			/>
		</>
	)
}
export default Learningprocessoutputs

export const useEventList = () => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const debrief = useSelector(debriefOnProccesSelector)
	const classes = useStyles()

	if (!debrief) return <div>Loading...</div>

	const { events = [] } = debrief
	const direction = isRtlDirection ? 'rtl' : 'ltr'
	
	const renderEventsList = (
		<ul style={{ direction }}>
			{events.map(event => (
				<li className={classes.styledEvent}>- {event.description}</li>
			))}
		</ul>
	)
	return renderEventsList
}
