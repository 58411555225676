import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '@mui/material'

import { AppDirectionContext } from "context/languages-context"

import useStyles from "./styled"

import { ActivityUserAccess } from "./useEmployeesActivities"

export interface ActivityRowProps {
    RowDescription: string
    color: string
    totalRow?: boolean
    names: ActivityUserAccess[]
    criteriaData: Record<string, Record<string, number>>
    subTitle?: boolean
}
export const Row = ({
    RowDescription,
    color,
    totalRow,
    names,
    criteriaData,
    subTitle = false,
}: ActivityRowProps) => {
    const { isRtlDirection } = useContext(AppDirectionContext)
    const classes = useStyles({ color, totalRow, subTitle, isRtlDirection })
    const { t } = useTranslation()
    const divider = (<Divider orientation='vertical' />)

    return (
        <>
            <div className={[classes.row, classes.body].join(' ')}>
                <div>
                    <p>{t(RowDescription)}</p>
                </div>
                {!subTitle &&
                    names.map(({ _id }) => (
                        <div key={_id}>
                            <div>{criteriaData[_id]?.total}</div>
                            {divider}
                            <div>{criteriaData[_id]?.lastMonth}</div>
                        </div>
                    ))}

                {subTitle &&
                    names.map(({ _id }) => (
                        <div key={_id}>

                            <div>{t('total')}</div>
                            {divider}
                            <div>{t('last_month')}</div>

                        </div>
                    ))}
            </div>
        </>
    )
}