import { makeStyles, lighten } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
type Style = {
	isHeb?: boolean
}

export default makeStyles<Theme, Style>(({ palette }) => ({
	itemContainer: {
		cursor: 'pointer',
		width: '305px',
		minHeight: '80px',
		padding: '13px 11.5px 0',
		display: 'flex',
		position: 'relative',
		margin: '3px 13.5px 0',
		transition: 'background 0.1s ease-in-out',

		'&:hover': {
			background: lighten(palette.action.disabled, 0.6),
		},
	},
	newNotificationContainer: {
		background: lighten(palette.action.disabled, 0.6),
	},
	avatar: {
		width: '46px',
		height: '46px',
		background: palette.secondary.light,
	},
	avatarName: {
		margin: 0,
		fontWeight: 600,
		fontSize: '16px',
		color: palette.text.primary,
	},
	content: {
		maxWidth: '46%',
		marginInlineStart: '18px',
		display: 'flex',
		flexDirection: 'column',
	},
	name: {
		fontWeight: 700,
		marginBottom: '6px',
	},
	description: {
		fontSize: '13px',
		maxWidth: '240px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-line-clamp': '2',
		'-webkit-box-orient': 'vertical',
	},
	feedbackLink: {
		fontSize: '12px',
		fontWeight: 700,
		color: palette.secondary.main,
		margin: '15px 0',
		textDecoration: 'underline',
	},
	date: {
		maxWidth: '30%',
		position: 'absolute',
		top: '13px',
		right: ({ isHeb }) => (isHeb ? '' : '11.5px'),
		left: ({ isHeb }) => (isHeb ? '11.5px' : ''),
		color: palette.secondary.main,
		fontSize: '12px',
	},
}))
