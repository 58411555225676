export enum ModalOperations {
    NEW_FOLDER = 'new_folder',
    NEW_FILE = 'new_file',
    NEW_TITLE = 'new_title',
    EDIT_FOLDER = 'edit_folder',
    EDIT_FILE = 'edit_file',
    NEW_LESSON = 'new_lesson',
    DELETE_FOLDER = 'delete_folder',
    DELETE_FILE = 'delete_file',
    ATTACHED = 'attached_lesson',
    DELETE_LESSON = 'delete_lesson'
}