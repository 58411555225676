import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useState, useEffect, useLayoutEffect } from 'react'

import routes from 'utils/routes'
import PrivateRoute from 'utils/PrivateRoute'
import 'App.css'

import AppLogic from './appLogic'
import { AppDirectionContext } from 'context/languages-context'

import Footer from './components/Footer/index'

import SocketContext from 'context/socket-context'
import socket from 'utils/socket'
import SessionTimeout from 'components/SessionTimeout/SessionTimeout'
import { Langs } from './enums/app/index'
import NotFound from 'utils/NotFound'
import { useRetreiveConnctedUser } from 'utils/useRetreiveConnctedUser'
import { inisializeI18n } from 'i18n/config'
import { UIConfigContext, defaultUIConfig } from 'context/UIConfig-context'
import { isUserFromMobile } from 'utils/isUserFromMobile'
import { getItem, setItem } from 'utils/localStorage'

const extraStyles = {
	width: '100vw',
	// height: '100vh', Gidi comment: the height caused problem in main screens
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
} as any

inisializeI18n(Langs.en)

function App() {
	const [isRtlDirection, setDirection] = useState(false)
	// const [uiConfig, setUIConfig] = useState(defaultUIConfig)
	const currentUser = useRetreiveConnctedUser()

	AppLogic()

	useLayoutEffect(() => {
		if (isUserFromMobile(window.location?.search)) {
			//Todo: disable take photo
			setItem('disableTakePhoto', true)
			return
		}
		// Todo: Enable take photo
		setItem('disableTakePhoto', false)

		return () => {}
	}, [])

	const toggleDirection = (isRtl: boolean) => setDirection(isRtl)
	return (
		<div style={extraStyles} className={isRtlDirection ? `app rtl` : 'app'}>
			<AppDirectionContext.Provider value={{ isRtlDirection, toggleDirection }}>
				<SocketContext.Provider value={socket}>
					<UIConfigContext.Provider
						value={{
							disableTakePhoto: JSON.parse(getItem('disableTakePhoto')),
						}}
					>
						<Router>
							<Switch>
								{routes.map((route, index) => {
									return route.public ? (
										<Route {...route} key={index} />
									) : (
										<PrivateRoute {...route} key={index} />
									)
								})}
								<Route path='*' exact={true} component={NotFound} />
							</Switch>
						</Router>
					</UIConfigContext.Provider>
				</SocketContext.Provider>
				<Footer />
			</AppDirectionContext.Provider>
			<SessionTimeout user_id={currentUser?._id} />
		</div>
	)
}

export default App
