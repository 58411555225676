import React, { FC, useState, useRef, useCallback, useEffect } from 'react'
import moment from 'moment'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'
// import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal'
import { useDispatch } from 'react-redux'
import { interval_job } from 'redux/user/user-actions'
import { userActions } from 'redux/user/user-reducer'
import { useHistory } from 'react-router-dom'
import { range } from 'utils/getRangeOfNumbers'
import { useTranslation } from 'react-i18next'

type SessionTimeout = {
    user_id: string
}

const SessionTimeout: FC<SessionTimeout> = ({ user_id }) => {
    const [events, setEvents] = useState(['click', 'mousemove', 'keydown'])
    const [second, setSecond] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const { t } = useTranslation()

    const text = t('inactive_user_message', { second })
    const history = useHistory()
    const dispatch = useDispatch()

    let timeStamp: any
    let warningInactiveInterval = useRef<any>()
    let startTimerInterval = useRef<any>()

    let timeChecker = () => {
        startTimerInterval.current = setTimeout(() => {
            const storedTimeStamp = sessionStorage.getItem('lastTimeStamp')!
            warningInactive(storedTimeStamp)
        }, 10000)
    };

    let warningInactive = (timeString: string) => {
        clearTimeout(startTimerInterval.current)
        warningInactiveInterval.current = setInterval(() => {

            const popTime = 10
            const logoutTime = 30

            const diff = moment.duration(moment().diff(moment(timeString)))
            const minPast = diff.minutes()
            const leftSecond = 1_800 /*30 minutes in seconds */ - diff.seconds()
            if (range(0, popTime).indexOf(leftSecond) !== -1) {
                setSecond(leftSecond)
                setIsOpen(true)
            }

            if (minPast == logoutTime) {
                clearInterval(warningInactiveInterval.current)
                hanldingSignOut()
            }

        }, 1000)
    }
    const hanldingSignOut = () => {
        const payload = {
            user_id: user_id,
            loginAction: false
        }
        dispatch(userActions.signOutUser())
        // dispatch(interval_job(payload))
        history.push(`/`)
    }

    let resetTimer = useCallback(() => {
        clearTimeout(startTimerInterval.current)
        clearInterval(warningInactiveInterval.current)

        timeStamp = moment()
        sessionStorage.setItem('lastTimeStamp', timeStamp)
        timeChecker()
        setIsOpen(false)
    }, [])

    useEffect(() => {
        if (!user_id) return
        events.forEach((event) => {
            window.addEventListener(event, resetTimer) //listen to each event that defined above and reset the timer as well
        })

        timeChecker() //by firing any event checking timer

        return () => { //cleanup function that stopping timer
            clearTimeout(startTimerInterval.current)
        }
    }, [resetTimer, events, timeChecker])

    if (!isOpen) {
        return null;
    }

    return (
        <ConfirmModalSB isOpen={isOpen} text={text} />
    )
}

export default SessionTimeout
