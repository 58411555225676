import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import LoginForm from 'components/LoginForm'
import {
	userLoadingSelector,
	userSuccessMessage,
} from 'redux/user/user-selector'
import useStyles from '../useStyles'
import './style.scss'
import { SolveLoginLogo } from '../SolveLoginLogo'

export const LoginScreenNode: FC<{}> = () => {
	const classes = useStyles()
	const { t } = useTranslation()
	const successMessage = useSelector(userSuccessMessage)
	const [isLoginContent, setIsLoginContent] = useState(true)
	const loading = useSelector(userLoadingSelector)
	// const phoneIcon = <i className={['phone', 'isAnimating'].join(' ')} />
	// const loader = <div id="loader"></div>

	return (
		<>
			<div className={classes.loginContainer}>
				<SolveLoginLogo />
				<h1 className={classes.loginTitle}>
					{isLoginContent ? t('login') : t('Forgot Password')}
				</h1>
				<h3 className={classes.loginDesc}>
					{t('login_message')}
					{/* {isLoginContent
						? t('login_message')
						: loading ? loader : phoneIcon} */}
				</h3>
				{successMessage ? (
					<div className={classes.userMessage}>
						{ReactHtmlParser(successMessage.toString())}
						{'any problem? contect-us'}
						<a href='mailto:support@saydotech.com' className={classes.anchor}>
							{' here'}
						</a>
					</div>
				) : null}
				<div className={classes.formContainer}>
					<LoginForm onContentChange={status => setIsLoginContent(status)} />
				</div>
			</div>
		</>
	)
}
