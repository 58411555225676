import { makeStyles } from "@material-ui/core"

export default makeStyles(({ palette }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        height: 45,
        width: 694,
        color: '#46484a',
        fontFamily: 'Sora, sans-serif',
        fontSize: 36,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    subTitle: {
        height: '63.13px',
        width: 438,
        color: '#46484a',
        fontFamily: 'Sora, sans-serif',
        fontSize: 16,
        textAlign: 'center',
        margin: 38,
    },
    additionalText: {
        margin: '16px 0 28px',

        color: '#0071C2',
        fontSize: '15px',
        fontWeight: 600,
        cursor: 'pointer',
        textAlign: 'center',
    },
    modalTitle: {
        color: palette.text.primary,
        fontFamily: 'Montserrat',
        fontSize: '24px',
        fontWeight: 'bold',
        marginTop: '19px',
    },
    description: {},
    summary: {},
    closeButton: {},
}))
