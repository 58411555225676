import { makeStyles, Theme } from '@material-ui/core'
import { IThemeOptions } from '../../../theme/index'

//inner styles
export default makeStyles<IThemeOptions & Theme>(({ palette }) => ({
	table: {
		'& > div:first-child': {
			'& > div:first-child': {
				'& > div:first-child': {
					'& span': {
						color: '#46484a',
					},
				},
				boxShadow: 'rgb(0 0 0 / 4%) 2px 2px',
				borderRadius: '10px',
			},
		},
	},
	tooltip: {},
	description: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	tabs: {
		'& div > span': {
			backgroundColor: `${palette.debrief.main} !important`,
		},
		'& button': {
			'& span': {
				color: `${palette.debrief.main} !important`,
			},
			color: 'green',
		},
	},
	debriefFinishButton: {
		fontSize: '90%',
		'&:hover': {
			backgroundColor: palette.debrief.shadow,
		},
		backgroundColor: palette.debrief.dark,
	},
	debriefButton: {
		fontSize: '90%',
		'&:hover': {
			backgroundColor: palette.debrief.shadow,
		},
		backgroundColor: '#7c6fd9',
	},
	tableHeader: {
		margin: '3% 0 3% 0',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}))
