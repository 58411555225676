type ErrorFallbackProps = {
	error: any
	resetErrorBoundary: any
}

export const ErrorFallback = (props: ErrorFallbackProps) => {
	const { error, resetErrorBoundary } = props
	return (
		<div role='alert'>
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
			<button onClick={resetErrorBoundary}>Try again</button>
		</div>
	)
}
