import { useState, ChangeEvent, FC } from 'react'

import { updateFeedbacksOutput } from 'api/feedback'
import { updatePraiseOutput } from 'api/praise'

import { RenderItem } from './RenderItem'
import { FullScrennDialog } from './FullScrennDialog'
import { Tab } from 'screens/OutputSettings/index'

import { Req } from 'enums/req/req.enum'
import { Langs } from 'enums/app/index'
import {
	OutputSettingType,
	OutputBeforeAfter,
	Context,
	BaseContext,
	PriaseOutputSettingType,
	WallOfPraiseOutput,
} from 'interface/bot/index'

type Props = {
	tabState: Tab
	output: OutputSettingType | PriaseOutputSettingType
	isOpen: boolean
	handleClickOpen: () => void
	handleClose: () => void
}

type EditeState = Record<string, Record<Langs, string>>
const goal = 'goal goal are reduced by x%.'
const beforeReplaceStringOnChange = (text: string) => {
	const changeContext =
		'בארבע עיניים/בשיחת טלפון/בישיבה/בשיחת מסדרון/במייל/בוואטסאפ/ברשת החברתית'
	const name = 'Susan'

	text = text.replace(changeContext, '%CONTEXT%')
	text = text.replaceAll(name, '%NAME%')

	if (text.search(goal)) {
		return text.replace(goal, 'goal %GOAL% by %PERCENT%%')
	}
	return text
}
const afterReplaceStringOnChange = (text: string) => {
	const name = 'Ellen'
	const date = 'today / yesterday / on MM-DD-YYYY or DD/MM/YYYY.'
	const t = text.replaceAll(name, '%NAME%')
	const afterDate = t.replaceAll(date, '%DATE%')
	if (text.search(goal)) {
		return afterDate.replace(goal, 'goal %GOAL% reduced by %PERCENT%%.')
	}

	return afterDate
}

function createInitialLangsObj() {
	let result: Record<Langs, string> = { ...Langs }
	for (let key in result) {
		result[key as Langs] = ''
	}
	return result
}
const initialLangsObj = createInitialLangsObj()

function isWallOfPraiseOnOutputTypeGuardGuard(
	output: OutputSettingType | PriaseOutputSettingType
) {
	return 'wallOfPraise' in output
		? output.wallOfPraise
		: {
			contributionTemplate: { ...initialLangsObj },
			reasonTemplate: { ...initialLangsObj },
		}
}

export default function FullScreenDialog({
	tabState,
	output,
	isOpen,
	handleClose,
}: Props) {
	const { title, beforeSend, afterSend } = output
	const wallOfPraiseOutput = isWallOfPraiseOnOutputTypeGuardGuard(output)
	const [afterSendedited, setAfterSendEdit] = useState<EditeState>({
		...afterSend,
	})
	const [beforeSendedited, setbeforeSendEdited] = useState<EditeState>({
		...beforeSend,
	})
	const [outputContext, setOutputContext] = useState<any>({
		...output.context,
	})
	const [wallOfPraiseTemplates, setWallOfPraiseTemplates] =
		useState<WallOfPraiseOutput>(wallOfPraiseOutput)
	const [language, setLanguage] = useState(Langs.en)

	const handleEditingChange = ({ target: { value, name: eventName } }: ChangeEvent<HTMLInputElement>) => {
		if (eventName === 'reason') {
			 setWallOfPraiseTemplates((prev: any) => ({
				...prev,
				reasonTemplate: {
					...prev.reasonTemplate,
					[language]: value,
				},
			}))
			return
		} else if (eventName === 'contribution') {
			setWallOfPraiseTemplates((prev: any) => ({
				...prev,
				contributionTemplate: {
					...prev.contributionTemplate,
					[language]: value,
				},
			}))
			return
		}
		const [key, ...rest] = eventName.split('-')
		let name = rest[0]
		if (key === 'before') {
			setbeforeSendEdited((prev: EditeState) => ({
				...prev,
				[name]: {
					...prev[name],
					[language]: beforeReplaceStringOnChange(value),
				},
			}))
			return
		} else if (key === 'after') {
			setAfterSendEdit((prev: EditeState) => ({
				...prev,
				[name]: {
					...prev[name],
					[language]: afterReplaceStringOnChange(value),
				},
			}))
		} else {
			name = eventName

			setOutputContext((prev: any) => ({
				...prev,
				[name]: {
					...prev[name],
					[language]: value,
				},
			}))
		}
	}

	const handleSaveButton = async () => {
		const basePayload: OutputSettingType = {
			...output,
			beforeSend: {
				...(beforeSendedited as OutputBeforeAfter),
			},

			afterSend: {
				...(afterSendedited as OutputBeforeAfter),
			},
			context: {
				...(outputContext as BaseContext),
			},
		}

		if (tabState === Tab.praise) {
			const payload: PriaseOutputSettingType = {
				...basePayload,
				wallOfPraise: {
					...(wallOfPraiseTemplates as WallOfPraiseOutput),
				},
			}
			updatePraise(payload)
			return
		}
		updateFeedback(basePayload)
	}

	const updatePraise = async (payload: OutputSettingType) => {
		const { status, data } = await updatePraiseOutput(payload)
		if (status === Req.failed) console.log({ data })

		handleClose()
	}

	const updateFeedback = async (payload: OutputSettingType) => {
		const { status, data } = await updateFeedbacksOutput(payload)
		if (status === Req.failed) console.log({ data })

		handleClose()
	}

	const handleChangeLang = (lang: string) => {
		if (lang !== language) setLanguage(lang as Langs)
	}

	const RenderChild = (
		<>
			<div style={{ textAlign: 'center', marginTop: '5%' }}>Before</div>
			<RenderBeforeOrAfterItem
				type='before'
				language={language}
				output={beforeSendedited}
				handleEditingChange={handleEditingChange}
			/>
			<div style={{ textAlign: 'center', marginTop: '5%' }}>After</div>

			<RenderBeforeOrAfterItem
				type='after'
				language={language}
				output={afterSendedited}
				handleEditingChange={handleEditingChange}
			/>

			{tabState === Tab.praise && (
				<>
					<div style={{ textAlign: 'center', marginTop: '5%' }}>Wall of Praise</div>
					<RenderWallOfPraiseSettings
						language={language}
						reasonTemplate={
							wallOfPraiseTemplates ? wallOfPraiseTemplates.reasonTemplate : ''
						}
						contributionTemplate={
							wallOfPraiseTemplates ? wallOfPraiseTemplates.contributionTemplate : ''
						}
						handleEditingChange={handleEditingChange}
					/>
				</>
			)}
			<div style={{ textAlign: 'center', marginTop: '5%' }}>Context Settings</div>
			<RenderContextSettings
				language={language}
				outputContext={outputContext}
				handleEditingChange={handleEditingChange}
			/>
		</>
	)

	return (
		<div>
			<FullScrennDialog
				title={title}
				handleChangeLang={handleChangeLang}
				renderChild={RenderChild}
				handleSaveButton={handleSaveButton}
				isOpen={isOpen}
				handleClose={handleClose}
			/>
		</div>
	)
}

const RenderBeforeOrAfterItem: FC<{
	type: string
	output: EditeState
	handleEditingChange: (event: ChangeEvent<HTMLInputElement>) => void
	language: Langs
}> = ({ type, output, language, handleEditingChange }) => {
	return (
		<>
			<RenderItem
				isRtl={language === Langs.he}
				placeholder='Reason'
				name={`${type}-aboutContext`}
				handleEditingChange={handleEditingChange}
				str={output.aboutContext ? output.aboutContext[language] : ''}
			/>
			<RenderItem
				isRtl={language === Langs.he}
				placeholder='Free Text'
				name={`${type}-freeTextDescriptions`}
				handleEditingChange={handleEditingChange}
				str={
					output.freeTextDescriptions ? output.freeTextDescriptions[language] : ''
				}
			/>

			<RenderItem
				isRtl={language === Langs.he}
				placeholder='Contribution'
				name={`${type}-contributionToSuccess`}
				handleEditingChange={handleEditingChange}
				str={
					output.contributionToSuccess ? output.contributionToSuccess[language] : ''
				}
			/>
		</>
	)
}
const RenderContextSettings: FC<{
	outputContext: any
	handleEditingChange: (event: ChangeEvent<HTMLInputElement>) => void
	language: Langs
}> = ({ outputContext, language, handleEditingChange }) => {
	const AllContexts = Array.from(Object.values(Context))
	return (
		<>
			{AllContexts.map(context => {
				if (!context) {
					return
				}
				let value = ''
				if (outputContext[context]) {
					if (outputContext[context][language]) {
						value = outputContext[context][language]
					}
				}
				return (
					<RenderItem
						isRtl={language === Langs.he}
						placeholder={context}
						name={context}
						handleEditingChange={handleEditingChange}
						str={value}
					/>
				)
			})}
		</>
	)
}
const RenderWallOfPraiseSettings: FC<{
	contributionTemplate: any
	reasonTemplate: any
	handleEditingChange: (event: ChangeEvent<HTMLInputElement>) => void
	language: Langs
}> = ({
	reasonTemplate,
	contributionTemplate,
	language,
	handleEditingChange,
}) => {
		return (
			<>
				<RenderItem
					isRtl={language === Langs.he}
					placeholder={'reason'}
					name={'reason'}
					handleEditingChange={handleEditingChange}
					str={reasonTemplate[language]}
				/>
				<RenderItem
					isRtl={language === Langs.he}
					placeholder={'contribution'}
					name={'contribution'}
					handleEditingChange={handleEditingChange}
					str={contributionTemplate[language]}
				/>
			</>
		)
	}
