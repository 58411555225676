import { StorageKey } from 'enums/localStorage/LocalStorage.enum'
import { getItem } from 'utils/localStorage'
export function uniqeId() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8
		return v.toString(16)
	})
}

export const isSameMonth = (storageKey = ''): boolean => {
	const currentMonth = new Date().getMonth()
	const expDate = getItem(storageKey ?? StorageKey.InitialMessageExpDt)
	return expDate === currentMonth
}
