import IUser from "interface/user/user-interface"

export function convertUsers(toConvert: string | string[], users: IUser[]) {
    console.log({ users }, { toConvert })
    let values: string[]
    if (typeof toConvert === "string") {
        values = [toConvert]
    } else {
        values = toConvert
    }
    console.log({ values })
    const objects = values.map((e: string) => users?.find(emp => emp?._id == e))

    return objects
}