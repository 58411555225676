import { useState } from 'react'
import { DropResult, DraggableLocation } from 'react-beautiful-dnd'
import { DroppableArea } from './droppable.enum'
import IUser from 'interface/user/user-interface'
import { useTranslation } from 'react-i18next'

const NUM_OF_ITEMS = 1
type Props = {
    members: IUser[]
    participants: IUser[]
    updateMembersList: (list: string[]) => void
}
const useDrag = ({ members, participants, updateMembersList }: Props) => {
    const [membersList, setMembersList] = useState<IUser[]>(members?.filter(m => m !== null))
    const [participantsList, setParticipantsList] = useState<IUser[]>(participants?.filter(p => p !== null))
    const [error, setError] = useState<string>('')
    
    const { t } = useTranslation()

    const handleDragEnd = (droppedAction: DropResult) => {
        if (isDroppedInWrongPlace(droppedAction)) return setError(t('wrong_area'))
        editMembersList(droppedAction)
    }
    const isDroppedInWrongPlace =
        ({ source, destination }: DropResult) => (source.droppableId === destination?.droppableId)

    const editMembersList = (droppedItem: DropResult) => {
        const { source, destination, draggableId: draggedIdCombinedSourceArea } = droppedItem
        const employee_id = removingSourceArea(draggedIdCombinedSourceArea)
    
        if (!destination) return
        const tempParticipants = participantsList
        const tempMembers = membersList

        if (source.droppableId === DroppableArea.MEMBERS) {
            const [draggedEmployee] = getDraggdEmployee(tempMembers, source.index)
            onSetParticipantsList(destination, tempParticipants, draggedEmployee)
            setMembersList(tempMembers)
            updateMembersList(mappedToId(tempMembers))
        }
        else {
            if (isDuplicateMember(tempMembers, employee_id)) return setError(t('already_member'))
            const [draggedEmployee] = getDraggdEmployee(tempParticipants, source.index)
            tempMembers.splice(destination.index, 0, draggedEmployee)
            const list = tempMembers.map((member => member._id))
            setMembersList(tempMembers)
            updateMembersList(mappedToId(tempMembers))
        }
    }
    const removingSourceArea = (draggableId: string) => (draggableId.split('-')[0]) // draggableId example `${emp._id}-${title}` => removing 'title'

    const mappedToId = (list: IUser[]) => (list.map((member => member._id)))

    const onSetParticipantsList =
        (destination: DraggableLocation, tempList: IUser[], draggedEmployee: IUser) => {
            if (!isDuplicateMember(tempList, draggedEmployee._id))
                participantsList.splice(destination.index, 0, draggedEmployee)
        }

    const isDuplicateMember = (destinationList: IUser[], current_id: string) => {
        if (!destinationList.length) return false
        if (!employeeAlreadyAttached(destinationList, current_id).length) return false

        return true
    }
    const employeeAlreadyAttached =
        (list: IUser[], current_id: string) => (list.filter(emp => emp._id === current_id))

    const getDraggdEmployee = (list: IUser[], index: number): IUser[] => {
        return list.splice(index, NUM_OF_ITEMS)
    }

    return { handleDragEnd, membersList, participantsList, error, setError }
}

export default useDrag