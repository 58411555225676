import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { companyValuesSelector } from 'redux/company/company.selector'
import { userSelector } from 'redux/user/user-selector'

import useDebriefActions from '../DebriefTree/useDebriefActions'
import getValuesSelectMenuItems from 'utils/companyValueSelectMenuItems'
import { DepartmentView } from './DepartmentView'
import BasicSelect from './BasicSelect'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'

import { Debrief } from 'interface/debrief/debrief-interface'

type Props = {
	debrief: Debrief
	canEdit: boolean
}
export const ValuesChoice: FC<Props> = ({ debrief, canEdit }) => {
	const [valuesIsOnEdit, setValuesIsOnEdit] = useState(false)
	const [showCantEditModal, setShowCantEditModal] = useState(false)

	const companyValues = useSelector(companyValuesSelector)
	const { _id, company_id } = useSelector(userSelector)

	const { t } = useTranslation()

	const valuesSelectMenuItems = getValuesSelectMenuItems(
		companyValues.concat(t('debrief.no_value'))
	)

	const { relatedValues = [] } = debrief

	const selectedValue = relatedValues?.join(', ')! ?? t('debrief.no_value')

	const handleUpdatedDescriptionOnClickAway = (selected: string) => {
		const data = {
			...debrief,
			relatedValues: [selected],
		}
		updateDebrief?.(data)
		handleDoubleClick()
	}

	const basicSelectNode = (
		<BasicSelect<string>
			setSelection={handleUpdatedDescriptionOnClickAway}
			currentSelection={selectedValue}
			disabled={!valuesIsOnEdit}
			selectMenuItems={valuesSelectMenuItems}
		/>
	)
	const value = !valuesIsOnEdit ? selectedValue : basicSelectNode

	const { updateDebrief } = useDebriefActions({
		debrief,
		isParticipant: false,
	})

	const handleDoubleClick = () => {
		if (!canEdit) return

		setValuesIsOnEdit(prev => !prev)
	}

	return (
		<>
			<DepartmentView
				handleDoubleClick={handleDoubleClick}
				value={value}
				placeholder='debrief.values'
			/>

			<ConfirmModalSB
				{...{
					isOpen: showCantEditModal,
					removeButtons: true,
					text: "You can't edit at the moment. please try later.",
					onClose: () => setShowCantEditModal(false),
				}}
			/>
		</>
	)
}
