import { Response } from 'interface/api/response-interface'
import { FetchUserTodos } from 'interface/api/get-payload.types'
import {
	DeleteTask,
	LazeLoadTasks,
	PayloadUpdateTask,
	RestoreTask,
	CreateTask,
} from '../interface/api/get-payload.types'
import { GET, PATCH, PUT, POST } from './api-requests'
import { URLS } from 'api/api-urls'
import { TodoStatusOptions } from 'enums/todos/todos.enum'

export const fetchUserTodos = async (
	payload: FetchUserTodos
): Promise<Response> => {
	const { user_id } = payload
	const { data, status } = await GET(
		`${URLS.COMPANY_TODOS}?user_id=${user_id}`
	)
	return { data, status }
}

export const loadMoreTasks = async (
	payload: LazeLoadTasks
): Promise<Response> => {
	const { todos_id, pageNumber, managerSide } = payload
	const { data, status } = await GET(
		`${URLS.COMPANY_TODOS}/load/${todos_id}?pageNumber=${pageNumber}&managerSide=${managerSide}`
	)
	return { data, status }
}

export const createTodo = async (payload: CreateTask): Promise<Response> => {
	const { data, status, code } = await POST(
		`${URLS.COMPANY_TODOS}/create`,
		payload
	)
	return { data, status, code }
}

export const updateTodo = async (
	payload: PayloadUpdateTask
): Promise<Response> => {
	const { data, status, code } = await PUT(
		`${URLS.COMPANY_TODOS}/update?owner=${payload.user_id}`,
		payload
	)
	return { data, status, code }
}

export const deleteTask = async (
	payload: DeleteTask
): Promise<Response> => {
	const { data, status, code } = await PUT(
		`${URLS.COMPANY_TODOS}/delete`, payload
	)
	return { data, status, code }
}

export const restoreTask = async (
	payload: RestoreTask
): Promise<Response> => {
	console.log({ payload })
	const { data, status } = await PATCH(
		`${URLS.COMPANY_TODOS}/restore`, payload
	)
	return { data, status }
}

export const toggleTasksState = async (payload: {
	company_id: string
	todos_id: string
	viewer: string
}): Promise<Response> => {
	const { company_id, todos_id, viewer } = payload
	const { data, status, code } = await PATCH(
		`${URLS.COMPANY_TODOS}/${company_id}?todos_id=${todos_id}&viewer=${viewer}`
	)
	return { data, status, code }
}

export const updateTaskStatus = async (payload: {
	company_id: string,
	status: TodoStatusOptions
	todo_id: string
}): Promise<Response> => {
	console.log(payload.todo_id)
	const { data, status } = await PUT(
		`${URLS.COMPANY_TODOS}/status/${payload.company_id}?todo_id=${payload.todo_id}&status=${payload.status}`
	)
	return { data, status }
}

export const loadDeletedTasks = async (
	payload: FetchUserTodos
): Promise<Response> => {
	const { user_id } = payload
	const { data, status } = await GET(
		`${URLS.COMPANY_TODOS}/load-deleted/${user_id}`
	)
	return { data, status }
}
