import classes from 'components/Goals/Goal/style.module.css'
import { GoalCardTextArea } from 'components/Goals/GoalCardTextArea'

export const getGoalTextAreaProps = (props: GoalCardTextArea) => {
	const { label, editMode, goalDesc } = props
	const style = { backgroundColor: editMode ? '#f5f5f5' : 'transparent' }
	return {
		label,
		style,
		disabled: !editMode,
		value: goalDesc,
		className: classes.content,
	}
}
