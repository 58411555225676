import { useDispatch, useSelector } from 'react-redux'
import { buildReplacment, mapOutputContext } from '../../utils/outputs/helpers'
import IUser from '../../interface/user/user-interface'
import {
	sentFeedbacksSelector,
	receivedFeedbacksSelector,
	searchFeedbacksSelector,
} from '../../redux/feedback/feedback-selector'
import {
	userSelector,
	preservUserInputSelector,
} from '../../redux/user/user-selector'
import {
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import {
	fetch_received_company_feedback_byIds,
	fetch_sent_company_feedback_byIds,
	get_feedbacks,
	load_more_feedbacks,
} from '../../redux/feedback/feedback-actions'
import { outputTabsDataMap, TabsData, TabType } from 'utils/outputTabsDataMap'
import { useTranslation } from 'react-i18next'
import { feedbacksDraftSelector } from '../../redux/feedback/feedback-selector'
import { FeedbackOutput } from 'interface/bot'
import { useTabsTemplates } from './useTabsTemplates'
import { OutputType, Type } from 'screens/BotOutput'
import { Langs } from '../../enums/app/index'
import sortTab from '../../utils/sortTab'
import filterTab from 'utils/filterTab'
import { userActions } from '../../redux/user/user-reducer'
import { debounceSearch } from 'utils/debounceSearch'
import { PraiseAndFeedbacksType } from 'enums/praise-state/praise-state.enum'
import useOutputFilterRule from 'utils/useOutputFilterRule'
import { FeedbackState } from 'enums/feedback-state/feedback-state.enum'
import { closestMatch } from 'components/Bot/UsersList/useHandlers'
import { isNotCurrentUser } from 'utils/isNotCurrentUser'
import { employeesSelector } from 'redux/employee/employee.selector'

enum OutputFilterRule {
	byEmployee,
	bySaydo,
	byBlockage,
}

interface Props {
	state: any
}
const PAGE_INCREMENTOR = 1

export const useLogic = (location: Props) => {
	const cahchedTab = location?.state?.tabState ?? 0

	const [shouldDisplayFilters, setShouldDisplayFilters] =
		useState<boolean>(false)
	const [sentPage, setSentPage] = useState(0)
	const [receivedPage, setReceivedPage] = useState(0)
	const [draftPage, setDraftPage] = useState(0)
	const [sentFilteredArray, setSentFilteredArray] = useState<
		TabsData<FeedbackOutput<IUser>>[]
	>([])
	const [receivedFilteredArray, setReceivedFilteredArray] = useState<
		TabsData<FeedbackOutput<IUser>>[]
	>([])
	const [tabNumber, setCurrentTab] = useState(cahchedTab)

	const { t } = useTranslation()
	const dispatch = useDispatch()

	const sentFeedbacks = useSelector(sentFeedbacksSelector)
	const receivedFeedbacks = useSelector(receivedFeedbacksSelector)
	const feedbacksDraft = useSelector(feedbacksDraftSelector)
	const currentUser = useSelector(userSelector)
	const userInput = useSelector(preservUserInputSelector)
	const employees = useSelector(employeesSelector)
	const searchedFeedbacks = useSelector(searchFeedbacksSelector)

	const { company_id, _id: user_id } = currentUser

	const { selectedEmployeeName, byBlockage, bySaydo } = useOutputFilterRule()
	const { tabsTemplate } = useTabsTemplates(Type.Feedback)

	useEffect(() => {
		const payload = {
			company_id: company_id,
			user_id: user_id,
		}

		dispatch(get_feedbacks(payload))
	}, [currentUser])

	const handleChangeTab = (newValue: SetStateAction<any>) => {
		console.log({ newValue })
		dispatch(userActions.resetOutputFilter())
		setCurrentTab(newValue)
	}

	const nextPage = (location: OutputType) => {
		let nextPageNumber = 0
		let type: PraiseAndFeedbacksType = PraiseAndFeedbacksType.SENT
		if (location == OutputType.FeedbackViewing) {
			nextPageNumber = sentPage + PAGE_INCREMENTOR
			setSentPage(nextPageNumber)
		}
		if (location == OutputType.FeedbackReciever) {
			nextPageNumber = receivedPage + PAGE_INCREMENTOR
			type = PraiseAndFeedbacksType.RECEIVED
			setReceivedPage(nextPageNumber)
		}
		if (location == OutputType.FeedbackDraft) {
			nextPageNumber = draftPage + PAGE_INCREMENTOR
			setDraftPage(nextPageNumber)
			type = PraiseAndFeedbacksType.DRAFT
		}
		const payload = {
			company_id: company_id,
			user_id: user_id,
			pageNumber: nextPageNumber,
		}
		dispatch(load_more_feedbacks({ ...payload, type: type }))
	}
	const filterOutputsByRule = useMemo((): OutputFilterRule | undefined => {
		if (selectedEmployeeName?.trim()) return OutputFilterRule.byEmployee
		if (bySaydo) return OutputFilterRule.bySaydo
		if (byBlockage) return OutputFilterRule.byBlockage
		else return undefined
	}, [bySaydo, byBlockage, selectedEmployeeName, tabNumber])

	const filterDataInCurrentTab = useCallback(() => {
		let filteredOutputsByFirstName
		const value =
			filterOutputsByRule && filterByEmployee(filterOutputsByRule!)
				? selectedEmployeeName
				: userInput
		if (filterBySaydo(filterOutputsByRule!)) {
			showFilters()
			if (tabNumber) setSentFilteredArray(saydoSentList())
			else setReceivedFilteredArray(saydoReceievdList())
			return
		}

		if (filterByBlocakages(filterOutputsByRule!)) {
			showFilters()

			if (tabNumber) {
				setSentFilteredArray(blockagesSentList())
			} else setReceivedFilteredArray(blockagesReceviedList())
			return
		}

		if (value === '') {
			console.log('reach value block')
			setShouldDisplayFilters(prev => false)
			return
		}

		if (tabNumber) {
			showFilters()
			filteredOutputsByFirstName = filterTab(sentFeedbacMapped, value!)
			if (!filteredOutputsByFirstName.length) setSentFilteredArray([])
			else setSentFilteredArray(filteredOutputsByFirstName)

			return
		}

		showFilters()
		filteredOutputsByFirstName = filterTab(receivedFeedbackMapped, value!)
		if (!filteredOutputsByFirstName.length) setReceivedFilteredArray([])
		else setReceivedFilteredArray(filteredOutputsByFirstName)
	}, [tabNumber, userInput, selectedEmployeeName])

	const saydoSentList = (): TabsData<FeedbackOutput<IUser>>[] => {
		return sentFeedbacMapped.filter(
			(output: TabsData<FeedbackOutput<IUser>>) =>
				output.feedbackState === FeedbackState.sayDo
		)
	}
	const saydoReceievdList = (): TabsData<FeedbackOutput<IUser>>[] => {
		return receivedFeedbackMapped.filter(
			(output: TabsData<FeedbackOutput<IUser>>) =>
				output.feedbackState === FeedbackState.sayDo
		)
	}
	const blockagesSentList = (): TabsData<FeedbackOutput<IUser>>[] => {
		return sentFeedbacMapped.filter(
			(output: TabsData<FeedbackOutput<IUser>>) =>
				output.feedbackState !== FeedbackState.sayDo
		)
	}
	const blockagesReceviedList = (): TabsData<FeedbackOutput<IUser>>[] => {
		return receivedFeedbackMapped.filter(
			(output: TabsData<FeedbackOutput<IUser>>) =>
				output.feedbackState !== FeedbackState.sayDo
		)
	}
	const filterByEmployee = (filterOutputsByRule: OutputFilterRule): boolean => {
		return filterOutputsByRule === OutputFilterRule.byEmployee
	}
	const filterBySaydo = (filterOutputsByRule: OutputFilterRule) => {
		return filterOutputsByRule === OutputFilterRule.bySaydo
	}
	const filterByBlocakages = (filterOutputsByRule: OutputFilterRule) => {
		return filterOutputsByRule === OutputFilterRule.byBlockage
	}

	useEffect(() => {
		filterDataInCurrentTab()
	}, [userInput, tabNumber])
	const showFilters = () => setShouldDisplayFilters(prev => true)
	const disableFilter = () => setShouldDisplayFilters(false)

	const debouncedSearch = debounceSearch(handleSearch, 500)

	const handleSearchTextChange = (
		{ target: { value } }: React.ChangeEvent<HTMLInputElement>,
		tabNumber: any
	) => {
		showFilters()
		dispatch(userActions.filterOutputsByEmployee(''))
		dispatch(userActions.saveInputUser(value))
	}
	function handleSearch(searchText: string) {
		// Perform your search logic here with the searchText
		console.log({ searchText })
		fetchSearchPraiseByTabs(searchText)
	}
	const memoizedEmployees = useMemo(
		() => employees.filter(e => isNotCurrentUser(e, currentUser)),
		[employees]
	)
	const searchSentPraises = (value: string) => {
		dispatch(
			fetch_sent_company_feedback_byIds({
				ids: closestMatch(memoizedEmployees, value).map(e => e._id),
			})
		)
	}
	const searchReceivedPraises = (value: string) => {
		dispatch(
			fetch_received_company_feedback_byIds({
				ids: closestMatch(memoizedEmployees, value).map(e => e._id),
			})
		)
	}
	const fetchSearchPraiseByTabs = useCallback(
		(value: string) => {
			if (tabNumber) {
				searchSentPraises(value)
				return
			}
			searchReceivedPraises(value)
		},
		[tabNumber]
	)

	const connectivityWord = t('and')

	const hasReceivedFeedbacks = !!receivedFeedbacks.length
	const hasSentFeedbacks = !!sentFeedbacks.length
	const hasMoreFeedbacksDraft = !!feedbacksDraft.length

	const lastSentReplacements = buildReplacment(
		{
			...sentFeedbacks[0],
			sender: sentFeedbacks[0]?.sender,
			receiver: sentFeedbacks[0]?.receiver,
		},
		false,
		currentUser?.statusInfo?.companyLanguage === Langs.en,
		connectivityWord
	)

	const lastReceivedReplacements = buildReplacment(
		{
			...receivedFeedbacks[0],
			sender: receivedFeedbacks[0]?.sender,
			receiver: receivedFeedbacks[0]?.receiver,
		},
		true,
		currentUser?.statusInfo?.companyLanguage === Langs.en,
		connectivityWord
	)

	const lastReceivedContext = mapOutputContext(
		false,
		lastReceivedReplacements,
		receivedFeedbacks[0],
		true
	)

	const lastSentContext = mapOutputContext(
		false,
		lastSentReplacements,
		sentFeedbacks[0],
		false
	)
	const searchedFeedbackMapped = useMemo(
		() =>
			outputTabsDataMap(
				tabNumber ? TabType.sent : TabType.receive,
				[...searchedFeedbacks].sort(sortTab),
				tabsTemplate,
				connectivityWord
			),
		[tabNumber, searchedFeedbacks.length]
	)

	const sentFeedbacMapped = useMemo(
		() =>
			outputTabsDataMap(
				TabType.sent,
				[...sentFeedbacks].sort(sortTab),
				tabsTemplate,
				connectivityWord
			),
		[tabNumber, sentFeedbacks.length]
	)

	const receivedFeedbackMapped = useMemo(
		() =>
			outputTabsDataMap<FeedbackOutput<IUser>>(
				TabType.receive,
				[...receivedFeedbacks].sort(sortTab),
				tabsTemplate,
				connectivityWord
			),
		[tabNumber, receivedFeedbacks.length]
	)

	const feedbacksDraftMapped = useMemo(() => {
		console.log({ feedbacksDraft })
		return outputTabsDataMap(
			TabType.draft,
			[...feedbacksDraft].sort(sortTab),
			tabsTemplate,
			connectivityWord
		)
	}, [tabNumber, feedbacksDraft.length])
	const resetTabsData = () => disableFilter()
	const filterSearchedFeedbacksByTabs = () => {
		if (!searchedFeedbackMapped.length) return

		if (tabNumber) {
			setSentFilteredArray(searchedFeedbackMapped)
			return
		}

		setReceivedFilteredArray(searchedFeedbackMapped)
	}

	const filterSentPraises = () => {
		if (!selectedEmployeeName) return
		const filteredOutputsByFirstName = filterTab(
			sentFeedbacMapped,
			selectedEmployeeName!
		)
		if (!filteredOutputsByFirstName.length) setSentFilteredArray([])
		setSentFilteredArray(filteredOutputsByFirstName)
	}

	const filterReceivedPraises = () => {
		if (!selectedEmployeeName) return
		const filteredOutputsByFirstName = filterTab(
			receivedFeedbackMapped,
			selectedEmployeeName!
		)
		if (!filteredOutputsByFirstName.length) setReceivedFilteredArray([])
		setReceivedFilteredArray(p => filteredOutputsByFirstName)
	}

	const filterPraisesByTabs = () => {
		if (tabNumber) {
			filterSentPraises()
			return
		}
		filterReceivedPraises()
	}

	useEffect(() => {
		console.log({ filterOutputsByRule })
		if (filterOutputsByRule) {
			return
		}

		if (!userInput) {
			resetTabsData()
			return
		}

		showFilters()
		debouncedSearch(userInput)
	}, [userInput, tabNumber])

	useEffect(() => {
		console.log({ filterOutputsByRule })
		if (filterOutputsByRule) {
			return
		}

		if (!searchedFeedbackMapped.length) {
			disableFilter()
			return
		}

		filterSearchedFeedbacksByTabs()
	}, [searchedFeedbackMapped.length, tabNumber])

	useEffect(() => {
		const isWorkMeeting = !!selectedEmployeeName?.length
		if (!isWorkMeeting) return

		showFilters()
		filterPraisesByTabs()

		return () => {
			dispatch(userActions.filterOutputsByEmployee(''))
		}
	}, [
		tabNumber,
		selectedEmployeeName,
		sentFeedbacMapped,
		receivedFeedbackMapped,
	])

	const lastSent = {
		...lastSentContext,
		_id: hasSentFeedbacks ? sentFeedbacks[0]._id : '',
		CONTEXT: hasSentFeedbacks
			? `${sentFeedbacMapped[0].description}`
			: t('lastSentFeedbackInitial'),
		name: hasSentFeedbacks ? lastSentReplacements['%NAME%'] : '',
		date: hasSentFeedbacks ? String(sentFeedbacks[0].time) : '',
	}

	const lastReceived = {
		...lastReceivedContext,
		_id: hasReceivedFeedbacks ? receivedFeedbacks[0]._id : '',
		CONTEXT: hasReceivedFeedbacks
			? `${receivedFeedbackMapped[0].description}`
			: t('lastReceivedFeedbackInitial'),
		name: hasReceivedFeedbacks ? lastReceivedReplacements['%NAME%'] : '',
		date: hasReceivedFeedbacks ? String(receivedFeedbacks[0].time) : '',
	}
	console.log({
		shouldDisplayFilters,
		receivedFilteredArray,
		receivedFeedbackMapped,
	})
	return {
		currentTab: tabNumber,
		feedbacksDraftMapped,
		hasMoreFeedbacksDraft,
		hasReceivedFeedbacks,
		hasSentFeedbacks,
		lastReceived,
		handleChangeCurrentTab: handleChangeTab,
		handleSearchTextChange,
		lastSent,
		sentFeedbacMapped: shouldDisplayFilters
			? sentFilteredArray
			: sentFeedbacMapped,
		receivedFeedbackMapped: shouldDisplayFilters
			? receivedFilteredArray
			: receivedFeedbackMapped,
		nextPage,
	}
}
