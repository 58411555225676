import useStyles from './useStyles'
import newBGImage2 from 'assets/newBGImage2.svg'

import PictureWithLogoJPG from 'assets/PictureWithLogoJPG.jpg'
import { LoginScreenNode } from './LoginScreenNode/index'
import { ChangePassword } from './ChangePasswordNode'
import LogoWithText from 'assets/Logofull2.png'

type ScreenWrapper = {
	children: React.ReactNode
}

export const ScreenWrapper = ({ children }: ScreenWrapper): JSX.Element => {
	const classes = useStyles()

	return (
		<div data-testid='loginContainer' className={classes.container}>
			{children}
			<picture>
				<source media='(max-width: 599px)' srcSet={newBGImage2} />
				<img
					src={PictureWithLogoJPG}
					alt='loginImage'
					className={classes.backgroundImage}
				/>
				{/* <img src={LogoWithText} className={classes.solveLogo} /> */}
			</picture>
		</div>
	)
}
ScreenWrapper.Login = LoginScreenNode
ScreenWrapper.ChangePassword = ChangePassword
