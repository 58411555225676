import { useTranslation } from 'react-i18next'

import { SpanWrapper } from './SpanWrapper'
import { UrgenyLevel } from 'components/BotOutput/UrgenyLevel'

import IUser from 'interface/user/user-interface'
import { UrgencyLevel as UrgencyLevelEnum } from 'enums/urgency/urgency.enum'

type Props = {
	sender: IUser
	receiver: IUser
	sendFeedbackOutput: (isDraft: boolean, urgency?: UrgencyLevelEnum) => void
	toggleModal: () => void
}
const useModalDescription = (props: Props) => {
	const { sender, receiver, sendFeedbackOutput, toggleModal } = props
	const { t } = useTranslation()

	const ContributionExplanationModal = (
		<>
			<SpanWrapper>{t('disabled_share_on_wall_explanation')}</SpanWrapper>
		</>
	)

	const UrgencyModalChild = (
		<>
			<SpanWrapper>
				{t('user,', {
					user: sender?.personalInfo?.firstName,
					interpolation: { escapeValue: false },
				})}{' '}
				<br />{' '}
				{t('when_would_you_like_to_get_respond', {
					username: receiver?.personalInfo?.firstName,
					interpolation: { escapeValue: false },
				})}
			</SpanWrapper>

			<UrgenyLevel
				handleClick={(urgencyLevel: UrgencyLevelEnum) => {
					sendFeedbackOutput(false, urgencyLevel!)
					toggleModal()
				}}
			/>
		</>
	)

	return { ContributionExplanationModal, UrgencyModalChild }
}

export default useModalDescription
