import { FC, useEffect, useMemo } from 'react'
import {
    ArgumentAxis,
    ValueAxis,
    BarSeries,
    Chart,
    Legend,
    Tooltip,
} from '@devexpress/dx-react-chart-material-ui'
import { EventTracker, Stack, Animation } from '@devexpress/dx-react-chart'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { get_company_chart } from 'redux/company/company-actions'
import { chartDataSelector } from 'redux/company/company.selector'

import useStyles from './styled'

import colors from 'utils/colors'

import { fixString } from 'utils/fixedString'

type ChartProps = {
    comapnyName: string
    company_id: string
}
const barsNames = [
    { name: "praises", color: colors.emeraldGreen },
    { name: "feedbacks", color: colors.boldBlue },
    { name: "persLearning", color: colors.grapes },
    { name: "groupLearning", color: colors.boldPurple },
    { name: "managersTodos", color: colors.orange },
    { name: "employeesTodos", color: colors.coffee }
]

const SuperAdminChart: FC<ChartProps> = ({ comapnyName, company_id }) => {

    const dispatch = useDispatch()
    const classes = useStyles({})
    const data = useSelector(chartDataSelector)

    useEffect(() => {
        if (!company_id) return
        dispatch(get_company_chart(company_id))
    }, [company_id])

    const extractArrays = useMemo(() => {
        return data
            .reduce((c: any, v: any) => c.concat(v), [])
            .map((o: any) => o.companyData)
    }, [data])

    const RootWithTitle = (props: any) => (
        <div>
            <h2 className={classes.chartTitle}>
                <span role="img" aria-label="family">
                    {`${comapnyName}`}
                </span>
                <p className={classes.titleFont}>Monthly Average Per User</p>
            </h2>
            <Legend.Root {...props} />
        </div>
    )
    const chartData = data.length! ? [
        {
            state: moment().month(extractArrays[0][0]?.state!).format("MMM"),
            praises: extractArrays[0][1]?.praises!,
            feedbacks: extractArrays[0][2]?.feedbacks!,
            persLearning: extractArrays[0][3]?.persLearning!,
            groupLearning: extractArrays[0][4]?.groupLearning!,
            managersTodos: extractArrays[0][5]?.managersTodos!,
            employeesTodos: extractArrays[0][6]?.employeesTodos!,
        },
        {
            state: moment().month(extractArrays[1][0]?.state!).format("MMM"),
            praises: extractArrays[1][1]?.praises!,
            feedbacks: extractArrays[1][2]?.feedbacks!,
            persLearning: extractArrays[1][3]?.persLearning!,
            groupLearning: extractArrays[1][4]?.groupLearning!,
            managersTodos: extractArrays[1][5]?.managersTodos!,
            employeesTodos: extractArrays[1][6]?.employeesTodos!,
        },
        {
            state: moment().month(extractArrays[2][0]?.state!).format("MMM"),
            praises: extractArrays[2][1]?.praises!,
            feedbacks: extractArrays[2][2]?.feedbacks!,
            persLearning: extractArrays[2][3]?.persLearning!,
            groupLearning: extractArrays[2][4]?.groupLearning!,
            managersTodos: extractArrays[2][5]?.managersTodos!,
            employeesTodos: extractArrays[2][6]?.employeesTodos!,
        },
        {
            state: moment().month(extractArrays[3][0]?.state!).format("MMM"),
            praises: extractArrays[3][1]?.praises,
            feedbacks: extractArrays[3][2]?.feedbacks,
            persLearning: extractArrays[3][3]?.persLearning,
            groupLearning: extractArrays[3][4]?.groupLearning,
            managersTodos: extractArrays[3][5]?.managersTodos,
            employeesTodos: extractArrays[3][6]?.employeesTodos,
        },
        {
            state: moment().month(extractArrays[4][0]?.state!).format("MMM"),
            praises: extractArrays[4][1]?.praises!,
            feedbacks: extractArrays[4][2]?.feedbacks!,
            persLearning: extractArrays[4][3]?.persLearning!,
            groupLearning: extractArrays[4][4]?.groupLearning!,
            managersTodos: extractArrays[4][5]?.managersTodos!,
            employeesTodos: extractArrays[4][6]?.employeesTodos!,
        },
        {
            state: moment().month(extractArrays[5][0]?.state).format("MMM"),
            praises: extractArrays[5][1]?.praises!,
            feedbacks: extractArrays[5][2]?.feedbacks!,
            persLearning: extractArrays[5][3]?.persLearning!,
            groupLearning: extractArrays[5][4]?.groupLearning!,
            managersTodos: extractArrays[5][5]?.managersTodos!,
            employeesTodos: extractArrays[5][6]?.employeesTodos!,
        },
    ] : []

    return (
        <div className={classes.chartContainer}>
            <Chart
                height={333}
                data={chartData}
            >
                <ArgumentAxis />
                <ValueAxis />
                {chartData.map((_, index: number) => (
                    <BarSeries
                        name={fixString(barsNames[index].name)}
                        valueField={barsNames[index].name}
                        argumentField="state"
                        barWidth={0.5}
                        color={barsNames[index].color}
                    />
                ))}
                <Stack />
                <Animation duration={2000} />
                <EventTracker />
                <Tooltip />
                <Legend rootComponent={RootWithTitle} />
            </Chart>
        </div>
    )

}
export default SuperAdminChart