import { FC, ChangeEvent, useContext } from 'react'
import { TextareaAutosize } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { AppDirectionContext } from 'context/languages-context'

type Props = {
    textareaInput?: boolean
    className?: string
    handleChanage: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void
    inputRef: React.MutableRefObject<any>
    maxLength?: number
}
const AppropriateInputArea: FC<Props> = props => {
    const { textareaInput, className, handleChanage, inputRef, maxLength = undefined } = props
    const { t } = useTranslation()

    const { isRtlDirection } = useContext(AppDirectionContext)

    let classInput = className ? `modal-input ${className}` : 'modal-input'
    if (isRtlDirection) classInput += ' rtl'

    const maxFolderDescriptionLength = 40
    return (
        textareaInput ? (
            <TextareaAutosize
                autoFocus
                aria-label='item-input'
                className={classInput}
                onChange={handleChanage}
                ref={inputRef}
                maxLength={maxLength}
            />
        ) : (
            <input
                type='text'
                autoFocus
                maxLength={maxFolderDescriptionLength}
                ref={inputRef}
                className={classInput}
                onChange={handleChanage}
                value={inputRef?.current?.value}
                placeholder={t('type_name')}
            />
        )
    )
}

export default AppropriateInputArea