import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles<Theme>(({ palette }) => ({
    screenWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    container: {
        display: ' flex',
        justifyContent: ' center',
    },
    title: {
        color: '#46484A',
        margin: '2% auto 3% auto',
    },
    saveButton: {
        alignSelf: 'center',
        margin: '5%',
    },
    inputStyle: {
        width: 'calc(100% / 3 - (12px * 2))',
        textAlign: 'center',
        boxSizing: 'border-box',
        height: 95,
        border: '1.36px solid rgba(49, 49, 49, 0.15)',
        borderRadius: '13.6px',
        margin: 12,
        fontSize: 19,
        '&:focus': {
            border: '2.74px solid #0071c2',
            outline: 'none',
            boxShadow: '0 2px 11px 0 rgba(0, 113, 194, 0.19)',
        },
        '&::placeholder': {
            color: palette.text.primary,
            fontSize: '19.04px',
        },
    },
    plusButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    companyValuesContainer: {
		maxWidth: 738,
		width: '100%',
		flexWrap: 'wrap',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
}))