// 	DEBRIEFING_SELF_ANALISYS_CHAT:

import { SelfAnalisysChatBot } from 'screens/DebriefingSelfAnalisys'
import { SELF_ANALISYS_MESSAGE, SUMMARY } from '../stepMapComponent'
import { EMPTY_STATE } from '../useTreeLogic'

export const RenderSelfAnalisysChatBotChoice = ({ ...props }) => {
	const { updateDebrief } = props

	const pageIcon = {
		src: 'heartIcon',
		opacity: '',
	}
	const content = (
		<SelfAnalisysChatBot
			setData={updateDebrief}
			prevStep={SELF_ANALISYS_MESSAGE}
			nextStep={SUMMARY} />
	)

	return { ...EMPTY_STATE, content, pageIcon }
}
