import { FC, useEffect, useState } from 'react'
import { ScreenWrapper } from '../../components/common/LoginAndChangePassword/index'
import { useLocation } from 'react-router-dom'
import { fetchUserNameByEmail } from '../../api/user'

const getUserEmail = (location: any): string => {
	return location.pathname.split('/')[location.pathname.split('/').length - 1]
}

export const ChangePassword: FC<{}> = () => {
	const [userName, setUserName] = useState('')
	const [showHasAcceptedPolicyTerm, setShowHasAcceptPolicyTerm] = useState(false)

	const location = useLocation()

	const email = getUserEmail(location)

	useEffect(() => {
		fetchUserNameByEmail(email)
			.then(({ data }) => {
				const { firstName, hasAcceptPolicyTerm } = data

				setShowHasAcceptPolicyTerm(hasAcceptPolicyTerm)
				setUserName(firstName)
			})
			.catch(({ data }) => setUserName(data))
	}, [])

	return (
		<ScreenWrapper>
			<ScreenWrapper.ChangePassword
				showHasAcceptedPolicyTerm={showHasAcceptedPolicyTerm}
				userName={userName}
				userEmail={email}
			/>
		</ScreenWrapper>
	)
}
