import { createAsyncThunk } from '@reduxjs/toolkit'
import {
	fetchFeedbacks,
	fetchReceivedFeedbacksByIds,
	fetchSentFeedbacksByIds,
	getFeedbackQuestion,
	sendOutputFeedbacks,
	updateFeedback,
} from 'api/feedback'
import { Req } from 'enums/req/req.enum'
import { FetchUserFeedbackPraisesPayload } from 'interface/api/get-payload.types'
import { FeedbackOutputUpdate, ReviewOutputPayload } from 'interface/bot'
import { IStoreRootState } from 'interface/redux'
import { splitAlgorithm } from '../../utils/splitPraisesAndFeedback'
import { ToggleOutputDraft } from '../../components/Bot/useHooks/index'
import {
	toggleFeedbackOutputDraft,
	deleteFeedback,
	loadMoreFeedbacks,
	reviewFeedbackToggle,
} from '../../api/feedback'
import { Language } from '@material-ui/icons'
import { Langs } from 'enums/app'
import { FeedbackCreationAction } from '../../interface/api/get-payload.types'
import { ExpansionPayload } from '../../interface/bot/index'
import { updateOuputExpansionsFeedback } from 'api/company'
import { queryStringBuilder } from 'utils/QueryStringBuilder'

// export const update_expansions = createAsyncThunk(
// 	'praise/updateExpansions',
// 	async (payload: ExpansionPayload, { rejectWithValue }) => {
// 		try {
// 			const { data, status } = await updateOuputExpansionsFeedback(payload)
// 			if (status === Req.failed) throw data.message

// 			return { data, payload }
// 		} catch (err) {
// 			rejectWithValue(err)
// 		}
// 	}
// )
export const delete_drafted_Feedback = createAsyncThunk(
	'praise/fetchUserPraiseDrafts',
	async (payload: Record<string, string>, { rejectWithValue }) => {
		try {
			const { data, status } = await deleteFeedback(payload)
			if (status === Req.failed) throw new Error(data)
		} catch (err) {
			return rejectWithValue(err)
		}
	}
	// {
	// 	condition: (_, { getState }) => {
	// 		const { praise } = getState() as IStoreRootState
	// 		return praise.loading === false
	// 	},
	// }
)

export const get_feedbacks = createAsyncThunk(
	'feedback/fetchUserFeedbacks',
	async (payload: FetchUserFeedbackPraisesPayload, { rejectWithValue }) => {
		try {
			const { data, status } = await fetchFeedbacks(payload)
			if (status === Req.failed) throw new Error(data)
			// split feedbacks (sent, received nd drafts)
			const feedbacks = splitAlgorithm(data, payload.user_id)
			return feedbacks
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const load_more_feedbacks = createAsyncThunk(
	'feedback/loadMoreFeedbacks',
	async (payload: FetchUserFeedbackPraisesPayload, { rejectWithValue }) => {
		try {
			const { data, status } = await loadMoreFeedbacks(payload)
			if (status === Req.failed) throw new Error(data)
			const feedbacks = splitAlgorithm(data, payload.user_id)
			return { feedbacks, payload }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const fetch_received_company_feedback_byIds = createAsyncThunk(
	'praise/fetchReceivedPraisesByIds',
	async (payload: { ids: Array<string> }, { rejectWithValue, getState }) => {
		try {
			const { user } = getState() as IStoreRootState
			const query = queryStringBuilder(payload.ids)
			const param =
				query + `company_id=${user.user.company_id}&user_id=${user.user._id}`
			const data = await fetchReceivedFeedbacksByIds(param)
			// if (status === Req.failed) throw new Error(data)
			return { data: data }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
	// {
	// 	condition: (_, { getState }) => {
	// 		const { praise } = getState() as IStoreRootState
	// 		return praise.loading === false
	// 	},
	// }
)

export const fetch_sent_company_feedback_byIds = createAsyncThunk(
	'praise/fetchSentPraisesByIds',
	async (payload: { ids: Array<string> }, { rejectWithValue, getState }) => {
		try {
			const { user } = getState() as IStoreRootState
			const query = queryStringBuilder(payload.ids)
			const param =
				query + `company_id=${user.user.company_id}&user_id=${user.user._id}`
			const data = await fetchSentFeedbacksByIds(param)
			// if (status === Req.failed) throw new Error(data)
			return { data: data }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
	// {
	// 	condition: (_, { getState }) => {
	// 		const { praise } = getState() as IStoreRootState
	// 		return praise.loading === false
	// 	},
	// }
)

export const create_feedback = createAsyncThunk(
	'feedback/createFeedback',
	async (
		{ feedback, company_id }: FeedbackCreationAction,
		{ rejectWithValue }
	) => {
		try {
			const { status, data } = await sendOutputFeedbacks(feedback, company_id)
			if (status === Req.failed) throw new Error(data)

			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
	// {
	// 	condition: (_, { getState }) => {
	// 		const { feedback } = getState() as IStoreRootState
	// 		return feedback.loading === false
	// 	},
	// }
)

export const toggle_feedback_output = createAsyncThunk(
	'feedback/toggleFeedbackOutput',
	async (feedback: ToggleOutputDraft, { rejectWithValue }) => {
		try {
			const { status, data } = await toggleFeedbackOutputDraft(feedback)
			if (status === Req.failed) throw new Error(data)
			// return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
	// {
	// 	condition: (_, { getState }) => {
	// 		const { feedback } = getState() as IStoreRootState
	// 		return feedback.loading === false
	// 	},
	// }
)

export const get_question_feedback = createAsyncThunk(
	'feedback/getFeedback',
	async (
		{ questionId, language }: { questionId: string | null; language: Langs },
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await getFeedbackQuestion(questionId, language)
			if (status === Req.failed) throw new Error(data)

			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const get_additional_feedback_question = createAsyncThunk(
	'feedback/getaAdditionalFeedbackQuestion',
	async (
		{ questionId, language }: { questionId: string | null; language: Langs },
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await getFeedbackQuestion(questionId, language)
			if (status === Req.failed) throw new Error(data)

			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const update_feedback = createAsyncThunk(
	'feedback/updateFeedback',
	async (
		payload: FeedbackOutputUpdate & { isReceiver: boolean; user_id: string },
		{ rejectWithValue }
	) => {
		const { isReceiver, user_id, ...rest } = payload
		// console.log(rest)
		try {
			const { status, data } = await updateFeedback(rest, user_id)
			if (status === Req.failed) throw data.message
			console.log({ actionPlanRes: data })
			return { data, isReceiver }
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { feedback } = getState() as IStoreRootState
			return feedback.loading === false
		},
	}
)

export const review_feedback_work_meet = createAsyncThunk(
	'feedback/reviewFeedbackAtWorkMeet',
	async (payload: ReviewOutputPayload, { rejectWithValue }) => {
		try {
			const { data, status } = await reviewFeedbackToggle(payload)
			if (status === Req.failed) throw data.message
			return { data, output_id: payload.output_id }
		} catch (err) {
			rejectWithValue(err)
		}
	}
)

export const update_feedback_expansions = createAsyncThunk(
	'feedback/updateExpansions',
	async (payload: ExpansionPayload, { rejectWithValue }) => {
		try {
			const { data, status } = await updateOuputExpansionsFeedback(payload)
			if (status === Req.failed) throw data.message

			return { data, payload }
		} catch (err) {
			rejectWithValue(err)
		}
	}
)
