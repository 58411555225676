import { createSelector } from "reselect"
import { IStoreRootState } from "interface/redux/index"

export const todosStateSelector = (state: IStoreRootState) => state.todo
//handle loading
export const todosLoadingSelector = createSelector(
    todosStateSelector,
    state => state.loading
)
//handle company todos
export const companyTodosSelector = createSelector(
    todosStateSelector,
    state => state.companyTodos || {}
)
export const hasMoreTasksSelector = createSelector(
    todosStateSelector,
    state => state.hasMoreTasks
)
export const loadingTodos = createSelector(
    todosStateSelector,
    state => state.loading
)
export const todosErrorSelector = createSelector(
    todosStateSelector,
    state => state.error || ''
)