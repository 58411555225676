import { makeStyles } from '@material-ui/core'
import { Theme } from '@mui/material'

type Styles = {
	isRtlDirection?: boolean
}

export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
	blockagesContainer: {
		position: 'relative',
		margin: '.5rem auto -3.3rem auto',
		width: '68.5%',
		minHeight: '125px',
		justifyContent: 'space-between',
		borderRadius: '10px',
		padding: '8px',
		[breakpoints.down('xs')]: {
			margin: '1.5rem 2% -3.3rem auto',
			width: '95.5%',
			gap: '9px',
		},

		'& >div': {
			width: '-webkit-fill-available',
		},
	},
	lessonsContainer: {
		position: 'relative',
		width: '68.5%',
		minHeight: '125px',
		justifyContent: 'space-between',
		padding: '8px',
		margin: '1.5rem auto',
		[breakpoints.down('xs')]: {
			margin: '2.8rem 2% 1rem auto',
			width: '95.5%',
			gap: '9px',
		},
	},
	blockageImg: {
		width: '15%',
	},
	displayStatus: {
		display: 'flex',
		padding: '2px',
		flexDirection: 'row',
		width: '100%',
		height: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
		cursor:'pointer',
		'& >p': {
			fontSize: '13px',
			color: 'grey',
			[breakpoints.down('xs')]: {
				fontSize: '71%',
			},
		},
		'& >img': {
			padding: '0 1.5% 2.5% 1px',
		},
	},
	gridTasksContainer: {
		border: '1px solid rgba(49, 49, 49, 0.152716)',

		overflow: 'scroll',
		maxHeight: '75vh',
		overflowX: 'hidden',
		borderRadius: '10px',
		width: '59%',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: 'auto',
		marginRight: 'auto',
		[breakpoints.down('xs')]: {
			position: 'static',
			maxHeight: 'fit-content',
			// height: '35vh',
			width: '80%',
			margin: '45px auto',
			padding: '1rem',
		},
	},
	hrLine: {
		fontSize: '1px',
		width: '93%',
		height: '1px',
		background: 'rgba(49, 49, 49, 0.152716)',
		marginLeft: '15px',
	},
	plusButton: {
		top: '40%',
		position: 'relative',
		display: 'flex',
		margin: 'auto',
		width: '8%',
		cursor: 'pointer',
	},
}))
