import React, { FC, useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import { Langs } from '../../enums/app/index'
import { CreateForm } from '../../components/SuperAdmin/OutputSettings/CreateOutputForm'
import { FullScrennDialog } from '../../components/SuperAdmin/OutputSettings/FullScrennDialog'
import { RenderItem } from '../../components/SuperAdmin/OutputSettings/RenderItem'
import { fetchAllFeedbacksFurtherimpact } from '../../api/feedback'

export type Impacts = {
	id?: string
	title: string
	beforeSend?: Record<Langs, string>
	afterSend?: Record<Langs, string>
}
export type ConnectivityWords = Record<Langs, Record<string, string>>

type FurtherImpact = {
	_id: string
	impacts: Impacts[]
	connectivityWords: ConnectivityWords
}

export const RenderCreateFurtherImpactForm: FC<{}> = () => {
	const [showFurhterImpactBtn, setShowFurtherImpactBtn] = useState(false)
	const [language, setLanguage] = React.useState(Langs.en)
	const [newFurtherIpmact, setNewFurtherIpmact] =
		useState<FurtherImpact | null>(null)
	useEffect(() => {
		fetchAllFeedbacksFurtherimpact().then(data => {})
	}, [])
	const handleCreateOutput = () => {
		//  api requestion
		setShowFurtherImpactBtn(true)
	}
	const handleChangeLang = (lang: string) => {
		if (lang !== language) setLanguage(lang as Langs)
	}

	const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {}
	const RenderForm = (
		<>
			{!!newFurtherIpmact?._id &&
				newFurtherIpmact?.impacts.map(impact => {
					const str = impact?.beforeSend ? impact.beforeSend[language] : ''
					return (
						<RenderItem
							isRtl={language === Langs.he}
							placeholder='Reason'
							name={`beforeSend`}
							handleEditingChange={handleFormChange}
							str={str}
						/>
					)
				})}
		</>
	)

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					marginBottom: '10%',
				}}
			>
				<CreateForm onChange={handleFormChange} values={{ title: '' }} />

				<Button
					color={'secondary'}
					onClick={handleCreateOutput}
					type='submit'
					variant='contained'
				>
					add
				</Button>
			</div>
			<FullScrennDialog
				title={''}
				handleChangeLang={handleChangeLang}
				renderChild={RenderForm}
				isOpen={showFurhterImpactBtn}
				handleSaveButton={() => setShowFurtherImpactBtn(true)}
				handleClose={() => setShowFurtherImpactBtn(false)}
			/>
		</>
	)
}
