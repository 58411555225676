import { OverridableStringUnion } from '@material-ui/types'
import { Skeleton, SkeletonPropsVariantOverrides } from '@mui/material'

type Props = {
    width: string | number
    height: string | number
    className?: string
    variant?: OverridableStringUnion<
        'text' | 'rectangular' | 'circular',
        SkeletonPropsVariantOverrides
    >,
    times: number
}
const AppSkeleton = ({ height, width, className, variant, times = 1 }: Props) => {
    const dom = (<Skeleton
        className={className}
        animation='wave'
        variant={variant}
        width={width}
        height={height}
    />)
    let array = []
    for (let i = 0; i < times; i++) {
        array.push(dom)
    }
    return (
        <div>{array.map(element => element)}</div>
    )
}

export default AppSkeleton