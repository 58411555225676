import { useContext, useState, useRef, useEffect, useCallback, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from '@material-ui/core'
import SearchIcon from '@material-ui/icons//Search'

import { AppDirectionContext } from 'context/languages-context'

import useStyles from './styles'

import { getFullName } from './builderHelper'

import IUser from 'interface/user/user-interface'

interface Props {
	onRowCreate: (employee: IUser, type: boolean) => Object
	employees: IUser[]
}
const EmployeesBox = ({ employees, onRowCreate }: Props) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const { t } = useTranslation()
	const classes = useStyles({ isRtlDirection, isManagersBox: false })

	const defaultPlaceHolder = t('unassigned_employees')

	const [currentEmployees, setCurrentEmployees] = useState(employees)
	const [placeholder, setPlaceholder] = useState(defaultPlaceHolder)

	const inputRef = useRef<HTMLInputElement | null>(null)

	useEffect(() => {
		setCurrentEmployees(employees)
	}, [employees])

	const handleFilter = (searchTerm: string) => {
		const filteredEmployees = employees.filter((employee: IUser) =>
			getFullName(employee)
				?.toLocaleLowerCase()
				.includes(searchTerm?.toLocaleLowerCase())
		)
		setCurrentEmployees(filteredEmployees)
	}

	const replacePlaceholder = () => setPlaceholder(t('type_to_search'))
	const blurPlaceholder = () => setPlaceholder(defaultPlaceHolder)
	const onFocusSearchArea = useCallback(() => inputRef.current && inputRef.current.focus(), [inputRef])

	return (
		<div className={classes.cardContainer}>
			<Card className={classes.card}>

				<div
					style={{ flexDirection: isRtlDirection ? 'row-reverse' : 'row' }}
					className={`${classes.cardTitle} ${classes.searchBox}`}
				>

					<div className={classes.icon}>
						<SearchIcon onClick={onFocusSearchArea} />
					</div>

					<input
						type='text'
						onBlur={blurPlaceholder}
						ref={inputRef}
						onChange={(e: ChangeEvent<HTMLInputElement>) => handleFilter(e.target.value)}
						onClick={replacePlaceholder}
						placeholder={placeholder}
					/>
				</div>

				<div className={classes.cardContent}>
					{currentEmployees.map((employee: IUser) => {
						const row = onRowCreate(employee, true)
						return row
					})}
				</div>

			</Card>
		</div>
	)
}

export default EmployeesBox
