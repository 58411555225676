import ConfirmModalButton from './ConfirmModalButton'
import useStyles from './styles'

type Props = {
    isRtlDirection?: boolean
    onClose?: () => void
    onConfirm?: () => void
    onDiscard?: () => void
    closeButtonText?: string
    confirmButtonText?: string
    buttonsStyle?: string
    acceptConfirm?: boolean
    ConfirmButton?: JSX.Element
}
const ModalButtonContainer = ({
    isRtlDirection,
    onClose,
    onConfirm,
    onDiscard,
    closeButtonText,
    confirmButtonText,
    buttonsStyle,
    acceptConfirm,
}: Props) => {
    const classes = useStyles({ isRtlDirection })

    const props = { onClose, onConfirm, onDiscard, acceptConfirm }

    const renderCloseButton = (isCloseButton: boolean, label?: string) => (
        <ConfirmModalButton
            label={label}
            isCloseButton={isCloseButton}
            {...props}
        />
    )

    return (
        <div
            className={!buttonsStyle ? classes.buttonContainer : [classes.buttonContainer, buttonsStyle].join(' ')}
            style={{ direction: isRtlDirection ? 'rtl' : 'ltr' }}
        >

            {onConfirm && onClose && renderCloseButton(false, confirmButtonText!)}

        </div>
    )
}

export default ModalButtonContainer