import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles<Theme>(({ palette }) => ({
	title: {
		fontSize: '22px',
		fontWeight: 600,
		marginTop: '44px',
		marginBottom: '0.7rem',
	},
	container: {
		boxShadow: 'rgb(0 0 0 / 4%) 2px 2px',
		flexGrow: 1,
		backgroundColor: palette.common.white,
		borderBottom: `1px solid ${palette.action.disabled}`,
		borderLeft: `1px solid ${palette.action.disabled}`,
		borderRight: `1px solid ${palette.action.disabled}`,
		borderRadius: '10px',
		'& div > .MuiBox-root': {
			padding: 0,
		},
		// '& .infinite-scroll-component::-webkit-scrollbar': {
		// 	width: '0px',
		// },
	},
	appBar: {
		boxShadow: 'none',
		'& .MuiTabs-root': {
			borderTop: `1px solid ${palette.action.disabled}`,
			borderTopLeftRadius: '10px',
			borderTopRightRadius: '10px',
		},
		'& .MuiTabs-flexContainer': {
			borderBottom: `1px solid ${palette.action.disabled}`,
		},
		'& .MuiTabs-indicator': {
			width: '60px !important',
			margin: '0 0 0 30px',
		},
	},
	tab: {
		textTransform: 'initial',
		padding: 0,
		minWidth: '120px',
		fontWeight: 650,
	},
	tabPanel: {
		'& .MuiBox-root': {
			padding: '0px',
		},
	},

}))
