import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { get_file, get_folder } from 'redux/company/company-actions'
import { companyLessonsSelector } from 'redux/company/company.selector'
import { userSelector } from 'redux/user/user-selector'

import useCacheFolders from './useCacheFolders'

import { ILessonsBase } from 'interface/lessons-management/LessonsBase.interface'
import { companyActions } from 'redux/company/company-reducer'
import { isFolderType } from 'utils/LessonsManagement/isFolder'
import { UI } from 'enums/lessonsManagement'

const useFoldersNavigation = () => {
	const [currentFolder, setCurrentFolder] = useState<ILessonsBase | null>()

	const { company_id } = useSelector(userSelector)
	const companyFolders = useSelector(companyLessonsSelector)

	const { getFolderFromCache, clearCacheFolders } = useCacheFolders({ currentFolder })

	const history = useHistory()
	const dispatch = useDispatch()

	useEffect(() => {
		if (!company_id) return

		dispatch(get_folder({ company_id, folder_id: company_id }))

		return () => {
			setCurrentFolder(null)
			clearCacheFolders()
		}
	}, [company_id])

	useEffect(() => {
		if (!foldersBuiltSuccessfully()) return

		setCurrentFolder(companyFolders)
	}, [companyFolders])

	const foldersBuiltSuccessfully = () => Object.keys(companyFolders).length

	const navigate = (_id: string): void => {
		if (shouldReturnPrevScreen(_id)) return history.go(-1)

		else fetchNextFolder(_id)
	}
	const fetchNextFolder = (_id: string): void => {
		const cachedFolder = getFolderFromCache(_id)
		if (cachedFolder) {
			applyDestinationFolderFromCache(cachedFolder)
			return
		}

		if (shouldGetFile(_id)) fatchDestinationFile(_id)

		else fatchDestinationFolder(_id)
	}
	const shouldReturnPrevScreen = (_id: string): boolean => {
		if (!foldersBuiltSuccessfully()) return true //In case user exit LM before fetching the folders

		const unknownDestinationFolder = _id === null

		return currentFolder?._id === company_id && unknownDestinationFolder
	}
	const applyDestinationFolderFromCache = (cachedFolder: ILessonsBase): void => {
		setCurrentFolder(cachedFolder)
		dispatch(companyActions.setCurrentFolder(cachedFolder))
	}
	const fatchDestinationFolder = (_id: string | null) => {
		const payload = {
			company_id,
			folder_id: _id
		}
		dispatch(get_folder(payload))
	}
	const shouldGetFile = (_id?: string | null): boolean => {
		if (isFolderType(currentFolder?.type!)) {
			const output = currentFolder?.type.children.find(f => f._id === _id)

			return output?.ui === UI.FILE
		}

		return false
	}
	const fatchDestinationFile = (_id: string | null) => {
		const payload = {
			company_id,
			file_id: _id
		}
		dispatch(get_file(payload))
	}

	return { currentFolder, navigate }
}

export default useFoldersNavigation