import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles<Theme>(({ breakpoints, palette }) => ({
	singleChoiceWrapper: {
		overflowY: 'auto',
		flexDirection: 'column',
		maxWidth: '469px',
		width: '100%',
		height: '100%',
		[breakpoints.down('sm')]: {
			height: 'auto',
		},
	},
	previousButton: {
		color: '#0071C2',
		border: '2.36px solid #949494',
		cursor: 'pointer',
		height: '54px',
		fontSize: '20.4px',
		textAlign: 'center',
		fontWeight: 600,
		borderColor: '#0071C2',
		borderRadius: '13.6px',
		backgroundColor: '#FFFFFF',
		width: '224px',
		[breakpoints.down('sm')]: {
			width: '100%',
			maxWidth: '224px',
		},
	},
	answerCard: {
		width: '95%',
		boxSizing: 'border-box',
		height: '98px',
		border: '2.8px solid rgba(49, 49, 49, 0.152716)',
		borderRadius: '14px',
		overflowWrap: 'break-word',
		padding: '0 31px',
		cursor: 'pointer',
		marginBottom: '15px',
		margin: 'auto',
		display: 'flex',
		alignItems: 'center',

		color: palette.text.secondary,

		'&:last-child': {
			marginBottom: 0,
		},

		[breakpoints.up('sm')]: {
			'&:hover': {
				borderColor: '#0071C2',
				border: '2.8px solid #0071C2',
				boxShadow: '5px 5px 10px rgb(0 113 194 / 19%)',
			},
		},
	},

	regular: {
		borderColor: 'rgba(49,49,49,0.15)',
	},

	imageWrapper: {
		marginTop: '6%',

		[breakpoints.between('sm', 'md')]: {
			margin: 0,
		},
		[breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	icon: { width: '120px', height: '120px' },
}))
