import { makeStyles, Theme } from '@material-ui/core/styles'
export default makeStyles<Theme>(({ breakpoints }) => ({
	daysOptions: {
		display: 'flex',
		width: '75%',
		margin: 'auto',
		justifyContent: 'space-between',

		'& div': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: 'white',
			borderRadius: '10%',
			padding: '5px',
			minWidth: '7%',
			textAlign: 'center',
		},
	},
	title: {
		margin: '4% auto 2% auto',
		color: '#46484A',
		width: '100%',
		textAlign: 'center',
	},
	activateContainer: {
		width: 'fit-content',
		height: '2rem',
		//margin: 'auto',
		display: 'flex',
		alignItems: 'center',
	},
	desc: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: 'fit-content',
	},

	content: {
		color: '#46484A',
		'& form': {
			'& section': {
				display: 'flex',
				justifyContent: 'space-between',
				borderRadius: '10px',
				margin: '2%',
				padding: '2%',
				boxShadow: 'rgba(0, 0, 0, 0.07) 2.4px 2.4px 1.2px',
				backgroundColor: '#F5F5F5',
				minHeight: '45px',
			},
		},
		width: '60%',
		margin: 'auto',
	},
	checked: {
		color: 'red',
	},
	checkboxes: {
		display: 'flex',
		width: '50%',
		justifyContent: 'space-evenly',
	},
	actionbutton: {
		color: '#0071C2',
		cursor: 'pointer',
	},
	buttonsContainer: {
		width: '20%',
		margin: '4% auto 2% auto',
		display: 'flex',
		justifyContent: 'space-between',
	},
	companyActions: {
		width: '97%',
		height: '50vh', // until sms coming back
		margin: 'auto ',
		marginTop: '5%',
		display: 'flex',
		flexDirection: 'column', // until sms coming back
		alignItems: 'center', // until sms coming bac
		justifyContent: 'space-around',
		[breakpoints.down('md')]: {
			flexDirection: 'column',
			alignItems: 'center',
		},
	},
	rocketContainer: {
		alignItems: 'center',
		display: 'flex',
		borderRadius: '8px',
		backgroundColor: 'white',
	},
	rocketButtonContainer: {
		alignItems: 'center',
		display: 'flex',
		width: '100%',
	},
	mainText: {
		margin: 'auto',
	},
	launchedText: {
		color: '#0071C2',
	},
	timeInputContainer: {
		'& span': {
			width: '-webkit-fill-available',
		},
		margin: '0 10px 0 10px',
		display: 'flex',
		width: '50%',
		alignItems: 'center',
	},
	timeInputs: {
		width: '55%',
		margin: 'auto',
		justifyContent: 'space-between',
		display: 'flex',
		[breakpoints.down('md')]: {
			marginTop: '5%',
			width: '70%',
		},
	},
	toggleContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	timeSection: {
		[breakpoints.down('md')]: {
			alignItems: 'flex-start',
			flexDirection: 'column',
		},
	},
}))
