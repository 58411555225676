import { useTranslation } from 'react-i18next'

import './style.css'

import InputLesson from '../modal/LessonsManagementInput'

import colors from 'utils/colors'
import { ItemDescriptionType } from '../props'

const DescriptionSection = ({
	itemDescription,
	isEdit,
	isFolder,
	handleLessonDescription,
	navigate
}: ItemDescriptionType) => {
	const { t } = useTranslation()

	const lessonLabelColor = colors.boldPurple
	const lessonContent = (<p><span style={{ color: lessonLabelColor }}>{t('lesson')}: </span>{itemDescription}</p>)

	return !isEdit ? (
		<div className='row-description' onClick={navigate}>
			{!isFolder ? lessonContent : itemDescription}
		</div>)
		: <InputLesson
			className='edit'
			itemDescription={itemDescription}
			isEdit={isEdit}
			onValueChanged={handleLessonDescription}
		/>
}

export default DescriptionSection