import { ILessonsBase, IInsertLesson, IAttacheLesson, DeleteLessonInFileParams } from 'interface/lessons-management/LessonsBase.interface'
import { DELETE, GET, PATCH, POST } from './api-requests'
import { URLS } from './api-urls'
import { Response } from 'interface/api/response-interface'
import { UpdateFolderManagers } from 'redux/company/company-actions'

export const getFolder = async (
    payload: { company_id: string, folder_id: string | null }
): Promise<Response> => {
    const { company_id, folder_id } = payload

    const { data, status } = await GET(`${URLS.COMPANY_LESSONS}?company_id=${company_id}&_id=${folder_id}`)
    return { data, status }
}

export const getFile = async (
    payload: { company_id: string, file_id: string | null }
): Promise<Response> => {
    const { company_id, file_id } = payload
    const { data, status } = await GET(`${URLS.COMPANY_LESSONS}/file?company_id=${company_id}&_id=${file_id}`)

    return { data, status }
}

export const createNewFolder = async (
    folder: Partial<ILessonsBase>
): Promise<Response> => {
    const { data, status } = await POST(`${URLS.COMPANY_LESSONS}`, { folder })

    return { data, status }
}

export const createNewFile = async (
    file: Partial<ILessonsBase>
): Promise<Response> => {
    const { data, status } = await POST(`${URLS.COMPANY_LESSONS}/file`, { file })

    return { data, status }
}

export const editFolder = async (
    folder: Partial<ILessonsBase>
): Promise<Response> => {
    const { data, status } = await PATCH(`${URLS.COMPANY_LESSONS}`, { folder })

    return { data, status }
}
export const grantManagersPermissions = async (
    folder: UpdateFolderManagers
): Promise<Response> => {
    const { data, status } = await PATCH(`${URLS.COMPANY_LESSONS}/permissions`, { folder })

    return { data, status }
}

export const editFile = async (
    updatedFile: Partial<ILessonsBase>
): Promise<Response> => {
    const { data, status } = await PATCH(`${URLS.COMPANY_LESSONS}/file`, updatedFile)

    return { data, status }
}

export const deleteFolder = async (
    payload: { company_id: string, _id: string }
): Promise<Response> => {
    const { _id, company_id } = payload
    const { data, status } = await DELETE(`${URLS.COMPANY_LESSONS}?company_id=${company_id}&_id=${_id}`)

    return { data, status }
}

export const deleteFile = async (
    payload: { company_id: string, _id: string }
): Promise<Response> => {
    const { _id, company_id } = payload
    const { data, status } = await DELETE(`${URLS.COMPANY_LESSONS}/file?company_id=${company_id}&_id=${_id}`)

    return { data, status }
}

export const attachLessonToFile = async (
    lesson: IAttacheLesson
): Promise<Response> => {
    const { data, status } = await PATCH(`${URLS.COMPANY_LESSONS}/attachLesson`, { lesson })

    return { data, status }
}

export const createNewLesson = async (
    lesson: IInsertLesson
): Promise<Response> => {
    const { data, status } = await PATCH(`${URLS.COMPANY_LESSONS}/file/lesson/new/lesson`, { lesson })

    return { data, status }
}
export const updateLessonDescription = async (
    lesson: IInsertLesson
): Promise<Response> => {
    const { data, status } = await PATCH(`${URLS.COMPANY_LESSONS}/file/lesson/update/lesson`, { lesson })

    return { data, status }
}

export const deleteLesson = async (
    deleteLesson: DeleteLessonInFileParams
): Promise<Response> => {
    const { _id, company_id, lessonBaseId, lesson_id } = deleteLesson
    const { data, status } = await DELETE(`${URLS.COMPANY_LESSONS}/file/lesson?company_id=${company_id}&_id=${_id}&lessonBaseId=${lessonBaseId}&lesson_id=${lesson_id}`)

    return { data, status }
}

export const createTitle = async (
    lesson: { company_id: string, _id?: string, lessonBaseId: string, title: string }
): Promise<Response> => {
    const { data, status } = await PATCH(`${URLS.COMPANY_LESSONS}/file/lesson/title`, { lesson })

    return { data, status }
}

