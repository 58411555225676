import { DebriefEvent } from '../interface/debrief/debrief-interface'

export const checkIfUserFinishedBot = (
	owner_id: string,
	matchPoints: Array<DebriefEvent>
) => {
	let summariesCount = 0
	const events = matchPoints?.filter((ev: DebriefEvent) => ev.isMatchPoint)

	events?.forEach((matchpoint: DebriefEvent) => {
		matchpoint?.summaries?.forEach(s => {
			if (s.lessonsOwner_id === owner_id) {
				summariesCount++
			}
		})
	})

	const matchpointsCount = events?.length ? events.length : 0

	return !!(
		matchpointsCount &&
		summariesCount &&
		matchpointsCount === summariesCount
	)
}
