import { createTheme, ThemeOptions } from '@material-ui/core'
import { Palette } from '@mui/material'

interface IPalette extends Palette {
	headerColors: {}
	bot: {
		regularText: string
	}
	debrief: {
		main: string
		light: string
		dark: string
		shadow: string
	}
	praise: {
		main: string
	}
}
export interface IThemeOptions extends ThemeOptions {
	palette: IPalette
}

export default createTheme({
	typography: {
		fontFamily: `"Sora", sans-serif`,
	},
	palette: {
		bot: {
			regularText: '#37597A',
		},
		debrief: {
			light: '#F3F3F9',
			main: '#5B4EB5',
			dark: '#5146A2',
			shadow: '#DAD8EB',
		},
		praise: {
			main: 'rgb(52, 171, 83)',
		},
		primary: {
			main: '#34AB53',
			light: '#35AB54, #50B76B, #70C486',
			dark: '#945EB4, #AE80CA, #C197DA',
		},
		secondary: {
			main: '#0071C2',
			light: '#E1EAF6',
		},
		error: {
			main: '#e2704e',
		},
		text: {
			primary: '#46484a',
			secondary: '#46484a',
		},
		action: {
			disabled: '#DEE3EF',
		},
	},
	// breakpoints: {
	// 	values: {
	// 		xs: 375,
	// 		sm: 414,
	// 		lg: 820,
	// 		xl: 912
	// 	}
	// }
} as IThemeOptions)
