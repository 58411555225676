export const replaceString = (
	str: string,
	{ recipientPlaceholder = '', senderPlaceholder = '' }: Record<string, string>
) => {
	if (str.includes('senderPlaceholder')) {
		return str.replaceAll('senderPlaceholder', senderPlaceholder)
	}
	if (str.includes('recipientPlaceholder') || str.includes('Placeholder')) {
		if (str.includes('recipientPlaceholder'))
			return str.replaceAll('recipientPlaceholder', recipientPlaceholder)
		else if (str.includes('Placeholder'))
			return str.replaceAll('Placeholder', recipientPlaceholder)
	}
	if (str.includes("recipientPlaceholder's"))
		return str.replaceAll("recipientPlaceholder's", `${recipientPlaceholder}'`)
	return str
}
