import { createSelector } from 'reselect'
import { IStoreRootState } from 'interface/redux'

export const userStateSelector = (state: IStoreRootState) => state.employee

export const employeeErrorSelector = createSelector(
	userStateSelector,
	state => state.error
)
export const employeeLoadingSelector = createSelector(
	userStateSelector,
	state => state.loading
)
export const employeesSelector = createSelector(
	userStateSelector,
	state => state.employees || []
)
