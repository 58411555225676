import { FC, useState } from 'react'
import DatePicker from '../Modal/DatePicker/index'
import ActionButtons from 'components/Bot/ActionButtons'
import { initialAnswer, UserAnswer } from '../ActionButtons/index'
import { AnswersGroupProp } from '../../../interface/bot/index'

const DatePickerChoice: FC<AnswersGroupProp> = ({
	setQuestion,
	nextQuestion,
	previousQuestion,
	type,
	isPraise,
}) => {
	const [answer, setAnswer] = useState<UserAnswer>({
		...initialAnswer,
		nextQuestion: nextQuestion!,
		previousQuestion: previousQuestion!,
		type,
	})
	const handleDayClick = (day: Date) => {
		if (day > today) return
		setAnswer((prevAnswer: UserAnswer) => ({
			...prevAnswer,
			answer: day.toString(),
		}))
	}
	const today = new Date()

	return (
		<>
			<div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
				<DatePicker
					disabledDays={{ after: today }}
					handleDayClick={handleDayClick}
					selectedDay={new Date(answer.answer!)}
				/>
			</div>
			<ActionButtons
				showNextStepButton
				showPreviousButton
				isPraise={isPraise}
				isEnabled={!!answer.answer?.length}
				answer={answer}
				setQuestion={setQuestion}
			/>
		</>
	)
}

export default DatePickerChoice
