import Paths, { DashboardPaths } from 'enums/routes/path-names'
import CompanyBuilder from 'components/CompanyBuilder/Builder/index'
import LoginScreen from 'screens/Login'
import { IRoute } from 'interface/routes/IRoute'
import Dashboard from '../screens/Dashboard/index'
import BotScreen from '../screens/Bot/index'
import BotOutput from 'screens/BotOutput'
import { ChangePassword } from 'screens/ChangePassword'
import DebriefingProcessScreen from 'screens/DebriefingProcess'

import CompaniesManagement from '../screens/CompaniesManagement'
import BotSettings from '../screens/BotSettings'
import { OutputSetting } from 'screens/OutputSettings'
import WelcomeContent from '../components/SuperAdmin/welcomeContent'
import { SuperTable } from 'components/SuperAdmin/SuperTable/superTable'
import { TotalSuperTable } from 'components/SuperAdmin/SuperTable/TotalSuperTable/TotalSuperTable'
import LessonsManagement from 'components/Debriefing/Lessons-management/root/index'

const routes: IRoute[] = [
	{ path: Paths.LOGIN, exact: true, public: true, component: LoginScreen },
	{ path: Paths.COMPANY_BUILDER, exact: true, component: CompanyBuilder },
	{
		path: Paths.RECEIVED_FEEDBACK_PROCESS,
		exact: true,
		component: BotOutput,
	},
	{
		path: Paths.SENT_FEEDBACK_PROCESS,
		exact: true,
		component: BotOutput,
	},
	{ path: `${Paths.BOT}/feedback`, exact: true, component: BotScreen },
	{
		path: Paths.DEBRIEF_PROCESS,
		exact: true,
		component: DebriefingProcessScreen,
	},
	{ path: `${Paths.BOT}/praise`, exact: true, component: BotScreen },
	{ path: Paths.DASHBOARD, component: Dashboard },
	{
		path: Paths.OUTPUT,
		exact: true,
		public: false,
		component: BotOutput,
	},
	{
		path: Paths.CHANGE_PASSWORD,
		exact: true,
		public: true,
		component: ChangePassword,
	},
	// {
	// 	component: LoginScreen,
	// },
]
export default routes

export const adminRoutes: IRoute[] = [
	{
		path: `${DashboardPaths.MAIN}`,
		exact: true,
		public: true,
		component: WelcomeContent,
	},
	{
		path: `${DashboardPaths.SUPER_ADMIN_COMPANIES}`,
		exact: true,
		public: true,
		component: CompaniesManagement,
	},
	{
		path: `${DashboardPaths.SUPER_ADMIN_BOT_SETTINGS}`,
		exact: true,
		public: true,
		component: BotSettings,
	},
	{
		path: `${DashboardPaths.SUPER_ADMIN_OUTPUT_SETTING}`,
		exact: true,
		public: true,
		component: OutputSetting,
	},
	{
		path: `${DashboardPaths.SUPER_ADMIN_COMPANIES_SUMMARIES}`,
		exact: true,
		public: true,
		component: TotalSuperTable,
	},
	{
		path: `${DashboardPaths.SUPER_COMPANY_TABLE}`,
		exact: true,
		public: true,
		component: SuperTable,
	},
]
