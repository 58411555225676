import { ReactElement, useState, useEffect, useCallback } from 'react'
import { Button, IconButton, makeStyles } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import DropDownBuilder from './DropDownBuilder'
import InputBuilder from './InputBuilder'
import { Roles } from 'enums/user/user-roles'
import { License } from 'enums/License/License.enum'
import IUser, { INewUserInitState } from 'interface/user/user-interface'
import { ILicenses } from 'interface/Licenses'
import TableRow, { TableCellProps } from './TableRow'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import useFormHook from './useForm'

const DataRoles = [Roles.EMPLOYEE, Roles.MANAGER]
const DataLicense = [License.bronze, License.silver, License.gold]

interface subDepData {
	name: string
	linkedWith: string
}
interface Props {
	cellSizes: any
	remainingLicense: ILicenses
	currentUser?: IUser
	onEdit?: (editedUser: INewUserInitState, id: string) => void
	onAdd?: (newUser: INewUserInitState) => void
	onClose: () => void
	managersEmails: Array<string>
	departments: Array<string>
	subDepartments: subDepData[]
}

const schema = yup.object().shape({
	firstName: yup
		.string()
		.trim()
		// .matches(/^[א-ת]+$/i)
		.required(),
	lastName: yup
		.string()
		.trim()
		// .matches(/^[A-Za-zא-ת]+$/i)
		.required(),
	email: yup.string().trim().email().required(),
	mobile: yup.string().trim().required(),
	title: yup.string().trim().required(),
})

function ManualInputs({
	cellSizes,
	remainingLicense,
	onEdit,
	onClose,
	onAdd,
	currentUser,
	managersEmails,
	departments,
	subDepartments,
}: Props): ReactElement {
	const { t } = useTranslation()
	const classes = useStyles()
	const [disabled, setDisabled] = useState(true)
	const [currentDepartment, setCurrentDepartment] = useState(
		currentUser ? currentUser.statusInfo.department : ''
	)
	const currentSubDepartments = subDepartments
		.filter((sb: subDepData) => sb.linkedWith === currentDepartment)
		.map(sb => sb.name)

	const { register } = useForm<INewUserInitState>({
		resolver: yupResolver<any>(schema),
	})

	const { personalInfo, statusInfo, securityInfo } = currentUser ?? {}

	const { handleChange, resetForm, errors, values } = useFormHook<
		INewUserInitState,
		INewUserInitState
	>(
		{
			firstName: personalInfo?.firstName ?? '',
			lastName: personalInfo?.lastName ?? '',
			email: securityInfo?.email ?? '',
			mobile: personalInfo?.mobile ?? '',
			title: statusInfo?.title ?? '',
			department: statusInfo?.department ?? departments[0],
			subDepartment: statusInfo?.subDepartment ?? '',
			directManager: statusInfo?.directManager ?? managersEmails[0],
			role: statusInfo?.role ?? Roles.EMPLOYEE,
			license: statusInfo?.license ?? License.bronze,
		} as INewUserInitState,
		{} as INewUserInitState
	)

	const {
		email,
		firstName,
		lastName,
		mobile,
		title,
		department,
		subDepartment,
		directManager,
		role,
		license,
	} = values

	useEffect(() => {
		const disabled =
			Object.values(errors).filter(Boolean).length !== 0 ||
			!firstName ||
			!lastName ||
			!email ||
			!mobile

		setCurrentDepartment(department)
		if (onEdit) {
			const isEqualValues =
				currentUser?.securityInfo.email === email &&
				currentUser?.personalInfo.firstName === firstName &&
				currentUser?.personalInfo.lastName === lastName &&
				currentUser?.personalInfo.mobile.toString() === mobile &&
				currentUser?.statusInfo.title === title &&
				currentUser?.statusInfo.department === department &&
				currentUser?.statusInfo.subDepartment === subDepartment &&
				currentUser?.statusInfo.directManager === directManager &&
				currentUser?.statusInfo.role === role &&
				currentUser?.statusInfo.license === license

			setDisabled(isEqualValues || !!disabled ? true : false)
		} else {
			setDisabled(!!disabled)
		}
	}, [setDisabled, errors, values, currentUser])

	const handleNewEmployeeSubmition = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault()
			if (!values.subDepartment)
				values.subDepartment = subDepartments
					.filter((sb: subDepData) => sb.linkedWith === values.department)
					.map(sb => sb.name)[0]

			onAdd && onAdd(values)
			const imgSrc = currentUser?.personalInfo.imgSrc
			currentUser &&
				onEdit &&
				onEdit({ ...values, imgSrc: imgSrc ? imgSrc : '' }, currentUser._id)
			onClose()
			resetForm({
				firstName: '',
				lastName: '',
				email: '',
				mobile: '',
				title: '',
			})
		},
		[onAdd, onEdit, resetForm, values]
	)
	let currentLicense = DataLicense

	if (remainingLicense.gold === 0)
		currentLicense =
			currentUser && currentUser.statusInfo.license === License.gold
				? currentLicense
				: currentLicense.filter(li => li !== License.gold)
	if (remainingLicense.silver === 0)
		currentLicense =
			currentUser && currentUser.statusInfo.license === License.silver
				? currentLicense
				: currentLicense.filter(li => li !== License.silver)
	if (remainingLicense.bronze === 0)
		currentLicense =
			currentUser && currentUser.statusInfo.license === License.bronze
				? currentLicense
				: currentLicense.filter(li => li !== License.bronze)

	const cells = [
		{
			content: (
				<InputBuilder
					focus
					value={firstName}
					handleInputChange={handleChange}
					type='text'
					name='firstName'
					placeholder={t('name')}
					showError={errors.firstName}
					defaultValue={currentUser && currentUser.personalInfo.firstName}
				/>
			),
			size: cellSizes.firstNameCell,
		},
		{
			content: (
				<InputBuilder
					name='lastName'
					type='text'
					value={lastName}
					placeholder={t('surname')}
					showError={errors.lastName}
					handleInputChange={handleChange}
					defaultValue={currentUser && currentUser.personalInfo.lastName}
				/>
			),
			size: cellSizes.lastNameCell,
		},
		{
			content: (
				<InputBuilder
					type='text'
					name='title'
					value={title}
					placeholder={t('work_title')}
					showError={errors.title}
					handleInputChange={handleChange}
					defaultValue={currentUser && currentUser.statusInfo.title}
				/>
			),
			size: cellSizes.titleCell,
		},
		{
			content: (
				<InputBuilder
					handleInputChange={handleChange}
					showError={errors.email}
					value={email}
					name='email'
					type='email'
					placeholder={t('email')}
					defaultValue={currentUser && currentUser.securityInfo.email}
				/>
			),
			size: cellSizes.emailCell,
		},
		{
			content: (
				<InputBuilder
					name='mobile'
					value={mobile}
					maxLength={12}
					handleInputChange={handleChange}
					showError={errors.mobile}
					type='number'
					placeholder={t('mobile')}
					defaultValue={currentUser && currentUser.personalInfo.mobile}
				/>
			),
			size: cellSizes.mobileCell,
		},
		{
			content: (
				<DropDownBuilder
					top='10px'
					name='department'
					handleChange={handleChange}
					defaultValue={currentUser && currentUser.statusInfo.department}
					values={departments}
				/>
			),
			size: cellSizes.departmentCell,
		},
		{
			content: (
				<DropDownBuilder // test dropdown builder
					top='10px'
					name='subDepartment'
					handleChange={handleChange}
					defaultValue={currentUser && currentUser.statusInfo.subDepartment}
					values={currentSubDepartments}
				/>
			),
			size: cellSizes.subDepartmentCell,
		},
		{
			content: (
				<DropDownBuilder
					top='10px'
					name='directManager'
					defaultValue={currentUser && currentUser.statusInfo.directManager}
					handleChange={handleChange}
					values={
						currentUser &&
							currentUser.securityInfo.email !== currentUser.statusInfo.directManager
							? managersEmails.filter(
								(email: string) => email !== currentUser.securityInfo.email
							)
							: managersEmails
					}
				/>
			),
			size: cellSizes.directManagerCell,
		},
		{
			content: (
				<DropDownBuilder
					top='10px'
					name='role'
					defaultValue={currentUser && currentUser.statusInfo.role}
					handleChange={handleChange}
					values={
						currentUser && currentUser.statusInfo.role === Roles.HR
							? [Roles.HR]
							: DataRoles
					}
				/>
			),
			size: cellSizes.roleCell,
		},
		{
			content: (
				<DropDownBuilder
					top='10px'
					name='license'
					handleChange={handleChange}
					defaultValue={currentUser && currentUser.statusInfo.license}
					values={currentLicense}
				/>
			),
			size: cellSizes.licenseCell,
		},
		{
			content: (
				<div className={classes.buttonsContainer}>
					<IconButton
						style={{
							padding: '2%',
							marginRight: '25%',
							fontSize: '150%',
							color: disabled ? 'grey' : '#34AB53',
						}}
						type='submit'
						aria-label='done'
						disabled={disabled}
					>
						<DoneIcon fontSize='inherit' />
					</IconButton>
					<IconButton
						onClick={() => onClose()}
						style={{ padding: '0', fontSize: '150%', color: '#FF4662' }}
						aria-label='cancel'
					>
						<CloseIcon fontSize='inherit' />
					</IconButton>
				</div>
			),
			size: cellSizes.actionsCell,
		},
	]

	return (
		<form onSubmit={handleNewEmployeeSubmition}>
			<div className={classes.formContent}>
				<TableRow cells={cells} />
			</div>
		</form>
	)
}

export default ManualInputs
const useStyles = makeStyles(() => ({
	formContent: {
		backgroundColor: '#F6F6F6',
	},
	actionButton: {
		backgroundColor: 'red',
		fontSize: '50%',
		width: '1px !important',
	},
	buttonsContainer: {
		display: 'flex',
	},
}))
