import { QuestionType } from 'interface/praise/praise-interface'
import { Langs } from '../../enums/app/index'
import { AnswerData } from './AnswersInputs'

interface State {
	collectionTypeValue: number
	backgroundIcon: string
	languages: typeof Langs
	selectedLanguage: Langs
	questionType: QuestionType
	id: string
	prevQuestion: string
	nextQuestion: string
	title: string
	subTitle: string
	answers: AnswerData[] | undefined
	skippable: boolean
}

export const initialState: State = {
	collectionTypeValue: 1,
	languages: Langs,
	selectedLanguage: Langs.en,
	questionType: QuestionType.SingleChoice,
	backgroundIcon: '',
	id: '',
	prevQuestion: '',
	nextQuestion: '',
	title: '',
	subTitle: '',
	skippable: false,
	answers: undefined,
}

export function reducer(state: any, action: any) {
	switch (action.type) {
		case 'CHOOSE_COLLECTION_VALUE':
			return { ...initialState, collectionTypeValue: action.value }
		// case 'SELECT_QUESTION_TYPE':
		// 	return { ...state, questionType: action.value }
		// case 'SELECT_PREV_QUESTION':
		// 	return { ...state, prevQuestion: action.value }
		// case 'SELECT_NEXT_QUESTION':
		// 	return { ...state, nextQuestion: action.value }
		// case 'SELECT_QUESTION_TITLE':
		// 	return { ...state, title: action.value }
		// case 'SELECT_QUESTION_SUB_TITLE':
		// 	return { ...state, subTitle: action.value }
		// case 'SELECT_SKIPPABLE':
		// 	return { ...state, skippable: action.value }
		case 'RESET_EDITOR':
			return {
				...initialState,
				collectionTypeValue: state.collectionTypeValue,
				selectedLanguage: state.selectedLanguage,
			}
		case 'SET_LANGUAGE':
			return {
				...state,
				collectionTypeValue: state.collectionTypeValue,
				selectedLanguage: action.value,
			}
		case 'SELECT_QUESTION_ICON':
			return { ...state, backgroundIcon: action.value }
		case 'UPDATE_QUESTION_ANSWERS':
			return {
				...state,
				answers: action.value,
			}
		case 'SET_QUESTION_TO_EDIT':
			const question = action.value

			const selectedLanguage: Langs = state.selectedLanguage
			return {
				...state,
				backgroundIcon: question.backgroundIcon,
				id: question._id,
				questionType: question.type,
				prevQuestion: question.previousQuestion,
				nextQuestion: question.nextQuestion,
				title: question.title[selectedLanguage],
				subTitle: question.subtitle[selectedLanguage],
				answers: question.answers,
				skippable: false,
			}

		default:
			throw new Error()
	}
}
