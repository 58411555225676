import { makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
	ChangeEventHandler,
	ReactElement,
	useContext,
	useEffect,
	useState,
} from 'react'
import { AppDirectionContext } from '../../../context/languages-context'
import { Styles } from 'screens/Bot/useStyles'

interface Props {
	onSelect: (value: string) => void
	width: string
	values: { id: string; fullName: string }[]
	defaultValue: { id: string; fullName: string } | undefined
}

const useStyles = makeStyles<Theme, Styles>(theme => ({
	dropDownContainer: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
	},
	dropDownText: {
		width: '100%',
		flex: 1,
		border: 'none',
		height: '2rem',
		marginRight: 0,
		display: 'flex',
		alignItems: 'center',
		borderRadius: '4px',
		backgroundColor: '#E8ECEF',
		color: '#4A4A4A',
		padding: `0 12px 0 12px`,
		cursor: 'pointer',
		appearance: 'none',
		'&:focus': {
			outline: '2px solid #0071C2',
		},
	},
	iconContainer: {
		backgroundColor: '#E8ECEF',
		padding: '4px',
		position: 'relative',
		top: -1.5,

		right: ({ isRtlDirection }) => (isRtlDirection ? -20 : 20),
		bottom: 'auto',
		display: 'flex',
		pointerEvents: 'none',
		alignItems: 'center',

		'& > i': {
			border: 'solid black',
			borderWidth: '0 1.5px 1.5px 0',
			display: 'inline-block',
			padding: '3px',
			transform: 'rotate(45deg)',
			webkitTransform: 'rotate(45deg)',
		},
	},
	text: {
		color: '#4A4A4A',
		fontFamily: 'Sora, sans-serif',
		letterSpacing: 0,
		lineHeight: '1rem',
		fontSize: '12px',
		cursor: 'pointer',
	},
}))
function DropDown({
	width,
	values,
	onSelect,
	defaultValue,
}: Props): ReactElement {
	console.log({ values, defaultValue })
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const { t } = useTranslation()
	const renderSelectors = () =>
		values.map((value: any) => {
			return (
				<option key={value.id} className={classes.text} value={value.id}>
					{t(value.fullName)}
				</option>
			)
		})
	return (
		<div className={classes.dropDownContainer} style={{ width: width }}>
			<select
				onChange={e => onSelect(e.target.value)}
				defaultValue={defaultValue ? defaultValue.id : ''}
				className={classes.dropDownText}
			>
				{defaultValue && renderSelectors()}
			</select>
			<div className={classes.iconContainer}>
				<i></i>
			</div>
		</div>
	)
}

export default DropDown
