import { FC, useRef } from 'react'
import { AnswersGroupProp } from '../../../interface/bot/index'
import { UserAnswer } from '../ActionButtons/index'
import { useSelector } from 'react-redux'
import { backgroundIconsMap } from '../../../utils/iconsMapper'
import { getBotAnswers } from '../../../redux/bot/bot-selector'
import { userSelector } from '../../../redux/user/user-selector'
import { useStyles } from './useStyles'
import { replaceString } from 'utils/replaceNameBot'
import ActionsButton from 'components/Bot/ActionButtons'
import { UseClickActions } from '../useHooks/index'

import { useTranslation } from 'react-i18next'
import ScrollableContent from 'components/common/ScrollableContent/ScrollableContent'

const SingleChoice: FC<AnswersGroupProp> = props => {
	const {
		isPraise,
		answers,
		nextQuestion,
		previousQuestion,
		backgroundIcon,
		setQuestion,
		type,
	} = props

	const classes = useStyles()
	const _answers = useSelector(getBotAnswers)
	const userSelecor = useSelector(userSelector)
	const recipientName = _answers.answers[0] ? _answers.answers[0].answer : ''
	const senderName = userSelecor?.personalInfo?.firstName
		? userSelecor.personalInfo.firstName
		: ''
	const { addAnswer } = UseClickActions(isPraise)

	const content = (
		<div id='answerCard' className={classes.singleChoiceWrapper}>
			{answers?.length &&
				answers.map(({ title, nextQuestion: _nextQuestion, outputType }, id) => {
					title = replaceString(title, {
						recipientPlaceholder: recipientName,
						senderPlaceholder: senderName,
					})
					const answer: UserAnswer = {
						id,
						nextQuestion: _nextQuestion ? _nextQuestion : nextQuestion!,
						answer: title,
						outputType: outputType,
						type,
					}
					return (
						<div className={classes.answerCard} onClick={() => addAnswer(answer)}>
							{title}
						</div>
					)
				})}
		</div>
	)

	return (
		<>
			<ScrollableContent content={content} />
			{backgroundIcon && (
				<div className={classes.imageWrapper}>
					<img
						className={classes.icon}
						src={backgroundIconsMap[backgroundIcon]}
						alt='backgroundIcon'
					/>
				</div>
			)}

			<ActionsButton
				isEnabled
				type={type}
				isPraise={isPraise}
				showPreviousButton
				previousQuestionId={previousQuestion}
				setQuestion={setQuestion}
			/>
		</>
	)
}

export default SingleChoice

// export const useIndicator = (
// 	items: any[],
// 	contentRef: React.MutableRefObject<HTMLDivElement | null>
// ) => {
// 	const [shouldRenderIndicator, setShouldRenderIndicator] = useState(false)
// 	const handleScroll = useCallback(() => {
// 		const { scrollTop = 0 } = contentRef.current ?? {}

// 		setShouldRenderIndicator(scrollTop < 50)
// 	}, [])
// 	useLayoutEffect(() => {
// 		const { scrollHeight = 0, offsetHeight = 0 } = contentRef.current ?? {}
// 		setShouldRenderIndicator(scrollHeight > offsetHeight)
// 	}, [items])
// 	return { shouldRenderIndicator, handleScroll }
// }
