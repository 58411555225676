import { createSelector } from 'reselect'
import { IStoreRootState } from 'interface/redux'

export const debriefStateSelector = (state: IStoreRootState) => state.debrief

export const debriefLoadingSelector = createSelector(
	debriefStateSelector,
	state => state.loading
)
export const debriefErrorSelector = createSelector(
	debriefStateSelector,
	state => state.error
)
export const userDebriefingsSelector = createSelector(
	debriefStateSelector,
	state => state.userDebriefings || []
)

export const debriefOnProccesSelector = createSelector(
	debriefStateSelector,
	state => state.debriefOnProcess || null
)

export const debriefEventOnEditSelector = createSelector(
	debriefStateSelector,
	state => state.debriefEventOnEdit || null
)

export const hasMoreDebriefsSelector = createSelector(
	debriefStateSelector,
	state => state.hasMoreDebriefs
)
