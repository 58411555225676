import { useState, useEffect } from 'react'

import IUser from '../../interface/user/user-interface'
import { Context, PraiseOutput } from '../../interface/bot/index'

import { Req } from 'enums/req/req.enum'
import { Langs } from '../../enums/app/index'
import { replaceFunction } from '../outputs/helpers'

import { fetchPraiseOutputById } from '../../api/praise'

type UseOutputPayloadArgsType = {
	currentUserInfo: IUser
	isReciever: boolean
	replacements: Record<string, any>
	outputBeforeMap: PraiseOutput<IUser>
}

const initialOutputData = {
	FREE_TEXT_LABEL: '',
	CONTRIBUTION: '',
	CONTEXT: '',
	IMPACTS: [] as string[],
	EXPANSIONS_CONTRIBUTION: '',
	EXPANSIONS_IMPACTS: [] as string[]
}

export const usePraiseOutputData = (params: UseOutputPayloadArgsType) => {
	const { currentUserInfo, isReciever, replacements, outputBeforeMap } = params

	const [outputData, setOutputData] = useState(initialOutputData)

	useEffect(() => {
		if (!outputBeforeMap?.outputType) return

		const getPraiseReplacemnts = async (id: string) => {
			const payload = { id, isBeforeSend: isReciever ? 'false' : 'true' }
			const language = currentUserInfo.statusInfo.companyLanguage

			if (!id) {
				//  fix error with output type.
				alert('An error occurred in the processes Please start anew!')
				return
			}

			const { data, status }: { data: any; status: any } =
				await fetchPraiseOutputById(payload)

			if (status === Req.failed) {
				alert('no output type!')
				return
			}

			const { mappedImpacts = [], context = {}, ...rest } = data
			let output = rest.beforeSend

			const isBeforeSend = payload.isBeforeSend === 'true'
			if (!isBeforeSend) {
				output = rest.afterSend
			}

			const {
				freeTextDescriptions = {},
				contributionToSuccess = {},
				aboutContext = {},
			} = output

			const replacedText = replaceFunction(
				freeTextDescriptions,
				replacements,
				language
			)

			const replacedContribution = replaceFunction(
				contributionToSuccess,
				replacements,
				language
			)

			let mappedFrameWorkContext = ''
			if ('context' in data && outputBeforeMap.context in data.context) {
				mappedFrameWorkContext = data.context[outputBeforeMap.context][language]
			}

			const notRelevant = [Context.NOT_RELEVANT, Context.NOT_RELEVANT_HE]

			const isNotRelevantContext = notRelevant.includes(
				outputBeforeMap.context as Context
			)

			const frameWorkContext = mappedFrameWorkContext
				? mappedFrameWorkContext
				: outputBeforeMap?.context && outputBeforeMap?.context.trim().length
					? isNotRelevantContext
						? ' '
						: `(${outputBeforeMap.context})`
					: ''

			const replacedReason = replaceFunction(
				aboutContext,
				{
					...replacements,
					'%CONTEXT%': frameWorkContext,
				},
				language
			)

			const furtherImpacts = mapFurtherImpact({
				userLang: language,
				indexes: outputBeforeMap.furtherImpact,
				impacts: mappedImpacts,
				rep: replacements,
				isBeforeSend: isBeforeSend,
			})

			setOutputData({
				FREE_TEXT_LABEL: replacedText,
				CONTRIBUTION: outputBeforeMap.contributions ? replacedContribution : '',
				CONTEXT: replacedReason,
				IMPACTS: furtherImpacts,
				EXPANSIONS_CONTRIBUTION: outputBeforeMap.contributionExpansion!,
				EXPANSIONS_IMPACTS: outputBeforeMap.impactsExpansion as []
			})
		}
		getPraiseReplacemnts(outputBeforeMap.outputType)
	}, [outputBeforeMap?.outputType, currentUserInfo])

	return outputData
}
type MapFurtherImpactFuncType = {
	userLang: Langs
	indexes: string[]
	impacts: Impcats[]
	rep: Record<string, any>
	isBeforeSend: boolean
}
type Impcats = {
	en: string
	he: string
	fr: string
	id: string
}
type ImpactIndex = {
	id: string
	impacted: string
}
export const mapFurtherImpact = (params: MapFurtherImpactFuncType) => {
	const { indexes = [], impacts, rep, userLang, isBeforeSend } = params

	const mappedIndexes = indexes
		.filter(Boolean)
		.map(index => buildIndexObj(index))

	const impactsTemplate = impacts.filter(impact =>
		mappedIndexes.map(indexes => indexes.id).includes(impact.id)
	)

	const mappedImpacts = getFurtherImpact(
		mappedIndexes,
		impactsTemplate,
		rep,
		userLang,
		isBeforeSend
	)
	return mappedImpacts
}
const getFurtherImpact = (
	mappedIndexes: ImpactIndex[],
	impactsTemplate: Impcats[],
	replacements: Record<string, string>,
	userLang: Langs,
	isBeforeSend: boolean
) => {
	return impactsTemplate.map(impact => {
		const _impact = impactsMaped(impact, userLang, {
			...replacements,
			id: mappedIndexes.filter(i => i.id === impact.id)[0].impacted,
			'%IMPACTED%': getImpacted(
				mappedIndexes,
				impact.id,
				userLang,
				replacements['%NAME%'],
				isBeforeSend
			),
		})

		return _impact
	})
}

const buildIndexObj = (index: string) => ({
	id: index.split('-')[0],
	impacted: index.split('-')[1] ?? ' ',
})

const getImpacted = (
	mappedIndexes: ImpactIndex[],
	id: string,
	userLang: Langs,
	userName: string,
	isBeforeSend: boolean
) => {
	const impacted = mappedIndexes.filter(i => i.id === id)[0].impacted

	if (!impacted) return ''

	const impact = isBeforeSend
		? impactedBeforeSend[impacted][userLang].replace(
			/%\w+%/g,
			function (all: string) {
				return userName || all
			}
		)
		: impactedAfterSend[impacted][userLang].replace(
			/%\w+%/g,
			function (all: string) {
				return userName || all
			}
		)

	return impact
}

const impactsMaped = (
	chosedImpact: Record<Langs, string>,
	userLang: Langs,
	replacements: Record<any, any>
) => {
	const im = connectHebrewWords(
		chosedImpact[userLang],
		replacements['id']
	).replace(/%\w+%/g, function (all: string) {
		return replacements[all] || all
	})
	return im
}

// <!---- before----->
const impactedBeforeSend: Record<string, any> = {
	1: {
		en: 'everyone',
		he: ' כולם ',
		fr: '',
	},
	2: {
		en: '%NAME%',
		he: '%NAME%',
		fr: '%NAME%',
	},
	3: { en: 'your employees', he: 'עובד/ים שלך', fr: '' },
	4: { en: 'your customers', he: 'לקוח/ות שלך', fr: '' },
	5: { en: 'your suppliers', he: 'ספק/ים שלך', fr: '' },
	6: { en: 'your manager', he: 'מנהל שלך', fr: '' },
	7: {
		en: 'the team you are both on',
		he: 'צוות המשותף שלך ושל %NAME%',
		fr: '',
	},
	8: { en: 'your colleagues', he: 'עמית/ים שלך', fr: '' },
	9: { en: 'senior management', he: 'הנהלה הבכירה', fr: '' },
}

// <!---- after ----->
const impactedAfterSend: Record<string, any> = {
	1: {
		en: 'everyone',
		he: 'כולם',
		fr: '',
	},
	2: {
		en: 'yourself',
		he: 'אלייך',
		fr: '',
	},
	3: { en: "%NAME%'s employees", he: 'עובד/ים של %NAME%', fr: '' },
	4: { en: "%NAME%'s customers", he: 'לקוח/ות של %NAME%', fr: '' },
	5: { en: 'your suppliers', he: 'ספק/ים של %NAME%', fr: '' },
	6: { en: "%NAME%'s manager", he: 'מנהל של %NAME%', fr: '' },
	7: {
		en: 'the team you are both on',
		he: 'צוות המשותף שלכן שלך ושל %NAME%',
		fr: '',
	},
	8: { en: 'colleagues', he: 'עמית/ים של %NAME%', fr: '' },
	9: { en: 'senior management', he: 'הנהלה הבכירה', fr: '' },
}

const WordsToConnect = {
	inRelation: 'ביחס',
	inWhomEyes: 'בעיני',
	inFrontOf: 'מול',
}
const inWhomEyesIdx = [1, 2]

const connectHebrewWords = (word: string, idx: number) => {
	if (word.search(WordsToConnect.inRelation) != -1) {
		return word.replace(WordsToConnect.inRelation, `ביחס ל`)
	}
	if (word.search(WordsToConnect.inWhomEyes) && !inWhomEyesIdx.includes(+idx)) {
		return word.replace(WordsToConnect.inWhomEyes, `בעיני ה`)
	}
	if (word.search(WordsToConnect.inFrontOf) && !inWhomEyesIdx.includes(idx)) {
		return word.replace(WordsToConnect.inFrontOf, `בעיני ה`)
	}

	return word
}
