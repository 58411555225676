import { FC } from 'react'
import { Typography, makeStyles, Theme } from '@material-ui/core'
import emptyCardIcon from 'assets/empty-task-list.svg'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles<Theme>(({ breakpoints, palette }) => ({
	emptyCatdContainer: {
		flex: '50%',
		position: 'relative',
		maxWidth: '47.5%',
		height: '88px',
		borderRadius: '10px',
		display: 'flex',
		alignItems: 'center',
		marginBottom: '19px',
		color: palette.text.primary,
		[breakpoints.down('sm')]: {
			margin: '5px 0',
			flex: '100%',
			maxWidth: '100%',
		},
		'&:last-child': {
			marginRight: 0,
		},
	},
	CreaditCardIconWrapper: {
		marginInlineEnd: '15px',
	},
	emptyCardTitle: {
		fontWeight: 700,
		color: palette.divider,
	},
	emptyCardSubitle: {
		color: palette.divider,
	},
}))
interface Props {
	tTitle: string
	tSubTitle?: string
}

export const EmptyCard: FC<Props> = ({ tTitle, tSubTitle }) => {
	const classes = useStyles()
	const { t } = useTranslation()
	return (
		<div className={classes.emptyCatdContainer}>
			<div style={{ display: 'flex' }}>
				<div className={classes.CreaditCardIconWrapper}>
					<img src={emptyCardIcon} alt='empty card icon' />
				</div>
			</div>
			<div style={{ flex: 1 }}>
				<Typography className={classes.emptyCardTitle} variant='h5'>
					{tTitle ? t(tTitle) : null}
				</Typography>
				<div className={classes.emptyCardSubitle}>
					<Typography variant='h5'>{tSubTitle ? t(tSubTitle) : null}</Typography>
				</div>
			</div>
		</div>
	)
}
