import { useState } from 'react'
import { useSelector } from 'react-redux'

import { userSelector } from 'redux/user/user-selector'

import { getData } from './tableUtils'

import { DebriefingPaths } from 'enums/debriefing/debriefing-paths.enum'

interface UseTab<T> {
	data: T
	filter: Function
	options: {
		isRtlDirection: boolean
	}
}
enum TAB {
	ALL = 0,
	PERSONAL = 1,
	GROUP = 2,
}
export const useDebriefingTabs = <T extends []>(args: UseTab<T>) => {
	const { _id = '' } = useSelector(userSelector)
	const [currentTab, setCurrentTab] = useState(0)
	const { data, filter, options } = args
	const { isRtlDirection } = options

	const filterByPath = (path: DebriefingPaths) => {
		const isPersonal = path === DebriefingPaths.PERSONAL
		setCurrentTab(isPersonal ? TAB.PERSONAL : TAB.GROUP)

		const filteredDebriefings = data.filter(debreif =>
			filter(debreif, path, isPersonal)
		)
		return getData(filteredDebriefings, isRtlDirection)
	}
	const reset = () => {
		setCurrentTab(TAB.ALL)
	}
	return { currentTab, reset, filterByPath }
}
