import { useEffect, useState } from "react"

import { isFolderType } from "utils/LessonsManagement/isFolder"

import { ILessonsBase, Folder, File, BaseFolderChild } from "interface/lessons-management/LessonsBase.interface"

type Props = {
    currentFolder: ILessonsBase<Folder | File> | null | undefined
}
const useCacheFolders = ({ currentFolder }: Props) => {
    const [cacheFolders, setCacheFolders] = useState<Set<ILessonsBase>>(new Set())

    const getFolderFromCache = (folder_id: string | null) => {
        return Array.from(cacheFolders).find(f => f._id === folder_id)
    }

    useEffect(() => {
        saveCurrentFolderInCache()
    }, [currentFolder])

    const saveCurrentFolderInCache = () => {
        if (!currentFolder) return

        addFolderInCache()
    }

    const addFolderInCache = (): void => {
        if (folderHasAlreadyCached(currentFolder?._id!)) {
            if (isFolderUpdated()) {
                setCacheFolders(prevSet => replaceSetItems(prevSet))
            }

            else return
        }

        setCacheFolders(prevSet => chainingFolderToCache(prevSet))
    }
    const folderHasAlreadyCached = (folder_id: string) => {
        for (const { _id } of cacheFolders) {
            if (_id === folder_id) return true
        }
        return false
    }
    const chainingFolderToCache = (prevSet: Set<ILessonsBase<Folder | File>>) => {
        return new Set([...prevSet, currentFolder!])
    }

    const isFolderUpdated = (): boolean => {
        const cachedFolder = getFolderFromCache(currentFolder?._id!)
        if (
            nameIsBeenChanged(cachedFolder?.name!, currentFolder?.name!) ||
            permissionsListIsChanged(cachedFolder?.permissions!, currentFolder?.permissions!) ||
            childrenHaveBeenUpdated(cachedFolder)
        ) return true

        return false
    }
    const nameIsBeenChanged = (cachedFolderName: string, updatedFolderName: string): boolean => {
        return Boolean(cachedFolderName?.trim() !== updatedFolderName?.trim())
    }
    const permissionsListIsChanged = (cachedPermissionsList: string[], updatedPermissionsList: string[]): boolean => {
        if (noAnyPermissions(cachedPermissionsList.length, updatedPermissionsList.length)) return true

        return folderHasConcretePermissions(cachedPermissionsList?.length, updatedPermissionsList?.length) ||
            usersPermissionsChanged(cachedPermissionsList, updatedPermissionsList!)
    }
    const noAnyPermissions = (
        cachedPermissionsLength?: number,
        updatedPermissionsLength?: number
    ) => {
        return cachedPermissionsLength && !updatedPermissionsLength
    }
    const folderHasConcretePermissions = (
        cachedPermissionsLength?: number,
        updatedPermissionsLength?: number
    ): boolean => {
        const permissionsListIsChanged = cachedPermissionsLength !== updatedPermissionsLength

        if (permissionsListIsChanged) return true

        //Reach here if the length of both arrays is equal and continue deep checking
        return false
    }
    const usersPermissionsChanged = (cachedPermissionsList: string[], updatedPermissionsList: string[]): boolean => {
        const newAndOldListAreSame =
            updatedPermissionsList?.every((user: string) => cachedPermissionsList?.includes(user))
        return newAndOldListAreSame ? false : true
    }
    const childrenHaveBeenUpdated = (cachedFolder?: ILessonsBase<Folder | File>) => {
        if (isFolderType(cachedFolder?.type!) && isFolderType(currentFolder?.type!)) {
            const cachedChildren: BaseFolderChild[] | undefined = cachedFolder?.type?.children
            const updatedChildren: BaseFolderChild[] | undefined = currentFolder?.type?.children
            const someChildAddedOrRemoved = cachedChildren?.length !== updatedChildren?.length

            if (someChildAddedOrRemoved) return true

            return someChildFolderChanged(cachedChildren, updatedChildren!)
        } else {
            //In case it's File updated always return 'true' without checking for any changes 
            return true
        }
    }
    const someChildFolderChanged = (
        cachedChildren?: BaseFolderChild[],
        updatedChildren?: BaseFolderChild[]
    ): boolean => {
        for (const oldChild of cachedChildren!) {
            for (const newChild of updatedChildren!) {
                if (newChild._id === oldChild._id) {
                    if (
                        nameIsBeenChanged(newChild.name, oldChild.name) ||
                        permissionsListIsChanged(oldChild.permissions, newChild.permissions)
                    ) return true
                }
            }
        }
        return false
    }

    //In Set, to avoid duplicates, we should delete the previous item and insert the newest instead
    const replaceSetItems = (prevSet: Set<ILessonsBase<Folder | File>>) => {
        const updatedSet = new Set(prevSet)

        const cachedFolder = getFolderFromCache(currentFolder?._id!)

        updatedSet.delete(cachedFolder!)
        updatedSet.add(currentFolder!)

        if (isFolderType(cachedFolder?.type!)) {
            deleteChildrenFromCache(cachedFolder?.type.children!, updatedSet)
        }

        const parentFolder = getFolderFromCache(cachedFolder?.prev!)

        if (isFolderType(parentFolder?.type!)) {
            updateBackwardFolders(parentFolder as ILessonsBase<Folder>, updatedSet)
        }

        return updatedSet
    }

    const deleteChildrenFromCache = (
        cachedChildren: BaseFolderChild[],
        updatedSet: Set<ILessonsBase<Folder | File>>
    ) => {
        cachedChildren.map((child: BaseFolderChild) => {
            const cachedChild = getFolderFromCache(child._id)

            if (cachedChild) updatedSet.delete(cachedChild)
        })
    }

    const updateBackwardFolders = (
        parentFolder: ILessonsBase<Folder>,
        updatedSet: Set<ILessonsBase<Folder | File>>
    ) => {
        const cachedFolder = getFolderFromCache(currentFolder?._id!)
        const cachedFolderAsChild = getNestedChild(parentFolder?.type.children!, cachedFolder?._id!)

        const updatedChild = initChildNewData(cachedFolderAsChild)

        const updatedChildrenList =
            getUpdatedParentChildrenList(parentFolder?.type.children, cachedFolder?._id!, updatedChild)

        const updatedParent = { ...parentFolder, type: { children: updatedChildrenList } } as ILessonsBase

        updatedSet.delete(parentFolder!)
        updatedSet.add(updatedParent)

        return updatedSet
    }

    const initChildNewData = (cachedFolderAsChild?: BaseFolderChild): BaseFolderChild => {
        return {
            ...cachedFolderAsChild,
            name: currentFolder?.name,
            permissions: currentFolder?.permissions
        } as BaseFolderChild
    }

    const getNestedChild = (parentChildren: BaseFolderChild[], _id: string) => {
        return parentChildren?.find((child: BaseFolderChild) => (child._id === _id))
    }

    const getUpdatedParentChildrenList = (
        parentChildren: BaseFolderChild[],
        _id: string,
        updatedChild: BaseFolderChild
    ) => {
        return parentChildren?.map((child: BaseFolderChild) => {
            if (child._id === _id) return updatedChild

            return child
        })
    }

    const clearCacheFolders = () => setCacheFolders(new Set())

    return { saveCurrentFolderInCache, getFolderFromCache, clearCacheFolders }
}

export default useCacheFolders