export enum PraiseState {
	SENT = 'sent',
	VIEW = 'view',
	NEW_PRAISE = 'new-praise',
	VIEWED = 'viewed',
}

export enum PraiseAndFeedbacksType {
	SENT = 'sent',
	RECEIVED = 'received',
	DRAFT = 'draft'
}
