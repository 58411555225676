import { ModalButtonLabel, ModalOperations } from "enums/lessonsManagement";

export default function initModalInfo() {
    return {
        name: [{ label: '', placeholder: '' }],
        title: '',
        operation: ModalOperations.NEW_FOLDER,
        modalBtnLabel: ModalButtonLabel.Create,
    }
}
