import { ISignInParameters } from 'interface/user/user-interface'
import { POST, DELETE } from './api-requests'
import { URLS } from './api-urls'
import { Response } from 'interface/api/response-interface'

export const alertList = async (user_id: string): Promise<Response> => {
	const { data, status } = await DELETE(`${URLS.AUTHENTICATE}/${user_id}`)
	return { data, status }
}

export const signIn = async (
	userData: ISignInParameters
): Promise<Response> => {
	const { data, status } = await POST(URLS.AUTHENTICATE, { ...userData })
	return { data, status }
}

export const forgotPassword = async (email: string): Promise<Response> => {
	const { data, status } = await POST(`${URLS.COMPANY}/reset`, { email })
	return { data, status }
}
export const forgotPasswordV2 = async (email: string): Promise<Response> => {
	const { data, status } = await POST(`${URLS.COMPANY}/reset`, { email })
	return { data, status }
}
