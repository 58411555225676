import { makeStyles } from '@material-ui/core/styles'
import { Theme, lighten } from '@material-ui/core'
import colors from 'utils/colors'
import { PraiseState } from 'enums/praise-state/praise-state.enum'

interface StyleProps {
	isPraiseButton: boolean
	expanded: boolean
	cursor?: 'pointer' | 'initial'
	isRtlDirection: boolean
	isLastPanelDisplay?: boolean
	praiseState?: PraiseState
}

export default makeStyles<Theme, StyleProps>(({ palette, breakpoints }) => ({
	accordion: {
		// backgroundColor: ({ isLastPanelDisplay }) => (isLastPanelDisplay ? 'red' : '#000'),
		cursor: 'pointer',
		boxShadow: 'none',
		margin: '0px !important',
		backgroundColor: ({ expanded }) =>
			expanded ? lighten(palette.text.disabled, 0.91) : 'inherit',
		borderLeft: ({ expanded }) =>
			expanded ? `5px solid ${palette.secondary.main}` : 'none',
		borderBottom: ({ expanded }) =>
			expanded ? `1px solid ${palette.action.disabled}` : 'none',
		borderTop: ({ expanded }) =>
			expanded ? `1px solid ${palette.action.disabled}` : 'none',
		'&:last-child': {
			borderBottomLeftRadius: '10px',
			borderBottomRightRadius: '10px',
		},
		'&:first-child': {
			borderTop: 'none',
		},
		'& .MuiAccordionSummary-content': {
			display: 'flex',
			flexDirection: 'column',
			margin: 0,
			padding: '23px',
			// cursor: ({ cursor }) => cursor || 'initial',

			[breakpoints.down('sm')]: {
				position: 'relative',
				padding: '10px',
				width: '100%',
			},
		},
		'& .MuiAccordionSummary-root': {
			// cursor: ({ cursor }) => (cursor || 'initial') + ' !important',
			[breakpoints.down('sm')]: {
				display: 'flex',
				flexDirection: 'column',

				borderBottom: `1px solid ${palette.action.disabled}`,
			},
		},
	},
	accordionSummary: {
		padding: 0,
	},
	accordionDetails: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '18px 23px 23px',
		[breakpoints.up('sm')]: {
			gap: '30px',
			width: '75%',
			'& div': {
				flex: '50%',
			},
		},
		[breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	eventSummary: {
		[breakpoints.down('sm')]: {
			marginBottom: '22px',
		},
	},
	accordionDetailsTitle: {
		fontWeight: ({ praiseState }) =>
			praiseState === PraiseState.VIEWED ? 400 : 700,
		fontSize: '13px',
		marginBottom: '4px',
	},
	accordionDetailsDescription: {
		fontSize: '13px',
		color: palette.text.secondary,
	},
	details: {
		display: 'flex',
		justifyContent: 'space-between',
		[breakpoints.down('sm')]: {
			width: '100%',
			justifyContent: 'space-between',
			'&>p': {
				border: '0.7px solid #34AB53',
				margin: '2% 7% 0 0',
				borderRadius: '29px',
				width: '25px',
				height: '29px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
		},
	},
	avatar: {
		margin: '0 10px 0 10px',
		display: 'flex',
	},
	avatarInitials: {
		fontSize: '18px',
	},
	detailsTextContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	name: {
		fontSize: '14px',
		fontWeight: ({ praiseState }) =>
			praiseState === PraiseState.VIEWED ? 400 : 700,
	},
	dateOn: {
		color: palette.text.disabled,
		fontSize: '13px',
		fontWeight: 500,
	},
	date: {
		fontSize: '13px',
		fontWeight: ({ praiseState }) =>
			praiseState === PraiseState.VIEWED ? 400 : 700,
	},
	description: {
		fontSize: '14px',
		marginTop: /*'12px'*/ '3px',
		width: '70%',
		fontWeight: ({ praiseState }) =>
			praiseState === PraiseState.VIEWED ? 400 : 700,
		// whiteSpace: 'nowrap',
		// overflow: 'hidden',
		// textOverflow: 'ellipsis',

		[breakpoints.down('sm')]: {
			wordBreak: 'break-word',
			padding: '0 3.5% 0 3.5% ',

			width: '90%',
		},
	},
	topRightContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[breakpoints.down('sm')]: {},
		'&>p': {
			margin: '2% 0 0 0',
		},
	},
	coinsContainer: {
		marginRight: '15px',
		display: 'flex',
		alignItems: 'center',
	},
	userDetails: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	indication: {
		border: `.7px solid ${colors.saydoGreen}`,
		borderRadius: '15px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '2%',
		width: '16%',
	},
	coins: {
		fontSize: '13px',
		fontWeight: ({ praiseState }) =>
			praiseState === PraiseState.VIEWED ? 400 : 700,
	},
	coinIcon: {
		marginRight: '4px',
		marginBottom: '12px',
	},
	modalButton: {
		position: 'relative',
		color: palette.common.white,
		backgroundColor: ({ isPraiseButton }) =>
			isPraiseButton ? palette.primary.main : palette.secondary.main,
		borderRadius: '16.5px',
		fontSize: '14px',
		fontweight: 700,
		textTransform: 'capitalize',
		height: '33px',
		width: '152px',
		cursor: 'pointer',
		borderColor: 'transparent',
		'&:hover': {
			backgroundColor: ({ isPraiseButton }) =>
				isPraiseButton ? '#269142' : '#0062A8',
		},
		[breakpoints.down('sm')]: {
			margin: ({ isRtlDirection }) =>
				isRtlDirection ? '2% auto 0 2%' : '2% 2% 0 auto',
			width: '30vw',
			minWidth: '135px',
		},
	},
	viewedIcon: {
		position: 'absolute',
		right: '7%',
		bottom: '24%',
	},
	flash: {
		animation: 'flash 1s linear',
		animationDuration: '2.5s',
	},
	'@global': {
		'@keyframes flash': {
			'0%': {
				// opacity: 0,
				backgroundColor: 'rgba(52, 171, 83, 0.3)',
			},
			'100%': {
				// opacity: 1,
				backgroundColor: 'rgba(52, 171, 83, 0)',
			},
		},
	},
	replay: {
		border: '1px solid lightgrey',
		borderRadius: '8px',
		height: '21px',
		padding: '1%',
		minWidth: '67%',
		width: '1rem',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'& > span': {
			color: colors.saydoGreen,
		},
	},
}))
