import { makeStyles, Theme } from '@material-ui/core/styles'

type Styles = {
	licenseValue?: any
	onEdit?: boolean
	isRtlDirection?: boolean
}
export default makeStyles<Theme, Styles>(({ palette }) => ({
	mainContainer: {
		color: '#46484a',
		margin: 'auto',
		width: '98%',
		justifyContent: 'flex-end',
		'& h2': {
			padding: '0',
			margin: '0 0 1% 0',
		},
		'& form': {
			'& input': {
				appearance: 'textfield',
				width: '12vw',
				border: '2px solid #A5A5A5',
				borderRadius: '8px',
				padding: '4% 3% 4% 3%',
				'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
					'-webkit-appearance': 'none',
					display: 'none',
				},
			},
			'& input:focus': {
				outline: 'none',
				border: '2px solid #0071C2',
			},
		},
	},
	trashIconReplacemnent: {
		margin: 'auto',
		width: '3.15%',
	},
	expandIcon: {
		width: '25px',
		height: '25px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	formButtonsContainer: {
		'& button': {
			width: '12vw',
		},
		marginTop: '2%',
		justifyContent: 'space-between',
		margin: 'auto',
		width: '40%',
	},
	licenseInput: {
		marginLeft: '5px',
		width: '5vh !important',
		height: '10%',
		textAlign: 'center',
	},

	company: {
		'& $actionButton': {
			margin: 'auto',
		},
		'& $trashIcon': {
			margin: 'auto',
			'& img': {
				width: '1.2vw',
			},
		},
		'& h2': {
			alignSelf: 'center',
			fontSize: '110%',
		},
	},
	annualFee: {
		fontSize: '80%',
		height: '100%',
		alignSelf: 'center',
		width: '10%',
	},
	identifier: {
		overflow: 'hidden',
		width: '10%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},
	companyName: {
		marginTop: '6%',
	},
	licensesTitle: {
		fontSize: '90%',
		width: '25%',
		margin: 'auto',
	},
	licenses: {
		display: 'flex',
		marginLeft: 'auto',
		width: '77%',
	},

	license: {
		width: '35%',
		fontSize: '120%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	actionName: {
		textDecoration: 'underline',
		color: '#0071C2',
	},
	divider: {
		margin: '2.5vh 0 2.5vh 0',
		width: '100%',
		borderTop: '1px solid grey', //change for rel color
	},
	actionButton: {
		fontSize: '100%',
		fontWeight: 'bold',
		backgroundColor: 'transparent',
		border: 'none',
		display: 'flex',
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
	},
	flexCenter: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: ({ isRtlDirection }) => (isRtlDirection ? '0 auto 0 0 ' : '0 0 0 auto'),
		width: ({ licenseValue, onEdit }) => (!licenseValue || onEdit ? '100%' : '70%'),
	},
	contacts: {
		'& $actionButton': {
			marginTop: '1%',
		},
	},
	contact: {},
	trashIcon: {
		backgroundColor: 'transparent',
		border: 'none',
		alignSelf: 'flex-end',
	},
	mainDetails: {
		width: '75%',
	},
	label: {
		color: 'red',
	},
	inputContainer: {
		height: '70px',
		fontWeight: 'bolder',
		justifyContent: 'space-between',
		display: 'flex',
		flexDirection: 'column',
		'& span': {
			marginLeft: '3%',
			fontSize: '80%',
			color: 'tomato',
			height: '1%',
		},
	},
	uploadButtonContainer: {
		margin: '2vw 12vw 0 3vw',
		'& button': {
			backgroundColor: '#F8FCFF',
			color: '#0071C2',
			border: '1px solid #A5A5A5',
			padding: '10px',
			borderRadius: '10px',
			fontWidth: 'bolder',
			fontSize: '120%',
			width: '100%',
		},
	},
	uploadImg: {
		backgroundSize: '100% 100%',
		justifyContent: 'center',
	},
	uploadPreviewImg: {
		borderRadius: '100%',
		backgroundSize: '100px',
		width: '80%',
	},
	closeButton: {
		margin: '1% 0 0 auto',
	},
	overview: {
		width: '10vw',
		marginRight: '5%',
	},
	editSection: {
		justifyContent: 'flex-start',
	},
	editCard: {
		backgroundColor: '#F6F6F6 !important',
		marginBottom: '2% !important',
		borderRadius: '15px !important',
		'& $mainDetails': {
			width: '75%',
		},
	},
	editInputsSection: {
		width: '100% ',
	},
	editCompanyMainDetails: {
		height: '10%',
		width: '28vw',
		display: 'flex',
		'& h1': {
			margin: '0 0 2% 0',
		},
		'& span': {
			margin: '4%',
		},
	},
}))
