import classes from './styles.module.css'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import ContactNow from '../ContactNow/ContactNow'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import { AppDirectionContext } from 'context/languages-context'
interface Props {
	contactInfo: { name: string; mobile: string }
	question: string
	answer: boolean | null
	handleAnswer: (e: any, q: string) => void
	showNotification: boolean
}
const AnswerButtons = ({
	handleAnswer,
	question,
	answer,
	contactInfo,
	showNotification,
}: Props) => {
	const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)
	return (
		<div
			className={
				isRtlDirection
					? `${classes.buttonsContainer} ${classes.rtl}`
					: classes.buttonsContainer
			}
		>
			<ToggleButtonGroup
				className={classes.toggleGroup}
				value={answer}
				exclusive
				onChange={(e: any) => {
					handleAnswer(e.target.value === 'true' ? true : false, question)
				}}
			>
				<ToggleButton
					className={
						!answer || answer == null
							? `${classes.button}`
							: `${classes.button} ${classes.active}`
					}
					value={true}
				>
					{t('yes')}
				</ToggleButton>
				<ToggleButton
					className={
						answer || answer == null
							? `${classes.button}`
							: `${classes.button} ${classes.active} ${classes.negative}`
					}
					value={false}
				>
					{t('no')}
				</ToggleButton>
			</ToggleButtonGroup>
			{!answer && answer !== null && showNotification && (
				<ContactNow contactInfo={contactInfo} />
			)}
		</div>
	)
}

export default AnswerButtons
