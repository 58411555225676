import { useEffect, useState } from "react"

import UserTeamTasks from './UserTeamTasks'

import { ITeamTask } from "interface/teams/teams-interface"
import IUser from "interface/user/user-interface"
import useOutputFilterRule from "utils/useOutputFilterRule"

type Props = {
    tasks: ITeamTask<IUser>[]
}

const useManageTasks = ({ tasks }: Props) => {
    const [filteredTeamTasks, setFilteredTeamTasks] = useState<ITeamTask<IUser>[]>([])

    const { byEmployee } = useOutputFilterRule()

    const resetFilteredTasks = () => setFilteredTeamTasks([])

    useEffect(() => {
        isWorkMeeting(byEmployee?.trim()!)
    }, [byEmployee, tasks])

    const isWorkMeeting = (empId: string) => {
        if (!empId) return

        setFilteredTeamTasks(selectedEmployeeTasks())
    }

    const selectedEmployeeTasks = () => {
        return (tasks?.filter(t => t.employees_id.find(u => u._id === byEmployee)))
    }

    const counterDomElements = (<UserTeamTasks setFilteredTeamTasks={setFilteredTeamTasks} tasks={tasks} />)

    return { counterDomElements, filteredTeamTasks, resetFilteredTasks }
}

export default useManageTasks