import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { employeesSelector } from 'redux/employee/employee.selector'

import IUser from 'interface/user/user-interface'
import { Roles } from 'enums/user/user-roles'
export enum ListType {
	all = 'view_all',
	managers = 'view_managers',
}
type Props = {
	page: number
	listType: ListType
	searchResults: { results: IUser[]; searchValue: string }
}
const useTablePagination = ({ listType, page, searchResults }: Props) => {
	const employees = useSelector(employeesSelector)

	const createMatrix10 = (employeesList: IUser[]) => {
		let employeesPart = 10

		let currentEmployeesMatrix = employeesList.reduce(
			(currentEmployeesChunk: Array<IUser[]>, employee: IUser, index) => {
				const chunkIndex: number = Math.floor(index / employeesPart)
				if (!currentEmployeesChunk[chunkIndex]) {
					currentEmployeesChunk[chunkIndex] = []
				}
				currentEmployeesChunk[chunkIndex].push(employee)
				return currentEmployeesChunk
			},
			[]
		)
		return currentEmployeesMatrix
	}

	const employeesChuncks = useMemo(() => createMatrix10(employees), [employees])

	const onListTypeChanged = () => {
		const previousChunk = employeesChuncks[page - 1]
			? employeesChuncks[page - 1]
			: []

		if (listType === ListType.all) return Object.values(previousChunk)

		return Object.values(previousChunk).filter(
			({ statusInfo: { role } }: IUser) => role !== Roles.EMPLOYEE
		)
	}

	const employeesList = useMemo(onListTypeChanged, [onListTypeChanged])

	const currentEmployees = searchResults.searchValue
		? searchResults.results
		: employeesList.length
		? employeesList
		: []

	return { currentEmployees, employeesChuncks }
}

export default useTablePagination
