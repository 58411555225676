import { FC, useEffect } from 'react'
import { Modal, Box } from '@material-ui/core'
import { DragDropContext } from 'react-beautiful-dnd'

import useStyles from './styled'

import close from 'assets/closeIcon.svg'
import DrawList from './DrawList'
import IUser from 'interface/user/user-interface'

import useDrag from './useDrag'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'

type MmbersProps = {
    isOpen: boolean
    updateMembersList: (list: string[]) => void
    onClose: () => void
    members: IUser[]
    participants: IUser[]
}
const MembersList: FC<MmbersProps> = ({ isOpen, onClose, members, participants, updateMembersList }) => {
    const classes = useStyles()
    const {
        handleDragEnd,
        membersList,
        participantsList,
        error,
        setError
    } = useDrag({ members, participants, updateMembersList })

    useEffect(() => {
        if (error) {
            setTimeout(() => (setError('')), 1200)
        }
    }, [error])

    const closeIcon = <div><img
        src={close}
        className={classes.closeBtn}
        onClick={onClose} /></div>
    return (
        <>
            <Modal
                open={isOpen}
                onClose={onClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'>

                <Box className={classes.membersModal} sx={{ ...style }}>

                    {closeIcon}
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <DrawList members={membersList} participants={participantsList} />
                    </DragDropContext>

                </Box>

            </Modal>

            <ConfirmModalSB
                isOpen={!!error}
                text={error}
            />
        </>
    )
}
export default MembersList
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '1px solid transparent',
    borderRadius: '5px',
    height: '573px',
    maxHeight: '600px',
    overflow: 'scroll',
    boxShadow: 24,
    p: 4,
    overflowX: 'hidden'
}