import { useEffect, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { AppDirectionContext } from 'context/languages-context'

import useStyles from './styled'

import { userSelector } from 'redux/user/user-selector'
import { get_company_exceptions } from 'redux/company/company-actions'
import { exceptionsSelector } from 'redux/company/company.selector'

import { Row } from './Row'

import { ManagerException } from 'interface/exceptions/exceptions-interface'

const CompanyManagersExceptions = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { isRtlDirection } = useContext(AppDirectionContext)

    const classes = useStyles({ isRtlDirection })

    const { company_id } = useSelector(userSelector)
    const exceptions = useSelector(exceptionsSelector)

    const headers = ['manager_name', 'inactive_employee_name', 'total_exceptions']

    useEffect(() => {
        if (exceptions.length) return
        dispatch(get_company_exceptions(company_id))
    }, [])
    const managerWhoInException = useMemo(() => (
        exceptions.filter(exc => exc.employees.length)
    ), [exceptions.length])
    return (
        <div>
            <div className={classes.container}>
                <div className={classes.tableWrapper}>
                    <div className={classes.tableHeader}>
                        {headers.map(h => (
                            <div>{t(h)}</div>
                        ))}
                    </div>
                    {managerWhoInException.map((manager: ManagerException) => (
                        <Row manager={manager} />
                    ))}
                </div>
            </div>
        </div>
    )
}
export default CompanyManagersExceptions