import BlockageIcon from 'assets/blockage.svg'
import PraiseIcon from 'assets/praise.svg'
import feedbackIcon from 'assets/feedback.svg'

export enum Text {
	FEEDBACKS = 'feedback',
	PRAISES = 'praise',
	BLOCKAGES = 'blockage',
}

export const getIconByName = (name: Text) => {
	const { BLOCKAGES, FEEDBACKS, PRAISES } = Text
	const nameMapIcon = {
		[BLOCKAGES]: BlockageIcon,
		[FEEDBACKS]: feedbackIcon,
		[PRAISES]: PraiseIcon,
	}
	return nameMapIcon[name]
}
