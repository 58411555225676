import { URLS } from './api-urls'
import IUser, { IUserGoalState } from 'interface/user/user-interface'
import { POST, GET, DELETE, PATCH, PUT } from './api-requests'
import { Response } from 'interface/api/response-interface'
import { EmployeePermissionToUpate } from 'components/ActivityTable/DisplayPermissionIndicator'

export const getEmployees = async (comapnyId: string): Promise<Response> => {
	const { data, status } = await GET(`${URLS.EMPLOYEE}/${comapnyId}`)
	return { data, status }
}
// { employee_id: string; goal_id: string; action: Item }
export const createGoalAction = async (payload: any): Promise<Response> => {
	const { data, status } = await PATCH(`${URLS.GOALS}/action`, {
		...payload,
	})
	console.log('CREATE GOAL ACTION: ');
	console.log({ data, status });

	return { data, status }
}
export const editGoalAction = async (payload: any): Promise<Response> => {
	const { data, status } = await PATCH(`${URLS.GOALS}/edit/action`, {
		...payload,
	})
	console.log('editGoalAction ACTION: ');
	console.log({ data, status });
	return { data, status }
}
export const deleteGoalAction = async (payload: any): Promise<Response> => {
	const { data, status } = await PATCH(`${URLS.GOALS}/delete/action`, {
		...payload,
	})
	console.log('deleteGoalAction ACTION: ');
	console.log({ data, status });
	return { data, status }
}

// export const getEmployeeByEmail = async (
// 	companyName: string,
// 	email: string
// ): Promise<Response> => {
// 	// check where we use this one cause we change from employee
// 	const { data, status } = await GET(
// 		`${URLS.EMPLOYEE}/?email=${email}&companyName=${companyName}`
// 	)

// 	return { data, status }
// }

export const deleteEmployees = async (ids: string[]): Promise<Response> => {
	const { data, status } = await DELETE(`${URLS.EMPLOYEE}/${ids}`)
	return { data, status }
}
export const restoreEmployee = async (employee: IUser): Promise<Response> => {
	const { data, status } = await POST(`${URLS.EMPLOYEE}/restore`, { employee })
	return { data, status }
}
export const deleteAllCompanyEmployees = async (
	company_id: string
): Promise<Response> => {
	const { data, status } = await DELETE(`${URLS.EMPLOYEE}/all/${company_id}`)
	return { data, status }
}
export const createEmployees = async (
	employees: IUser[]
): Promise<Response> => {
	const { data, status }: any = await POST(`${URLS.EMPLOYEE}`, {
		employees,
	})
	return { data, status }
}

export const updateEmployee = async (employee: IUser): Promise<Response> => {
	const { data, status }: any = await PUT(`${URLS.EMPLOYEE}`, {
		...employee,
	})
	return { data, status }
}

export const updateEmployeePermissions = async (
	employee: EmployeePermissionToUpate
): Promise<Response> => {
	const { isAddingPermission, ...rest } = employee
	const { data, status }: any = await PUT(
		`${URLS.EMPLOYEE}/permissions?isAddingPermission=${isAddingPermission}`,
		{
			...rest,
		}
	)
	return { data, status }
}

export const defineEmployeeManager = async (
	company_id: string,
	email: string,
	employees: string[],
	manager_id: string
): Promise<Response> => {
	const { data, status }: any = await PATCH(`${URLS.EMPLOYEE}`, {
		employees,
		email,
		company_id,
		manager_id,
	})
	return { data, status }
}

export const setEmployeeGoals = async (
	employee_id: string,
	goals: IUserGoalState[]
): Promise<Response> => {
	const { data, status }: any = await PATCH(`${URLS.GOALS}`, {
		employee_id,
		goals,
	})
	return { data, status }
}
