import { useContext } from 'react'
import { AvatarGroup, Tooltip } from '@mui/material'

import useStyles from './styled'

import { AppDirectionContext } from 'context/languages-context'

import UserAvatar from 'components/common/Avatar'

import IUser from 'interface/user/user-interface'

const MAX_MEMBERS_AVATAR = 3
type RecordMembersProps = {
    openMembersAndParticipants: () => void
    membersObjects: IUser[]
    matchPointColor?: string
    creator: IUser
}
const ItemMembers = (props: RecordMembersProps) => {
    const { openMembersAndParticipants, creator, membersObjects, matchPointColor } = props
    const { isRtlDirection } = useContext(AppDirectionContext)
    const classes = useStyles({ isRtlDirection, matchPointColor })

    return (
        <div className={classes.ownerImage} onClick={openMembersAndParticipants}>

            {membersObjects?.length
                ? <AvatarGroup
                    max={MAX_MEMBERS_AVATAR}
                    spacing='small'
                    classes={{ root: classes.avatarGroup }}
                >
                    {membersObjects?.map((member: IUser, index: number) => (
                        <Tooltip title={member?.personalInfo?.firstName + ' ' + member?.personalInfo?.lastName} key={index}>
                            <span>
                                <UserAvatar userInfo={member} className={classes.avatar} />
                            </span>
                        </Tooltip>
                    ))}
                </AvatarGroup>

                : <span>
                    <UserAvatar userInfo={creator} className={classes.avatar} />
                </span>
            }
        </div>
    )
}
export default ItemMembers