import makeStyles from '@material-ui/core/styles/makeStyles'
import { Theme } from '@material-ui/core'
import { IThemeOptions } from 'theme'

export const useStyles = makeStyles<
	Theme & IThemeOptions,
	{ canSkipToMatchpointChoice: boolean }
>(({ breakpoints, palette }) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		[breakpoints.down('sm')]: {
			maxHeight: '100%',
			overflow: 'hidden',
			height: '100%',
		},
	},
	list: {
		width: '100%',
		//flexGrow: 2, //?
		[breakpoints.down('sm')]: {
			overflow: 'auto',
		},
	},
	skipButton: {
		textDecoration: 'underline',
		pointerEvents: ({ canSkipToMatchpointChoice }) =>
			canSkipToMatchpointChoice ? 'unset' : 'none',
		color: ({ canSkipToMatchpointChoice }) =>
			!canSkipToMatchpointChoice ? 'grey' : palette.debrief.main,
		[breakpoints.down('sm')]: {
			marginTop: 'auto !important',
		},
	},
	button: {
		cursor: 'pointer',
		color: palette.debrief.main,
		padding: '3%',
		margin: '1%',
		width: 'fit-content',
		alignSelf: 'center',
	},
}))
