import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
//this componnent should be re invented and configure by the figma design  - (primary , hover , disabeld and such...)
type Props = {
	onClick?: () => void
	isInverted?: boolean
	bgColor?: string
	padding?: string
	fontFamily?: string
	borderRadius?: string
	type?: 'button' | 'reset' | 'submit' | undefined
	className?: string
} & StylesProps

type StylesProps = {
	fontSize?: number
	isInverted?: boolean
	width?: number
	height?: number
	bgColor?: string
	padding?: string
	fontFamily?: string
	borderRadius?: string
}

const useStyles = makeStyles({
	button: {
		padding: ({ padding }) => padding && padding,
		cursor: ({ isInverted }) => (isInverted ? 'not-allowed' : 'pointer'),
		fontFamily: ({ fontFamily }) => (fontFamily ? fontFamily : 'Sora,sans-serif'),
		fontSize: (props: StylesProps) => (props?.fontSize ? props.fontSize : 15),
		fontWeight: 300,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'white',
		height: (props: StylesProps) => (props?.height ? props.height : 40),
		width: (props: StylesProps) => (props?.width ? props.width : '100%'),
		border: 'none',
		borderRadius: (props: StylesProps) =>
			props?.borderRadius ? props.borderRadius : 10,
		backgroundColor: ({ isInverted, bgColor }: StylesProps) =>
			isInverted ? '#b6b9bb' : bgColor ? bgColor : '#0071c2',
		// boxShadow: ({ isInverted }: StylesProps) =>
		// 	isInverted ? 'none' : '10px 10px 35px 0 rgba(0, 113, 194, 0.21)', // by amit choice no inverted buttons on the app.

		'@media (max-width:480px)': {
			width: '100% !important',
		},
	},
})

const Button: React.FC<Props> = ({
	children,
	isInverted,
	width,
	height,
	onClick: hanldleClick,
	fontSize,
	bgColor,
	padding,
	fontFamily,
	borderRadius,
	type,
	className,
}) => {
	const classNames = useStyles({
		isInverted,
		width,
		height,
		fontSize,
		bgColor,
		padding,
		fontFamily,
		borderRadius,
	})
	return (
		<button
			disabled={isInverted}
			type={type ? type : 'button'}
			tabIndex={0}
			onClick={hanldleClick}
			className={
				className ? ` ${classNames.button} ${className}` : classNames.button
			}
		>
			{children}
		</button>
	)
}

export default Button
