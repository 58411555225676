import { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getBotAnswers } from 'redux/bot/bot-selector'
import { userSelector } from 'redux/user/user-selector'
import {
	draftsPraisesSelector,
	searchPraisesSelector,
} from '../../redux/praise/praise-selector'
import {
	receivedPraisesSelector,
	sentPraisesSelector,
} from 'redux/praise/praise-selector'

import {
	OutputWrapperProps,
	PraiseOutput,
	PraiseWrapperType,
} from '../../interface/bot/index'

import { OutputType } from 'screens/BotOutput'
import { UserAnswerType } from 'redux/bot/bot-reducer'
import IUser from 'interface/user/user-interface'

import { answersOuputMapped, buildReplacment } from 'utils/outputs/helpers'
import { usePraiseOutputData } from './usePraiseOutputData'
import { AppDirectionContext } from 'context/languages-context'
import { useTranslation } from 'react-i18next'
import { get_praises } from 'redux/praise/praise-actions'
import { getItem } from 'utils/localStorage'

export const PraiseWrapper = (props: PraiseWrapperType) => {
	const { outputId, screenType, render } = props

	const currentUserInfo = useSelector(userSelector)
	const receivedPraises = useSelector(receivedPraisesSelector)
	const sentPraises = useSelector(sentPraisesSelector)
	const searchedPraisesResult = useSelector(searchPraisesSelector)
	const draftsPraises: any = useSelector(draftsPraisesSelector)
	const botAnswers: UserAnswerType = useSelector(getBotAnswers)
	console.log({ screenType })
	const isPraiseReciever = screenType === OutputType.PraiseReciever
	const isViewingPraiseScreen = screenType === OutputType.PraiseViewing
	const isPraiseDraftBeforeSend = screenType == OutputType.PraiseDraft

	const dispatch = useDispatch()

	useEffect(() => {
		const fetchPraises =
			!receivedPraises.length &&
			!sentPraises.length &&
			!draftsPraises.length &&
			currentUserInfo._id

		if (fetchPraises) {
			dispatch(
				get_praises({
					company_id: currentUserInfo?.company_id!,
					user_id: currentUserInfo?._id,
				})
			)
		}
	}, [currentUserInfo])

	const receivedPraise = useMemo(() => {
		console.log({ receivedPraises, searchedPraisesResult })
		const praise = findPraiseOutputById(searchedPraisesResult, outputId)
		if (!praise) return findPraiseOutputById(receivedPraises, outputId)
		return praise
	}, [receivedPraises, searchedPraisesResult, outputId])

	const sentPraise = useMemo(() => {
		const praise = findPraiseOutputById(searchedPraisesResult, outputId)
		if (!praise) return findPraiseOutputById(sentPraises, outputId)
		return praise
	}, [sentPraises, searchedPraisesResult, outputId])

	const draftedPraise = useMemo(
		() => findPraiseOutputById<IUser>(draftsPraises, outputId),
		[draftsPraises, outputId]
	)

	const { isRtlDirection } = useContext(AppDirectionContext)
	const { t } = useTranslation()
	const connectivityWord = t('and')

	const outputBeforeMap = useMemo(() => {
		console.log({ receivedPraise, isPraiseReciever })
		if (isPraiseReciever) {
			return receivedPraise
		}
		if (isViewingPraiseScreen) {
			return sentPraise
		}
		if (isPraiseDraftBeforeSend) {
			return draftedPraise
		}

		const hasAnswers = botAnswers.answers.length
		const outputs = hasAnswers
			? botAnswers.answers
			: getItem('output-data').answers
		const mappedOutputFromBot = answersOuputMapped(outputs, currentUserInfo)

		return mappedOutputFromBot as PraiseOutput<IUser>
	}, [
		screenType,
		outputId,
		receivedPraise,
		sentPraise,
		draftedPraise,
		botAnswers.answers,
		currentUserInfo,
	])
	console.log({ outputBeforeMap })
	const replacements = buildReplacment(
		{
			...outputBeforeMap,
			receiver: outputBeforeMap?.receiver,
			sender: outputBeforeMap?.sender,
		},
		isPraiseReciever,
		isRtlDirection,
		connectivityWord
	)

	const buildReplacements = useBuildReplacements(
		outputBeforeMap,
		isPraiseReciever,
		isRtlDirection,
		connectivityWord
	)

	const usePraiseOutputDataPayload = {
		currentUserInfo,
		isReciever: isPraiseReciever,
		replacements,
		outputBeforeMap,
	}

	const outputData = usePraiseOutputData(usePraiseOutputDataPayload)

	const { CONTEXT, CONTRIBUTION, FREE_TEXT_LABEL, IMPACTS } = outputData
	const { time = '', impactsExpansion } = outputBeforeMap ?? {}

	const componentData: OutputWrapperProps = {
		DATE: time ? new Date(outputBeforeMap?.time).toDateString() : time,
		CONTEXT,
		IMPACTS,
		CONTRIBUTION,
		FREE_TEXT_LABEL,
		FREE_TEXT: outputBeforeMap?.text,
		VALUES: outputBeforeMap?.values,
		receiver: outputBeforeMap?.receiver,
		sender: outputBeforeMap?.sender,
		impactsExpansion: impactsExpansion as [],
		contributionExpansion: outputBeforeMap?.contributionExpansion,
		readed: outputBeforeMap?.readed ?? false,
		replay: outputBeforeMap?.replay ?? '',
		closeAction: () => {},
	}

	return render({ ...componentData })
}

const useBuildReplacements = (
	payload: any,
	isPraiseReciever: boolean,
	isRtlDirection: boolean,
	connectivityWord?: string
) => {
	const [replacements, setReplacements] = useState<Record<string, any>>({})

	useEffect(() => {
		const replacements = buildReplacment(
			{
				...payload,
				receiver: payload?.receiver,
				sender: payload?.sender,
			},
			isPraiseReciever,
			isRtlDirection,
			connectivityWord
		)

		setReplacements(replacements)
	}, [payload])
	return replacements
}
export const findPraiseOutputById = <T>(
	output: PraiseOutput<T>[],
	outputId: string
) => {
	return output.filter(output => output._id === outputId)[0]
}
/// the useEffect aboe needs to be refactoe for a hook later (to fit both praise/feedbacks)
