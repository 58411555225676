import { FC } from 'react'
import urgencyAsapIcon from 'assets/urgencyAsapIcon.svg'
import urgencyLaterIcon from 'assets/urgencyLaterIcon.svg'
import urgencySoonIcon from 'assets/urgencySoonIcon.svg'

import { UrgencyLevel } from 'enums/urgency/urgency.enum'
import { useTranslation } from 'react-i18next'

type UrgenyLevelPropType = {
	handleClick: (urgencyLevel: UrgencyLevel) => void
}
export const UrgenyLevel: FC<UrgenyLevelPropType> = ({ handleClick }) => {
	const { t } = useTranslation()
	return (
		<div
			id='urgenyLevelButton'
			style={{
				display: 'flex',
				justifyContent: 'space-evenly',
				marginTop: '2rem',
			}}
		>
			<IconWrapper handleClick={() => handleClick(UrgencyLevel.later)}>
				<img src={urgencyLaterIcon} alt='' />
				<span>{t('later_urgency')}</span>
			</IconWrapper>
			<IconWrapper handleClick={() => handleClick(UrgencyLevel.soon)}>
				<img src={urgencySoonIcon} alt='' />
				<span> {t('soon_urgency')}</span>
			</IconWrapper>
			<IconWrapper handleClick={() => handleClick(UrgencyLevel.asap)}>
				<img src={urgencyAsapIcon} alt='' />
				<span>{t('asap_urgency')}</span>
			</IconWrapper>
		</div>
	)
}
const IconWrapper: FC<{ handleClick: () => void }> = ({
	children,
	handleClick,
}) => {
	return (
		<div
			onClick={handleClick}
			style={{
				width: '68px',
				height: '20%',
				cursor: 'pointer',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{children}
		</div>
	)
}
