import { createAsyncThunk } from '@reduxjs/toolkit'
import {
	updateCompany,
	getCompanies,
	getCompanyPraises,
	deleteCompany,
	sendEmails,
	createCompany,
	getCompanyValues,
	setCompanyValues,
	getCompanyLicense,
	setSmsNotifications,
	sendEmailToRole,
	getCompanySettings,
	getCompanyDepartments,
	getDepartmentSubDepartments,
	updateCompanyActivation,
	fetchEmployeesActivities,
	getCompanyActivityForSuperAdmin,
	getSummariesActivities,
	getCompanyActivityForSuperAdminV2,
	getCompanyChart,
	getCompanyExceptions,
	toggleTaskShow,
	getUserBaseInsightV2,
	loadMoreTasks,
	getNewExceptions,
} from 'api/company'
import { attachLessonToFile, createNewFile, createNewFolder, deleteFolder, editFile, editFolder, getFile, getFolder, deleteFile, deleteLesson, createTitle, createNewLesson, updateLessonDescription, grantManagersPermissions } from 'api/lessons'
import { Req } from 'enums/req/req.enum'
import { Roles } from 'enums/user/user-roles'
import { Company } from 'interface/company/company-interface'
import { IStoreRootState } from 'interface/redux'
import {
	FetchEmployeesActivities,
} from '../../interface/api/get-payload.types'
import { TaskSection } from 'enums/task/tasks.enum'
import { requestErrorActions } from 'redux/requestError/request-error-reducer'
import { Folder, ILessonsBase, File, IInsertLesson, IAttacheLesson, DeleteLessonInFileParams, UpdateLessonType } from 'interface/lessons-management/LessonsBase.interface'

export type UpdateFolderDto = ILessonsBase<Folder | File> & { company_id: string }

export type UpdateFolderManagers = {
	company_id: string
	_id: string,
	permissions: string[],
}
export type BaseInsightType = {
	employee_id: string
	company_id: string
	task_id?: string
	pageNumber?: number
	type?: TaskSection
}

export const get_company_praises = createAsyncThunk(
	'company/fetchCompanyPraises',
	async (payload: Record<string, any>, { rejectWithValue }) => {
		try {
			const { status, data } = await getCompanyPraises(payload)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)

export const get_employees_activities = createAsyncThunk(
	'company/fetchEmployeesActivities',
	async (payload: FetchEmployeesActivities, { rejectWithValue }) => {
		try {
			const { data, status } = await fetchEmployeesActivities(payload)
			console.log(data)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const get_companies = createAsyncThunk(
	'company/fetchCompanies',
	async (_, { rejectWithValue }) => {
		try {
			const { status, data } = await getCompanies()
			console.log({ data })
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)
export const update_company_activation = createAsyncThunk(
	'company/updateCompanyActivation',
	async (payload: Record<string, any>, { rejectWithValue, dispatch }) => {
		try {
			const { status, data, code } = await updateCompanyActivation(payload)

			if (status === Req.failed) {
				dispatch(
					requestErrorActions.setError({
						message: 'company_updated',
						statusCode: code,
					})
				)
			}

			return payload
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)
export const delete_company = createAsyncThunk(
	'company/deleteCompany',
	async (_id: string, { rejectWithValue }) => {
		try {
			const { status, data } = await deleteCompany(_id)
			if (status === Req.failed) throw new Error(data)
			return _id
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)
//we dont use it right now , but maybe good fot the future if we need to send emails by array.
export const send_emails = createAsyncThunk(
	'company/sendEmails',
	async (emailArray: Array<string>, { rejectWithValue }) => {
		try {
			const { status, data } = await sendEmails(emailArray)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)

export const create_company = createAsyncThunk(
	'company/createCompany',
	async (company: Company, { rejectWithValue, dispatch }) => {
		try {
			const { status, data } = await createCompany(company)
			console.log({ data })
			if (status === Req.failed) throw new Error(data?.message)
			return data
		} catch (err: any) {
			console.log({ createCompErr: err })
			dispatch(
				requestErrorActions.setError({
					message: (`Error: ${err.response.data.message}` as any) ?? err,
					statusCode: err.statusCode,
				})
			)
			// return rejectWithValue({ message: err.message })
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)

export const update_company = createAsyncThunk(
	'company/updateCompany',
	async (company: Company, { rejectWithValue }) => {
		try {
			const { status, data } = await updateCompany(company)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)

export const get_company_values = createAsyncThunk(
	'company/getCompanyValues',
	async (company_id: string, { rejectWithValue }) => {
		try {
			const { status, data } = await getCompanyValues(company_id)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)
export const get_company_departments = createAsyncThunk(
	'company/getCompanyDepartments',
	async (company_id: string, { rejectWithValue }) => {
		try {
			const { status, data } = await getCompanyDepartments(company_id)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)
export const get_department_sub_departments = createAsyncThunk(
	'company/getDepartmentSubDepartments',
	async (
		payload: { company_id: string; department: string },
		{ rejectWithValue }
	) => {
		try {
			const { status, data } = await getDepartmentSubDepartments(payload)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)
export const get_company_settings = createAsyncThunk(
	'company/getCompanySettings',
	async (company_id: string, { rejectWithValue }) => {
		try {
			const { status, data } = await getCompanySettings(company_id)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)

export const set_company_values = createAsyncThunk(
	'company/setCompanyValues',
	async (
		payload: { company_id: string; values: string[] },
		{ rejectWithValue }
	) => {
		try {
			const { status, data } = await setCompanyValues(
				payload.company_id,
				payload.values
			)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)

export const get_company_license = createAsyncThunk(
	'company/getCompanyLicense',
	async (payload: { company_id: string }, { rejectWithValue }) => {
		try {
			const { status, data } = await getCompanyLicense(payload.company_id)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)
export const set_sms_notifications = createAsyncThunk(
	'company/setSmsNotifications',
	async (
		payload: { company_id: string; isSms: boolean },
		{ rejectWithValue }
	) => {
		try {
			const { status, data } = await setSmsNotifications(
				payload.company_id,
				payload.isSms
			)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)
export const send_email_to_role = createAsyncThunk(
	'company/sendEmailToRole',
	async (payload: { company_id: string; role: Roles }, { rejectWithValue }) => {
		try {
			const { status, data } = await sendEmailToRole(
				payload.company_id,
				payload.role
			)
			if (status === Req.failed) throw new Error(data)
			return payload.role
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { company } = getState() as IStoreRootState
			return company.loading === false
		},
	}
)

export const get_company_activity_for_super_admin = createAsyncThunk(
	'company/superadminCompanyActivity',
	async (company_id: string, { rejectWithValue }) => {
		try {
			const { status, data } = await getCompanyActivityForSuperAdmin(company_id)
			if (status == Req.failed) throw new Error(data)
			console.log(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const get_summaries_activities = createAsyncThunk(
	'company/superadminSummaries',
	async (_, { rejectWithValue }) => {
		try {
			const { data, status } = await getSummariesActivities()
			if (status == Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const get_company_activity_for_super_admin_v2 = createAsyncThunk(
	'company/superadminCompanyActivity',
	async (company_id: string, { rejectWithValue }) => {
		try {
			const { status, data } = await getCompanyActivityForSuperAdminV2(company_id)
			if (status == Req.failed) throw new Error(data)
			console.log(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const get_company_chart = createAsyncThunk(
	'company/superadminCompanyChart',
	async (company_id: string, { rejectWithValue }) => {
		try {
			const { data, status } = await getCompanyChart(company_id)
			console.log(data)
			if (status == Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const get_company_exceptions = createAsyncThunk(
	'company/companyExceptions',
	async (company_id: string, { rejectWithValue }) => {
		try {
			const { data, status } = await getCompanyExceptions(company_id)
			if (status == Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const get_new_exceptions = createAsyncThunk(
	'company/newCompanyExceptions',
	async (
		payload: { company_id: string; hr_id: string },
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await getNewExceptions(
				payload.company_id,
				payload.hr_id
			)
			if (status == Req.failed) throw new Error(data)

			return data ? true : false
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const get_user_base_insight = createAsyncThunk(
	'user/userBaseInsight',
	async ({ company_id, employee_id }: BaseInsightType, { rejectWithValue }) => {
		try {
			const { data, status } = await getUserBaseInsightV2(company_id, employee_id)
			if (status == Req.failed) throw new Error(data)
			return { data, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const load_more_insights = createAsyncThunk(
	'user/lazeLoadeInsights',
	async (
		{ company_id, employee_id, pageNumber, type }: BaseInsightType,
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await loadMoreTasks(
				company_id,
				employee_id,
				pageNumber!,
				type!
			)
			if (status == Req.failed) throw new Error(data)
			return { data, status, type }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const toggle_task_should_show = createAsyncThunk(
	'user/userBaseInsightToggleTask',
	async (
		{ company_id, task_id, employee_id }: BaseInsightType,
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await toggleTaskShow(
				company_id,
				task_id!,
				employee_id
			)
			console.log({ data })
			if (status == Req.failed) throw new Error(data)
			return { data, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)


//company-folders-lessons
export const get_folder = createAsyncThunk(
	'lessons/currentFolder',
	async (payload: { company_id: string, folder_id: string | null }, { rejectWithValue }) => {
		try {
			const { data, status } = await getFolder(payload)
			if (status == Req.failed) throw new Error(data)

			return { data, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const get_file = createAsyncThunk(
	'lessons/getFile',
	async (payload: { company_id: string, file_id: string | null }, { rejectWithValue }) => {
		try {
			const { data, status } = await getFile(payload)
			if (status == Req.failed) throw new Error(data)

			return { data, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const create_lessons_folder = createAsyncThunk(
	'lessons/createNewFolder',
	async (folder: Partial<ILessonsBase>, { rejectWithValue }) => {
		try {
			const { data, status } = await createNewFolder(folder)
			if (status == Req.failed) throw new Error(data)

			return { data, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const create_lessons_file = createAsyncThunk(
	'lessons/createNewFile',
	async (file: Partial<ILessonsBase>, { rejectWithValue }) => {
		try {
			const { data, status } = await createNewFile(file)
			if (status == Req.failed) throw new Error(data)

			return { data, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const edit_the_folder = createAsyncThunk(
	'lessons/editFolder',
	async (folder: UpdateFolderDto, { rejectWithValue }) => {
		try {
			const updatedFolder = {
				_id: folder._id,
				name: folder.name,
				permissions: folder.permissions,
				company_id: folder.company_id
			}
			const { data, status } = await editFolder(updatedFolder)

			if (status === Req.failed) throw new Error(data)

			return { data: folder, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const edit_the_file = createAsyncThunk(
	'lessons/editFile',
	async (file: UpdateFolderDto, { rejectWithValue }) => {
		try {
			const updatedFile = {
				_id: file._id,
				name: file.name,
				permissions: file.permissions,
				company_id: file.company_id
			}
			const { data, status } = await editFile(updatedFile)

			if (status === Req.failed) throw new Error(data)

			return { data: file, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const delete_folder = createAsyncThunk(
	'lessons/deleteFolder',
	async (payload: { _id: string, company_id: string }, { rejectWithValue }) => {
		try {
			const { data, status } = await deleteFolder(payload)

			if (status === Req.failed) throw new Error(data)

			return { data: payload._id, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const delete_file = createAsyncThunk(
	'lessons/deleteFile',
	async (payload: { _id: string, company_id: string }, { rejectWithValue }) => {
		try {
			const { data, status } = await deleteFile(payload)

			if (status === Req.failed) throw new Error(data)

			return { data: payload._id, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const delete_file_lesson = createAsyncThunk(
	'lessons/deleteFileLesson',
	async (payload: DeleteLessonInFileParams, { rejectWithValue }) => {
		try {
			const { data, status } = await deleteLesson(payload)

			if (status === Req.failed) throw new Error(data)

			return { data }

		} catch (err) {
			return rejectWithValue(err)
		}
	},
)

export const paste_lesson = createAsyncThunk(
	'lessons/pasteLesson',
	async (lesson: IAttacheLesson, { rejectWithValue }) => {
		try {
			const { data, status } = await attachLessonToFile(lesson)

			if (status === Req.failed) throw new Error(data)

			return { data, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const update_lesson_description = createAsyncThunk(
	'lessons/updateLessonDescription',
	async (lesson: IInsertLesson, { rejectWithValue }) => {
		try {
			const { data, status } = await updateLessonDescription(lesson)

			if (status === Req.failed) throw new Error(data)

			return { data, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const create_new_lesson = createAsyncThunk(
	'lessons/createNewLesson',
	async (lesson: IInsertLesson, { rejectWithValue }) => {
		try {
			const { data, status } = await createNewLesson(lesson)

			if (status === Req.failed) throw new Error(data)

			return { data, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const create_title = createAsyncThunk(
	'lessons/createTitle',
	async (lesson: UpdateLessonType, { rejectWithValue }) => {
		try {
			const { data, status } = await createTitle(lesson)

			if (status === Req.failed) throw new Error(data)
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const defined_folder_managers = createAsyncThunk(
	'lessons/grantPermissions',
	async (folder: UpdateFolderManagers, { rejectWithValue }) => {
		try {
			const updatedFolder = {
				_id: folder._id,
				permissions: folder.permissions,
				company_id: folder.company_id
			}
			const { data, status } = await grantManagersPermissions(updatedFolder)

			if (status === Req.failed) throw new Error(data)
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)


