import { FC, useEffect, useRef } from 'react'
import { getUserGoals } from '../../../redux/user/user-selector'
import { useSelector } from 'react-redux'
import ActionsButton, { UserAnswer } from 'components/Bot/ActionButtons'
import { AnswersGroupProp } from '../../../interface/bot/index'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useStyles } from './useStyles'
import { UseClickActions } from '../useHooks/index'
import ScrollableContent from 'components/common/ScrollableContent/ScrollableContent'

type GolsProps = AnswersGroupProp & {
	setIsAbleToSkipFurtherImpact: React.Dispatch<React.SetStateAction<boolean>>
}
const GoalsChoice: FC<GolsProps> = ({
	setIsAbleToSkipFurtherImpact,
	setQuestion,
	nextQuestion,
	previousQuestion,
	answers,
	type,
	isPraise,
}) => {
	const userGoals = useSelector(getUserGoals).filter(goal => !goal.archive)

	const classes = useStyles()
	const { addAnswer, fetchQuestion } = UseClickActions(isPraise!)

	useEffect(() => {
		!userGoals.length &&
			fetchQuestion(isPraise!, answers[0]?.nextQuestion ?? nextQuestion)
	}, [userGoals])

	const handleClick = (userAnswer: UserAnswer) => {
		setIsAbleToSkipFurtherImpact(true)
		addAnswer(userAnswer)
	}
	const content = (
		<Box className={classes.wrapper}>
			<Grid
				container
				rowSpacing={2}
				columnSpacing={{ xs: 2, sm: 2, md: 3.5 }}
				id='goalAnswerCard'
			>
				{userGoals?.map(({ description: title, _id }) => {
					const answer: UserAnswer = {
						id: _id,
						answer: title,
						previousQuestion,
						nextQuestion: nextQuestion!,
						type,
					}

					return (
						<Grid item xs={12} md={6} key={_id}>
							<div
								key={_id}
								className={classes.answerCard}
								onClick={() => handleClick(answer)}
							>
								{title}
							</div>
						</Grid>
					)
				})}

				{answers?.map(({ title, nextQuestion: _nextQuestion }, id) => {
					const styles = [classes.answerCard, classes.answerCardAlt]
						.filter(Boolean)
						.join(' ')

					return (
						<Grid item xs={12} md={6} key={id}>
							<div
								key={id}
								className={styles}
								onClick={() => {
									setIsAbleToSkipFurtherImpact(prev => false)
									setQuestion(_nextQuestion)
								}}
							>
								{title}
							</div>
						</Grid>
					)
				})}
			</Grid>
		</Box>
	)

	return (
		<>
			<ScrollableContent content={content} />
			<ActionsButton
				isPraise={isPraise}
				setQuestion={setQuestion}
				type={type}
				showPreviousButton
				previousQuestionId={previousQuestion}
			/>
		</>
	)
}

export default GoalsChoice
