import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { userSelector } from 'redux/user/user-selector'
import { get_user, reset_user_alerts } from 'redux/user/user-actions'
import { getItem } from 'utils/localStorage'

export const useRetreiveConnctedUser = () => {
	const currentUser = useSelector(userSelector)

	const dispatch = useDispatch()
	useEffect(() => {
		const user = getItem('user')
		if (!('_id' in currentUser) && user && '_id' in user) {
			const { _id, company_id } = user

			dispatch(get_user({ user_id: _id, company_id }))
		}
	}, [currentUser])

	useEffect(() => {
		if (!currentUser?._id) return

		dispatch(reset_user_alerts(currentUser._id))
	}, [currentUser])

	return currentUser
}
