import { FC, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import classes from './styles.module.css'

import { userSelector } from 'redux/user/user-selector'
import {
	companyTodosSelector,
	todosErrorSelector,
} from 'redux/todos/todos.selector'

import { RenderSection } from './RenderSection'
import UserAvatar from 'components/common/Avatar'
import ErrorMessage from 'utils/error/index'

import { TodosStatus } from 'enums/todos/todos.enum'
import IUser from '../../interface/user/user-interface'

import { buildPayload } from 'utils/buildPayload'
import { useManagerObject } from 'utils/getManager'
import { getItem, setItem } from 'utils/localStorage'
import useTodoReuqests from './useTodos'

type Props = {
	selectedEmployee: IUser
	isManagerViewing?: boolean
	shouldHideManagerSide?: boolean
}
export const Todos: FC<Props> = props => {
	const { selectedEmployee, isManagerViewing, shouldHideManagerSide } = props
	const user = useSelector(userSelector)
	const userTodos = useSelector(companyTodosSelector)

	const { fetchUserTodos, viewedTasks } = useTodoReuqests(
		selectedEmployee,
		isManagerViewing
	)
	const manager = useManagerObject(selectedEmployee)

	const { _id: todos_id, status, didManagerSee } = userTodos
	const data = { userTodos, user, isManagerViewing }

	const payload = useMemo(() => buildPayload(data), [userTodos])

	useEffect(() => {
		if (selectedEmployee._id) fetchUserTodos()
	}, [selectedEmployee._id])

	useEffect(() => {
		const selectedEmployee = getItem('selectedEmployee')
		if (selectedEmployee !== '') setItem('selectedEmployee', '')
	}, [])

	useEffect(() => {
		if (!todos_id) return
		return () => {
			if (
				didManagerSee &&
				!isManagerViewing &&
				status !== TodosStatus.beforeSending
			)
				viewedTasks('employee')
			if (isManagerViewing && status !== TodosStatus.beforeSending)
				viewedTasks('manager')
		}
	}, [todos_id])

	const sectionProps = {
		isManagerViewing: isManagerViewing!,
		selectedEmployee: selectedEmployee,
		payload: payload,
	}

	return (
		<>
			<div className={classes.actionsSections}>
				<RenderSection
					RenderHeader={SectionHeader(selectedEmployee)}
					{...sectionProps}
				/>
				{!shouldHideManagerSide && (
					<RenderSection
						RenderHeader={SectionHeader(manager)}
						managerSide
						{...sectionProps}
					/>
				)}
			</div>
		</>
	)
}

const SectionHeader = (participant: IUser = {} as IUser) => {
	const avatarStyles = `${classes.avatar} ${classes.small}`
	const fontSizeStyle = { fontSize: '90%' }
	const {
		personalInfo: { firstName = '', lastName = '' },
		statusInfo: { title: roleAtDepartment = '' },
	} = participant

	return (
		<div style={fontSizeStyle} className={classes.participant}>
			<UserAvatar className={avatarStyles} userInfo={participant} />
			<div className={classes.userInfo}>
				<div className={classes.fullName}>{firstName + ' ' + lastName}</div>
				<div className={classes.title}>{roleAtDepartment}</div>
			</div>
		</div>
	)
}
