import { AppDirectionContext } from "context/languages-context"
import { FC, useContext } from "react"

export const SpanWrapper: FC<{}> = ({ children }) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	return (
		<span
			style={{
				direction: isRtlDirection ? 'rtl' : 'ltr',
				fontWeight: 'normal',
				fontSize: '1.1rem',
			}}
		>
			{children}
		</span>
	)
}