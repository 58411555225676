import { FC, useState, ChangeEvent, ReactNode } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import useStyles from './styles'

interface TabPanelProps {
	children?: ReactNode
	index: any
	value: any
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => (
	<div
		role='tabpanel'
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	>
		{value === index && (
			<Box p={3}>
				<Typography component={'div'} >{children}</Typography>
			</Box>
		)}
	</div>
)

interface ITab {
	label: string
}
interface Props {
	title: string
	panels: JSX.Element[]
	tabs: ITab[]
	tabState?: number,
	onTabChange?: (tabNum: number) => void
	tabClass?: string
	className?: string
}

const CustomizedTabs: FC<Props> = ({
	onTabChange,
	title,
	panels,
	tabs,
	tabState,
	tabClass,
	className,
}) => {
	const classes = useStyles()
	const [value, setValue] = useState(tabState ?? 0)
	const { t } = useTranslation()

	const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
		onTabChange && onTabChange(newValue)
		setValue(newValue)
	}

	return (
		<div>
			<Typography className={classes.title}>{t(title)}</Typography>
			<div className={classes.container}>
				<AppBar
					position='static'
					color='transparent'
					classes={{ root: classes.appBar }}
				>
					<Tabs
						className={className ? className : ''}
						value={value}
						onChange={handleChange}
					>
						{tabs.map(tab => (
							<Tab
								disableFocusRipple
								disableRipple
								label={tab.label}
								classes={{
									root: tabClass ? `${tabClass} ${classes.tab}` : classes.tab,
								}}
							/>
						))}
					</Tabs>
				</AppBar>
				{panels.map((panel, index) => (
					<TabPanel
						key={index}
						value={value}
						index={index}
					>
						{panel}
					</TabPanel>
				))}
			</div>
		</div>
	)
}

export default CustomizedTabs


