import { FC } from 'react'
import { makeStyles } from '@material-ui/core'

import { TasksSection } from './BaseInsight'

const InsightMobile: FC<TasksSection> = props => {
	const { tasks = [] } = props
	const styles = useStyles()

	return (
		<div className={styles.wrapper}>
			<InsightHeader></InsightHeader>
			<div className={styles.insights}>
				{[...tasks, ...tasks, ...tasks]?.map(task => (
					<div>{task.description}</div>
				))}
			</div>
		</div>
	)
}

export default InsightMobile

export const InsightHeader: FC<{}> = ({ children }) => {
	const styles = useStyles()

	return <div className={styles.header}>{children}</div>
}

const useStyles = makeStyles(({ breakpoints }) => ({
	wrapper: {
		width: '100%',
		height: '30vh',
		backgroundColor: 'red',
		display: 'flex',
		flexDirection: 'column',
	},
	header: {
		position: 'relative',
		backgroundColor: 'rgb(52, 171, 83)',
		padding: '0.5% 2.8%',
		color: 'white',
		width: 'fit-content',
		display: 'flex',
		// position: "relative",
		fontSize: '13px',
		fontWeight: 300,
		borderRadius: '15px',
		justifyContent: 'center',
	},

	insights: {
		overflowY: 'auto',
		backgroundColor: 'black',
		color: 'white',
		flex: 1,
		height: '30vh',
	},
}))
