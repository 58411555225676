import { FC } from 'react'
import eyeShown from 'assets/eye-shown.svg'
import eyeHidden from 'assets/eye-hidden.svg'
import { Permissions } from '../../interface/user/user-interface'
import { PERMISSION_KEYS } from 'components/common/Header/Mobile/constants'
import { Roles } from '../../enums/user/user-roles'
import { useDispatch } from 'react-redux'
import { update_employee_permissions } from 'redux/employee/employee-actions'

type Props = { _id: string; permissions: Permissions; isManager: boolean }
export const DisplayPermissionIndicator: FC<Props> = props => {
	const { _id, isManager, permissions = [] } = props
	const dispatch = useDispatch()
	const hasPermissoion = permissions.includes(
		PERMISSION_KEYS.employeePerformance
	)
	/**
	 * handles 2 cases:
	 *  case 1: if has permission we would dispatch request to remove
	 *  case 2: if no permission we would dispatch request to grant
	 * @param _id
	 */
	const handleActivityPermission = (_id: string) => {
		if (!isManager) {
			const confirmed = window.confirm('you cant grant permission!')
			return
		}

		const payload: EmployeePermissionToUpate = {
			isAddingPermission: !hasPermissoion,
			_id,
			role: Roles.MANAGER,
			permission: PERMISSION_KEYS.employeePerformance,
		}
		dispatch(update_employee_permissions(payload))
	}

	const eyeIcon = hasPermissoion ? eyeShown : eyeHidden
	return (
		<img
			src={eyeIcon}
			alt='eyeIcon'
			onClick={() => handleActivityPermission(_id)}
		/>
	)
}

export type EmployeePermissionToUpate = {
	isAddingPermission: boolean
	_id: string
	role: Roles
	permission: PERMISSION_KEYS
}
