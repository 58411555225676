import { FC } from 'react'
import useStyles from '../useStyles'
import thumbupIcon from 'assets/thumpupIcon.svg'
import outputMailView from 'assets/outputMailView.svg'
import { Chip } from '../../Dashboard/MainContentInsights/LastInsightsCards'
import { useTranslation } from 'react-i18next'

type Payload = {
	sender: string
	receiver: string
}
interface HeaderPropsTypes {
	headerText?: string
	isViewScreen?: boolean
	isBeforeSned?: boolean
	isFeedbackReceiver?: boolean
	receiveFeedbackHeaderPayload?: Payload
}
type RegularHeaderProps = Omit<HeaderPropsTypes, 'isBeforeSned' | 'isPraise'>

export const Header: FC<HeaderPropsTypes> = ({
	isViewScreen,
	headerText,
	isFeedbackReceiver,
	receiveFeedbackHeaderPayload,
}) => {
	return isFeedbackReceiver ? (
		<FeedbackReceiverHeader {...receiveFeedbackHeaderPayload!} />
	) : (
		<RegularHeader {...{ headerText, isViewScreen }} />
	)
}

const RegularHeader: FC<RegularHeaderProps> = ({
	isViewScreen,
	headerText,
}) => {
	const { t } = useTranslation()
	const classes = useStyles({})
	return (
		<div className={classes.contentHeaderSection}>
			<img src={isViewScreen ? outputMailView : thumbupIcon} alt='thumbupIcon' />
			<div className={classes.summeryTitle}>
				{isViewScreen ? headerText : t('summary')}
			</div>
		</div>
	)
}

const FeedbackReceiverHeader: FC<Payload> = ({
	sender = 'Ellen',
	receiver = 'Susan',
}) => {
	const classes = useStyles({})
	const { t } = useTranslation()
	return (
		<div className={classes.receiverHeader}>
			<div className={classes.recipientsInfo}>
				<div>{t('hi_user', { user: receiver })} </div>
				<div>You’ve received feedback from {sender}.</div>
				<div style={{ width: '59vh' }}>
					We invite you to read the details here, and hope that both you and{' '}
					{sender.concat(' ')}
					will derive significant valuefrom the feedback.
				</div>
				<div style={{ display: 'flex' }}>
					<Chip chipName={t('Feedback')} />
				</div>
			</div>
			<div className={classes.urgencyLevelContainer}>
				<div>{sender} requested that you reply to this feedback</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-evenly',
						marginTop: '26px',
					}}
				>
					{Array.from(
						[
							{ title: 'When you have a moment' },
							{ title: 'Soon' },
							{ title: 'ASAP' },
						],
						({ title }) => {
							return (
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
									}}
								>
									<Ovals />
									<div
										style={{
											fontSize: '0.7rem',
											width: '5vw',
											textAlign: 'center',
											marginTop: '10%',
										}}
									>
										{title}
									</div>
								</div>
							)
						}
					)}
				</div>
			</div>
		</div>
	)
}

const Ovals = () => (
	<div
		style={{
			width: 44,
			height: 44,
			borderRadius: '50%',
			backgroundColor: 'white',
		}}
	></div>
)
