import { useContext } from 'react'
import { AppDirectionContext } from 'context/languages-context'

import useStyles from './styled'

import ItemInput from './ItemInput'

import { useTranslation } from 'react-i18next'
import useFocusedInput from './useFocusedInput'

type Props = {
    handleSaveInput: (description: string) => void
    description: string
    matchPointColor?: string
    _id: string
    allowEditAccess: boolean
    label: string
}

function ItemDescription(props: Props) {
    const { handleSaveInput, description, matchPointColor, _id, allowEditAccess, label } = props

    const { showInputField, setShowInputField } = useFocusedInput(_id)

    const { t } = useTranslation()
    const { isRtlDirection } = useContext(AppDirectionContext)

    const classes = useStyles({ isRtlDirection, matchPointColor })

    const handleClickAway = () => allowEditAccess && setShowInputField(prev => !prev)

    return (
        <>
            {showInputField
                ? <ItemInput
                    handleSaveInput={handleSaveInput}
                    matchPointColor={matchPointColor}
                    handleClickAway={handleClickAway}
                    description={description}
                />

                : <div className={classes.description} onDoubleClick={handleClickAway}>
                    <span>
                        <span>{t(label)}{': '}</span>
                        {description}
                    </span>
                </div>}
        </>
    )
}

export default ItemDescription