import { FC, useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import 'components/common/StatusInfoCard/style.scss'

import { userSelector } from 'redux/user/user-selector'
import {
	getUserBaseInsights,
	isCompanySelectorLoading,
	newExceptionsSelector,
} from 'redux/company/company.selector'
import { companyActions } from 'redux/company/company-reducer'
import { get_new_exceptions } from 'redux/company/company-actions'

import StatusInfoCard from 'components/common/StatusInfoCard'
import MainContentInsights from 'components/Dashboard/MainContentInsights'
import WallOfPraise from 'components/Dashboard/WallOfPraise'
import { ConcentrationTasksAtDashboard } from 'components/Dashboard/BaseInsight/index'

import { generateGreetings } from 'utils'
import { filter } from 'utils/filter'

import dashboardImg from 'assets/dashboardImage.png'
import Paths, { DashboardPaths } from 'enums/routes/path-names'
import { Roles } from 'enums/user/user-roles'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'

const MINUTE_MS = 3600000
const BOX_SHADOW = '2px 2px 5px rgba(0, 0, 0, 0.1)'

export enum Events {
	PRIVATE_EVENT = 'private-notification',
	CREAATE_PRIVATE_EVENT = 'create-private-notification',
	BRODCAST_EVENT = '',
}

const Main: FC = () => {
	const [greeting, setGreeting] = useState('')
	const [hasNewCompanyExceptions, setHasNewCompanyExceptions] =
		useState<boolean>(false)

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const user = useSelector(userSelector)
	const { lessons } = useSelector(getUserBaseInsights)
	const loading = useSelector(isCompanySelectorLoading)
	const newExceptions = useSelector(newExceptionsSelector)

	const theme = useTheme()
	const history = useHistory()
	const { firstName = '' } = user.personalInfo || {}

	useEffect(() => {
		if (!user) return
		if (user?.statusInfo?.role! === Roles.HR)
			dispatch(
				get_new_exceptions({ company_id: user.company_id, hr_id: user._id! })
			)
	}, [user])

	useEffect(() => {
		if (newExceptions) setHasNewCompanyExceptions(prev => true)
	}, [newExceptions])

	useEffect(() => {
		let message = generateGreetings(new Date().getHours())

		const interval = setInterval(() => {
			message = generateGreetings(new Date().getHours())
		}, MINUTE_MS)

		setGreeting(message)
		return () => clearInterval(interval)
	}, [])

	const handleChangeView = (path: string) => {
		if (path === 'יעדים') history.push(`${Paths.DASHBOARD}/goals`)
	}
	const loader = <div className='bar'></div>

	const lessonsWithShowPermission = useMemo(() => {
		return filter(lessons ?? [], ({ shouldShow }) =>
			shouldShow.includes(user._id)
		)
	}, [lessons.length, user._id])

	const randomIndex = Math.floor(
		Math.random() * lessonsWithShowPermission?.length!
	)
	const description = loading
		? loader
		: lessonsWithShowPermission.length
		? `${lessonsWithShowPermission[randomIndex].description}`
		: ''

	const title = t(greeting, {
		name: firstName,
		interpolation: { escapeValue: false },
	})
	const resetNotifyMessage = () =>
		dispatch(companyActions.resetNewExceptionsNofity())
	const handelClosedModal = () => {
		setHasNewCompanyExceptions(prev => false)
		resetNotifyMessage()
	}
	const handleNavigateToExceptionsList = () => {
		resetNotifyMessage()
		history.push(DashboardPaths.SETTINGS)
	}

	return (
		<>
			<StatusInfoCard
				boxShadow={BOX_SHADOW}
				backgroundColor={theme.palette.primary.light}
				title={title}
				imageSrc={dashboardImg}
				description={description}
				isDashboard
			/>

			<MainContentInsights changeView={handleChangeView} />

			<ConcentrationTasksAtDashboard />

			<WallOfPraise />

			<ConfirmModalSB
				isOpen={hasNewCompanyExceptions}
				onConfirm={handleNavigateToExceptionsList}
				onClose={handelClosedModal}
				text={t('new_exceptions_message')}
				confirmButtonText={t('take_me')}
				closeButtonText={t('later')}
			/>
		</>
	)
}

export default Main
