import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { AppDirectionContext } from 'context/languages-context'

import { useStyles } from 'components/Bot/UsersList/useStyles'
import { useStyles as useUsersListStyles } from 'components/Bot/UsersList/userCard'

import { userSelector } from 'redux/user/user-selector'

import UserAvatar from 'components/common/Avatar'
import SearchRectangle from 'components/common/SearchRectangle'
import useHandlers from './useHandlers'
import { Loader } from 'components/common/Loader'

import IUser from 'interface/user/user-interface'
import { Debrief } from 'interface/debrief/debrief-interface'
import { License } from 'enums/License/License.enum'

interface Props {
	shouldSendSms?: boolean
	setDisableNext: (isDisabeld: boolean) => void
	setData: (data: Partial<Debrief>) => void
	currentParticipants: IUser[] | undefined
}

export const ParticipantsChoice = ({
	setDisableNext,
	setData,
	shouldSendSms: _shouldSendSms = false,
	currentParticipants = [],
}: Props) => {
	const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })

	const user = useSelector(userSelector)

	const [shouldSendSms, setShouldSendSms] = useState(_shouldSendSms)

	const {
		handleClick,
		handleSearchTextChange,
		usersList,
		participants,
		listInitilaitzed,
	} = useHandlers({ currentParticipants })

	const selectedUsersId = participants?.map((user: IUser) => user._id)

	useEffect(() => {
		const isDisabeld = participants.length > 0
		setDisableNext(!isDisabeld)
		setData({ participants: [...participants, user], shouldSendSms })
	}, [participants.length, shouldSendSms])

	const handleSmsChange = () => setShouldSendSms(prev => !prev)
	return (
		<>
			<SearchRectangle
				handleChange={handleSearchTextChange}
				className={classes.searchWrapper}
			/>

			<div className={classes.usersListContainer}>
				{usersList.length ? (
					usersList.filter(permittedUser).map((user: IUser) => {
						const {
							securityInfo: { email },
						} = user
						const isSelectedUser = selectedUsersId?.includes(user._id)

						return (
							<UserCard
								key={email}
								user={user}
								isSelectedUser={isSelectedUser}
								handleSelectedUser={handleClick}
							/>
						)
					})
				) : !listInitilaitzed ? (
					<Loader size={55} height={'auto'} />
				) : (
					<div>{t('bot.user_was_not_found')}</div>
				)}
			</div>

			<div>{t('debrief.participants_num', { num: participants.length })}</div>

			<div className={classes.checkboxWrapper}>
				<label htmlFor='checkboxId'>{t('notification.send_notification')}</label>
				<input
					onChange={handleSmsChange}
					checked={shouldSendSms}
					id='checkboxId'
					type='checkbox'
				/>
			</div>
		</>
	)
}

export default ParticipantsChoice

const permittedUser = ({ statusInfo: { license, active } }: IUser) =>
	license !== License.bronze && active

interface UserCardProps {
	user: IUser
	isSelectedUser: boolean
	handleSelectedUser: (user: IUser) => void
}

export const UserCard = ({
	user,
	isSelectedUser,
	handleSelectedUser,
}: UserCardProps) => {
	const classes = useUsersListStyles({ isSelectedUser: isSelectedUser! })
	const {
		statusInfo: { title },
		personalInfo: { firstName, lastName },
	} = user

	return (
		<>
			<div
				id='usersListContainer'
				className={classes.continaer}
				onClick={() => handleSelectedUser(user)}
			>
				<UserAvatar className={classes.avatar} userInfo={user} />
				<div>
					<div className={classes.userName}>
						{firstName} {lastName}
					</div>
					<div>{title}</div>
				</div>
			</div>
		</>
	)
}
