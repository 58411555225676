import { useContext, useState, ChangeEvent, FormEvent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { userSelector } from 'redux/user/user-selector'

import { AppDirectionContext } from '../../context/languages-context'

import useStyles from './styles'

import Input from 'components/common/Input'
import Button from 'components/common/Button'

import { sendContactUsForm } from 'api/contact-us'

import { ContactUsDto } from 'interface/contactUs/contact-us-interface'
import contactUsSvg from 'assets/contactUsSvg.svg'
import IUser from '../../interface/user/user-interface'

interface FormState {
	name: string
	phone: string
	email: string
	message: string
	errors: string[]
}

const getInitialFormState = (user: IUser) => {
	return {
		name: user?.personalInfo?.firstName + ' ' + user?.personalInfo?.lastName,
		phone: user?.personalInfo?.mobile,
		email: user?.securityInfo?.email,
		message: '',
		errors: [],
	}
}
const ContactUs = () => {
	const [loading, setLoading] = useState(false)
	const [notification, setNotification] = useState('')

	const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)

	const user = useSelector(userSelector)

	const initialFormState: FormState = getInitialFormState(user)
	const [formState, setFormState] = useState(initialFormState)

	useEffect(() => {
		setFormState(() => initialFormState)
	}, [user])

	const inputChangeHandler = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
		const property = target.name
		const value = target.value
		let error = value.trim() === ''

		setFormState(prevState => {
			return {
				...prevState,
				[property]: value,
				errors: error
					? [...prevState.errors, property]
					: prevState.errors.filter(e => e !== property),
			}
		})
	}

	const handleReset = () => setFormState(initialFormState)

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		setLoading(true)

		const messageIsEmpty = formState.message.trim() === ''
		if (formState.errors.length || messageIsEmpty) {
			messageIsEmpty &&
				setFormState(prevState => {
					return {
						...prevState,
						errors: [...prevState.errors, 'message'],
					}
				})
			// notify('invalid form')
			setLoading(false)
			return
		}

		const contactData: ContactUsDto = {
			name: formState.name,
			phone: formState.phone,
			email: formState.email,
			message: formState.message,
		}
		const response = await sendContactUsForm(contactData)
		setLoading(false)

		// notify(response.status === 'success' ? 'Sent successfuly' : 'Server Error')
		handleReset()
	}
	// const notify = (notification: string) => {
	// 	setNotification(notification)
	// 	setTimeout(() => {
	// 		setNotification('')
	// 	}, 800)
	// }
	const inputBorder = (name: string) => {
		return formState.errors.includes(name) ? '1px solid tomato' : ''
	}

	const classes = useStyles({ isRtlDirection, notification })

	return (
		<>
			<div className={classes.pageInfo}>
				{/* <h2>{t("Let's_talk")}</h2> */}
				<h1>{t('We’re_here_for_you')}</h1>
				<p className={classes.para}>
					{t("Let's_put_you_in_touch_with_the_right_people")}
				</p>
			</div>
			<div className={classes.mainContainer}>
				<form onSubmit={e => handleSubmit(e)} className={classes.contactForm}>
					<div className={classes.inputsContainer}>
						<Input
							border={inputBorder('firstName')}
							label={'we’ll_need_your_name'}
							type={'text'}
							name={'name'}
							htmlFor={'name'}
							onChange={inputChangeHandler}
							value={formState.name}
						/>
						<Input
							border={inputBorder('phone')}
							label={'a_number_we_can_call'}
							type={'number'}
							name={'phone'}
							htmlFor={'phone'}
							onChange={inputChangeHandler}
							value={formState.phone}
						/>
						<Input
							border={inputBorder('email')}
							label={'and_an_email_to_get_in_touch'}
							type={'email'}
							name={'email'}
							htmlFor={'email'}
							onChange={inputChangeHandler}
							value={formState.email}
						/>
						<Input
							border={inputBorder('message')}
							textarea={true}
							label={'what_are_we_gonna_talk_about'}
							type={'message'}
							name={'message'}
							htmlFor={'message'}
							onChange={inputChangeHandler}
							value={formState.message}
						/>
					</div>
					{loading ? (
						<div className={classes.progress}>
							<CircularProgress />
						</div>
					) : notification ? (
						<div className={classes.notification}>{notification}</div>
					) : (
						<Button className={classes.button} type='submit'>
							{t('Contact_us')}
						</Button>
					)}
				</form>
			</div>
			<img className={classes.contactImg} src={contactUsSvg} />
		</>
	)
}

export default ContactUs
