import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

interface StyleProps {
	backgroundColor: string
	textColor?: string
	imageWidth?: number
	imageHight?: number
	boxShadow?: string
	isRtlDirection?: boolean
	isDashboard: boolean
	isLongText: boolean
}

export default makeStyles<Theme, StyleProps>(({ palette, breakpoints }) => ({
	container: {
		position: 'relative',
		margin: '53px auto 6px',
		boxShadow: ({ boxShadow }) => (boxShadow ? boxShadow : ''),
		background: ({ backgroundColor, isRtlDirection }) =>
			`linear-gradient(${
				isRtlDirection ? '0.750turn' : '0.25turn'
			},${backgroundColor})` || palette.primary.main,
		color: ({ textColor }) => textColor || palette.common.white,
		height: ({ isDashboard }) => (isDashboard ? '100px' : '125px'),
		minHeight: ({ isDashboard }) => (isDashboard ? '100px' : '125px'),
		borderRadius: '10px',
		padding: ({ isDashboard }) => (isDashboard ? '8px' : '30px'),
		[breakpoints.down('xs')]: {
			height: ({ isDashboard }) => (isDashboard ? '116px' : '125px'),
			minHeight: '100px',
			padding: '18px',
		},
	},
	title: {
		position: 'relative',
		zIndex: 10,
		textTransform: 'capitalize',
		fontSize: ({ isDashboard }) => (isDashboard ? '22px' : '28px'),
		fontWeight: 700,
		[breakpoints.down('xs')]: {
			fontSize: ({ isDashboard }) => (isDashboard ? '12px' : '28px'),
			width: '182px',
			lineHeight: '27px',
			marginBottom: '8px',
		},
	},
	descriptionContainer: {
		position: 'relative',
		zIndex: 10,
		display: 'flex',
		top: ({ isDashboard }) => (isDashboard ? '20%' : 0),
		height: '55%',
		width: '88%',
		[breakpoints.down('xs')]: {
			width: '72%',
			height: '42%',
			top: ({ isDashboard }) => (isDashboard ? '20%' : 0),
		},
		'& >span': {
			fontSize: ({ isDashboard }) => (isDashboard ? '1rem' : '16px'),
			fontFamily: 'Sora, sans-serif',
			justifyContent: ({ isDashboard }) => (isDashboard ? 'center' : 'none'),
			width: '88%',
			height: '100%',
			fontWeight: 'bold',
			textOverflow: 'ellipsis',
			'-webkit-line-clamp': 2,

			'-webkit-box-orient': 'vertical',
			overflow: 'hidden',
			overflowY: 'auto',
			'&::-webkit-scrollbar': {
				width: 0,
			},
			wordWrap: 'break-word',
			//description in 1 line: uncomment whiteSpace
			// whiteSpace: 'nowrap',

			//description in 2 lines:
			display: ({ isLongText }) => (isLongText ? '-webkit-box' : 'flex'),

			[breakpoints.down('xs')]: {
				width: '100%',
				display: 'inline-block',
				margin: '0 auto 0 0',
			},
		},
	},
	image: {
		height: '10.5rem',
		position: 'absolute',
		bottom: 0,
		right: ({ isRtlDirection }) => (isRtlDirection ? '' : 0),
		left: ({ isRtlDirection }) => (isRtlDirection ? 0 : ''),
		[breakpoints.down('lg')]: {},
		[breakpoints.down('sm')]: {
			// right: 0,
			position: 'absolute',
			height: '6rem',
		},
	},
}))
