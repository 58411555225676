import { useMemo } from "react"
import { useSelector } from "react-redux"

import { employeesSelector } from "redux/employee/employee.selector"
import { selectedEmployeeSelector } from "redux/user/user-selector"

const useOutputFilterRule = () => {
    const { byEmployee, byBlockage, bySaydo } = useSelector(selectedEmployeeSelector)
    const employees = useSelector(employeesSelector)

    const selectedEmployeeName = useMemo(() => (
        employees.find(emp => emp._id === byEmployee)?.personalInfo.firstName
    ), [employees, byEmployee])

    return { selectedEmployeeName, byEmployee, bySaydo, byBlockage }
}

export default useOutputFilterRule