import { useState, useEffect } from 'react'

import IUser from '../../interface/user/user-interface'
import { Context, FeedbackOutput } from '../../interface/bot/index'

import { Req } from 'enums/req/req.enum'
import { replaceFunction } from '../outputs/helpers'

import { fetchFeedbackOutputById } from 'api/feedback'
import { mapFurtherImpact } from './usePraiseOutputData'

type UseOutputPayloadArgsType = {
	currentUserInfo: IUser
	isReciever: boolean
	replacements: Record<string, any>
	outputBeforeMap: FeedbackOutput<IUser>
}

const initialOutputData = {
	FREE_TEXT_LABEL: '',
	CONTRIBUTION: '',
	CONTEXT: '',
	IMPACTS: [] as string[],
}

export const useFeedbackOutputData = (params: UseOutputPayloadArgsType) => {
	const { currentUserInfo, isReciever, replacements, outputBeforeMap } = params

	const [outputData, setOutputData] = useState(initialOutputData)

	useEffect(() => {
		if (!outputBeforeMap?.outputType) return

		const getPraiseReplacemnts = async (id: string) => {
			const payload = { id, isBeforeSend: isReciever ? 'false' : 'true' }
			const language = currentUserInfo.statusInfo.companyLanguage

			const { data, status }: { data: any; status: any } =
				await fetchFeedbackOutputById(payload)

			if (status === Req.failed) {
				alert('no output type!')
				return
			}

			const { mappedImpacts, context, ...rest } = data
			let output = rest.beforeSend

			const isBeforeSend = payload.isBeforeSend === 'true'

			if (!isBeforeSend) {
				output = rest.afterSend
			}
			const {
				freeTextDescriptions = {},
				contributionToSuccess = {},
				aboutContext = {},
			} = output

			const replacedText = replaceFunction(
				freeTextDescriptions,
				replacements,
				language
			)

			const replacedContribution = replaceFunction(
				contributionToSuccess,
				replacements,
				language
			)

			let mappedFrameWorkContext = ''
			if ('context' in data && outputBeforeMap.context in data.context) {
				mappedFrameWorkContext = data.context[outputBeforeMap.context][language]
			}
			const notRelevant = [Context.NOT_RELEVANT, Context.NOT_RELEVANT_HE]

			const isNotRelevantContext = notRelevant.includes(
				outputBeforeMap.context as Context
			)

			const frameWorkContext = mappedFrameWorkContext
				? mappedFrameWorkContext
				: outputBeforeMap?.context && outputBeforeMap?.context.trim().length
					? isNotRelevantContext
						? ' '
						: `(${outputBeforeMap.context})`
					: ' '

			const replacedReason = replaceFunction(
				aboutContext,
				{
					...replacements,
					'%CONTEXT%': frameWorkContext,
				},
				language
			)
			const furtherImpacts = mapFurtherImpact({
				userLang: language,
				indexes: outputBeforeMap.furtherImpact,
				impacts: mappedImpacts,
				rep: replacements,
				isBeforeSend: isBeforeSend,
			})

			setOutputData({
				FREE_TEXT_LABEL: replacedText,
				CONTRIBUTION: outputBeforeMap.contributions ? replacedContribution : '',
				CONTEXT: replacedReason,
				IMPACTS: furtherImpacts,
			})
		}
		getPraiseReplacemnts(outputBeforeMap.outputType)
	}, [outputBeforeMap?.outputType, currentUserInfo])

	return outputData
}
