import { FC } from 'react'
import { makeStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const InitialMessageContainer: FC<{}> = ({ children }) => {
	const classes = useStyles()
	return (
		<div style={{ margin: 'auto' }} className={classes.container}>
			{children}
		</div>
	)
}

export const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
	container: {
		width: '100%',
		maxWidth: '516px',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		[breakpoints.down('sm')]: {
			margin: '0 4%',
			height: '83vh',
		},
	},
}))
