import { FC } from 'react'
import UsersList from '../UsersList/index'
import { AnswersGroupProp } from 'interface/bot/index'

const BotOnboarding: FC<AnswersGroupProp> = props => (
	<>
		<UsersList {...{ ...props }} />
	</>
)

export default BotOnboarding
