import { makeStyles } from '@material-ui/core'
import UserAvatar from 'components/common/Avatar'
import { AppDirectionContext } from 'context/languages-context'
import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import { Debrief } from 'interface/debrief/debrief-interface'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { userSelector } from 'redux/user/user-selector'
import { IThemeOptions } from 'theme'
import { isSameMonth } from 'utils/helpers'
import { backgroundIconsMap } from 'utils/iconsMapper'
import { removeItem, setItem } from 'utils/localStorage'
import { useStyles } from '../../Bot/InitialMessage/useStyles'
import ActionButtons from '../ActionButtons'
import { InitialMessageContainer } from './initialMessageContainer'
import { MessageHeader } from './messageHeader'

interface Props {
	msgData?: { type: string; steps: string[] }
	nextStep: DebriefingSteps
	prevStep?: DebriefingSteps
	showStepDescription?: boolean
	setData: (data: Partial<Debrief>, step: DebriefingSteps) => void
}
export const InitialMessage = (props: Props) => {
	const {
		nextStep,
		prevStep,
		setData,
		showStepDescription,
		msgData = { type: 'initial_msg', steps: ['1', '2', '3', '4'] },
	} = props
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const innerClasses = useInnerStyles({ isRtlDirection })
	const sameMonth = isSameMonth(msgData.type)
	const user = useSelector(userSelector)
	const { t } = useTranslation()
	const {
		personalInfo: { firstName = '', lastName = '' },
		statusInfo: { title = '' },
	} = user || {}
	const recepientUsername = firstName.concat(' ' + lastName)
	useEffect(() => {
		if (!sameMonth) removeItem(msgData.type)

		if (sameMonth) setData({}, nextStep)
	}, [msgData, sameMonth])

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const currentMonth = new Date().getMonth()

		if (e.target.checked) setItem(msgData.type, currentMonth)
		else removeItem(msgData.type)
	}
	const renderMsgSteps = () => {
		const style = {
			padding: '0.2rem 1rem 0.2rem 1rem',
			margin: '5px 2.5% 0 2.5%',
		}
		return msgData.steps.map((step: string) => {
			return (
				<section style={style} className={classes.fistInstruction}>
					<b>
						{msgData.steps.length === 1
							? t('debrief.noitce')
							: showStepDescription
							? `${t('debrief.step')} ${step}:`
							: `${step}.`}{' '}
					</b>
					{msgData.steps.length === 1 && <br />}
					{t(`debrief.${`${msgData.type}_step_${step}`}`)}
				</section>
			)
		})
	}
	return (
		<InitialMessageContainer>
			{!sameMonth ? (
				<>
					<MessageHeader additionalClass={innerClasses.headerSection}>
						<div className={classes.recepientUserDetails}>
							<UserAvatar className={classes.avatar} userInfo={user} />
							<div className={classes.recepientUserDetailsNamAndRole}>
								<div
									style={{ fontSize: '130%' }}
									className={classes.recepientUserDetailsName}
								>
									{recepientUsername}
								</div>
								<div
									style={{ fontSize: '80%' }}
									className={classes.recepientUserDetailsRole}
								>
									{title}
								</div>
							</div>
						</div>
						<div className={classes.YouWantToPraise}>
							{t(`debrief.${`${msgData.type}_title`}`)}
						</div>
						<div className={classes.thatsgreat}>
							{t(`debrief.${`${msgData.type}_sub_title`}`)}
						</div>
					</MessageHeader>
					{renderMsgSteps()}
					<div className={classes.checkboxAction} style={{ marginBottom: '6%' }}>
						<input type='checkbox' onChange={handleCheckboxChange} />
						<div className={classes.checkboxNote}>
							{t(`bot.${'Dont_show_me_this_message_again'}`)}
						</div>
					</div>

					<div className={classes.bottomSection}>
						{/* <div className={classes.iconWrapper}>
							<img
								className={classes.heartIconStyle}
								src={backgroundIconsMap['heartIcon']}
								alt='heartIcon'
							/>
						</div> */}

						<div className={classes.actionsContainer} id='actionsContainer'>
							<ActionButtons
								nextButtonText={t('debrief.got_it')}
								update={setData}
								height={'100%'}
								nextStep={{ step: nextStep }}
								prevStep={prevStep ? { step: prevStep } : undefined}
							/>
						</div>
					</div>
				</>
			) : null}
		</InitialMessageContainer>
	)
}

export default InitialMessage

const useInnerStyles = makeStyles<IThemeOptions>(
	({ breakpoints, palette }) => ({
		headerSection: {
			backgroundColor: palette.debrief.main,
		},
	})
)
