import { FC, useContext, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import searchIcon from 'assets/searchIcon.svg'
import clearIcon from 'assets/closeIcon.svg'
import { AppDirectionContext } from 'context/languages-context'
import { Styles } from 'screens/Bot/useStyles'

const useStyles = makeStyles<{}, Styles>({
	searchInputWrapper: {
		position: 'relative',
	},
	searchIconStyle: {
		position: 'absolute',
		left: ({ isRtlDirection }) => (isRtlDirection ? '' : '11px'),
		top: '11px',
		right: '15px',
	},
	clearIconStyle: {
		height: '41px',
		position: 'absolute',
		float: 'right',
		right: ({ isRtlDirection }) => (isRtlDirection ? '' : '7px'),
		left: ({ isRtlDirection }) => (isRtlDirection ? '7px' : ''),
		top: '2px',
		opacity: 0.8,
		cursor: 'pointer',
	},
	container: {
		height: '44px',
		width: '100%',
		borderRadius: '27px',
		backgroundColor: 'rgba(233, 232, 241, 0.5)',
		outline: 'none',
		paddingInlineStart: '52px',
		fontSize: '18px',
		boxSizing: 'border-box',
		border: '2.8px solid',
		borderColor: 'transparent',

		'&::placeholder': {
			color: 'rgba(70, 72, 74, 0.5)',
		},

		'&:focus': {
			borderColor: '#0071C2',
			background: '#FFFFFF',
			boxShadow: '0px 5px 12px 0px #00000040',
		},
	}
})

type Props = {
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	placeholder?: string
	className?: string
	onClear?: () => void
	inputRef?: React.RefObject<HTMLInputElement>
	inputFieldStyle?: string 
}

const SearchRectangle: FC<Props> = ({
	handleChange,
	placeholder,
	className,
	onClear,
	inputRef,
	inputFieldStyle
}) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const { t } = useTranslation()

	const styles = [classes.searchInputWrapper, className && className]
		.filter(Boolean)
		.join(' ')

	const inputIsFilled =
		inputRef && inputRef.current && inputRef.current.value.length > 0
	return (
		<div className={styles}>
			<img
				className={classes.searchIconStyle}
				src={searchIcon}
				alt='search Icon'
			/>
			<input
				ref={inputRef}
				onChange={handleChange}
				className={inputFieldStyle ?  [classes.container,inputFieldStyle].join(' ') : classes.container }
				type='text'
				placeholder={placeholder ? placeholder : t('bot.SearchForEmployee')}
			/>
			{onClear && inputIsFilled && (
				<img
					className={classes.clearIconStyle}
					onClick={onClear}
					src={clearIcon}
					alt='clear Icon'
				/>
			)}
		</div>
	)
}

export default SearchRectangle
