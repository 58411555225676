import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PATCH } from 'api/api-requests'
import { URLS } from 'api/api-urls'
import { getItem } from 'utils/localStorage'
import { UserAnswer } from '../../components/Bot/ActionButtons/index'
import { QuestionType } from '../../interface/praise/praise-interface'

export interface BotState<A = any, O = any> {
	outpot: O
	senderPlaceholder: string
	recipientPlaceholder: string
	answers: A[]
}
export type UserAnswerType = BotState<UserAnswer, string>

const initialState: UserAnswerType = {
	outpot: '',
	senderPlaceholder: 'sender user name',
	recipientPlaceholder: 'recipient placeholder name',
	answers: [],
}

const getTextArea = (text: string) => {
	return {
		type: QuestionType.TextArea,
		text,
		answer: text,
	}
}

export const botSlice = createSlice({
	name: 'bot',
	initialState,

	reducers: {
		setState(state, action) {
			const newState = action.payload
			return {
				...newState,
			}
		},
		updateTextOutputAnswer(
			state,
			action: PayloadAction<{ type?: QuestionType.TextArea; answer: string }>
		) {
			const answers = state.answers.slice()

			const updatedAnswers = answers.map(_answer => {
				const shouldUpdateAnswer =
					QuestionType.TextArea === (_answer.type as QuestionType)
				if (!shouldUpdateAnswer) return _answer

				return {
					..._answer,
					type: QuestionType.TextArea,
					text: action.payload.answer,
					answer: action.payload.answer
				}
			})

			return {
				...state,
				answers: [...updatedAnswers],
			}
		},
		addAnswer(state, action: PayloadAction<UserAnswer>) {
			const answer = action.payload
			console.log({ answer })
			const {
				answer: _answer,
				recipient,
				date,
				multiChoiceAnswer,
				participants,
				values,
				expansionContribution,
				expansionImpacts,
			} = answer
			PATCH(URLS.BOT_LOGS, {
				_id: getItem('logId'),
				answer:
					(values?.length && values) ||
					(recipient &&
						`${recipient.personalInfo.firstName} ${recipient.personalInfo.lastName}`) ||
					_answer ||
					date ||
					multiChoiceAnswer ||
					(participants?.length &&
						participants.map((participant, index) =>
							index == participants.length - 1
								? participant.personalInfo.firstName +
								' ' +
								participant.personalInfo.lastName
								: participant.personalInfo.firstName +
								' ' +
								participant.personalInfo.lastName +
								', '
						)) ||
					expansionImpacts ||
					expansionContribution,
			})
			return {
				...state,
				answers: [...state.answers, answer],
			}
		},
		removeAnswer(state) {
			return {
				...state,
				answers: state.answers.filter((_, i) => i !== state.answers.length - 1),
			}
		},
		removeAllAnswers(state) {
			return {
				...state,
				answers: [],
			}
		},
	},
})
export const botActions = botSlice.actions
export default botSlice.reducer
