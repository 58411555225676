import { useState } from "react"

const useCalendar = () => {

    const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false)
    const [dueDate, setDueDate] = useState<Date | undefined>(undefined)

    const handleSetDueDate = (day: Date) => {
        setDueDate(prev => day)
        toggleShowingCalendar()
    }
    const toggleShowingCalendar = () => {
        setIsCalendarOpen(prev => !prev)
    }
    const resetCalendarState = () => {
        setDueDate(prev => undefined)
        toggleShowingCalendar()
    }
    const resetDueDate = () => {
        setDueDate(prev => undefined)
    }
    return {
        isCalendarOpen,
        dueDate,

        toggleShowingCalendar,
        handleSetDueDate,
        resetCalendarState,
        resetDueDate
    }
}

export default useCalendar