import IUser from 'interface/user/user-interface'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { employeesSelector } from 'redux/employee/employee.selector'
import { userSelector } from 'redux/user/user-selector'

export const fakeManagerObject = {
	_id: '',
	personalInfo: {
		firstName: '',
		lastName: '',
	},
	statusInfo: { title: '' },
} as IUser
export const UNASSINGED = 'unassigned'

export const useManagerObject = (user: IUser | undefined) => {
	const employees = useSelector(employeesSelector)
	const connectedUser = useSelector(userSelector)

	const foundManagerObject = useMemo(() => {
		if (!user) return fakeManagerObject

		const { statusInfo } = user
		const { directManager } = statusInfo

		if (directManager === UNASSINGED) return fakeManagerObject

		const [employee] = filterEmployee(employees, directManager)
		const isManager = directManager === connectedUser.securityInfo.email

		return isManager ? connectedUser : employee
	}, [employees, connectedUser])

	return foundManagerObject
}

const filterEmployee = (employees: IUser[], directManager: string) =>
	employees.filter(({ securityInfo: { email } }) => email === directManager)
