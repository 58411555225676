import React, { FC } from 'react'
import {
	Type as ScreenType,
	OutputType,
} from '../../../screens/BotOutput/index'

import useStyles from './useStyles'
import IUser from '../../../interface/user/user-interface'
import { useHistory } from 'react-router-dom'
import { DashboardPaths } from '../../../enums/routes/path-names'
import { RenderCardText } from './RenderText'
import { CardAvatar } from './RenderCardAvatar'
import { useTranslation } from 'react-i18next'

export enum Type {
	PRAISE = 'Praise',
	FEEDBACK = 'Feedback',
	BLOCKAGE = 'Blockage',
}
type LastInsightsCardsProps = {
	chipName: Type
	date?: string
	amountOfBlockages?: number
	senderInfo?: IUser & {
		outputId?: string
	}
}
export type CardProp = {
	cardType: Type
	hasReceived: boolean
	amountOfBlockages?: number
	senderInfo: IUser
}
type Chip = {
	isPraise?: boolean
	chipName: string
}
export const Chip = (props: Chip) => {
	const {
		isPraise,
		chipName,
	} = props
	
	const classes = useStyles()
	const { t } = useTranslation()
	return (
		<div className={classes[isPraise ? 'praiseChip' : 'feedbackChip']}>
			{t(chipName)}
		</div>
	)
}
const LastInsightsCards: FC<LastInsightsCardsProps> = React.memo(
	({ chipName, date, senderInfo, amountOfBlockages }) => {
		const classes = useStyles()
		const history = useHistory()
		const { t } = useTranslation()
		const bloackeBox = chipName === Type.BLOCKAGE
		const hasReceived = Boolean(senderInfo?._id) && chipName !== Type.BLOCKAGE
		const isPraise = chipName === Type.PRAISE
		const handleClick = (url: string) => {
			history.push(url, {
				id: senderInfo?.outputId,
				screenToReturn: DashboardPaths.MAIN,
				type: isPraise ? ScreenType.Praise : ScreenType.Feedback,
				outputScreenType: isPraise
					? OutputType.PraiseReciever
					: OutputType.FeedbackReciever,
			})
		}
		const cursorStyle = { cursor: bloackeBox || hasReceived ? 'pointer' : 'auto' }
		return null

		return (
			<div
				className={classes.borderBox}
				style={cursorStyle}
				onClick={() =>
					hasReceived
						? handleClick(`/output/${senderInfo?.outputId}`)
						: Type.BLOCKAGE
						? handleClick(`/dashboard/feedbacks`)
						: {}
				}
			>
				<div className={classes.box}>
					<Chip isPraise={isPraise} chipName={chipName} />
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							height: '74%',
						}}
					>
						<RenderCardText
							cardType={chipName}
							hasReceived={hasReceived}
							senderInfo={senderInfo!}
							amountOfBlockages={amountOfBlockages}
						/>
						{hasReceived && (
							<p>
								{t('on')} {date}
							</p>
						)}
						<CardAvatar
							cardType={chipName}
							hasReceived={hasReceived}
							senderInfo={senderInfo!}
							amountOfBlockages={amountOfBlockages}
						/>
						{/* <div
						onClick={() =>
							handleClick(`/dashboard/bot/${isPraise ? 'praise' : 'feedback'}`)
						}
						style={{ color: '#0071C2', cursor: 'pointer', margin: 'auto' }}
					>
						{!hasReceived && chipName !== Type.BLOCKAGE && t(`Send_${chipName}`)}
					</div> */}
					</div>
				</div>
			</div>
		)
	}
)

export default LastInsightsCards
