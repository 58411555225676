import { useEffect, useState } from 'react'
import { fetchPraiseTabsTemplate } from '../../api/praise'

import { OutputBeforeAfter } from 'interface/bot'
import { Req } from 'enums/req/req.enum'
import { Type } from 'screens/BotOutput'
import { fetchFeedbackTabsTemplate } from '../../api/feedback'

type BeforeAfter = Omit<
	OutputBeforeAfter,
	'freeTextDescriptions' | 'contributionToSuccess'
>

export type TabsOutput = {
	beforeSend: BeforeAfter
	afterSend: BeforeAfter
}

export type ConstructedOutputTab = Record<string, TabsOutput>

export const useTabsTemplates = (type: Type) => {
	const [tabsTemplate, setTabsTemplates] = useState<ConstructedOutputTab>({})
	const [error, setError] = useState('')
	const [isLoading, setLoading] = useState(true)

	useEffect(() => {
		try {
			const fetchTemplates = async () => {
				const { data, status } =
					type === Type.Praise
						? await fetchPraiseTabsTemplate()
						: await fetchFeedbackTabsTemplate()

				if (status === Req.failed) setError(data)
				setTabsTemplates(data)
				setLoading(false)
			}
			fetchTemplates()
		} catch (err) {
			setError(err as string)
			setLoading(false)
		}
	}, [])
	return { tabsTemplate, isLoading, error }
}
