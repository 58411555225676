import { TodosStatus, TodoState } from 'enums/todos/todos.enum'
import { Task, TasksAction } from 'interface/todos/todos-interface'

export const filterAlgorithem = (
	todos: TasksAction,
	isManagerViewing: boolean,
	managerSide: boolean,
	showDeletedEmployeeTasks: boolean
): Task[] => {
	const { tasks } = todos
	const managerTasks = tasks.filter(task => task.isManagerAction)
	const employeeTasks = tasks.filter(task => !task.isManagerAction)
	console.log({ showDeletedEmployeeTasks })

	const deletedEmployeeTasks = employeeTasks.filter(task => task.state == TodoState.deleted)
	const excludeEmployeeDeletedTasks = employeeTasks.filter(task => task.state !== TodoState.deleted)

	if (!isManagerViewing && !managerSide) {
		return employeeTasks
	}

	// if (!isManagerViewing && managerSide) {
	// 	return excludeManagerDeletedTasks
	// }

	if (managerSide) {
		return managerTasks
	}

	// if (status == TodosStatus.beforeSending) {
	// 	return viewedEmployeeTasks
	// }
	if (showDeletedEmployeeTasks) {
		return deletedEmployeeTasks
	}
	// if (status == TodosStatus.waiting && !didManagerSee) {
	// 	return employeeTasks
	// }
	return excludeEmployeeDeletedTasks


}