import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import useStyles from './styled'

import { get_employees } from 'redux/employee/employee-actions'
import { get_company_values } from 'redux/company/company-actions'

import { userSelector } from 'redux/user/user-selector'
import { employeesSelector } from 'redux/employee/employee.selector'
import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'

import { CircularProgress } from '@material-ui/core'
import DepartmentBuilder from 'components/HRsettings/DepartmentBuilder'
import HRCompanyBuilder from 'components/HRsettings/HRCompanyBuilder'
import CompanyValues from 'components/HRsettings/CompanyValues'
import CompanySettings from 'components/HRsettings/CompanySettings/CompanySettings'
import CompanyManagersExceptions from '../../components/HRsettings/HRCompanyExceptions/index'
import StepsBar from 'components/HRsettings/StepsBar'

import IUser from 'interface/user/user-interface'

const HrSettingsScreen = () => {
	const [step, setStep] = useState<number>(0)

	const classes = useStyles()
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const steps = [
		{
			label: t('assign_employees'),
			value: 0,
		},
		{
			label: t('set_up_company'),
			value: 1,
		},
		{
			label: t('define_values'),
			value: 2,
		},
		{
			label: t('activate_company'),
			value: 3,
		},
		{
			label: t('activate_monitoring'),
			value: 4,
		}
	]
	const { company_id: companyId, statusInfo: { companyLanguage } } = useSelector(userSelector)
	const employees: IUser[] = useSelector(employeesSelector)

	useEffect(() => {
		dispatch(get_employees({ company_id: companyId! }))
		dispatch(get_company_values(companyId!))
	}, [])

	const windowWidth = useWindowWidth()
	const mobileWidth = 1000
	const isMobile = mobileWidth >= windowWidth

	const progressbar = (
		<div className={classes.progresbar}>
			<CircularProgress size={150} />
		</div>
	)

	const render = () => {
		return {
			[0]: (
				<DepartmentBuilder
					progressbar={progressbar}
					employees={employees}
					companyId={companyId!}
				/>
			),
			[1]: (
				<HRCompanyBuilder
					companyLanguage={companyLanguage}
					progressbar={progressbar}
					companyId={companyId!}
				/>
			),
			[2]: <CompanyValues progressbar={progressbar} company_id={companyId!} />,
			[3]: <CompanySettings company_id={companyId!} />,
			[4]: <CompanyManagersExceptions />,
			[-1]: <span>no step defined</span>
		}[step]
	}

	const screenToRender = (
		<p className={classes.header}>
			{t('settings_on_desktop_1')}
			<br />
			{t('settings_on_desktop_2')}
		</p>
	)

	return (
		<>
			{isMobile ? screenToRender : (
				<>
					<StepsBar
						barWidth='60vw'
						tabWidth='25%'
						stepsData={steps}
						currentStep={step}
						onStepChange={(step: number) => setStep(step)}
					/>
					{render()}
				</>
			)}
		</>
	)
}

export default HrSettingsScreen

// switch (step) {
		// 	case 0:
		// 		return (
		// 			<DepartmentBuilder
		// 				progressbar={progressbar}
		// 				employees={employees}
		// 				companyId={companyId!}
		// 			/>
		// 		)

		// 	case 1:
		// 		return (
		// 			<HRCompanyBuilder
		// 				companyLanguage={companyLanguage}
		// 				progressbar={progressbar}
		// 				companyId={companyId!}
		// 			/>
		// 		)
		// 	case 2:
		// 		return <CompanyValues progressbar={progressbar} company_id={companyId!} />

		// 	case 3:
		// 		return <CompanySettings company_id={companyId!} />
		// 	case 4:
		// 		return <HrControl company_id={companyId!} />

		// 	default:
		// 		return <span>no step defined</span>
		// }
