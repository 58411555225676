import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { teamsUpdated } from "redux/teams/teams-selector"

const useFoucesedInput = (_id: string) => {
    const [showInputField, setShowInputField] = useState<boolean>(false)
    const updatePrccess = useSelector(teamsUpdated)

    useEffect(() => {
        if (!_id) return setShowInputField(true)

        updatePrccess && setShowInputField(prev => false)
    }, [_id, updatePrccess])

    return { showInputField, setShowInputField }
}

export default useFoucesedInput