import React, { FC, useEffect, useMemo, useRef, forwardRef, ForwardedRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStyles from './style'
import { get_summaries_activities } from 'redux/company/company-actions'
import { allCompaniesActivitiesSelector } from 'redux/company/company.selector'
import { EportTableToExcelFile } from './ExportTableToXl'
import { useTranslation } from 'react-i18next'

type ColProps = {
    colData: any[]
    ref?: ForwardedRef<HTMLDivElement | null>
    firstColumn?: boolean
}

export const Col: FC<ColProps> = forwardRef(({ colData, firstColumn }, ref: ForwardedRef<HTMLDivElement | null>) => {
    const classes = useStyles({ firstColumn })
    const { t } = useTranslation()
    return (
        <div
            className={classes.tableColumn}
            ref={ref!}
        >
            {colData.map((cell: any, index: any) => (
                <div key={index}>{t(`${cell}`)}</div>
            ))}
        </div>
    )
})

const rowsNames = ['activity', 'praises', 'feedbacks', 'pers_learning', 'group_learning', 'managers_todos', 'employees_todos']
export const TotalSuperTable = () => {

    const dispatch = useDispatch()
    const classes = useStyles({})
    const activities = useSelector(allCompaniesActivitiesSelector)
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        dispatch(get_summaries_activities())
    }, [])

    const activityDataMapped = useMemo(() => {
        const result = excluedEmptyCompanies([...activities])
        const sumOfEmployees = result[1]
        const extractArrays =
            result[0]
                .reduce((c: any, v: any) => c.concat(v), [])
                .map((o: any) => o.data)

        return [extractArrays, sumOfEmployees]
    }, [activities])
    const superTableHeader = (
        <>
            <div className={classes.header}>
                <div>SYSTEM activity - Month average per user</div>
            </div>
            <div className={classes.companyDetails}>
                <div>Total active users:
                    <span className={classes.usersCounter}>
                        {activityDataMapped[1]}
                    </span>
                </div>
            </div>
        </>
    )
    const fileName = 'saydo_summary'
    return (
        <>
            {superTableHeader}
            {activityDataMapped.length && <EportTableToExcelFile apiData={activityDataMapped[0]} fileName={fileName} />}
            <div className={classes.wrapper}>
                <Col colData={rowsNames} firstColumn />
                {activityDataMapped[0].map((elem: any, index: any) => (
                    <Col colData={elem} ref={ref} />
                ))}
            </div>
        </>
    )
}

export default TotalSuperTable


const excluedEmptyCompanies = <T extends any[]>(
    companiesData: Array<T> = [],
    result: T[] = [],
    usersLength: number = 0): Array<Array<T> | any> => {

    if (!companiesData.length) return [result, usersLength]
    console.log({ companiesData })
    const company = companiesData[0]
    const shouldAddCompany = company.length && company[0].data.length
    const usersList = shouldAddCompany ? usersLength + company[0].sumActivityUsers : usersLength
    companiesData.splice(0, 1)
    return excluedEmptyCompanies(companiesData, shouldAddCompany ? [...result, company] : result, usersList)
}