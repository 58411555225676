
export const buildPayload = (data: any) => {
    const {
        userTodos: { _id: todos_id },
        user: { company_id, _id },
        isManagerViewing, todo_id } = data
    return {
        company_id,
        user_id: _id,
        todos_id,
        todo_id,
        isManager: !!isManagerViewing!
    }
}