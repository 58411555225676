import { createAsyncThunk } from '@reduxjs/toolkit'
import {
	createEmployees,
	updateEmployee,
	deleteEmployees,
	getEmployees,
	defineEmployeeManager,
	setEmployeeGoals,
	deleteAllCompanyEmployees,
	updateEmployeePermissions,
	restoreEmployee,
	createGoalAction,
	editGoalAction,
	deleteGoalAction,
} from 'api/employee'
import { EmployeePermissionToUpate } from 'components/ActivityTable/DisplayPermissionIndicator'
import { GoalPayload } from 'components/Goals/GoalModal/GoalsTable/types'
import { Req } from 'enums/req/req.enum'
import { IStoreRootState } from 'interface/redux'
import IUser, { IUserGoalState } from 'interface/user/user-interface'
import { get_user } from 'redux/user/user-actions'
//

export const create_goal_action = createAsyncThunk(
	'employee/createGoalAction',
	async (
		payload: GoalPayload,
		{ rejectWithValue, dispatch, getState }
	) => {
		try {
			const { status, data } = await createGoalAction(payload)

			if (status === Req.failed) throw new Error(data)
			const { user } = getState() as IStoreRootState

			dispatch(
				get_user({ user_id: user.user._id, company_id: user.user?.company_id })
			)
			dispatch(get_employees({ company_id: user.user?.company_id }))

			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { employee } = getState() as IStoreRootState
			return employee.loading === false
		},
	}
)
export const edit_goal_action = createAsyncThunk(
	'employee/editGoalAction',
	async (
		payload: GoalPayload,
		{ rejectWithValue, dispatch, getState }
	) => {
		try {
			console.log('in edit_goal_action = createAsyncThunk(')
			const { status, data } = await editGoalAction(payload)

			if (status === Req.failed) throw new Error(data)
			const { user } = getState() as IStoreRootState

			dispatch(
				get_user({ user_id: user.user._id, company_id: user.user?.company_id })
			)
			dispatch(get_employees({ company_id: user.user?.company_id }))

			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { employee } = getState() as IStoreRootState
			return employee.loading === false
		},
	}
)
export const delete_goal_action = createAsyncThunk(
	'employee/deleteGoalAction',
	async (
		payload: GoalPayload,
		{ rejectWithValue, dispatch, getState }
	) => {
		try {
			console.log('in delete_goal_action = createAsyncThunk(')
			const { status, data } = await deleteGoalAction(payload)

			if (status === Req.failed) throw new Error(data)
			const { user } = getState() as IStoreRootState

			dispatch(
				get_user({ user_id: user.user._id, company_id: user.user?.company_id })
			)
			dispatch(get_employees({ company_id: user.user?.company_id }))

			return data
		} catch (err) {
			console.log(err);
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { employee } = getState() as IStoreRootState
			return employee.loading === false
		},
	}
)
export const get_employees = createAsyncThunk(
	'employee/getEmployees',
	async (payload: { company_id: string }, { rejectWithValue }) => {
		try {
			const { status, data } = await getEmployees(payload.company_id)

			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { employee } = getState() as IStoreRootState
			return employee.loading === false
		},
	}
)

export const create_employees = createAsyncThunk(
	'employee/createEmployees',
	async (payload: { employees: IUser[] }, { rejectWithValue }) => {
		try {
			const { status, data } = await createEmployees(payload.employees)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { employee } = getState() as IStoreRootState
			return employee.loading === false
		},
	}
)

export const delete_employees = createAsyncThunk(
	'employee/deleteEmployees',
	async (ids: Array<string>, { rejectWithValue }) => {
		try {
			const { status, data } = await deleteEmployees(ids)
			console.log({ data })
			if (status === Req.failed) throw new Error(data)
			return { deletedIds: ids, unassignedEmployees: data }
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { employee } = getState() as IStoreRootState
			return employee.loading === false
		},
	}
)

export const restore_employee = createAsyncThunk(
	'employee/restoreEmployee',
	async (employee: IUser, { rejectWithValue }) => {
		try {
			const { status, data } = await restoreEmployee(employee)
			if (status === Req.failed) throw new Error(data)
			return { data, employee }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const delete_all_company_employees = createAsyncThunk(
	'employee/deleteAllCompanyEmployees',
	async (company_id: string, { rejectWithValue }) => {
		try {
			const { status, data } = await deleteAllCompanyEmployees(company_id)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { employee } = getState() as IStoreRootState
			return employee.loading === false
		},
	}
)

export const update_employee = createAsyncThunk(
	'employee/updateEmployee',
	async (payload: { employee: IUser }, { rejectWithValue }) => {
		try {
			const { status, data } = await updateEmployee(payload.employee)
			if (status === Req.failed) throw new Error(data.message)
			return data
		} catch (err) {
			console.log('sadasd')
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { employee } = getState() as IStoreRootState
			return employee.loading === false
		},
	}
)

export const update_employee_permissions = createAsyncThunk(
	'employee/updateEmployeePermissions',
	async (payload: EmployeePermissionToUpate, { rejectWithValue }) => {
		try {
			const { status, data } = await updateEmployeePermissions(payload)
			if (status === Req.failed) throw new Error(data.message)
			return { data: payload, status }
		} catch (err) {
			console.log('sadasd')
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { employee } = getState() as IStoreRootState
			return employee.loading === false
		},
	}
)

export const define_employee_manager = createAsyncThunk(
	'employee/defineEmployeeManager',
	async (
		payload: {
			company_id: string
			manager_id: string
			managerEmail: string
			assignIdsList: string[]
		},
		{ rejectWithValue }
	) => {
		try {
			const { status, data } = await defineEmployeeManager(
				payload.company_id,
				payload.managerEmail,
				payload.assignIdsList,
				payload.manager_id
			)
			if (status === Req.failed) throw new Error(data)
			return payload
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { employee } = getState() as IStoreRootState
			return employee.loading === false
		},
	}
)

export const set_employee_goals = createAsyncThunk(
	'employee/setEmployeeGoals',
	async (
		payload: { id: string; goals: IUserGoalState[] },
		{ rejectWithValue }
	) => {
		try {
			const { status, data } = await setEmployeeGoals(payload.id, payload.goals)
			if (status === Req.failed) throw new Error(data.message)
			return { id: payload.id, goals: data }
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { employee } = getState() as IStoreRootState
			return employee.loading === false
		},
	}
)
