export enum TodosStatus {
	beforeSending = 'beforeSending',
	waiting = 'waiting',
	approved = 'approved',
}
export enum TodoState {
	new = "new",
	updated = "updated",
	viewed = "viewed",
	deleted = "deleted"
}
export enum ApprovedTask {
	true = "true",
	false = "false",
	none = "none"
}
export enum TodoStatusOptions {
	DONE = 'done',
	IN_PROGRESS = 'in_progress',
	STUCK = 'stuck',
	NOT_STARTED = 'not_started'
}
