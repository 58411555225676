import {
	Debrief,
	Lesson,
	DebriefEvent,
	Summary,
} from '../interface/debrief/debrief-interface'

type Payload = { event_id: string; summary_id: string; lessonOwner_id: string }
/**
 * The function accepts current debrief data and payload.
 * Maps trough the events and summaries and insert the new lesson.
 */
export default (debriefOnProcess: Debrief, data: Lesson, payload: Payload) => {
	const { event_id, lessonOwner_id } = payload
	return debriefOnProcess.events.map((event: DebriefEvent) => {
		if (event._id === event_id) {
			event.summaries?.map((summary: Summary) => {
				const isEvent = summary.event_id === event_id
				const isLessonOwner = summary.lessonsOwner_id === lessonOwner_id
				const isExpectedSummary = isEvent && isLessonOwner

				if (isExpectedSummary) {
					summary.lessons = [...summary.lessons, data]
				}
			})
		}
		return event
	})
}
