import * as React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { makeStyles, Theme } from '@material-ui/core'
import { IThemeOptions } from 'theme'

import { useTranslation } from 'react-i18next'
import { AppDirectionContext } from 'context/languages-context'

export type SelectMenuItem = {
	value: string
	createOptionContentText: string
}

interface Props<T> {
	isSummary?: boolean
	disabled: boolean
	currentSelection: T
	selectMenuItems: Array<SelectMenuItem>
	setSelection: (cat: T) => void
}

export default function BasicSelect<T>(props: Props<T>) {
	const {
		disabled,
		currentSelection,
		isSummary,
		selectMenuItems,
		setSelection,
	} = props

	const handleChange = (event: any) => {
		setSelection(event.target.value)
	}
	const { isRtlDirection } = React.useContext(AppDirectionContext)
	const { t } = useTranslation()
	const classes = useStyles({ isRtlDirection })

	const createOptionContent = (content: string) => (
		<span>
			{isSummary && (
				<span className={classes.placeholder}>{t('debrief.category')}</span>
			)}
			<span className={isSummary ? classes.bold : ''}>{content}</span>
		</span>
	)

	const dir = isRtlDirection ? 'rtl' : 'ltr'
	const selectStyles = {
		icon: disabled ? classes.iconDisabled : '',
	}
	const formControlStyles = disabled ? classes.disabled : classes.control
	return (
		<Box sx={{ minWidth: 120 }}>
			<FormControl className={formControlStyles} fullWidth>
				<Select
					classes={selectStyles}
					dir={dir}
					IconComponent={() => <div></div>}
					disabled={false}
					labelId='demo-simple-select-label'
					id='demo-simple-select'
					defaultValue={currentSelection}
					value={currentSelection}
					onChange={handleChange}
				>
					{selectMenuItems.map(({ value, createOptionContentText }) => (
						<MenuItem
							key={value}
							dir={dir}
							value={value}
							defaultValue={currentSelection as any}
						>
							{createOptionContent(createOptionContentText)}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Box>
	)
}

const useStyles = makeStyles<
	IThemeOptions & Theme,
	{ isRtlDirection: boolean }
>(({ palette }) => ({
	bold: {
		fontWeight: 600,
	},
	placeholder: {
		color: '#8D9296',
	},
	disabled: {
		'& .MuiOutlinedInput-root': {
			marginRight: ({ isRtlDirection }) => (isRtlDirection ? '-13px' : '0'),
			pointerEvents: 'none',
			'& .MuiOutlinedInput-notchedOutline': {
				border: '1px solid transparent',
			},
		},
		'& >div > div': {
			padding: '0',
		},
	},
	control: {
		width: '85% !important',
		'& .MuiOutlinedInput-root': {
			'& div': {
				padding: '8px 8px 5px 8px !important',
			},
			'& .MuiOutlinedInput-notchedOutline': {
				border: `2px solid ${palette.debrief.main} !important`,
			},
		},
	},

	iconDisabled: {
		display: 'none !important',
	},
}))
