import { ChangeEvent, KeyboardEvent, useMemo, useState, useContext } from 'react'
import { ClickAwayListener } from '@material-ui/core'
import useStyles from './styled'

import { AppDirectionContext } from 'context/languages-context'

import plusIcon from 'assets/plusIcon.svg'

type Props = {
    botAnswer: string
    title: string
    saveDescription: (answer: string) => void
}
const BotflowAnswer = ({ botAnswer, title, saveDescription }: Props) => {
    const { isRtlDirection } = useContext(AppDirectionContext)
    const classes = useStyles({ isRtlDirection })

    const [showInputField, setShowInputField] = useState<boolean>(false)
    const [description, setDescription] = useState<string>(botAnswer)

    const openInputField = () => setShowInputField(prev => !prev)

    const handleChangeDescription = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setDescription(value)
    }
    const handleEnterPressed = ({ key }: KeyboardEvent<HTMLInputElement>) => {
        if (key === 'Enter') return closeInputField()
    }

    const closeInputField = () => {
        setShowInputField(prev => !prev)
        saveDescription(description)
    }

    const inputField = <ClickAwayListener onClickAway={closeInputField}>
        <input
            type='text'
            autoFocus
            className={classes.inputField}
            onKeyDown={handleEnterPressed}
            onChange={handleChangeDescription}
        /></ClickAwayListener>

    const plusBtn = <img onClick={openInputField} src={plusIcon} />

    const child = useMemo(() => (!description?.length ? plusBtn : description), [description])

    return (
        <div className={classes.analysis}>
            <div>{title}</div>

            {!showInputField
                ? <p className={classes.analysisAnswer}>
                    {child}
                </p>
                : inputField
            }

        </div>
    )
}

export default BotflowAnswer