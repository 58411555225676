import {
	FC,
	useState,
	useContext,
	ChangeEvent,
	KeyboardEvent,
	useRef,
	useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'

import { AppDirectionContext } from 'context/languages-context'

import { useStyles } from '../useStyles'
import useInnerStyles from './innerStyles'

import { UseClickActions } from 'components/Bot/useHooks'
import { BeforeSendOutputLogic } from './useLogic'
import BotOutput, { Color, OkayIcon } from 'components/BotOutput/index'
import useModalDescription from './useModalDescription'
import { Loader } from 'components/common/Loader'
import UserAvatar from 'components/common/Avatar'
import useExpansions from './useExpansion'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'

import { OutputWrapperProps } from 'interface/bot'
import { OutputType } from '../index'
import { ExpansionsEnum } from './useExpansion'

import plusIcon from 'assets/plusIcon.svg'
import okayIcon from 'assets/okayIcon.svg'
import CheckSharpIcon from '@material-ui/icons/CheckSharp'
import RenderOneOnOneReviewButton from 'components/BotOutput/RenderOneOnOneReviewButton'
import { ExpantionInput } from 'components/ExpantionInput'

interface BeforeSendOutput extends OutputWrapperProps {
	allowInputWrite?: boolean
	outputType?: OutputType
	isPraise: boolean
	currentOutputId?: string
	isFeedBackProcess?: boolean
	impactsExpansion?: Array<string>
	contributionExpansion?: string
	width?: string
	readed?: boolean
}

const getTitles = (isPraise = false) => {
	if (isPraise) {
		return {
			toRecipient: 'The_employee_you_are_sending_praise',
			eventTitleDescription: 'What_the_praise_is_about',
			contributionDescription: 'contribution_to_success_praise',
			valueDescription: 'contribution_to_company_values',
		}
	}

	return {
		toRecipient: 'The_employee_you_are_sending_feedback',
		eventTitleDescription: 'What_the_feedback_is_about',
		contributionDescription: 'contribution_to_success_feedback',
		valueDescription: 'impact_on_company_values',
	}
}

const isOutputEditableCheck = (outputType?: OutputType) => {
	const isPraiseDraft = outputType === OutputType.PraiseDraft
	const isFeedbackDraft = outputType === OutputType.FeedbackDraft
	const isPraiseView = outputType === OutputType.PraiseViewing
	const isFeedbackView = outputType === OutputType.FeedbackViewing

	return !isPraiseDraft && !isFeedbackDraft && !isPraiseView && !isFeedbackView
}

const getColor = (isPraise: boolean) => (isPraise ? Color.green : Color.red)

const BeforeSendOutput: FC<BeforeSendOutput> = props => {
	const {
		allowInputWrite = false,
		FREE_TEXT,
		outputType,
		isPraise,
		currentOutputId,
		CONTEXT,
		receiver,
		isFeedBackProcess,
		CONTRIBUTION,
		sender,
		DATE,
		FREE_TEXT_LABEL,
		IMPACTS,
		VALUES,
		impactsExpansion: impactsExpansionData = [],
		contributionExpansion: contributionExpansionsData = '',
		handleToggleReview,
		width,
		readed,
	} = props

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const innerStyles = useInnerStyles({ isRtlDirection, readed })
	const { t } = useTranslation()

	const isDraftOutput =
		outputType === OutputType.FeedbackDraft ||
		outputType === OutputType.PraiseDraft

	const {
		onChangeExpansion,
		saveNewExpansion,
		contributionExpansion = '',
		impactsExpansion = [''],
	} = useExpansions(
		currentOutputId!,
		isDraftOutput,
		impactsExpansionData,
		contributionExpansionsData,
		isPraise
	)

	const { sendPraiseOutput, sendFeedbackOutput, closeAction, deleteOutput } =
		UseClickActions(
			isPraise,
			currentOutputId,
			outputType,
			CONTEXT,
			contributionExpansion,
			impactsExpansion
		)

	const logicResult = BeforeSendOutputLogic({
		sendPraiseOutput,
		sendFeedbackOutput,
		isPraise,
		recipientId: receiver?._id,
		isFeedBackProcess,
	})

	const {
		toggleModal,
		setIsOnWall,
		loading,
		alertMessage,
		canSendFeedback,
		handleClick,
		isOnWall,
		isModalOpen,
	} = logicResult

	const { ContributionExplanationModal, UrgencyModalChild } =
		useModalDescription({ sender, receiver, sendFeedbackOutput, toggleModal })

	const shouldShowPraiseModalChild = isPraise && !CONTRIBUTION
	const modalChild = shouldShowPraiseModalChild
		? ContributionExplanationModal
		: UrgencyModalChild

	const handleCheckboxChange = () => {
		if (!CONTRIBUTION) {
			toggleModal()
			return
		}

		setIsOnWall(prev => !prev)
	}
	const handleExpationChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChangeExpansion(ExpansionsEnum.CONTRIBUTION, e)
	}

	const isContributionChanged =
		contributionExpansion.trim() !== '' &&
		contributionExpansion !== contributionExpansionsData

	const isImpactDescriptionChanged = (key: number) =>
		impactsExpansion![key]?.trim() !== '' &&
		impactsExpansionData![key] !== impactsExpansion[key]

	const isEditable = isOutputEditableCheck(outputType)

	if (loading) return <Loader />
	const {
		toRecipient,
		eventTitleDescription,
		contributionDescription,
		valueDescription,
	} = getTitles(isPraise)
	const color = getColor(isPraise)
	console.log({ canSendFeedback })
	return (
		<>
			<BotOutput width={width}>
				{!isFeedBackProcess && <BotOutput.Header isBeforeSned />}
				<BotOutput.ContextWrapper>
					<div className={classes.greetings}>
						<div className={classes.greetingSender}>
							{t('hi', { username: sender?.personalInfo?.firstName })}
							<UserAvatar className={classes.avatarStyles} userInfo={sender} />
						</div>

						<div className={classes.toRecipient}>
							{t(toRecipient)}

							<div className={classes.recepientName}>
								{receiver?.personalInfo?.firstName}
							</div>

							<UserAvatar className={classes.avatarStyles} userInfo={receiver} />
						</div>
					</div>

					<BotOutput.EventDate time={DATE} isPraise={isPraise} />

					<div className={classes.eventTitleContainer}>
						{t(eventTitleDescription)}
						<div className={classes.eventTitle}>{CONTEXT}</div>
					</div>

					<BotOutput.Text
						canEdit={isEditable}
						label={FREE_TEXT_LABEL}
						text={FREE_TEXT}
					/>

					{CONTRIBUTION && (
						<div className={classes.successContributionContainer}>
							<div>
								{t(contributionDescription, {
									userName: receiver?.personalInfo?.firstName,
									interpolation: { escapeValue: false },
								})}
							</div>
							<BotOutput.ContextPoints text={CONTRIBUTION}>
								<OkayIcon color={color} />
							</BotOutput.ContextPoints>

							{allowInputWrite ? (
								<div className={classes.expanstionSection}>
									<img src={plusIcon} className={classes.plusButton} alt='plusIcon' />
									<ExpantionInput
										onChange={handleExpationChange}
										value={contributionExpansionsData}
									/>
									{isContributionChanged && (
										<CheckSharpIcon
											onClick={() =>
												isDraftOutput && saveNewExpansion(ExpansionsEnum.CONTRIBUTION)
											}
											fontSize='medium'
										/>
									)}
								</div>
							) : (
								contributionExpansionsData && (
									<BotOutput.Text label='' text={contributionExpansionsData} />
								)
							)}
						</div>
					)}

					{IMPACTS.length ? (
						<div className={classes.furtherImpactContainer}>
							<div>{t('further_affect')}</div>

							{IMPACTS.map((impact, key) => (
								<>
									<BotOutput.ContextPoints key={key} text={impact}>
										<OkayIcon color={color} />
									</BotOutput.ContextPoints>

									{allowInputWrite ? (
										<div className={classes.expanstionSection}>
											<img src={plusIcon} className={classes.plusButton} alt='plusIcon' />
											{/* <ExpantionInput onChange={handleExpationChange} /> */}
											{/*The input bellow Should be a ExpantionInput component  as above */}
											<input
												onChange={e => onChangeExpansion(ExpansionsEnum.IMPACTS, e, key)}
												type='text'
												defaultValue={impactsExpansionData![key]}
												className={classes.expanstionInput}
											/>

											{isImpactDescriptionChanged(key) && (
												<CheckSharpIcon
													onClick={() =>
														isDraftOutput && saveNewExpansion(ExpansionsEnum.IMPACTS)
													}
													fontSize='medium'
												/>
											)}
										</div>
									) : (
										impactsExpansionData![key] && (
											<BotOutput.Text label='' text={impactsExpansionData![key]} />
										)
									)}
								</>
							))}
						</div>
					) : null}

					{VALUES && VALUES.length > 0 ? (
						<div className={classes.contributionCompanyValuesContainer}>
							<div>{t(valueDescription)}</div>

							{VALUES.map((value: any, key: any) => (
								<div key={key} className={classes.textWrapper}>
									<OkayIcon color={color} />

									<div className={classes.value}>
										{t(isPraise ? 'conduct_value' : 'conduct_value_feedback', {
											username: receiver?.personalInfo?.firstName,
											value: value,
											interpolation: { escapeValue: false },
										})}
									</div>
								</div>
							))}
						</div>
					) : null}

					{!isFeedBackProcess && (
						<div className={classes.actionContainer}>
							<div className={classes.proceedText}>
								{t('How_would_you_like_to_proceed')}
							</div>

							<BotOutput.ActionsSections
								alertMessage={alertMessage}
								isDisabled={!isPraise && !canSendFeedback.canSend}
								isDraftDisabled={isOnWall}
								disabledReason={canSendFeedback.reason}
								isPraise={isPraise}
								senderName={receiver?.personalInfo.firstName}
								closeAction={closeAction}
								isDraftOutput={isDraftOutput}
								deleteAction={deleteOutput}
								handleSendOutput={(isDraft: boolean) => handleClick(isDraft)}
							/>

							{isPraise && (
								<div className={classes.checkboxWrapper}>
									<input
										id='checkbox-input'
										type='checkbox'
										checked={isOnWall}
										onChange={handleCheckboxChange}
									/>

									<label style={{ fontSize: '1rem', fontWeight: 'normal' }}>
										{t('show_on_wall')}
									</label>
								</div>
							)}
						</div>
					)}
				</BotOutput.ContextWrapper>
			</BotOutput>

			{(!isPraise || isFeedBackProcess) && (
				<RenderOneOnOneReviewButton
					{...{ styles: innerStyles, handleClick: handleToggleReview }}
				/>
			)}

			{!isFeedBackProcess && (
				<ConfirmModalSB
					removeButtons
					closeButtonText={t('ok_got_it')}
					onClose={toggleModal}
					isOpen={isModalOpen}
					child={modalChild}
				/>
			)}
			{/* </div> */}
		</>
	)
}

export default BeforeSendOutput

// type ExpantionInput = {
// 	value?: string
// 	onChange: (e: ChangeEvent<HTMLInputElement>) => void
// }

// export const ExpantionInput: FC<ExpantionInput> = ({ onChange, value }) => {
// 	const { isRtlDirection } = useContext(AppDirectionContext)

// 	const classes = useStyles({ isRtlDirection })

// 	const cursor = useRef<HTMLInputElement>(null)

// 	useEffect(() => {
// 		if (!cursor.current) return

// 		cursor.current.value = value!
// 	}, [cursor, value])

// 	return (
// 		<input
// 			onChange={onChange}
// 			type='text'
// 			ref={cursor}
// 			autoFocus
// 			value={cursor?.current?.value}
// 			// defaultValue={value}
// 			className={classes.expanstionInput}
// 		/>
// 	)
// }
