import { useState } from "react"
import { useTranslation } from "react-i18next"

import './style.css'

import colors from "utils/colors"

const isTitleType = (option: TitlesType | string): option is TitlesType => {
    return (option as TitlesType)?.title_id !== undefined
}

type TitlesType = { title: string, title_id: string }

type TitlesOptionsType = {
    options?: TitlesType[] | string[]
    onSelect: (_id: string) => void
}
const ModalDropdownOptions = ({ options, onSelect }: TitlesOptionsType) => {
    const [selectedOption, setSelectedOption] = useState('')

    const { t } = useTranslation()

    const handleSelect = (title_id: string) => {
        setSelectedOption(title_id)
        onSelect(title_id)
    }

    return (
        <>
            {!options?.length && <p style={{ color: colors.red }}>{t('please_create_title')}</p>}

            {!!options?.length && <> <p className='title-label'>{t('choose_title_label')}</p>

                <select value={selectedOption}
                    onChange={(e) => handleSelect(e.target.value)}
                    className='titles-dropdown'
                >
                    <option value="" placeholder={t(`choose_title`)}></option>


                    {options?.map(option => {
                        if (isTitleType(option)) {
                            return (
                                <option key={option.title} value={option.title_id}>
                                    {option.title}
                                </option>
                            )
                        }
                        else {
                            return (
                                <option key={option} value={option}>
                                    {option}
                                </option>)
                        }
                    })}

                </select>
            </>}
        </>
    )
}

export default ModalDropdownOptions