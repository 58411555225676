import { AppDirectionContext } from 'context/languages-context'
import { ChangeEvent, FC, useContext, useEffect, useRef } from 'react'
import { useStyles } from './useStyles'

type ExpantionInput = {
	value?: string
	onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const ExpantionInput: FC<ExpantionInput> = ({ onChange, value }) => {
	const { isRtlDirection } = useContext(AppDirectionContext)

	const classes = useStyles({ isRtlDirection })

	const cursor = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (!cursor.current) return

		cursor.current.value = value!
	}, [cursor, value])

	return (
		<input
			onChange={onChange}
			type='text'
			ref={cursor}
			autoFocus
			value={cursor?.current?.value}
			// defaultValue={value}
			className={classes.expanstionInput}
		/>
	)
}
