import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
	get_user_base_insight,
	toggle_task_should_show,
	load_more_insights,
} from 'redux/company/company-actions'
import { getUserBaseInsights } from 'redux/company/company.selector'

import Insight from './BaseInsight'
import BloackgesStatus from './BlockagesStatus'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'

import { TaskSection } from 'enums/task/tasks.enum'
import { userSelector } from 'redux/user/user-selector'

export const ConcentrationTasksAtDashboard = () => {
	const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
	const [currentTask, setCurrentTask] = useState({
		task_id: '',
		includeUser: false,
	})

	const user = useSelector(userSelector)
	const { tasks } = useSelector(getUserBaseInsights)

	const { _id: employee_id, company_id } = user
	const payload = { company_id, employee_id }

	useEffect(() => {
		if (!user) return
		dispatch(get_user_base_insight({ company_id, employee_id }))
	}, [company_id])

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const handleLazyLoad = (pageNumber?: number, type?: TaskSection) => {
		if (shouldLoadMore(type)) return
		dispatch(load_more_insights({ ...payload, pageNumber, type }))
	}
	const shouldLoadMore = (type?: TaskSection) => {
		if (type === TaskSection.GENERAL) {
			return tasks.length < 10
		}
	}

	const handleToggleTask = (task_id: string, includeUser: boolean) => {
		setCurrentTask({ task_id: task_id, includeUser: includeUser })
		setOpenConfirmModal(true)
	}

	const modalConfirmAction = () => {
		dispatch(
			toggle_task_should_show({ ...payload, task_id: currentTask.task_id })
		)
	}

	const getModalText = (): string => {
		// TODO: Text translation is not well.
		const actionItemText = currentTask.includeUser
			? t('sure_you_want_to_done_lesson')
			: t('sure_you_want_restore_task')

		return actionItemText
	}

	return (
		<>
			<BloackgesStatus />

			<Insight
				toggleShowingTask={handleToggleTask}
				employee_id={employee_id}
				tasks={tasks}
				containerDescription={TaskSection.GENERAL}
				loadMoreTasks={(pageNumber: number) =>
					handleLazyLoad(pageNumber, TaskSection.GENERAL)
				}
			/>

			<ConfirmModalSB
				closeButtonText={t('back')}
				confirmButtonText={t('done')}
				onClose={() => setOpenConfirmModal(false)}
				onConfirm={modalConfirmAction}
				isOpen={openConfirmModal}
				text={getModalText()}
			/>
		</>
	)
}
