import { ChangeEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme } from '@material-ui/core'
import { IThemeOptions } from 'theme'
import { useStyles } from '../../Bot/SingleChoice/useStyles'

import { debriefOnProccesSelector } from 'redux/debrief/debrief-selector'

import achievedIcon from '../../../assets/achievedIcon.svg'
import unachievedIcon from '../../../assets/unachievedIcon.svg'

import TextArea from '../TextArea/TextArea'

import { Debrief, DebriefResult } from 'interface/debrief/debrief-interface'

interface Answer {
	title: string
	icon?: string
}
interface Props {
	setData: (data: Partial<Debrief>) => void
	setDisableNext: (status: boolean) => void
	isNextDisabled: boolean
}

const UNACHIVED = false
const ACHIVED = true
export const ExpectedResult = ({
	setData,
	setDisableNext,
	isNextDisabled,
}: Props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const innerClasses = useInnerStyles()

	const debrief = useSelector(debriefOnProccesSelector)

	const achivedValue = debrief?.expectedResult?.achieved
	const markedAchieved = achivedValue === true || achivedValue === false

	const [expectedResult, setExpectedResult] = useState<DebriefResult>({
		description: debrief?.expectedResult?.description ?? '',
		achieved: markedAchieved ? achivedValue : null,
	})

	const achieved = t('debrief.achieved_result')
	const unachieved = t('debrief.unachieved_result')

	const answers = [
		{ title: achieved, icon: achievedIcon },
		{ title: unachieved, icon: unachievedIcon },
	]
	useEffect(() => {
		setDisableNext(true)
	}, [])

	useEffect(() => {
		handleNextButtonStatus()
		setData({ expectedResult })
	}, [expectedResult])

	const update = (property: string, value: string | boolean) => {
		setExpectedResult(prev => {
			return {
				...prev,
				[property]: value,
			}
		})
	}
	const handleNextButtonStatus = () => {
		const achivedValue = expectedResult.achieved
		const markedAchieved = achivedValue === ACHIVED || achivedValue === UNACHIVED
		const answeredAll = markedAchieved && expectedResult.description
		if (answeredAll) {
			setDisableNext(false)
		} else if (!isNextDisabled) {
			setDisableNext(true)
		}
	}
	const handleAchievement = (value: string) => {
		update('achieved', value === achieved)
	}

	const handleResultValue = ({
		target: { value },
	}: ChangeEvent<HTMLTextAreaElement>) => {
		update('description', value)
	}

	const getAnswerClassName = (answerValue: string) => {
		return (answerValue === achieved && expectedResult.achieved) ||
			(answerValue === unachieved && expectedResult.achieved === false)
			? `${classes.answerCard} ${innerClasses.answer} ${innerClasses.marked}`
			: `${classes.answerCard} ${innerClasses.answer}`
	}

	return (
		<div className={`${classes.singleChoiceWrapper} ${innerClasses.wrapper}`}>
			<div className={innerClasses.textAreaWrapper}>
				<TextArea
					autoFocus
					maxLength={300}
					defaultValue={expectedResult.description}
					handleChange={handleResultValue}
					placeholder={t('debrief.write_briefly')}
				/>
			</div>

			<div className={innerClasses.answersWrapper}>
				{answers.map((answer: Answer) => {
					const answerClassName = getAnswerClassName(answer.title)

					return (
						<div
							className={answerClassName}
							onClick={() => handleAchievement(answer.title)}
						>
							{answer.icon && (
								<img className={innerClasses.answerIcon} src={answer.icon} />
							)}

							{answer.title === achieved
								? t('debrief.the_achieved_result')
								: t('debrief.the_unachieved_result')}
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default ExpectedResult
const useInnerStyles = makeStyles<IThemeOptions & Theme>(
	({ breakpoints, palette }) => ({
		wrapper: {
			display: 'flex',
			height: '100%',
			maxHeight: '400px',
			paddingBottom: '5%',
		},
		textAreaWrapper: {
			display: 'flex',
			width: '95%',
			minHeight: '80px',
			height: '40%',
			margin: '0 auto auto auto',
		},
		answersWrapper: {
			height: '45%',
		},
		answer: {
			'&:hover': {
				borderColor: palette.debrief.main,
				boxShadow: `5px 5px 10px ${palette.debrief.shadow}`,
			},
			minHeight: '80px',
			maxHeight: '120px',

			height: '40%',
			[breakpoints.down('sm')]: {
				minHeight: '60px',
			},
		},
		answerIcon: {
			marginInlineEnd: '5%',
		},
		marked: {
			border: `2.8px solid ${palette.debrief.main}`,
			boxShadow: `5px 5px 10px ${palette.debrief.shadow}`,
		},
	})
)
