import { FC, useRef, useLayoutEffect, useCallback } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useSelector } from 'react-redux'

import { userSelector } from 'redux/user/user-selector'

import { ChatBotCardWrapper } from './chatBotCard'
import { RenderChatCard } from './RenderChatCard'
import { ChatPresets, PHASE, Options } from './InitialChatPreset'

import { ChatAnswer } from './index'
import { useTranslation } from 'react-i18next'
import { debriefOnProccesSelector } from '../../redux/debrief/debrief-selector'
import { Debrief } from '../../interface/debrief/debrief-interface'

type ChatBotPreviousAnswerProps = {
	answers: ChatAnswer[]
	currentPhase: PHASE
	presetData: any[]
	summary: Record<string, string | string[]>
	handlePresetClick: (preset: string, options?: Options) => void
}

export const ChatBotAnswers: FC<ChatBotPreviousAnswerProps> = props => {
	const { presetData, answers, currentPhase, summary, handlePresetClick } = props

	const { t } = useTranslation()
	const debriefOnProcces = useSelector(debriefOnProccesSelector)
	const { expectedResult } = debriefOnProcces as Debrief

	const chatBotPreviousAnswerContainer = useRef<HTMLDivElement | null>(null)

	const {
		personalInfo: { imgSrc },
	} = useSelector(userSelector)

	useLayoutEffect(() => {
		handleScrollBottom()
	}, [answers, presetData])

	const handleScrollBottom = useCallback(() => {
		if (chatBotPreviousAnswerContainer.current && answers.length) {
			const { current } = chatBotPreviousAnswerContainer
			const { scrollHeight } = current

			current.scrollTo({
				top: scrollHeight,
				behavior: 'smooth',
			})
		}
	}, [answers])

	const classes = useStyles()

	return (
		<div
			ref={chatBotPreviousAnswerContainer}
			className={classes.chatBotPreviousAnswerContainer}
		>
			<RenderChatCard
				isChatQuestion
				text={t(
					expectedResult?.achieved
						? 'achivedInitailQuesion'
						: 'unAchivedInitailQuesion'
				)}
			/>

			{answers.map((answer: ChatAnswer, index) => {
				return (
					<ChatBotCardWrapper
						key={`${answer}-${index}`}
						userImg={imgSrc}
						answer={answer}
						summary={summary}
						isLastAnswer={answers.length - 1 === index}
						handleScrollBottom={handleScrollBottom}
						presetData={presetData}
						currentPhase={currentPhase}
						handlePresetClick={handlePresetClick}
					/>
				)
			})}
			{currentPhase === PHASE.ACTIONS && (
				<ChatPresets
					dataArr={presetData}
					currentPhase={currentPhase}
					handlePresetClick={handlePresetClick}
				/>
			)}
		</div>
	)
}

const useStyles = makeStyles({
	chatBotPreviousAnswerContainer: {
		height: '100%',
		overflowY: 'auto',

		padding: '0 32px',
		width: '100%',
		boxSizing: 'border-box',

		'-ms-overflow-style': 'none',
		scrollbarWidth: 'none',

		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
})
