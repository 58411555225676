import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core'

export type Styles = {
    isRtlDirection?: boolean
}
export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
    searchInput: {
        width: 250,
        height: 29,
        borderRadius: 20,
        border: "1px solid lightgrey",
        color: '#000',
        backgroundColor: '#f8f4f4',
        float: ({ isRtlDirection }) => (isRtlDirection ? 'left' : 'right'),
        paddingLeft: ({ isRtlDirection }) => (isRtlDirection ? 0 : 8),
        paddingRight: ({ isRtlDirection }) => (isRtlDirection ? 8 : 0),
        fontSize: 16,
        marginTop: 45,
        '&:hover': {
            backgroundColor: '#e4dfdf'
        },
        '&:focus': {
            outline: 'none'
        },
        [breakpoints.down('sm')]: {
            width: 200,
        },
    

    },
}))
