import { makeStyles, Theme } from '@material-ui/core/styles'
export default makeStyles<Theme>(({ breakpoints }) => ({
	botHeaderWrapper: {
		maxHeight: '14vh',

		[breakpoints.down('sm')]: {
			height: 0,
		},
	},
	conatainer: {
		width: '96vw',
		padding: '0 2%',
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '31px',
		alignItems: 'center',

		[breakpoints.down('sm')]: {
			boxSizing: 'border-box',
			margin: '0 0 0 0',
			alignItems: 'flex-start',

			'& >:last-child': {
				// close icon element
				position: 'absolute',
			},
		},
	},

	logo: {
		cursor: 'pointer',
		width: '77px',
		height: '89px',
		[breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	progressBar: {
		width: '100%',
		maxWidth: '335px',

		[breakpoints.down('sm')]: {
			display: 'none',
			width: '50%',
		},
	},
	progressBarWrpaper: {
		zIndex: -1,

		position: 'relative',
		top: '-53px',

		width: '100%',

		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		[breakpoints.down('sm')]: {
			flexDirection: 'column-reverse',
		},
	},
	percentSign: {
		[breakpoints.down('sm')]: {
			marginTop: '10px',
			marginLeft: '15%',
		},
	},
	pregressContainer: {
		margin: '19px 0 14px',
		'& > div >:first-child > div': {
			minHeight: '5px',
		},
		'& > div >:nth-child(2)': {
			width: 0,
			paddingLeft: 0,
		},
	},
}))
