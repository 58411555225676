import { makeStyles } from '@material-ui/core'
import { Theme } from '@mui/material'

export type Styles = {
    matchPointColor?: string
    isRtlDirection: boolean
    isLeader?: boolean
}
export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
    lessonContainer: {
        borderLeft: ({ isRtlDirection, matchPointColor }) =>
            isRtlDirection ? 'none' : `13px solid ${matchPointColor}`,
        borderRight: ({ isRtlDirection, matchPointColor }) =>
            isRtlDirection ? `13px solid ${matchPointColor}` : 'none',
        width: '96%',
        height: /*with Input element: '45px', with textarea element:*/'fit-content',
        minHeight: '45px',
        fontFamily: 'Sora, sans-serif',
        fontSize: '15px',
        fontWeight: 500,

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
        margin: '0 auto 1px auto',
        flexDirection: 'row',

        color: 'grey',
        backgroundColor: '#f5f4f4e8',
    },
    closeBtn: {
        width: '25px',
        height: '25px',
        cursor: 'pointer',
        [breakpoints.down('xs')]: {
            width: '20px',
            height: '20px',
        },
    },
    customLessonSection: {
        width: '24%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [breakpoints.down('xs')]: {
            width: '29%',
        },
    },
    mpInputField: {
        height: '100%',
        width: 'fit-content',
        outline: 'none',
        borderTop: 'none',
        borderRight: 'none',
        borderBottom: '1px solid',
        borderLeft: 'none',
        marginLeft: '1%',
        fontSize: '15px',
        fontFamily: 'Sora, sans-serif',
        caretColor: ({ matchPointColor }) =>
            matchPointColor ? matchPointColor : 'grey',
        borderBottomColor: ({ matchPointColor }) =>
            matchPointColor ? matchPointColor : 'grey',
        [breakpoints.down('xs')]: {
            marginLeft: 0,
        },
    },
    backgroundDeleteIcon: {
        backgroundColor: ({ matchPointColor }) =>
            matchPointColor ? matchPointColor : '#8EC5FC',
        backgroundImage: 'linear - gradient(62deg, #8EC5FC 0 %, #E0C3FC 100 %)',
    },
    deleteSpan: {
        position: 'relative',
        right: '0',
        width: '24px',
    },
    transferIcon: {
        color: 'white',
        cursor: 'pointer',
        
    }
}))
