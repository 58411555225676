import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FilledInput from '@mui/material/FilledInput'

type FormProps<T> = {
	isRtl?: boolean
	width?: string
	label?: string
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	values: T
}
export const CreateForm = <T extends Record<string, string>>({
	onChange,
	isRtl,
	label,
	width,
	values,
}: FormProps<T>) => {
	const keys = Object.keys(values)
	return (
		<>
			{keys.map((key: string) => {
				return (
					<div style={{ width: width ? width : '50%' }}>
						<FormControl fullWidth sx={{ m: 1 }} variant='filled'>
							<InputLabel htmlFor='filled-adornment-amount'>{key ?? label}</InputLabel>
							<FilledInput
								style={{ direction: isRtl ? 'rtl' : 'ltr' }}
								autoComplete='off'
								name={key}
								value={values[key] ?? ''}
								onChange={onChange}
								id='filled-adornment-amount'
							/>
						</FormControl>
					</div>
				)
			})}
		</>
	)
}