import React, { useState, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@mui/material'
import Tooltip from '@material-ui/core/Tooltip'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'

import { AppDirectionContext } from 'context/languages-context'

import { employeesSelector } from 'redux/employee/employee.selector'

import TableRow, { TableCellProps } from './TableRow'
import ManualInputs from './ManualInputs'

import { ILicenses } from 'interface/Licenses'
import { License } from 'enums/License/License.enum'
import deleteIcon from 'assets/deleteIcon.svg'
import { Roles } from 'enums/user/user-roles'
import editIcon from 'assets/editIcon.svg'

import IUser, { INewUserInitState } from 'interface/user/user-interface'
import { userSelector } from 'redux/user/user-selector'

type Props = {
	onError: (e: string) => void
	remainingLicense: ILicenses
	height?: string
	employees: Array<IUser>
	handleOpenModal: (employeeId: string) => void
	handleAddNewUser: (user: INewUserInitState) => void
	handleEditUser: (user: INewUserInitState, id: string) => void
	handleRestore: (id: string) => void
}

export const TABLE_CELL_PADDING = '0.5rem'

const useStyles = makeStyles<Theme, { isRtlDirection: Boolean }>({
	tableContainer: {
		width: '100%',
	},
	tableWrapper: {
		width: '100%',
		position: 'static',
		overflowY: 'scroll',
	},

	tableData: {
		fontSize: 14,
		textAlign: 'left',
		color: '#46484A',
		whiteSpace: 'nowrap',
		display: 'block',
		width: 'auto',
		maxWidth: 177,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	icon: {
		cursor: 'pointer',
		margin: 0,
		'&:not(:last-child)': {
			marginRight: ({ isRtlDirection }) => (isRtlDirection ? 0 : 28),
			marginLeft: ({ isRtlDirection }) => (isRtlDirection ? 28 : 0),
		},
	},
	disabledIcon: {
		cursor: 'no-drop',
	},
	overrides: {
		MuiTooltip: {
			tooltip: {
				fontSize: '2em',
				color: 'yellow',
				backgroundColor: 'red',
			},
		},
	},
	actionsCell: {
		width: '100%',
		display: 'flex',
	},
})
const CellWithTooltip = ({ content }: { content: string }) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	return (
		<Tooltip
			title={
				content?.length > 11 ? (
					<div style={{ fontSize: 20 }}>{content.length > 10 && content}</div>
				) : (
					''
				)
			}
		>
			<div className={classes.tableData}>{content}</div>
		</Tooltip>
	)
}
const ActionsCell = ({
	id,
	handleOpenModal,
	handleEditMode,
	handleRestorEmployee,
	active
}: {
	id: string
	handleOpenModal: (id: string) => void
	handleEditMode: (id: string) => void
	handleRestorEmployee: (id: string) => void
	active: boolean
}) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const { statusInfo: { isSuperAdmin } } = useSelector(userSelector)
	return (
		<div className={classes.actionsCell}>
			<img
				className={classes.icon}
				src={editIcon}
				alt='edit icon'
				onClick={() => handleEditMode(id)}
			/>
			{active && <img
				className={classes.icon}
				src={deleteIcon}
				alt='delete icon'
				onClick={() => handleOpenModal(id)}
			/>}
			{!active &&
				<SettingsBackupRestoreIcon
					onClick={() => isSuperAdmin && handleRestorEmployee(id)}
					classes={{ root: classes.icon }}
					className={!isSuperAdmin ? [classes.disabledIcon, classes.icon].join(' ') : ''}
				/>}
		</div>
	)
}

const cellSizes = {
	firstNameCell: '2fr',
	lastNameCell: '2fr',
	titleCell: '3fr',
	emailCell: '4fr',
	mobileCell: '3fr',
	departmentCell: '3.5fr',
	subDepartmentCell: '3.5fr',
	directManagerCell: '4fr',
	licenseCell: '3fr',
	roleCell: '3fr',
	actionsCell: '1fr',
}

const mapEmployeeDataToCells = (
	employee: IUser,
	license: License,
	role: Roles,
	restoreEmployee: (employeeId: string) => void,
	handleOpenModal: (employeeId: string) => void,
	handleEditMode: (employeeId: string) => void,
	index: number
): Array<TableCellProps> => {
	const { securityInfo, statusInfo, _id, personalInfo } = employee
	const { firstName, lastName, mobile } = personalInfo
	const { title, department, subDepartment, directManager, active } = statusInfo
	const { email } = securityInfo

	const firstNameCell: TableCellProps = {
		content: <CellWithTooltip content={firstName} />,
		size: cellSizes.firstNameCell,
		active: active
	}
	const lastNameCell: TableCellProps = {
		content: <CellWithTooltip content={lastName} />,
		size: cellSizes.lastNameCell,
		active: active
	}
	const titleCell: TableCellProps = {
		content: <CellWithTooltip content={title} />,
		size: cellSizes.titleCell,
		active: active
	}
	const emailCell: TableCellProps = {
		content: <CellWithTooltip content={email} />,
		size: cellSizes.emailCell,
		active: active
	}
	const mobileCell: TableCellProps = {
		content: <CellWithTooltip content={mobile} />,
		size: cellSizes.mobileCell,
		active: active
	}
	const departmentCell: TableCellProps = {
		content: <CellWithTooltip content={department} />,
		size: cellSizes.departmentCell,
		active: active
	}
	const subDepartmentCell: TableCellProps = {
		content: <CellWithTooltip content={subDepartment} />,
		size: cellSizes.subDepartmentCell,
		active: active
	}
	const directManagerCell: TableCellProps = {
		content: <CellWithTooltip content={directManager} />,
		size: cellSizes.directManagerCell,
		active: active
	}

	const roleCell: TableCellProps = {
		content: <CellWithTooltip content={role?.toLocaleLowerCase()} />,
		size: cellSizes.roleCell,
		active: active
	}
	const licenseCell: TableCellProps = {
		content: <CellWithTooltip content={license} />,
		size: cellSizes.licenseCell,
		active: active
	}
	const actionsCell: TableCellProps = {
		content: (
			<ActionsCell
				handleRestorEmployee={restoreEmployee}
				handleEditMode={handleEditMode}
				handleOpenModal={handleOpenModal}
				id={_id}
				active={active}
			/>
		),
		size: cellSizes.actionsCell,
		active: active
	}

	return [
		firstNameCell,
		lastNameCell,
		titleCell,
		emailCell,
		mobileCell,
		departmentCell,
		subDepartmentCell,
		directManagerCell,
		roleCell,
		licenseCell,
		actionsCell,
	]
}

const CompanyBuilderTableData: React.FC<Props> = props => {
	const {
		onError,
		remainingLicense,
		employees,
		height,
		handleOpenModal,
		handleAddNewUser,
		handleEditUser,
		handleRestore
	} = props
	const allEmployees = useSelector(employeesSelector)
	const [AddNewRowIsOpen, setAddNewRowIsOpen] = useState<boolean>(false)
	const [employeeOnEdit, setEmployeeOnEdit] = useState<string>('')
	const { t } = useTranslation()
	useEffect(() => {
		setAddNewRowIsOpen(false)
	}, [employeeOnEdit])

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })

	const hasEmployees = employees.length > 0

	const toggleAddNewRow = () => {
		if (
			remainingLicense.gold === 0 &&
			remainingLicense.silver === 0 &&
			remainingLicense.bronze === 0
		) {
			onError(
				'Cant create new user. insufficient company licsense. contact Solve for additional license'
			)
			return
		}
		setAddNewRowIsOpen(prevState => {
			return !prevState
		})
	}

	const handleEditMode = (id: string) => {
		setEmployeeOnEdit(id)
	}

	const handleEdit = (user: INewUserInitState, id: string) => {
		setEmployeeOnEdit('')
		handleEditUser(user, id)
	}
	const handleRestoreEmployee = (id: string) => {
		handleRestore(id)
	}
	const managersEmails = [
		...new Set(
			allEmployees
				.filter(
					e => e.statusInfo.role === Roles.MANAGER || e.statusInfo.role === Roles.HR
				)
				.map(e => e.securityInfo.email)
		),
	]

	const departments = [
		...new Set(
			allEmployees.map(e => e.statusInfo.department).filter(d => d !== undefined)
		),
	]
	const subDepartments = allEmployees
		.map(e => {
			return {
				name: e.statusInfo.subDepartment,
				linkedWith: e.statusInfo.department,
			}
		})
		.filter(sd => sd !== undefined)
		.filter(
			(thing, index, self) => index === self.findIndex(t => t.name === thing.name)
		)
	const columns: Array<TableCellProps> = [
		{
			content: t('name'),
			size: cellSizes.firstNameCell,
		},
		{
			content: t('surname'),
			size: cellSizes.lastNameCell,
		},
		{
			content: t('work_title'),
			size: cellSizes.titleCell,
		},
		{
			content: t('email'),
			size: cellSizes.emailCell,
		},
		{
			content: t('mobile'),
			size: cellSizes.mobileCell,
		},
		{
			content: t('department'),
			size: cellSizes.departmentCell,
		},
		{
			content: t('sub_department'),
			size: cellSizes.subDepartmentCell,
		},

		{
			content: t('direct_manager'),
			size: cellSizes.directManagerCell,
		},
		{
			content: t('role'),
			size: cellSizes.roleCell,
		},
		{
			content: t('license'),
			size: cellSizes.licenseCell,
		},
		{
			content: '',
			size: cellSizes.actionsCell,
		},
	]

	return hasEmployees ? (
		<>
			<div
				style={{ height: height ? height : '60vh' }}
				className={classes.tableWrapper}
			>
				<TableRow
					cells={columns}
					AddNewRowIsOpen={AddNewRowIsOpen}
					onToggleAddNewRow={toggleAddNewRow}
					isHeader
				/>
				{AddNewRowIsOpen && (
					<ManualInputs
						cellSizes={cellSizes}
						remainingLicense={remainingLicense}
						subDepartments={subDepartments}
						departments={departments}
						managersEmails={managersEmails}
						onAdd={handleAddNewUser}
						onClose={() => setAddNewRowIsOpen(false)}
					/>
				)}
				{employees.map((employee, index) => {
					return employeeOnEdit === employee._id ? (
						<ManualInputs
							cellSizes={cellSizes}
							remainingLicense={remainingLicense}
							subDepartments={subDepartments}
							departments={departments}
							managersEmails={managersEmails}
							onEdit={handleEdit}
							currentUser={employee}
							onClose={() => setEmployeeOnEdit('')}
						/>
					) : (
						<TableRow
							cells={mapEmployeeDataToCells(
								employee,
								t(employee?.statusInfo?.license?.toLocaleLowerCase()),
								t(employee.statusInfo.role),
								handleRestoreEmployee,
								handleOpenModal,
								handleEditMode,
								index
							)}
						/>
					)
				})}
			</div>
		</>
	) : null
}

export default CompanyBuilderTableData
