import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useStyles from '../styles'

import GoldLicenseIcon from '../../../assets/GoldLicenseIcon.svg'
import { AppDirectionContext } from 'context/languages-context'
import SilverLicenseIcon from '../../../assets/SilverLicenseIcon.svg'
import BronzeLicenseIcon from '../../../assets/BronzeLicenseIcon.svg'

interface Props {
	onEdit?: boolean
	setValues?: (values: any) => void
	licenseValue?: {
		bronze: any
		gold: any
		silver: any
	}
	showTotal?: boolean
}
const initialLicense = {
	bronze: 0,
	silver: 0,
	gold: 0,
}
const Licenses = ({
	showTotal = true,
	licenseValue,
	setValues,
	onEdit,
}: Props) => {
	const [licensesState, setLicensesState] = useState(
		licenseValue ? { ...licenseValue } : initialLicense
	)

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection, licenseValue, onEdit })
	const { t } = useTranslation()

	useEffect(() => {
		setValues && setValues(licensesState)
	}, [licensesState])

	const onChangeHanlder = (e: any, type: string) => {
		setLicensesState(prevState => {
			return {
				...prevState,
				[type]: Number(e.target.value),
			}
		})
	}

	return (
		<div className={`${classes.flexCenter}  ${classes.licenses}`}>
			{showTotal && (
				<div className={classes.licensesTitle}>
					<h2 style={{ margin: '0' }}>
						{licenseValue
							? `Total Licenses-${licenseValue.silver + licenseValue.bronze + licenseValue.gold
							}`
							: 'Licenses'}
					</h2>
				</div>
			)}
			<div className={`${classes.flexCenter} ${classes.licenses}`}>
				<div className={classes.license}>
					<img className={classes.icon} src={BronzeLicenseIcon} alt='Bronze icon' />
					{t('bronze')}{' '}
					{licenseValue && !onEdit ? (
						`: ${licenseValue.bronze}`
					) : (
						<input
							onChange={e => onChangeHanlder(e, 'bronze')}
							className={classes.licenseInput}
							type='number'
							defaultValue={licensesState.bronze}
						/>
					)}
				</div>
				<div className={classes.license}>
					<img className={classes.icon} src={SilverLicenseIcon} alt='Silver icon' />
					{t('silver')}{' '}
					{licenseValue && !onEdit ? (
						`: ${licenseValue.silver}`
					) : (
						<input
							onChange={e => onChangeHanlder(e, 'silver')}
							className={classes.licenseInput}
							type='number'
							defaultValue={licensesState.silver}
						/>
					)}
				</div>
				<div className={classes.license}>
					<img className={classes.icon} src={GoldLicenseIcon} alt='Gold icon' />
					{t('gold')}{' '}
					{licenseValue && !onEdit ? (
						`: ${licenseValue.gold}`
					) : (
						<input
							onChange={e => onChangeHanlder(e, 'gold')}
							className={classes.licenseInput}
							type='number'
							defaultValue={licensesState.gold}
						/>
					)}
				</div>
			</div>
		</div>
	)
}

export default Licenses
