import { makeStyles, Theme } from '@material-ui/core'
import { IThemeOptions } from '../../../theme/index'

export const useStyles = makeStyles<IThemeOptions & Theme>(
	({ breakpoints }) => ({
		styledEvent: { textAlign: 'start', listStyleType: 'none', padding: '1%', wordBreak: 'break-word' },
		refreshButton: {
			margin: '1% auto 1% auto',
		},
		additionalInfo: {
			marginTop: '1%',

			'& >:last-child': {
				color: 'blue',
				cursor: 'pointer',
				textDecoration: 'underline',
			},
		},
		usersList: {
			position: 'absolute',
			top: 0,

			overflowY: 'auto',
			height: '71%',

			backgroundColor: '#80808008',
			padding: '1% 1% 1% 0',

			'& > ul': {
				margin: 0,
			},

			[breakpoints.down('sm')]: {
				position: 'relative',
			},
		},
		info: {
			position: 'relative',
			[breakpoints.down('sm')]: {
				display: 'flex',
				height: 'auto',
				borderRadius: '10px',
				padding: '2%',
				margin: '2% auto 2% auto',
				backgroundColor: 'rgb(166,163,198,0.1)',
				flexDirection: 'column',
				width: '90vw',
			},
		},
		infoBarData: {
			margin: '0 1% 0 1%',
			display: 'flex',
			position: 'relative',
			top: 0,
			minHeight: '50px',
			alignItems: 'center',
			height: '30px',
			[breakpoints.down('sm')]: {
				margin: '2% 1% 2% 1%',
			},
		},
		infoBarContent: {
			fontWeight: 600,
		},
		infoBar: {
			color: 'rgba(70, 72, 74, 1)',
			display: 'flex',
			width: '100%',

			justifyContent: 'center',
			[breakpoints.down('sm')]: {
				flexDirection: 'column',
			},
		},
		container: {
			width: '95vw',
			display: 'flex',
			flexDirection: 'column',
			[breakpoints.down('sm')]: {
				alignItems: 'center',
				margin: 'auto',
				flexDirection: 'column',
			},
		},
		borderTop: {
			borderTop: '2px solid rgba(224, 224, 224, 1)',
		},
		matchpointsContainer: {
			paddingTop: '1%',
			marginTop: '1%',
			height: '100%',
			width: '100%',
			display: 'flex',
			[breakpoints.down('sm')]: {
				flexDirection: 'column',
			},
		},
		transferIcon: {
			width: '30px',
			height: '30px',
		},
		exportModal: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-around',
			fontFamily: 'Sora, sens-serif',
			minWidth: '250px',
			width: '92.5%',
			padding: '15px',
		},
		title: {
			display: 'flex',
			justifyContent: 'space-between',
			'& > img': {
				width: '25px',
				cursor: 'pointer',
				padding: '8px'
			}
		},
		modalTitle: {
			padding: "6% 0",
			fontSize: '20px',
			fontWeight: 410,
			fontFamily: 'Sora, sens - serif',
		}
	})
)
