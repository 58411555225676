import { ChangeEvent, useEffect, useState } from 'react'

import useStyles from '../styles'

import { Contact } from 'interface/company/company-interface'

import trashIcon from 'assets/trashIcon.svg'

interface Props {
	errors?: Array<string>
	contacts?: Contact[]
	onInputChange: (contacts: any) => void
}
const contactsInputsData = [
	{ name: 'name', title: 'Name', type: 'text' },
	{ name: 'phone', title: 'Phone', type: 'number' },
	{ name: 'email', title: 'Email', type: 'email' },
	{ name: 'role', title: 'Role', type: 'text' },
]
const Contacts = ({ contacts, onInputChange, errors }: Props) => {
	const classes = useStyles({})

	const mapContactsRows = (contact?: Contact, idx?: number) => {
		return {
			id: idx ?? 1,
			rowKey: Math.floor(Math.random() * 100),
			name: contact?.name ?? '',
			phone: contact?.phone ?? '',
			email: contact?.email ?? '',
			role: contact?.role ?? '',
		}
	}
	const initialRows = contacts
		? contacts.map((contact: Contact, idx: number) => (
			mapContactsRows(contact, idx)
		))
		: [mapContactsRows()]

	const [formRows, setFormRows] = useState(initialRows)

	useEffect(() => {
		const updatedContacts = formRows.map(row => {
			return {
				name: row.name,
				email: row.email,
				phone: row.phone,
				role: row.role,
				rowKey: row.rowKey,
			}
		})

		onInputChange(updatedContacts)
	}, [formRows])

	const inputChangeHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
		const className = target.classList[0]
		const key: any = Number(className.split('/')[1])
		const property = className.split('/')[0]

		setFormRows(prevState => {
			const updatedRows = prevState.map(row => {
				if (row.rowKey === key) {
					return {
						...row,
						[property]: target.value,
					}
				} else {
					return row
				}
			})
			return updatedRows
		})
	}
	const handleDeleteContact = (row: Contact) => setFormRows(formRows.filter(fr => fr.id !== row.id))

	const handleAddNewContact = () => {
		setFormRows([
			...formRows,
			{
				id: formRows.length + 1,
				rowKey: Math.floor(Math.random() * 100),
				name: '',
				phone: '',
				email: '',
				role: '',
			},
		])
	}
	const getDefaultValue = (name: string, row: Contact) => {
		if (row.name === name) return row.name = name
	}

	const isInvalidInput = (name: string, rowKey: number) => (errors && errors.includes(`${name}/${rowKey}`))

	return (
		<div id='contacts' className={classes.contacts}>
			{formRows.map(row => {
				const id = row.id
				return (
					<>
						<div
							className={`${classes.contact} ${classes.flexCenter}`}
							key={row.rowKey}
						>
							{contactsInputsData.map(input => {

								const inputError = isInvalidInput(input.name, row.rowKey)

								return (
									<div className={classes.inputContainer} key={input.name}>

										<label htmlFor={`contact_${input.name}_${id}`}>{input.title}</label>
										<input
											style={{
												border: inputError ? '2px solid #E55757' : '2px solid #A5A5A5',
											}}
											onChange={e => inputChangeHandler(e)}
											className={`${input.name}/${row.rowKey}`}
											id={`contact_${input.name}_${id}`}
											type={input.type}
											placeholder={input.title}
											defaultValue={getDefaultValue(input.name, row)}
										/>
										<span>{inputError ? `invalid ${input.name}` : ''}</span>

									</div>
								)
							})}

							<button
								id='trashIcon'
								type='button'
								onClick={() => handleDeleteContact(row)}
								className={classes.trashIcon}
							>
								<img src={trashIcon} />
							</button>
						</div>
						<div className={classes.divider} />
					</>
				)
			})}
			<button
				type='button'
				id='addButton'
				className={classes.actionButton}
				onClick={handleAddNewContact}
			>
				<span className={classes.actionName}> + Add New Contact</span>
			</button>
		</div>
	)
}

export default Contacts
