export const filter = <T>(array: Array<T>, filterFn: (e: T) => boolean) => {
	const results = []
	for (let index = 0; index < array.length; index++) {
		const element = array[index]

		if (filterFn(element)) {
			results[results.length] = element
		}
	}
	return results
}
