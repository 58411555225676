import { Langs } from 'enums/app'
export interface IPraise {
	_id: string
	title: string
	description: string
	createdBy: string
	createdAt: string
	updatedAt: string
	recipient?: string
	sender?: string
	imageUrl: string
	coins?: number
}

export const PRAISE_SKIP_COUNT = 4

export type Praise = {
	recipient: User
	urgencyLevel: UrgencyLevel
}

type User = {}
enum UrgencyLevel {
	Low = 'low',
	Middle = 'middle',
	High = 'high',
	Urgent = 'urgent',
}
export enum QuestionType {
	InitialMessage = 'initial-message',
	TextArea = 'text-area',
	SingleChoice = 'single-choice',
	SingleChoiceAlt = 'single-choice-alt',
	MultipleChoice = 'multiple-choice',
	MultipleChoiceGrid = 'multiple-choice-grid',
	DateChoice = 'date-choice',
	RangeOption = 'range-option',
	Range = 'range',
	GoalsChoice = 'goals-choice',
	FirstQuestion = 'first-question',
	DatePickerChoice = 'date-picker-choice',
	FeedbackRangeQuestion = 'feedback-range',
	SingleChoiceAltRegular = 'single-choice-alt-regular',
	Reason = 'reason',
	UsersList = 'users-list-choice',
	CompanyValuesChoice = 'company-values-choice',
	ExpansionsImpacts = 'expansions-impacts',
	ExpansionsContribution = 'expansions-contribution'
}

type RangeQuestion<A, T> = BaseQuestion<A, T> & {
	type: QuestionType.Range
	min: number
	max: number
	step: number
	minText?: string
	maxText?: string
	valueToIllustrationMap?: Record<number, string>
}

type RangeOptionQuestion<A, T> = BaseQuestion<A, T> & {
	type: QuestionType.RangeOption
	nextQuestion?: string
	previousQuestion?: string
	options: Array<string>
}

export type QuestionValue = {
	title: string
	subtitle?: string
	icon?: string
	outputType?: number
	nextQuestion: string
}
export type Answers = Record<Langs, Array<QuestionValue>>
export type Text = Record<Langs, string>

export type BaseQuestion<A = any, T = any> = {
	_id: string
	backgroundIcon?: string
	title: T
	subtitle: T
	type: QuestionType
	skipable: boolean
	nextQuestion?: string
	previousQuestion?: string
	answers: A
}

export type Question<A, T> =
	| BaseQuestion<A, T>
	| RangeQuestion<A, T>
	| RangeOptionQuestion<A, T>
