import React, { FC, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import { readXlFile } from '../../../../utils/readXlFile'
import { useDispatch } from 'react-redux'
import IUser from 'interface/user/user-interface'
import { Roles } from 'enums/user/user-roles'
import { occurrencesOf } from 'utils/calcRemainingLicense'
import { create_employees } from 'redux/employee/employee-actions'
import { ILicenses } from 'interface/Licenses'
import { License } from 'enums/License/License.enum'
import { Langs } from 'enums/app'
import { IUserStatusInfo } from '../../../../interface/user/user-interface'
import { Employee, employeesMapIUser } from 'utils/employeesMapIUser'
const useStyles = makeStyles(() => ({
	container: {
		height: '85px',
		width: '456px',
		border: '1px dashed #0071C2',
		borderRadius: '13.6px',
		backgroundColor: '#F6F6F6',
		cursor: 'pointer',
	},
}))

type UploadFilePropsType = {
	remainingLicense?: ILicenses
	onError?: (error: string) => void
	closeModal: () => void
	company_id?: string
	companyLanguage: Langs
}
const hasUserExceedFromAmountPermitted = (
	currentDemoLicense: ILicenses,
	remainingLicense: ILicenses
) => {
	return (
		currentDemoLicense.silver > remainingLicense.silver ||
		currentDemoLicense.bronze > remainingLicense.bronze ||
		currentDemoLicense.gold > remainingLicense.gold
	)
}
const getLicenses = (demoLicense: Array<string>): ILicenses => {
	return {
		silver: occurrencesOf(License.silver, demoLicense),
		bronze: occurrencesOf(License.bronze, demoLicense),
		gold: occurrencesOf(License.gold, demoLicense),
	}
}
const UploadFile: FC<UploadFilePropsType> = props => {
	const { remainingLicense, closeModal, onError, company_id, companyLanguage } =
		props
	const classes = useStyles()
	const dispatch = useDispatch()
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone()
	const files = acceptedFiles.map(({ name }: File) => <li>{name}</li>)
	const handleChange = useCallback(
		async (event: React.ChangeEvent<HTMLInputElement>) => {
			const file = event.target.files![0]
			console.log({ file })
			readXlFile(file)
				.then(async (resultEmployees: any) => {
					const employees: Array<Employee> = resultEmployees
					const demoLicense = employees.map(e => e.license)
					const currentDemoLicense = getLicenses(demoLicense)
					if (remainingLicense) {
						if (
							hasUserExceedFromAmountPermitted(currentDemoLicense, remainingLicense)
						) {
							const msg = `employees rejected. increase company licenses and try again.(sum of employees licenses is higher then company paid licenses)`
							onError && onError(msg)
							return
						}
					}
					if (company_id) {
						const demoEmployees = employeesMapIUser(
							employees,
							company_id,
							companyLanguage
						)
						dispatch(create_employees({ employees: [...demoEmployees] }))
					}
					closeModal()
				})
				.catch(err => {
					// alert('error reading file')
					console.log({ err })
				})
		},
		[readXlFile, dispatch]
	)

	return (
		<>
			<div {...getRootProps({ className: 'dropzone' })}>
				<div>
					<input {...getInputProps()} onChange={handleChange} />
					<section className={classes.container}></section>
				</div>
			</div>
		</>
	)
}

export default UploadFile
