import { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useStyles } from 'screens/Bot/useStyles'

import PageTitles from '../PageTitles/index'
import useDebriefActions from './useDebriefActions'
import ActionButtons from '../ActionButtons'
import { useTreeLogic } from './useTreeLogic'
import { ParticipantsTree } from './ParticipantsTree/index'
import { BackgroundImage } from 'components/Bot/BackgroundImage'

import IUser from 'interface/user/user-interface'
import { Debrief } from 'interface/debrief/debrief-interface'
import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import { steps } from './steps'

interface Props {
	isRtlDirection: boolean
	debrief: Debrief
	wrapperRef: React.RefObject<HTMLDivElement>
	user: IUser
}

export const DebriefTree = (props: Props) => {
	const { debrief, wrapperRef, user } = props

	const { t } = useTranslation()
	const classes = useStyles({})

	const [isNextDisabled, setDisableNext] = useState(false)
	const [nextData, setNextData] = useState<Partial<Debrief>>({})
	const [participantStep, setParticipantStep] = useState<DebriefingSteps | null>(
		null
	)

	const { step } = debrief

	useEffect(() => {
		setNextData({})
	}, [step])

	const userIsParticipant = useMemo(
		() => debrief.creator?._id !== user._id,
		[debrief.creator]
	)

	const useActionsPayload = {
		debrief,
		isParticipant: userIsParticipant,
	}

	const { updateDebrief } = useDebriefActions(useActionsPayload)

	const logicPayload = {
		updateDebrief,
		setDisableNext,
		setParticipantStep,
		setNextData,
		nextData,
		isNextDisabled,
		wrapperRef,
	}

	const {
		content,
		pageIcon = { opacity: '', src: '' },
		bottomText = '',
		nextButtonText = '',
		prevData = {},
	} = useTreeLogic(logicPayload)

	const showParticipantsTree =
		userIsParticipant && step !== DebriefingSteps.FINISH
	console.log({ showParticipantsTree, userIsParticipant, step })
	const participantsTreedata = {
		userIsParticipant,
		updateDebrief,
		setParticipantStep,
		setDisableNext,
		step,
	}

	const { prevStep, nextStep } = steps[step!]

	return (
		<div className={classes.container}>
			{!showParticipantsTree && <PageTitles />}

			{showParticipantsTree ? (
				<ParticipantsTree {...participantsTreedata} />
			) : (
				content
			)}

			<ActionButtons
				setParticipantStep={setParticipantStep}
				isParticipant={userIsParticipant}
				bottomText={bottomText}
				nextButtonText={t(nextButtonText)}
				isNextDisabeld={isNextDisabled}
				update={updateDebrief}
				prevStep={prevStep ? { step: prevStep, data: prevData } : undefined}
				nextStep={nextStep ? { step: nextStep, data: nextData } : undefined}
			/>

			<BackgroundImage opacity={pageIcon?.opacity} image={pageIcon?.src} />
		</div>
	)
}

export default DebriefTree

export const DOSENT_REFER = 'dosent_refer'
export const OTHER = 'other'
