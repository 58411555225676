import { useEffect, useState } from 'react'
import { Drawer, makeStyles } from '@material-ui/core'
import colors from 'utils/colors'
import { useDispatch, useSelector } from 'react-redux'
import { IStoreRootState } from 'interface/redux'
import { requestErrorActions } from 'redux/requestError/request-error-reducer'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
	drawer: {
		height: '100%',
		width: 250,
		display: 'flex',
		justifyContent: 'start',
		alignItems: 'center',
	},
	drawerPaper: {
		left: '2%',
		width: '350px',
		height: '35px',
		bottom: 0,
		padding: '8px',
		position: 'relative',
		top: '87%',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.default,
		border: `3px solid ${colors.saydoGreen}`,
	},
}))

const ErrorMessage = () => {
	const [isOpen, setIsOpen] = useState(false)
	const { t } = useTranslation()

	const error = useSelector((state: IStoreRootState) => state.requestError.error)

	const dispatch = useDispatch()
	const classes = useStyles()

	const handleCloseWindow = () => setIsOpen(prev => false)
	console.log({ error })
	useEffect(() => {
		if (!error.message) return

		setIsOpen(prev => true)

		setTimeout(() => {
			handleCloseWindow()
			dispatch(requestErrorActions.clear())
		}, 1000)
	}, [error.message])

	const toggleDrawer = (open: any) => (event: any) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return
		}

		handleCloseWindow()
	}

	return (
		<div>
			<Drawer
				anchor='left'
				hideBackdrop
				open={isOpen}
				onClose={toggleDrawer(false)}
				classes={{ paper: classes.drawerPaper }}
			>
				<div
					className={classes.drawer}
					role='presentation'
					onClick={toggleDrawer(false)}
					onKeyDown={toggleDrawer(false)}
				>
					<div>{t(error.message)}</div>
				</div>
			</Drawer>
		</div>
	)
}

export default ErrorMessage
