import { ChangeEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import { ClickAwayListener } from "@material-ui/core"

import { ILessonsFolders, ILessonsItems } from "interface/lessons-management/LessonsBase.interface"
import { UI } from "enums/lessonsManagement"

type Props = {
    section: ILessonsFolders | ILessonsItems
    updateTitle?: (title: string, title_id: string) => void
}
const TitleInputSection = ({ section, updateTitle }: Props) => {
    const [editTitle, setEditTitle] = useState(false)
    const [updatedTitleName, setUpdateTitleName] = useState('')

    const DEFAULT_TITLE = 'default_file_title'

    const onEditTitle = () => {
        if (isUnchangeableTitles()) return

        setEditTitle(prev => !prev)
    }

    const isUnchangeableTitles = (): boolean => (
        section.label === UI.FILE ||
        section.label === UI.FOLDER ||
        section.label === DEFAULT_TITLE
    )

    const handleTitleDescription = ({
        target: { value },
    }: ChangeEvent<HTMLInputElement>) => {
        setUpdateTitleName(value)
    }

    const saveUpdatedTitle = () => {
        setEditTitle(prev => false)

        if (isTitleChanged()) updateTitle?.(updatedTitleName, section._id!)
    }

    const isTitleChanged = (): boolean => {
        return Boolean(updatedTitleName.trim())
    }

    const { t } = useTranslation()

    const widthTitle = section.label === DEFAULT_TITLE ? 'fit-content' : '150px'

    return (
        <>
            {/*Add the condition below to exclude empty titles*/}

            {/*!!section.data.length && */
                !editTitle
                    ? <p onClick={onEditTitle} style={{ width: widthTitle }}>{t(section.label.toString())}</p>
                    : <ClickAwayListener onClickAway={saveUpdatedTitle}>
                        <input
                            type='text'
                            className='title-inline-input'
                            autoFocus
                            defaultValue={section.label}
                            onChange={handleTitleDescription}
                        />
                    </ClickAwayListener>
            }
        </>
    )
}

export default TitleInputSection