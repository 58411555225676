import { useState } from "react"

type Props = {
    itemId?: string | null
}
const useEditDescription = ({ itemId }: Props) => {
    const [isEdit, setIsEdit] = useState<boolean>(!Boolean(itemId))

    const toggleEditLesson = () => setIsEdit(prev => !prev)

    return { isEdit, toggleEditLesson }
}

export default useEditDescription