import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { employeesSelector } from 'redux/employee/employee.selector'
import { userActions } from 'redux/user/user-reducer'
import { userSelector } from 'redux/user/user-selector'
import {
	receivedFeedbacksSelector,
	sentFeedbacksSelector,
} from 'redux/feedback/feedback-selector'

import { useDebriefOnProcess } from './useDebriefProcess'
import { setItem } from './localStorage'

import { OutputType } from 'screens/BotOutput'
import { Type } from 'screens/BotOutput/index'
import IUser from 'interface/user/user-interface'
import { SOURCE } from 'enums/baseInsight/baseInsight'
import Paths, { DashboardPaths } from 'enums/routes/path-names'
import { IBaseInsight } from 'interface/baseInsight/baseInsight'
import {
	INotification,
	INotificationDescription,
	NotificationTypes,
} from 'interface/notifications/notifications-interface'
import { BlockagesGoal } from 'components/Dashboard/BaseInsight/MainDashboardScreenRectagles'
import { isNotificationType } from './isNotificationType'

export const isBlockages = (
	output: INotification | Partial<IBaseInsight> | BlockagesGoal
): output is BlockagesGoal => {
	return (
		(output as BlockagesGoal) === BlockagesGoal.Group ||
		(output as BlockagesGoal) === BlockagesGoal.Personal
	)
}

type FeedbackState = {
	URL: string
	outputScreenType: OutputType
	output_id?: string
	tabState?: number
}
type HistoryStateType = {
	id?: string
	screenToReturn?: string
	outputScreenType?: string
	tabState?: number
	type?: string
}
type EndpointNav = {
	URL: string
	state: HistoryStateType
}
type EndpointOptions = {
	block: boolean
}
const useOutputNavigation = () => {
	const history = useHistory()
	const dispatch = useDispatch()

	const user = useSelector(userSelector)
	const employees = useSelector(employeesSelector)
	const sentFeedbacks = useSelector(sentFeedbacksSelector)
	const receivedFeedbacks = useSelector(receivedFeedbacksSelector)

	const setDebriefOnProcess = useDebriefOnProcess()

	const goEndpoint = (
		output: INotification | Partial<IBaseInsight> | BlockagesGoal,
		options?: EndpointOptions
	) => {
		if (isNotificationType(output)) definedNotificationEndpoint(output)
		else if (isBlockages(output)) definedFeedbacksEndpoint(output, options!)
		else definedTaskEndpoint(output)
	}

	const definedTaskEndpoint = (output: Partial<IBaseInsight>) => {
		const { source } = output
		let endpoint: EndpointNav = { state: {}, URL: '' }

		switch (source) {
			case SOURCE.FEEDBACK_ACTION_PLAN:
				endpoint = actionPlanScreenEndpoint(output)
				break
			case SOURCE.LESSON:
				endpoint = debriefScreenEndpoint(output)
				break
			default:
				endpoint = mainTodoScreenEndpoint(output)
				break
		}

		navigate(endpoint)
	}

	const definedNotificationEndpoint = (output: INotification) => {
		const { type } = output
		let endpoint: EndpointNav = { state: {}, URL: '' }
		console.log({ output })
		switch (type) {
			case NotificationTypes.PRAISE:
				endpoint = praiseScreenEndpoint(output)
				break
			case NotificationTypes.FEEDBACK:
				endpoint = feedbackScreenEndpoint(output)
				break
			case NotificationTypes.DEBRIEF:
				endpoint = debriefScreenEndpoint(output)
				break
			case NotificationTypes.TODO || type === NotificationTypes.TEAM:
				endpoint = todosScreenEndpoint(output)
				break
			default:
				endpoint = goalsScreenEndpoint()
				break
		}

		navigate(endpoint)
	}

	const definedFeedbacksEndpoint = (
		output: BlockagesGoal,
		{ block }: EndpointOptions
	): void => {
		console.log({ block })
		const outputScreenType =
			output === BlockagesGoal.Group
				? OutputType.FeedbackReciever
				: OutputType.FeedbackSender
		const tabState = output === BlockagesGoal.Group ? 0 : 1
		updatedStore(block)

		navigate(
			feedbackState({ URL: DashboardPaths.FEEDBACKS, outputScreenType, tabState })
		)
	}
	const updatedStore = (block: boolean): void => {
		if (block) dispatch(userActions.filterOutputsByBlockages())
		if (!block) dispatch(userActions.filterOutputsBySaydo())
	}

	const praiseScreenEndpoint = (notification: INotification): EndpointNav => {
		const { description } = notification
		console.log({ description })

		const isSentPraise = description === INotificationDescription.REPLY_MESSAGE
		const outputScreenType = isSentPraise
			? OutputType.PraiseViewing
			: OutputType.PraiseReciever
		const tabState = isSentPraise ? 0 : 1

		return {
			URL: `/output/${notification.output_id}`,
			state: {
				id: notification.output_id,
				outputScreenType: outputScreenType,
				screenToReturn: DashboardPaths.PRAISES,
				tabState,
				type: Type.Praise,
			},
		}
	}
	const feedbackScreenEndpoint = (notification: INotification): EndpointNav => {
		const showFeedbackSenderScreen =
			notification.description === INotificationDescription.ACTION_PLAN

		const outputType = showFeedbackSenderScreen
			? OutputType.FeedbackViewing
			: OutputType.FeedbackReciever

		const feedbackProcessScreen = showFeedbackSenderScreen
			? Paths.SENT_FEEDBACK_PROCESS
			: Paths.RECEIVED_FEEDBACK_PROCESS

		return feedbackState({
			URL: feedbackProcessScreen,
			outputScreenType: outputType,
			output_id: notification.output_id!,
		})
	}

	const todosScreenEndpoint = (notification: INotification): EndpointNav => {
		const sender = employees.find(
			({ personalInfo: { firstName, lastName } }: IUser) =>
				firstName.trim() === notification.firstName.trim() &&
				lastName.trim() === notification.lastName.trim()
		)

		let URL = ''
		const isSenderManager =
			sender?.securityInfo.email === user.statusInfo.directManager
		const isReceiverManage =
			user.securityInfo.email === sender?.statusInfo.directManager

		if (isSenderManager) {
			URL = DashboardPaths.GOALS
			keepStorageKey('nav-todo', true)
		}
		if (isReceiverManage) {
			URL = DashboardPaths.MANAGER_GOALS
			setSenderDataForNavigateCorrectly(sender._id)
		}

		return { URL, state: {} }
	}

	const goalsScreenEndpoint = (): EndpointNav => ({
		URL: DashboardPaths.GOALS,
		state: {},
	})

	const actionPlanScreenEndpoint = (
		task: Partial<IBaseInsight>
	): EndpointNav => {
		const isSender = sentFeedbacks.find(f => f._id === task.output_id)
		const isReciever = receivedFeedbacks.find(f => f._id === task.output_id)

		let URL = ''
		let outputScreenType = OutputType.FeedbackViewing
		if (isSender) {
			URL = Paths.SENT_FEEDBACK_PROCESS
			outputScreenType = OutputType.FeedbackViewing
		}
		if (isReciever) {
			URL = Paths.RECEIVED_FEEDBACK_PROCESS
			outputScreenType = OutputType.FeedbackReciever
		}

		return feedbackState({ URL, outputScreenType, output_id: task.output_id! })
	}

	const feedbackState = ({
		URL,
		outputScreenType,
		output_id,
		tabState,
	}: FeedbackState): EndpointNav => {
		return {
			URL,
			state: {
				id: output_id ? output_id : undefined,
				outputScreenType,
				screenToReturn: DashboardPaths.FEEDBACKS,
				tabState: tabState ? tabState : 0,
				type: Type.Feedback,
			},
		}
	}
	const debriefScreenEndpoint = (
		output: INotification | Partial<IBaseInsight>
	): EndpointNav => {
		setDebriefOnProcess({
			company_id: user.company_id,
			debrief_id: output.output_id!,
			user_id: user._id,
		})

		return {
			URL: Paths.DEBRIEF_PROCESS,
			state: {},
		}
	}
	const mainTodoScreenEndpoint = (
		output: Partial<IBaseInsight>
	): EndpointNav => {
		const employeeScreen = { URL: DashboardPaths.GOALS, state: {} }
		const managerScreen = { URL: DashboardPaths.MANAGER_GOALS, state: {} }

		const shouldNavigateEmployeeScreen =
			!output.sourceOptions || !output.sourceOptions

		if (!shouldNavigateEmployeeScreen) {
			if (output.source === SOURCE.TODO)
				setSenderDataForNavigateCorrectly(output.sourceOptions?.trim()!)
			else keepStorageKey('nav-team', true)

			return managerScreen
		} else {
			if (output.source === SOURCE.TEAM) keepStorageKey('nav-team', true)
			else keepStorageKey('nav-todo', true)

			return employeeScreen
		}
	}

	const setSenderDataForNavigateCorrectly = (sender: string) => {
		keepStorageKey('selectedEmployee', sender)
		dispatch(userActions.filterOutputsByEmployee(sender))
	}

	const keepStorageKey = (key: string, value: any) => setItem(key, value)
	const navigate = (endpoint: EndpointNav) => {
		history.push(endpoint.URL, endpoint.state)
	}

	return { goEndpoint }
}

export default useOutputNavigation
