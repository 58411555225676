import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles'

export default makeStyles<Theme>(({ breakpoints, palette }) => ({
	launchIcon: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '3%',
		[breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	recognizion: {
		color: palette.text.primary,
		fontWeight: 'bold',
		[breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'column-reverse',
			fontSize: '165% !important',
			textAlign: 'center',
		},
	},
	greetings: {
		display: 'flex',
		alignItems: 'center',
		[breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	receiver: {
		fontSize: '2.8rem',
		display: 'flex',
		alignItems: 'center',

		[breakpoints.up('sm')]: {
			'& > embed': { marginInlineStart: '10px' },
		},
		[breakpoints.down('sm')]: {
			padding: '2% 0',
		},
	},
	userSending: {
		fontSize: '1.5rem',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'center',
		margin: '1% 0',
		[breakpoints.down('sm')]: {
			fontSize: '100% !important',
			display: 'block',
		},
	},
	userName: {
		margin: '0 0 0 1px',
		color: '#38AD47',
		[breakpoints.down('sm')]: {
			padding: '2% 0',
		},
	},
	intro: { fontWeight: 'bold', fontSize: '1rem', marginBottom: '46px' },
	context: {
		width: '25vw',
		wordBreak: 'break-word',
		[breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	buttonsWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '50%',

		[breakpoints.up('md')]: {
			width: '25%',
		},
		'& >:first-child': {
			width: '50%',
		},

		'& >:last-child': {
			width: '100%',
		},
	},
	button: {
		maxWidth: '228px',
		[breakpoints.down('sm')]: {
			// maxWidth: '50vw',
		},
	},
	senderAvatar: {
		margin: '0 5%',
		[breakpoints.down('sm')]: {
			margin: '0 3%',
		},
	},
	textInput: {
		display: 'flex',
		justifyContent: 'center',
		wordBreak: 'break-word',
		width: '100%',
		flexDirection: 'column',
		padding: '5px',
		minHeight: '80px',
		resize: 'none',
		fontSize: '1.3rem',
		fontFamily: 'Sora, sans-serif',
		margin: '12px 0 39px',
		outline: 'none',
		background: '#FFFFFF',
		border: '1.4px solid rgba(49, 49, 49, 0.152716)',
		boxSizing: 'border-box',
		boxShadow: ' inset 3px 3px 10px #E1F0FF',
		borderRadius: '14px',
		[breakpoints.down('sm')]: {
			minHeight: '18vw',
			fontSize: '1rem',
		},
	},
	desabled: {
		color: 'grey',
	},
}))
