import { FC } from 'react'

import { UseClickActions } from 'components/Bot/useHooks/index'
import { FeedbackWrapper } from 'utils/outputWrapper/feedback'
import FeedbackProcess from 'screens/FeedbackProcess/FeedbackProcess'
import PraiseReciever from '../receiver/index'
import { PraiseWrapper } from 'utils/outputWrapper/praise'
import ViewingOutput from '../ViewingOutput/index'
import BeforeSendOutput from '../beforeSending/index'

import { OutputType } from '../index'
import { FeedbackOutputWrapperProps } from 'interface/bot'

type Props = {
	currentOutputId: string
	outputScreenType: OutputType
	isPraise: boolean
	handleToggleReview: () => void
	handleClose: () => void
}
export const RenderScreenType: FC<Props> = props => {
	const { currentOutputId, outputScreenType, isPraise, handleToggleReview } =
		props
	const { closeAction } = UseClickActions(isPraise)
	console.log({ outputScreenType })
	if (!currentOutputId) return <></>

	switch (outputScreenType) {
		case OutputType.FeedbackReciever:
			return (
				<FeedbackWrapper
					outputId={currentOutputId}
					screenType={OutputType.FeedbackReciever}
					render={props => <FeedbackProcess {...{ ...props, currentOutputId }} />}
				/>
			)

		case OutputType.PraiseReciever:
			return (
				<PraiseWrapper
					outputId={currentOutputId}
					screenType={OutputType.PraiseReciever}
					render={props => (
						<PraiseReciever
							handleToggleReview={handleToggleReview}
							{...{ ...props, currentOutputId }}
						/>
					)}
				/>
			)
		case OutputType.FeedbackViewing:
			return (
				<FeedbackWrapper
					outputId={currentOutputId}
					screenType={OutputType.FeedbackViewing}
					render={(props: FeedbackOutputWrapperProps) => (
						<FeedbackProcess {...{ ...props, currentOutputId }} />
					)}
				/>
			)
		case OutputType.PraiseViewing:
			return (
				<PraiseWrapper
					outputId={currentOutputId}
					screenType={OutputType.PraiseViewing}
					render={props => (
						<ViewingOutput
							handleToggleReview={handleToggleReview}
							{...{ ...props, currentOutputId, isPraise: true }}
						/>
					)}
				/>
			)
		case OutputType.FeedbackSender:
			return (
				<FeedbackWrapper
					outputId={currentOutputId}
					screenType={OutputType.FeedbackSender}
					render={props => (
						<BeforeSendOutput
							{...{ ...props, currentOutputId }}
							isPraise={false}
							allowInputWrite
						/>
					)}
				/>
			)
		case OutputType.PraiseSender:
			return (
				<PraiseWrapper
					outputId={currentOutputId}
					screenType={OutputType.PraiseSender}
					render={props => (
						<BeforeSendOutput
							{...{ ...props, currentOutputId }}
							isPraise
							allowInputWrite
						/>
					)}
				/>
			)
		case OutputType.FeedbackDraft:
			return (
				<FeedbackWrapper
					outputId={currentOutputId}
					screenType={OutputType.FeedbackDraft}
					render={props => (
						<BeforeSendOutput
							{...{ ...props, currentOutputId, outputType: OutputType.FeedbackDraft }}
							isPraise={false}
							allowInputWrite
						/>
					)}
				/>
			)
		case OutputType.PraiseDraft:
			return (
				<PraiseWrapper
					outputId={currentOutputId}
					screenType={OutputType.PraiseDraft}
					render={props => (
						<BeforeSendOutput
							{...{ ...props, currentOutputId, outputType: OutputType.PraiseDraft }}
							isPraise
							allowInputWrite
						/>
					)}
				/>
			)
		default:
			closeAction()
			return <div></div>
	}
}
