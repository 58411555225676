import {
	OutputSettingType,
	PraiseOutput,
	ReviewOutputPayload,
} from 'interface/bot'
import { mapDataToQuestionType } from 'utils'
import { GET, PATCH, PUT, DELETE, POST } from './api-requests'
import { URLS } from './api-urls'
import { Response } from 'interface/api/response-interface'
import {
	FetchUserFeedbackPraisesPayload,
	UpdatePraisePatch,
} from '../interface/api/get-payload.types'
import { ToggleOutputDraft } from '../components/Bot/useHooks/index'
import { BaseQuestion } from 'interface/praise/praise-interface'
import { Langs } from 'enums/app'
import { PraiseState } from '../enums/praise-state/praise-state.enum'

export const fetchReceivedPraisesByIds = async (params: string) => {
	const data = await GET(`${URLS.COMPANY_PRAISES}/receive?${params}`)
	return data
}

export const fetchSentPraisesByIds = async (params: string) => {
	const data = await GET(`${URLS.COMPANY_PRAISES}?${params}`)
	return data
}

export const fetchWallofPraiseTemplates = async () => {
	const { data, status } = await GET(
		`${URLS.PRAISE_OUTPUT_SETTINGS}/wall-of-praise`
	)
	return { data, status }
}

export const fetchPraiseTabsTemplate = async () => {
	const { data, status } = await GET(
		`${URLS.PRAISE_OUTPUT_SETTINGS}/praise/tabs`
	)
	return { data, status }
}

export const fetchPraiseOutputById = async (payload: Record<string, any>) => {
	const { id, isBeforeSend } = payload
	console.log({ id })
	const { data, status } = await GET(
		`${URLS.PRAISE_OUTPUT_SETTINGS}/praise?id=${id}&isBeforeSend=${isBeforeSend}`
	)
	return { data, status }
}

export const fetchAllPraisesOutputs = async () => {
	const { data, status } = await GET(URLS.PRAISE_OUTPUT_SETTINGS)
	return { data, status }
}
export const createPraiseOutput = async (
	output: Omit<OutputSettingType, '_id'>
) => {
	const { data, status } = await POST(URLS.PRAISE_OUTPUT_SETTINGS, { ...output })
	return { data, status }
}
export const updatePraiseOutput = async (output: OutputSettingType) => {
	const { data, status } = await PUT(URLS.PRAISE_OUTPUT_SETTINGS, { ...output })
	return { data, status }
}

export const deletePraise = async (payload: Record<string, string>) => {
	const { data, status } = await DELETE(
		`${URLS.COMPANY_PRAISES}?output_id=${payload.output_id}&company_id=${payload.company_id}`
	)
	return { data, status }
}

export const togglePraiseOutputDraft = async (payload: ToggleOutputDraft) => {
	const { data, status } = await PATCH(`${URLS.COMPANY_PRAISES}/toggleDraft`, {
		...payload,
	})
	return { data, status }
}

export const sendOutputPraise = async (
	payload: PraiseOutput<string>,
	company_id: string,
	postOnWall?: boolean
) => {
	const { data, status } = await PATCH(URLS.COMPANY_PRAISES, {
		company_id,
		praise: { ...payload, praiseState: PraiseState.SENT },
		postOnWall,
	})
	return { data, status }
}
export const getQuestionPraise = async (
	questionId: string | null,
	language: Langs
): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.PRAISE}${questionId ? `/${questionId}` : '/first'}`
	)
	const question = await mapDataToQuestionType(data, language)
	return { data: question, status }
}
export const getAllPraiseQuestions = async (): Promise<Response> => {
	const { data, status } = await GET(`${URLS.PRAISE}`)
	return { data, status }
}
export const fetchUserPraises = async ({
	company_id,
	user_id,
}: FetchUserFeedbackPraisesPayload): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.EMPLOYEE}/praises?company_id=${company_id}&user_id=${user_id}`
	)
	return { data, status }
}

export const loadMorePraises = async ({
	company_id,
	user_id,
	pageNumber,
	type,
}: FetchUserFeedbackPraisesPayload): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.EMPLOYEE}/loadPraises?company_id=${company_id}&user_id=${user_id}&pageNumber=${pageNumber}&type=${type}`
	)
	return { data, status }
}
export const fetchUserPraiseDrafts = async ({
	company_id,
	user_id,
}: FetchUserFeedbackPraisesPayload): Promise<Response> => {
	const { data, status } = await GET(
		`${URLS.EMPLOYEE}/praiseDrafts?company_id=${company_id}&user_id=${user_id}`
	)
	return { data, status }
}

export const updatePraiseBotQuestion = async (
	payload: BaseQuestion
): Promise<Response> => {
	const { data, status } = await PUT(`${URLS.PRAISE}`, payload)
	return { data, status }
}
export const updatePraiseOutputState = async (praise: UpdatePraisePatch) => {
	const { data, status } = await PATCH(`${URLS.EMPLOYEE}/praises`, praise)
	return { data, status }
}
export const praiseReviewToggle = async (payload: ReviewOutputPayload) => {
	const { data, status } = await PATCH(`${URLS.COMPANY_PRAISES}/review`, payload)
	return { data, status }
}
