import { useState } from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

import { Tab } from 'screens/OutputSettings/index'
import FullScreenDialog from './fullScreenModal'

import { OutputSettingType } from 'interface/bot/index'

type OutputListPropType = {
	tabState: Tab
	outputs: OutputSettingType[]
	fetchDate: () => void
}
export const OutputList = ({
	tabState,
	outputs,
	fetchDate,
}: OutputListPropType) => {
	const [isOpen, setIsOpen] = useState(false)
	const [output, setOutput] = useState<OutputSettingType | null>(null)

	const onItemChosen = (output: OutputSettingType) => {
		setOutput(output)
		setIsOpen(true)
	}

	const handleOpenDialogModal = () => setIsOpen(prev => true)
	const handleCloseDialogModal = () => {
		setIsOpen(false)
		setOutput(null)
		fetchDate()
	}

	return (
		<>
			<List
				sx={{
					height: '70%',
					width: '80vw',
				}}
			>
				{outputs.map(output => {
					const { title, _id } = output

					return (
						<ListItem
							style={style}
							onClick={() => onItemChosen(output)}
							key={_id}
							disableGutters
						>
							<ListItemText primary={title} />
						</ListItem>
					)
				})}
			</List>

			{output && (
				<FullScreenDialog
					tabState={tabState}
					output={output!}
					isOpen={isOpen}
					handleClickOpen={handleOpenDialogModal}
					handleClose={handleCloseDialogModal}
				/>
			)}
		</>
	)
}
const style = {
	padding: '0 0 0 2rem',
	margin: '2% 0',
	backgroundColor: '#DFDFDF',
	cursor: 'pointer',
	borderRadius: '25px',
	border: '1px solid black',
}