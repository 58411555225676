import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isBetween from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import { hebrewDateFormat } from '../components/BotOutput/index'

dayjs.extend(isToday)
dayjs.extend(isBetween)
dayjs.extend(relativeTime)

export const getRelativeTime = (
	date: Date | string = '',
	t: Function,
	isHe: boolean = false
): string => {
	let relativeTime: string
	const currentDateMilliseconds = dayjs(new Date()).valueOf()
	const dateInMilliseconds = dayjs(new Date(date)).valueOf()
	const weekInMilliseconds = 1000 * 60 * 60 * 24 * 7
	const weeksAgo =
		((currentDateMilliseconds - dateInMilliseconds) / weekInMilliseconds) | 0

	if (dayjs(date).isToday()) {
		relativeTime = dayjs(date).format('hh:mm A')
	} else if (weeksAgo < 4 && weeksAgo >= 1) {
		if (weeksAgo < 2) {
			relativeTime = t('weeks_ago')
		} else {
			relativeTime = t('weeks_ago_plural', { count: weeksAgo })
		}
	} else {
		relativeTime = true
			? hebrewDateFormat(date.toString())
			: dayjs(date).fromNow()
	}

	return relativeTime
}
