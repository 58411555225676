import { createSelector } from 'reselect'
import { IStoreRootState } from 'interface/redux/index'

export const fetchBotStateSelector = (state: any) => state.bot

export const getBotAnswers = createSelector(
	fetchBotStateSelector,
	(state: any) => state
)

export const getOutputSelector = createSelector(
	fetchBotStateSelector,
	state => state.output
)
