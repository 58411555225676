import React, { FC, useEffect, useMemo } from 'react'
import useStyles from './styled'
import { Tooltip } from '@material-ui/core'
import { get_company_activity_for_super_admin, get_company_activity_for_super_admin_v2 } from 'redux/company/company-actions'
import {
    companyEmployeesTodosActivitySelector,
    companyFeedbacksActivitySelector,
    companyManagerTodosActivitySelector,
    companyPersLearningActivitySelector,
    companyPraisesActivitySelector,
    companyTotalActivitySelector,
    companyGroupLearningActivitySelector,
    companyNameSelector,
    numOfEmployeesSelector,
    companyJoiningDateSelector
} from 'redux/company/company.selector'
import { useDispatch, useSelector } from 'react-redux'
import SuperAdminChart from './Chart'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import moment from 'moment'

type RowProps = {
    index: any
    data: Array<any>
}

export const Row: FC<RowProps> = ({ data, index }) => {
    const classes = useStyles({})
    const { t } = useTranslation()
    return (
        <div className={[classes.row, classes.body].join(' ')}>
            <div>
                <p>{t(`${names[index]}`)}</p>
            </div>
            <div>
                {data.map(({ total, lastMonth, avg }, index) => (
                    <div key={index}>
                        <div>{total}</div>
                        <div>{lastMonth}</div>
                        <div>{avg}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const SuperTableHeader = () => {
    const classes = useStyles({})
    const joiningDate = useSelector(companyJoiningDateSelector)
    const numOfEmployees = useSelector(numOfEmployeesSelector)

    const days = useMemo(() => {
        const diff = moment.duration(moment().diff(joiningDate)).asDays()
        return diff.toFixed()
    }, [joiningDate])

    const title = `${days} days passed from joining`

    const tooltip = (
        <Tooltip arrow placement='right-end' title={title}>
            <span> {dayjs(joiningDate).format('DD/MM/YYYY')}</span>
        </Tooltip>
    )

    return (
        <>
            <div className={classes.header}>
                <div>Company activity module for super admin</div>
            </div>
            <div className={classes.companyDetails}>
                <div>Active users:
                    <span className={classes.usersCounter}>
                        {numOfEmployees}
                    </span>
                </div>
                <div>Joining date:
                    <span className={classes.date}>
                        {tooltip}
                    </span>
                </div>
            </div>
        </>
    )
}

const headers = ['activity', 'total', 'last_month', 'monthly_average']
export const SuperTable = () => {
    const dispatch = useDispatch()
    const location: any = useLocation()
    const classes = useStyles({})
    const { t } = useTranslation()
    const tableData = useSupetTableData()
    console.log(tableData)
    const companyName = useSelector(companyNameSelector)
    const { id: company_id } = location.state

    useEffect(() => {
        if (!company_id) return
        dispatch(
            get_company_activity_for_super_admin_v2(company_id)
            // get_company_activity_for_super_admin(compant_id)
            )
    }, [company_id])

    return (
        <>
            {SuperTableHeader()}
            <div className={classes.wrapper}>
                <div className={classes.tableHeader}>
                    {headers.map(h => (
                        <div>{t(`${h}`)}</div>
                    ))}
                </div>
                {tableData.map((data, index) => (
                    <Row {...{ data, index }} />
                ))}
            </div>
            <SuperAdminChart comapnyName={companyName} company_id={company_id} />
        </>
    )
}
const names = ['praises', 'feedbacks', 'pers_learning', 'group_learning', 'managers_todos', 'employees_todos', 'total_activity']

const useSupetTableData = () => {
    const praises = useSelector(companyPraisesActivitySelector).slice(0, 3)
    const feedbacks = useSelector(companyFeedbacksActivitySelector).slice(0, 3)
    const persLearning = useSelector(companyPersLearningActivitySelector).slice(0, 3)
    const groupLearning = useSelector(companyGroupLearningActivitySelector).slice(0, 3)
    const managersTodos = useSelector(companyManagerTodosActivitySelector).slice(0, 3)
    const employeesTodos = useSelector(companyEmployeesTodosActivitySelector).slice(0, 3)
    const totalActivities = useSelector(companyTotalActivitySelector)

    return [praises, feedbacks, persLearning, groupLearning, managersTodos, employeesTodos, totalActivities]
}
export default SuperTable

