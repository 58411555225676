import { makeStyles } from '@material-ui/core/styles'
import { lighten, Theme } from '@material-ui/core'

export type Styles = {
	date: string | Date
}
export default makeStyles<Theme, Styles>(({ palette, breakpoints }) => ({
	container: {
		height: '100%',
		cursor: 'pointer',
		border: `1px solid ${palette.action.disabled}`,
		boxShadow: 'rgb(0 0 0 / 4%) 2px 2px',
		borderRadius: '10px',
		color: palette.text.primary,
		textAlign: 'center',
		fontSize: '14px',
		padding: '20px 22px 30px',
		display: 'flex',
		flexDirection: 'column',
		'&:hover': {
			padding: '19px 21px 29px',
			border: `2px solid ${palette.secondary.main}`,
		},
		[breakpoints.down('sm')]: {
			'&:hover': {
				border: `1px solid ${palette.action.disabled}`,
			},
		},
	},
	chipAndCoins: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	chip: {
		height: '24px',
		color: palette.common.white,
		fontWeight: 300,
		fontSize: '13px',
	},
	coins: {
		fontSize: '13px',
		fontWeight: 700,
	},
	date: {
		margin: '30px 0 15px',
		color: ({ date }) => (!date ? 'white' : '#46484a')
	},
	description: {
		fontWeight: 700,
		margin: 0,
		height: '55px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-line-clamp': '3',
		'-webkit-box-orient': 'vertical',
	},
	toFromContainer: {
		marginTop: '40px',
		fontWeight: 600,
		fontSize: '13px',
	},
	toFrom: {
		color: palette.text.disabled,
	},
}))
