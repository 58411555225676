import { FC, useMemo } from 'react'
import makeStyles from '../../../components/common/Header/BotHeader/style'
import logo from 'assets/Logowithtext.svg'

import 'react-sweet-progress/lib/style.css'
import { CloseIcon } from 'components/common/CloseIcon'
import { useHistory } from 'react-router-dom'
import { DashboardPaths } from 'enums/routes/path-names'
import { LineProgressBar } from 'components/common/Header/BotHeader'
import { useDispatch } from 'react-redux'
import { debriefActions } from 'redux/debrief/debrief-reducer'
import { removeItem } from 'utils/localStorage'

const BotHeader: FC = () => {
	const classes = makeStyles()
	const history = useHistory()
	const dispatch = useDispatch()
	const handleClose = () => {
		dispatch(debriefActions.clearDebriefOnProcess())
		removeItem('debrief')
		history.go(-1)
		// history.push(DashboardPaths.DEBRIEFINGS)
	}

	return (
		<div className={classes.botHeaderWrapper}>
			<div id='botHeader' className={classes.conatainer}>
				<img
					className={classes.logo}
					onClick={handleClose}
					src={logo}
					alt='SolveLogo'
				/>
				<CloseIcon handleClick={handleClose} />
			</div>
		</div>
	)
}

export default BotHeader
