import axios, { CancelTokenSource } from 'axios'
import baseUrl from './base-url'
import { getItem } from 'utils/localStorage'
import { Req } from 'enums/req/req.enum'
let ip: string 
  
if (process.env.REACT_APP_STATE === 'production') ip = baseUrl.production
else if (process.env.REACT_APP_STATE === 'staging') ip = baseUrl.staging
else ip = baseUrl.development

export const POST = async <T>(url: string, body: unknown):Promise<any> => {
	const config = getRequestConfiguration()
	return await (
		await axios.post<T>(`${ip}${url}`, body, config)
	).data
}

export const PUT = async <T>(url: string, body?: unknown):Promise<any> => {
	const config = getRequestConfiguration()
	return await (
		await axios.put<T>(`${ip}${url}`, body, config)
	).data
}

export const GET = async <T>(url: string):Promise<any> => {
	const config = getRequestConfiguration()
	return await (
		await axios.get<T>(`${ip}${url}`, config)
	).data
}

export const PATCH = async <T>(url: string, body?: unknown):Promise<any> => {
	const config = getRequestConfiguration()
	return await (
		await axios.patch<T>(`${ip}${url}`, body, config)
	).data
}

export const DELETE = async <T>(url: string):Promise<any> => {
	const config = getRequestConfiguration()
	return await (
		await axios.delete<T>(`${ip}${url}`, config)
	).data
}

const getRequestConfiguration = () => {
	const timeout = 60_000; // equal to 1 minute
	const token = getItem(Req.token) ?? '';
	const { token: cancelToken } = axios.CancelToken.source()

	return {
		headers: { Authorization: `Bearer ${token}` },
		timeout,
		cancelToken
	}
}
