import CompanyBuilder from 'components/CompanyBuilder/Builder'
import { ILicenses } from 'interface/Licenses'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get_company_license } from 'redux/company/company-actions'
import { IStoreRootState } from '../../../interface/redux/index'
import { Langs } from '../../../enums/app/index'
interface Props {
	companyLanguage: Langs
	companyId: string
	progressbar: JSX.Element
}

const HRCompanyBuilder = (props: Props) => {
	const { companyId, progressbar, companyLanguage } = props
	const companyLicense = useSelector(
		(state: IStoreRootState) => state.company.currentCompanyLicense
	)
	const isDataloading = useSelector(
		(state: IStoreRootState) => state.company.loading
	)

	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(get_company_license({ company_id: companyId }))
	}, [])

	const builder = typeof companyLicense === 'object' && (
		<CompanyBuilder
			companyLanguage={companyLanguage}
			paidLicense={companyLicense!}
			company_id={companyId}
			upload={false}
		/>
	)

	return <div>{isDataloading ? progressbar : builder}</div>
}

export default HRCompanyBuilder
