import { useState, useReducer, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Fab from '@material-ui/core/Fab'
import { NavigateNext, NavigateBefore } from '@material-ui/icons'

import { AppDirectionContext } from 'context/languages-context'

import useStyles from './styles'

import { employeesReducer, initialEmployeesState } from './employeesReducer'
import { getManagers, findDuplicates, getFullName } from './builderHelper'

import EmployeesBox from './EmployeesBox'
import ManagersBox from './ManagersBox'

import { define_employee_manager } from 'redux/employee/employee-actions'

import IUser from 'interface/user/user-interface'
import { isCompanySelectorLoading } from 'redux/company/company.selector'

interface Props {
	companyId: string
	employees: IUser[]
	progressbar: JSX.Element
}
const DepartmentBuilder = ({ employees, companyId, progressbar }: Props) => {
	const [currentManagerIdx, setCurrentManagerIdx] = useState(0)

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [state, dispatchState] = useReducer(employeesReducer, initialEmployeesState)

	const companyDataloading = useSelector(isCompanySelectorLoading)

	const duplicates = findDuplicates(employees)

	const managers: Array<IUser> = getManagers(employees)

	const managerEmployees = employees.filter(
		(employee: IUser) =>
			employee.statusInfo.directManager ===
			managers[currentManagerIdx].securityInfo.email
	)

	const employeeActionRow = (employee: IUser, isOnAssignList: boolean) => {
		const fullName = getFullName(employee)
		const isShouldShowEmailAddress = duplicates.includes(fullName)

		const marked = !isOnAssignList
			? state.unAssignList.includes(employee._id)
			: state.assignList.includes(employee._id)

		return (
			<div key={employee._id}
				onClick={() => { isOnAssignList ? listToAssign(employee._id) : listToUnAssign(employee._id) }}
				className={marked ? `${classes.row} ${classes.markedRow}` : classes.row}
			>
				{fullName}{' '}

				{isShouldShowEmailAddress && (
					<span className={classes.duplicateExtraDetails}>
						{'(' + employee.securityInfo.email + ')'}
					</span>
				)}
			</div>
		)
	}

	const handleSelectChange = (id: number) => {
		setCurrentManagerIdx(id)
		dispatchRestartList()
	}

	const listToAssign = (id: string) => {
		state.assignList.includes(id)
			? dispatchState({ type: 'UNLIST_FROM_ASSIGN', value: id })
			: dispatchState({ type: 'LIST_ON_ASSIGN', value: id })
	}

	const listToUnAssign = (id: string) => {
		state.unAssignList.includes(id)
			? dispatchState({ type: 'UNLIST_FROM_UNASSIGN', value: id })
			: dispatchState({ type: 'LIST_ON_UNASSIGN', value: id })
	}

	const managersOptions = managers.map((manager, idx) => {
		const managerName = getFullName(manager)
		const isShouldShowEmailAddress = duplicates.includes(managerName)

		const option = {
			value: `${isShouldShowEmailAddress
				? managerName + `(${managers[idx].securityInfo.email})`
				: managerName
				}`,
			idx: idx,
		}
		return option
	})

	const onAssingEmployeeToManager = async () => {
		const managerEmail = managers[currentManagerIdx].securityInfo.email
		const assignIdsList = state.assignList
		if (assignIdsList) definedEmployeeManager(managerEmail, assignIdsList)

		dispatchState({ type: 'RESTART_ASSIGN_LIST', value: null })
	}
	const onUnassignEmployee = async () => {
		const managerEmail = 'unassigned'
		const unassignIdsList = state.unAssignList
		if (unassignIdsList) definedEmployeeManager(managerEmail, unassignIdsList)

		dispatchRestartList()
	}
	const definedEmployeeManager = (managerEmail: string, assignIdsList: any) => {
		console.log(managers[currentManagerIdx])
		dispatch(
			define_employee_manager({
				company_id: companyId,
				managerEmail,
				manager_id: managers[currentManagerIdx]._id,
				assignIdsList,
			})
		)
	}
	const dispatchRestartList = () => dispatchState({ type: 'RESTART_UNASSIGN_LIST', value: null })

	const unassignedEmployees = useMemo(() => (
		employees.filter(
			(e: IUser) =>
				e.statusInfo.directManager === 'unassigned' &&
				e.securityInfo.email !== managers[currentManagerIdx].securityInfo.email
		)
	), [employees, managers, currentManagerIdx])

	return (
		<div className={classes.mainContainer}>

			<h1>{t('department_builder_title')}</h1>
			<p>{t('department_builder_paragraph')}</p>

			{!employees.length && companyDataloading
				? (progressbar)
				: (
					<div className={classes.cardsContainer}>
						<EmployeesBox
							employees={unassignedEmployees}
							onRowCreate={employeeActionRow}
						/>

						<div className={classes.navigationButtons}>

							<Fab
								color='primary'
								onClick={onAssingEmployeeToManager}
							>
								{isRtlDirection ? <NavigateBefore /> : <NavigateNext />}
							</Fab>

							<Fab
								color='primary'
								onClick={onUnassignEmployee}
							>
								{isRtlDirection ? <NavigateNext /> : <NavigateBefore />}
							</Fab>

						</div>

						<ManagersBox
							defaultValue={managersOptions[0]}
							managers={managersOptions}
							onSelect={id => handleSelectChange(id)}
							employees={managerEmployees}
							onRowCreate={employeeActionRow}
						/>

					</div>
				)}
		</div>
	)
}
export default DepartmentBuilder
