import { DebriefEvent, Debrief } from 'interface/debrief/debrief-interface'
import { useEffect, useLayoutEffect, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { update_event_location } from 'redux/debrief/debrief-actions'

import Event from './Event/Event'
import { ListItem } from 'components/common/DraggableList/ListItem'
import EventPraimaryContent from './Event/EventPrimaryContent'
import { reorder } from 'components/common/DraggableList/List'

interface Props {
	companyId: string
	debriefId: string
	events: DebriefEvent[]
	onMatchPointChoice: boolean
	isNextDisabled: boolean
	setData?: (data: Partial<Debrief>) => void
	setDisableNext: (status: boolean) => void
}

const useEventList = (props: Props) => {
	const {
		companyId,
		debriefId,
		events,
		onMatchPointChoice,
		isNextDisabled,
		setData,

		setDisableNext,
	} = props // hook props
	const initialMatchpointsIds = events
		? [
				// ids comes from server to mark the current matchpoints selected.
				...events.filter(event => event.isMatchPoint === true),
		  ].map(event => event._id)
		: []

	const [items, setItems] = useState<ListItem[]>([]) //current list items elements
	const [currentMatchpoints, setCurrentMatchpoints] = useState<string[]>( //current matchpoints ids
		initialMatchpointsIds
	)
	const dispatch = useDispatch()

	const toggleMatchpointSelection = (eventId: string) => {
		//toggle matchpoints (set thier ids on state).
		const eventIsMatchpoint = currentMatchpoints.includes(eventId)
		if (currentMatchpoints.length > 2 && !eventIsMatchpoint) return
		const filterdMatchpoints = currentMatchpoints.filter(e => e !== eventId)
		const resultMatchpoints = eventIsMatchpoint
			? filterdMatchpoints
			: [...currentMatchpoints, eventId]

		setCurrentMatchpoints(resultMatchpoints)
	}
	useEffect(() => {
		setDisableNext(
			onMatchPointChoice
				? currentMatchpoints.length < 1 //next status on matcpoints selection case
				: !events || events.length < 1 //next status on chronological events case
		)
		//with every matchpoints selection/unselection updated data on debrief tree- ready to be send when next is clicked.
		if (!events) return
		const matchpointsEventsSelection: DebriefEvent[] = events.map(event => ({
			...event,
			isMatchPoint: currentMatchpoints.includes(event._id) || events.length === 1,
		}))

		setData && setData({ events: matchpointsEventsSelection })
	}, [currentMatchpoints, events, onMatchPointChoice])

	useLayoutEffect(() => {
		//with changes of events / screen(matchpoint choice / chronological events ) / matchpoint selection - update the list items state
		if (!events) return
		const mappedItems = events.map((item: DebriefEvent, index: number) => {
			const position = index + 1
			const primary = <EventPraimaryContent position={position} date={item.date} />
			const optionalEventProps = onMatchPointChoice
				? {
						isMatchPointChoice: onMatchPointChoice,
						selected: currentMatchpoints.includes(item._id),
						onEventClick: () => toggleMatchpointSelection(item._id),
				  }
				: {}
			return {
				id: item._id,
				element: (
					<Event
						{...optionalEventProps}
						titleElement={primary}
						description={item.description}
						id={item._id}
					/>
				),
			}
		})
		setItems(mappedItems)
	}, [events, onMatchPointChoice, currentMatchpoints])
	useEffect(() => {
		//reset the initial state in case of going back from matchpoints choice
		if (!onMatchPointChoice) {
			setCurrentMatchpoints(initialMatchpointsIds)
		}
	}, [onMatchPointChoice])
	const onDragEnd = ({ destination, source }: DropResult) => {
		//happens on dragg action and define new order in the elements list
		if (!destination) return
		const newItems = reorder<ListItem>(items, source.index, destination.index)
		const locationPayload = {
			company_id: companyId,
			debrief_id: debriefId,
			source: source.index,
			destination: destination.index,
		}
		dispatch(update_event_location(locationPayload))
		setItems(newItems)
	}
	return { items, onDragEnd, currentMatchpoints }
}

export default useEventList
