import IUser, { IUserStatusInfo } from 'interface/user/user-interface'
import { Langs } from 'enums/app'
import { uniqeId } from 'utils/helpers'
import { Roles } from 'enums/user/user-roles'
import { UNASSINGED } from 'utils/getManager'

export type Employee = {
	firstName: string
	lastName: string
	title: string
	email: string
	mobile: string
	department: string
	subDepartment: string
	directManager: string
	license: string
	imgSrc?: string
	role: Roles
}

export const employeesMapIUser = (
	emplopyees: Array<Employee>,
	company_id: string,
	companyLanguage: Langs
): Array<IUser> => {
	return emplopyees.reduce(
		(
			usersAccumulator: Array<IUser>,
			{
				firstName,
				lastName,
				title,
				email,
				department,
				subDepartment,
				directManager,
				license,
				role,
				mobile,
				imgSrc,
			}
		) => {
			if (!firstName && !lastName && !email && !mobile) return usersAccumulator

			const user: IUser = {
				_id: uniqeId(),
				company_id: company_id,
				personalInfo: {
					firstName,
					lastName,
					imgSrc: imgSrc ?? '',
					mobile: mobile ? handleEmployeeMobile(mobile.toString()) : '',
				},
				securityInfo: {
					token: '',
					password: '',
					email: email ?? '',
				},
				statusInfo: {
					companyLanguage: companyLanguage ?? '',
					goals: [],
					role: role ?? '',
					active: true,
					department: department ?? '',
					subDepartment: subDepartment ?? '',
					directManager: directManager ?? UNASSINGED,
					license: license ?? '',
					title: title ?? '',
				} as IUserStatusInfo,
			}

			return [...usersAccumulator, user]
		},
		[]
	)
}

const handleEmployeeMobile = (mobile: string) => {
	mobile = mobile
		.split('')
		.map((digit, index) => (index === 3 && digit !== '-' ? `-${digit}` : digit))
		.join('')
	return mobile
}
