import { TodoState, ApprovedTask } from "enums/todos/todos.enum"
import dislike from "assets/dislikeIcon.svg"
import colors from "./colors"
import { Task } from "interface/todos/todos-interface"

export default function ({ approved, state }: Task) {
    return {
        [TodoState.new]: { border: `2.5px solid ${colors.emeraldGreen}` },
        [TodoState.updated]: { border: `2.5px solid ${colors.orange}` },
        [TodoState.deleted]: { border: `2.5px solid ${colors.red}`, opacity: 0.3, textDecorationLine: 'line-through' },
        [TodoState.viewed]: approved !== ApprovedTask.false
            ? { broder: 'none' }
            : {
                backgroundImage: `url( ${dislike} )`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: "center top",
                backgroundPositionY: "center",
                backgroundSize: '30px 30px',
            }
    }[state]
}