import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import UserInformation, { Notification } from '../Header/Web'
import Logo from './Logo'
import NavigationButtons from './NavigationButtons'
import { Roles } from 'enums/user/user-roles'
import { License } from 'enums/License/License.enum'
import classes from './style.module.css'

interface Props {
	firstName: string
	lastName: string
	title: string
	role: Roles
	license: License
}
const appSx = {
	display: { sx: 'none', md: 'flex' },
	backgroundColor: 'white',
	boxShadow:
		'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
}
const logoBoxSx = {
	width: '14vw',
}
const navBoxSx = {
	margin: 'auto',
	width: '66vw',
	justifyContent: 'space-between',
	display: 'flex',
}

const DesktopNavigation = React.memo(
	({ firstName, lastName, title, role, license }: Props) => {
		const userInfoProps = { firstName, lastName, title, isMobile: false }

		return (
			<AppBar
				elevation={0}
				className={classes.appBar}
				sx={appSx}
				position='static'
			>
				<Container maxWidth={false}>
					<Toolbar disableGutters>
						<Box sx={logoBoxSx}>
							<Logo />
						</Box>

						<Box sx={navBoxSx}>
							<NavigationButtons license={license} isMobile={false} role={role} />
							<Notification />
						</Box>

						<Box
							sx={{
								width: '15vw',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<UserInformation {...userInfoProps} />
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
		)
	}
)
export default DesktopNavigation
