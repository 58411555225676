import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { debriefOnProccesSelector } from 'redux/debrief/debrief-selector'

import { DebriefEvent } from 'interface/debrief/debrief-interface'

export const useMatchPointsList = () => {
	const debrief = useSelector(debriefOnProccesSelector)
	return useMemo(() => {
		if (!debrief) return []

		const { events } = debrief
		return events.filter((event: DebriefEvent) => event.isMatchPoint)
	}, [debrief?.events])
}
