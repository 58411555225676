import { FC, useContext, useRef } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { UserAnswer } from '../ActionButtons/index'
import { iconNameToIconMap } from '../../../utils/iconsMapper'
import { useStyles } from './useStyles'
import { AnswersGroupProp } from '../../../interface/bot/index'
import ActionButtons from 'components/Bot/ActionButtons'
import { UseClickActions } from '../useHooks/index'
import { AppDirectionContext } from 'context/languages-context'
import ScrollableContent from 'components/common/ScrollableContent/ScrollableContent'

const SingleChoiceAltGrid: FC<AnswersGroupProp> = ({
	isPraise,
	answers,
	nextQuestion,
	previousQuestion,
	setQuestion,
	type,
}) => {
	const { addAnswer } = UseClickActions(isPraise!)
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	// alert('here!')
	const content = (
		<Box className={classes.wrapper}>
			<Grid
				container
				rowSpacing={1}
				columnSpacing={{ xs: 1, sm: 1, md: 1 }}
				id='answerCard'
			>
				{answers?.map(({ title, nextQuestion: _nextQuestion, icon }, id) => {
					const answer: UserAnswer = {
						id,
						answer: title,
						previousQuestion,
						nextQuestion: _nextQuestion ? _nextQuestion : nextQuestion!,
						type,
					}

					return (
						<Grid item xs={10} md={6} key={id}>
							<div
								key={id}
								className={classes.answerCard}
								onClick={() => addAnswer(answer)}
							>
								<img
									className={classes.contextIconStyle}
									src={iconNameToIconMap[icon ? icon : 'socialNetworkIcon']}
									alt=''
								/>
								{title}
							</div>
						</Grid>
					)
				})}
			</Grid>
		</Box>
	)
	return (
		<>
			<ScrollableContent content={content} />
			<ActionButtons
				isEnabled
				type={type}
				isPraise={isPraise}
				showPreviousButton
				previousQuestionId={previousQuestion}
				setQuestion={setQuestion}
			/>
		</>
	)
}
export default SingleChoiceAltGrid
