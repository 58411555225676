import { makeStyles } from "@material-ui/core"
import { Theme } from "@mui/material"

type Styles = {
    isRtlDirection: boolean
    matchPointColor?: string
}
export default makeStyles<Theme, Styles>(() => ({
    description: {
        width: ({ isRtlDirection }) => (isRtlDirection ? '540px' : '650px'),
        height: '100%',

        borderRight: '1px solid white',
        paddingLeft: ({ isRtlDirection }) => (isRtlDirection ? 0 : '8px'),
        paddingRight: ({ isRtlDirection }) => (isRtlDirection ? '8px' : 0),
        display: /*with input element: 'flex', with textarea: */'-webkit-box',
        alignItems: 'center',
        '& >span': {
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            wordWrap: /* with input element: 'nowrap', with textarea: */'break-word',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&>span': {
                color: ({ matchPointColor }) =>
                    matchPointColor ? matchPointColor : 'grey',
            }
        }
    },
    lessonInputField: {
        resize: 'none',
        width: ({ isRtlDirection }) => (isRtlDirection ? '540px' : '650px'),
        border: 'none',
        outline: 'none',
        backgroundColor: 'inherit',
        caretColor: ({ matchPointColor }) =>
            matchPointColor ? matchPointColor : 'default',
        fontSize: '14px',
        paddingLeft: '8px',
        color: ({ matchPointColor }) => (matchPointColor ? matchPointColor : 'grey'),
    },
}))