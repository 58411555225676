import { createAsyncThunk } from '@reduxjs/toolkit'
import { Task, TasksAction } from 'interface/todos/todos-interface'
import {
	fetchUserTodos,
	loadMoreTasks,
	createTodo,
	updateTodo,
	deleteTask,
	restoreTask,
	toggleTasksState,
	loadDeletedTasks,
	updateTaskStatus
} from 'api/todos'
import {
	FetchUserTodos,
	DeleteTask,
	PayloadUpdateTask,
	RestoreTask,
	CreateTask,
	LazyloadPayload
} from 'interface/api/get-payload.types'
import { requestErrorActions } from '../requestError/request-error-reducer'
import { Req } from 'enums/req/req.enum'
import { TodoStatusOptions } from 'enums/todos/todos.enum'

export const get_user_todos = createAsyncThunk<TasksAction, FetchUserTodos>(
	'todos/fetchUserTodos',
	async (payload: FetchUserTodos, { rejectWithValue }) => {
		try {
			const { data, status } = await fetchUserTodos(payload)

			if (status === Req.failed) throw new Error(data)

			return data
		} catch (err: any) {
			return rejectWithValue(err)
		}
	}
)

export const load_more_tasks = createAsyncThunk(
	'todo/loadMoreTasks',
	async (payload: LazyloadPayload, { rejectWithValue }) => {
		try {
			const { data, status } = await loadMoreTasks(payload)
			if (status === Req.failed) throw new Error(data)

			return { data, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const create_todos = createAsyncThunk(
	'todos/createTodos',
	async (payload: CreateTask, { rejectWithValue, dispatch }) => {
		try {
			const { data, status, code } = await createTodo(payload)

			if (status === Req.failed) dispatch(requestErrorActions.setError({ message: data, statusCode: code }))

			dispatch(requestErrorActions.setError({ message: 'known_recipient', statusCode: code }))

			return { data, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const update_todos = createAsyncThunk(
	'todos/updateTodos',
	async (payload: PayloadUpdateTask, { rejectWithValue, dispatch }) => {
		try {
			const { data, status, code } = await updateTodo(payload)

			if (status == Req.failed) dispatch(requestErrorActions.setError({ message: data, statusCode: code }))

			dispatch(requestErrorActions.setError({ message: 'known_recipient', statusCode: code }))

			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const delete_task = createAsyncThunk(
	'todos/deleteTodo',
	async (payload: DeleteTask, { rejectWithValue, dispatch }) => {
		try {
			const { data, status, code } = await deleteTask(payload)
			if (status === Req.failed) dispatch(requestErrorActions.setError({ message: data, statusCode: code }))

			dispatch(requestErrorActions.setError({ message: 'task_deleted', statusCode: code }))
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const restore_task = createAsyncThunk(
	'todos/restoreTask',
	async (payload: RestoreTask, { rejectWithValue }) => {
		try {
			const { data, status } = await restoreTask(payload)
			if (status === Req.failed) throw new Error(data)

			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const tasks_viewed = createAsyncThunk(
	'todos/toggleTasksState',
	async (payload: { company_id: string, todos_id: string, viewer: string }, { rejectWithValue, dispatch }) => {
		try {
			const { data, status, code } = await toggleTasksState(payload)
			if (status === Req.failed) dispatch(requestErrorActions.setError({ message: data, statusCode: code }))

			return { data, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const update_task_status = createAsyncThunk(
	'todos/updateTaskStatus',
	async (payload: { company_id: string, status: TodoStatusOptions, todo_id: string }, { rejectWithValue }) => {
		try {
			const { data, status } = await updateTaskStatus(payload)

			return { data: { status: payload.status, todo_id: payload.todo_id }, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const load_deleted_employee_tasks = createAsyncThunk(
	'todos/loadDeletedEmployeeTasks',
	async (payload: FetchUserTodos, { rejectWithValue }) => {
		try {
			const { data, status } = await loadDeletedTasks(payload)
			if (status === Req.failed) throw new Error(data)

			return { data, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)