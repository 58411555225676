import { useTranslation } from 'react-i18next'
import classes from './styles.module.css'
import { TodoStatusOptions } from 'enums/todos/todos.enum'
import { ChangeEvent, useState } from 'react'
import colorLabel from 'utils/colorTodosLabel'

type Props = {
    defaultValue?: TodoStatusOptions
    name: string
    handleChange: (e: ChangeEvent<HTMLSelectElement>) => void
}
const TodoStatusDropdown = ({ defaultValue, name, handleChange }: Props) => {
    const [selectColor, setSelectColor] = useState(colorLabel(defaultValue))
    const { t } = useTranslation()
    const labels = Object.values(TodoStatusOptions)

    const options = () => (
        labels.map((status: TodoStatusOptions, index: number) => {
            const selected = defaultValue === status ? true : false
            return (
                <option
                    key={index}
                    selected={selected}
                    style={{ color: colorLabel(status) }}
                    value={status}
                >
                    {t(status)}
                </option>
            )
        }))

    const onOptionChanged = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = labels.find(val => val == e.target.value)!
        setSelectColor(prev => colorLabel(value))

        handleChange(e)
    }
    return (
        <div className={classes.dropdown}>
            <select name={name} onChange={onOptionChanged} style={{ color: selectColor }}>
                {options()}
            </select>
        </div>
    )
}

export default TodoStatusDropdown;