import { makeStyles, Theme } from '@material-ui/core/styles'
import { Styles } from 'screens/Bot/useStyles'
export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
	contactForm: {
		marginInlineStart: '15%',
		width: '25%',
		display: 'flex',
		flexDirection: 'column',
		[breakpoints.down('sm')]: {
			width: '100%',
			margin: '0 5%',
		},
	},
	inputsContainer: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '1%',
		'& label': {
			marginTop: '3%',
		},
		'& input': {
			'&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
				'-webkit-appearance': 'none',
				display: 'none',
			},
		},
	},
	pageInfo: {
		'& p, h2': {
			fontStyle: 'normal',
			fontWeight: 'normal',
		},
		'& p': {
			padding: '0',
			margin: '0',
			[breakpoints.down('sm')]: {
				fontSize: '18px',
				maxWidth: '80%',
				margin: '0.5% 0 10%',
			},
		},

		'& h1': {
			margin: '0 0 0.5% 0',
			padding: '0',

			[breakpoints.down('sm')]: {
				fontStyle: 'normal',
				fontWeight: 600,
				fontSize: '20px',
			},
		},
		'& h2': {
			[breakpoints.down('sm')]: {
				fontSize: '16px',
			},
		},
		color: '#46484a',
		marginInlineStart: '15%',

		margin: '2% 0',
		[breakpoints.down('sm')]: {
			marginLeft: '5%',
			marginInlineStart: '5%',
		},
	},
	contactImg: {
		left: ({ isRtlDirection }) => (isRtlDirection ? 0 : ''),
		position: 'absolute',
		top: '50%',
		right: '0',
		bottom: '0',
		display: 'block',
		marginTop: 'auto',
		width: '400px',
		[breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	button: {
		alignSelf: 'center',
		width: '50%',
		marginTop: '4%',
		[breakpoints.down('sm')]: {
			maxWidth: '224px',
		},
	},
	mainContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	progress: {
		marginTop: '4%',
		display: 'flex',
		justifyContent: 'center',
		margin: 'auto',
		width: '50%',
	},
	notification: {
		fontWeight: 500,
		padding: '5%',
		textAlign: 'center',
		color: ({ notification }) => (notification === 'Sent successfuly' ? 'green' : 'tomato')
	},
}))
