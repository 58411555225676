import { Debrief } from 'interface/debrief/debrief-interface'
import { useDispatch, useSelector } from 'react-redux'
import {
	create_debrief_event,
	update_debrief_event,
	delete_debrief_event,
	get_user_debriefings,
} from 'redux/debrief/debrief-actions'
import { debriefOnProccesSelector } from 'redux/debrief/debrief-selector'
import { userSelector } from 'redux/user/user-selector'

const useManageMatchpoints = () => {
	const dispatch = useDispatch()
	const debrief: Debrief | null = useSelector(debriefOnProccesSelector)
	const { _id, company_id } = useSelector(userSelector)

	const createNewMP = (eventDetails: any) => {
		const payload = {
			company_id: company_id!,
			debrief_id: debrief?._id!,
			event: { ...eventDetails },
			shouldDispatch: true,
			nextPayload: {
				output_id: debrief?._id!,
				company_id: company_id!,
				createdAt: new Date(),
				actionAnswer: '',
				assumption: '',
				need: '',
				lessons: [],
				lessonsOwner_id: _id!,
			},
		}
		dispatch(create_debrief_event(payload))
	}
	const updateExistingMP = (payload: any, event_id: string) => {
		dispatch(
			update_debrief_event({
				company_id: company_id,
				debrief_id: debrief?._id!,
				event: { ...payload, _id: event_id },
			})
		)
		dispatch(get_user_debriefings({ user_id: _id, company_id, pageNumber: 0 }))
	}
	const deleteMP = (mp_id: string) => {
		const payload = {
			company_id,
			debrief_id: debrief?._id!,
			event_id: mp_id,
		}
		dispatch(delete_debrief_event(payload))
	}
	return {
		createNewMP,
		updateExistingMP,
		deleteMP,
	}
}

export default useManageMatchpoints
