import { makeStyles } from '@material-ui/core'

export default makeStyles(() => ({
    progresbar: {
        width: 'fit-content',
        margin: '15% auto'
    },
    header: {
        width: '100%',
        textAlign: 'center',
        paddingTop: '5%'
    }
}))