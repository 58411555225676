import { Theme, makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
	answerCard: {
		maxWidth: '469px',
		width: '100%',
		boxSizing: 'border-box',
		height: '98px',
		border: '2.8px solid rgba(49, 49, 49, 0.152716)',
		backgroundColor: '#FFFFFF',
		borderRadius: '14px',
		overflowWrap: 'break-word',
		padding: '0 31px',
		cursor: 'pointer',

		display: 'flex',
		alignItems: 'center',

		[breakpoints.up('sm')]: {
			'&:hover': {
				borderColor: '#0071C2',
				border: '2.8px solid #0071C2',
				boxShadow: '5px 5px 10px rgb(0 113 194 / 19%)',
			},
		},
		[breakpoints.down('sm')]: {
			fontSize: '0.9rem',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			padding: '3%',
		},
	},
	answerCardAlt: {
		color: '#0071C2',
		backgroundColor: '#FFFFFF',
	},
	isChosed: {
		borderColor: '#0071C2',
		border: '2.8px solid #0071C2',
		boxShadow: '5px 5px 10px rgb(0 113 194 / 19%)',
	},
	regular: {
		borderColor: 'rgba(49,49,49,0.15)',
	},
	wrapper: {
		maxWidth: '938px',
		width: '100%',
		marginTop: '33px',
		height: '60vh',
		overflowY: 'auto',

		[breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'center',
			width: '95%',
			height: 'auto',
			marginTop: 0,
			margin: 'auto',
			overflow: 'auto',

			'& > div > div': {
				flex: '1 1 40%;',
			},
		},
	},
	previousButtonWrapper: {
		[breakpoints.down('sm')]: {
			height: '19vh',
			display: 'flex',
			alignItems: 'center',
		},
	},
}))
