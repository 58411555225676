import { Text, StyleSheet, View } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import SayDoPdfLogo from './SaydoPdfLog'

const FileHeader = () => {
    dayjs.extend(localizedFormat)
    const time = dayjs().format('L LT')
    return (
        <>
            <View style={styles.svgContainer}>
                <SayDoPdfLogo />
            </View>
            <Text style={styles.time} fixed>
                {time}
            </Text>
        </>
    )
}

export default FileHeader

const styles = StyleSheet.create({
    time: {
        display: 'flex',
        fontWeight: 400,
        fontSize: '12px',
        padding: '8px'
    },
    svgContainer: {
        width: '100%',
        height: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
})