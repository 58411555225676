import { useState, FC } from 'react'
import { IconButton, Checkbox } from '@material-ui/core'
import Folder from '@material-ui/icons/Folder'

import './style.css'

type Props = {
    isPrivate: boolean
}
const SwitchableFolderIcon: FC<Props> = ({ isPrivate = false }) => {
    const [itemSelected, setItemSelected] = useState(false)

    //const toggleChecked = () => !isPrivate && setItemSelected(prev => !prev)

    const PREFIX_ICON = itemSelected
        ? (<Checkbox
            className='checked'
            checked={itemSelected}
            onChange={e => setItemSelected(e.target.checked)}
        />)
        : (<span className={isPrivate ? 'private' : ''}>
            <Folder /*onClick={toggleChecked}*/ />
        </span>)

    return (
        <IconButton>
            {PREFIX_ICON}
        </IconButton>
    )
}

export default SwitchableFolderIcon