import { createSlice } from '@reduxjs/toolkit'
import { Roles } from 'enums/user/user-roles'
import { Company } from 'interface/company/company-interface'
import { CompanySettings } from 'interface/company/company-interface'
import { ILicenses } from 'interface/Licenses'
import {
	create_lessons_file,
	delete_file,
	delete_folder,
	edit_the_file,
	edit_the_folder,
	get_company_chart,
	get_file,
	get_summaries_activities,
	paste_lesson,
	update_company_activation,
	update_lesson_description,
} from './company-actions'
import {
	get_companies,
	update_company,
	create_company,
	delete_company,
	get_company_values,
	set_company_values,
	send_emails,
	get_company_license,
	set_sms_notifications,
	send_email_to_role,
	get_company_settings,
	get_company_departments,
	get_department_sub_departments,
	get_employees_activities,
	get_company_activity_for_super_admin_v2,
	get_company_exceptions,
	get_user_base_insight,
	load_more_insights,
	get_new_exceptions,
	toggle_task_should_show,
	create_lessons_folder,
	get_folder,
	create_new_lesson
} from './company-actions'

import { IBaseInsight } from 'interface/baseInsight/baseInsight'
import { TaskSection } from 'enums/task/tasks.enum'
import { avoidDuplicationOfTasks } from 'redux/todos/filterUserTasks'
import { ManagerException } from '../../interface/exceptions/exceptions-interface'
import { Team } from 'interface/teams/teams-interface'
import { ILessonsBase, Folder, BaseFolderChild, File } from 'interface/lessons-management/LessonsBase.interface'
import { UI } from 'enums/lessonsManagement'

export enum CompanyActivityRubric {
	total = 'total',
	lastMonth = 'lastMonth',
	avg = 'avg',
}
type SumActivity = { total: number, lastMonth: number }
export type UserActivity = Record<string, SumActivity>

export type EmployeesActivity = {
	[key: string]: UserActivity
}

export interface ICompanyState {
	currentCompanyLicense: ILicenses | undefined
	currentCompanyValues: string[]
	currentCompanyDepartments: string[]
	currentCompanySubDepartments: string[]
	companies: Company[]
	employeesActivities: EmployeesActivity
	loading: boolean
	error: string
	settings: CompanySettings
	companyActivity: {
		praises: { monthlyAvgPerUser: number }
		feedbacks: { monthlyAvgPerUser: number }
		persLearning: { monthlyAvgPerUser: number }
		groupLearning: { monthlyAvgPerUser: number }
		managersTodos: { monthlyAvgPerUser: number }
		employeesTodos: { monthlyAvgPerUser: number }
	}
	companyJoiningDate: string
	companyName: string
	numOfEmployees: number
	companyPraisesActivity: any[]
	companyFeedbacksActivity: any[]
	companyPersLearningActivity: any[]
	companyGroupLearningActivity: any[]
	companyManagerTodosActivity: any[]
	companyEmployeesTodosActivity: any[]
	hasMoreBaseInsight: boolean
	baseInsight: {
		isLoading?: boolean
		tasks: IBaseInsight[]
		lessons: IBaseInsight[]
	}
	companyChartData: any[]
	companyTotalActivity: any[]
	allCompaniesActivities: any[]
	companyExceptions: ManagerException[]
	hasNewExceptions: boolean
	companyTeams: Team
	companyLessons: ILessonsBase<Folder>
}

const initialState: ICompanyState = {
	currentCompanyLicense: undefined,
	currentCompanyValues: [],
	currentCompanyDepartments: [],
	currentCompanySubDepartments: [],
	companies: [],
	companyActivity: {
		praises: { monthlyAvgPerUser: 0 },
		feedbacks: { monthlyAvgPerUser: 0 },
		persLearning: { monthlyAvgPerUser: 0 },
		groupLearning: { monthlyAvgPerUser: 0 },
		managersTodos: { monthlyAvgPerUser: 0 },
		employeesTodos: { monthlyAvgPerUser: 0 },
	},
	companyName: '',
	companyJoiningDate: '',
	numOfEmployees: 0,
	employeesActivities: {} as EmployeesActivity,
	companyPraisesActivity: [],
	companyFeedbacksActivity: [],
	companyPersLearningActivity: [],
	companyGroupLearningActivity: [],
	companyManagerTodosActivity: [],
	companyEmployeesTodosActivity: [],
	companyTeams: { _id: '', manager: '', members_id: [], tasks: [] },
	companyTotalActivity: [],
	allCompaniesActivities: [],
	baseInsight: {
		isLoading: false,
		tasks: [],
		lessons: [],
	},
	hasMoreBaseInsight: true,
	companyChartData: [],
	companyExceptions: [],
	hasNewExceptions: false,
	loading: false,
	error: '',
	settings: {
		isEmployeesNotified: false,
		isManagersNotified: false,
		isHrNotified: false,
		sms: false,
		isActivated: false,
	},
	companyLessons: {} as ILessonsBase<Folder>,
}

export const companySlice = createSlice({
	name: 'company',
	initialState,
	reducers: {
		removeError(state) {
			return {
				...state,
				error: '',
			}
		},
		resetNewExceptionsNofity(state) {
			return {
				...state,
				hasNewExceptions: false,
			}
		},
		setCurrentFolder(state, action) {
			const folder = action.payload
			return {
				...state,
				companyLessons: folder
			}
		}
	},
	extraReducers: builder => {
		// get_companies
		builder.addCase(get_companies.fulfilled, (state, action) => {
			state.companies = action.payload
			state.loading = false
		})
		builder.addCase(get_companies.pending, state => {
			state.loading = true
		})
		builder.addCase(get_companies.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		builder.addCase(get_employees_activities.fulfilled, (state, action) => {
			console.log(action.payload)
			state.employeesActivities = action.payload
			state.loading = false
		})
		builder.addCase(get_employees_activities.pending, state => {
			state.loading = true
		})
		builder.addCase(get_employees_activities.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		builder.addCase(update_company_activation.fulfilled, (state, action) => {
			const updatedCompany = action.payload

			const updatedCompanies = state.companies.map((company: Company) => {
				if (company._id === updatedCompany.company_id) {
					return {
						...company,
						settings: {
							...company.settings,
							isActivated: updatedCompany.activate,
						},
					}
				} else {
					return company
				}
			})
			state.companies = updatedCompanies
			state.loading = false
		})
		builder.addCase(update_company_activation.pending, state => {
			state.loading = true
		})
		builder.addCase(update_company_activation.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		//update_companies
		builder.addCase(update_company.fulfilled, (state, action) => {
			const updatedCompany = action.payload

			const updatedCompanies = state.companies.map((company: Company) => {
				if (company._id === updatedCompany._id) {
					return updatedCompany
				} else {
					return company
				}
			})
			state.companies = updatedCompanies
			state.loading = false
		})
		builder.addCase(update_company.pending, state => {
			state.loading = true
		})
		builder.addCase(update_company.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		//create_company
		builder.addCase(create_company.fulfilled, (state, action) => {
			const newCompany = action.payload
			state.companies = [...state.companies, newCompany]
			state.loading = false
		})
		builder.addCase(create_company.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(create_company.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		//delete_company
		builder.addCase(delete_company.fulfilled, (state, action) => {
			const filterdCompanies = state.companies.filter(
				company => company._id !== action.payload
			)
			state.companies = [...filterdCompanies]
			state.loading = false
		})
		builder.addCase(delete_company.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(delete_company.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})
		//get_company_values
		builder.addCase(get_company_values.fulfilled, (state, action) => {
			const values = action.payload
			state.currentCompanyValues = values
			state.loading = false
		})
		builder.addCase(get_company_values.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(get_company_values.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})
		//set_company_values
		builder.addCase(set_company_values.fulfilled, (state, action) => {
			const values = action.payload
			state.currentCompanyValues = values
			state.loading = false
		})
		builder.addCase(set_company_values.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(set_company_values.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})
		//get_company_departments
		builder.addCase(get_company_departments.fulfilled, (state, action) => {
			const values = action.payload
			state.currentCompanyDepartments = values.filter((v: string) => v !== '')
			state.loading = false
		})
		builder.addCase(get_company_departments.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(get_company_departments.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})
		//get_department_sub_departments
		builder.addCase(get_department_sub_departments.fulfilled, (state, action) => {
			const values = action.payload
			state.currentCompanySubDepartments = values.filter((v: string) => v !== '')
			state.loading = false
		})
		builder.addCase(get_department_sub_departments.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(get_department_sub_departments.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})
		//get_company_license
		builder.addCase(get_company_license.fulfilled, (state, action) => {
			const license = action.payload
			state.currentCompanyLicense = license
			state.loading = false
		})
		builder.addCase(get_company_license.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(get_company_license.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})
		//send_emails
		//we dont use it right now , but maybe good fot the future if we need to send emails by array.
		builder.addCase(send_emails.fulfilled, (state, action) => {
			state.loading = false
		})
		builder.addCase(send_emails.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(send_emails.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})
		//set_sms_notifications
		builder.addCase(set_sms_notifications.fulfilled, (state, action) => {
			state.loading = false
			state.settings.sms = !state.settings.sms
		})
		builder.addCase(set_sms_notifications.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(set_sms_notifications.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})
		//send_email_to_role
		builder.addCase(send_email_to_role.fulfilled, (state, action) => {
			state.loading = false
			switch (action.payload) {
				case Roles.EMPLOYEE: {
					state.settings.isEmployeesNotified = true
					break
				}
				case Roles.MANAGER: {
					state.settings.isManagersNotified = true
					break
				}
				case Roles.HR: {
					state.settings.isHrNotified = true
					break
				}
			}
		})
		builder.addCase(send_email_to_role.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(send_email_to_role.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		//get_company_settings
		builder.addCase(get_company_settings.fulfilled, (state, action) => {
			const settings = action.payload
			state.settings = settings
			state.loading = false
		})
		builder.addCase(get_company_settings.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(get_company_settings.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		//get company activity
		builder.addCase(
			get_company_activity_for_super_admin_v2.fulfilled,
			(state, action) => {
				const allActivities = action.payload
				const {
					praises,
					feedbacks,
					persLearning,
					groupLearning,
					managersTodos,
					employeesTodos,
					totalActivities,
					companyName,
					numOfEmployees,
					createdAt,
				} = allActivities

				state.companyPraisesActivity = praises
				state.companyFeedbacksActivity = feedbacks
				state.companyPersLearningActivity = persLearning
				state.companyGroupLearningActivity = groupLearning
				state.companyManagerTodosActivity = managersTodos
				state.companyEmployeesTodosActivity = employeesTodos
				state.companyTotalActivity = totalActivities
				state.companyName = companyName
				state.numOfEmployees = numOfEmployees
				state.companyJoiningDate = createdAt

				state.loading = false
			}
		)
		builder.addCase(
			get_company_activity_for_super_admin_v2.pending,
			(state, action) => {
				state.loading = true
			}
		)
		builder.addCase(
			get_company_activity_for_super_admin_v2.rejected,
			(state, action) => {
				state.error = action.error.message!
				state.loading = false
			}
		)

		builder.addCase(get_summaries_activities.fulfilled, (state, action) => {
			const data = action.payload
			state.loading = false
			state.allCompaniesActivities = data
		})
		builder.addCase(get_summaries_activities.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(get_summaries_activities.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		builder.addCase(get_company_chart.fulfilled, (state, action) => {
			const data = action.payload
			state.loading = false
			state.companyChartData = data
		})
		builder.addCase(get_company_chart.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(get_company_chart.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		builder.addCase(get_company_exceptions.fulfilled, (state, action) => {
			const data = action.payload

			state.companyExceptions = data.exceptions
			state.loading = false
		})
		builder.addCase(get_company_exceptions.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(get_company_exceptions.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		builder.addCase(get_new_exceptions.fulfilled, (state, action) => {
			const shouldNotifyHr = action.payload
			state.hasNewExceptions = shouldNotifyHr
			state.loading = false
		})
		builder.addCase(get_new_exceptions.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(get_new_exceptions.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		builder.addCase(get_user_base_insight.fulfilled, (state, action) => {
			state.loading = false
			state.baseInsight.isLoading = false
			const insights = action.payload.data
			// TODO: FIX action for infinite scroll
			if (!action.payload.data.length) state.hasMoreBaseInsight = false

			state.baseInsight.tasks = insights.filter((t: IBaseInsight) => t.date)
			state.baseInsight.lessons = insights.filter((t: IBaseInsight) => !t.date)
		})
		builder.addCase(get_user_base_insight.pending, state => {
			state.loading = true
			state.baseInsight.isLoading = true
		})
		builder.addCase(get_user_base_insight.rejected, (state, action) => {
			state.error = `${action.payload}`
			state.loading = false
			state.baseInsight.isLoading = false
		})

		builder.addCase(load_more_insights.fulfilled, (state, action) => {
			state.baseInsight.isLoading = false
			const tasks = action.payload.data
			const scrollPosition = action.payload.type
			if (scrollPosition == TaskSection.LESSONS) {
				state.baseInsight.lessons = avoidDuplicationOfTasks(
					state.baseInsight.lessons.concat(tasks)
				)
			} else {
				state.baseInsight.tasks = avoidDuplicationOfTasks(
					state.baseInsight.tasks.concat(tasks)
				)
			}

			state.loading = false
		})
		builder.addCase(load_more_insights.pending, state => {
			// state.loading = true
			state.baseInsight.isLoading = true
		})
		builder.addCase(load_more_insights.rejected, (state, action) => {
			state.error = `${action.payload}`
			state.loading = false
			state.baseInsight.isLoading = false
		})

		builder.addCase(toggle_task_should_show.fulfilled, (state, action) => {
			state.loading = false
			state.baseInsight.tasks = [...action.payload.data].filter(
				(t: IBaseInsight) => t.date
			)
			state.baseInsight.lessons = [...action.payload.data].filter(
				(t: IBaseInsight) => !t.date
			)
		})
		builder.addCase(toggle_task_should_show.pending, state => {
			state.loading = true
		})
		builder.addCase(toggle_task_should_show.rejected, (state, action) => {
			state.error = `${action.payload}`
			state.loading = false
		})

		builder.addCase(get_folder.fulfilled, (state, action) => {
			state.loading = false
			const folder = action.payload.data
			state.companyLessons = folder
		})
		builder.addCase(get_folder.pending, state => {
			state.loading = true
		})
		builder.addCase(get_folder.rejected, (state, action) => {
			state.error = `${action.payload}`
			state.loading = false
		})

		builder.addCase(get_file.fulfilled, (state, action) => {
			state.loading = false
			const file = action.payload.data
			state.companyLessons = file
		})
		builder.addCase(get_file.pending, state => {
			state.loading = true
		})
		builder.addCase(get_file.rejected, (state, action) => {
			state.error = `${action.payload}`
			state.loading = false
		})

		builder.addCase(create_lessons_folder.fulfilled, (state, action) => {
			state.loading = false
			const folder = action.payload.data
			folder.ui = UI.FOLDER
			state.companyLessons.type.children.unshift(folder)
		})
		builder.addCase(create_lessons_folder.pending, state => {
			state.loading = true
		})
		builder.addCase(create_lessons_folder.rejected, (state, action) => {
			state.error = `${action.payload}`
			state.loading = false
		})

		builder.addCase(create_lessons_file.fulfilled, (state, action) => {
			state.loading = false
			const file = action.payload.data
			file.ui = UI.FILE
			state.companyLessons.type.children.unshift(file)
		})
		builder.addCase(create_lessons_file.pending, state => {
			state.loading = true
		})
		builder.addCase(create_lessons_file.rejected, (state, action) => {
			state.error = `${action.payload}`
			state.loading = false
		})

		builder.addCase(edit_the_folder.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(edit_the_folder.rejected, (state, action) => {
			state.error = `${action.payload}`
			state.loading = false
		})
		builder.addCase(edit_the_folder.fulfilled, (state, action) => {
			const folder = action.payload.data as ILessonsBase<Folder>

			const isEditCurrentFolder = folder._id === state.companyLessons._id

			if (isEditCurrentFolder) state.companyLessons = folder
			else {
				const updatedChildren = state.companyLessons.type.children.map((f: BaseFolderChild) => {
					if (f._id === folder._id) return {
						name: folder.name,
						ui: UI.FOLDER,
						permissions: folder.permissions,
						_id: folder._id
					}

					else return f
				}) as BaseFolderChild[]

				state.companyLessons.type.children = updatedChildren
			}
		})

		builder.addCase(edit_the_file.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(edit_the_file.rejected, (state, action) => {
			state.loading = false
			state.error = `${action.payload}`
		})
		builder.addCase(edit_the_file.fulfilled, (state, action) => {
			const file = action.payload.data as ILessonsBase<File>
			console.log({ file })
			const updatedChildren = state.companyLessons.type.children.map((f: BaseFolderChild) => {
				if (f._id === file._id) return {
					name: file.name,
					ui: UI.FILE,
					permissions: file.permissions,
					_id: file._id
				}

				else return f
			}) as BaseFolderChild[]
			console.log({ updatedChildren })
			state.companyLessons.type.children = updatedChildren
		})

		builder.addCase(delete_folder.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(delete_folder.rejected, (state, action) => {
			state.loading = false
			state.error = `${action.payload}`
		})
		builder.addCase(delete_folder.fulfilled, (state, action) => {
			const folder_id: string = action.payload.data

			const updatedFolders =
				state.companyLessons.type.children.filter((f: BaseFolderChild) => f._id !== folder_id)
			console.log({ updatedFolders })
			state.companyLessons.type.children = updatedFolders
		})

		builder.addCase(delete_file.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(delete_file.rejected, (state, action) => {
			state.loading = false
			state.error = `${action.payload}`
		})
		builder.addCase(delete_file.fulfilled, (state, action) => {
			const file_id: string = action.payload.data

			const updatedFolders =
				state.companyLessons.type.children.filter((f: BaseFolderChild) => f._id !== file_id)

			state.companyLessons.type.children = updatedFolders
		})

		builder.addCase(paste_lesson.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(paste_lesson.rejected, (state, action) => {
			state.loading = false
			state.error = `${action.payload}`
		})
		builder.addCase(paste_lesson.fulfilled, (state, action) => {
			const updateFile = action.payload.data

			state.companyLessons = updateFile
		})

		builder.addCase(create_new_lesson.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(create_new_lesson.rejected, (state, action) => {
			state.loading = false
			state.error = `${action.payload}`
		})
		builder.addCase(create_new_lesson.fulfilled, (state, action) => {
			const updatedLessonsList = action.payload.data

			state.companyLessons = updatedLessonsList
		})

		builder.addCase(update_lesson_description.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(update_lesson_description.rejected, (state, action) => {
			state.loading = false
			state.error = `${action.payload}`
		})
		builder.addCase(update_lesson_description.fulfilled, (state, action) => {
			const updatedLessonsList = action.payload.data

			state.companyLessons = updatedLessonsList
		})


	},
})

export const companyActions = companySlice.actions
export default companySlice.reducer
