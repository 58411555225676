import dayjs from 'dayjs'
import { Debrief } from 'interface/debrief/debrief-interface'
import { useTranslation } from 'react-i18next'
import { Row } from './DebriefTable'
export const GetColName = (field: string) => {
	const { t } = useTranslation()
	let colName = t(`debrief.${field}`)
	return colName
}
const sortDefProps = { unSortIcon: true, sortable: true }
const baseDefsProps = {
	suppressMovable: true,
	resizable: false,
	filter: false,
	cellStyle: {
		display: 'flex',
		alignItems: 'center',
		wordBreak: 'break-all',
	},
}

export function getData(
	debriefings: Debrief[],
	isRtlDirection: boolean
): Array<Row> {
	var rows: Array<Row> = []

	debriefings.forEach((debrief: Debrief) => {
		const achieved_text = isRtlDirection ? 'הושגה' : 'Achieved'
		const unachieved_text = isRtlDirection ? 'לא הושגה' : 'Not achieved'
		const row = {
			number: debrief.code,
			date: dayjs(debrief.createdAt).format('DD/MM/YYYY'),
			description: debrief.expectedResult?.description
				? debrief.expectedResult.description
				: '',
			result:
				debrief.expectedResult?.achieved == null
					? ''
					: debrief.expectedResult?.achieved
					? achieved_text
					: unachieved_text,
			creator:
				debrief.creator?.personalInfo.firstName +
				' ' +
				debrief.creator?.personalInfo.lastName,
			/*department: debrief.relatedDepartment ? debrief.relatedDepartment : '',
			sub_department: debrief.relatedSubDepartment
				? debrief.relatedSubDepartment
				: '',*/
			button: '',
		}
		rows.push(row)
	})

	return rows
}
//date comparision algorithms
export const monthToComparableNumber = (date: string) => {
	if (date === undefined || date === null || date.length !== 10) {
		return null
	}
	const yearNumber = Number.parseInt(date.substring(6, 10))
	const monthNumber = Number.parseInt(date.substring(3, 5))
	const dayNumber = Number.parseInt(date.substring(0, 2))
	return yearNumber * 10000 + monthNumber * 100 + dayNumber
}
export const dateComparator = (date1: string, date2: string) => {
	const date1Number = monthToComparableNumber(date1)
	const date2Number = monthToComparableNumber(date2)
	if (date1Number === null && date2Number === null) {
		return 0
	}
	if (date1Number === null) {
		return -1
	}
	if (date2Number === null) {
		return 1
	}
	return date1Number - date2Number
}

export const tableNumberDef = {
	...baseDefsProps,
	field: 'number',
	maxWidth: 90,
}
export const tableDateDef = {
	...baseDefsProps,
	...sortDefProps,
	comparator: dateComparator,
	field: 'date',
}
export const tableResultDef = {
	...baseDefsProps,
	...sortDefProps,
	field: 'result',
}
export const tableDescriptionDef = {
	...baseDefsProps,
	...sortDefProps,

	minWidth: 130,
	field: 'description',
}
export const tableCreatorDef = {
	...baseDefsProps,
	...sortDefProps,

	field: 'creator',
}
export const tableDepartmentDef = {
	...baseDefsProps,
	...sortDefProps,
	field: 'department',
}
export const tableSubDepartmentDef = {
	...baseDefsProps,
	...sortDefProps,
	cellStyle: {
		...baseDefsProps.cellStyle,
		'text-overflow': 'ellipsis',
		'white-space': 'nowrap',
		overflow: 'hidden',
		minWidth: '20%',
	},
	field: 'sub_department',
}
export const tableButtonDef = {
	minWidth: 100,
	...baseDefsProps,
	filter: false,
	resizable: false,
}
