import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { AppDirectionContext } from 'context/languages-context'

import useStyles from './styles'

import {
	get_company_settings,
	send_email_to_role,
} from 'redux/company/company-actions'

import SlideButton from 'components/common/SlideButton'

import { Roles } from 'enums/user/user-roles'
import { IStoreRootState } from 'interface/redux'
import Envlope from 'assets/envelop.png'
import Rocket from 'assets/rocket.png'

interface Props {
	company_id: string
}

const CompanySettings = ({ company_id }: Props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const dispatch = useDispatch()

	const loading = useSelector((state: IStoreRootState) => state.company.loading)
	
	const isManagersNotified = useSelector(
		(state: IStoreRootState) => state.company.settings.isManagersNotified
	)
	const isEmployeesNotified = useSelector(
		(state: IStoreRootState) => state.company.settings.isEmployeesNotified
	)

	useEffect(() => {
		dispatch(get_company_settings(company_id))
	}, [])
	
	return (
		<div>
			<h1 className={classes.title}>{t('activate_company')}</h1>
			<div className={classes.content}>
				<div className={classes.companyActions}>
					{/* <div className={classes.activateContainer}>
						{loading ? (
							<CircularProgress />
						) : (
							<>
								<h2>SMS notifications</h2>
								<SwitchUnstyled
									onChange={() => {
										dispatch(set_sms_notifications({ company_id, isSms: !smsSelector }))
									}}
									checked={smsSelector}
									component={Root}
								/>
							</>
						)}
					</div> */}
					<SlideButton
						switchedOn={isManagersNotified}
						loading={loading && !isManagersNotified}
						onAction={() => {
							dispatch(send_email_to_role({ company_id, role: Roles.MANAGER }))
						}}
						disabled={false}
						text={{
							primary: t('email_managers'),
							secondary: t('email’s_has_been_sent'),
						}}
						leftText={isRtlDirection ? '-50%' : '69%'}
						icon={Envlope}
					/>
					<SlideButton
						switchedOn={isEmployeesNotified}
						loading={loading && !isEmployeesNotified}
						onAction={() => {
							dispatch(send_email_to_role({ company_id, role: Roles.EMPLOYEE }))
						}}
						disabled={!isManagersNotified}
						text={{
							primary: t('start_company_activation'),
							secondary: t('company_launched'),
						}}
						icon={Rocket}
						leftText={isRtlDirection ? '-55%' : '56%'}
					/>
				</div>
			</div>
		</div>
	)
}

export default CompanySettings
