import { makeStyles, Theme, lighten } from '@material-ui/core/styles'
import Paths from 'enums/routes/path-names'
import { IThemeOptions } from 'theme'

interface StylesProps {
	screen: string
	isPraise?: boolean
}

export default makeStyles<Theme & IThemeOptions, StylesProps>(
	({ palette, breakpoints }) => ({
		container: {
			textAlign: 'center',
			border: ({ screen }) =>
				screen === Paths.DEBRIEF_PROCESS
					? `1px dashed ${palette.debrief.main}`
					: `1px dashed ${palette.secondary.main}`,
			borderRadius: '10px',
			cursor: 'pointer',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',

			background: lighten(palette.divider, 0.7),

			'& >div': {

				justifyContent: 'space-evenly',
				alignItems: 'center',

				"& > svg": {
					position: 'static' as any,

				},
			},
			[breakpoints.down('sm')]: {
				height: '112px',
				justifyContent: 'initial',
			},
		},
		actionWrapper: {
			width: '100%',
			height: '100%',

			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-evenly',
		},
		addIcon: {
			color: ({ screen, isPraise }) =>
				isPraise
					? palette.praise.main
					: screen === Paths.DEBRIEF_PROCESS
						? palette.debrief.main
						: palette.secondary.main,
			fontSize: '1.5rem',
			// margin: '100px 0 65px',
			[breakpoints.down('sm')]: {
				margin: '35px 0 10px',
			},
		},
		sendFeedbackText: {
			color: ({ screen, isPraise }) =>
				isPraise
					? palette.praise.main
					: screen === Paths.DEBRIEF_PROCESS
						? palette.debrief.main
						: palette.secondary.main,
			position: 'relative',
			top: '11%',
			fontSize: '1.5rem',

			fontWeight: 500,
			justifySelf: 'flex-end',
			[breakpoints.down('sm')]: {
				marginBottom: '12px',
			},
		},
	})
)
