import { createSelector } from "reselect"
import { IStoreRootState } from "interface/redux/index"
import { Team } from "interface/teams/teams-interface"
import IUser from "interface/user/user-interface"

export const teamsStateSelector = (state: IStoreRootState) => state.teams

export const teamSelector = createSelector(
    teamsStateSelector,
    state => state.team
)
export const teamLoading = createSelector(
    teamsStateSelector,
    state => state.loading
)

export const teamsUpdated = createSelector(
    teamsStateSelector,
    state => state.updated || false
)