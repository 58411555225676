import { useCallback, useContext, useEffect, useState } from 'react'
import { IUser } from 'interface/user/user-interface'
import { EditUser } from 'components/CompanyBuilder/Builder/DropDownBuilder'
import { useDispatch } from 'react-redux'
import { userSelector } from 'redux/user/user-selector'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { userActions } from 'redux/user/user-reducer'
import { edit_user_action, interval_job } from 'redux/user/user-actions'
import { AppDirectionContext } from '../../../../context/languages-context'
import { allowChangePassword } from 'api/user'
import useFormHook from 'components/CompanyBuilder/Builder/useForm'
import {
	IUserSecurityInfo,
	IUserPersonalInfo,
} from '../../../../interface/user/user-interface'

type PropsType = {
	hanaldeToggleCard?: () => void
	// hanaldeToggleCard: React.Dispatch<React.SetStateAction<boolean>>
}
//update the img
const CLOUDINARY_API = 'https://api.cloudinary.com/v1_1/sayDo/image/upload'
export const handleUserSaveImageInCloud = async (data: any) => {
	const response = await fetch(CLOUDINARY_API, {
		method: 'post',
		body: data,
	})
	const result = await response.json()
	return result.url
}
//merge old and new data to iuser obj
export const userEditToIUser = (
	editingInfo: EditUser,
	oldInfo: IUser
): IUser => {
	const { mobile_prefix, mobile, uploadImageUrl } = editingInfo
	const { personalInfo, securityInfo, statusInfo, _id, company_id } = oldInfo
	const profileImage = uploadImageUrl
		? uploadImageUrl
		: personalInfo.imgSrc
		? personalInfo.imgSrc
		: ''

	const userUpdatedinfo: IUser = {
		_id,
		company_id,
		personalInfo: {
			firstName: personalInfo?.firstName,
			lastName: personalInfo?.lastName,
			mobile:
				mobile || mobile_prefix ? mobile_prefix + mobile : personalInfo?.mobile,
			imgSrc: profileImage,
		},
		securityInfo: {
			...securityInfo,
		},
		statusInfo: {
			...statusInfo,
		},
	}
	return userUpdatedinfo
}
const PULLSIM_TEMPLATE_PERFIX = 11
const useLogic = ({ hanaldeToggleCard }: PropsType) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const { toggleDirection } = useContext(AppDirectionContext)
	const [uploadImage, setUploadedImage] = useState<{
		previewImage: string
		data: any
	}>({ previewImage: '', data: {} })

	const user = useSelector(userSelector)
	const { personalInfo = {}, securityInfo = {} } = user || {}
	const { email = '' } = securityInfo as IUserSecurityInfo
	const { mobile = '' } = personalInfo as IUserPersonalInfo

	const geoMobilePerfix = mobile.length >= PULLSIM_TEMPLATE_PERFIX
	const { handleChange, errors, values } = useFormHook<EditUser, EditUser>(
		{
			mobile_prefix: geoMobilePerfix ? mobile.slice(0, 4) : mobile.slice(0, 3),
			mobile: geoMobilePerfix
				? mobile?.slice(4, mobile.length)
				: mobile?.slice(3, mobile.length),
		} as EditUser,
		{} as EditUser
	)
	const [disabled, setDisabled] = useState(true)
	const mobileChanged = mobile !== values.mobile_prefix + values.mobile

	useEffect(() => {
		setDisabled(errors.mobile ? true : mobileChanged ? false : true)
	}, [mobileChanged, errors.mobile])

	const handleMobileChange = (e: any) => {
		if (!errors.mobile) {
			setDisabled(false)
		}
		handleChange(e)
	}

	const buildUserPayload = async (data?: any) => {
		console.log({ data, uploadImage: uploadImage.data })
		const userImageUrl: any = await handleUserSaveImageInCloud(
			data ?? uploadImage.data
		)
		const editedUser = userEditToIUser(
			{ ...values, uploadImageUrl: userImageUrl },
			user
		)

		const payload: IUser = {
			...editedUser,
			securityInfo: {
				...editedUser.securityInfo,
				email: user.securityInfo.email,
			},
			personalInfo: {
				...editedUser.personalInfo,
			},
		}
		return payload
	}

	const saveAvatarViaMobile = (payload: IUser) => {
		dispatch(edit_user_action(payload))
		dispatch(userActions.editUser(payload))
		setUploadedImage({ previewImage: '', data: {} })
		hanaldeToggleCard?.()
	}
	const handleNewEmployeeSubmition = useCallback(
		async e => {
			e.preventDefault()
			const payload = await buildUserPayload()
			dispatch(edit_user_action(payload))
			dispatch(userActions.editUser(payload))
			setUploadedImage({ previewImage: '', data: {} })
			hanaldeToggleCard?.()
		},
		[
			hanaldeToggleCard,
			dispatch,
			handleUserSaveImageInCloud,
			uploadImage,
			userActions.editUser,
			user,
			values,
		]
	)

	const handleUploadFile = useCallback((image: any) => {
		setDisabled(false)
		const data: any = new FormData()
		data.append('file', image)
		data.append('upload_preset', 'qkjibttv')
		data.append('cloud_name', 'sayDo')
		setUploadedImage({
			previewImage: URL.createObjectURL(image).toString(),
			data,
		})
		return data
	}, [])

	//handle change signout action
	const handleSignout = () => {
		history.push(`/`)
		dispatch(userActions.signOutUser())
		const payload = {
			user_id: user._id,
			loginAction: false,
		}
		// dispatch(interval_job(payload))
		toggleDirection(false)
	}
	//handle change password action
	const handleChangePasswordClick = () => {
		allowChangePassword(email)
		history.push(`/change-password/${email}`)
		dispatch(userActions.signOutUser())
	}
	return {
		buildUserPayload,
		saveAvatarViaMobile,
		handleChangePasswordClick,
		handleNewEmployeeSubmition,
		handleChange: handleMobileChange,
		handleUploadFile,
		handleSignout,
		disabled,
		previewImage: uploadImage.previewImage,
		user,
		values,
		errors,
	}
}

export default useLogic
