import InitialMessage from 'components/Debriefing/InitialMessage'
import { DebriefingPaths } from 'enums/debriefing/debriefing-paths.enum'
import { EMPTY_STATE } from '../useTreeLogic'
import {
	CHOOSE_PATH,
	EXPECTED_RESULT,
	PARTICIPANTS_CHOICE,
} from '../stepMapComponent'

export const msgSteps = ['1', '2', '3', '4']

export const RenderInitialMessage = ({ ...props }) => {
	const { debrief, updateDebrief } = props

	const pageIcon = {
		src: 'heartIcon',
		opacity: '',
	}
	const isGroupPath = debrief?.path === DebriefingPaths.GROUP
	const content = (
		<InitialMessage
			msgData={isGroupPath ? INITIAL_MSG_GROUP_DATA : undefined}
			showStepDescription={true}
			setData={updateDebrief}
			prevStep={CHOOSE_PATH}
			nextStep={isGroupPath ? PARTICIPANTS_CHOICE : EXPECTED_RESULT}
		/>
	)
	return { ...EMPTY_STATE, content, pageIcon }
}

const INITIAL_MSG_GROUP_DATA = {
	type: 'initial_group_msg',
	steps: [...msgSteps, '5', '6'],
}
