import { makeStyles } from "@material-ui/core"
import { Theme } from "@mui/material"

type Styles = {
    isRtlDirection?: boolean
}
export default makeStyles<Theme, Styles>(({ breakpoints }) => ({
    contentModalStyle: {
        position: 'absolute' as 'absolute',
        top: '50%',
        direction: ({ isRtlDirection }) => (isRtlDirection ? 'rtl' : 'ltr'),
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'fit-content',
        maxWidth: 400,
        height: 'fit-content',
        maxHeight: 500,
        backgroundColor: 'white',
        border: '1px solid transparent',
        borderRadius: '23px',
        overflow: 'hidden hidden',
        boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
        [breakpoints.down('xs')]: {
            width: 339,
            padding: 8
        },
    },
    text: {
        textAlign: 'center',
        fontSize: 15,
    },
    buttonContainer: {
        display: 'flex',
        marginTop: '2%',
        justifyContent: 'space-evenly',
        // backgroundColor:'white',
        paddingBottom:'15px',
        [breakpoints.down('sm')]: {
            width: '100%',
        },
        '& button': {
            width: '90%',
            [breakpoints.down('sm')]: {
                width: '45% !important',
            },
        },
    },
    lmModalBtn: {
        backgroundColor: '#7834b4',
        width: '100%',
        fontFamily: 'Sora, sens-serif',
        fontSize: '18px'
    }
}))