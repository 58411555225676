import * as React from 'react'
import DraggableListItem, { ListItem } from './ListItem'
import {
	DragDropContext,
	Droppable,
	OnDragEndResponder,
} from 'react-beautiful-dnd'
import ScrollableContent from 'components/common/ScrollableContent/ScrollableContent'

export type DraggableListProps = {
	isSelectionList: boolean
	items: ListItem[]
	onDragEnd: OnDragEndResponder
}
const DraggableList = React.memo(
	({ items, onDragEnd, isSelectionList }: DraggableListProps) => {
		return (
			<ScrollableContent
				contentLength={items.length}
				content={
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId='droppable-list'>
							{provided => (
								<div ref={provided.innerRef} {...provided.droppableProps}>
									{items.map((item, index) => (
										<DraggableListItem
											disableDrag={isSelectionList}
											item={item}
											index={index}
											key={item.id}
										/>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				}
			/>
		)
	}
)

export default DraggableList
//utility func to reorder the list.
export const reorder = <T extends {}>(
	list: T[],
	startIndex: number,
	endIndex: number
): T[] => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)
	return result
}
