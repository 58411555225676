import { GET, PATCH } from './api-requests'
import { URLS } from './api-urls'
import { Response } from 'interface/api/response-interface'
import { mockNewNotifications } from '../redux/notifications/mock-notifications'

export const fetchNotifications = async ({
	receiverId,
}: Record<string, any>): Promise<Response> => {
	const {
		data,
		status,
	} = await GET(`${URLS.NOTIFICATION}/${receiverId}`)
	return {
		data,
		status,
	}
}
export const fetchNotificationsV2 = async ({
	receiverId,
}: Record<string, any>): Promise<Response> => {
	const {
		data,
		status,
	} = await GET(`${URLS.NOTIFICATION}/v2/${receiverId}`)
	return {
		data,
		status,
	}
}

export const loadNotifications = async (
	payload: { receiverId: string, pageNumber: number }
): Promise<Response> => {
	const { receiverId, pageNumber } = payload
	const { data, status } = await GET(`${URLS.NOTIFICATION}/${receiverId}/${pageNumber}`)
	return { data, status }
}

export const loadNotificationsV2 = async (
	payload: { receiverId: string, pageNumber: number }
): Promise<Response> => {
	const { receiverId, pageNumber } = payload
	const { data, status } = await GET(`${URLS.NOTIFICATION}/v2/${receiverId}/${pageNumber}`)
	return { data, status }
}

export const updateNotificationOld = async (
	receiverId: string,
	payload: Record<string, any>
): Promise<Response> => {
	const { data, status } = await PATCH(
		`${URLS.NOTIFICATION}/${receiverId}`,
		payload
	)
	return {
		data,
		status,
	}
}
