import { Theme } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

interface Props {
	position: number
	date: Date | undefined
	hideEventNumber?: boolean
}

export const EventPraimaryContent = ({
	position,
	date,
	hideEventNumber,
}: Props) => {
	const classes = useStyles()
	const { t } = useTranslation()

	const primaryBase = hideEventNumber
		? ''
		: t('debrief.event_primary', { number: position })

	return date ? (
		<span>
			<span className={classes.primaryBase}>{primaryBase}</span>
			<span className={classes.on}>{t('debrief.on_date')}</span>
			{dayjs(date).format('DD/MM/YYYY')}
		</span>
	) : (
		<span className={classes.primaryBase}>{primaryBase}</span>
	)
}

export default EventPraimaryContent
const useStyles = makeStyles<Theme>(({ breakpoints, palette }) => ({
	on: {
		color: '#8D9296',
	},
	primaryBase: {
		color: '#46484A',
		fontWeight: 'bold',
	},
}))
