import { FC, useState, useMemo, useContext } from 'react'
import Slider from '@mui/material/Slider'
import ActionsButton, { initialAnswer } from '../ActionButtons'
import Box from '@mui/material/Box'
import { UserAnswer } from '../ActionButtons/index'
import { AnswersGroupProp } from '../../../interface/bot/index'
import { numberToilustrationMap } from './constants'
import { useStyles } from './useStyles'
import { useTranslation } from 'react-i18next'
import { AppDirectionContext } from 'context/languages-context'

const FeedbackRangeQuestionChoice: FC<AnswersGroupProp> = ({
	setQuestion,
	nextQuestion,
	previousQuestion,
	isPraise,
}) => {
	const [slideAnswer, setSlideAnswer] = useState<UserAnswer>({
		...initialAnswer,
		id: '01',
		nextQuestion: nextQuestion!,
		previousQuestion: previousQuestion!,
	})
	const classes = useStyles()
	const { t } = useTranslation()

	const handleOnSlideChange = (
		event: Event,
		value: number | number[],
		activeThumb: number
	) => {
		setSlideAnswer((prevAnswer: UserAnswer) => ({
			...prevAnswer,
			id: `0${value.toString()}`,
			answer: value.toString(),
		}))
	}

	const ilustration = useMemo(() => `flower-${slideAnswer.id}`, [slideAnswer])
	const { isRtlDirection } = useContext(AppDirectionContext)
	return (
		<div className={classes.feedbackrangewrapper}>
			<div className={classes.container}>
				<div style={{ height: '33vh' }}>
					<img
						style={{ height: '25vh' }}
						src={numberToilustrationMap[ilustration]}
						alt='numberToilustrationMap'
					/>
				</div>
				<Box sx={{ height: 300, width: '100%' }}>
					<Slider
						onChange={handleOnSlideChange}
						className={classes.slider}
						defaultValue={1}
						valueLabelDisplay='auto'
						step={1}
						marks={Array.from({ length: 7 }).map((_: any, val: number) => ({
							value: val + 1,
							label: val + 1,
						}))}
						min={1}
						max={7}
						getAriaValueText={() => {
							return ``
						}}
						aria-label='Custom marks'
					/>
					<div className={classes.indicators}>
						<div
							style={{
								marginLeft: isRtlDirection ? '0' : '-6.2%',
								marginRight: isRtlDirection ? '-5.5%' : '0%',
							}}
							className={classes.indicator}
						>
							{t(isRtlDirection ? 'bot.large_extent' : 'bot.small_extent')}
						</div>
						<div
							style={{
								marginRight: isRtlDirection ? '0' : '-7.3%',
								marginLeft: isRtlDirection ? '-5.7%' : '0',
							}}
							className={classes.indicator}
						>
							{t(isRtlDirection ? 'bot.small_extent' : 'bot.large_extent')}
						</div>
					</div>
				</Box>
			</div>
			<ActionsButton
				isPraise={isPraise}
				isEnabled={true}
				answer={slideAnswer}
				setQuestion={setQuestion}
				showNextStepButton
				showPreviousButton
			/>
		</div>
	)
}

export default FeedbackRangeQuestionChoice
