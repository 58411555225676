import { FC, useContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Checkbox from '@mui/material/Checkbox'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import dayjs from 'dayjs'
import { AppDirectionContext } from 'context/languages-context'

import useStyles from './styled'

import { userSelector } from 'redux/user/user-selector'

import colors from 'utils/colors'
import UserAvatar from 'components/common/Avatar'
import {
	ItemDescription,
	ItemMembers,
	ItemDate,
} from 'utils/generic-task-items/index'
import useTeamsRequests from './useTeamsRequest'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'
import MembersList from 'components/Debriefing/SummeryTable/members-list/index'

import IUser from 'interface/user/user-interface'
import { ITeamTask } from 'interface/teams/teams-interface'
import { employeesSelector } from 'redux/employee/employee.selector'
import SocketContext from 'context/socket-context'
import { TeamTodosEvents } from 'enums/teams/team-events.enum'
import useTeamSocketRespond from './useTeamSocket'

type Props = {
	manager_id?: string
	onDelete?: (_id: string) => void
	task?: ITeamTask<IUser>
	toggleShowEmptyTask?: () => void
	participants?: IUser[]
	isMobile?: boolean
}

const TeamTask = ({
	onDelete,
	manager_id,
	task,
	toggleShowEmptyTask,
	participants,
	isMobile,
}: Props) => {
	const {
		_id = '',
		description,
		updatedAt,
		date,
		creator,
		shouldShow,
		employees_id,
	} = task ?? ({} as ITeamTask<IUser>)

	const [showMembersList, setShowMembersList] = useState<boolean>(false)
	const [acceptDeleteLesson, setAcceptDeleteLesson] = useState<boolean>(false)

	const socket = useContext(SocketContext)
	const user = useSelector(userSelector)

	const { wsUpdate, wsDoneTask, wsDeleteTask } = useTeamSocketRespond(_id)
	const { updateTeamTasks, toggleTaskDoneByUser, createTeamTask } = useTeamsRequests({})

	useEffect(() => {
		socket?.on(TeamTodosEvents.UPDATE, wsUpdate)
		socket?.on(TeamTodosEvents.DONE, wsDoneTask)
		socket?.on(TeamTodosEvents.DELETE_TASK, wsDeleteTask)
		return () => {
			socket?.off(TeamTodosEvents.UPDATE, wsUpdate)
			socket?.off(TeamTodosEvents.DONE, wsDoneTask)
			socket?.off(TeamTodosEvents.DELETE_TASK, wsDeleteTask)
		}
	}, [user._id, socket, wsUpdate, wsDoneTask, wsDeleteTask])

	const isManager = manager_id == user._id

	const isUserCreatedForHimself = employees_id?.some((emp: IUser) => emp?._id === creator?._id)

	const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection, isUserCreatedForHimself })

	const isUserIncluding = employees_id?.some(
		(emp: IUser) => emp?._id === user?._id
	) || user?._id == creator?._id
	const isTaskDoneByUser = !Boolean(shouldShow?.length)

	const closeMembersModal = () =>
		setShowMembersList(false)

	const toggleAcceptDeleteLessonModal = () =>
		setAcceptDeleteLesson(prev => !prev)

	const showDragAndDropWindow = () =>
		isUserIncluding && setShowMembersList(prev => true)

	const taskHaveBeenDone = () => isUserIncluding && toggleTaskDoneByUser(_id)

	const employees = useSelector(employeesSelector)

	const handleDeleteLesson = () => onDelete!(_id!)

	const updateMembersList = (list: string[]) => {
		const convertedUsers = list.map(emp => employees.find(e => e._id == emp))
		const teamTaskRevertToUsersString = {
			...task,
			creator: task?.creator,
			employees_id: convertedUsers,
			shouldShow: convertedUsers,
			isNew: convertedUsers,
		} as ITeamTask<IUser>
		updateTeamTasks(teamTaskRevertToUsersString)
	}

	const updateTaskDate = (day: Date) => {
		const teamTaskRevertToUsersString = {
			...task,
			date: day,
		} as ITeamTask<IUser>
		updateTeamTasks(teamTaskRevertToUsersString)
	}

	const handleSaveTask = (description: string) => {
		toggleShowEmptyTask?.()
		const teamTaskRevertToUsersString = {
			...task,
			description,
		} as ITeamTask<IUser>

		if (!_id) {
			createTeamTask(teamTaskRevertToUsersString)
			return
		}

		updateTeamTasks(teamTaskRevertToUsersString)
	}
	const creatorInfo =
		!isUserCreatedForHimself && !isMobile && _id ? creator : undefined

	return (
		<TaskContainer>

			{creatorInfo ? <UserAvatar userInfo={creatorInfo} /> : <div style={{ width: '2.5vw' }}></div>}

			<div className={classes.lessonContainer}>
				<ItemDescription
					allowEditAccess={!isTaskDoneByUser}
					description={description}
					handleSaveInput={handleSaveTask}
					_id={_id}
					label={'task'}
					matchPointColor={colors.saydoGreen}
				/>

				{!isMobile && (
					<div className={classes.customLessonSection}>
						<ItemMembers
							creator={creator}
							openMembersAndParticipants={showDragAndDropWindow}
							membersObjects={employees_id}
						/>
						<ItemDate
							matchPointColor={colors.saydoGreen}
							date={date}
							isLeader={isUserIncluding}
							updateDate={updateTaskDate}
						/>
					</div>
				)}
			</div>

			{_id ? (
				<div className={classes.check}>
					{updatedAt && <span>{dayjs(updatedAt).format('DD/MM/YY')}</span>}
					<Checkbox
						checked={isTaskDoneByUser}
						onChange={taskHaveBeenDone}
						style={{ width: '3vw' }}
					/>
				</div>
			) : (
				<div style={{ width: '3vw' }}></div>
			)}

			{isManager
				? <div className={classes.deleteIcon}><DeleteIcon onClick={toggleAcceptDeleteLessonModal} /></div>
				: <div style={{ width: '2.3em' }}></div>
			}

			<ConfirmModalSB
				text={t('delete_lesson')}
				isOpen={acceptDeleteLesson}
				onClose={toggleAcceptDeleteLessonModal}
				onConfirm={handleDeleteLesson}
				closeButtonText={t('no')}
				confirmButtonText={t('yes')}
			/>
			<MembersList
				isOpen={showMembersList}
				updateMembersList={updateMembersList}
				onClose={closeMembersModal}
				members={[]}
				participants={participants!}
			/>
		</TaskContainer>
	)
}

const TaskContainer: FC<{}> = ({ children }) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	return <div className={classes.teamTaskContainer} data-testid='task-container'>{children}</div>
}
const TasksWrapper: FC<{}> = ({ children }) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	return <div className={classes.teamTaskWrappwer}>{children}</div>
}

TeamTask.TaskContainer = TaskContainer
TeamTask.TasksWrapper = TasksWrapper

export default TeamTask