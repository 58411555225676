import { FC, useContext, useState, useEffect } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AppDirectionContext } from '../../context/languages-context'

import { useStyles } from './useStyles'

import { userSelector } from 'redux/user/user-selector'
import { review_praise_work_meet } from 'redux/praise/praise-actions'

import { UseClickActions } from 'components/Bot/useHooks'
import { getItem, setItem } from 'utils/localStorage'
import { RenderScreenType } from './renderScreenType'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'

import rocketRide from 'assets/rocketRide.svg'
import likeIcon from 'assets/likeIcon.svg'
import learningIcon from 'assets/learningIcon.svg'
import { CloseIcon } from 'components/common/CloseIcon'
import solvelogo from 'assets/Logowithtext.svg'

export enum OutputType {
	FeedbackSender = 'FeedbackSender',
	FeedbackReciever = 'FeedbackReciever',
	FeedbackDraft = 'FeedbackDraft',
	FeedbackViewing = 'FeedbackViewing',
	PraiseReciever = 'PraiseReciever',
	PraiseSender = 'PraiseSender',
	PraiseDraft = 'PraiseDraft',
	PraiseViewing = 'Viewing',
}
export enum Type {
	Praise = 'praise',
	Feedback = 'feedback',
}

const BotOutput: FC = () => {
	const { isRtlDirection } = useContext(AppDirectionContext)

	const [shouldOpenModal, setShouldOpenModal] = useState(false)
	const [outputdData, setOutputdData] = useState<Record<string, string>>({})

	const classes = useStyles({ isRtlDirection })
	const location: any = useLocation()
	console.log({ location })
	const { t } = useTranslation()
	const disptach = useDispatch()

	const { company_id } = useSelector(userSelector)

	const [_, currentPage, outputId] = location.pathname.split('/')
	const { isPraise, id, outputScreenType = '' } = location.state

	const shouldShowLogo =
		outputScreenType !== OutputType.FeedbackReciever &&
		outputScreenType !== OutputType.FeedbackViewing

	const isFeedbackSender = outputScreenType === OutputType.FeedbackSender
	const isPraiseSender = outputScreenType === OutputType.PraiseSender

	const outputBgImage =
		outputScreenType === OutputType.PraiseReciever
			? rocketRide
			: isPraise
			? likeIcon
			: learningIcon

	const message = isPraise
		? t('sure_you_want_to_delete_praise')
		: t('sure_you_want_to_delete_feedback')

	const currentOutputId = outputId ? outputId : id

	useBeforeunload(() => {
		setItem('outputdData', { outputScreenType, currentOutputId, isPraise: true })
	})

	useEffect(() => {
		document.getElementById('root')?.scrollTo(0, 0)
	}, [])

	useEffect(() => {
		// handle chaching data
		const hasData = outputScreenType && currentOutputId
		const cachedOutputData = getItem('outputdData')
		console.log({ outputScreenType, cachedOutputData, currentOutputId })
		setOutputdData(
			hasData
				? { outputScreenType, currentOutputId, isPraise: true }
				: cachedOutputData
		)
	}, [outputScreenType, currentOutputId])

	const { closeAction, deleteOutput } = UseClickActions(
		isPraise,
		currentOutputId
	)

	const handleToggleReview = () => {
		const payload = { company_id, output_id: outputId }

		disptach(review_praise_work_meet(payload))
		handleClose()
	}

	const openModal = () => {
		setShouldOpenModal(prev => {
			return !prev
		})
	}

	const handleClose = () => {
		if (isFeedbackSender || isPraiseSender) {
			openModal()
			return
		}

		closeAction()
	}

	const handleDeleteOutput = () => {
		if (isFeedbackSender || isPraiseSender) {
			closeAction()
			return
		}

		// api to delete output in server
		deleteOutput()
		closeAction()
	}
	console.log({ outputdData })
	if (!outputdData || !('currentOutputId' in outputdData))
		return <> Loading....</>
	const containerStyle = {
		height: '100%',
		direction: (isRtlDirection ? 'rtl' : 'ltr') as any,
	}
	return (
		<>
			<div className={classes.container} style={containerStyle}>
				<div className={classes.headerSection}>
					{shouldShowLogo && (
						<>
							<img
								className={classes.sayDoLogo}
								onClick={closeAction}
								src={solvelogo}
								alt='SolveLogo'
							/>
							<CloseIcon handleClick={handleClose} />
						</>
					)}
				</div>

				<RenderScreenType
					{...(outputdData as any)}
					handleToggleReview={handleToggleReview}
				/>

				{shouldShowLogo && (
					<div style={{ width: '100%' }}>
						<img src={outputBgImage} alt='s' className={classes.backgroundImage} />
					</div>
				)}
			</div>

			{shouldOpenModal && (
				<ConfirmModalSB
					isOpen={shouldOpenModal}
					text={<h2>{message}</h2>}
					closeButtonText={t('output_delete_close_button_text')}
					confirmButtonText={t('output_delete_confirm_button_text')}
					onConfirm={handleDeleteOutput}
					onClose={openModal}
				/>
			)}
		</>
	)
}

export default BotOutput
