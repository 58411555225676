import { createSlice } from '@reduxjs/toolkit'
import IUser from '../../interface/user/user-interface'
import {
	create_employees,
	update_employee,
	delete_employees,
	get_employees,
	define_employee_manager,
	set_employee_goals,
	delete_all_company_employees,
	restore_employee,
} from 'redux/employee/employee-actions'

export interface EmployeesState {
	employees: IUser[]
	loading: boolean
	error: string
}
const initialState: EmployeesState = {
	employees: [],
	loading: false,
	error: '',
}

export const employeeSlice = createSlice({
	name: 'employee',
	initialState,
	reducers: {
		clearError(state) {
			return {
				...state,
				error: '',
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(get_employees.fulfilled, (state, action) => {
			const employees = action.payload
			state.employees = employees
			state.loading = false
		})
		builder.addCase(get_employees.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(get_employees.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		builder.addCase(create_employees.fulfilled, (state, action) => {
			const employees = action.payload
			state.employees = [...state.employees, ...employees]
			state.loading = false
		})
		builder.addCase(create_employees.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(create_employees.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		builder.addCase(update_employee.fulfilled, (state, action) => {
			const receivedEmployees = action.payload
			if (!receivedEmployees?.length) return
			const updatedEmployees = state.employees.map(employee =>
				receivedEmployees
					.map((receivedEmployee: IUser) => receivedEmployee._id)
					.includes(employee._id)
					? receivedEmployees.find((e: IUser) => e._id === employee._id)
					: employee
			)
			state.employees = [...updatedEmployees]
			state.loading = false
		})
		builder.addCase(update_employee.pending, state => {
			state.loading = true
		})
		builder.addCase(update_employee.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		builder.addCase(restore_employee.fulfilled, (state, action) => {
			state.loading = false
			const employee = action.payload.employee
			const updatedEmployees = state.employees.map((emp: IUser) => {
				if (emp._id === employee._id) emp.statusInfo.active = true
				return emp
			})
			state.employees = updatedEmployees
		})
		builder.addCase(restore_employee.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(restore_employee.rejected, (state, action) => {
			state.loading = false
		})

		builder.addCase(delete_employees.fulfilled, (state, action) => {
			const deletedIds = action.payload.deletedIds
			const unassignedEmployees = action.payload.unassignedEmployees
			let updatedEmployees = state.employees
				// .filter(e => !deletedIds.includes(e._id))
				.map((e: IUser) => {
					console.log({ e })
					const unassignedEmployee = unassignedEmployees.find(
						(unassignedEmployee: IUser) => unassignedEmployee._id === e._id
					)
					if (deletedIds.includes(e._id)) e.statusInfo.active = false
					return unassignedEmployee ? unassignedEmployee : e
				})
			console.log({ updatedEmployees })
			state.employees = updatedEmployees
			state.loading = false
		})
		builder.addCase(delete_employees.pending, state => {
			state.loading = true
		})
		builder.addCase(delete_employees.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		builder.addCase(delete_all_company_employees.fulfilled, (state, action) => {
			state.employees = []
			state.loading = false
		})
		builder.addCase(delete_all_company_employees.pending, state => {
			state.loading = true
		})
		builder.addCase(delete_all_company_employees.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		builder.addCase(define_employee_manager.fulfilled, (state, action) => {
			const assignmentData = action.payload
			const updatedEmployees = state.employees.map((employee: IUser) => {
				if (assignmentData.assignIdsList.includes(employee._id)) {
					employee.statusInfo.directManager = assignmentData.managerEmail // can be unassiged instead of email value (when unassign action)
				}
				return employee
			})
			console.log(updatedEmployees)
			state.employees = updatedEmployees
			state.loading = false
		})
		builder.addCase(define_employee_manager.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(define_employee_manager.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})

		builder.addCase(set_employee_goals.fulfilled, (state, action) => {
			const updatedGoals = action.payload.goals
			console.log(updatedGoals)
			const updatedEmployeeId = action.payload.id
			const updatedEmployees = state.employees.map(employee => {
				if (employee._id === updatedEmployeeId) {
					employee.statusInfo.goals = [...updatedGoals]
				}
				return employee
			})
			console.log({ ...updatedEmployees })
			state.employees = [...updatedEmployees]
			state.loading = false
		})
		builder.addCase(set_employee_goals.pending, state => {
			state.loading = true
		})
		builder.addCase(set_employee_goals.rejected, (state, action) => {
			state.error = action.error.message!
			state.loading = false
		})
	},
})
export const employeesAction = employeeSlice.actions
export default employeeSlice.reducer
