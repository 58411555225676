import * as React from 'react'
import Slide from '@mui/material/Slide'
import { Box } from '@material-ui/core'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import List from '@mui/material/List'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import SelectLanguageList from '../SelectLanguageList/index'

type DialogProp = {
	title: string
	handleChangeLang: (lang: string) => void
	handleClose: () => void
	handleSaveButton: () => void
	isOpen: boolean
	renderChild: JSX.Element
}
const Transition = React.forwardRef(function Transition(
	props: any & {
		children: React.ReactElement
	},
	ref: React.Ref<JSX.Element>
) {
	return <Slide direction='up' ref={ref} {...props} />
})

export const FullScrennDialog = ({
	title,
	renderChild,
	handleClose,
	isOpen,
	handleSaveButton,
	handleChangeLang,
}: DialogProp) => {
	const RenderSelectLang = (
		<h1
			style={{
				paddingLeft: '5%',
				marginBottom: '7%',
				display: 'flex',
				width: '90%',
				justifyContent: 'space-between',
			}}
		>
			<div style={{ paddingTop: '10px' }}>Title: {title}</div>

			<SelectLanguageList
				paddingTop='0'
				selectedLang={lang => handleChangeLang(lang)}
			/>
		</h1>
	)
	return (
		<Dialog
			fullScreen
			open={isOpen}
			onClose={handleClose}
			TransitionComponent={Transition}
		>
			<Box sx={{ bgcolor: 'skyblue', position: 'relative' }}>
				<Toolbar style={{ justifyContent: 'space-between', padding: '0 10%' }}>
					<IconButton
						edge='start'
						color='inherit'
						onClick={handleClose}
						aria-label='close'
					>
						X
					</IconButton>
					<div></div>

					<Button autoFocus color='inherit' onClick={handleSaveButton}>
						save
					</Button>
				</Toolbar>
			</Box>

			<List>
				{RenderSelectLang}
				{renderChild}
			</List>
		</Dialog>
	)
}
