import { FC, Dispatch, useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import ShareIcon from '@material-ui/icons/Share'

import { useStyles } from './learningOutputStyles'
import closeIcon from 'assets/closeIcon.svg'
import { debriefOnProccesSelector } from 'redux/debrief/debrief-selector'
import fileUpload from 'assets/fileUpload.svg'
import ExportFileModal from '../Lessons-management/modal/index'
import { ValuesChoice } from './DepartmentChoices'
import DepartmentMembersList from "../Lessons-management/modal/DepartmentMembersList"
import IUser from 'interface/user/user-interface'
import { employeesSelector } from 'redux/employee/employee.selector'
import { BasicSwitches } from 'components/common/BasicSwitches'
import { filterUsers } from 'utils/filterTab'
import useExportPdf from '../../../utils/useExportPdf'
import CreateDebriefPdf from "./PdfSummary/CreateDebriefPdf"

type SummeryHeader = {
	canEdit: boolean
	showShareIcon: boolean
	dispatch?: Dispatch<any>
}
export const SummeryHeader: FC<SummeryHeader> = props => {
	const { canEdit, showShareIcon, dispatch } = props

	const [isOpen, setIsOpen] = useState(false)
	const [permissionsSetList, setPermissionsSetList] = useState<Set<string>>(new Set())
	const [searchKey, setSearchKey] = useState('')
	const [switchChecked, setSwitchChecked] = useState(Boolean(permissionsSetList.size))

	const employees = useSelector(employeesSelector)
	const debrief = useSelector(debriefOnProccesSelector)

	const instance = (<CreateDebriefPdf debrief={debrief} />)
	const receivers = Array.from(permissionsSetList)

	const { handleSendPdf } = useExportPdf({ debrief, receivers, reactPdfInstance: instance })

	const classes = useStyles()
	const { t } = useTranslation()

	useEffect(() => {
		if (!employees.length) return

		initSetOfEmployees()
	}, [employees])

	const allowConfirmAction = useMemo(() => {
		if (permissionsSetList.size) return true

		return false
	}, [permissionsSetList])

	const initSetOfEmployees = () => {
		const activeEmployees = employees.filter(user => user.statusInfo.active)
		const employeesIds = activeEmployees.map(user => user._id)
		setPermissionsSetList(new Set(employeesIds))
	}

	const availablePermissions = useMemo((): IUser[] | undefined => {
		const availablePermissions = employees.filter(user => user.statusInfo.active)
		const userIsTyping = searchKey.trim().length

		if (userIsTyping) return filterUsers(availablePermissions, searchKey)

		return availablePermissions
	}, [employees, searchKey])

	const createBarContent = (
		placeholder: string | React.ReactNode,
		content: string = '',
		handleClick?: React.MouseEventHandler<HTMLSpanElement>
	) => {
		return (
			<span className={classes.infoBarData} onClick={handleClick}>
				<span className={classes.placeholder}>{placeholder}</span>
				<span className={classes.infoBarContent}>{content}</span>
			</span>
		)
	}
	const handleClosePopup = () => {
		setIsOpen(false)
	}
	const exportPdf = () => {
		setIsOpen(prev => !prev)
	}
	const sendPdf = () => handleSendPdf()

	const receiversLabel = useMemo((): string => {
		return switchChecked ? '' : 'all_users'
	}, [switchChecked])

	const changeSwitchChecked = () => {
		if (switchChecked) {
			setSwitchChecked(false)
			initSetOfEmployees()
		} else {
			setSwitchChecked(true)
			setPermissionsSetList(new Set())
		}
	}

	const membersList = (<>
		<div className={classes.title}>
			<div className={classes.modalTitle}>{t('export_debrief')}</div>
			<img src={closeIcon} onClick={handleClosePopup} />
		</div>
		<div>
			<BasicSwitches
				color='success'
				handleClick={changeSwitchChecked}
				value={switchChecked}
			/>

			<p>{t(receiversLabel)}</p>
		</div>
		{switchChecked && <DepartmentMembersList
			setPermissionsSetList={setPermissionsSetList}
			permissionsSetList={permissionsSetList}
			availablePermissions={availablePermissions}
			setUserInput={setSearchKey}
		/>}</>)
	const date = dayjs(debrief?.createdAt).format('DD/MM/YYYY')

	if (!debrief) return <>Loading...</>

	const { code, sharedEmployess = [] } = debrief
	const creator =
		debrief.creator?.personalInfo.firstName +
		' ' +
		debrief.creator?.personalInfo.lastName

	const renderUsersList = (
		<div className={classes.usersList}>
			<ul>
				{sharedEmployess?.map(({ personalInfo: { firstName, lastName } }) => (
					<li>{firstName + ' ' + lastName}</li>
				))}
			</ul>
		</div>
	)

	return (
		<div className={classes.info}>
			<div className={classes.infoBar} id='target'>
				{showShareIcon &&
					createBarContent(
						<ShareIcon
							style={{ cursor: 'pointer' }}
							onClick={() => dispatch?.({ type: ACTION_TYPES.TOGGLE_SHARE_ICON })}
						/>
					)}

				{createBarContent(
					t('debrief.debriefNum', { interpolation: { escapeValue: false } }),
					code
				)}
				{createBarContent(
					t('debrief.on', { interpolation: { escapeValue: false } }),
					date
				)}
				{createBarContent(
					t('debrief.initiator', { interpolation: { escapeValue: false } }),
					creator
				)}
				{createBarContent(
					<img
						src={fileUpload}
						alt='file-upload'
						className={classes.transferIcon}
						onClick={exportPdf}
					/>
				)}
			</div>

			<div className={`${classes.infoBar} ${classes.additionalInfo}`}>
				<ValuesChoice canEdit={canEdit} debrief={debrief} />

				{createBarContent(
					t('debrief.show_events', { interpolation: { escapeValue: false } }),
					'',
					() => dispatch?.({ type: ACTION_TYPES.TOGGLE_SHOW_EVENTS })
				)}
			</div>
			{!!sharedEmployess?.length && renderUsersList}

			<ExportFileModal
				isConfirmAccepted={allowConfirmAction}
				modalStyle={classes.exportModal}
				isOpen={isOpen}
				confirmButtonText='send'
				child={membersList}
				onConfirm={sendPdf}
				onClose={handleClosePopup}
			/>
		</div>
	)
}

// constants
const stateKeys = {
	share: 'isShareListOpen',
	events: 'showEvents',
}

// reducer initial state
export const initialState = {
	isShareListOpen: false,
	showEvents: false,
}
// action types
export enum ACTION_TYPES {
	TOGGLE_SHARE_ICON = 'TOGGLE_SHARE_ICON',
	TOGGLE_SHOW_EVENTS = 'TOGGLE_SHOW_EVENTS',
}
// reducer
export const reducer = (state: any, action: any) => {
	switch (action.type) {
		case ACTION_TYPES.TOGGLE_SHARE_ICON:
			return {
				...state,
				isShareListOpen: !state[stateKeys.share],
			}
		case ACTION_TYPES.TOGGLE_SHOW_EVENTS:
			return {
				...state,
				showEvents: !state[stateKeys.events],
			}
		default:
			throw new Error()
	}
}
