import { Tooltip } from '@mui/material'
import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'
import { AppDirectionContext } from 'context/languages-context'
import { Reactions } from 'enums/reactions/reactions.enum'
import { useContext } from 'react'

interface Props {
	type: string
	count: number
	img: string
	isClicked: boolean
	onClick: () => void
	usersData: any
	classes: any
	isMobile: boolean
	onShowMoreReactors: (users: any, type: string, img: string) => void
}

export const ClickIcon = ({
	usersData,
	count,
	img,
	type,
	classes,
	isClicked,
	onClick,
	isMobile,
	onShowMoreReactors,
}: Props) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const countContent = <div className={classes.count}>{count}</div>

	return (
		<Tooltip
			placement='bottom-start'
			title={
				isMobile || usersData.length === 0 ? (
					''
				) : (
					<div className={classes.toolTipTitle}>
						{!isRtlDirection && <h1>{type.toUpperCase()}</h1>}
						{usersData.slice(0, 9).map((u: any) => (
							<h1 key={u._id}>{u.fullName}</h1>
						))}
						{usersData.length > 9 && (
							<h2
								className={classes.expandTitle}
								onClick={() => onShowMoreReactors(usersData, type, img)}
							>
								and {usersData.length + 1 - 10} more...
							</h2>
						)}
					</div>
				)
			}
		>
			<div className={classes.iconContainer}>
				<div
					onClick={onClick}
					style={{
						backgroundColor:
							isMobile && isClicked
								? 'rgba(117, 141, 174, 0.65)'
								: isClicked && !isMobile
								? 'rgba(117, 141, 174, 1)'
								: '#E1EAF6',
					}}
					className={classes.reactionContainer}
				>
					<img alt='Click icon' src={img} />
					{isMobile && countContent}
				</div>
				{!isMobile && countContent}
			</div>
		</Tooltip>
	)
}
