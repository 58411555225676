import { useMediaQuery } from '@material-ui/core'

import './style.css'

import useButtonsGenerator from '../lesson-btn/useButtonsGenerator'
import ItemsSections from './ItemsSections'

import { FoldersMainSectionType } from '../props'
import useMainSections from './useMainSections'

const Main = ({
	currentFolder,
	showEmptyLesson,
	navigate,
	insertNewLesson,
	createFolder,
	createTitle,
	editFolder,
	toggleEmptyLesson,
	deleteFolder,
	deleteLesson,
	updateTitle,
	createFile,
	updateLesson,
	lessonDescription,
}: FoldersMainSectionType) => {
	const isMobile = useMediaQuery('(max-width:550px)')

	const { buttons } = useButtonsGenerator({
		currentFolder,
		createFolder,
		createFile,
		createTitle,
		toggleEmptyLesson,
		editFolder,
	})

	const { isFolder, itemsSctions } = useMainSections({ currentFolder })

	const itemButtonEvents = {
		lessonDescription: lessonDescription,
		updateLesson: updateLesson,
		navigate: navigate,
		insertNewLesson: insertNewLesson,
		deleteFolder: deleteFolder,
		editFolder: editFolder,
		deleteLesson: deleteLesson,
		updateTitle: updateTitle,
		toggleEmptyLesson: toggleEmptyLesson
	}

	return (
		<div className='main'>

			<ItemsSections
				sections={itemsSctions}
				isFolder={isFolder}
				showEmptyLesson={showEmptyLesson}
				itemButtonEvents={itemButtonEvents}
			/>

			{isMobile && buttons}

		</div>
	)
}

export default Main
