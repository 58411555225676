import { Response } from 'interface/api/response-interface'
import { DELETE } from './api-requests'
import { URLS } from './api-urls'
// http://localhost:3001/api/tasks/62fd144fd9c7d40052ce9611/6357f55a3cf293006a08129a

type DeleteTask = {
	company_id: string
	task_id: string
}

export const deleteTask = async (payload: DeleteTask): Promise<Response> => {
	const { company_id, task_id } = payload
	const { data, status } = await DELETE(
		`${URLS.COMPANY_TASKS}/${company_id}/${task_id}`
	)
	return { data, status }
}
