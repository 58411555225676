import { Tooltip } from "@material-ui/core"
import { ReactElement } from "react"

import useStyles from './styled'

import infoIcon from 'assets/info.svg'

type InfoTooltip = {
    text: JSX.Element | ReactElement | string
    isOpen: boolean
    toggleModal: () => void
}

const InfoTooltip = ({ isOpen, toggleModal, text }: InfoTooltip) => {
    const classes = useStyles()
    return (
        <Tooltip
            PopperProps={{
                disablePortal: true,
            }}
            onClose={toggleModal}
            open={isOpen}
            placement='top'
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={text}
            classes={{ tooltip: classes.tooltip }}
        >
            <img className={classes.infoImg} src={infoIcon} onClick={toggleModal} />
        </Tooltip>
    )
}

export default InfoTooltip