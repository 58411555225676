import { useState, useEffect } from 'react'
import { Tab } from '.'
import { OutputSettingType } from '../../interface/bot/index'
import { createPraiseOutput, fetchAllPraisesOutputs } from 'api/praise'
import { Langs } from 'enums/app'
import { Req } from 'enums/req/req.enum'
import { createFeedbacksOutput, fetchAllFeedbacksOutputs } from 'api/feedback'
type CreateOutputDto = Omit<OutputSettingType, '_id' | 'context'>

const formInitialState = {
	title: '',
	aboutContext: '',
	freeTextDescriptions: '',
	contributionToSuccess: '',
}

export const UseLogic = ({ tabState }: { tabState: Tab }) => {
	const [loading, setLoading] = useState(false)
	const [outputs, setOutputs] = useState<OutputSettingType[]>([])
	const [newOutput, setNewOutput] = useState(formInitialState)

	const fetchPraise = () => {
		fetchAllPraisesOutputs().then(({ data }) => {
			setOutputs(data)
			setLoading(false)
		})
		return
	}

	const fetchFeedback = () => {
		fetchAllFeedbacksOutputs().then(({ data }) => {
			setOutputs(data)
			setLoading(false)
		})
		return
	}

	const fetchDate = () => {
		setLoading(true)
		if (Tab.praise === tabState) {
			fetchPraise()
		}
		if (Tab.feedback === tabState) {
			fetchFeedback()
		}
		setOutputs([])
		setLoading(false)
	}

	useEffect(() => {
		fetchDate()
	}, [tabState])

	const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewOutput(prev => ({ ...prev, [event.target.name]: event.target.value }))
	}

	const handleCreateOutput = async () => {
		if (!newOutput.title) {
			alert('you must provide title!')
			return
		}

		const enNewOutputPayload = getOutputPayload(newOutput)

		if (tabState === Tab.praise) {
			createPraise(enNewOutputPayload)
			return
		}
		createFeedback(enNewOutputPayload)
	}
	const createPraise = async (enNewOutputPayload: CreateOutputDto) => {
		const { status, data } = await createPraiseOutput(enNewOutputPayload)

		if (status == Req.failed) {
			console.log(data)
			return
			// setServerError(data)
		}
		setNewOutput(prev => formInitialState)
		fetchDate()
	}
	const createFeedback = async (enNewOutputPayload: CreateOutputDto) => {
		const { status, data } = await createFeedbacksOutput(enNewOutputPayload)

		if (status == Req.failed) {
			console.log(data)
			return
			// setServerError(data)
		}
		setNewOutput(prev => formInitialState)
		fetchDate()
	}

	return {
		newOutput,
		loading,
		outputs,
		handleCreateOutput,
		handleFormChange,
		fetchDate,
	}
}

///////////////////////////
const getOutputPayload = (
	newOutput: typeof formInitialState
): CreateOutputDto => {
	return {
		title: newOutput.title,
		beforeSend: {
			contributionToSuccess: {
				...Langs,
				[Langs.en]: newOutput.contributionToSuccess,
			},
			aboutContext: { ...Langs, [Langs.en]: newOutput.aboutContext },
			freeTextDescriptions: {
				...Langs,
				[Langs.en]: newOutput.freeTextDescriptions,
			},
		},
	}
}
