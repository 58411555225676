import { useTranslation } from 'react-i18next'

import SingleChoice from '../SingleChoice'
import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'

import rocketIcon from 'assets/launchTransparent.svg'
import peronalDebriefingIcon from 'assets/personalDebriefing.svg'
import groupDebriefingIcon from 'assets/groupDebriefing.svg'

import { Debrief } from 'interface/debrief/debrief-interface'
import { DebriefingPaths } from 'enums/debriefing/debriefing-paths.enum'
import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'

interface Props {
	currentPath: DebriefingPaths | undefined
	nextStep: DebriefingSteps
	setData: (data: Partial<Debrief>, nextStep: DebriefingSteps) => void
}

export const PathChoice = ({ setData, currentPath }: Props) => {
	const { t } = useTranslation()

	const personal = t('personal_debriefing')
	const group = t('group_debriefing')

	const answers = [
		{
			title: personal,
			icon: peronalDebriefingIcon,
			selected: currentPath === DebriefingPaths.PERSONAL,
		},
		{
			title: group,
			icon: groupDebriefingIcon,
			selected: currentPath === DebriefingPaths.GROUP,
		},
	]

	const handlePathChoice = (value: string) => {
		const isPersonalPath = value === t('personal_debriefing')
		setData(
			{
				path: isPersonalPath ? DebriefingPaths.PERSONAL : DebriefingPaths.GROUP,
			},
			DebriefingSteps.INITIAL_MESSAGE
		)
	}

	const windowWidth = useWindowWidth()
	const mobileWidth = 600
	const isMobile = mobileWidth >= windowWidth

	return (
		<>
			<img
				style={{
					width: '35%',
					margin: '2% 0 5% 0',
					display: isMobile ? 'block' : 'none',
				}}
				src={rocketIcon}
			></img>

			<SingleChoice handleAnswerChoice={handlePathChoice} answers={answers} />
		</>
	)
}

export default PathChoice
