import { FC, useContext, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { Grid, Tooltip } from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

import { AppDirectionContext } from 'context/languages-context'

import './loader.scss'
import useStyles from './style'
import { InsightHeader } from './InsightMobile'
import plusIcon from 'assets/plusIcon.svg'

import { Task, TasksContainer } from 'utils/Task/index'

import {
	hasMoreBaseInsight,
	isBaseInsightLoading,
} from 'redux/company/company.selector'

import { IBaseInsight } from 'interface/baseInsight/baseInsight'

import { DashboardPaths } from 'enums/routes/path-names'
import {
	useWindowWidth,
	MOBILE_WIDTH,
} from '../../common/Header/useHooks/useWindowWidth'

export type TasksSection = {
	tasks: Array<IBaseInsight>
	employee_id: string
	incluedHrLine?: boolean
	containerDescription: string
	toggleShowingTask: (task_id: string, includeUser: boolean) => void
	loadMoreTasks: (pageNumber: number) => void
}

const Insight: FC<TasksSection> = props => {
	const {
		tasks,
		employee_id,
		containerDescription,
		toggleShowingTask,
		loadMoreTasks,
	} = props
	const [pageNumber, setPageNumber] = useState<number>(0)

	const isBaseInsightsLoading = useSelector(isBaseInsightLoading)
	const { t } = useTranslation()
	const classes = useStyles({})

	const windowWidth = useWindowWidth()
	const isMobile = MOBILE_WIDTH >= windowWidth

	const infiniteScrollStyle = { overflow: 'scroll', padding: '1%' }
	const inifinteScrollHeight = '100%' // window.innerWidth > 400 ? '85%' : 530

	const endMessage = (
		<p style={{ textAlign: 'center' }}>
			<b>{t('end_list')}</b>
		</p>
	)
	const loader = <div id='loader'></div>

	const INCREMENT_BY_ONE = 1
	const nextPage = () => {
		let currentPage = 0
		currentPage = pageNumber + INCREMENT_BY_ONE
		setPageNumber(currentPage)
		loadMoreTasks(currentPage)
	}

	const propsContainer = {
		height: 221,
		scroll: false,
		tasksSection: true,
	}

	const shouldRenderPlusIcon = !tasks.length && !isBaseInsightsLoading
	return (
		<>
			{isMobile ? (
				<div className={classes.gridTasksContainer}>
					<InsightHeader>{t(containerDescription)}</InsightHeader>
					<Scroll
						isLoading={isBaseInsightsLoading}
						tasks={tasks}
						employee_id={employee_id}
						toggleShowingTask={toggleShowingTask}
						fetchMoreData={nextPage}
					/>
				</div>
			) : (
				<TasksContainer
					{...propsContainer}
					margin='40px auto !important'
					containerDescription={t(containerDescription)}
				>
					<InfiniteScroll
						dataLength={tasks.length}
						next={nextPage}
						hasMore={true}
						loader={isBaseInsightsLoading ?? loader}
						endMessage={endMessage}
						height={inifinteScrollHeight}
						style={{ ...infiniteScrollStyle }}
						scrollThreshold={0.1}
						scrollableTarget='scrollableDiv'
					>
						{!!tasks.length &&
							tasks.map((task: IBaseInsight, index: number) => (
								<Grid key={index}>
									<Task
										key={index}
										{...task}
										toggleShowingTask={toggleShowingTask}
										employee_id={employee_id}
									/>
									{!task.date ? <div className={classes.hrLine}></div> : null}
								</Grid>
							))}
						{shouldRenderPlusIcon && <PlusIcon />}
					</InfiniteScroll>
				</TasksContainer>
			)}
		</>
	)
}

export default Insight

const useInnerStyles = makeStyles(({ breakpoints }) => ({
	wrapper: {
		width: '100%',
		height: '30vh',
		backgroundColor: 'red',
		display: 'flex',
		flexDirection: 'column',
	},
	header: {
		backgroundColor: 'green',
	},

	insights: {
		overflowY: 'auto',
		backgroundColor: 'black',
		color: 'white',
		flex: 1,
		height: '30vh',
	},
}))

const PlusIcon = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const tooltipTitle = t('add_task')
	const classes = useStyles({})

	return (
		<Tooltip title={tooltipTitle} placement={isRtlDirection ? 'left' : 'right'}>
			<img
				onClick={() => history.push(DashboardPaths.GOALS)}
				src={plusIcon}
				className={classes.plusButton}
				alt='plusIcon'
			/>
		</Tooltip>
	)
}
const style = {
	height: 30,
	border: '1px solid green',
	margin: 6,
	padding: 8,
}
type P = {
	isLoading?: boolean
	tasks: IBaseInsight<string>[]
	fetchMoreData: any
	toggleShowingTask: any
	employee_id?: string
}

const Scroll: FC<P> = props => {
	const { tasks, fetchMoreData, toggleShowingTask, employee_id, isLoading } =
		props
	const hasMoreInsights = useSelector(hasMoreBaseInsight)

	const fetchNextData = () => {
		setTimeout(() => {
			fetchMoreData()
		}, 500)
	}

	const shouldRenderPlusIcon = !tasks.length && !isLoading

	return (
		<InfiniteScroll
			dataLength={tasks.length}
			next={fetchNextData}
			hasMore={hasMoreInsights}
			loader={isLoading ?? <h4>Loading...</h4>}
			height={400}
			endMessage={
				<p style={{ textAlign: 'center' }}>
					<b>Gideon enough this the end!</b>
				</p>
			}
		>
			{tasks.map(i => (
				<Task
					{...i}
					toggleShowingTask={toggleShowingTask}
					employee_id={employee_id!}
				/>
			))}
			{shouldRenderPlusIcon && <PlusIcon />}
		</InfiniteScroll>
	)
}
