import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@mui/material'
type Styles = {
    isRtlDirection?: boolean
}
export default makeStyles<Theme, Styles>({
    searchWrapper: {
        width: '30%',
        marginBottom: '1%',
    },

    tab: {
        textTransform: 'none',
        fontSize: 13,
    },
    titleContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        margin: '2%',
        padding: ({ isRtlDirection }) => (isRtlDirection ? '0 3rem 0 0.5rem' : '0 0.5rem 0 3rem')
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    filtersContainer: {
        fontSize: 13,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },

    viewAll: {
        fontFamily: 'Sora, sans-serif',
        cursor: 'pointer',
        textTransform: 'none',
        fontSize: 13,
    },
    viewManager: {
        fontFamily: 'Sora, sans-serif',
        margin: '0 37px 0 29px',
        textTransform: 'none',
        fontSize: 13,
        cursor: 'pointer',
    },
    tabs: {
        width: '345px',
        '& > .MuiTabs-scroller': {
            //
            overflow: 'initial !important',
        },
        '& .MuiTabs-indicator': {
            width: '46px !important',
            marginLeft: '60px',
        },
    },
    text: {
        color: '#46484a',
        fontSize: 22,
        fontWeight: 600,
        display: 'flex',
    },
    licenses: {
        display: 'flex',
        fontSize: '0.81vw',
        width: '100%',
        margin: '1.5% auto 1% auto',
        backgroundColor: '#F9F9F9',
        '& div': {
            paddingInlineStart: '1%',
            borderRadius: '13px',

            alignItems: 'center',
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
        },
    },
    progressbar: {
        width: 'fit-content',
        margin: '5% 0 10% 0 ',
    },
    licensesContainer: {
        width: '60%',
        margin: '3% auto 0 auto',
    },
    title: {
        textAlign: 'center',
    },
})