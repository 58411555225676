import { useCallback, useEffect, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classes from './style.module.css'
import { Button, Tab, Tabs } from '@material-ui/core'
import IUser, { IUserGoalState } from 'interface/user/user-interface'
import { AppDirectionContext } from 'context/languages-context'
import { set_employee_goals } from 'redux/employee/employee-actions'
import { useDispatch, useSelector } from 'react-redux'
import { employeeLoadingSelector } from 'redux/employee/employee.selector'
import DropDown from 'components/common/DropDown'
import StatusInfoCard from 'components/common/StatusInfoCard'
import Goal from 'components/Goals/Goal'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'
import { ActivityTable } from '../ActivityTable/index'
import { Todos } from 'components/Todos/index'
import managerGoalsSvg from 'assets/managerGoalsCardSvg.svg'
import { GoalStatus } from 'enums/user/user-goal-status'
import { userActions } from 'redux/user/user-reducer'
import { filter } from 'utils/filter'
import { userSelector } from '../../redux/user/user-selector'
import { getItem, setItem } from 'utils/localStorage'
import { fakeManagerObject } from '../../utils/getManager'
import Team from 'components/Teams'
import useOutputFilterRule from 'utils/useOutputFilterRule'
import GoalCreateEdit from 'components/Goals/GoalCreateEdit'
import GoalModal from 'components/Goals/GoalModal'
import GoalCreateEditModal from 'components/Goals/GoalCreateEdit/GoalCreateEditModal'

enum Section {
	GOAL,
	ARCHIVE,
	TODO,
	MY_TEAM,
	ACTIVITY,
	MANAGER_ACTIVITY,
}
interface Props {
	accessByPermission?: boolean
	employees: IUser[]
	progressbar: JSX.Element
}

type Employee = {
	id: string
	fullName: string
}

type Tab = {
	label: string
}

const tabsState: Tab[] = [
	{
		label: 'active',
	},
	{
		label: 'archive',
	},
	{
		label: 'todo',
	},
	{
		label: 'direct_team',
	},
	{
		label: 'Activities',
	},

	{
		label: 'Manager_Activities',
	},
]

const newGoal = {
	_id: 'new',
	description: '',
	feedbacks: [],
	praises: [],
	blockages: 0,
	archive: false,
	status: GoalStatus.NOT_COMPLETED,
	needs: [],
	indexs: [],
	requiredActions: [],
} as IUserGoalState

const ManagerGoals = (props: Props) => {
	const { employees, progressbar, accessByPermission } = props

	const { isRtlDirection } = useContext(AppDirectionContext)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const user = useSelector(userSelector)
	//const loading = useSelector(employeeLoadingSelector)

	const { byEmployee } = useOutputFilterRule()

	const [itemOnEdit, setItemOnEdit] = useState('')
	const [itemOnDelete, setItemOnDelete] = useState('')
	const [currentNum, setNum] = useState(0)
	const [isNewEntry, setAsNewEntry] = useState(false)

	const displayGoalsSection = currentNum === Section.GOAL
	const displayActivitySection = currentNum === Section.ACTIVITY
	const displayManagerActivitySection = currentNum === Section.MANAGER_ACTIVITY
	const displayTodosSection = currentNum === Section.TODO
	const displayArchive = currentNum === Section.ARCHIVE
	const displayTeamTodos = currentNum === Section.MY_TEAM

	const filteredDirectManager = useMemo(
		() =>
			filter(
				employees,
				e => e.securityInfo.email === user.statusInfo.directManager
			)[0],
		[employees, user]
	)
	const filterByManager = useCallback(
		(e: IUser) => e.statusInfo.directManager === user.securityInfo.email,
		[user]
	)

	const filterByDirectManager = useCallback(
		({ statusInfo }: IUser) =>
			statusInfo.directManager === filteredDirectManager.securityInfo.email,
		[filteredDirectManager]
	)
	const managerEmployees = useMemo(() => {
		const shouldGetDirectManager =
			accessByPermission && displayManagerActivitySection
		const filterFn = shouldGetDirectManager
			? filterByDirectManager
			: filterByManager
		return filter(employees, filterFn)
	}, [employees, accessByPermission, displayManagerActivitySection])
	const fakeManagerObjectWithName = {
		...fakeManagerObject,
		personalInfo: {
			...fakeManagerObject.personalInfo,
			firstName: t('pick_user'),
		},
	}


	const employeesSelectData = useMemo((): Employee[] => {



		var meList =  [fakeManagerObjectWithName, ...managerEmployees].map((e: IUser) => ({
			id: e._id,
			fullName: e.personalInfo.firstName + ' ' + e.personalInfo.lastName,
		}))
		//meList.push({id: 'mockup_employee_id',fullName: 'Mockup Employee'})
		return meList
	}, [managerEmployees])


	const empId = byEmployee
		? byEmployee
		: employeesSelectData.length
		? employeesSelectData[0].id
		: ''
	const [selectedEmployeeId, selectEmployeeId] = useState(empId)

	useEffect(() => {
		const taskUserTeams = getItem('nav-team')
		if (taskUserTeams) setNum(Section.MY_TEAM)
		return () => {
			setItem('nav-team', false)
		}
	}, [])

	useEffect(() => {
		const selectedEmployee = getItem('selectedEmployee')
		if (selectedEmployee !== '') {
			selectEmployeeId(selectedEmployee)
			setNum(Section.TODO)
		}
	}, [])

	const setSelectedEmployee = (id: string) => {
		selectEmployeeId(id)
		dispatch(userActions.filterOutputsByEmployee({ byEmployee: id }))
	}

	const selectedEmployee = useMemo(
		() => managerEmployees.find(e => e._id === selectedEmployeeId),
		[selectedEmployeeId, managerEmployees?.length, employees]
	)

	const employeeGoals = selectedEmployee ? selectedEmployee.statusInfo.goals : []

	const newGoalNumber = employeeGoals
		? employeeGoals.filter(g => !g.archive).length + 1
		: 1

	const saveGoalEntry = (updatedGoal: IUserGoalState)=>{
		setGoal(updatedGoal);
		setAsNewEntry(false);
	}

	const setGoal = useCallback(
		(goal: IUserGoalState) => {
			let updatedGoals = []
			if (goal._id === 'new') {
				updatedGoals = [...employeeGoals, { ...goal }]
			} else {
				updatedGoals = employeeGoals.map(g => (g._id === goal._id ? goal : g))
			}
			applyDispatch(updatedGoals)
		},
		[employeeGoals]
	)

	const deleteHandler = (goalId: string) => {
		let updatedGoals = employeeGoals.filter(g => g._id !== goalId)
		applyDispatch(updatedGoals)
	}

	const archiveHandler = (goalId: string) => {
		let updatedGoals = employeeGoals.map(g => {
			return g._id === goalId ? { ...g, archive: !g.archive } : g
		})
		applyDispatch(updatedGoals)
	}
	const applyDispatch = (updatedGoals: IUserGoalState[]) => {
		dispatch(
			set_employee_goals({
				id: selectedEmployeeId,
				goals: updatedGoals,
			})
		)
		setItemOnEdit('')
	}

	const filterByPermission = (t: Tab) => true
	const regularFilter = (t: Tab) => t.label !== 'Manager_Activities'

	const tabs = filter(
		tabsState,
		accessByPermission ? filterByPermission : regularFilter
	)

	const manager = displayActivitySection ? user : filteredDirectManager

	const defaultDropDownUser = employeesSelectData.find(
		e => e.id === (selectedEmployeeId ?? '')
	)
	return (
		<div
			onClick={() => {
				itemOnEdit && window.getSelection()?.type !== 'Range' && setItemOnEdit('')
			}}
		>
			<StatusInfoCard
				boxShadow='2px 2px 5px rgba(0, 0, 0, 0.1)'
				backgroundColor={'#0171C2,#1E7EC3,#2D85C4'}
				imageSrc={managerGoalsSvg}
				title={t('hi', {
					username: user.personalInfo.firstName,
					interpolation: { escapeValue: false },
				})}
				description={t('set_goals_to_your_employees')}
			/>

			<div className={classes.mainContainer}>
        <div
          className={
            isRtlDirection
              ? `${classes.actionsBar} ${classes.rtl}`
              : classes.actionsBar
          }
        >
          <div className={classes.dropdownContainer}>
            <DropDown
              defaultValue={defaultDropDownUser}
              width='100%'
              values={employeesSelectData}
              onSelect={setSelectedEmployee}
            />
          </div>
          <div className={classes.addButton}>
            {displayGoalsSection && (
              <Button
                variant='text'
                color='inherit'
                disabled={!empId}
                onClick={() => {
                  setItemOnEdit('new')
                  setAsNewEntry(true)
                }}
              >
                {t('add_goal')}
              </Button>
            )}
          </div>
          <Tabs
            className={classes.tabs}
            value={currentNum}
            indicatorColor='secondary'
            textColor='secondary'
          >
            {tabs.map(({ label }, index) => (
              <Tab
                label={t(label)}
                onClick={() => {
                  setNum(index)
                }}
              />
            ))}
          </Tabs>
        </div>
        <div
          className={
            isRtlDirection ? `${classes.goals} ${classes.rtl}` : classes.goals
          }
          style={{
            justifyContent:
              displayActivitySection ||
              displayManagerActivitySection ||
              displayTodosSection
                ? 'center'
                : 'flex-start', // refactore styling!
            // width:
            // displayActivitySection || displayManagerActivitySection
            // 	? '100%'
            // 	: 'fit-content',
          }}
        >
          {displayTodosSection &&
            !!managerEmployees.length &&
            selectedEmployee && (
              <Todos selectedEmployee={selectedEmployee!} isManagerViewing />
            )}

          {displayTeamTodos && !!managerEmployees.length && (
            <Team managerGoalsScreen />
          )}

          {(displayActivitySection || displayManagerActivitySection) && (
            <ActivityTable
              accessByPermission={displayManagerActivitySection}
              manager={manager}
              selectedEmployeeId={selectedEmployeeId}
            />
          )}
          {(displayGoalsSection || displayArchive) &&
            employeeGoals
              .filter(g => (displayArchive ? g.archive : !g.archive))
              .map((goal, idx) => (
                <Goal
                  employee_id={selectedEmployeeId}
                  isOnEdit={itemOnEdit === goal._id}
				          isNewEntry={false}
                  setItemOnEdit={setItemOnEdit}
			        	  onSaveGoal = {saveGoalEntry}
                  goal={goal}
                  goalNumber={idx + 1}
                  key={goal._id}
                  onToggleArchive={(id: string) => archiveHandler(id)}
                  onDelete={(id: string) => setItemOnDelete(id)}
                  enableSavingForEmployee={true}
                />
              ))}

          <GoalCreateEditModal
						goalInitialValues={newGoal}
						onClose={() => setAsNewEntry(false)}
						isOpen={!displayArchive && isNewEntry}
						onSaveModal={saveGoalEntry}
						enableSavingForEmployee={true}
          />
        </div>
			</div>

			<ConfirmModalSB
				isOpen={itemOnDelete ? true : false}
				text={`Are you sure you want to delete ${
					employeeGoals.find(g => g._id === itemOnDelete)?.description
				} ?`}
				onConfirm={() => deleteHandler(itemOnDelete)}
				onClose={() => setItemOnDelete('')}
			/>
		</div>
	)
}

export default ManagerGoals


