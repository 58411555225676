import { makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ReactElement } from 'react'
import { UseFormRegister } from 'react-hook-form'
import { INewUserInitState } from 'interface/user/user-interface'

export type EditUser = INewUserInitState & {
	mobile_prefix?: string
	uploadImageUrl?: string
}
interface Props extends DropdDownStyleProp {
	values: Array<string>
	name: keyof EditUser
	handleChange: (
		event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => void
	defaultValue?: string
}

type DropdDownStyleProp = {
	maxWidth?: number
	top?: string
	backGroundColor?: string
	height?: string
}
const arrowIconWidth = 9
const arrowIconMargin = 14

function DropDownBuilder({
	values,
	handleChange,
	name,
	maxWidth,
	top,
	backGroundColor,
	height,
	defaultValue,
}: Props): ReactElement {
	const classes = useStyles({ maxWidth, top, backGroundColor, height })
	const { t } = useTranslation()

	const renderSelectors = () =>
		values.map(value => {
			const selected = defaultValue === value ? true : false
			return (
				<option
					key={value}
					selected={selected}
					className={classes.text}
					value={value}
				>
					{t(value)}
				</option>
			)
		})
	const selectors = renderSelectors()
	return (
		<div className={classes.dropDownContainer}>
			<select name={name} onChange={handleChange} className={classes.dropDownText}>
				{selectors}
			</select>
			<div className={classes.iconContainer}>
				<i></i>
			</div>
		</div>
	)
}

export default DropDownBuilder

const useStyles = makeStyles<Theme>(theme => ({
	dropDownContainer: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		maxWidth: ({ maxWidth }: DropdDownStyleProp) => maxWidth && maxWidth,
	},
	dropDownText: {
		width: '100%',
		flex: 1,
		border: 'none',
		height: ({ height }: DropdDownStyleProp) => (height ? height : '2rem'),
		marginRight: 0,
		display: 'flex',
		alignItems: 'center',
		borderRadius: ' 4px',
		backgroundColor: ({ backGroundColor }: DropdDownStyleProp) =>
			backGroundColor ? backGroundColor : '#E8ECEF',
		color: '#4A4A4A',
		padding: `0 ${arrowIconWidth + arrowIconMargin * 2}px 0 12px`,
		cursor: 'pointer',
		appearance: 'none',
		'&:focus': {
			outline: '2px solid #0071C2',
		},
	},
	iconContainer: {
		position: 'absolute',
		right: 17,
		top: ({ top }: DropdDownStyleProp) => (top ? top : '27px'),
		bottom: 'auto',
		display: 'flex',
		pointerEvents: 'none',
		alignItems: 'center',

		'& > i': {
			border: 'solid black',
			borderWidth: '0 1.5px 1.5px 0',
			display: 'inline-block',
			padding: '3px',
			transform: 'rotate(45deg)',
			webkitTransform: 'rotate(45deg)',
		},
	},
	text: {
		color: '#4A4A4A',
		fontFamily: 'Sora, sans-serif',
		letterSpacing: 0,
		lineHeight: '1rem',
		fontSize: '12px',
		cursor: 'pointer',
	},
}))
