import React, { Children, FC, useContext } from 'react'
import { HeaderPropsTypes, Icon } from '..'
import { makeStyles, Theme } from '@material-ui/core'
import hamburgerIcon from 'assets/hamburgerIcon.svg'
import UserAvatar from '../../Avatar'
import { Link } from './constants'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from 'redux/user/user-selector'
import { userActions } from 'redux/user/user-reducer'
import { Drawer } from '@mui/material'
import { AppDirectionContext } from 'context/languages-context'
import UploadAvatar from '../UploadAvatar'
import useLogic from '../PersonalProfileMenu/useLogic'
import { edit_user_action } from 'redux/user/user-actions'
import {
	handleUserSaveImageInCloud,
	userEditToIUser,
} from '../PersonalProfileMenu/useLogic'
import IUser from '../../../../interface/user/user-interface'
import { EditUser } from '../../../CompanyBuilder/Builder/DropDownBuilder'

type HeaderSideNavMenuProp = {
	isMenuOpen: boolean
	currentUserFullName: string
	title: string
	handleOpenMobileMenu: () => void
}
// type SideNavLinkProps = {
// 	handleOpenMobileMenu: () => void
// } & Link

// const pagesToDashboard = ['kci', 'debriefings', 'tasks']
// const checkCurrentPath = (pagePath: string, linkPath: string) => {
// 	return pagePath === linkPath
// }

// const SideNavLink: FC<SideNavLinkProps> = ({
// 	link,
// 	NavIcon,
// 	linkName,
// 	handleOpenMobileMenu,
// }) => {
// 	const [isActivePage, setisActivePage] = useState(false)
// 	const history = useHistory()
// 	const location = useLocation()
// 	const main = 'main'

// 	const { t } = useTranslation()
// 	const classes = useStyles()

// 	useEffect(() => {
// 		const isActive = checkCurrentPath(location.pathname, link)
// 		isActive ? setisActivePage(prev => true) : setisActivePage(prev => false)
// 	}, [location, link])

// 	const handleClick = () => {
// 		!pagesToDashboard.includes(link) && history.push(link === main ? '/' : link)
// 		handleOpenMobileMenu()
// 	}
// 	return (
// 		<div className={classes.linkWrapper} onClick={handleClick}>
// 			<div
// 				style={{
// 					border: isActivePage ? '1px solid rgb(52, 171, 83)' : '1px solid #DEE3EF',
// 					boxShadow: isActivePage
// 						? ' rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'
// 						: 'none',
// 					backgroundColor: isActivePage ? 'rgb(52, 171, 83)' : 'white',
// 				}}
// 				className={classes.iconLinkWrapper}
// 			>
// 				<NavIcon color={isActivePage ? '' : '#34AB53'} />
// 			</div>
// 			<div className={classes.sideNavLink}>{t(linkName)}</div>
// 		</div>
// 	)
// }
export const Menu: FC<HeaderSideNavMenuProp> = ({
	isMenuOpen,
	currentUserFullName,
	title,
	handleOpenMobileMenu,
	children,
}) => {
	const user = useSelector(userSelector)

	// const {
	// 	saveAvatarViaMobile,
	// 	buildUserPayload,
	// 	handleUploadFile,
	// 	previewImage,
	// } = useLogic({
	// 	hanaldeToggleCard: handleOpenMobileMenu()!,
	// })
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const handleLogOut = () => {
		handleOpenMobileMenu()
		setTimeout(() => {
			dispatch(userActions.signOutUser())
		}, 1000)
	}

	// fix
	const handleUploading = async (image: string) => {
		const data: any = new FormData()
		data.append('file', image)
		data.append('upload_preset', 'qkjibttv')
		data.append('cloud_name', 'sayDo')

		const userImageUrl: any = await handleUserSaveImageInCloud(data)
		const editedUser = userEditToIUser(
			{ uploadImageUrl: userImageUrl } as EditUser,
			user
		)

		const payload: IUser = {
			...editedUser,
			securityInfo: {
				...editedUser.securityInfo,
				email: user.securityInfo.email,
			},
			personalInfo: {
				...editedUser.personalInfo,
			},
		}

		dispatch(edit_user_action(payload))
		dispatch(userActions.editUser(payload))
		handleOpenMobileMenu?.()
	}

	return (
		<div>
			<Drawer open={isMenuOpen} sx={{ direction: isRtlDirection ? 'rtl' : 'ltr' }}>
				<div
					className='drawer-title-div'
					style={{ width: '100vw', padding: '0 26px', boxSizing: 'border-box' }}
				>
					<div className={classes.headerUserInformation}>
						<div className={classes.userInfoContainer}>
							{/* <UserAvatar className={classes.sideNavImage} userInfo={userInfo} /> */}
							<UploadAvatar
								previewImage={user?.personalInfo?.imgSrc}
								handleUplingFile={handleUploading}
							/>
							<div className={classes.userInfo}>
								<div className={classes.userName}>{currentUserFullName}</div>
								<div className={classes.userRole}>{title}</div>
							</div>
						</div>
						<div className={classes.closeIcon} onClick={handleOpenMobileMenu}>
							&times;
						</div>
					</div>
					{children}
					<div className={classes.linkWrapper}>
						<div className={classes.logOut} onClick={handleLogOut}>
							{t('logout')}
						</div>
					</div>
				</div>
			</Drawer>
		</div>
	)
}

const MobileMenu: FC<HeaderPropsTypes> = React.memo(
	({
		firstName,
		lastName,
		title,
		handleOpenMobileMenu,
		isMenuOpen,
		children,
	}) => {
		const { isRtlDirection } = useContext(AppDirectionContext)
		const classes = useStyles({ isRtlDirection })

		const menuWrapperStyle = isMenuOpen
			? classes.sideNavMenuWrapperOpen
			: classes.sideNavMenuWrapperClose

		return (
			<>
				<div style={{ paddingInline: '5%' }} className={classes.actionsContainer}>
					<Icon
						style={classes.swapIcon}
						icon={hamburgerIcon}
						alt='hamburger Icon'
						handleClick={handleOpenMobileMenu}
					/>
				</div>
				<div className={menuWrapperStyle}>
					<Menu
						title={title}
						currentUserFullName={`${firstName} ${lastName}`}
						isMenuOpen={isMenuOpen}
						handleOpenMobileMenu={handleOpenMobileMenu}
					>
						{children}
					</Menu>
				</div>
			</>
		)
	}
)

export default MobileMenu

const useStyles = makeStyles<Theme, { isRtlDirection?: boolean }>(
	({ breakpoints, palette }) => ({
		mobileContainer: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',

			padding: '24px 20px',
			direction: 'rtl',

			[breakpoints.down('md')]: {
				zIndex: 99,
				backgroundColor: 'white',
				position: 'sticky',
				top: 0,
			},
		},
		sideNavMenuWrapperOpen: {
			width: '100%',
			height: '100%',

			position: 'fixed',
			zIndex: 12,
			top: 0,
			right: 0,
			left: 0,

			backgroundColor: '#FFFFFF',
			transition: '0.2s ease-in-out',
			direction: 'ltr',
		},
		sideNavMenuWrapperClose: {
			width: '0',
			height: '100%',
			position: 'fixed',
			zIndex: 1,
			right: 0,
			left: 0,
			transition: '0.2s ease-in-out',
		},
		sideNameContent: {
			color: 'white',
		},
		sideNameContentClose: {
			display: 'none',
		},
		linkWrapper: {
			display: 'flex',
			padding: '2% 0 2% 0',
		},
		sideNavLink: {
			display: 'flex',
			alignItems: 'center',
			color: palette.text.primary,
		},
		sideNavLinkActivated: {
			display: 'flex',
			alignItems: 'center',
			color: palette.text.primary,
			fontWeight: 'bold',
		},
		iconLinkWrapper: {
			height: 30,
			width: 30,
			padding: '3%',

			borderRadius: 10,
			marginRight: '26px ',
			cursor: 'pointer',
		},
		logOut: {
			padding: '1%',
			width: '100%',
			color: '#0071C2',
			textAlign: 'center',
			cursor: 'pointer',
		},
		headerUserInformation: {
			padding: '32px 0',
			display: 'flex',
			justifyContent: 'space-between',
		},
		closeIcon: {
			fontSize: '2rem',
			cursor: 'pointer',
			color: palette.text.primary,
		},
		userInfoContainer: {
			display: 'flex',
			alignItems: 'center',
		},
		userInfo: {
			marginLeft: ({ isRtlDirection }) => (isRtlDirection ? 0 : 19),
			marginRight: ({ isRtlDirection }) => (isRtlDirection ? 19 : 0),
		},
		userName: {
			fontSize: 16,
			fontWeight: 'bold',
			color: palette.text.primary,
			textTransform: 'capitalize',
		},
		userRole: {
			textTransform: 'capitalize',
			fontSize: 13,
			color: palette.text.primary,
		},
		actionsContainer: {
			display: 'flex',
			alignItems: 'center',
			// paddingLeft: 21,
		},
		sayDoLogoContainer: {
			padding: '32px 20px 0 0',
		},
		sayDoLogo: {
			width: 106,
			position: 'relative',
			bottom: '-9px',
		},
		swapIcon: {
			transform: ({ isRtlDirection }) =>
				isRtlDirection ? 'rotate(none)' : 'rotate(180deg)',
		},
		hamburgerIconStyle: {
			height: 21,
			width: 22,

			marginTop: 34,
		},
		searchIconIconStyle: {
			width: 20,
			height: 20,
		},
		sideNavImage: {
			width: '46px',
			height: '46px',
		},
	})
)
