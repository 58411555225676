import React, { FC } from 'react'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { useTranslation } from 'react-i18next'
import ExcelIcon from 'assets/excel.png'
import useStyles from './style'

type ExcelProps = {
    apiData: any
    fileName: string
}

const fileExtension = ".xlsx"
const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"

export const EportTableToExcelFile: FC<ExcelProps> = ({ apiData, fileName }) => {
    const { t } = useTranslation()
    const classes = useStyles({})

    const exportToCSV = (apiData: any, fileName: string) => {
        const ws = XLSX.utils.json_to_sheet(apiData)
        XLSX.utils.sheet_add_aoa(ws, [['Company name', 'Praises', 'Feedbacks', 'Pers. learning', 'Group learning', 'Managers Todos', 'Employees Todos']], { origin: "A1" })
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, fileName + fileExtension)
    }
    return (
        <div onClick={(e) => exportToCSV(apiData, t(`${fileName}`))} className={classes.excelIcon}>
            <img src={ExcelIcon} />
        </div>
    )
}
