import { useState, useEffect, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button } from "@material-ui/core"
import { useTranslation } from 'react-i18next'

import './style.css'

import { AppDirectionContext } from 'context/languages-context'

import { userSelector } from 'redux/user/user-selector'

import usePopover from './usePopover'
import GenericItemButton from './GenericItemButton'
import AppPopover from 'utils/popover/AppPopover'
import { isFolderType } from 'utils/LessonsManagement/isFolder'

import { LessonsBtnsLable, LessonsIconLable } from 'enums/lessonsManagement'
import {
	File,
	Folder,
	ILessonsBase,
} from 'interface/lessons-management/LessonsBase.interface'
import { Roles } from 'enums/user/user-roles'

type Props = {
	currentFolder?: ILessonsBase<File | Folder> | null
	createFolder?: () => void
	createTitle?: () => void
	editFolder?: () => void
	createFile?: () => void
	toggleEmptyLesson?: () => void
	managerPermissions?: () => void
}
const useButtonsGenerator = ({
	currentFolder,
	createFolder,
	createTitle,
	createFile,
	editFolder,
	managerPermissions,
	toggleEmptyLesson,
}: Props) => {
	const [barButtons, setBarButtons] = useState<JSX.Element[]>([])
	const [enablePaste, setEnablePaste] = useState(false)

	const { _id: user_id, company_id, statusInfo: { role } } = useSelector(userSelector)

	const { t } = useTranslation()

	const { isRtlDirection } = useContext(AppDirectionContext)

	const btnsClass = !isRtlDirection ? 'bar-btns' : 'bar-btns rtl'

	const { anchorEl, closePopover, id, open, togglePopover } = usePopover()

	const isManagerFolder = (currentFolder?.folderManagers!?.includes(user_id))

	const popover = (
		<AppPopover anchorEl={anchorEl} id={id} close={closePopover} open={open} >
			<Box className='folder-options'>
				<Button onClick={createFolder}>{t('new_folder')}</Button>
				<Button onClick={createFile}>{t('new_file')}</Button>
			</Box>
		</AppPopover>
	)

	const grantePermissionBtn = role === Roles.HR ? (
		<GenericItemButton
			label={LessonsBtnsLable.FOLDER_MANAGERS}
			icon={LessonsIconLable.SHIELD}
			handleClick={managerPermissions}
		/>
	) : <></>

	useEffect(() => {
		if (!currentFolder) return

		const isMainFolder = currentFolder._id === company_id
		const isSubMainFolder = currentFolder.prev === company_id

		if (!isFolderType(currentFolder.type)) {
			setBarButtons([
				<GenericItemButton
					label={LessonsBtnsLable.NEW_TITLE}
					icon={LessonsIconLable.PLUS}
					handleClick={createTitle}
				/>,
				<GenericItemButton
					label={LessonsBtnsLable.NEW_LESSON}
					icon={LessonsIconLable.PLUS}
					handleClick={toggleEmptyLesson}
				/>,
			])
			setEnablePaste(prev => true)
			return
		}

		setEnablePaste(prev => false)

		if (isMainFolder) {
			setBarButtons([
				<GenericItemButton
					label={LessonsBtnsLable.NEW_FOLDER}
					icon={LessonsIconLable.PLUS_FOLDER}
					handleClick={createFolder}
				/>
			])
		} else if (!isMainFolder && isSubMainFolder) {
			setBarButtons([
				grantePermissionBtn,
				<GenericItemButton
					label={LessonsBtnsLable.NEW_FOLDER}
					icon={LessonsIconLable.PLUS_FOLDER}
					handleClick={createFolder}
				/>
			])
		} else if (!isMainFolder && !isSubMainFolder) {
			setBarButtons([
				grantePermissionBtn,
				<GenericItemButton
					label={LessonsBtnsLable.EDIT_FOLDER}
					icon={LessonsIconLable.PENCIL}
					handleClick={editFolder}
				/>,
				<GenericItemButton
					label={LessonsBtnsLable.NEW}
					icon={LessonsIconLable.PLUS}
					handleClick={(e) => togglePopover(e)}
					aria_describedby={id}
				/>, popover
			])
		}
	}, [currentFolder, open])

	return {
		buttons: <div className={btnsClass}>{barButtons}</div>,
		enablePaste
	}
}

export default useButtonsGenerator