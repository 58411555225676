import { useContext } from 'react'
import { makeStyles, Theme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { IThemeOptions } from 'theme'

import { AppDirectionContext } from 'context/languages-context'

import { delete_debrief_event } from 'redux/debrief/debrief-actions'
import { debriefOnProccesSelector } from 'redux/debrief/debrief-selector'
import { debriefActions } from 'redux/debrief/debrief-reducer'

import dragHolderIcon from 'assets/dragHolderIcon.svg'
import editIcon from 'assets/purpleEditIcon.svg'
import deleteIcon from 'assets/purpleTrashIcon.svg'
import { userSelector } from 'redux/user/user-selector'

interface Props {
	id: string
	description: string
	titleElement: JSX.Element
	isMatchPointChoice?: boolean
	selected?: boolean
	onEventClick?: () => void
}
const Event = ({
	id,
	description,
	titleElement,
	isMatchPointChoice,
	selected,
	onEventClick,
}: Props) => {
	const dispatch = useDispatch()
	const { isRtlDirection } = useContext(AppDirectionContext)

	const debrief = useSelector(debriefOnProccesSelector)
	const { company_id } = useSelector(userSelector)

	const classes = useStyles({ selected, isMatchPointChoice, isRtlDirection })

	const deleteEvent = () => {
		dispatch(
			debrief &&
				delete_debrief_event({
					company_id: company_id,
					debrief_id: debrief._id,
					event_id: id,
				})
		)
	}

	const editEvent = () => {
		dispatch(debriefActions.setDebriefEventOnEdit(id))
	}

	return (
		<div
			onClick={onEventClick ? onEventClick : () => {}}
			className={classes.card}
		>
			<div className={classes.content}>
				<div className={classes.sideContainer}>
					{!isMatchPointChoice && <img src={dragHolderIcon} />}
				</div>
				<div className={classes.mainContainer}>
					<div className={classes.topContainer}>
						<div className={classes.title}>
							<p>{titleElement}</p>
						</div>
						{!isMatchPointChoice && (
							<div className={classes.buttonsContainer}>
								<div className={classes.buttonContainer} onClick={editEvent}>
									<img src={editIcon} />
								</div>
								<div className={classes.buttonContainer} onClick={deleteEvent}>
									<img src={deleteIcon} />
								</div>
							</div>
						)}
					</div>
					<div className={classes.bottomContainer}>
						<p className={classes.description}>{description}</p>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Event

const useStyles = makeStyles<
	Theme & IThemeOptions,
	{
		isMatchPointChoice: boolean | undefined
		selected: boolean | undefined
		isRtlDirection: boolean
	}
>(({ breakpoints, palette }) => ({
	card: {
		cursor: ({ isMatchPointChoice }) => (isMatchPointChoice ? 'pointer' : ''),
		outline: ({ selected }) =>
			selected ? `2px solid ${palette.debrief.main}` : '2px transparent',
		boxShadow: ({ selected }) =>
			selected ? `5px 5px 10px ${palette.debrief.shadow}` : '',
		backgroundColor: 'white',
		width: '100%',
		border: ({ selected }) =>
			selected ? '1px solid transparent' : '1px solid #dee3ef',
		borderRadius: '10px',
		padding: '1.5% 0 1.5% 0',
	},
	content: {
		display: 'flex',
		height: '100%',
		width: '100%',
	},
	sideContainer: {
		display: 'flex',
		width: '5%',
		'& img': {
			margin: 'auto',
			width: '30%',
			height: '60%',
		},
	},
	mainContainer: {
		paddingInlineStart: '2%',
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	topContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	title: {
		'& p': {
			margin: '0',
			padding: '0',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
		display: 'flex',
		width: '78%',
	},
	buttonsContainer: {
		marginInlineEnd: '2%',
		width: '12%',
		display: 'flex',
		justifyContent: 'space-between',
		[breakpoints.down('sm')]: { width: '20%' },
	},
	buttonContainer: {
		cursor: 'pointer',
		width: '35%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& img': {
			height: '80%',

			[breakpoints.down('sm')]: {
				height: '100%',
			},
		},
	},
	bottomContainer: {
		'& p': {
			textAlign: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
		},
		width: '100%',
		height: '70%',
	},
	description: {
		color: '#46484A',
		// lineBreak: 'anywhere',
		padding: '0',
		margin: '0',
		paddingInlineEnd: '2%',
	},
}))
