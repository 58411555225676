import { useTranslation } from 'react-i18next'

import './style.css'

import SelectionAccessCard from './SelectionAccessCard'
import SearchRectangle from "components/common/SearchRectangle"
import { setHasInclude } from 'utils/hasSetInclude'

import { DepartmentMembersListType } from '../props'
import { ModalOperations } from 'enums/lessonsManagement'

const max_managers_permissions_per_folder = 3

const DepartmentMembersList = ({
    availablePermissions,
    setUserInput,
    setPermissionsSetList,
    permissionsSetList,
    operation
}: DepartmentMembersListType) => {

    const maxPermissions = permissionsSetList.size >= max_managers_permissions_per_folder
    console.log({ maxPermissions })
    const { t } = useTranslation()

    const searchCoworckerByName = ({
        target: { value },
    }: React.ChangeEvent<HTMLInputElement>) => {
        setUserInput(value)
    }

    const updatePermissionsList = (_id: string) => {
        if (setHasInclude(permissionsSetList, _id)) {
            setPermissionsSetList(prevSet => getUpdatedSet(prevSet, _id))
            return
        }

        if (maxPermissions) return

        setPermissionsSetList(prev => new Set([...prev, _id]))
    }

    const getUpdatedSet = (prevSet: Set<string>, _id: string) => {
        const updatedSet = new Set(prevSet)
        updatedSet.delete(_id)
        return updatedSet
    }

    return (
        <div className='access-users'>

            <SearchRectangle
                placeholder={t('add_access')}
                handleChange={searchCoworckerByName}
                className='search-wrapper'
                inputFieldStyle='input-rectangle'
            />

            {!!availablePermissions?.length
                ? availablePermissions.map(user => (
                    <SelectionAccessCard
                        key={user._id}
                        user={user}
                        updatePermissionsList={updatePermissionsList}
                        selectedAccessUsers={permissionsSetList}
                    />
                ))
                : <p>{t('not_found')}</p>
            }
        </div>
    )
}

export default DepartmentMembersList