import { ChangeEvent, FC, useState, useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@material-ui/core'
import { Pagination } from '@mui/material'

import { employeesAction } from 'redux/employee/employee-reducer'
import {
	create_employees,
	delete_all_company_employees,
	delete_employees,
	restore_employee,
	update_employee,
} from 'redux/employee/employee-actions'
import {
	employeeErrorSelector,
	employeeLoadingSelector,
	employeesSelector,
} from 'redux/employee/employee.selector'

import { AppDirectionContext } from 'context/languages-context'

import useStyles from './styled'

import useTablePagination, { ListType } from './useTablePagination'
import { occurrencesOf } from 'utils/calcRemainingLicense'
import { debounceSearch } from 'utils/debounceSearch'
import { closestMatch } from 'components/Bot/UsersList/useHandlers'

// import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'
import Button from 'components/common/Button'
import CompanyBuilderTableData from './CompanyBuilderTableData'
import CustomizedModal from './CustomizedModal'
import RenderContextTitleAndSubTitle from '../RenderContextTitleAndSubTitle'
import TableTile from './TableTitle'
import SearchRectangle from 'components/common/SearchRectangle'

import { ILicenses } from 'interface/Licenses'
import { License } from 'enums/License/License.enum'
import { Langs } from 'enums/app'
import IUser, {
	INewUserInitState,
	IUserPersonalInfo,
	IUserSecurityInfo,
	IUserStatusInfo,
} from 'interface/user/user-interface'
import LicensesContainer from './LicensesContainer'
import { employeesMapIUser } from 'utils/employeesMapIUser'

export const getChangedProperties = <T extends Record<string, any>>(
	oldObj: T,
	updatedObj: T
) => {
	return Object.fromEntries(
		Object.entries({ ...oldObj, ...updatedObj }).filter(([key, value]) => {
			if (
				!Object.is(oldObj[key], updatedObj[key]) &&
				!Array.isArray(oldObj[key])
			) {
				return [key, value]
			}
		})
	)
}
type Props = {
	paidLicense: ILicenses
	companyName?: string
	company_id: string
	companyLanguage: Langs
	height?: string
	upload?: boolean
}
const CompanyBuilder: FC<Props> = props => {
	const {
		paidLicense,
		companyName,
		company_id,
		height,
		companyLanguage,
		upload = true,
	} = props
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isModalOpenAll, setIsModalOpenAll] = useState(false)
	const [searchResults, setSearchResults] = useState<{
		results: IUser[]
		searchValue: string
	}>({ results: [], searchValue: '' })
	const [employeeToDeletId, setEmployeeToDeletId] = useState('')
	const [value, setValue] = useState(0)
	const [listType, setListType] = useState<ListType>(ListType.all)
	const [page, setPage] = useState(1)
	const [modalError, setModalError] = useState('')

	const dispatch = useDispatch()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })

	const employees = useSelector(employeesSelector)
	const loading = useSelector(employeeLoadingSelector)
	const error = useSelector(employeeErrorSelector)

	const hasEmployees = Object.values(employees).length > 0
	const licenses = employees.map(e => e.statusInfo.license)

	useEffect(() => {
		if (error) {
			setModalError(error)
			dispatch(employeesAction.clearError())
		}
	}, [error])

	const { currentEmployees, employeesChuncks } = useTablePagination({
		listType,
		page,
		searchResults,
	})

	const remainingLicense = {
		silver: paidLicense.silver - occurrencesOf(License.silver, licenses),
		bronze: paidLicense.bronze - occurrencesOf(License.bronze, licenses),
		gold: paidLicense.gold - occurrencesOf(License.gold, licenses),
	}

	const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
		setValue(newValue)
	}

	const searchEmployee = ({
		target: { value },
	}: ChangeEvent<HTMLInputElement>) => {
		let startOfFoundEmployeesList = 0
		let end = value.trim().includes(' ') ? employees.length : 20
		if (value) {
			const mostIdenticalNames = closestMatch(employees, value).slice(
				startOfFoundEmployeesList,
				end
			)

			setSearchResults({ results: mostIdenticalNames, searchValue: value })
		} else setSearchResults({ results: [], searchValue: value })
	}

	const handlePageChange = (event: any, value: number) => {
		setPage(value)
	}

	const onEditEmployeeInfo = async (employee: INewUserInitState, id: string) => {
		const [newData] = employeesMapIUser([employee], company_id, companyLanguage)
		const [oldData] = employees.filter(({ _id }) => _id === id)

		const personalInfoUpdated = getChangedProperties<IUserPersonalInfo>(
			oldData.personalInfo,
			newData.personalInfo
		)
		const securityInfoUpdated = getChangedProperties<IUserSecurityInfo>(
			oldData.securityInfo,
			newData.securityInfo
		)
		const statusInfoUpdated = getChangedProperties<IUserStatusInfo>(
			oldData.statusInfo,
			newData.statusInfo
		)
		console.log({ securityInfoUpdated, statusInfoUpdated, personalInfoUpdated })
		const result = {
			personalInfo: personalInfoUpdated,
			securityInfo: securityInfoUpdated,
			statusInfo: statusInfoUpdated,
		} as IUser

		handleUpdateEmployeeInfo(result, id)
	}
	const handleUpdateEmployeeInfo = (result: IUser, id: string) => {
		dispatch(
			update_employee({
				employee: {
					...result,
					company_id,
					_id: id,
				},
			})
		)
	}

	const handleAddNewUser = async (employee: INewUserInitState) => {
		const [structeredEmployee] = employeesMapIUser(
			[employee],
			company_id,
			companyLanguage
		)
		console.log({ structeredEmployee })
		dispatch(create_employees({ employees: [structeredEmployee] }))
	}

	const handleOpenModalDeleteEmployee = (employeeId: string) => {
		setIsModalOpen(true)
		setEmployeeToDeletId(employeeId)
	}

	const handleRestoreEmployee = (id: string) => {
		const employeeToRestore = employees.filter(emp => emp._id === id)[0]
		dispatch(restore_employee(employeeToRestore))
	}

	const handleDeleteEmployee = async (id: string) => {
		dispatch(delete_employees([id]))
		setIsModalOpen(false)
	}
	const handleDeleteAll = async () => {
		dispatch(delete_all_company_employees(company_id))
		setIsModalOpenAll(false)
	}
	const handleSetListType = (listType: ListType) => {
		setListType(listType)

		if (listType !== ListType.managers) return

		setPage(prev => prev - prev + 1)
	}
	const progressbar = (
		<div className={classes.progressbar}>
			<CircularProgress size={150} />
		</div>
	)
	return (
		<>
			{upload && (
				<>
					<RenderContextTitleAndSubTitle
						companyLanguage={companyLanguage}
						remainingLicense={remainingLicense}
						company_id={company_id}
						onError={(error: string) => setModalError(error)}
						title={`${companyName} company builder`}
						subTitile='select_your_co-workers'
						additionalText='upload_saydo_file'
						hasEmployees={hasEmployees}
						isUploadingFile={false}
					/>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Button onClick={() => setIsModalOpenAll(true)} width={250}>
							Delete all{' '}
						</Button>
					</div>
				</>
			)}

			<LicensesContainer
				paidLicense={paidLicense}
				remainingLicense={remainingLicense}
			/>

			<div className={classes.contentContainer}>
				<TableTile
					value={value}
					handleChange={handleChange}
					handleSetListType={handleSetListType}
				/>

				{loading
					? progressbar
					: hasEmployees && (
							<>
								<SearchRectangle
									handleChange={debounceSearch(searchEmployee)}
									className={classes.searchWrapper}
								/>
								{!searchResults.results.length && !searchResults.searchValue && (
									<Pagination
										style={{ direction: 'ltr' }}
										count={employeesChuncks.length}
										page={page}
										onChange={handlePageChange}
									/>
								)}
								<CompanyBuilderTableData
									onError={(e: string) => setModalError(e)}
									remainingLicense={remainingLicense}
									height={height}
									handleEditUser={onEditEmployeeInfo}
									handleAddNewUser={handleAddNewUser}
									handleOpenModal={handleOpenModalDeleteEmployee}
									employees={currentEmployees}
									handleRestore={handleRestoreEmployee}
								/>
							</>
					  )}
				<CustomizedModal
					employeeToDeletId={employeeToDeletId}
					handleDeleteEmployee={handleDeleteEmployee}
					isModalOpen={isModalOpen}
					handleCloseModal={() => setIsModalOpen(!isModalOpen)}
				/>
				<CustomizedModal
					employeeToDeletId={company_id}
					handleDeleteEmployee={handleDeleteAll}
					isModalOpen={isModalOpenAll}
					handleCloseModal={() => setIsModalOpenAll(!isModalOpenAll)}
				/>
			</div>
			<ConfirmModalSB
				onClose={() => {
					modalError && setModalError('')
				}}
				onConfirm={undefined}
				text={modalError}
				isOpen={modalError ? true : false}
			/>
		</>
	)
}
export default CompanyBuilder
