import ExpectedResult from 'components/Debriefing/ExpectedResult'
import { EMPTY_STATE } from '../useTreeLogic'

export const RenderExpectedResultChoice = ({ ...props }) => {
	const { setDisableNext, isNextDisabled, setNextData } = props

	const pageIcon = {
		src: 'surveyResults',
		opacity: '12%',
	}
	const content = (
		<ExpectedResult
			isNextDisabled={isNextDisabled}
			setDisableNext={setDisableNext}
			setData={setNextData}
		/>
	)

	return { ...EMPTY_STATE, content, pageIcon }
}
