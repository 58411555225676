import { useContext, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

import './style.css'

import { AppDirectionContext } from 'context/languages-context'

import { companyLessonsSelector } from 'redux/company/company.selector'

import useEditDescription from '../main/useEditDescription'
import usePrivateSection from './usePrivateSection'
import DescriptionSection from './DescriptionSection'
import ItemButtons from './ItemButtons'
import ItemPrefix from './ItemPrefix'

import { ItemLMType } from '../props'
import { UI } from 'enums/lessonsManagement'
import { userSelector } from 'redux/user/user-selector'
import { setHasInclude } from 'utils/hasSetInclude'

const Item = ({
	isFolder,
	sectionData,
	navigate,
	insertNewLesson,
	deleteFolder,
	editFolder,
	deleteLesson,
	toggleEmptyLesson,
	updateLesson,
	handleLessonDescription,
	folderManagers
}: ItemLMType) => {
	const [hoverOnItem, setHoverOnItem] = useState<boolean>(false)

	const companyFolders = useSelector(companyLessonsSelector)

	const [isSaveAvailable, setIsSaveAvailable] = useState({
		exist: false,
		new: false,
	})

	const { isRtlDirection } = useContext(AppDirectionContext)

	const { _id, name, permissions, createdAt } = useMemo(() => {
		if (sectionData) return {
			_id: sectionData._id,
			name: sectionData.name,
			permissions: sectionData.permissions,
			createdAt: sectionData.createdAt
		}

		else return { id: null, name: '', permissions: [''], createdAt: undefined }
	}, [sectionData])

	const childIsFile = useMemo(() => {
		if (!companyFolders.type.children) return true

		const item = companyFolders.type.children.find(f => f._id === _id)
		return item?.ui === UI.FILE

	}, [companyFolders, _id])

	const { isPrivate } = usePrivateSection({ permissions })
	const { isEdit, toggleEditLesson } = useEditDescription({ itemId: _id })

	const updateLessonDescription = () => {
		toggleEditLesson()
		updateLesson?.(_id)
	}

	const itemButtonEvents = {
		toggleEditLesson: toggleEditLesson,
		insertNewLesson: insertNewLesson,
		updateLesson: updateLessonDescription,
		toggleEmptyLesson: toggleEmptyLesson,
		deleteLesson: deleteLesson,
		deleteFolder: deleteFolder,
		editFolder: editFolder
	}

	useEffect(() => {
		if (isEdit && _id) setIsSaveAvailable({ exist: true, new: false })
		if (isEdit && !_id) setIsSaveAvailable({ exist: false, new: true })
		if (!isEdit) setIsSaveAvailable({ exist: false, new: false })
	}, [isEdit])

	let containerStyle = 'row-container'
	if (isRtlDirection) containerStyle += ' row-rtl'
	if (isEdit) containerStyle += ' edit-container'
	if (isPrivate) containerStyle += ' private'

	const handleMouseEnter = () => setHoverOnItem(true)
	const handleMouseLeave = () => setHoverOnItem(false)

	const handleNavigate = (): void => {
		// if (!isFolder) return

		// else if (!isPrivate) 
		
		navigate?.(_id!)
	}
	const displayLessonDate = !hoverOnItem && !isFolder && !isEdit && createdAt

	return (
		<div className={containerStyle}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}>

			<ItemPrefix childIsFile={childIsFile} isPrivate={isPrivate} />

			<DescriptionSection
				itemDescription={name}
				isEdit={isEdit}
				isFolder={isFolder}
				handleLessonDescription={handleLessonDescription}
				navigate={handleNavigate}
			/>

			<ItemButtons
				folderManagers={folderManagers}
				isFolder={isFolder}
				isPrivate={isPrivate}
				hoverOnItem={hoverOnItem}
				isSaveAvailable={isSaveAvailable}
				itemButtonEvents={itemButtonEvents}
				folder_id={_id}
			/>

			{displayLessonDate && <span>{dayjs(createdAt).format('DD.MM.YY')}</span>}
		</div>
	)
}

export default Item