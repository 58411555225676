import { FC } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import useStyles from '../styles'

interface Props {
	text: string
}
const EditCard: FC<Props> = ({ children, text }) => {
	const classes = useStyles({})

	return (
		<Card variant='outlined' className={classes.editCard}>
			<CardContent>
				<div className={`${classes.flexCenter} ${classes.editSection}`}>
					<div className={classes.overview}>
						<h2>overview</h2>
						<span>{text}</span>
					</div>
					{children}
				</div>
			</CardContent>
		</Card>
	)
}

export default EditCard
