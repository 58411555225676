import { useState, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Avatar } from '@material-ui/core'

import useStyles from '../styles'

import {
	delete_company,
	update_company_activation,
} from 'redux/company/company-actions'

import SearchRectangle from 'components/common/SearchRectangle'
import Button from 'components/common/Button'
import Licenses from '../Licenses/Licenses'
import { BasicSwitches } from 'components/common/BasicSwitches'
import ErrorMessage from 'utils/error/index'

import { DashboardPaths } from 'enums/routes/path-names'
import { Company } from '../../../interface/company/company-interface'

import expand from 'assets/expand.png'
import trashIcon from '../../../assets/trashIcon.svg'

interface Props {
	onCreate: () => void
	onEditEmployees: (company: Company) => void
	onEdit: (company: Company) => void
	onMsg: (onConfirm: any, msg: string) => void
	error: string
	companies: Company[]
}
const Companies = ({
	onEdit,
	onCreate,
	onEditEmployees,
	onMsg,
	companies,
	error,
}: Props) => {
	const [currentCompanies, setCurrentCompanies] = useState<Company[]>(companies)

	const classes = useStyles({})
	const dispatch = useDispatch()
	const history = useHistory()

	const onSearchChangeHandler = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
		const filterValue = value.trim().toLowerCase()

		const filteredCompanies = companies.filter(company => {
			const companyName = company.companyName.toLowerCase()
			return companyName.includes(filterValue)
		})

		setCurrentCompanies(filteredCompanies)
	}

	const updateCompanyActivation = (val: boolean, company_id: string) => {
		dispatch(update_company_activation({ company_id, activate: val }))
	}

	const deleteCompanyHandler =
		async (_id: string) => dispatch(delete_company(_id))

	const showCompanyActivity = (company_id: string) => {
		const state = {
			id: company_id
		}
		history.push(`${DashboardPaths.SUPER_COMPANY_TABLE}/${company_id}`, state)
	}

	return (
		<>
			<div className={classes.header}>

				<h1>Companies</h1>

				<SearchRectangle
					handleChange={onSearchChangeHandler}
					placeholder='Search company'
				/>

				<button className={classes.actionButton} onClick={onCreate}>
					<span className={classes.actionName}> + Add New Company</span>
				</button>

			</div>

			{!!currentCompanies.length &&
				currentCompanies.map((company: Company) => {
					const { settings: { isActivated }, _id: company_id, companyName, license } = company

					return (
						<div key={company_id} className={classes.company}>
							<div className={classes.flexCenter}>

								<div className={classes.identifier}>
									<Avatar alt={companyName}> {companyName[0]} </Avatar>
									<span className={classes.companyName}>{companyName}</span>
								</div>

								{<Licenses licenseValue={license} />}

								<BasicSwitches
									value={isActivated}
									handleClick={val => {
										updateCompanyActivation(val, company_id)
									}}
								/>

								<button
									className={classes.actionButton}
									onClick={() => onEdit(company)}
								>
									<span className={classes.actionName}> + Edit company</span>
								</button>

								<button
									className={classes.actionButton}
									onClick={() => onEditEmployees(company)}
								>
									<span className={classes.actionName}> + Edit employees</span>
								</button>

								<Button bgColor='white' width={25} onClick={() => showCompanyActivity(company_id)}>
									<img src={expand} className={classes.expandIcon} />
								</Button>

								{company.companyName !== 'SayDo' ? (
									<button
										onClick={() =>
											onMsg(
												() => deleteCompanyHandler(company._id.toString()),
												`are you sure you want to delete ${company.companyName}?`
											)
										}
										className={classes.trashIcon}
									>
										<img src={trashIcon} className={classes.expandIcon} />
									</button>
								) : (
									<span className={classes.trashIconReplacemnent}></span>
								)}

							</div>
							<div className={classes.divider} />
						</div>
					)
				})}
			<ErrorMessage />
		</>
	)
}
export default Companies
