export enum ActionNameMapScreens {
	PRAISE = 'i_see_you',
	FEEDBACK = 'i_need_you',
	DEBRIEF = 'start_new_learn_debrief',
	DEFAULT = '',
}

export const mapUrl = (action: ActionNameMapScreens): string => {
	if (action === ActionNameMapScreens.PRAISE) return 'praise'
	if (action === ActionNameMapScreens.FEEDBACK) return 'feedback'
	if (action === ActionNameMapScreens.DEBRIEF) return 'start_new_learn_debrief'
	return ActionNameMapScreens.DEFAULT
}
