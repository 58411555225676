import { makeStyles } from "@material-ui/core"
import { Theme } from "@mui/material"

type Styles = {
    isRtlDirection: boolean
}
export default makeStyles<Theme, Styles>(() => ({
    container: {
        height: 'fit-content',
        borderRadius: '8px',
        minWidth: '350px',
        width: 'fit-content',
        margin: '0 auto 0 auto',
        display: 'flex',
        flexDirection: 'column',
        direction: ({ isRtlDirection }) => (isRtlDirection ? 'rtl' : 'ltr')
    },
    closeBtn: {
        display: 'flex',
        float: 'right',
        width: 25,
        height: 25,
        cursor: 'pointer',
        border: '0.1px solid grey',
        borderRadius: '50%',
    },
    analysis: {
        maxWidth: '350px',
        boxShadow: '0px 4px 6px 0 lightgrey',
        padding: '6px',
        backgroundColor: '#c2cdce42',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Sora, sans-serif',
        fontSize: '15px',
        margin: '1.5%',
        '& >div': {
            width: '100%',
            hight: '25px',
            borderBottom: '0.8px solid lightgrey',
        }
    },
    analysisAnswer: {
        width: '100%',
        height: 'fit-content',
        textAlign: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
    },
    inputField: {
        height: '100%',
        backgroundColor: '#c2cdce42',
        border: 'none',
        outline: 'none',
        fontSize: '15px',
    }
}))