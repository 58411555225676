import { FC } from 'react'
import UserAvatar from 'components/common/Avatar'
import { LetsBeginModalProp } from 'components/common/Modal/Wrapper'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../../redux/user/user-selector'
import IUser from 'interface/user/user-interface'
import { License } from 'enums/License/License.enum'
import { Langs } from '../../../../enums/app/index'

type ModalContentInfoPropType = {
	companyLanguage?: Langs
	onSave?: (employees: IUser[]) => void
	onError?: (error: string) => void
	closeModal: () => void
} & LetsBeginModalProp

const useStyles = makeStyles({
	modalContentInfo: {
		position: 'relative',
		bottom: '38px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatarStyle: {
		width: '90px',
		height: '90px',
	},
})
const ModalContentInfo: FC<ModalContentInfoPropType> = ({
	company_id,
	onSave,
	onError,
	remainingLicense,
	closeModal,
	ModalNode,
	companyLanguage,
}) => {
	const userInfo = useSelector(userSelector)
	const classes = useStyles()
	return (
		<div className={classes.modalContentInfo}>
			<UserAvatar className={classes.avatarStyle} userInfo={userInfo} />
			<ModalNode
				companyLanguage={companyLanguage!}
				remainingLicense={remainingLicense}
				company_id={company_id}
				onError={onError}
				onSave={onSave}
				onClick={closeModal}
			/>
		</div>
	)
}

export default ModalContentInfo
