import { FC, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useStyles, RenderChatCard } from './RenderChatCard'
import { ChatAnswer } from './index'
import { Options, PHASE, ChatPresets } from './InitialChatPreset'
import ConclusionCard from './conclusionCard'

type ChatBotCardProps = {
	summary?: any
	userImg?: string
	answer: ChatAnswer
	isLastAnswer?: boolean
	handleScrollBottom?: () => void
	presetData: any[]
	currentPhase: PHASE
	handlePresetClick: (preset: string, options?: Options) => void
}

export const ChatBotCardWrapper: FC<ChatBotCardProps> = props => {
	const {
		summary,
		answer,
		presetData,
		handlePresetClick,
		currentPhase,
		userImg,
		isLastAnswer,
		handleScrollBottom,
	} = props

	const [isBotTyping, setisBotTyping] = useState(false)

	useLayoutEffect(() => {
		const randomSeconds = 1500
		setisBotTyping(true)
		const timer = setTimeout(() => {
			setisBotTyping(false)
			handleScrollBottom && handleScrollBottom()
		}, randomSeconds)

		return () => clearTimeout(timer)
	}, [isLastAnswer])

	const { t } = useTranslation()
	const classes = useStyles({})

	const { chatNextQuestion, userAnswer, showConclusionCard } = answer
	const { what_was_done, assumption, Whats_was_you_need } = summary

	const showPreset =
		isLastAnswer && !isBotTyping && presetData && !!presetData.length
	return (
		<>
			<div className={classes.chatBotCardWrapper}>
				<RenderChatCard userImg={userImg} text={userAnswer} />
				{isLastAnswer && isBotTyping ? (
					<div>{t('typing')}</div>
				) : (
					<>
						{showConclusionCard && (
							<ConclusionCard
								what_was_done={what_was_done}
								assumption={assumption}
								Whats_was_you_need={Whats_was_you_need}
							/>
						)}

						{chatNextQuestion && (
							<RenderChatCard isChatQuestion text={chatNextQuestion} />
						)}
					</>
				)}
			</div>
			{showPreset ? (
				<ChatPresets
					dataArr={presetData}
					currentPhase={currentPhase}
					handlePresetClick={handlePresetClick}
				/>
			) : null}
		</>
	)
}
