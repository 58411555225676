import { createSlice } from '@reduxjs/toolkit'
import { INotificationsState } from 'interface/notifications/notifications-interface'
import { getNotifications, getNotificationsV2, load_more_notifications, setNotificationOld } from './notifications-action'
import { INotification } from '../../interface/notifications/notifications-interface'

const initialState: INotificationsState = {
	currentRequestId: undefined,
	loading: false,
	error: null,
	oldNotifications: [],
	newNotifications: [],
	allNotificationsLength: 0,
	hasMoreNotifications: true
}

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		setNotification(state, action) {
			const notification: INotification = action.payload

			return {
				...state,
				newNotifications: [notification, ...state.newNotifications],
			}
		},
	},

	extraReducers: builder => {
		builder.addCase(getNotificationsV2.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
				state.currentRequestId = action.meta.requestId
			}
		})
		builder.addCase(getNotificationsV2.fulfilled, (state, action) => {
			const {
				status,
				oldNotifications,
				newNotifications,
				allNotificationsLength,
			} = action.payload
			if (
				status === 'success' &&
				oldNotifications &&
				newNotifications &&
				allNotificationsLength
			) {
				state.newNotifications = newNotifications
				state.oldNotifications = oldNotifications
				state.allNotificationsLength = allNotificationsLength
				state.hasMoreNotifications = true
			} else {
				console.log('getNotifications status: ', status)
				state.hasMoreNotifications = false
			}
			console.log(action.payload)
			state.loading = false
		})
		builder.addCase(getNotificationsV2.rejected, (state, action) => {
			const { requestId } = action.meta
			if (state.loading && state.currentRequestId === requestId) {
				state.loading = false
				state.error = action.error.message
				state.currentRequestId = undefined
			}
		})
		// builder.addCase(getNotifications.pending, (state, action) => {
		// 	if (!state.loading) {
		// 		state.loading = true
		// 		state.currentRequestId = action.meta.requestId
		// 	}
		// })
		// builder.addCase(getNotifications.fulfilled, (state, action) => {
		// 	const {
		// 		status,
		// 		oldNotifications,
		// 		newNotifications,
		// 		allNotificationsLength,
		// 	} = action.payload
		// 	if (
		// 		status === 'success' &&
		// 		oldNotifications &&
		// 		newNotifications &&
		// 		allNotificationsLength
		// 	) {
		// 		state.newNotifications = newNotifications
		// 		state.oldNotifications = oldNotifications
		// 		state.allNotificationsLength = allNotificationsLength
		// 		state.hasMoreNotifications = true
		// 	} else {
		// 		console.log('getNotifications status: ', status)
		// 		state.hasMoreNotifications = false
		// 	}
		// 	console.log(action.payload)
		// 	state.loading = false
		// })
		// builder.addCase(getNotifications.rejected, (state, action) => {
		// 	const { requestId } = action.meta
		// 	if (state.loading && state.currentRequestId === requestId) {
		// 		state.loading = false
		// 		state.error = action.error.message
		// 		state.currentRequestId = undefined
		// 	}
		// })
		builder.addCase(setNotificationOld.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
				state.currentRequestId = action.meta.requestId
			}
		})
		builder.addCase(setNotificationOld.fulfilled, (state, action) => {
			const { status, oldNotifications, newNotifications } = action.payload
			if (status === 'success' && oldNotifications && newNotifications) {
				state.newNotifications = newNotifications
				state.oldNotifications = oldNotifications
			} else {
				console.log('setNotificationOld status: ', status)
			}
			state.loading = false
		})
		builder.addCase(setNotificationOld.rejected, (state, action) => {
			const { requestId } = action.meta
			if (state.loading && state.currentRequestId === requestId) {
				state.loading = false
				state.error = action.error.message
				state.currentRequestId = undefined
			}
		})
		builder.addCase(load_more_notifications.fulfilled, (state, action) => {
			const { newNotidications, oldNotifications } = action.payload[0]
			if (!oldNotifications?.length) state.hasMoreNotifications = false
			if (newNotidications?.length) state.newNotifications = state.newNotifications.concat(newNotidications)
			state.oldNotifications = state.oldNotifications.concat(oldNotifications)
		})
		builder.addCase(load_more_notifications.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
				state.currentRequestId = action.meta.requestId
			}
		})
		builder.addCase(load_more_notifications.rejected, (state, action) => {
			const { requestId } = action.meta
			if (state.loading && state.currentRequestId === requestId) {
				state.loading = false
				state.error = action.error.message
				state.currentRequestId = undefined
			}
		})
	},
})

export default notificationsSlice.reducer
export const { setNotification } = notificationsSlice.actions
