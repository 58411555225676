import { FC, useState, useCallback, useRef } from 'react'
import ActionsButton from '../ActionButtons'
import { initialAnswer } from '../ActionButtons/index'
import { useStyles } from './useStyles'
import { AnswersGroupProp } from 'interface/bot/index'
import { replaceString } from '../../../utils/replaceNameBot'
import { useSelector } from 'react-redux'
import { getBotAnswers } from 'redux/bot/bot-selector'
import { userSelector } from 'redux/user/user-selector'
import ScrollableContent from 'components/common/ScrollableContent/ScrollableContent'

const MultipleChoice: FC<AnswersGroupProp> = ({
	answers,
	previousQuestion,
	setQuestion,
	nextQuestion,
	subtitle,
	isPraise,
	type,
}) => {
	const classes = useStyles()
	const [chosenIds, setChosenIds] = useState([''])
	const [answer, setAnswer] = useState({
		...initialAnswer,
		previousQuestion: previousQuestion!,
		nextQuestion: nextQuestion!,
	})
	const _answers = useSelector(getBotAnswers)
	const userSelecor = useSelector(userSelector)

	const recipientName = _answers.answers[0] ? _answers.answers[0].answer : ''
	const senderName = userSelecor?.personalInfo?.firstName
		? userSelecor.personalInfo.firstName
		: ''

	const handleClick = useCallback(
		(id: string, nextQuestion: string) => {
			const choosedIds = chosenIds.includes(id)
				? chosenIds.filter(exictedId => exictedId !== id)
				: [...chosenIds, id]

			setChosenIds(choosedIds)

			setAnswer(previousAnswer => ({
				...previousAnswer,
				nextQuestion: nextQuestion!,
				multiChoiceAnswer: [...choosedIds],
			}))
		},
		[setAnswer, setChosenIds, nextQuestion, chosenIds]
	)
	const content = (
		<div className={classes.singleChoiceWrapper}>
			{answers?.length &&
				answers.map(({ title, nextQuestion }, id) => {
					const answerTitle = replaceString(title, {
						recipientPlaceholder: recipientName,
						senderPlaceholder: senderName,
					})

					const isChosed = chosenIds.includes(String(id))
					const style = [
						classes.answerCard,
						isChosed ? classes.isChosed : classes.regular,
					]
						.filter(Boolean)
						.join(' ')

					return (
						<div
							className={style}
							onClick={() => handleClick(String(id), nextQuestion)}
						>
							{answerTitle}
						</div>
					)
				})}
		</div>
	)

	return (
		<>
			<div>{subtitle}</div>
			<ScrollableContent content={content} />
			<ActionsButton
				isEnabled={!!answer.multiChoiceAnswer?.length}
				removeIds={() => setChosenIds([])}
				answer={answer}
				setQuestion={setQuestion}
				isPraise={isPraise}
				type={type}
				showPreviousButton
				previousQuestionId={previousQuestion}
			/>
		</>
	)
}

export default MultipleChoice
