import { Response } from 'interface/api/response-interface'
import { ContactUsDto } from 'interface/contactUs/contact-us-interface'
import { POST } from './api-requests'
import { URLS } from './api-urls'

export const sendContactUsForm = async (
	contactData: ContactUsDto
): Promise<Response> => {
	const { data, status } = await POST(`${URLS.CONTACT_US}`, {
		...contactData,
	})
	return { data, status }
}
