import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './styled'

import ModalWrapper from 'components/common/Modal/Wrapper/index'
import UploadFile from '../Builder/UploadEmployees'

import { Langs } from 'enums/app/index'
import { ILicenses } from 'interface/Licenses'
import { ModalNodeType } from 'screens/Tasks/index'

const UploadEmployeeContentModalNode: FC<ModalNodeType> = ({
	remainingLicense,
	onClick,
	onError,
	company_id,
	companyLanguage,
}) => {
	const classes = useStyles()
	const { t } = useTranslation()

	return (
		<>
			<h2 id='transition-modal-title' className={classes.modalTitle}>
				{t('upload_file')}
			</h2>
			<UploadFile
				companyLanguage={companyLanguage}
				remainingLicense={remainingLicense}
				company_id={company_id}
				onError={onError}
				closeModal={onClick}
			/>
			<button onClick={onClick} className={classes.closeButton}>
				{t('cancel')}
			</button>
		</>
	)
}

type RenderTitlePropsType = {
	remainingLicense?: ILicenses
	company_id?: string
	onError?: (error: string) => void
	title: string
	subTitile: string
	additionalText?: string
	hasEmployees?: boolean
	isUploadingFile?: boolean
	companyLanguage?: Langs
}
const RenderContextTitleAndSubTitle: FC<RenderTitlePropsType> = ({
	remainingLicense,
	company_id,
	onError,
	title,
	subTitile,
	additionalText,
	hasEmployees,
	isUploadingFile,
	companyLanguage,
}) => {
	const styles = useStyles()
	const { t } = useTranslation()

	return (
		<div className={styles.container}>

			<div className={styles.title}> {t(title)}</div>

			{!hasEmployees && <div className={styles.subTitle}>{t(subTitile)}</div>}

			{!isUploadingFile && additionalText && (
				<div className={styles.additionalText}>
					<ModalWrapper
						companyLanguage={companyLanguage}
						remainingLicense={remainingLicense}
						onError={onError}
						company_id={company_id}
						openModalTriggerText={t(additionalText)}
						ModalNode={UploadEmployeeContentModalNode}
					/>
				</div>
			)}

		</div>
	)
}
export default RenderContextTitleAndSubTitle