import {
	FC,
	useState,
	useCallback,
	ChangeEvent,
	useContext,
	useRef,
	useEffect,
} from 'react'
import ActionButtons from 'components/Bot/ActionButtons'
import { UserAnswer, initialAnswer } from '../ActionButtons/index'
import { backgroundIconsMap } from '../../../utils/iconsMapper'
import { AnswersGroupProp } from '../../../interface/bot/index'
import { useStyles } from './useStyles'
import { AppDirectionContext } from 'context/languages-context'
import { useDispatch, useSelector } from 'react-redux'
import { brieflySelector } from 'redux/user/user-selector'
import { getBotAnswers } from 'redux/bot/bot-selector'
import SpeechRecognitionComponent from 'components/SpeechRecognitionComponent'
import { useTranslation } from 'react-i18next'

const TextArea: FC<AnswersGroupProp> = ({
	subtitle,
	setQuestion,
	nextQuestion,
	previousQuestion,
	type,
	isPraise,
	answers,
}) => {
	const [userAnswer, setAnswer] = useState<UserAnswer>({
		...initialAnswer,
		previousQuestion,
	})
	const cursor = useRef<HTMLTextAreaElement>(null)

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const { t } = useTranslation()
	const answer: UserAnswer = {
		id: '',
		answer: null,
		nextQuestion: nextQuestion!,
		previousQuestion,
		type,
		// outputType: '',
	}

	useEffect(() => {
		//place the cursor at the end
		if (!cursor.current) return

		cursor.current.selectionStart = cursor.current.value.length
	}, [cursor])

	/**
	 * This function has to handle change event.
	 * only when the text is changed we save it in local state.
	 */
	const handleTextChange = useCallback(
		({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
			// debounceSearch(dispatch(userActions.brieflyInput(value)))
			updateAnswer(value)
		},
		[]
	)

	const updateAnswer = (text: string) => {
		console.log({ text })
		setAnswer(prevAnswer => ({
			...prevAnswer,
			...answer,
			answer: text,
			outputType: answers?.length ? answers[0].outputType : 0,
		}))
	}
	const _answer = userAnswer.answer?.trim()

	return (
		<>
			<div className={classes.textAreaContainer}>
				{/* <SpeechRecognitionComponent
					handleUpdateTextChange={updateAnswer}
					defaultTranscript={userAnswer.answer!}
				> */}
				<textarea
					autoFocus
					ref={cursor}
					value={userAnswer.answer!}
					onChange={handleTextChange}
					placeholder={subtitle} // + ' ' + t('or_record')
					className={classes.textArea}
					rows={5}
					cols={33}
					maxLength={300}
				></textarea>
				{/* </SpeechRecognitionComponent> */}
				<img src={backgroundIconsMap['noteBookBackgroundIcon']} alt='notebook' />
			</div>
			<ActionButtons
				type={type}
				showPreviousButton
				isPraise={isPraise}
				previousQuestionId={previousQuestion}
				isEnabled={!!_answer?.length}
				setQuestion={setQuestion}
				answer={{ ...userAnswer, answer: _answer }}
				showNextStepButton
			/>
		</>
	)
}

export default TextArea
