import { useContext, FC, useEffect } from 'react'

import { Switch, useLocation } from 'react-router-dom'
import { Grid, Theme, makeStyles } from '@material-ui/core'

import Wrapper from 'components/common/ScreenWrapper/index'
import NavigationBar from 'components/common/NavigationBar'
import { AppDirectionContext } from 'context/languages-context'

import PrivateRoute from 'utils/PrivateRoute'
import dashboardRoutes from './utils'

const Dashboard: FC = () => {
	const classes = useStyles()
	const { isRtlDirection } = useContext(AppDirectionContext)
	const location = useLocation()

	useEffect(() => {
		document.getElementById('root')?.scrollTo(0, 0)
	}, [location])

	return (
		<Wrapper>
			<NavigationBar isRtl={isRtlDirection} />
			<Grid item xs={12} sm={12} className={classes.container}>
				<Switch>
					{dashboardRoutes.map(route => (
						<PrivateRoute {...route} />
					))}
				</Switch>
			</Grid>
		</Wrapper>
	)
}

export default Dashboard

const useStyles = makeStyles<Theme>({
	container: {
		margin: '1rem auto 0',
		flexWrap: 'wrap',
	},
})
