import { createAsyncThunk } from '@reduxjs/toolkit'
import {
	getCompanyPraises,
	updatePraiseReactions,
	updateOuputExpansions,
	updateReplayMessage,
} from 'api/company'
import {
	fetchUserPraiseDrafts,
	getQuestionPraise,
	sendOutputPraise,
	fetchUserPraises,
	fetchWallofPraiseTemplates,
	loadMorePraises,
	praiseReviewToggle,
	fetchSentPraisesByIds,
	fetchReceivedPraisesByIds,
} from 'api/praise'
import { Req } from 'enums/req/req.enum'
import { FetchUserFeedbackPraisesPayload } from 'interface/api/get-payload.types'
import { IStoreRootState } from 'interface/redux'
import { splitAlgorithm } from 'utils/splitPraisesAndFeedback'
import { togglePraiseOutputDraft, deletePraise } from '../../api/praise'
import { ToggleOutputDraft } from '../../components/Bot/useHooks/index'
import { PraiseOutput, ReviewOutputPayload } from '../../interface/bot/index'
import { Events } from '../../screens/Main/index'
import {
	PraiseCreateAction,
	FetchQuestion,
} from '../../interface/api/get-payload.types'
import { ExpansionPayload } from 'interface/bot/index'
import { queryStringBuilder } from '../../utils/QueryStringBuilder'

export const fetch_company_praises_templates = createAsyncThunk(
	'praise/fetchWallofPraiseTemplates',
	async (_, { rejectWithValue }) => {
		try {
			const { data, status } = await fetchWallofPraiseTemplates()
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
	// {
	// 	condition: (_, { getState }) => {
	// 		const { praise } = getState() as IStoreRootState
	// 		return praise.loading === false
	// 	},
	// }
)
export const fetch_received_company_praises_byIds = createAsyncThunk(
	'praise/fetchReceivedPraisesByIds',
	async (payload: { ids: Array<string> }, { rejectWithValue, getState }) => {
		try {
			const { user } = getState() as IStoreRootState
			const query = queryStringBuilder(payload.ids)
			const param =
				query + `company_id=${user.user.company_id}&user_id=${user.user._id}`
			const data = await fetchReceivedPraisesByIds(param)
			// if (status === Req.failed) throw new Error(data)
			return { data: data }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
	// {
	// 	condition: (_, { getState }) => {
	// 		const { praise } = getState() as IStoreRootState
	// 		return praise.loading === false
	// 	},
	// }
)

export const fetch_sent_company_praises_byIds = createAsyncThunk(
	'praise/fetchSentPraisesByIds',
	async (payload: { ids: Array<string> }, { rejectWithValue, getState }) => {
		try {
			const { user } = getState() as IStoreRootState
			const query = queryStringBuilder(payload.ids)
			const param =
				query + `company_id=${user.user.company_id}&user_id=${user.user._id}`
			const data = await fetchSentPraisesByIds(param)
			// if (status === Req.failed) throw new Error(data)
			return { data: data }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
	// {
	// 	condition: (_, { getState }) => {
	// 		const { praise } = getState() as IStoreRootState
	// 		return praise.loading === false
	// 	},
	// }
)

export const fetch_company_praises = createAsyncThunk(
	'praise/getCompanyPraises',
	async (
		payload: { companyId: string; pageNumber: number },
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await getCompanyPraises(payload)
			if (status === Req.failed) throw new Error(data)
			return { data: data[0].companyPraises, status }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
	// {
	// 	condition: (_, { getState }) => {
	// 		const { praise } = getState() as IStoreRootState
	// 		return praise.loading === false
	// 	},
	// }
)

export const delete_drafted_praise = createAsyncThunk(
	'praise/fetchUserPraiseDrafts',
	async (payload: Record<string, string>, { rejectWithValue }) => {
		try {
			const { data, status } = await deletePraise(payload)
			if (status === Req.failed) throw new Error(data)
		} catch (err) {
			return rejectWithValue(err)
		}
	}
	// {
	// 	condition: (_, { getState }) => {
	// 		const { praise } = getState() as IStoreRootState
	// 		return praise.loading === false
	// 	},
	// }
)

export const fetch_praises_drafts = createAsyncThunk(
	'praise/fetchUserPraiseDrafts',
	async (payload: FetchUserFeedbackPraisesPayload, { rejectWithValue }) => {
		try {
			const { data, status } = await fetchUserPraiseDrafts(payload)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
	// {
	// 	condition: (_, { getState }) => {
	// 		const { praise } = getState() as IStoreRootState
	// 		return praise.loading === false
	// 	},
	// }
)

export const get_praises = createAsyncThunk(
	'praise/fetchUserPraises',
	async (payload: FetchUserFeedbackPraisesPayload, { rejectWithValue }) => {
		try {
			const { data, status } = await fetchUserPraises(payload)
			if (status === Req.failed) throw new Error(data)
			const praises = splitAlgorithm(data, payload.user_id)
			console.log({ praises })
			return praises
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const load_more_praises = createAsyncThunk(
	'praise/loadMorePraises',
	async (payload: FetchUserFeedbackPraisesPayload, { rejectWithValue }) => {
		try {
			const { data, status } = await loadMorePraises(payload)
			if (status === Req.failed) throw new Error(data)
			const praises = splitAlgorithm(data, payload.user_id)

			return { praises, payload }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const toggle_praise_draft = createAsyncThunk(
	'praise/updatePraiseOutput',
	async (praise: ToggleOutputDraft, { rejectWithValue }) => {
		try {
			const { status, data } = await togglePraiseOutputDraft({ ...praise })
			if (status === Req.failed) throw new Error(data)
			// return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
	// {
	// 	condition: (_, { getState }) => {
	// 		const { praise } = getState() as IStoreRootState
	// 		return praise.loading === false
	// 	},
	// }
)
export const create_praise = createAsyncThunk(
	'praise/createPraise',
	async ({ praise, company_id, socket }: any, { rejectWithValue }) => {
		try {
			const { status, data } = await sendOutputPraise(praise, company_id)

			if (status === Req.failed) {
				throw new Error(data)
			}
			socket?.emit(Events.CREAATE_PRIVATE_EVENT) //NOTE: wrong code. when removing the line app crashes!

			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
	// {
	// 	condition: (_, { getState }) => {
	// 		const { praise } = getState() as IStoreRootState
	// 		return praise.loading === false
	// 	},
	// }
)
export const get_question_praise = createAsyncThunk(
	'praise/getPraise',
	async ({ questionId, language }: FetchQuestion, { rejectWithValue }) => {
		try {
			const { data, status } = await getQuestionPraise(questionId, language)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const get_additional_praise_question = createAsyncThunk(
	'praise/getAdditionalPraiseQuestion',
	async ({ questionId, language }: FetchQuestion, { rejectWithValue }) => {
		try {
			const { data, status } = await getQuestionPraise(questionId, language)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const update_praise_reactions = createAsyncThunk(
	'praise/updatePraisereactions',
	async (
		payload: {
			company_id: string
			praise_id: string
			reactions: { like: string[]; love: string[]; clap: string[] }
		},
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await updatePraiseReactions(
				payload.company_id,
				payload.praise_id,
				payload.reactions
			)
			if (status === Req.failed) throw new Error(data)
			return { praise_id: payload.praise_id, reactions: payload.reactions }
		} catch (err) {
			return rejectWithValue(err)
		}
	},
	{
		condition: (_, { getState }) => {
			const { praise } = getState() as IStoreRootState
			return praise.loading === false
		},
	}
)

export const review_praise_work_meet = createAsyncThunk(
	'praise/reviewPraiseAtWorkMeet',
	async (payload: ReviewOutputPayload, { rejectWithValue }) => {
		try {
			const { data, status } = await praiseReviewToggle(payload)
			if (status === Req.failed) throw data.message
			return { data, output_id: payload.output_id }
		} catch (err) {
			rejectWithValue(err)
		}
	}
)
export const update_praise_expansions = createAsyncThunk(
	'praise/updateExpansions',
	async (payload: ExpansionPayload, { rejectWithValue }) => {
		try {
			const { data, status } = await updateOuputExpansions(payload)
			if (status === Req.failed) throw data.message

			return { data, payload }
		} catch (err) {
			rejectWithValue(err)
		}
	}
)
export const update_replay_message = createAsyncThunk(
	'praise/updateReplayMessage',
	async (
		payload: { company_id: string; output_id: string; replayMessage: string },
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await updateReplayMessage(payload)

			if (status == Req.failed) throw data.message

			return { data: payload, status }
		} catch (err) {
			rejectWithValue(err)
		}
	}
)
