import { FC, useContext } from 'react'
import { useSelector } from 'react-redux'

import UserAvatar from 'components/common/Avatar'

import useStyles from '../PersonalProfileMenu/useStyles'
import { Icon } from '../index'
import { userSelector } from 'redux/user/user-selector'
import { AppDirectionContext } from '../../../../context/languages-context'
import cameraIcon from 'assets/cameraIcon.svg'
import { UIConfigContext } from 'context/UIConfig-context'

type Props = {
	previewImage?: string
	handleUplingFile: (image: any) => void
}

const UploadAvatar: FC<Props> = props => {
	const { previewImage, handleUplingFile } = props
	const user = useSelector(userSelector)
	const { isRtlDirection } = useContext(AppDirectionContext)
	const { disableTakePhoto } = useContext(UIConfigContext)
	const classes = useStyles({ isRtlDirection })

	return (
		<div className={classes.avatarContainer}>
			<UserAvatar
				className={classes.userAvatar}
				userInfo={user}
				previewImage={previewImage}
			/>
			{!disableTakePhoto && (
				<Icon
					regularIcon
					uploadFileClick={handleUplingFile}
					icon={cameraIcon}
					alt='camera icon'
					isUploadFile
					style={classes.cameraIcon}
				/>
			)}
		</div>
	)
}

export default UploadAvatar
