import makeStyles from '@material-ui/core/styles/makeStyles'
import { IThemeOptions } from '../../theme/index'

export const useStyles = makeStyles<
	IThemeOptions,
	{ isEndSelfAnalisysDisabled?: boolean; isRtlDirection?: boolean }
>(({ palette, breakpoints }) => ({
	chatWrapper: {
		position: 'relative',

		height: '80vh',
		display: 'flex',
		flexDirection: 'column',

		direction: ({ isRtlDirection }) => (isRtlDirection ? 'rtl' : 'ltr'),

		'& > button': {
			border: 'none',
			cursor: 'pointer',
			height: '100%',

			width: '100%',
			maxWidth: '224px',
			maxHeight: '54px',
			alignSelf: 'center',
			borderRadius: '14px',
			color: 'white',
			backgroundColor: palette.debrief.main,
		},

		[breakpoints?.down!('sm')!]: {
			padding: '1rem 0.55rem',
			height: '95vh',
		},
	},

	textInputWrapper: {
		position: 'relative',
		width: 'auto',
		display: 'flex',
		alignItems: 'center',

		'& > input': {
			width: '100%',
			height: '100%',

			padding: '4% 32px',
			fontFamily: 'Sora sans-serif',
			fontSize: 17,
			paddingInlineEnd: '73px',

			outline: 'none',
			border: '1px solid rgba(49, 49, 49, 0.152716)',
			borderRadius: '10px',
			boxSizing: 'border-box',
		},

		'& > input:disabled': {
			border: '1px solid #0000000f',
		},
		'& > input:disabled::-webkit-input-placeholder': {
			color: '#cfcfcf',
		},
		'& > div': {
			fontWeight: 600,

			color: '#CFCFCF',
			cursor: 'pointer',

			position: 'absolute',
			// left: ({ isRtlDirection }) => (!isRtlDirection ? '' : '-23px'),
			left: ({ isRtlDirection }) => (isRtlDirection ? '23px' : ''),
			right: ({ isRtlDirection }) => (isRtlDirection ? '' : '23px'),
		},
	},
	buttonsContainer: {
		fontStyle: 'normal',
		fontWeight: 'normal',

		cursor: 'pointer',
		// width: 'fit-content',
		color: '#5B4EB5',

		display: 'flex',
		justifyContent: 'space-between',

		marginTop: '1%',

		'& > div': {
			fontSize: '17px',
		},
	},

	endSelfAnalisys: {
		cursor: 'pointer',
		textAlign: 'center',
		color: ({ isEndSelfAnalisysDisabled }) =>
			isEndSelfAnalisysDisabled ? '#CFCFCF' : '#5B4EB5',
	},
	chatPresetsContainer: {
		'& > div:nth-child(1)': {
			margin: '3% 0',
		},
	},
	presetAnswersContainer: {
		height: 'auto',

		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		gap: '1em',
		justifyContent: 'center',
		// backgroundColor: 'red',

		'& > div': {
			width: '100%',
			maxWidth: '22%',

			lineBreak: 'auto',
			cursor: 'pointer',

			// margin: '0 3% 3% 0',
			padding: '21px 21px 21px  14px',
			// backgroundColor: 'black',

			boxShadow: '2px 2px 5px 0px rgba(0, 0, 0, 0.2)',
			border: '1px solid rgba(216, 216, 216, 1)',
			// filter: 'drop-shadow(0 0 30px #333)',
			borderRadius: '10px',
		},

		'& > div:last-child': {
			color: 'rgba(91, 78, 181, 1)',
		},
		[breakpoints?.down!('xs')!]: {
			fontSize: '75%'
		},
		[breakpoints?.down!('sm')!]: {
			fontSize: '80%'
		},
	},
}))