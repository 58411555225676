import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import {
	Debrief,
	DebriefEvent,
	SummaryDTO,
} from 'interface/debrief/debrief-interface'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { create_matchpoint_summary } from 'redux/debrief/debrief-actions'
import { userSelector } from 'redux/user/user-selector'
import { checkIfUserFinishedBot } from 'utils/checkIfUserFinishedBot'
import { AnswerState, ChatAnswer } from './index'
import { PHASE, Options } from './InitialChatPreset'
import {
	initialAnswer,
	assumption,
	assumptionNeed,
	what_is_the_lesson,
	more_lessons,
} from './utils'
import { debriefOnProccesSelector } from 'redux/debrief/debrief-selector'

const MAX_ANALISYS_FLOW = 3

interface Props {
	isParticipant?: boolean
	matchPoints: DebriefEvent[]
	nextStep?: DebriefingSteps
	setParticipantStep?: (step: DebriefingSteps) => void
	setData?: (data: Partial<Debrief>, step?: DebriefingSteps | undefined) => void
}
const useChatLogic = (props: Props) => {
	const { isParticipant, matchPoints, nextStep, setParticipantStep, setData } =
		props

	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [isInputDisabled, setIsInputDisabled] = useState(true)
	const [currentMatchPoint, setCurrentMatchPoint] = useState(0)
	const [chatNextQuestion, setChatNextQuestion] = useState(1)
	const [answerState, setAnswer] =
		useState<AnswerState<string, ChatAnswer>>(initialAnswer)
	const debrief = useSelector(debriefOnProccesSelector)

	const { company_id, _id } = useSelector(userSelector)

	const { isDone, currentAnswer, answers, currentPhase, summary } = answerState

	useEffect(() => {
		matchPoints.forEach(point => {
			if (point.summaries?.length) {
				point.summaries.forEach(s => {
					if (s.lessonsOwner_id === _id) {
						setCurrentMatchPoint(prev => prev + 1)
						return
					}
				})
			}
		})
	}, [])

	useEffect(() => {
		switch (currentPhase) {
			case PHASE.ACTIONS:
				answers.length && updatePhase(PHASE.ANALISYS)
				break
			case PHASE.ANALISYS:
				isDone && answers.length && updatePhase(PHASE.ASSUMPTION)
				break
			case PHASE.ASSUMPTION:
				isDone && answers.length && updatePhase(PHASE.SUMMARY)
				break
			case PHASE.ASSUMPTION_NEED:
				isDone && answers.length && updatePhase(PHASE.SUMMARY)
				break
			default:
				break
		}
	}, [answers, isDone])

	useEffect(() => {
		const isAnalysisProccessFinished = checkIfUserFinishedBot(
			_id,
			matchPoints!
		)

		if (!isAnalysisProccessFinished) {
			return
		}

		sendToProccessOutput()
	}, [currentMatchPoint, matchPoints])

	const updatePhase = (currentPhase: PHASE) => {
		setAnswer(({ ...rest }) => ({
			...rest,
			currentPhase,
		}))
	}

	const updateSummery = (key: string, value: string) => {
		setAnswer(({ summary, ...rest }) => ({
			...rest,
			summary: { ...summary, [key]: value },
		}))
	}

	const updateSummeryLesson = (lesson: string) => {
		setAnswer(({ summary, ...rest }) => ({
			...rest,
			summary: { ...summary, lessons: [...summary.lessons, lesson] },
		}))
	}

	const updateIsDone = (isDone: boolean) => {
		setAnswer(({ ...rest }) => ({
			...rest,
			isDone,
		}))
	}

	const resetState = () => {
		setAnswer(initialAnswer)
		setChatNextQuestion(1)
		setIsInputDisabled(true)
	}

	const handleIDontKnowWhyClick = () => {
		const shouldGoNextStep = Boolean(answers.length) && !isDone
		if (!shouldGoNextStep) return

		let showConclusionCard = false
		updateChatHistoryAnswers(t('Done'), t(assumption), showConclusionCard)
		updateIsDone(true)
	}

	const getChatNextQuestion = (
		userAnswer: string,
		botNextQuestion?: string
	): {
		userAnswer: string
		botNextQuestion: string
	} => {
		const answer = {
			userAnswer: userAnswer,
			botNextQuestion: '',
		}
		if (botNextQuestion) return { ...answer, botNextQuestion }

		if (PHASE.ANALISYS === currentPhase || currentPhase === PHASE.ACTIONS) {
			const nextChatWhy = chatNextQuestion >= 3 ? 2 : chatNextQuestion + 1

			if (chatNextQuestion === MAX_ANALISYS_FLOW) {
				return { ...answer, botNextQuestion: '' }
			}

			setChatNextQuestion(nextChatWhy)

			return { ...answer, botNextQuestion: t(`chat_why_${chatNextQuestion}`) }
		}

		return { ...answer, botNextQuestion: botNextQuestion! }
	}

	const updateAnswers = (
		chat: {
			userAnswer: string
			botNextQuestion: string
		},
		showConclusionCard: boolean = false
	) => {
		const { userAnswer, botNextQuestion } = chat
		const answerPayload = {
			chatNextQuestion: botNextQuestion,
			isDone,
			phase: currentPhase,
			userAnswer: userAnswer,
			showConclusionCard,
		}
		setAnswer(({ answers, ...rest }) => ({
			...rest,
			currentAnswer: '',
			answers: [...answers, answerPayload],
		}))
	}
	//takes user answer and update chat history
	const updateChatHistoryAnswers = (
		userAnswer: string,
		qustion?: string,
		showConclusionCard?: boolean
	) => {
		if (chatNextQuestion === MAX_ANALISYS_FLOW) {
			const chat1 = getChatNextQuestion(userAnswer, '')
			const chat2 = getChatNextQuestion(t('Done'), t(assumption))

			updateIsDone(true)
			updateAnswers(chat1, false)
			updateAnswers(chat2, false)
			setChatNextQuestion(1)
			return
		}
		const chat = getChatNextQuestion(userAnswer, qustion)
		updateAnswers(chat, showConclusionCard)
	}

	const handleInputChange = ({
		target: { value },
	}: React.ChangeEvent<HTMLInputElement>) =>
		setAnswer(prevAnswer => ({ ...prevAnswer, currentAnswer: value }))

	//click event handler for chat input button
	const handleChatButtonClick = () => {
		let chatNextQuestion = ''
		let showConclusionCard = false
		if (isDone) {
			chatNextQuestion = assumptionNeed
		}

		if (!isDone && currentPhase === PHASE.ACTIONS) {
			updateSummery('what_was_done', currentAnswer)
		}
		console.log({ isDone, currentPhase })

		if (isDone && currentPhase === PHASE.ASSUMPTION) {
			updateSummery('assumption', currentAnswer)
			showConclusionCard = true
			chatNextQuestion = what_is_the_lesson
		}

		if (isDone && currentPhase === PHASE.SUMMARY) {
			updateSummeryLesson(currentAnswer)
			chatNextQuestion = more_lessons
		}

		updateChatHistoryAnswers(
			currentAnswer,
			t(chatNextQuestion),
			showConclusionCard
		)
	}

	const handlePresetClick = (preset: string, options?: Options) => {
		const { enableChatTextInput } = options || {}
		const chatNextQuestion = isDone ? t('what_is_the_lesson') : ''

		if (enableChatTextInput) {
			// enable the text input if user has a different answer

			setIsInputDisabled(false)
			return
		}
		if (!isDone) updateSummery('what_was_done', preset)
		if (isDone) updateSummery('Whats_was_you_need', preset)

		updateChatHistoryAnswers(preset, chatNextQuestion, isDone)
		setIsInputDisabled(false)
	}

	const getPreviousQuestion = () => {
		setAnswer(({ answers, summary, ...rest }) => {
			const lastAnswer = answers[answers.length - 1]
			const answer = answers[answers.length - 2]

			const shouldDisableChatInput = lastAnswer?.phase === PHASE.ACTIONS

			if (shouldDisableChatInput) setIsInputDisabled(true)
			const isSummaryPhase = answer?.phase! === PHASE.SUMMARY
			const isLessonsPhase = answer?.phase! === PHASE.LESSON

			return {
				...rest,
				summary: {
					...summary,
					lessons: isSummaryPhase || isLessonsPhase ? summary.lessons : [],
				},
				currentAnswer: '',
				isDone: lastAnswer?.isDone!,
				currentPhase: answer?.phase ?? PHASE.ACTIONS,
				answers: answers.slice(0, answers.length - 1),
			}
		})
	}

	// function to restart current matchpoin
	const restartMatchPoint = () => resetState()

	//click event to take user to the the next match point.
	const handleEndOfMatchPoint = () => {
		const { what_was_done, assumption, Whats_was_you_need, lessons } = summary
		if (!debrief?._id) {
			alert('error with debrief id (handleEndOfMatchPoint)')
			return
		}

		if (company_id) {
			const payload: SummaryDTO = {
				output_id: debrief?._id,
				company_id,
				lessonsOwner_id: _id,
				event_id: matchPoints[currentMatchPoint]._id,
				actionAnswer: what_was_done,
				assumption,
				need: Whats_was_you_need,
				lessons,
				createdAt: new Date(),
			}
			dispatch(create_matchpoint_summary(payload))
		}
		resetState()
		setCurrentMatchPoint(prevMatchPoint => ++prevMatchPoint)
	}

	const sendToProccessOutput = () => {
		resetState()
		if (nextStep === DebriefingSteps.SUMMARY && isParticipant) {
			setParticipantStep?.(DebriefingSteps.SUMMARY)
			return
		}
		setData && setData({}, nextStep)
	}

	return {
		answerState,
		currentMatchPoint,
		isInputDisabled,
		getPreviousQuestion,
		restartMatchPoint,
		handleInputChange,
		handleEndOfMatchPoint,
		handleIDontKnowWhyClick,
		handlePresetClick,
		handleChatButtonClick,
	}
}

export default useChatLogic
