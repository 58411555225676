import { FC, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import Grid from '@material-ui/core/Grid'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import useStyles from './styles'

import { AppDirectionContext } from 'context/languages-context'

import {
	receivedPraisesSelector,
	moreReceivedPraisesSelector,
	moreSentPraisesSelector,
	moreDraftsPraisesSelector,
	praisesLoadingSelector,
} from 'redux/praise/praise-selector'
import { setNotificationOld } from 'redux/notifications/notifications-action'

import MostRecent from 'components/common/MostRecent'
import Tabs from 'components/common/Tabs'
import CustomTab from 'components/common/Tabs/Row'
import SearchUser from 'components/common/SearchUser'
import { useLogic } from './useLogic'
import { OutputType } from 'screens/BotOutput'
import { Type } from '../BotOutput/index'

import { DashboardPaths } from '../../enums/routes/path-names'
import { PraiseState } from '../../enums/praise-state/praise-state.enum'
import { UpdatePraisePatch } from '../../interface/api/get-payload.types'
import { updatePraiseOutputState } from '../../api/praise'
import { userSelector } from '../../redux/user/user-selector'
import { ActionNameMapScreens } from 'utils/mapUrl'

const PraisesScreen: FC = () => {
	const [lastPanel, setLastPanel] = useState<string>('')

	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const { state }: any = useLocation()
	const { t } = useTranslation()
	const { isRtlDirection } = useContext(AppDirectionContext)

	const hasMoreSentPraises = useSelector(moreSentPraisesSelector)
	const hasMoreReceivedPraises = useSelector(moreReceivedPraisesSelector)
	const hasDradtsPraises = useSelector(moreDraftsPraisesSelector)
	const receivedPraises = useSelector(receivedPraisesSelector)
	const isLoading = useSelector(praisesLoadingSelector)
	const user = useSelector(userSelector)
	const logicResponse = useLogic({ state })

	useEffect(() => {
		if (!sessionStorage.getItem('id')) return
		const id = sessionStorage.getItem('id') || ''
		setLastPanel(id)
	}, [])

	if (!logicResponse) return <div>Loding!</div>

	const {
		company_id,
		currentTab,
		draftsPraisesMapped,
		handleChangeCurrentTab,
		handleSearchTextChange,
		lastReceived,
		lastSent,
		sentPraisesMapped,
		receivedPraisesMapped,
		nextPage,
	} = logicResponse

	const gotoOutputScreen = (praise: any) => {
		const state = {
			type: Type.Praise,
			screenToReturn: DashboardPaths.PRAISES,
			outputScreenType: praise.outputScreenType,
			tabState: praise.tabState,
		}

		history.push(`/output/${praise.id}`, state)
		sessionStorage.setItem('id', praise.id)
	}

	const updatePraiseState = (id: string) => {
		const praise: UpdatePraisePatch = {
			_id: id,
			company_id: company_id!,
			praiseState: PraiseState.VIEWED,
		}

		updatePraiseOutputState(praise)
	}

	const endMessage = (
		<p style={{ textAlign: 'center' }}>
			<b>{t('end_list')}</b>
		</p>
	)
	const loader = <h4 className={classes.loader}>{t('loading...')}</h4>
	const noPraises = <h4 style={{ margin: '2% 2%' }}>{t('no_praises')}</h4>

	const infiniteScrollHeight = window.innerWidth > 400 ? 371 : 530

	const panels = [
		<InfiniteScroll
			dataLength={receivedPraises.length}
			next={() => nextPage(OutputType.PraiseReciever)}
			hasMore={hasMoreReceivedPraises}
			loader={isLoading ? loader : noPraises}
			endMessage={endMessage}
			height={infiniteScrollHeight}
		>
			{receivedPraisesMapped.map(p => {
				const { id, imageUrl, description, replay, date, readed, name, praiseState } = p
				let buttonText
				let buttonClass

				switch (praiseState) {
					case PraiseState.SENT:
						buttonText = PraiseState.NEW_PRAISE
						buttonClass = classes.buttonGreen
						break
					case PraiseState.VIEWED:
						buttonText = PraiseState.VIEW
						buttonClass = classes.buttonTransparent
						break
					default:
						buttonText = PraiseState.VIEW
						buttonClass = classes.buttonTransparent
				}

				return (
					<CustomTab
						lastPanelDisplay={lastPanel}
						key={id}
						id={id}
						handleClick={() => {
							if (praiseState === PraiseState.SENT) {
								updatePraiseState(id)
								dispatch(setNotificationOld({ id, receiver_id: user._id }))
							}
							gotoOutputScreen({
								id,
								outputScreenType: OutputType.PraiseReciever,
								tabState: 0,
							})
						}}
						imageUrl={imageUrl}
						date={date}
						name={name}
						buttonClass={buttonClass}
						description={description}
						modalButtonText={t(buttonText)}
						praiseState={praiseState}
						type='praises'
						readed={readed}
						replay={replay}
					/>
				)
			})}
		</InfiniteScroll>,
		<InfiniteScroll
			next={() => nextPage(OutputType.PraiseViewing)}
			dataLength={sentPraisesMapped.length}
			hasMore={hasMoreSentPraises}
			loader={isLoading ? loader : noPraises}
			endMessage={endMessage}
			height={infiniteScrollHeight}
		>
			{sentPraisesMapped.map(
				({ id, description, imageUrl, readed, replay, name, date, praiseState }) => {
					let buttonText
					let buttonClass
					let buttonIcon
					switch (praiseState) {
						case PraiseState.SENT:
							buttonClass = classes.buttonGreen
							buttonText = PraiseState.SENT
							break
						case PraiseState.VIEWED:
							buttonClass = classes.buttonTransparent
							buttonText = PraiseState.VIEWED
							break
						default:
							buttonClass = classes.buttonTransparent
							buttonText = PraiseState.VIEWED
					}
					return (
						<CustomTab
							lastPanelDisplay={lastPanel}
							key={id}
							id={id}
							handleClick={() => {
								gotoOutputScreen({
									id,
									outputScreenType: OutputType.PraiseViewing,
									tabState: 1,
								})
							}}
							isSendTab
							imageUrl={imageUrl}
							praiseState={praiseState}
							date={date}
							name={name}
							replay={replay}
							description={description}
							buttonIcon={buttonIcon}
							buttonClass={classes.buttonTransparent}
							modalButtonText={t(buttonText)}
							type='praises'
							readed={readed}
						/>
					)
				}
			)}
		</InfiniteScroll>,
		<InfiniteScroll
			dataLength={draftsPraisesMapped.length}
			next={() => nextPage(OutputType.PraiseDraft)}
			hasMore={hasDradtsPraises}
			loader={isLoading ? loader : noPraises}
			endMessage={endMessage}
			height={infiniteScrollHeight}
		>
			{draftsPraisesMapped.map(({ id, imageUrl, description, date, name }) => (
				<CustomTab
					lastPanelDisplay={lastPanel}
					key={id}
					id={id}
					handleClick={() => {
						gotoOutputScreen({
							id,
							outputScreenType: OutputType.PraiseDraft,
							tabState: 2,
						})
					}}
					imageUrl={imageUrl}
					date={date}
					name={name}
					description={description}
					modalButtonText={t('view_praise')}
					type='praises'
				/>
			))}
		</InfiniteScroll>,
	]

	return (
		<Grid item xs={11} sm={8} className={classes.container}>
			<MostRecent
				type={Type.Praise}
				lastSentOutputId={lastSent._id!}
				lastReceivedOutputId={lastReceived._id!}
				actionName={ActionNameMapScreens.PRAISE}
				sentCoins={0}
				receivedCoins={0}
				recipient={lastSent.name}
				sender={lastReceived.name}
				sentDescription={lastSent.CONTEXT}
				receivedDescription={lastReceived.CONTEXT}
				sentDate={lastSent.date}
				receivedDate={lastReceived.date}
			/>
			<SearchUser
				handleChange={e => handleSearchTextChange(e, currentTab)}
				isRtlDirection={isRtlDirection}
			/>
			<Tabs
				title='praises'
				panels={panels}
				tabState={state?.tabState}
				onTabChange={(tabNum: any) => handleChangeCurrentTab(tabNum)}
				tabs={[
					{ label: t('received') },
					{ label: t('sent') },
					{ label: t('draft') },
				]}
			/>
		</Grid>
	)
}

export default PraisesScreen
