export default function buildQueryString(arr: Array<any>) {
    let queryString = ''
    const add = '?user_id='
    for (let i = 0; i < arr.length; i++) {
        // if (arr.length == 1) return queryString = add + arr[i]._id  // return only one user_id for query string
        queryString += arr[i]._id + (i == arr.length - 1 ? '' : add)
    }

    return queryString
}

// export default function buildQueryString(arr: Array<any>) {
//     let queryString = ''
//     const add = '?user_id='
//     for (let i = 0; i < arr.length; i++) {
//         if(arr.length == 1) return queryString = add + arr[i]._id  // return only one user_id for query string
//         queryString += arr[i]._id + (i == arr.length - 1 ? '' : add)
//     }

//     return queryString
// }