import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'
import { useStyles } from '../../../screens/Bot/useStyles'
import { useTitle } from 'utils/useTitle'

export const PageTitles = () => {
	const {
		title,
		subTitle,
		infoTitle1 = '',
		shrinkMainTitle = false,
	} = useTitle()

	const windowWidth = useWindowWidth()
	const mobileWidth = 600
	const isMobile = mobileWidth >= windowWidth
	const classes = useStyles({})

	return (
		<>
			{infoTitle1 && (
				<div
					style={{
						marginBottom: '2%',
						fontSize: '120%',
						fontWeight: '600',
					}}
					className={classes.subTitle}
				>
					{infoTitle1}
				</div>
			)}
			{/* {infoTitle2 && (
				<div style={{ marginBottom: '2%' }} className={classes.subTitle}>
					{infoTitle2}
				</div>
			)} */}
			{title && (
				<div
					style={{
						fontSize: isMobile ? '130%' : '',
						marginBottom: shrinkMainTitle ? '2%' : '',
						marginTop: infoTitle1 ? '3%' : '', // infoTitle1 || infoTitle2 ? '3%' : '',
						width: isMobile ? '90vw' : '55vw',
						maxWidth: isMobile ? '90vw' : '55vw',
					}}
					className={classes.questionTitle}
				>
					{title}
				</div>
			)}
			{subTitle && (
				<div
					style={{
						marginBottom: shrinkMainTitle ? '2%' : '',
						fontSize: isMobile ? '100%' : '130%',
					}}
					className={classes.subTitle}
				>
					{' '}
					{subTitle}{' '}
				</div>
			)}
		</>
	)
}

export default PageTitles
