import InitialMessage from 'components/Debriefing/InitialMessage'
import { EMPTY_STATE } from '../useTreeLogic'
import { EXPECTED_RESULT, PARTICIPANTS_CHOICE } from '../stepMapComponent'

export const msgSteps = ['1', '2', '3', '4']

export const RenderGroupMessageChoice = ({ ...props }) => {
	const { updateDebrief } = props

	const pageIcon = {
		src: 'heartIcon',
		opacity: '',
	}
	const content = (
		<InitialMessage
			msgData={GROUP_PROCESS_MSG_DATA}
			setData={updateDebrief}
			prevStep={PARTICIPANTS_CHOICE}
			nextStep={EXPECTED_RESULT}
		/>
	)

	return { ...EMPTY_STATE, content, pageIcon }
}

const GROUP_PROCESS_MSG_DATA = {
	type: 'group_process_msg',
	steps: [...msgSteps],
}
