import { useMemo, useState } from "react"

import { isFolderType } from "utils/LessonsManagement/isFolder"

import { ILessonsBase, Folder, File, ILesson } from "interface/lessons-management/LessonsBase.interface"

type TitleOption = {
    title: string
    title_id: string
    lessons: string[]
}
type Props = {
    currentFolder?: ILessonsBase<Folder | File> | null
    setShowEmptyLesson: React.Dispatch<React.SetStateAction<boolean>>
}
const useItemsSection = ({ currentFolder, setShowEmptyLesson }: Props) => {
    const [selectedSection, setSelectedSection] = useState<TitleOption>({ title: '', title_id: '', lessons: [] })
    const [selectedEmailType, setSelectedEmailType] = useState('')

    const titlesOptions = useMemo(() => {
        if (!currentFolder) return

        if (!isFolderType(currentFolder.type!)) {
            const titles = currentFolder.type.lessons?.map((lesson: ILesson) => {
                const lessonsList = lesson?.data?.length ? lesson.data.map(l => l._id) : []

                return { title: lesson.label, title_id: lesson._id, lessons: lessonsList }
            })
            return titles
        }
        setShowEmptyLesson(prev => false)
    }, [currentFolder])

    const getTitleIdOfLesson = (lesson_id: string) => {
        return titlesOptions?.find(t => t.lessons.includes(lesson_id))?.title_id
    }

    const handleTitleSelected = (title_id: string) => {
        const title = titlesOptions?.find(title => title.title_id === title_id)
        setSelectedSection(title!)
    }

    const handleEmailType = (type: string) => {
        setSelectedEmailType(type)
    }

    const resetSelectedSection = () => setSelectedSection({ title: '', title_id: '', lessons: [] })

    return {
        getTitleIdOfLesson,
        handleTitleSelected,
        selectedSection,
        resetSelectedSection,
        titlesOptions,
        handleEmailType,
        selectedEmailType
    }
}

export default useItemsSection