//  MATCHPOINT_CHOICE:

import EventsList from 'components/Debriefing/EventsList'
import { EMPTY_STATE } from '../useTreeLogic'

export const RenderMatchPointsChoice = ({ ...props }) => {
	const { debrief, nextData, isNextDisabled, setDisableNext, setNextData } =
		props

	const pageIcon = {
		src: 'matchpointIcon',
		opacity: '',
	}

	const content = (
		<EventsList
			isNextDisabled={isNextDisabled}
			setDisableNext={setDisableNext}
			setData={setNextData}
			onMatchPointChoice
			debrief={debrief}
			hideCalendar
		/>
	)
	const prevData = nextData

	return { ...EMPTY_STATE, content, pageIcon, prevData }
}
