import { useEffect, useState } from 'react'

import './style.css'

import StickybarTitle from './StickybarTitle'
import GenericItemButton from '../lesson-btn/GenericItemButton'
import useButtonsGenerator from '../lesson-btn/useButtonsGenerator'

import { LessonsBtnsLable } from 'enums/lessonsManagement/index'
import { StickybarType } from '../props'

const StickyBar = (props: StickybarType) => {
	const {
		currentFolder,
		isHeader = true,
		handleClose,
		navigate,
		createFolder,
		createFile,
		createTitle,
		editFolder,
		toggleEmptyLesson,
		onLessonPasted,
		managerPermissions,
	} = props

	const [barDescription, setBarDescription] = useState('')

	const fotterClassName = 'fotter-btn'

	useEffect(() => {
		if (!isHeader) setBarDescription('one_item')

		else setBarDescription(currentFolder?.name!)
	}, [currentFolder, isHeader])

	const { buttons, enablePaste } = useButtonsGenerator({
		currentFolder,
		createTitle,
		createFolder,
		editFolder,
		toggleEmptyLesson,
		createFile,
		managerPermissions,
	})

	const footerBtns = !isHeader && (
		<div className='bar-btns'>
			<GenericItemButton
				label={LessonsBtnsLable.CANCEL}
				className={fotterClassName}
				handleClick={handleClose}
			/>
			<GenericItemButton
				label={LessonsBtnsLable.PASTE}
				handleClick={onLessonPasted}
				className={enablePaste ? fotterClassName : `${fotterClassName} disabled`}
			/>
		</div>
	)

	return (
		<StickybarTitle
			isHeader={isHeader}
			name={barDescription}
			navigate={() => navigate?.(currentFolder?.prev!)}
		>
			{isHeader ? buttons : footerBtns}
		</StickybarTitle>
	)
}

export default StickyBar