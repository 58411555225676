import { useContext } from 'react'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import EyeIcon from '@material-ui/icons/Visibility'

import useStyles from './styled'
import './style.scss'

import { AppDirectionContext } from "context/languages-context"
import fileUpload from 'assets/fileUpload.svg'


type ButtonProps = {
    allowEditAccess: boolean
    matchPointColor: string
    toggleAcceptDeleteLessonModal: () => void
    toggleBotAnalysisModal: () => void
    onCopiedLesson: () => void
}
const LessonButtons = ({
    allowEditAccess,
    matchPointColor,
    toggleAcceptDeleteLessonModal,
    onCopiedLesson,
    toggleBotAnalysisModal }: ButtonProps) => {
    const { isRtlDirection } = useContext(AppDirectionContext)
    const classes = useStyles({ isRtlDirection, matchPointColor })
    const lessonButtonsStyle = `${classes.backgroundDeleteIcon} btn expand-on-hover`
    const seeMoreButton = isRtlDirection ? { left: '.9em' } : { left: '.7em' }
    return (
        <div>
            <div className={lessonButtonsStyle}>
                <i className='periods close' style={seeMoreButton}></i>
                <span className='text'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>
                            {allowEditAccess ? (
                                <DeleteIcon onClick={toggleAcceptDeleteLessonModal} />
                            ) : (
                                <img
                                    src={fileUpload}
                                    alt='file-upload'
                                    className={classes.transferIcon}
                                    onClick={onCopiedLesson}
                                />
                            )}
                        </div>

                        <div>
                            <EyeIcon onClick={toggleBotAnalysisModal} />
                        </div>
                    </div>
                </span>
            </div>
        </div>
    )
}

export default LessonButtons