import { createSlice } from '@reduxjs/toolkit'
import { FeedbackOutput } from 'interface/bot'
import {
	Question,
	QuestionValue,
} from '../../interface/praise/praise-interface'
import {
	fetch_received_company_feedback_byIds,
	get_feedbacks,
	update_feedback,
} from './feedback-actions'
import IUser from '../../interface/user/user-interface'

import {
	get_question_feedback,
	get_additional_feedback_question,
	create_feedback,
	load_more_feedbacks,
	review_feedback_work_meet,
	update_feedback_expansions,
} from 'redux/feedback/feedback-actions'
import { filterUserOutputs } from '../praise/filterUserOutputs'
import { PraiseAndFeedbacksType } from 'enums/praise-state/praise-state.enum'
import { fetch_sent_company_praises_byIds } from 'redux/praise/praise-actions'

export type IFeedbackState = {
	currentRequestId: undefined | string
	loading: boolean
	additionalFeedbackQuestionLoading?: boolean
	error: string
	creationError: string
	drafts: FeedbackOutput<IUser>[]
	sentFeedbacks: FeedbackOutput<IUser>[]
	receivedFeedbacks: FeedbackOutput<IUser>[]
	searchResult: FeedbackOutput<IUser>[]
	currentFeedbackQuestion: Question<QuestionValue[], string> | null
	additionalFeedbackQuestion: null
	hasMoreReceivedFeedbacks: boolean
	hasMoreSentFeedbacks: boolean
	hasMoreDraftsFeedbacks: boolean
}

const initialState: IFeedbackState = {
	currentRequestId: '',
	loading: false,
	error: '',
	creationError: '',
	drafts: [],
	sentFeedbacks: [],
	receivedFeedbacks: [],
	searchResult: [],
	currentFeedbackQuestion: null,
	additionalFeedbackQuestion: null,
	hasMoreReceivedFeedbacks: true,
	hasMoreSentFeedbacks: true,
	hasMoreDraftsFeedbacks: true,
}

export const feedbackSlice = createSlice({
	name: 'feedback',
	initialState,
	reducers: {
		clearError(state) {
			return {
				...state,
				error: '',
			}
		},
	},
	extraReducers: builder => {
		//  get_feedbacks
		builder.addCase(get_feedbacks.fulfilled, (state, action) => {
			const { receivedMapped, sentMapped, drafts } = action.payload
			if (!drafts.length) {
				state.drafts = []
				state.hasMoreDraftsFeedbacks = false
			}
			if (!receivedMapped.length) {
				state.receivedFeedbacks = []
				state.hasMoreReceivedFeedbacks = false
			}
			if (!sentMapped.length) {
				state.sentFeedbacks = []
				state.hasMoreSentFeedbacks = false
			}
			state.receivedFeedbacks = filterUserOutputs(
				state.receivedFeedbacks.concat(receivedMapped as FeedbackOutput<IUser>[])
			)
			state.sentFeedbacks = filterUserOutputs(
				state.sentFeedbacks.concat(sentMapped as FeedbackOutput<IUser>[])
			)
			state.drafts = filterUserOutputs(
				state.drafts.concat(drafts as FeedbackOutput<IUser>[])
			)
			state.loading = false
		})
		builder.addCase(get_feedbacks.pending, (state, action) => {
			state.loading = true
			state.currentRequestId = action.meta.requestId!
		})

		builder.addCase(get_feedbacks.rejected, (state, action) => {
			state.loading = false
			state.currentRequestId = ''
			state.error = action.error.message!
		})
		builder.addCase(
			fetch_received_company_feedback_byIds.fulfilled,
			(state, action) => {
				const praises = action.payload.data as any

				state.searchResult = praises
				state.loading = false
			}
		)

		builder.addCase(
			fetch_received_company_feedback_byIds.pending,
			(state, action) => {
				if (!state.loading) {
					state.loading = false
					state.currentRequestId = action.meta.requestId!
				}
			}
		)
		builder.addCase(
			fetch_received_company_feedback_byIds.rejected,
			(state, action) => {
				if (action.meta.requestId === state.currentRequestId) {
					state.loading = false
					state.currentRequestId = ''
					state.error = action.error.message!
				}
			}
		)
		builder.addCase(
			fetch_sent_company_praises_byIds.fulfilled,
			(state, action) => {
				const praises = action.payload.data as any

				state.searchResult = praises
				state.loading = false
			}
		)

		builder.addCase(fetch_sent_company_praises_byIds.pending, (state, action) => {
			if (!state.loading) {
				state.loading = false
				state.currentRequestId = action.meta.requestId!
			}
		})
		builder.addCase(
			fetch_sent_company_praises_byIds.rejected,
			(state, action) => {
				if (action.meta.requestId === state.currentRequestId) {
					state.loading = false
					state.currentRequestId = ''
					state.error = action.error.message!
				}
			}
		)

		builder.addCase(load_more_feedbacks.pending, (state, action) => {
			state.loading = true
			state.currentRequestId = action.meta.requestId!
		})
		builder.addCase(load_more_feedbacks.fulfilled, (state, action) => {
			state.loading = false
			const {
				payload: { type },
				feedbacks: { sentMapped, receivedMapped, drafts },
			} = action.payload
			if (type === PraiseAndFeedbacksType.SENT) {
				if (!sentMapped.length) state.hasMoreSentFeedbacks = false
				state.sentFeedbacks = filterUserOutputs(
					state.sentFeedbacks.concat(sentMapped as FeedbackOutput<IUser>[])
				)
			}
			if (type === PraiseAndFeedbacksType.RECEIVED) {
				if (!receivedMapped.length) state.hasMoreReceivedFeedbacks = false
				state.receivedFeedbacks = filterUserOutputs(
					state.receivedFeedbacks.concat(receivedMapped as FeedbackOutput<IUser>[])
				)
			}
			if (type === PraiseAndFeedbacksType.DRAFT) {
				if (!drafts.length) state.hasMoreDraftsFeedbacks = false
				state.drafts = filterUserOutputs(
					state.drafts.concat(drafts as FeedbackOutput<IUser>[])
				)
			}
		})
		builder.addCase(load_more_feedbacks.rejected, (state, action) => {
			state.loading = false
			state.currentRequestId = ''
			state.error = action.error.message!
		})

		// create_feedback
		builder.addCase(create_feedback.fulfilled, (state, action) => {
			// state.loading = false
			const newFeedback = action.payload
			// state.sentFeedbacks = [newFeedback, ...state.sentFeedbacks]
		})
		builder.addCase(create_feedback.pending, (state, action) => {
			// state.loading = true
		})
		builder.addCase(create_feedback.rejected, (state, action) => {
			// state.loading = false
			state.currentRequestId = ''
			state.creationError = action.payload as string
		})
		// get_question_feedback
		builder.addCase(get_question_feedback.fulfilled, (state, action) => {
			const question = action.payload
			state.currentFeedbackQuestion = question
			state.loading = false
		})
		builder.addCase(get_question_feedback.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
				state.currentRequestId = action.meta.requestId
			}
		})
		builder.addCase(get_question_feedback.rejected, (state, action) => {
			state.loading = false
			state.currentRequestId = ''
			state.error = action.error.message!
		})

		builder.addCase(
			get_additional_feedback_question.fulfilled,
			(state, action) => {
				const question = action.payload
				state.additionalFeedbackQuestion = question
				state.additionalFeedbackQuestionLoading = false
			}
		)
		builder.addCase(get_additional_feedback_question.pending, (state, action) => {
			state.additionalFeedbackQuestionLoading = true
			state.currentRequestId = action.meta.requestId
		})

		builder.addCase(
			get_additional_feedback_question.rejected,
			(state, action) => {
				state.additionalFeedbackQuestionLoading = false
				state.error = action.error.message!
			}
		)

		//update_feedback
		builder.addCase(update_feedback.fulfilled, (state, action) => {
			state.loading = false

			const actions = action.payload.isReceiver
				? [...state.receivedFeedbacks]
				: [...state.sentFeedbacks]
			const updatedFeedbacks = actions.map((feedback: FeedbackOutput<IUser>) => {
				if (feedback._id === action.payload.data._id) {
					console.log({ feedback })
					return { ...feedback, ...action.payload.data }
				}
				return feedback
			})

			action.payload.isReceiver
				? (state.receivedFeedbacks = [...updatedFeedbacks])
				: (state.sentFeedbacks = [...updatedFeedbacks])
		})
		builder.addCase(update_feedback.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
			}
		})
		builder.addCase(update_feedback.rejected, (state, action) => {
			state.loading = false
			state.error = action.payload as string
		})

		builder.addCase(review_feedback_work_meet.fulfilled, (state, action) => {
			state.loading = false
			const outputToUpdate = action.payload?.output_id
			console.log({ _id: outputToUpdate })
			const currentStatus = action.payload?.data
			console.log({ updatedStatus: currentStatus })
			const updatedSentFeedbacks = state.sentFeedbacks.map(
				(feedback: FeedbackOutput<IUser>) => {
					if (feedback._id === outputToUpdate) {
						return { ...feedback, readed: currentStatus }
					}
					return feedback
				}
			) as FeedbackOutput<IUser>[]
			const updatedReceivedFeedbacks = state.receivedFeedbacks.map(
				(feedback: FeedbackOutput<IUser>) => {
					if (feedback._id === outputToUpdate) {
						return { ...feedback, readed: currentStatus }
					}
					return feedback
				}
			) as FeedbackOutput<IUser>[]
			state.receivedFeedbacks = updatedReceivedFeedbacks
			state.sentFeedbacks = updatedSentFeedbacks
		})
		builder.addCase(review_feedback_work_meet.pending, state => {
			state.loading = true
		})
		builder.addCase(review_feedback_work_meet.rejected, (state, action) => {
			state.error = `${action.payload}`
			state.loading = false
		})
		builder.addCase(update_feedback_expansions.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(update_feedback_expansions.fulfilled, (state, action) => {
			state.loading = false

			const feedback_id = action.payload?.payload.feedback_id
			const newExpansionValue = Object.values(
				action.payload?.payload.expanstionToUpdate
			)[0]
			let arrayToUpdate =
				action.payload?.payload.type === 'draft'
					? state.drafts
					: state.sentFeedbacks

			const updateExpansionContributions = arrayToUpdate.map(
				(output: FeedbackOutput<IUser>) => {
					if (output._id === feedback_id) {
						return {
							...output,
							expansionContribution: newExpansionValue,
						}
					}
					return output
				}
			)
			const updateExpansionImpacts = arrayToUpdate.map(
				(output: FeedbackOutput<IUser>) => {
					if (output._id === feedback_id) {
						return {
							...output,
							expansionsImapcts: newExpansionValue,
						}
					}
					return output
				}
			)

			// if(updateExpansionContributions.length) return state.drafts = updateExpansionContributions
		})
		builder.addCase(update_feedback_expansions.rejected, (state, action) => {
			state.loading = false
		})
	},
})
export const feedbackActions = feedbackSlice.actions
export default feedbackSlice.reducer
