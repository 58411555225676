import { FC, useEffect, useState, useContext, Dispatch } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import Typography from '@material-ui/core/Typography'

import useStyles from './styles'
import colors from 'utils/colors'
import { AppDirectionContext } from '../../context/languages-context'

import {
	newNotificationsSelector,
	notificationsLengthSelector,
	oldNotificationsSelector,
	hasMoreNotifications,
} from 'redux/notifications/notifications-selector'
import { userSelector } from 'redux/user/user-selector'
import {
	load_more_notifications,
	setNotificationOld,
} from 'redux/notifications/notifications-action'

import Notification from './Notification'
import { userActions } from 'redux/user/user-reducer'
import { setItem } from 'utils/localStorage'

type Props = {
	closeNotification: (e: any) => void
}
const PAGE_INCREMENTOR = 1
const Notifications = ({ closeNotification }: Props) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isHeb: isRtlDirection })
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const user = useSelector(userSelector)
	const hasMore = useSelector(hasMoreNotifications)
	const newNotifications = useSelector(newNotificationsSelector)
	const oldNotifications = useSelector(oldNotificationsSelector)
	const [pageNumber, setPageNumber] = useState(0)
	
	const nextPage = () => {
		let nextPageNumber = 1
		nextPageNumber = pageNumber + PAGE_INCREMENTOR
		const payload = {
			receiverId: user._id,
			pageNumber: nextPageNumber,
		}
		dispatch(load_more_notifications(payload))
		setPageNumber(nextPageNumber)
	}

	useEffect(() => {
		setItem('selectedEmployee', '')
	}, [])

	const setNotificationOldHandler = (id: string) =>
		dispatch(setNotificationOld({ id, receiver_id: user._id }))

	const endMessage = (
		<p style={{ textAlign: 'center', color: colors.boldBlue }}>end</p>
	)
	const hasNotification = newNotifications.length || oldNotifications.length
	return (
		<div
			className={classes.container}
			style={{
				left: isRtlDirection ? '' : '-150%',
				right: isRtlDirection ? '-150%' : '',
			}}
		>
			<Typography variant='body1' className={classes.title}>
				{t('notifications')}
			</Typography>
			{hasNotification ? (
				<InfiniteScroll
					dataLength={newNotifications.length + oldNotifications.length}
					next={nextPage}
					hasMore={hasMore}
					loader={<h4 className={classes.loader}>Loading...</h4>}
					endMessage={endMessage}
					height={500}
				>
					{newNotifications.length ? (
						<div className={`${classes.header} ${classes.headerNew}`}>{t('new')}</div>
					) : null}
					{[...newNotifications].map(notification => (
						<Notification
							{...notification}
							close={closeNotification}
							key={notification._id}
							setNotificationOld={setNotificationOldHandler}
						/>
					))}
					{newNotifications.length && oldNotifications.length ? (
						<>
							<hr className={classes.hr} />
							<div className={classes.header}>{t('older')}</div>
						</>
					) : oldNotifications.length ? (
						<div style={{ marginTop: '15px' }} />
					) : null}
					{[...oldNotifications].map(notification => (
						<Notification
							close={closeNotification}
							{...notification}
							key={notification._id}
							setNotificationOld={setNotificationOld}
						/>
					))}
				</InfiniteScroll>
			) : (
				<p style={{ textAlign: 'center' }}>Empty state</p>
			)}
		</div>
	)
}

export default Notifications
