import { makeStyles, Theme } from '@material-ui/core'
import colors from 'utils/colors'

export const useStyles = makeStyles<
	Theme,
	{ isRtlDirection: boolean; readed?: boolean }
>(({ breakpoints }) => ({
	checkboxWrapper: {
		marginTop: '3%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		[breakpoints.down('sm')]: {
			marginTop: '9%',
		},
	},
	backgroundImage: {
		width: '25%',
		zIndex: -1,
		position: 'fixed',
		left: '-60px',
		bottom: '-50px',
		opacity: '0.1',

		[breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	container: {
		padding: '32px 0 0 0',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',

		[breakpoints.down('sm')]: {
			// paddingLeft: '0px',
			// paddingRight: '0px',
		},
	},
	headerSection: {
		width: '100%',
		maxWidth: '1313px',
		display: 'flex',
		justifyContent: 'space-between',

		[breakpoints.down('sm')]: {
			justifyContent: 'flex-end',
		},
	},
	contentSection: {
		width: '871px',
	},
	contentBody: {
		border: '1px solid rgba(49, 49, 49, 0.2)',
		borderRadius: '10px',
		padding: '5%',
	},
	greetings: {
		fontFamily: 'Sora, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '20px',

		color: '#46484A',
	},
	greetingSender: {
		fontWeight: 'bold',
		fontSize: '25px',

		display: 'flex',
		alignItems: 'center',

		[breakpoints.down('sm')]: {
			fontSize: '20px',
			marginBottom: '10%',
		},
	},
	toRecipient: {
		display: 'flex',
		alignItems: 'center',

		[breakpoints.down('sm')]: {
			alignItems: 'flex-end',
		},
	},
	textInput: {
		display: 'flex',
		justifyContent: 'space-between',

		width: '100%',
		minHeight: '80px',
		resize: 'none',
		fontSize: '1.3rem',
		fontFamily: 'Sora, sans-serif',
		margin: '12px 0 39px',
		padding: ({ isRtlDirection }) => '9px',
		outline: 'none',
		background: '#FFFFFF',
		border: '1.4px solid rgba(49, 49, 49, 0.152716)',
		boxSizing: 'border-box',
		boxShadow: ' inset 3px 3px 10px #E1F0FF',
		borderRadius: '14px',
		[breakpoints.down('sm')]: {
			minHeight: '18vw',
			fontSize: '1rem',
		},
	},
	sayDoLogo: {
		cursor: 'pointer',
		height: '89px',
		width: '77px',
		[breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	closeIcon: {
		width: '40px',
		height: '39px',
		cursor: 'pointer',
	},
	avatarStyles: {
		marginInlineStart: '12px',
		width: '50px',
		height: '51px',

		[breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	recepientAvatarStyle: {
		marginLeft: '14px',
		width: '50px',
		height: '51px',
	},
	recepientName: {
		fontWeight: 'bold',
		marginLeft: '5px',

		[breakpoints.down('sm')]: {
			alignSelf: 'end',
			display: 'contents',
		},
	},
	eventTitleContainer: {
		fontFamily: 'Sora, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '20px',

		marginTop: '34px',
		color: '#46484A',

		[breakpoints.down('sm')]: {
			fontWeight: 'bold',
			fontSize: '1rem',
		},
	},
	eventTitle: {
		fontWeight: 'bold',
		marginLeft: '5px',

		[breakpoints.down('sm')]: {
			fontWeight: 'normal',
		},
	},
	descriptionContinair: {
		fontFamily: 'Sora, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '1.3rem',

		marginTop: '42px',

		color: '#46484A',
	},
	descriptionInput: {
		width: '100%',
		height: '123px',
		resize: 'none',
		fontSize: '1.3rem',

		margin: '12px 0 39px',
		padding: '12px 0 0 9px',

		outline: 'none',
		background: '#FFFFFF',
		border: '1.4px solid rgba(49, 49, 49, 0.152716)',
		boxSizing: 'border-box',
		boxShadow: ' inset 3px 3px 10px #E1F0FF',
		borderRadius: '14px',
	},
	successContributionContainer: {
		fontFamily: 'Sora, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '20px',

		color: '#46484A',

		marginTop: '32px',

		[breakpoints.down('sm')]: {
			'& > div': {
				fontWeight: 'bold',
				fontSize: '1rem',
			},
		},
	},

	textWrapper: {
		fontWeight: 'bold',
		display: 'flex',
		marginTop: '10px',
		alignItems: 'flex-start',
		'& > div': {
			marginLeft: ({ isRtlDirection }) => (isRtlDirection ? '0' : '1%'),
			marginRight: ({ isRtlDirection }) => (isRtlDirection ? '1%' : '0'),
		},
		[breakpoints.down('sm')]: {
			marginTop: '4.5%',
		},
	},
	furtherImpactContainer: {
		fontFamily: 'Sora, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '20px',
		color: '#46484A',
		marginTop: '32px',

		[breakpoints.down('sm')]: {
			'& > div:nth-of-type(1)': {
				fontWeight: 'bold',
				fontSize: '1rem',
			},
		},
	},

	contributionCompanyValuesContainer: {
		fontFamily: 'Sora, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '20px',

		color: '#46484A',

		marginTop: '34px',

		'& > div:nth-of-type(1) ': {
			marginBottom: '10px',
		},

		[breakpoints.down('sm')]: {
			fontWeight: 'bold',
			fontSize: '1rem',
		},
	},
	companyValue: {
		display: 'flex',
	},
	value: {
		fontWeight: 'bold',

		[breakpoints.down('sm')]: {
			fontWeight: 'normal',
			fontSize: '16px',
		},
	},
	expanstionSection: {
		direction: ({ isRtlDirection }) => (isRtlDirection ? 'rtl' : 'ltr'),
		display: 'flex',
		flexDirection: 'row',
	},
	expanstionInput: {
		width: '90%',
		outline: 'none',
		border: 'none',
		borderBottom: `1px solid ${colors.saydoGreen}`,
		fontSize: '22px',
		cursor: 'pointer',
		paddingLeft: ({ isRtlDirection }) => (isRtlDirection ? 0 : '8px'),
		paddingRight: ({ isRtlDirection }) => (isRtlDirection ? '8px' : 0),
	},
	plusButton: {
		cursor: 'pointer',
		width: '15px',
		height: '15px',
		padding: '5px',
	},
	actionContainer: {
		fontWeight: 'bold',
		fontSize: '25px',
		color: '#46484A',

		margin: '7% 0',
	},
	proceedText: {
		textAlign: 'center',
		color: '#46484A',

		marginBottom: '33px',

		[breakpoints.down('sm')]: {
			marginTop: '20%',
			fontSize: '1.2rem',
		},
	},

	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-evenly',
	},
	reviewBtnWrapper: {
		margin: '0 0 4% 0',
	},
	reviewBtn: {
		borderRadius: '15px',
		width: '150px',
		fontWeight: 600,
		border: `.7px solid ${colors.saydoGreen}`,
		color: ({ readed }) => (readed ? '#fff' : colors.saydoGreen),
		backgroundColor: ({ readed }) => (readed ? colors.saydoGreen : '#fff'),

		float: ({ isRtlDirection }) => (isRtlDirection ? 'left' : 'right'),
		margin: '0% 0 3% 0',
		'&:active': {
			backgroundColor: 'lightgrey',
		},
		[breakpoints.down('xs')]: {
			width: '150px !important',
		},
	},
	replayTextarea: {
		width: '90%',
		border: 'none',
		outline: 'none',
		borderRadius: '8px',
		resize: 'none',
		margin: '1% 0',
		padding: '8px',
		fontSize: '1.3rem',
		direction: ({ isRtlDirection }) => (isRtlDirection ? 'rtl' : 'ltr'),
	},
	editIcon: {
		wordBreak: 'keep-all',
		fontWeight: 'bold',
		cursor: 'pointer',
		fontSize: '0.85rem',
		display: 'flex',
		color: '#000',
	},
}))
