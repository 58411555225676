import { createAsyncThunk } from '@reduxjs/toolkit'
import {
	createDebrief,
	createDebriefEvent,
	createMatchpointLesson,
	createMatchpointSummary,
	deleteDebriefEvent,
	deleteMatchpointLesson,
	fetchUserDebriefings,
	findOneDebrief,
	shareDebrief,
	updateBotAnswers,
	updateDebrief,
	updateDebriefEvent,
	updateEventLocation,
	updateMatchpointLesson,
	exportDebrief
} from 'api/debrief'
import { Req } from 'enums/req/req.enum'
import { deleteDbreif } from '../../api/debrief'
import {
	Debrief,
	DebriefDTO,
	DebriefEvent,
	ExtraDispatchPayload,
	Lesson,
	SummaryDTO,
	BotAnswersPayload,
	FindDebriefPayload,
} from 'interface/debrief/debrief-interface'
import { ShareDebriefDto } from '../../interface/debrief/debrief-interface'
import IUser from '../../interface/user/user-interface'

export type PdfDebriefPayload = {
	blob: string | ArrayBuffer | null,
	company_id: string,
	receivers: string[]
	debrief_id: string
	sender: string
}

export const get_user_debriefings = createAsyncThunk(
	'debrief/getUserDebriefings',
	async (
		payload: {
			user_id: string
			company_id: string
			pageNumber: number
		},
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await fetchUserDebriefings(payload)
			if (status === Req.failed) throw new Error(data)
			return { data, pageNumber: payload.pageNumber }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const update_debrief = createAsyncThunk(
	'debrief/updateDebriefStep',
	async (payload: Partial<DebriefDTO>, { rejectWithValue, dispatch }) => {
		try {
			const { data, status } = await updateDebrief(payload)
			if (status === Req.failed) throw new Error(data)
			dispatch(
				get_user_debriefings({
					user_id: payload.creator!,
					company_id: payload.company_id!,
					pageNumber: 0,
				})
			)
			return data as Debrief
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const share_debrief = createAsyncThunk(
	'debrief/shareDebrief',
	async (
		payload: ShareDebriefDto & { updatedSharedParticipants: Array<IUser> },
		{ rejectWithValue }
	) => {
		try {
			const { updatedSharedParticipants, ...rest } = payload
			const { data, status } = await shareDebrief(rest)
			if (status === Req.failed) throw new Error(data)

			return { updatedSharedParticipants, _id: rest._id } as any
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const create_debrief_event = createAsyncThunk(
	'debrief/createDebriefEvent',
	async (
		// payload: {
		// 	company_id: string
		// 	debrief_id: string
		// 	event: DebriefEvent
		// 	extraDispatchPayload?: ExtraDispatchPayload
		// },
		payload: ExtraDispatchPayload,
		{ rejectWithValue, dispatch }
	) => {
		try {
			const { nextPayload, shouldDispatch } = payload
			const { data, status } = await createDebriefEvent(payload)
			if (status === Req.failed) throw new Error(data)

			// const { extraDispatchPayload } = payload

			// // Note: the code bellow will run only if we enable it with shouldDispatch

			// const { shouldDispatch = false, nextPayload = {} } =
			// 	extraDispatchPayload ?? {}
			if (shouldDispatch)
				dispatch(create_matchpoint_summary({ ...nextPayload!, event_id: data._id }))

			return data as DebriefEvent
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const create_matchpoint_summary = createAsyncThunk(
	'debrief/createMatchpointSummary',
	async (payload: SummaryDTO, { rejectWithValue }) => {
		try {
			const { data, status } = await createMatchpointSummary(payload)
			if (status === Req.failed) throw new Error(data)

			return { event_id: payload.event_id, data }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const delete_debrief_event = createAsyncThunk(
	'debrief/deleteDebriefEvent',
	async (
		payload: { company_id: string; debrief_id: string; event_id: string },
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await deleteDebriefEvent(payload)
			if (status === Req.failed) throw new Error(data)
			return payload.event_id
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const update_debrief_event = createAsyncThunk(
	'debrief/updateDebriefEvent',
	async (
		payload: { company_id: string; debrief_id: string; event: DebriefEvent },
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await updateDebriefEvent(payload)
			if (status === Req.failed) throw new Error(data)
			return payload.event
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const update_matchpoint_lesson = createAsyncThunk(
	'debrief/updateMatchpointLesson',
	async (
		payload: {
			company_id: string
			event_id: string
			lessonData: Lesson
			summary_id: string
			debrief_id?: string
		},
		{ rejectWithValue }
	) => {
		try {
			const { company_id, event_id, lessonData, summary_id, debrief_id } = payload

			const { data, status } = await updateMatchpointLesson({
				company_id,
				event_id,
				debrief_id,
				lessonData,
			})

			if (status === Req.failed) throw new Error(data)
			return { event_id, summary_id, data: payload.lessonData }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const create_matchpoint_lesson = createAsyncThunk(
	'debrief/createMatchpointLesson',
	async (
		payload: {
			company_id: string
			debrief_id?: string
			event_id: string
			lessonData: Lesson
			summary_id: string
			lessonOwner_id: string
		},
		{ rejectWithValue }
	) => {
		try {
			const { company_id, event_id, lessonData, debrief_id, lessonOwner_id } =
				payload

			const { data, status } = await createMatchpointLesson({
				company_id,
				event_id,
				debrief_id,
				lessonData,
			})

			if (status === Req.failed) throw new Error(data)
			return { event_id, summary_id: event_id, lessonOwner_id, data }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const delete_matchpoint_lesson = createAsyncThunk(
	'debrief/deleteMatchpointLesson',
	async (
		payload: {
			company_id: string
			event_id: string
			lesson_id: string
			summary_id: string
		},
		{ rejectWithValue }
	) => {
		try {
			const { company_id, event_id, lesson_id, summary_id } = payload
			console.log(payload)
			const { data, status } = await deleteMatchpointLesson({
				company_id,
				lesson_id,
			})
			if (status === Req.failed) throw new Error(data)
			return { event_id, summary_id, data: lesson_id }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const update_event_location = createAsyncThunk(
	'debrief/updateEventLocation',
	async (
		payload: {
			company_id: string
			debrief_id: string
			source: number
			destination: number
		},
		{ rejectWithValue }
	) => {
		try {
			const { data, status } = await updateEventLocation(payload)
			if (status === Req.failed) throw new Error(data)
			return data //arr of id  strings with the order stored like backend
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const create_new_debrief = createAsyncThunk(
	'debrief/createNewDebrief',
	async (payload: Partial<DebriefDTO>, { rejectWithValue }) => {
		try {
			const { data, status } = await createDebrief(payload)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const delete_debreif = createAsyncThunk(
	'debrief/deleteDbreif',
	async ({ _id, company_id }: Partial<DebriefDTO>, { rejectWithValue }) => {
		try {
			const { data, status } = await deleteDbreif({
				company_id: company_id!,
				_id: _id!,
			})
			if (status === Req.failed) throw new Error(data)
			return { _id }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const update_analysis_answer = createAsyncThunk(
	'debrief/analysisAnswers',
	async (payload: BotAnswersPayload, { rejectWithValue }) => {
		try {
			const { data, status } = await updateBotAnswers(payload)
			if (status === Req.failed) throw new Error(data)
			return { data: payload }
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)
export const get_one_debrife = createAsyncThunk(
	'debrief/findOneDebrief',
	async (payload: FindDebriefPayload, { rejectWithValue }) => {
		try {
			const { data, status } = await findOneDebrief(payload)
			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			rejectWithValue(err)
		}
	}
)

export const export_debrief = createAsyncThunk(
	'debrief/exportDebrief',
	async (payload: PdfDebriefPayload, { rejectWithValue }) => {
		try {
			const { data, status } = await exportDebrief(payload)

			if (status === Req.failed) throw new Error(data)
			return data
		} catch (err) {
			rejectWithValue(err)
		}
	}
)
