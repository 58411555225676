import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getaAdditionalPraiseQuestionSelector } from 'redux/praise/praise-selector'
import { getAdditionalFeedbackQuestionSelector } from 'redux/feedback/feedback-selector'
import { get_additional_feedback_question } from 'redux/feedback/feedback-actions'
import { get_additional_praise_question } from 'redux/praise/praise-actions'
import { userSelector } from 'redux/user/user-selector'
import { botActions } from 'redux/bot/bot-reducer'
import { QuestionValue, QuestionType } from 'interface/praise/praise-interface'
import { initialAnswer, UserAnswer } from '../ActionButtons/index'
import { UseClickActions, UseReplaceNameHook } from '../useHooks/index'

const MAX_AMOUNT_USER_CAN_CHOSE = 3

type Props = {
	isPraise?: boolean
	nextQuestion?: string
	previousQuestion?: string
	answers: Array<QuestionValue>
	isAbleToSkipFurtherImpact: boolean
	setQuestion: React.Dispatch<React.SetStateAction<string | null>>
	type: QuestionType
}

const useMultipleChoiceLogic = (props: Props) => {
	const {
		setQuestion,
		nextQuestion,
		isAbleToSkipFurtherImpact,
		previousQuestion,
		answers,
		type,
		isPraise,
	} = props

	const currentSelector = isPraise
		? getaAdditionalPraiseQuestionSelector
		: getAdditionalFeedbackQuestionSelector

	const additionalPraiseQuestionSelector = useSelector(currentSelector)
	const user = useSelector(userSelector)

	const [answerIndex, setAnswerIndex] = useState(0)
	const [furtherImpact, setFurtherImpact] = useState({})
	const [openPopUpModal, setopenPopUpModal] = useState(false)
	const [chosenIds, setChosenIds] = useState<Array<number>>([])
	const [nextQuestionsId, setNextQuestionsId] = useState<string[]>([])
	const [answer, setAnswer] = useState<UserAnswer>({
		...initialAnswer,
		previousQuestion: previousQuestion!,
		nextQuestion: nextQuestion!,
		type,
	})

	const dispatch = useDispatch()
	const { fetchQuestion } = UseClickActions(isPraise)
	useEffect(() => {
		const fetchAdditionalQuestion = (filteredAnswers: string[]) => {
			if (isPraise) {
				dispatch(
					get_additional_praise_question({
						questionId: filteredAnswers[answerIndex],
						language: user.statusInfo.companyLanguage,
					})
				)
			} else {
				dispatch(
					get_additional_feedback_question({
						questionId: filteredAnswers[answerIndex],
						language: user.statusInfo.companyLanguage,
					})
				)
			}
		}
		const fetch = async () => {
			const multiAnswersIds =
				nextQuestionsId?.length && nextQuestionsId.filter(Boolean)

			const hasMoreAnswers =
				multiAnswersIds && answerIndex <= multiAnswersIds.length
			if (openPopUpModal && multiAnswersIds && hasMoreAnswers) {
				fetchAdditionalQuestion(multiAnswersIds)
			}
		}
		fetch()
	}, [nextQuestionsId, openPopUpModal, answerIndex, isPraise])

	const handleClick = useCallback(
		(index: number, nextQuestion: string) => {
			const isUserAbleToChose = chosenIds.length < MAX_AMOUNT_USER_CAN_CHOSE
			const isChosed = chosenIds.includes(index)

			if (isAbleToSkipFurtherImpact && answers.length - 1 === index) {
				setQuestion(nextQuestion)
				return
			}

			if (!isUserAbleToChose || isChosed) {
				handleRemoveUserAnswer(index)
				return
			}

			handleAddUserAnswer(index, nextQuestion)
		},
		[chosenIds, answers, answerIndex]
	)

	const handleAddUserAnswer = (answerIndex: number, nextQuestion: string) => {
		const indices = chosenIds.length
			? [...chosenIds, answerIndex].sort((a, b) => a - b)
			: [answerIndex]

		setNextQuestionsId(
			indices.map(id => answers[id].nextQuestion).filter(Boolean)
		)
		setChosenIds(indices)
		setAnswer(previousAnswer => ({
			...previousAnswer,
			nextQuestion: nextQuestion!,
			multiChoiceAnswer: indices.map(
				(index: number) => answers[index].nextQuestion
			),
		}))

		handleSetFurtherImpact(indices)
	}

	const handleRemoveUserAnswer = useCallback(
		(answerIndex: number): void => {
			const indices = chosenIds.filter(
				existedIndex => existedIndex !== answerIndex
			)

			setNextQuestionsId(
				indices.map((index: number) => answers[index].nextQuestion)
			)

			setChosenIds(indices)

			setAnswer((previousAnswer: UserAnswer) => ({
				...previousAnswer,
				nextQuestion: nextQuestion!,
				multiChoiceAnswer: indices.map(
					(index: number) => answers[index].nextQuestion
				),
			}))

			handleSetFurtherImpact(chosenIds)
		},
		[chosenIds]
	)

	const handleCloseModal = useCallback(() => {
		fetchQuestion(isPraise!, nextQuestion)
		setopenPopUpModal(false)
	}, [nextQuestion])

	const handleSetIndex = useCallback(() => {
		setAnswerIndex(prev => prev + 1)
	}, [])

	const handleModalOpenNextQuestion = useCallback(
		(modalAnswer: UserAnswer, currentImpactId: any) => {
			if (nextQuestionsId.length) {
				const hasMoreAnswers = answerIndex + 1 < nextQuestionsId.length
				const updatedFurtherImpact = handleUpdateFurtherImpactOnModalOpen(
					modalAnswer.id!,
					currentImpactId
				)
				if (hasMoreAnswers) {
					handleSetIndex()
				} else {
					handleSaveFurtherImpacts(updatedFurtherImpact)
					handleCloseModal()
				}
			} else {
				handleCloseModal()
			}
		},
		[chosenIds, nextQuestionsId, answerIndex, furtherImpact]
	)

	const handleSetFurtherImpact = useCallback((chosenIds: number[]) => {
		const DEFAULT_VALUE = '0'
		let impacts: Record<any, any> = {}

		chosenIds
			.sort((a, b) => a - b)
			.forEach(id => {
				if (!impacts[id]) impacts[id] = DEFAULT_VALUE
				impacts[id] = id.toString()
			})

		setFurtherImpact(prev => impacts)
	}, [])

	const handleUpdateFurtherImpactOnModalOpen = (
		impactedBoxId: string | number,
		furtherImpactId: string
	) => {
		const updatedFurtherImpact = {
			...furtherImpact,
			[furtherImpactId]: `${furtherImpactId}-${impactedBoxId}`,
		}

		setFurtherImpact(prev => updatedFurtherImpact)

		return updatedFurtherImpact
	}

	const replaceTitle = (title: string) => {
		//  the code bellow renders an error: "to much rerenders"
		return UseReplaceNameHook(title)
		// return title
	}
	const handleResetModal = () => {
		setAnswerIndex(0)
		setChosenIds([])
		setopenPopUpModal(false)
	}

	const handleSaveFurtherImpacts = useCallback(
		(updatedFurtherImpact?: Record<any, any>) => {
			const furtherImpactToValues = updatedFurtherImpact
				? updatedFurtherImpact
				: furtherImpact

			const answers: string[] = furtherImpactMapIdAnswer(
				Object.values(furtherImpactToValues)
			)

			dispatch(botActions.addAnswer({ ...answer, multiChoiceAnswer: answers }))
		},
		[furtherImpact]
	)

	const furtherImpactMapIdAnswer = (impacts: string[]) => {
		return impacts.map(impact => {
			const [id, modalBoxId] = impact.split('-')

			return `${Number(id) + 1}-${modalBoxId ?? ''}`
		})
	}

	const getCurrentFurtherImpactId = useMemo(() => {
		if (!openPopUpModal) {
			return []
		}

		return chosenIds.filter(id => answers[id].nextQuestion)
	}, [chosenIds, openPopUpModal])

	const isEnabled = nextQuestionsId?.length || chosenIds.length ? true : false

	return {
		handleSaveFurtherImpacts,
		handleModalOpenNextQuestion,
		setAnswerIndex,
		handleClick,
		replaceTitle,
		setChosenIds,
		setopenPopUpModal,
		setAnswer,
		handleCloseModal: handleResetModal,
		currentImpactId: getCurrentFurtherImpactId[answerIndex],
		additionalPraiseQuestionSelector,
		openPopUpModal,
		answer,
		isEnabled,
		answerIndex,
		chosenIds,
	}
}
export default useMultipleChoiceLogic
