import { FC, useState, useContext, useEffect } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { AppDirectionContext } from 'context/languages-context'

import { useStyles } from './styled'

import {
	employeesSelector,
	employeeLoadingSelector,
} from 'redux/employee/employee.selector'
import {
	companyPraisesSelector,
	companyPraisesTemplatesSelector,
	praisesLoadingSelector,
	moreOnWallPraisesSelector,
} from 'redux/praise/praise-selector'
import {
	fetch_company_praises,
	fetch_company_praises_templates,
} from 'redux/praise/praise-actions'
import { userSelector, userLoadingSelector } from 'redux/user/user-selector'

import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'
import PraiseCard from './PraiseCard'
import { EmptyCard } from '../EmptyCard'
import ConfirmModalSB from 'components/common/confirmModalSB/ConfirmModal'
// import ConfirmModal from 'components/common/ConfirmModal/ConfirmModal'

import InfiniteScroll from 'react-infinite-scroll-component'
import IUser from 'interface/user/user-interface'

const PAGE_INCREMENTOR = 1
export type WallOfPraiseMocData = {
	image?: string
	name: string
	givenDate: string
	summarizedPraise: string
}
type Props = {}
const WallOfPraise: FC<Props> = props => {
	const [reactorsModalUsers, setReactorsModalUsers] = useState([])
	const [reactorsModalType, setReactorsModalType] = useState({
		text: '',
		icon: '',
	})
	const [pageNumber, setPageNumber] = useState<number>(0)

	const users = useSelector(employeesSelector)
	const companyPraises = useSelector(companyPraisesSelector)
	const templates = useSelector(companyPraisesTemplatesSelector)
	const isLoading = useSelector(praisesLoadingSelector)
	const isUserLoading = useSelector(userLoadingSelector)
	const isEmployeesLoadingSelector = useSelector(employeeLoadingSelector)
	const hasMoreCompanyPraises = useSelector(moreOnWallPraisesSelector)
	const user = useSelector(userSelector)

	const { _id: userId, company_id: companyId } = user

	useEffect(() => {
		if (!companyId) return
		dispatch(fetch_company_praises({ companyId, pageNumber: 0 }))
		dispatch(fetch_company_praises_templates())
	}, [companyId])

	const { isRtlDirection } = useContext(AppDirectionContext)
	const classes = useStyles({ isRtlDirection })
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const windowWidth = useWindowWidth()
	const mobileWidth = 600
	const isMobile = mobileWidth >= windowWidth

	const infiniteScrollHeight = window.innerWidth > 400 ? 371 : 530
	const endMessage = (
		<p style={{ textAlign: 'center' }}>
			<b>{t('end_list')}</b>
		</p>
	)
	const loader = <h4>{t('loading...')}</h4>
	const nextPage = () => {
		const nextPageNumber = pageNumber + PAGE_INCREMENTOR
		setTimeout(() => {
			setPageNumber(nextPageNumber)
			dispatch(fetch_company_praises({ companyId, pageNumber: nextPageNumber }))
		}, 1500)
	}

	const modalChild = (
		<>
			<div className={classes.reactorsModalContainer}>
				<h1>{reactorsModalType.text} </h1>
				<img src={reactorsModalType.icon} />
			</div>
			<div className={classes.reactorsContainer}>
				{reactorsModalUsers.map((user: any) => (
					<div>{user.fullName}</div>
				))}
			</div>
		</>
	)
	if (isLoading || isUserLoading || !templates || isEmployeesLoadingSelector) {
		return (
			<div className={classes.loader}>
				<CircularProgress />
			</div>
		)
	}

	return (
		<>
			<Grid item xs={11} sm={8} className={classes.container}>
				<div className={classes.title}>{t('wall_of_praise_title')}</div>

				{!!companyPraises.length && templates ? (
					<Grid className={classes.wallOfPraiseBox}>
						<InfiniteScroll
							dataLength={companyPraises.length}
							next={nextPage}
							hasMore={hasMoreCompanyPraises}
							loader={loader}
							endMessage={endMessage}
							height={infiniteScrollHeight}
						>
							{companyPraises.map(
								({
									_id,
									sender,
									receiver,
									participants,
									reason,
									createdAt,
									outputType,
									contributions,
									reactions,
									context,
								}) => {
									return (
										<PraiseCard
											usersData={users.map((u: IUser) => {
												const { personalInfo } = u
												const { firstName = '', lastName = '' } = personalInfo ?? {}
												return {
													id: u._id,
													fullName: firstName + ' ' + lastName,
												}
											})}
											language={user.statusInfo.companyLanguage}
											contextTemplate={templates[outputType].context}
											templates={templates[outputType].wallOfPraise}
											key={_id!}
											company_id={companyId!}
											userId={userId}
											outputType={outputType}
											context={context}
											contributions={contributions}
											praise_id={_id!}
											sender={sender}
											receiver={receiver}
											createdAt={createdAt}
											participants={participants ?? []}
											summarizedPraise={reason}
											reactions={reactions}
											isMobile={isMobile}
											onShowMoreReactors={(users, type, img) => {
												setReactorsModalUsers(users)
												setReactorsModalType({
													text: type.charAt(0).toUpperCase() + type.slice(1),
													icon: img,
												})
											}}
										/>
									)
								}
							)}
						</InfiniteScroll>
					</Grid>
				) : (
					<EmptyCard tTitle='empty_wall' />
				)}
			</Grid>
			<ConfirmModalSB
				isOpen={reactorsModalUsers.length > 0}
				onClose={() => setReactorsModalUsers([])}
				text={null}
				child={modalChild}
				closeButtonText={t('back')}
			/>
		</>
	)
}

export default WallOfPraise
