import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { IconButton, useMediaQuery, CircularProgress } from '@material-ui/core'

import './style.css'

import { AppDirectionContext } from 'context/languages-context'

type Props = {
    children: any
    isHeader: boolean
    name?: string
    navigate?: () => void
}
const StickybarTitle = ({ children, isHeader, name, navigate }: Props) => {
    const { isRtlDirection } = useContext(AppDirectionContext)

    const { t } = useTranslation()
    const isMobile = useMediaQuery('(max-width:550px)')

    let stickybarTitleStyle = 'stickbar'
    if (!isHeader) stickybarTitleStyle += ' animated-bar'
    if (isRtlDirection) stickybarTitleStyle += ' stickbar-rtl'

    const desktopHeader = !isMobile && isHeader
    const renderButtons = desktopHeader || !isHeader

    const navigationArrows = isHeader ? (
        <IconButton
            onClick={navigate}
            className='back-arrow'
            aria-label={isRtlDirection ? 'Next' : 'Previous'}
        >
            {isRtlDirection ? <ArrowForwardIos /> : <ArrowBackIos />}
        </IconButton>
    ) : null

    return (
        <div className={stickybarTitleStyle}>

            <StickybarTitle.Description
                navigationArrows={navigationArrows}
                description={t(`${name}`)}
                isRtlDirection={isRtlDirection} />

            {renderButtons && children}

        </div>
    )
}

type TemplateDescritpion = {
    navigationArrows: JSX.Element | null
    description: string
    isRtlDirection: boolean
}
const Description: FC<TemplateDescritpion> = ({ navigationArrows, description, isRtlDirection }) => {
    let descriptionClass = 'bar-name'
    if (navigationArrows == null) descriptionClass += ` copy-item`

    const progressbar = (<CircularProgress size={25} />)

    return (
        <div className={descriptionClass}>
            {navigationArrows}
            <p className={isRtlDirection ? ' rtl' : ''}>{description === 'undefined' ? progressbar : description}</p>
        </div>
    )
}
StickybarTitle.Description = Description

export default StickybarTitle