import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UserAvatar from 'components/common/Avatar'
import { backgroundIconsMap } from '../../../utils/iconsMapper'
import { useSelector } from 'react-redux'
import { getBotAnswers } from '../../../redux/bot/bot-selector'
import { useStyles } from './useStyles'
import { AnswersGroupProp } from 'interface/bot/index'
import { setItem } from 'utils/localStorage'
import { isSameMonth } from 'utils/helpers'
import { StorageKey } from 'enums/localStorage/LocalStorage.enum'

const previousQuestionTrans = 'previousQuestion'
const gotIt = 'gotIt'

const InitialMessage: FC<AnswersGroupProp> = props => {
	const [shouldShowMessageNextTime, setShouldShowMessageNextTime] =
		useState(true)

	const { setQuestion, isPraise, nextQuestion, previousQuestion } = props

	const sameMonth = useCallback(isSameMonth, [])()

	const answers = useSelector(getBotAnswers)
	const { recipient: userInfo }: any = answers.answers[0] || {}
	const {
		personalInfo: { firstName = '', lastName = '' },
		statusInfo: { title = '' },
	} = userInfo || {}

	useEffect(() => {
		if (!sameMonth) setQuestion(nextQuestion!)
	}, [])

	const handleNextTimeChange = () => setShouldShowMessageNextTime(prev => !prev)

	const handlePreviousStep = useCallback(() => {
		setQuestion(previousQuestion!)
	}, [])

	const handleGetNextStep = useCallback(() => {
		if (!shouldShowMessageNextTime) {
			const currentMonth = new Date().getMonth()
			setItem(StorageKey.InitialMessageExpDt, currentMonth)
		}
		setQuestion(nextQuestion!)
	}, [shouldShowMessageNextTime])

	const { t } = useTranslation()
	const classes = useStyles()
	const recepientUsername = firstName.concat(' ' + lastName)

	return (
		<div className={classes.container}>
			{!sameMonth ? (
				<>
					<div className={classes.headerSection}>
						<div className={classes.recepientUserDetails}>
							<UserAvatar className={classes.avatar} userInfo={userInfo} />
							<div className={classes.recepientUserDetailsNamAndRole}>
								<div className={classes.recepientUserDetailsName}>
									{recepientUsername}
								</div>
								<div className={classes.recepientUserDetailsRole}>{title}</div>
							</div>
						</div>
						<div className={classes.YouWantToPraise}>
							{t(`bot.${isPraise ? 'YouWantToPraise' : 'YouWantToFeedback'}`, {
								recepientUsername,
							})}
						</div>
						<div className={classes.thatsgreat}>
							{t("bot.that's_great", { isPraise })}
						</div>
					</div>
					<section className={classes.fistInstruction}>
						<b>1. </b>
						{t(
							`bot.${
								isPraise ? 'fist_instruction_praise' : 'fist_instruction_feedback'
							}`,
							{ recepientUsername }
						)}
					</section>
					<section className={classes.secondInstruction}>
						<b>2. </b>
						{t(
							`bot.${
								isPraise ? 'second_instruction_praise' : 'second_instruction_feedback'
							}`,
							{ recepientUsername }
						)}
					</section>
					<div className={classes.bottomSection}>
						<div className={classes.iconWrapper}>
							<img
								className={classes.heartIconStyle}
								src={backgroundIconsMap['heartIcon']}
								alt='heartIcon'
							/>
						</div>
						<div className={classes.checkboxAction}>
							<input type='checkbox' onChange={handleNextTimeChange} />
							<div className={classes.checkboxNote}>
								{t(`bot.${'Dont_show_me_this_message_again'}`)}
							</div>
						</div>
					</div>
					<div className={classes.actionsContainer} id='actionsContainer'>
						<button className={classes.previousButton} onClick={handlePreviousStep}>
							{t(`bot.${previousQuestionTrans}`)}
						</button>
						<button className={classes.nextButton} onClick={handleGetNextStep}>
							{t(`bot.${gotIt}`)}
						</button>
					</div>
				</>
			) : null}
		</div>
	)
}

export default InitialMessage
