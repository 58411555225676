import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import Input from '../common/Input'
import { reset_password } from 'redux/user/user-actions'
import useStyles from './styles'

type Props = {
	backToLogin: () => void
}

const ForgotPassword: FC<Props> = ({ /*backToLogin*/ }) => {
	const [email, setEmail] = useState('')
	const classes = useStyles({ isDisabled: email.trim() === '' })
	const dispatch = useDispatch()

	const onFormSubmit = async (e: any) => {
		e.preventDefault()
		if (email.trim() === '') return
		dispatch(reset_password(email))
		return
	}

	return (
		<>
			<form onSubmit={onFormSubmit}>
				<div className={classes.wrapper}>
					<Input
						label={'email'}
						type={'email'}
						name={'email'}
						htmlFor={'email'}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setEmail(e.target.value)
						}
					/>
				</div>
				<div className={classes.wrapper}>
					<button
						className={classes.button}
						type='submit'
						disabled={email.trim() === ''}
						autoFocus
					>
						{'send me an activation Code'}
					</button>
					<div className={classes.cantLoginWrapper}></div>
				</div>
			</form>
		</>
	)
}

export default ForgotPassword
