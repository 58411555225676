import { createSlice } from '@reduxjs/toolkit'
import { TasksAction, Task } from 'interface/todos/todos-interface'
import {
	get_user_todos,
	create_todos,
	update_todos,
	delete_task,
	restore_task,
	load_more_tasks,
	load_deleted_employee_tasks,
	update_task_status
} from './todos-actions'
import { filterUserOutputs } from "./filterUserTasks"
import { TodoState, TodoStatusOptions } from 'enums/todos/todos.enum'

export interface TodosState {
	error: string
	companyTodos: TasksAction
	loading: boolean
	hasMoreTasks: boolean
}

const initialState: TodosState = {
	error: '',
	loading: false,
	companyTodos: {} as TasksAction,
	hasMoreTasks: true
}

export const todosSlice = createSlice({
	name: 'todos',
	initialState,
	reducers: {
		loading(state) {
			return {
				...state,
				loading: true
			}
		},
		clearError(state) {
			return {
				...state,
				error: ''
			}
		},
		setManagerPage(state, action) {
			const pageNumber: number = action.payload
			return {
				...state,
				managerSidePage: pageNumber
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(get_user_todos.pending, (state, action) => {
			if (!state.loading) {
				state.loading = false
			}
		})
		builder.addCase(get_user_todos.fulfilled, (state, action) => {
			const todos = action.payload

			state.companyTodos = todos
			state.loading = false
		})
		builder.addCase(get_user_todos.rejected, (state, action) => {
			state.loading = false

			state.error = action.error.message!
		})

		builder.addCase(load_more_tasks.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
			}
		})
		builder.addCase(load_more_tasks.fulfilled, (state, action) => {
			state.loading = false

			const todos = action.payload.data.tasks
			state.companyTodos.tasks = filterUserOutputs(state.companyTodos.tasks.concat(todos))
		})
		builder.addCase(load_more_tasks.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message!
		})

		builder.addCase(create_todos.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
			}
		})
		builder.addCase(create_todos.fulfilled, (state, action) => {
			state.loading = false

			const todos = action.payload.data
			state.companyTodos = { ...todos }
		})
		builder.addCase(create_todos.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message!
		})


		builder.addCase(update_todos.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
			}
		})
		builder.addCase(update_todos.fulfilled, (state, action) => {
			state.loading = false
			state.companyTodos.didManagerSee = false

			const todo = action.payload as Task

			if (todo.state === TodoState.new) state.companyTodos.tasks = [todo, ...state.companyTodos.tasks]

			const updatedTasks = state.companyTodos.tasks.map((task: Task) => {
				if (task._id === todo._id) {
					return todo
				}
				return task
			})
			state.companyTodos.tasks = updatedTasks
		})
		builder.addCase(update_todos.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message!
		})


		builder.addCase(delete_task.pending, (state, action) => {
			state.loading = true
		})
		builder.addCase(delete_task.fulfilled, (state, action) => {
			state.loading = false

			const removedTask = action.payload
			state.companyTodos.tasks.map((task: Task) => {
				if (task._id === removedTask) return task.state = TodoState.deleted
				else return task
			})
		})
		builder.addCase(delete_task.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message!
		})

		builder.addCase(restore_task.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
			}
		})
		builder.addCase(restore_task.fulfilled, (state, action) => {
			state.loading = false

			const todo_id = action.payload
			state.companyTodos.tasks.map((task: Task) => {
				if (task._id === todo_id) task.state = TodoState.viewed
				else return task
			})
		})
		builder.addCase(restore_task.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message!
		})


		builder.addCase(load_deleted_employee_tasks.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
			}
		})
		builder.addCase(load_deleted_employee_tasks.fulfilled, (state, action) => {
			state.loading = false
			const todos = action.payload.data
			console.log({ todos })

			state.companyTodos.tasks.push(...todos)
		})
		builder.addCase(load_deleted_employee_tasks.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message!
		})

		builder.addCase(update_task_status.pending, (state, action) => {
			if (!state.loading) {
				state.loading = true
			}
		})
		builder.addCase(update_task_status.fulfilled, (state, action) => {
			state.loading = false
			const { status, todo_id } = action.payload.data
			
			state.companyTodos.tasks.map((task: Task) => {
				if (task._id === todo_id) task.status = status

				else return task
			})
		})
		builder.addCase(update_task_status.rejected, (state, action) => {
			state.loading = false
			state.error = action.error.message!
		})
	},
})
export const todosActions = todosSlice.actions
export default todosSlice.reducer