import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'

type StyleProps = {
	width?: string
	margin?: boolean
	isRtlDirection?: boolean
}
export default makeStyles<Theme, StyleProps>(({ breakpoints }) => ({
	containerWrapper: {
		[breakpoints.up('md')]: {
			width: ({ width }) => width,
		},

		[breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	contributions: {
		width: '1058px',
		height: '475px',
		backgroundColor: '#F6F6F6',
		borderRadius: '10px 10px 0px 0px',
	},
	recipientsInfo: {
		padding: '23px 0 17px 31px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',

		'& >div:nth-of-type(1)': {
			fontWeight: 'bold',
			fontSize: '1.7rem',
		},
		'& > div:nth-of-type(2)': {
			fontWeight: 600,
			fontSize: '1.3rem',
			marginBottom: '10px',
		},
		'& > div:nth-of-type(3)': {
			marginBottom: '16px',
		},
	},
	urgencyLevelContainer: {
		borderLeft: '1px solid rgba(205, 205, 205, 0.4)',
		padding: '23px 17px 0 45px',
	},
	receiverHeader: {
		color: '#FFFFFF',

		maxWidth: '1060px',
		width: '100%',
		height: '188px',
		background: '#0071C2',
		borderRadius: '10px',
		marginTop: '1.5%',

		display: 'flex',
		justifyContent: 'space-between',

		boxSizing: 'border-box',
	},
	contentHeaderSection: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',

		[breakpoints.down('sm')]: {
			'& > img': {
				width: '14vw',
				height: '7vh',
			},
		},
	},
	summeryTitle: {
		margin: '22px',

		fontFamily: 'Sora, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'bold',
		fontSize: '30px',

		color: '#46484A',

		[breakpoints.down('sm')]: {
			marginTop: '1%',
		},
	},

	contentBody: {
		border: '1px solid rgba(49, 49, 49, 0.2)',
		borderRadius: '10px',

		marginBottom: '2%',
		padding: '5%',

		[breakpoints.down('sm')]: {
			border: 'none',
			padding: '3%',
		},
	},
	contextTextWrapper: {
		fontFamily: 'Sora, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '1.3rem',

		marginTop: '42px',

		color: '#46484A',
		[breakpoints.down('sm')]: {
			fontSize: '1.1rem',
		},
	},
	textInput: {
		display: 'flex',
		justifyContent: 'space-between',
		wordBreak: 'break-word',
		width: '100%',
		minHeight: '80px',
		resize: 'none',
		fontSize: '1.3rem',
		fontFamily: 'Sora, sans-serif',
		margin: '12px 0 39px',
		padding: ({ isRtlDirection }) => '9px',
		outline: 'none',
		background: '#FFFFFF',
		border: '1.4px solid rgba(49, 49, 49, 0.152716)',
		boxSizing: 'border-box',
		boxShadow: ' inset 3px 3px 10px #E1F0FF',
		borderRadius: '14px',
		[breakpoints.down('sm')]: {
			minHeight: '18vw',
			fontSize: '1rem',
		},
	},
	editIcon: {
		wordBreak: 'keep-all',
		fontWeight: 'bold',
		cursor: 'pointer',
		fontSize: '0.85rem',
		display: 'flex',
		color: '#000',
	},
	textareaOutput: {
		fontSize: '1.3rem',
		width: '100%',
		outline: 'none',
		border: 'none',
		overflowWrap: 'break-word',
		resize: 'none'
	},
	desabled: {
		color: 'grey',
	},
	textWrapper: {
		fontWeight: 'bold',
		display: 'flex',
		alignItems: 'flex-start',
		marginTop: '10px',
		marginBottom: ({ margin }) => (margin ? '3%' : ''),

		'& > div': {
			marginLeft: ({ isRtlDirection }) => (isRtlDirection ? '0' : '1%'),
			marginRight: ({ isRtlDirection }) => (isRtlDirection ? '1%' : '0'),
		},
		[breakpoints.down('sm')]: {
			'& > img': {
				//	marginTop: ' 1.5%', //took it off if there is a reason put it back
			},

			fontWeight: 'normal',
			fontSize: '16px',

			'& > div': {
				fontWeight: 'normal',
			},
		},
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'space-evenly',
		[breakpoints.down('sm')]: {
			justifyContent: 'space-between',
		},
	},
	actionButton: {
		fontFamily: 'Sora, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '1.2rem',
		backgroundColor: 'transparent',
		maxWidth: '330px',
		width: '100%',
		height: '154px',
		background: 'transparent',
		boxSizing: 'border-box',
		cursor: 'pointer ',
		borderRadius: '14px',
		border: '2.8px solid rgba(49, 49, 49, 0.152716)',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[breakpoints.up('sm')]: {
			'&:hover': {
				border: '2.8px solid #0071C2',
			},
		},

		[breakpoints.down('sm')]: {
			fontSize: '1rem',
			textAlign: 'center',
			padding: 0,
			width: '46%',
			height: '90px',
			display: 'flex',
			justifyContent: 'center',
			'& > div, embed': {
				padding: '0 0.3%',
			},
		},
		'& > div, embed ,rect , svg': {
			cursor: 'pointer !important',
		},
	},
	buttonText: {
		padding: '2%',
		[breakpoints.down('sm')]: {
			height: '50%',
			marginTop: '6%',
		},
	},
	buttonIcon: {
		zIndex: -1,
		width: 'fit-content',
		height: '30%',
		cursor: 'pointer',
	},
	icon: {
		marginInlineStart: '0.4rem',
		fill: 'green',
		cursor: 'pointer',
	},

	eventDate: {
		fontFamily: 'Sora, sans-serif',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '20px',

		marginTop: '20px',

		color: '#46484A',

		[breakpoints.down('sm')]: {
			fontWeight: 'bold',
			fontSize: '1rem',
		},
	},

	date: {
		fontWeight: 'normal',
		[breakpoints.up('sm')]: {
			fontWeight: 'bold',
		},
	},
	closeAndSaveBtns: {
		fontSize: '1.3rem',
		backgroundColor: 'inherit',
		color: 'black'
	},
}))
