import PathChoice from 'components/Debriefing/PathChoice'
import { INITIAL_MESSAGE } from '../stepMapComponent'
import { EMPTY_STATE } from '../useTreeLogic'

export const RenderChosePath = ({ ...props }) => {
	const { debrief, updateDebrief } = props

	const content = (
		<PathChoice
			currentPath={debrief?.path}
			setData={updateDebrief}
			nextStep={INITIAL_MESSAGE}
		/>
	)
	const pageIcon = {
		src: 'rocketIcon',
		opacity: '',
	}

	return { ...EMPTY_STATE, content, pageIcon }
}
