import { useContext } from 'react'
import { ClickAwayListener, Box } from '@material-ui/core'

import useStyles from './styled'

import { AppDirectionContext } from 'context/languages-context'
import close from 'assets/closeIcon.svg'


type Props = {
    closeModal: () => void
    node: React.ReactNode
}
const AnalysisPopup = ({ closeModal, node }: Props) => {
    const { isRtlDirection } = useContext(AppDirectionContext)
    const classes = useStyles({ isRtlDirection })

    const closeAnalysisModal = () => closeModal()

    const closeIcon = <div><img
        src={close}
        className={classes.closeBtn}
        onClick={closeAnalysisModal}
    /></div>

    return (
        <ClickAwayListener onClickAway={closeAnalysisModal}>
            <Box className={classes.container}>

                {closeIcon}

                {node}
            </Box>
        </ClickAwayListener>
    )
}
export default AnalysisPopup
