import { ChangeEvent, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useStyles from '../styles'

import { create_company } from 'redux/company/company-actions'
import { companyActions } from 'redux/company/company-reducer'

import {
	formReducer,
	buildCompanyObject,
	initialFormState,
	mainInputsData,
} from '../formHelper'
import Button from '../../common/Button'
import Licenses from '../Licenses/Licenses'
import Contacts from '../Contacts/Contacts'
import SelectLanguageList from '../SelectLanguageList'

import xIcon from '../../../assets/xIcon.svg'
import { Contact } from 'interface/company/company-interface'
import { License } from 'enums/License/License.enum'
import { IStoreRootState } from '../../../interface/redux/index'

interface Props {
	error: string
	onClose: () => void
	onMsg: (onConfirm: any, msg: string) => void
}
const CreateCompany = ({ onClose, onMsg, error }: Props) => {
	const requestError = useSelector(
		(state: IStoreRootState) => state.requestError.error
	)
	const [formState, dispatchForm] = useReducer(formReducer, initialFormState)

	const dispatch = useDispatch()
	const classes = useStyles({})

	useEffect(() => {
		if (error) {
			console.log({ requestError })
			onMsg(false, requestError?.message)
			dispatchForm({ type: 'FORM_RESTART', value: '' })
			dispatch(companyActions.removeError())
		}
	}, [error])

	useEffect(() => {
		const createNewCompany = async () => {
			const newCompany = buildCompanyObject(formState)
			dispatch(create_company(newCompany))
			// onClose()
		}
		if (formState.isValid) {
			createNewCompany()
		}
	}, [formState.isValid])

	const onFormSubmit = async (e: any) => {
		e.preventDefault()
		dispatchForm({ type: 'FORM_VALIDATION', value: '' })
	}

	const handleSetProperties = (
		{ target: { value } }: ChangeEvent<HTMLInputElement>,
		name: string
	) =>
		dispatchForm({
			type: 'SET_PROPERTY',
			value: { name: name, value },
		})
	const handleSetLang = (lang: string) =>
		dispatchForm({ type: 'SET_LANGUAGE', value: lang })

	const handleSetContacts = (contacts: Contact[]) =>
		dispatchForm({ type: 'SET_CONTACTS', value: contacts })

	const handleSetLicense = (licenses: License[]) =>
		dispatchForm({ type: 'SET_LICENSES', value: licenses })

	return (
		<div>
			<form onSubmit={onFormSubmit}>
				<button
					className={`${classes.actionButton} ${classes.closeButton}`}
					onClick={onClose}
				>
					<img src={xIcon} />
				</button>

				<h2>New company</h2>

				<div className={`${classes.flexCenter} ${classes.mainDetails}`}>
					{mainInputsData.map(input => (
						<div className={classes.inputContainer} key={input.name}>
							<label htmlFor={input.id}>{input.title}</label>
							<input
								style={{
									border: formState.formErrors[input.name]
										? '2px solid #E55757'
										: '2px solid #A5A5A5',
								}}
								id={input.id}
								type={input.type}
								placeholder={input.title}
								onChange={e => handleSetProperties(e, input.name)}
							/>
							<span>
								{formState.formErrors[input.name] ? `invalid ${input.name}` : ''}
							</span>
						</div>
					))}

					<SelectLanguageList selectedLang={handleSetLang} />
				</div>

				<div className={classes.divider} />

				<h2>Contact</h2>

				<Contacts
					errors={formState.formErrors.contacts}
					onInputChange={handleSetContacts}
				/>

				<div className={classes.divider} />

				{<Licenses setValues={handleSetLicense} />}

				<div className={`${classes.flexCenter} ${classes.formButtonsContainer}`}>
					<Button onClick={onClose}>Cancel</Button>
					<Button type='submit'>Save</Button>
				</div>
			</form>
		</div>
	)
}
export default CreateCompany
