import { makeStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles<Theme>(({ breakpoints, palette }) => ({
	SingleChoiceAltWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: 'auto',
		justifyContent: 'center',
	},
	previousButton: {
		color: '#0071C2',
		width: '224px',
		border: '2.36px solid #949494',
		cursor: 'pointer',
		height: '54px',
		fontSize: '20.4px',
		textAlign: 'center',
		fontWeight: 600,
		borderColor: '#0071C2',
		borderRadius: '13.6px',
		backgroundColor: '#FFFFFF',
	},
	container: {
		width: '938px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
	},
	answerCardWrapper: {
		[breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'center',
		},
	},
	answerCard: {
		maxWidth: '469px',
		width: '100%',
		boxSizing: 'border-box',
		height: '98px',
		border: '2.8px solid rgba(49, 49, 49, 0.152716)',
		borderRadius: '14px',
		overflowWrap: 'break-word',
		cursor: 'pointer',
		backgroundColor: 'white',
		textAlign: 'start',
		display: 'flex',
		alignItems: 'center',
		padding: '0 18px 0 24px',
		color: palette.text.secondary,

		'&:hover': {
			borderColor: '#0071C2',
			border: '2.8px solid #0071C2',
			boxShadow: '5px 5px 10px rgb(0 113 194 / 19%)',
		},
		[breakpoints.down('md')]: {
			borderColor: 'rgba(49, 49, 49, 0.152716)',
			backgroundColor: 'transparent',
			'&:hover': {
				border: '1.4px solid rgba(49, 49, 49, 0.152716)',
				// boxShadow: '5px 5px 10px rgb(0 113 194 / 19%)',
			},
		},
	},
	wrapper: {
		width: '938px',
		height: '65vh',
		[breakpoints.up('md')]: {
			display: 'flex',
			alignItems: 'center',
		},

		[breakpoints.down('md')]: {
			width: '95%',
			height: 'auto',
			overflowY: 'auto',
			overflowX: 'hidden',
			padding: '1rem',

			'&::-webkit-scrollbar': {
				width: 0,
			},
		},
		[breakpoints.down('sm')]: {
			margin: 'auto',
			borderRadius: '5px',
			padding: 0,
		},
	},
}))
