import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { UserAnswer } from '../ActionButtons/index'
import { useDispatch, useSelector } from 'react-redux'

import { userSelector } from '../../../redux/user/user-selector'
import { botActions } from 'redux/bot/bot-reducer'
import { QuestionType } from 'interface/praise/praise-interface'
import IUser from '../../../interface/user/user-interface'
import {
	FeedbackOutput,
	PraiseOutput,
	BaseCompanyOutput,
} from '../../../interface/bot/index'
import { sentPraisesSelector } from 'redux/praise/praise-selector'
import { sentFeedbacksSelector } from 'redux/feedback/feedback-selector'
import { employeesSelector } from '../../../redux/employee/employee.selector'
import { get_employees } from '../../../redux/employee/employee-actions'
import { Roles } from 'enums/user/user-roles'
import { isNotCurrentUser } from 'utils/isNotCurrentUser'

const listCapacity = 21
type Props = {
	isPraise?: boolean
	type: QuestionType
	nextQuestion?: string
	setQuestion: React.Dispatch<React.SetStateAction<string | null>>
}

const useHandlers = ({ setQuestion, nextQuestion, type }: Props) => {
	const [listInitilaitzed, setListInitilaitzed] = useState(false)
	const [userText, setUserText] = useState('')
	const [answer, setAnswer] = useState<UserAnswer>({
		id: 'a',
		nextQuestion: nextQuestion!,
		type,
		participants: [],
	})

	const sentPraises = useSelector(sentPraisesSelector)
	const sentFeedbacks = useSelector(sentFeedbacksSelector)

	const dispatch = useDispatch()
	const currentConnectedUser = useSelector(userSelector)
	useEffect(() => {
		dispatch(get_employees({ company_id: currentConnectedUser.company_id! })) // get 1st chunck of 10 users then bring whatever user ask ( on search / on scroll)
	}, [])

	const users = useSelector(employeesSelector)

	const addParticipant = useCallback(
		(user: IUser) => {
			setAnswer(prev => ({ ...prev, participants: [...prev.participants!, user] }))
		},
		[answer]
	)
	const removeParticipant = useCallback(
		(id: string) => {
			const updatedParticipants = answer?.participants?.filter(
				participant => participant._id !== id
			)
			setAnswer(prev => ({ ...prev, participants: updatedParticipants }))
		},
		[answer]
	)

	const handleUsersListClick = useCallback(
		(user: IUser, userAnswer?: UserAnswer) => {
			if (type === QuestionType.FirstQuestion) {
				dispatch(botActions.removeAllAnswers())
				dispatch(botActions.addAnswer({ ...userAnswer!, recipient: user }))
				setQuestion(nextQuestion!)
				return
			}

			const participantsarrr = answer?.participants?.length
				? answer?.participants
				: []

			const chosedParticipantsId = participantsarrr.map(
				participant => participant._id
			)

			if (chosedParticipantsId.includes(user._id)) {
				removeParticipant(user._id)
				return
			}

			addParticipant(user)
		},
		[answer, setQuestion]
	)

	const handleSearchTextChange = ({
		target: { value },
	}: React.ChangeEvent<HTMLInputElement>) => {
		setUserText(value)
	}

	const usersList = useMemo(() => {
		let searchResults: IUser[] = []
		const shouldGetAllUsers = type !== QuestionType.FirstQuestion
		const activeUsers = users.filter(user => user.statusInfo.active)

		const usersData = {
			all: activeUsers,
			current: currentConnectedUser,
		}
		const filter = (filter: UserFilter, condition: boolean) => {
			if (condition)
				return removeDuplicates<IUser>(
					[...searchResults, ...filterUsers(usersData, filter)],
					'_id'
				)
			return []
		}
		if (!userText) {
			searchResults = filterUsers(usersData, UserFilter.SELF_DIRECT_MANAGER)
			const isManager =
				currentConnectedUser.statusInfo.role === Roles.HR ||
				currentConnectedUser.statusInfo.role === Roles.MANAGER
			const maxResults = searchResults.length < listCapacity
			searchResults = filter(UserFilter.MANAGER_EMPLOYEES, isManager)
			searchResults = filter(UserFilter.COLLEAGUES, maxResults)
			searchResults = filter(UserFilter.SUB_DEPARTMENT_MEMBERS, maxResults)
			searchResults = filter(UserFilter.DEPARTMENT_MEMBERS, maxResults)
			searchResults = filter(UserFilter.A_Z, maxResults)
		}

		if (userText.length > 1) {
			// Why not get results even one chracter entered
			searchResults = closestMatch([...users], userText)
		}
		return shouldGetAllUsers
			? searchResults
			: searchResults.filter(user => isNotCurrentUser(user, currentConnectedUser))
	}, [users, currentConnectedUser, userText, listCapacity, type])

	useEffect(() => {
		usersList.length && !listInitilaitzed && setListInitilaitzed(true)
	}, [usersList])

	return {
		listInitilaitzed,
		handleClick: handleUsersListClick,
		handleSearchTextChange,
		usersList: usersList.slice(0, listCapacity),
		answer,
	}
}

export default useHandlers

export const closestMatch = (employeesList: IUser[], searchkey: string) => {
	let matchedKeys = []

	for (let i = 0; i < employeesList.length; i++) {
		const name = getFullName(employeesList[i])

		if (name.match(searchkey.toLowerCase())) {
			matchedKeys.push(employeesList[i])
		}
	}
	return matchedKeys
}
const getFullName = ({ personalInfo: { firstName, lastName } }: IUser) =>
	firstName.toLowerCase() + ' ' + lastName.toLowerCase()

const filterUsers = (
	data: { all: IUser[]; current: IUser },
	filter: UserFilter
) => {
	let results = []
	if (filter === UserFilter.A_Z) {
		results = [...data.all]
			// .sort((a, b) =>
			// 	a.personalInfo.firstName > b.personalInfo.firstName ? 1 : -1
			// )
			.slice(0, listCapacity)
	} else {
		results = data.all.filter((user: IUser) => {
			let filterCondition = false
			switch (filter) {
				case UserFilter.SELF_DIRECT_MANAGER:
					filterCondition =
						user.securityInfo.email === data.current.statusInfo.directManager
					break
				case UserFilter.MANAGER_EMPLOYEES:
					filterCondition =
						user.statusInfo.directManager === data.current.securityInfo.email
					break
				case UserFilter.COLLEAGUES:
					filterCondition =
						user.statusInfo.directManager === data.current.statusInfo.directManager
					break
				case UserFilter.SUB_DEPARTMENT_MEMBERS:
					filterCondition =
						user.statusInfo.subDepartment === data.current.statusInfo.subDepartment
					break
				case UserFilter.DEPARTMENT_MEMBERS:
					filterCondition =
						user.statusInfo.department === data.current.statusInfo.department
					break

				default:
					return false
			}

			return filterCondition
		})
	}
	return [...results]
}

function removeDuplicates<T>(arr: T[], property: string): T[] {
	const uniqeValues: T[] = []
	const entitiy: any = {}
	arr.forEach((element: any) => {
		if (!entitiy[element[property]]) {
			entitiy[element[property]] = true
			uniqeValues.push(element)
		}
	})
	return uniqeValues
}
enum UserFilter {
	SELF_DIRECT_MANAGER,
	MANAGER_EMPLOYEES,
	COLLEAGUES,
	SUB_DEPARTMENT_MEMBERS,
	DEPARTMENT_MEMBERS,
	A_Z,
}

// type UserWithPermission = {
// 	user: IUser
// 	canWriteFeedback: boolean
// }
// export const addUserPermission = (
// 	user: IUser,
// 	praises: PraiseOutput<IUser>[] = [],
// 	feedbacks: FeedbackOutput<IUser>[] = []
// ): UserWithPermission => {
// 	const canWriteFeedback =
// 		praises.length >= 2 && praises.length > feedbacks.length * 2

// 	return {
// 		user,
// 		canWriteFeedback,
// 	}
// }
