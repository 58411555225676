import Box from '@mui/material/Box'

import sayDoLogo from 'assets/Logowithtext.svg'

interface Props {
	mr?: number
	ml?: number
	height?: string
	width?: string
}
const Logo = ({ mr = 4, ml = 3, height = '100%', width = '50%' }: Props) => {
	return (
		<Box
			component='div'
			sx={{
				mr,
				ml,
				display: 'flex',
			}}
		>
			<img
				src={sayDoLogo}
				style={{
					alignSelf: 'center',
					width,
					height,
				}}
				alt='sayDo logo'
			/>
		</Box>
	)
}
export default Logo
