import * as React from 'react'
import { useSelector } from 'react-redux'

import Dialog from '@mui/material/Dialog'
import { makeStyles, Theme, CircularProgress } from '@material-ui/core'

import TextArea from './TextArea'
import DatePicker from './DatePicker'
import ModalSingleChoice from './ModalSingleChoice/index'
import ActionsButton from '../ActionButtons'
import { isAdditionalPraiseQuestionLoading } from '../../../redux/praise/praise-selector'
import { isAdditionalFeedbackQuestionLoading } from '../../../redux/feedback/feedback-selector'
import ScrollableContent from 'components/common/ScrollableContent/ScrollableContent'

type Props = {
	isOpen: boolean
	next: string
	previous: string
	handleClose: () => void
	children: React.ReactNode
}

const BotModal = (props: Props): JSX.Element => {
	const { children, isOpen, handleClose } = props

	const isLoadingPraise = useSelector(isAdditionalPraiseQuestionLoading)
	const iisLoadingFeedback = useSelector(isAdditionalFeedbackQuestionLoading)

	const classes = useStyles()

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<Dialog open={isOpen} onClose={handleClose} className={classes.paper}>
					<>
						{isLoadingPraise || iisLoadingFeedback ? (
							<div
								style={{
									width: '50vw',
									height: '50vh',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<CircularProgress size={50} />
							</div>
						) : (
							<>
								<ScrollableContent content={children} />
								<ActionsButton
									setQuestion={() => {}}
									showPreviousButton
									onPrevClick={handleClose}
								/>
							</>
						)}
					</>
				</Dialog>
			</div>
		</div>
	)
}

BotModal.TextArea = TextArea
BotModal.DatePicker = DatePicker
BotModal.ModalSingleChoice = ModalSingleChoice

export default BotModal

const useStyles = makeStyles<Theme>(({ breakpoints }) => ({
	container: {
		width: '1182px',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',

		padding: '47px 0 37px',
	},
	nextButton: {
		height: '54px',
		width: '224px',
		borderRadius: '13.6px',
		borderColor: 'none',
		backgroundColor: '#0071C2',
		boxShadow: '14px 14px 48px 0 rgba(0,113,194,0.21)',
		cursor: 'pointer',

		marginLeft: '21px',

		color: '#FFFFFF',
		fontSize: '20.4px',
		textAlign: 'center',
	},
	previousButton: {
		height: '54px',
		width: '224px',
		border: '2.36px solid #949494',
		borderRadius: '13.6px',
		backgroundColor: '#FFFFFF',
		cursor: 'pointer',

		borderColor: '#0071C2',
		color: '#0071C2',
		fontSize: '20.4px',
		textAlign: 'center',
	},
	dialogOvedrider: {
		'& > .MuiDialog-paper': {
			backgroundColor: 'red',
		},
	},
	popupActions: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',

		marginTop: '28px',
	},
	paper: {
		'& >:nth-child(3) > :nth-child(1)  ': {
			maxWidth: '100%',
			borderRadius: '10px',
			padding: '39px 0 59px 0',

			[breakpoints.down('sm')]: {
				margin: 0,
				height: '100%',
			},
		},
	},

	root: {
		'& > .MuiPaper-root': {
			backgroundColor: 'red',
			borderRadius: '100px !important',
			boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.75) !important',
		},
	},
}))
