export enum ActionTypes {
	login = 'LOGIN',
	change_password = 'CHANGE_PASSWORD',
	set_email = 'SET_EMAIL',
	set_password = 'SET_PASSWORD',
	set_new_password = 'SET_NEW_PASSWORD',
	validate_form = 'VALIDATE_FORM',
}

export enum FormType {
	login = 'LOGIN',
	change_password = 'CHANGE_PASSWORD',
}
export type FormState = {
	isValid: boolean
	type: FormType | null
	email: string
	password: string
	newPassword: string
}
export type FormErrors = {
	email?: string
	password?: string
	newPassword?: string
}
export const reducer = (state: FormState, action: any) => {
	switch (action.type) {
		case ActionTypes.change_password: {
			return { ...state, type: FormType.change_password }
		}
		case ActionTypes.login: {
			return { ...state, type: FormType.login }
		}
		case ActionTypes.set_email: {
			return { ...state, email: action.email }
		}
		case ActionTypes.set_new_password: {
			return { ...state, newPassword: action.password }
		}

		case ActionTypes.set_password: {
			return { ...state, password: action.password }
		}

		case ActionTypes.validate_form: {
			const loginFormValidation = Boolean(
				state.email.trim() === '' || state.password.trim() === ''
			)
			const changePasswordFormValidation = Boolean(
				state.newPassword.trim() === '' ||
					state.password.trim() === '' ||
					state.password !== state.newPassword
			)

			const isDisabled =
				state.type === FormType.change_password
					? changePasswordFormValidation
					: loginFormValidation

			return { ...state, isValid: isDisabled }
		}

		default:
			return state
	}
}
