import { Page, Text, Document, StyleSheet, Font } from '@react-pdf/renderer'
import { Debrief, DebriefEvent } from 'interface/debrief/debrief-interface'
import { useTranslation } from 'react-i18next'

import FileHeader from './FileHeader'
import DebriefInfo from './DebriefInfo'
import { useMatchPointsList } from 'utils/useMatchPointsList'
import PdfMatchpoint from './FileMatchpoint'
import Rubik from '../../../../assets/fonts/Rubik.ttf'

Font.register({ family: 'Rubik', src: Rubik })

type Props = {
    debrief: Debrief | null
}
const ExportDebief = ({ debrief }: Props) => {
    const matchpoints = useMatchPointsList()

    const { achieved, description } = debrief?.expectedResult!
    const { t } = useTranslation()
    const subTitlePrefix = achieved ? t('achieved') : t('unachieved')
    const subTitle = `${subTitlePrefix} ${t('result')}: ’’${description}’’`

    const creatorName = debrief?.creator.personalInfo.firstName + ' ' + debrief?.creator.personalInfo.lastName

    return (
        <Document>
            <Page style={styles.body}>

                <FileHeader />

                <Text style={styles.title}>
                    {t('summary')}
                </Text>

                <Text style={styles.subTitle}>
                    {subTitle ? subTitle : ''}
                </Text>

                <DebriefInfo
                    code={debrief?.code}
                    createdAt={debrief?.createdAt}
                    creator={creatorName}
                />

                {matchpoints.map(({ description, summaries }: DebriefEvent, index: number) => (
                    <PdfMatchpoint key={index} description={description} summaries={summaries} num={index + 1} />
                ))}

                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    )
}
export default ExportDebief

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: 'Rubik'
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        margin: '15px',
        fontFamily: 'Rubik'
    },
    subTitle: {
        color: 'grey',
        wordBreak: 'break-all',
        display: 'flex',
        textAlign: 'center',
        fontFamily: 'Rubik'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
})