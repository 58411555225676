import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
type Style = {
	isHeb?: boolean
}
export default makeStyles<Theme, Style>(({ palette }) => ({
	container: {
		userSelect: 'none',
		boxSizing: 'border-box',
		width: '360px',
		position: 'absolute',
		top: '50px',
		right: ({ isHeb }) => (isHeb ? '' : '-110px'),
		left: ({ isHeb }) => (isHeb ? '-110px' : ''),
		zIndex: 3,
		color: palette.text.primary,
		background: palette.common.white,
		border: `1px solid ${palette.action.disabled}`,
		borderRadius: '7px',
		boxShadow: '0px 5px 47px 0px rgba(210,214,233,0.59)',
		// '& .infinite-scroll-component::-webkit-scrollbar': {
		// 	width: '5px',
		// },
		// '& .infinite-scroll-component::-webkit-scrollbar-thumb': {
		// 	backgroundColor: palette.action.disabled,
		// 	borderRadius: '2.5px',
		// },
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		height: '62px',
		fontWeight: 600,
		color: palette.common.white,
		background: palette.secondary.main,
		borderTopLeftRadius: '7px',
		borderTopRightRadius: '7px',
	},
	header: {
		fontSize: '14px',
		marginInlineStart: '12px',
		margin: '17px 0 12px',
	},
	headerNew: {
		fontWeight: 700,
	},
	hr: {
		width: '328px',
		borderTop: 0,
		borderColor: palette.action.disabled,
		margin: '25px auto',
	},
	loader: {
		textAlign: 'center',
		color: palette.text.primary,
	},
}))
