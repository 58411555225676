import { makeStyles, Theme } from '@material-ui/core'
import { IThemeOptions } from 'theme'
import UserAvatar from 'components/common/Avatar'
import IUser from 'interface/user/user-interface'

type Props = {
	creator: IUser | undefined
}

export const CreatorAvatar = ({ creator }: Props) => {
	const classes = useStyles()

	return (
		<div className={classes.creatorAvatar}>
			{creator && (
				<>
					<UserAvatar className={classes.creatorPhoto} userInfo={creator} />
					<span className={classes.creatorName}>
						{creator?.personalInfo.firstName + ' ' + creator?.personalInfo.lastName}
					</span>
				</>
			)}
		</div>
	)
}

const useStyles = makeStyles<IThemeOptions & Theme>(
	({ breakpoints, palette }) => ({
		creatorAvatar: {
			width: '100%',
			fontWeight: 500,
			height: 'auto',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		creatorName: {
			fontSize: '110%',
			fontWeight: 600,
			display: 'flex',
			flexWrap: 'wrap',
		},
		creatorPhoto: {
			marginInlineEnd: '10px',
		},
	})
)

export default CreatorAvatar
