import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		position: 'absolute',
		width: '100%',
		top: '-37px',
	},
	line: {
		height: '8px',
		borderRadius: '2.52px',
		background: 'rgba(166, 163, 198, 0.250275)',

		position: 'relative',
		marginBottom: '80px',
	},
	oval: {
		width: '81px',
		height: '81px',
		background: '#ECEBF3',
		borderRadius: '50%',
		cursor: 'pointer',

		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	selected: {
		width: '53px',
		height: '53px',
		borderRadius: '50%',
		background: '#0071C2',

		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		boxShadow: '0px 32px 73px rgba(0, 113, 194, 0.18651)',
	},
	ovalWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	ovalIndicator: {
		marginTop: '13px',
	},
})
