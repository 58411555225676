import { createAsyncThunk } from '@reduxjs/toolkit'
import { getTeamTasks, toggleTaskDone, updateTeamTasks, deleteTeamTask, createNewTask } from 'api/teams'
import { Req } from 'enums/req/req.enum'
import { ICreateTeamTask, ITeamTask, Team } from 'interface/teams/teams-interface'
import { IStoreRootState } from 'interface/redux'
import IUser from 'interface/user/user-interface'
import { TeamTodosEvents } from 'enums/teams/team-events.enum'

export type TaskUpdatingPayload<T = IUser> = {
    user_id: string
    team_id: string
    task: ITeamTask<T>
}
export type OneTaskUpdatePayload = Omit<TaskUpdatingPayload, 'task'> & { task_id: string }

export const get_team_tasks = createAsyncThunk(
    'team/getTeamTasks',
    async (payload: { order?: number, user_id: string }, { rejectWithValue, getState }) => {
        try {
            const { data, status } = await getTeamTasks(payload)
            if (status == Req.failed) throw new Error(data)

            const { employee } = getState() as IStoreRootState
            const dataToReturn = await getConvertedUsers(data, employee.employees)
            return { data: dataToReturn }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)
export const create_team_task = createAsyncThunk(
    'team/createTeamTask',
    async (payload: ICreateTeamTask, { rejectWithValue, getState }) => {
        try {
            const { data, status } = await createNewTask(payload)
            console.log({ data })
            const { employee } = getState() as IStoreRootState
            const dataToReturn = {
                ...data,
                employees_id: data.employees_id.map((e: string) => employee.employees.find(emp => emp._id === e)),
                shouldShow: data.shouldShow.map((e: string) => employee.employees.find(emp => emp._id === e)),
                isNew: data.isNew?.map((e: string) => employee.employees.find(emp => emp._id === e)),
                creator: employee.employees.find(emp => emp._id === data.creator)
            } as ITeamTask<IUser>

            return { data: dataToReturn, status }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)
export const update_team_tasks = createAsyncThunk(
    'team/updateTeamTasks',
    async (payload: TaskUpdatingPayload, { rejectWithValue, getState }) => {
        try {
            let body: ITeamTask<string> = convertUsersToString(payload.task) as ITeamTask<any>

            const { data, status } =
                await updateTeamTasks({ user_id: payload.user_id, team_id: payload.team_id, task: body })

            if (status == Req.failed) throw new Error(data)

            const { employee } = getState() as IStoreRootState

            console.log({ data })
            const dataToReturn = {
                ...data,
                employees_id: data.employees_id.map((e: string) => employee.employees.find(emp => emp._id === e)),
                shouldShow: data.shouldShow.map((e: string) => employee.employees.find(emp => emp._id === e)),
                isNew: data.isNew?.map((e: string) => employee.employees.find(emp => emp._id === e)),
                creator: employee.employees.find(emp => emp._id === data.creator)
            } as ITeamTask<IUser>
            console.log({ dataToReturn })
            return { data: dataToReturn, status }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const toggle_task_done = createAsyncThunk(
    'teams/toggleTaskDone',
    async (payload: OneTaskUpdatePayload, { rejectWithValue, getState }) => {
        try {
            const { data, status } = await toggleTaskDone(payload)
            if (status == Req.failed) throw new Error(data)

            const { employee: { employees } } = getState() as IStoreRootState

            const dataToReturn = {
                ...data,
                employees_id: data.employees_id.map((e: string) => employees.find(emp => emp._id === e)),
                shouldShow: data.shouldShow.map((e: string) => employees.find(emp => emp._id === e)),
                isNew: data.isNew?.map((e: string) => employees.find(emp => emp._id === e)),
                creator: employees.find(emp => emp._id === data.creator)
            } as ITeamTask<IUser>
            return { data: dataToReturn, status }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const delete_task = createAsyncThunk(
    'teams/deleteTeamTask',
    async (payload: OneTaskUpdatePayload, { rejectWithValue }) => {
        try {
            const { data, status } = await deleteTeamTask(payload)
            if (status == Req.failed) throw new Error(data)

            return { data, status }
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

const convertUsersToString = (task: ITeamTask<IUser>): ITeamTask<string> => {
    return {
        ...task,
        employees_id: mappingUsersIds(task.employees_id),
        shouldShow: mappingUsersIds(task.shouldShow),
        isNew: mappingUsersIds(task.isNew),
        creator: task.creator._id,
    } as ITeamTask<string>
}
const mappingUsersIds = (list?: IUser[]) => (list?.map(e => e?._id))

const getConvertedUsers = async (data: any, employees: IUser[]): Promise<Team<IUser>> => {
    const [convertStringsToUsers] = data.map((team: Team) => {
        const convertedTeam = {
            ...team,
            manager: employees.find(emp => emp._id === team.manager),
            members_id: team.members_id.map(member => employees.find(emp => emp._id == member)),
        }

        const tasksList: ITeamTask<IUser>[] = []
        team.tasks.map((task: ITeamTask<string>) => {
            const convertedTask = {
                ...task,
                employees_id: task.employees_id.map(e => employees.find(emp => emp._id === e)),
                shouldShow: task.shouldShow.map(e => employees.find(emp => emp._id === e)),
                isNew: task.isNew?.map(e => employees.find(emp => emp._id === e)),
                creator: employees.find(emp => emp._id === task.creator)
            } as ITeamTask<IUser>
            tasksList.push(convertedTask)
        })
        return { ...convertedTeam, tasks: tasksList }
    }) as Team<IUser>[]
    return convertStringsToUsers
}


