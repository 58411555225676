import { Popover } from '@material-ui/core'
import { FC } from 'react'
/** 
* @Popover component documentation see: https://mui.com/material-ui/api/popover
*/
type PopoverProps = {
    open: boolean
    anchorEl: Element | null | undefined
    close: () => void
    id?: string
}
const AppPopover: FC<PopoverProps> = props => {
    const { id, open, anchorEl, children, close } = props
    return (
        <Popover
            id={id}
            open={open && anchorEl !== null}
            anchorEl={anchorEl}
            onClose={close}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}

        >
            {children}
        </Popover>
    )
}

export default AppPopover