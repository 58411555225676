import { FC, useMemo, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Theme, Box, Button, Modal } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { IThemeOptions } from 'theme/index'

import { employeesSelector } from 'redux/employee/employee.selector'
import { userSelector } from 'redux/user/user-selector'
import { debriefOnProccesSelector } from 'redux/debrief/debrief-selector'
import { share_debrief } from 'redux/debrief/debrief-actions'
import { get_employees } from 'redux/employee/employee-actions'

import SearchRectangle from 'components/common/SearchRectangle'
import UserCard from 'components/Bot/UsersList/userCard'
import { Loader } from 'components/common/Loader'
import { UserAnswer } from '../../Bot/ActionButtons/index'

import IUser from 'interface/user/user-interface'
import { Debrief } from 'interface/debrief/debrief-interface'

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 2,
}
type UsersModalProps = {
	isOpen: boolean
	handleClickAway: () => void
}
const UsersModal: FC<UsersModalProps> = ({ handleClickAway, isOpen }) => {
	const [userInput, setUserInput] = useState<string>('')
	const [filteredUsersList, setFilteredUsersList] = useState<string[]>([])

	const classes = useStyles()
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const employees = useSelector(employeesSelector)
	const { company_id } = useSelector(userSelector)
	const debrief: Debrief | null =
		useSelector(debriefOnProccesSelector) ?? ({} as Debrief)

	const { creator = {}, sharedEmployess = [], participants } = debrief
	const { _id = '' } = creator as IUser

	const usersIds = useMemo(
		() => (sharedEmployess?.length ? sharedEmployess?.map(({ _id }) => _id) : []),
		[sharedEmployess]
	)

	const userMapParticipants = useMemo(() => {
		let userMp = new Map()
		participants.forEach(({ _id }) => {
			userMp.set(_id, _id)
		})
		return userMp
	}, [participants.length])

	const [selectedUsersIds, setSelectedUsersIds] =
		useState<Array<string>>(usersIds)

	useEffect(() => {
		dispatch(get_employees({ company_id }))
	}, [])

	useEffect(() => {
		if (!userInput) return setFilteredUsersList([])

		filterUsersList()
	}, [userInput])

	const users = useMemo((): IUser[] => {
		const _employees = employees.filter(e => !userMapParticipants.has(e._id))

		if (!filteredUsersList.length) {
			return _employees.filter(emp => emp._id !== _id)
		}

		return _employees.filter(
			emp => filteredUsersList.includes(emp._id) && emp._id !== _id
		)
	}, [employees, filteredUsersList, _id, userMapParticipants])

	const filterUsersList = useCallback(() => {
		let res: string[] = []
		for (let i = 0; i < users.length!; i++) {
			let userName = users[i]?.personalInfo?.firstName
			if (userName.toLowerCase().indexOf(userInput.toLowerCase()) !== -1) {
				res = [...res, users[i]._id]
			}
		}
		setFilteredUsersList(res)
	}, [userInput, users])

	const handleSelectedUser = (userInfo: IUser, UserAnswer: UserAnswer) => {
		if (!selectedUsersIds.includes(userInfo._id)) {
			setSelectedUsersIds(prevUsersIds => [...prevUsersIds, userInfo._id])
			return
		}

		setSelectedUsersIds(prevUsersIds =>
			prevUsersIds.filter(id => id !== userInfo._id)
		)
	}

	const handleClick = () => {
		const participantsList = users.filter(user =>
			selectedUsersIds.includes(user._id)
		)

		const payload = {
			_id: debrief._id,
			updatedSharedParticipants: participantsList,
			sharedEmployess: participantsList.map(p => p._id),
			creator: debrief.creator._id,
		}
		dispatch(share_debrief(payload))
		handleClickAway()
	}

	const availableUsersToShareList = useMemo(
		() => users.filter(({ _id }) => !selectedUsersIds?.includes(_id)),
		[users, selectedUsersIds]
	)
	const renderNoUsersError = (
		<>
			Could Not Display Company Users, <br />
			Can't Share At The Moment Please Try Later.
		</>
	)

	const renderHasNoUsersInUsersIdsOption = !availableUsersToShareList.length
		? 'You have no more users to share with!'
		: 'Please Click Share'

	const handleSearchTextChange = ({
		target: { value },
	}: React.ChangeEvent<HTMLInputElement>) => {
		setUserInput(value)
	}

	return (
		<Modal
			onClose={handleClickAway}
			open={isOpen}
			aria-labelledby='modal-modal-title'
			aria-describedby='modal-modal-description'
		>
			<Box sx={style}>
				<div className={classes.listModalStyle}>
					<SearchRectangle
						handleChange={handleSearchTextChange}
						className={classes.searchWrapper}
					/>
					{!users.length ? (
						renderNoUsersError
					) : (
						<>
							<div className={classes.usersListContainer}>
								{users.filter(u => !usersIds.includes(u._id)).length ? (
									users
										.filter(u => (!usersIds.includes(u._id) && u.statusInfo.active))
										.map(user => {
											const {
												securityInfo: { email },
											} = user

											const answer = {
												answer: '',
												id: user._id,
												nextQuestion: null,
												previousQuestion: null,
												type: '',
											} as any

											return (
												<UserCard
													key={email}
													answer={answer}
													user={user}
													isSelectedUser={selectedUsersIds.includes(user._id)}
													handleSelectedUser={handleSelectedUser}
												/>
											)
										})
								) : !!users.filter(u => !usersIds.includes(u._id)).length ? (
									<Loader size={55} height={'auto'} />
								) : (
									renderHasNoUsersInUsersIdsOption
								)}
							</div>
							<div className={classes.sendButton}>
								<Button disabled={!selectedUsersIds.length} onClick={handleClick}>
									{t('okay')}
								</Button>
							</div>
						</>
					)}
				</div>
			</Box>
		</Modal>
	)
}
export default UsersModal
const useStyles = makeStyles<IThemeOptions & Theme>(({ breakpoints }) => ({
	refreshButton: {
		margin: '1% auto 1% auto',
	},
	valuesBar: {
		marginTop: '1%',
	},
	info: {
		[breakpoints.down('sm')]: {
			display: 'flex',
			height: 'auto',
			borderRadius: '10px',
			padding: '2%',
			margin: '2% auto 2% auto',
			backgroundColor: 'rgb(166,163,198,0.1)',
			flexDirection: 'column',
			width: '90vw',
		},
	},
	infoBarData: {
		margin: '0 1% 0 1%',
		[breakpoints.down('sm')]: {
			margin: '2% 1% 2% 1%',
		},
	},
	infoBarContent: {
		fontWeight: 600,
	},
	infoBar: {
		color: 'rgba(70, 72, 74, 1)',
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		[breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	container: {
		width: '95vw',
		display: 'flex',
		flexDirection: 'column',
		[breakpoints.down('sm')]: {
			alignItems: 'center',
			margin: 'auto',
			flexDirection: 'column',
		},
	},
	borderTop: {
		borderTop: '2px solid rgba(224, 224, 224, 1)',
	},
	matchpointsContainer: {
		paddingTop: '1%',
		marginTop: '1%',
		height: '100%',
		width: '100%',
		display: 'flex',
		[breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	listModalStyle: {
		width: '100%',
		height: '100%',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	} as any,
	usersListContainer: {
		overflowY: 'auto',
		height: '336.15px',
		paddingInlineStart: '4px',
		margin: '5% 0',
		maxWidth: '469px',
		width: '100%',
		padding: '1.5%',

		[breakpoints.down('sm')]: {
			padding: 0,
			paddingInlineStart: 0,
			width: '95%',
			height: '100%',
			'&::-webkit-scrollbar': {
				width: 0,
			},
		},
	},
	sendButton: {
		display: 'flex',
		alignSelf: 'center',
		marginTop: '1%',

		'& >button': {
			cursor: 'pointer',
			fontSize: '1rem',
		},
	},
}))
