import { BaseCompanyOutput } from "interface/bot";
import IUser from "interface/user/user-interface";
import { TabsData } from "./outputTabsDataMap";


export default function <T extends TabsData<BaseCompanyOutput<IUser>>>(users: T[], userText: string): T[] {
    let res: T[] = []
    for (let i = 0; i < users.length; i++) {
        let userName = users[i].name
        if (userName.toLowerCase().indexOf(userText.toLowerCase()) !== -1) {
            res = [...res, users[i]]
        }
    }
    return res;
}

export function filterUsers(users: IUser[], userText: string): IUser[] | undefined {
    let res: IUser[] = []
    for (let i = 0; i < users.length; i++) {
        let userName = users[i].personalInfo.firstName
        if (userName.toLowerCase().indexOf(userText.toLowerCase()) !== -1) {
            res = [...res, users[i]]
        }
    }
    return res;
}
