import React, { FC } from 'react'

import './style.css'

import { ItemsSectionType } from '../props'
import Item from '../item/index'

import TitleInputSection from './TitleInput'

const ItemsSections: FC<ItemsSectionType> = ({
    sections,
    isFolder,
    showEmptyLesson,
    itemButtonEvents
}) => {
    const {
        deleteFolder,
        editFolder,
        insertNewLesson,
        lessonDescription,
        navigate,
        deleteLesson,
        toggleEmptyLesson,
        updateLesson,
        updateTitle
    } = itemButtonEvents

    let sectionStyle = 'section'
    if (!isFolder) sectionStyle += ' lessons-section'

    return (
        <>
            {showEmptyLesson &&
                <Item
                    isFolder={false}
                    insertNewLesson={insertNewLesson}
                    toggleEmptyLesson={toggleEmptyLesson}
                    handleLessonDescription={lessonDescription}
                />}

            {sections?.map(section => (
                <React.Fragment key={section.label}>

                    <TitleInputSection section={section} updateTitle={updateTitle} />

                    <div className={sectionStyle}>
                        {section.data.map(next => (

                            <Item
                                key={next._id}
                                sectionData={next}
                                isFolder={isFolder}
                                handleLessonDescription={lessonDescription}
                                updateLesson={updateLesson}
                                insertNewLesson={insertNewLesson}
                                deleteLesson={deleteLesson}
                                deleteFolder={deleteFolder}
                                editFolder={editFolder}
                                navigate={navigate}
                            />
                        ))}
                    </div>

                </React.Fragment>
            ))}
        </>
    )
}

export default ItemsSections