import { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Theme } from '@material-ui/core'
import { IThemeOptions } from 'theme'

import { useStyles } from './useStyles'

import { AppDirectionContext } from 'context/languages-context'

import {
	create_debrief_event,
	update_debrief_event,
} from 'redux/debrief/debrief-actions'
import {
	debriefEventOnEditSelector,
	debriefLoadingSelector,
} from 'redux/debrief/debrief-selector'
import { debriefActions } from 'redux/debrief/debrief-reducer'

import useEventList from './useEventList'
import EventInput from './EventInput'
import DraggableList from 'components/common/DraggableList/List'
import { useWindowWidth } from 'components/common/Header/useHooks/useWindowWidth'
import { Loader } from 'components/common/Loader'

import { uniqeId } from 'utils/helpers'

import { DebriefingSteps } from 'enums/debriefing/debriefing-steps.enum'
import {
	Debrief,
	DebriefEventDTO,
	DebriefEvent,
} from 'interface/debrief/debrief-interface'

interface Props {
	onMatchPointChoice: boolean
	debrief: Debrief
	setData?: (data: Partial<Debrief>) => void
	isNextDisabled: boolean
	setDisableNext: (status: boolean) => void
	updateData?: (data: Partial<Debrief>, step: DebriefingSteps) => void
	wrapperRef?: React.RefObject<HTMLDivElement>
	hideCalendar?: boolean
}
export const getInitialEvent = (): DebriefEvent => {
	//get initial event for the input
	return {
		_id: '',
		description: '',
		date: undefined,
		isMatchPoint: false,
		// company_id: ''
	}
}

export const EventsList = ({
	debrief,
	onMatchPointChoice,
	setData,
	setDisableNext,
	isNextDisabled,
	updateData,
	wrapperRef,
	hideCalendar,
}: Props) => {
	const dispatch = useDispatch()
	const { isRtlDirection } = useContext(AppDirectionContext)

	const classes = useStyles({
		canSkipToMatchpointChoice:
			debrief?.events && !!debrief?.events.length && debrief?.events.length < 4,
	})
	//const innerClasses = useInnerStyles()

	const [showInput, setShowInput] = useState<boolean>(true) //event input state.
	//const [showMainButton, setShowMainButton] = useState<boolean>(true) //add/update button visiblity state.
	const [currentEvent, setCurrentEvent] = useState<DebriefEvent>( //current event on update / new event.
		getInitialEvent()
	)

	const debriefEventOnEditId = useSelector(debriefEventOnEditSelector) //debrief id on edit - if no id (false) no update/edit features.
	const loading = useSelector(debriefLoadingSelector) // loading state of the debrief on process.

	const { items, onDragEnd } = useEventList({
		//providing logic hook props it needs
		onMatchPointChoice,
		events: debrief?.events,
		debriefId: debrief?._id,
		companyId: debrief?.creator.company_id,
		isNextDisabled,

		setData: setData,
		setDisableNext: setDisableNext,
	})

	const payloadData = {
		// payload for update / create actions.
		company_id: debrief?.creator.company_id,
		debrief_id: debrief?._id,
		event: { ...currentEvent, description: currentEvent.description.trim() },
		extraDispatchPayload: {},
	}

	useEffect(() => {
		// when debrief id on edit changes, set it as the current event and fill input.
		if (debriefEventOnEditId) {
			const eventOnEdit: DebriefEvent = debrief?.events.filter(
				event => event._id === debriefEventOnEditId
			)[0]
			setCurrentEvent(eventOnEdit)
			!showInput && setShowInput(true)
		}
	}, [debriefEventOnEditId])

	const storeCurrentEvent = (value: string | Date, property: string) => {
		//update the component current event (via input)
		setCurrentEvent(prev => {
			return {
				...prev,
				_id: debriefEventOnEditId,
				[property]: value,
			} as DebriefEvent
		})
	}

	const sendData = () => {
		// happens on click main button (up to the state- update/create action)
		if (!payloadData.event.description) return
		debriefEventOnEditId ? updateEvent() : createEvent()
	}
	const onCloseInputHandler = () => {
		// clear current event state on close action.
		debriefEventOnEditId && dispatch(debriefActions.clearDebriefEventOnEdit())
		setCurrentEvent(getInitialEvent())
		setShowInput(false)
	}
	const updateEvent = () => {
		//dispacth of update
		console.log({payloadData})
		dispatch(update_debrief_event(payloadData))
		onCloseInputHandler()
		return
	}

	const windowWidth = useWindowWidth()
	const mobileWidth = 600
	const isMobile = mobileWidth >= windowWidth

	useLayoutEffect(() => {
		if (loading || isMobile) return
		if (wrapperRef) {
			wrapperRef.current?.scrollTo({ top: wrapperRef.current.scrollHeight })
		}
	}, [loading, isMobile])

	const createEvent = () => {
		//dispacth of create
		dispatch(create_debrief_event(payloadData))
		onCloseInputHandler()
		return
	}

	return (
		<div
			style={{ direction: isRtlDirection ? 'rtl' : 'ltr' }}
			className={classes.container}
		>
			<div className={classes.list}>
				{!!items.length && ( //draggable/static events list
					<DraggableList
						isSelectionList={onMatchPointChoice}
						items={items}
						onDragEnd={onDragEnd}
					/>
				)}
			</div>
			{loading ? (
				<div style={{ padding: '10%' }}>
					<Loader size={70} height={'100%'} />
				</div>
			) : (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginBottom: isMobile ? '10%' : '20%',
						marginTop: isMobile ? '5%' : '0',
						paddingRight: '16px',
						paddingLeft: '16px',
						direction: 'ltr',
					}}
				>
					{showInput &&
						!onMatchPointChoice && ( //event input
							<EventInput
								hideCalendar={hideCalendar}
								event={currentEvent}
								onClose={onCloseInputHandler}
								onSave={sendData}
								onUpdateEvent={storeCurrentEvent}
							/>
						)}

					{!showInput &&
						!onMatchPointChoice && ( //show the event input
							<EventInput
								isFocus
								event={currentEvent}
								onClose={onCloseInputHandler}
								onSave={sendData}
								onUpdateEvent={storeCurrentEvent}
							/>
							// <div onClick={() => setShowInput(true)} className={classes.button}>
							// 	{t('debrief.add_event')}
							// </div>
						)}
				</div>
			)}
		</div>
	)
}

export default EventsList
const useInnerStyles = makeStyles<Theme & IThemeOptions>(({ palette }) => ({
	button: {
		borderRadius: '10px',
		backgroundColor: palette.debrief.main,
		padding: '1%',
		marginTop: '8px',
		marginBottom: '8px',
		border: '1px solid #D8D8D8',
	},
	buttonContent: {
		color: 'white',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '0.5rem',
		height: '2rem',
		width: '2rem',
		fontSize: '80%',
	},
}))
