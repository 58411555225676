import { FC } from 'react'
import Grid from '@material-ui/core/Grid'
import Add from '@material-ui/icons/Add'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Paths from 'enums/routes/path-names'
import { mapUrl, ActionNameMapScreens } from 'utils/mapUrl'

interface Props {
	actionName: ActionNameMapScreens
	screenToReturn: string
}

const SendCard: FC<Props> = props => {
	const { actionName, screenToReturn } = props
	const classes = useStyles({ screen: screenToReturn })

	return (
		<Grid item sm={12} md={3} className={classes.container} id={actionName}>
			<Card {...props} />
		</Grid>
	)
}

export default SendCard

export const Card: FC<Props> = ({ actionName, screenToReturn }) => {
	const { t } = useTranslation()
	const history = useHistory()

	const isPraise = actionName === ActionNameMapScreens.PRAISE
	const isFeedback = actionName === ActionNameMapScreens.FEEDBACK
	const isDebrief = actionName === ActionNameMapScreens.DEBRIEF

	const classes = useStyles({
		screen: screenToReturn,
		isPraise,
	})

	const handleClick = async () => {
		if (screenToReturn === Paths.DEBRIEF_PROCESS) {
			history.push(screenToReturn)
			return
		}

		const url = mapUrl(actionName)

		history.push(`${Paths.BOT}/${url}`, {
			screenToReturn,
		})
	}
	const displayMessage = isFeedback || isPraise || isDebrief

	const projectMessage = displayMessage ? (
		<StartConversationMessage classes={classes} isDebrief={isDebrief} />
	) : (
		<Add className={classes.addIcon} />
	)

	return (
		<div onClick={handleClick} className={classes.actionWrapper}>
			{projectMessage}
			<Typography className={classes.sendFeedbackText}>{t(actionName)}</Typography>
		</div>
	)
}

const StartConversationMessage: FC<any> = ({ classes = {}, isDebrief = false }) => {
	const { t } = useTranslation()
	const label = isDebrief ? 'start_learn' : 'start_converstation'

	return <div className={classes.addIcon}>{t(label)}</div>
}
