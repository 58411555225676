const STAGING = 'https://saydo-preprod.azurewebsites.net/'
const PRODUCTION = 'https://saydobackend.azurefd.net/'
const LOCAL_DEV = 'http://localhost:3001/'
const BACK_UP_SERVER = 'https://saydo.uksouth.cloudapp.azure.com/'

// const baseUrl = {
// 	production: PRODUCTION,
// 	staging: PRODUCTION,
// 	development: PRODUCTION,
// }
// const baseUrl = {
// 	production: BACK_UP_SERVER,
// 	staging: BACK_UP_SERVER,
// 	development: BACK_UP_SERVER,
// }

const baseUrl = {
	production: STAGING,
	staging: STAGING,
	development: STAGING,
}

// const baseUrl = {
// 	production: LOCAL_DEV,
// 	staging: LOCAL_DEV,
// 	development: LOCAL_DEV,
// }

export default baseUrl
