import moment from "moment"

export const getDaysDiff = (
    start_date: string,
    end_date: string,
    date_format = 'YYYY-MM-DD'
): number => {
    const getDateAsArray = (date: string) => {
        console.log({ date })
        return moment(date.split(/\D+/), date_format)
    }
    return getDateAsArray(end_date).diff(getDateAsArray(start_date), 'days') + 1
}