import i18next from 'i18next'
import { en, he } from 'i18n/languages'
import { initReactI18next } from 'react-i18next'

import { Langs } from 'enums/app'

export const inisializeI18n = (lang: string) => {
	i18next.use(initReactI18next).init({
		lng: lang, // if you're using a language detector, do not define the lng option
		fallbackLng: Langs.en,
		debug: false,
		resources: {
			en: {
				translation: en,
			},
			he: {
				translation: he,
			},
		},
	})
}
