import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core/styles'

const useStyle = makeStyles<Theme>(({ breakpoints }) => ({
	container: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-evenly',
		marginTop: '10%',
		
		[breakpoints.between('sm', 'md')]: {
			margin: 0,
		},

		[breakpoints.down('sm')]: {
			marginTop: 0,
			alignItems: 'center',
			height: '19%',
		},
	},
	waitingParticipantWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		justifyContent: 'space-around',
		height: '100%',
	},
	nextButton: {
		height: '54px',
		maxWidth: '224px',
		width: '100%',
		borderRadius: '13.6px',
		borderColor: 'none',
		backgroundColor: '#0071C2',
		cursor: 'pointer',

		// marginLeft: '21px',

		border: 0,
		color: '#FFFFFF',
		fontSize: '20.4px',
		textAlign: 'center',

		'&:hover': {
			backgroundColor: '#0062A8',
		},
		'&:disabled': {
			backgroundColor: '#CECECE',
		},
		[breakpoints.down('sm')]: {
			width: '35%',
		},
	},
	previousButton: {
		height: '54px',
		maxWidth: '224px',
		width: '100%',
		border: '2.36px solid #1C73C9',
		borderRadius: '13.6px',
		backgroundColor: '#FFFFFF',
		cursor: 'pointer',
		// marginLeft: '14px',
		color: '#0071C2',
		fontSize: '20.4px',
		textAlign: 'center',
		fontWeight: 'bold',
		boxShadow: '3px',
		[breakpoints.down('sm')]: {
			width: '35%',
		},
		'&:hover': {
			backgroundColor: '#F3F9FF',
		},
	},
}))

export default useStyle
