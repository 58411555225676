export function debounceSearch(func: any, timeout: number = 100) {
	let timer: NodeJS.Timeout

	return (...args: any[]) => {
		clearTimeout(timer)
		timer = setTimeout(() => {
			// @ts-ignore: Unreachable code error
			func.apply(this, args)
		}, timeout)
	}
}
