import {
	ChangeEvent,
	useEffect,
	useState,
	SetStateAction,
	useCallback,
	useMemo,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
	sentPraisesSelector,
	receivedPraisesSelector,
	draftsPraisesSelector,
	searchPraisesSelector,
} from 'redux/praise/praise-selector'
import { userSelector } from 'redux/user/user-selector'

import {
	fetch_sent_company_praises_byIds,
	fetch_praises_drafts,
	get_praises,
	load_more_praises,
	fetch_received_company_praises_byIds,
} from 'redux/praise/praise-actions'
import { preservUserInputSelector } from 'redux/user/user-selector'
import { userActions } from 'redux/user/user-reducer'

import filterTab from 'utils/filterTab'
import sortTab from 'utils/sortTab'
import {
	buildReplacment,
	getListType,
	getReplacementObj,
	mapOutputContext,
} from 'utils/outputs/helpers'
import { outputTabsDataMap, TabsData, TabType } from 'utils/outputTabsDataMap'
import { debounceSearch } from 'utils/debounceSearch'

import { useTabsTemplates } from '../Feedbacks/useTabsTemplates'
import { Type } from 'screens/BotOutput'
import { OutputType } from 'screens/BotOutput'

import { PraiseAndFeedbacksType } from 'enums/praise-state/praise-state.enum'
import { PraiseOutput } from 'interface/bot'
import { Langs } from 'enums/app/index'
import IUser from 'interface/user/user-interface'
import useOutputFilterRule from 'utils/useOutputFilterRule'
import { closestMatch } from 'components/Bot/UsersList/useHandlers'
import { employeesSelector } from 'redux/employee/employee.selector'
import { isNotCurrentUser } from 'utils/isNotCurrentUser'

const INCREMENT_BY_ONE = 1
// Function Declarations

interface Props {
	state: any
}
export const useLogic = (location: Props) => {
	const cahchedTab = location?.state?.tabState ?? 0
	const [shouldDisplayFilters, setShouldDisplayFilters] =
		useState<boolean>(false)
	const [currentSentPraisesPage, setCurrentSentPraisesPage] = useState(0)
	const [currentReceivedPraisesPage, setCurrentReceivedPraisesPage] = useState(0)
	const [currentDraftsPraisesPage, setCurrentDraftsPraisesPage] = useState(0)
	const [tabNumber, setCurrentTab] = useState(cahchedTab)
	const [sentFilteredArray, setSentFilteredArray] = useState<
		TabsData<PraiseOutput<IUser>>[]
	>([])
	const [receivedFilteredArray, setReceivedFilteredArray] = useState<
		TabsData<PraiseOutput<IUser>>[]
	>([])

	const sentPraises = useSelector(sentPraisesSelector)
	const receivedPraises = useSelector(receivedPraisesSelector)
	const praisesDrafts = useSelector(draftsPraisesSelector)
	const searchedPraises = useSelector(searchPraisesSelector)
	const currentUser = useSelector(userSelector)
	const userInput = useSelector(preservUserInputSelector)
	const employees = useSelector(employeesSelector)
	const { selectedEmployeeName } = useOutputFilterRule()

	const dispatch = useDispatch()
	const { t } = useTranslation()

	const { tabsTemplate } = useTabsTemplates(Type.Praise)
	const { _id: userId, company_id: companyId } = currentUser

	const showFilters = () => setShouldDisplayFilters(true)
	const disableFilter = () => setShouldDisplayFilters(false)
	const handleChangeTab = (newValue: SetStateAction<any>) => {
		setCurrentTab(newValue)
	}
	const dispatchMorePraises = (payload: any) =>
		dispatch(load_more_praises(payload))
	const getNextPage = useCallback(
		(type: OutputType) => {
			const output = {
				[OutputType.PraiseViewing]: currentSentPraisesPage,
				[OutputType.PraiseReciever]: currentReceivedPraisesPage,
				[OutputType.PraiseDraft]: currentDraftsPraisesPage,
			} as Record<OutputType, number>
			const nextPageNumber = output[type] + INCREMENT_BY_ONE

			if (type == OutputType.PraiseViewing) {
				setCurrentSentPraisesPage(nextPageNumber)
			}
			if (type == OutputType.PraiseReciever) {
				setCurrentReceivedPraisesPage(nextPageNumber)
			}
			if (type == OutputType.PraiseDraft) {
				setCurrentDraftsPraisesPage(nextPageNumber)
			}

			return nextPageNumber
		},
		[currentSentPraisesPage, currentReceivedPraisesPage, currentDraftsPraisesPage]
	)

	const nextPage = (type: OutputType) => {
		const payload = {
			company_id: companyId,
			user_id: userId,
			pageNumber: getNextPage(type),
			type: getListType(type),
		}
		dispatchMorePraises(payload)
	}
	function handleSearch(searchText: string) {
		// Perform your search logic here with the searchText
		console.log({ searchText })
		fetchSearchPraiseByTabs(searchText)
	}

	const debouncedSearch = debounceSearch(handleSearch, 500)

	const handleSearchTextChange = (
		{ target: { value } }: ChangeEvent<HTMLInputElement>,
		tabNumber: any
	) => {
		showFilters()
		dispatch(userActions.filterOutputsByEmployee(''))
		dispatch(userActions.saveInputUser(value))
	}
	const memoizedEmployees = useMemo(
		() => employees.filter(e => isNotCurrentUser(e, currentUser)),
		[employees]
	)
	const searchSentPraises = (value: string) => {
		dispatch(
			fetch_sent_company_praises_byIds({
				ids: closestMatch(memoizedEmployees, value).map(e => e._id),
			})
		)
	}
	const searchReceivedPraises = (value: string) => {
		dispatch(
			fetch_received_company_praises_byIds({
				ids: closestMatch(memoizedEmployees, value).map(e => e._id),
			})
		)
	}
	const fetchSearchPraiseByTabs = useCallback(
		(value: string) => {
			if (tabNumber) {
				searchSentPraises(value)
				return
			}
			searchReceivedPraises(value)
		},
		[tabNumber]
	)

	useEffect(() => {
		if (!currentUser?._id) return

		const payload = {
			company_id: companyId,
			user_id: userId,
		}
		// dispatch(fetch_praises_drafts(payload))
		dispatch(get_praises(payload))
	}, [currentUser?._id])

	const connectivityWord = t('and')
	const isEnglish = currentUser?.statusInfo?.companyLanguage === Langs.en

	const lastSentReplacements = buildReplacment(
		getReplacementObj(sentPraises)!,
		false,
		isEnglish,
		connectivityWord
	)

	const lastReceivedReplacements = buildReplacment(
		getReplacementObj(receivedPraises)!,
		true,
		isEnglish,
		connectivityWord
	)

	const lastReceivedContext = mapOutputContext(
		true,
		lastReceivedReplacements,
		receivedPraises[0],
		true
	)
	const lastSentContext = mapOutputContext(
		true,
		lastSentReplacements,
		sentPraises[0],
		false
	)
	console.log({ searchedPraises })
	const searchedPraisesMapped = useMemo(
		() =>
			outputTabsDataMap(
				tabNumber ? TabType.sent : TabType.receive,
				[...searchedPraises].sort(sortTab),
				tabsTemplate,
				connectivityWord
			),
		[tabNumber, searchedPraises.length]
	)

	const sentPraisesMapped = useMemo(
		() =>
			outputTabsDataMap(
				TabType.sent,
				[...sentPraises].sort(sortTab),
				tabsTemplate,
				connectivityWord
			),
		[tabNumber, sentPraises]
	)

	const receivedPraisesMapped = useMemo(
		() =>
			outputTabsDataMap(
				TabType.receive,
				[...receivedPraises].sort(sortTab),
				tabsTemplate,
				connectivityWord
			),
		[tabNumber, receivedPraises]
	)

	const draftsPraisesMapped = useMemo(() => {
		const drafts = outputTabsDataMap(
			TabType.draft,
			[...praisesDrafts].sort(sortTab),
			tabsTemplate,
			connectivityWord
		)

		return drafts
	}, [praisesDrafts.length])
	const resetTabsData = () => disableFilter()
	const filterSearchedPraisesByTabs = () => {
		if (!searchedPraisesMapped.length) return

		if (tabNumber) {
			setSentFilteredArray(searchedPraisesMapped)
			return
		}

		setReceivedFilteredArray(searchedPraisesMapped)
	}

	const filterSentPraises = () => {
		if (!selectedEmployeeName) return
		const filteredOutputsByFirstName = filterTab(
			sentPraisesMapped,
			selectedEmployeeName!
		)
		if (!filteredOutputsByFirstName.length) setSentFilteredArray([])
		setSentFilteredArray(filteredOutputsByFirstName)
	}

	const filterReceivedPraises = () => {
		if (!selectedEmployeeName) return
		const filteredOutputsByFirstName = filterTab(
			receivedPraisesMapped,
			selectedEmployeeName!
		)
		if (!filteredOutputsByFirstName.length) setReceivedFilteredArray([])
		setReceivedFilteredArray(p => filteredOutputsByFirstName)
	}

	const filterPraisesByTabs = () => {
		if (tabNumber) {
			filterSentPraises()
			return
		}
		filterReceivedPraises()
	}

	useEffect(() => {
		if (!userInput) {
			resetTabsData()
			return
		}

		showFilters()
		debouncedSearch(userInput)
	}, [userInput, tabNumber])

	useEffect(() => {
		if (!searchedPraisesMapped.length) return disableFilter()

		filterSearchedPraisesByTabs()
	}, [searchedPraisesMapped.length, tabNumber])

	useEffect(() => {
		const isWorkMeeting = !!selectedEmployeeName?.length
		if (!isWorkMeeting) return

		showFilters()
		filterPraisesByTabs()

		return () => {
			dispatch(userActions.filterOutputsByEmployee(''))
		}
	}, [tabNumber, selectedEmployeeName, sentPraisesMapped, receivedPraisesMapped])

	const hasReceievedPraises = !!receivedPraises.length
	const hasSentPraises = !!sentPraises.length

	const lastSent = {
		...lastSentContext,
		_id: hasSentPraises ? sentPraises[0]._id : '',
		CONTEXT: hasSentPraises
			? `${sentPraisesMapped[0]?.description}`
			: t('lastSentPraiseInitial'),
		name: hasSentPraises ? sentPraises[0].receiver.personalInfo?.firstName : '',
		date: hasSentPraises ? String(sentPraisesMapped[0]?.date) : '',
	}

	const lastReceived = {
		...lastReceivedContext,
		_id: hasReceievedPraises ? receivedPraises[0]._id : '',
		CONTEXT: hasReceievedPraises
			? `${receivedPraisesMapped[0]?.description}`
			: t('lastReceivedInitial'),
		name: hasReceievedPraises
			? receivedPraises[0]?.sender.personalInfo?.firstName
			: '',
		date: hasReceievedPraises ? String(receivedPraisesMapped[0]?.date) : '',
	}

	return {
		company_id: currentUser.company_id,
		currentTab: tabNumber,
		handleChangeCurrentTab: handleChangeTab,
		handleSearchTextChange,
		lastReceived,
		lastSent,
		receivedPraisesMapped: shouldDisplayFilters
			? receivedFilteredArray
			: receivedPraisesMapped,
		sentPraisesMapped: shouldDisplayFilters
			? sentFilteredArray
			: sentPraisesMapped,
		draftsPraisesMapped,
		nextPage,
	}
}
