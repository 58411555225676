import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Lesson, Summary } from 'interface/debrief/debrief-interface'
import {
	update_matchpoint_lesson,
	delete_matchpoint_lesson,
	create_matchpoint_lesson,
} from 'redux/debrief/debrief-actions'
import { userSelector } from 'redux/user/user-selector'
import { debriefOnProccesSelector } from '../../../redux/debrief/debrief-selector'

export const useLessonsLogic = (summary: Partial<Summary>) => {
	const { company_id } = useSelector(userSelector)
	const dispatch = useDispatch()
	const debrief = useSelector(debriefOnProccesSelector)

	const updateLesson = useCallback(
		(lesson: Lesson) => {
			if (!summary?.event_id) return

			dispatch(
				update_matchpoint_lesson({
					company_id: company_id!,
					event_id: summary?.event_id!,
					lessonData: lesson,
					summary_id: summary?.event_id!,
					debrief_id: debrief?._id,
				})
			)
		},
		[company_id, summary?.event_id, debrief?._id]
	)

	const saveNewLesson = useCallback(
		(lesson: Lesson) => {
			if (!summary?.event_id) {
				alert('Error with the system, Please try later.') // remove later on!
				return
			}
			const [ownerId] = lesson.members_id

			dispatch(
				create_matchpoint_lesson({
					company_id: company_id!,
					event_id: summary.event_id!,
					lessonData: lesson,
					debrief_id: debrief?._id,
					summary_id: summary.event_id,
					lessonOwner_id: ownerId,
				})
			)
		},
		[company_id, summary?.event_id, debrief?._id]
	)

	const deleteLesson = useCallback(
		(id: string) => {
			dispatch(
				delete_matchpoint_lesson({
					company_id: company_id!,
					event_id: summary?.event_id!,
					lesson_id: id,
					summary_id: summary?.event_id!,
				})
			)
		},
		[company_id]
	)

	return {
		update: updateLesson,
		deleteLessonById: deleteLesson,
		save: saveNewLesson,
	}
}
