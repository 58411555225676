export const thePraiseIsAboutReceiver: Record<number, string> = {
	1: '%NAME% wants to praise you on the response she received from you %CONTEXT% %DATE%.',
	2: '%NAME% wants to praise you on your collaboration with her held %CONTEXT% %DATE%.',
	3: '%NAME% wants to praise you for keeping to your agreement with her %DATE%.',
	4: '%NAME% wants to praise you for delivering on time %DATE%.',
	5: '%NAME% wants to praise you for following procedure %DATE%.',
	6: '%NAME% wants to praise you for meeting your goal %DATE%.',
	7: '%NAME% wants to praise you for something you said %CONTEXT% %DATE%.',
	8: '%NAME% wants to praise you for something you wrote %CONTEXT% %DATE%.',
	9: '%NAME% wants to praise you for %REASON% during %CONTEXT% %DATE%.',
	10: '%NAME% wants to praise you for %REASON% during %CONTEXT% %DATE%.',
	11: '%NAME% wants to praise you for %REASON% during a %CONTEXT% %DATE%.',
	12: '%NAME% wants to praise you for sharing information %CONTEXT% %DATE%.',
	13: '%NAME% wants to praise you for giving a report %CONTEXT% %DATE%.',
	14: '%NAME% wants to praise you for involving her in the decision-making process %CONTEXT% %DATE%.',
	15: '%NAME% wants to praise you for praising her %CONTEXT% %DATE%.',
	16: '%NAME% wants to praise you for praising %PARTICIPANTS% %CONTEXT%  %DATE%.',
	17: '%NAME% wants to praise you for giving her feedback %CONTEXT%  %DATE%.',
	18: '%NAME% wants to praise you for giving feedback to %PARTICIPANTS% %CONTEXT%  %DATE%.',
	19: '%NAME% wants to praise you for praising her and providing feedback %CONTEXT%  %DATE%.',
	20: '%NAME% wants to praise you for praising and providing feedback to %PARTICIPANTS% %CONTEXT%  %DATE%.',
	21: '%NAME% wants to praise you for setting expectations %CONTEXT% %DATE%.',
}

export const praiseFreeTextDescriptionsReceiver: Record<number, string> = {
	1: 'The response you gave:',
	2: 'What the collaboration was about:',
	3: 'What the agreement was about:',
	4: 'The deadline set:',
	5: 'The procedure followed:',
	6: 'The goal met:',
	7: 'What you said:',
	8: 'What you wrote:',
	12: 'The information shared:',
	13: 'The report given:',
	14: 'The decision-making process:',
	15: 'The praise:',
	17: 'The feedback given:',
	19: 'The praise and feedback given:',
	21: 'The expectations set:',
}

export const praiseContributionToSuccessReceiver: Record<number, string> = {
	1: 'Because of your response to %NAME%, her ability to achieve her goal %GOAL% increased by %PERCENT%%.',
	2: 'Because of your collaboration with %NAME%, her ability to achieve her goal %GOAL% increased by %PERCENT%%.',
	3: 'Because you kept to your agreement with %NAME%, her ability to achieve her goal %GOAL% increased by %PERCENT%%.',
	4: "Because you delivered on time, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%.",
	5: "Because you followed procedure, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%.",
	6: "Because you met your goal, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%.",
	7: "Because of what you said, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%.",
	8: "Because of what you wrote, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%.",
	9: "Because of your gesture during the meeting, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%.",
	10: "Because of your conduct in the meeting, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%.",
	11: "Because of your communication style in the meeting, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%.",
	12: "Because of your information, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%.",
	13: "Because of your report, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%.",
	14: "Because you involved %NAME% in the decision-making process, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%.",
	15: "Because you praised %NAME%, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%%.",
	17: "Because you gave %NAME% feedback, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%.",
	19: "Because you provided feedback and praised %NAME%, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%.",
	21: "Because you set expectations, %NAME%'s ability to achieve her goal %GOAL% increased by %PERCENT%%.",
}
export const mapContext: Record<string, string> = {
	'Casual conversation': 'in a casual conversation',
	'Phone call': 'on the phone',
	'Social media': 'on Social media',
	'Say=Do system': 'in the Solve system',
	'Instant message': 'by instant message',
	meeting: 'in a meeting',
	Email: 'by email',
}
export const furtherImpactPraiseReceiver: Record<string, string> = {
	energy:
		"You contributed to saving or increasing %NAME%'s energy level in relation to %IMPACTED%",
	security: "You grew %NAME%'s sense of security in relation to %IMPACTED%",
	'feel closer': 'You helped %NAME% feel closer to you.',
	professional:
		"You helped improve %NAME%'s professional standing in  %IMPACTED%",
	meaningful: 'You made %NAME% feel more meaningful.',
	belonging:
		"You increased %NAME%'s sense of belonging in relation to %IMPACTED%",
	responsibility:
		'You enabled %NAME% to initiate and take on more responsibility in front of %IMPACTED%',
	develop: 'You empowered %NAME% to learn and develop herself.',
}
