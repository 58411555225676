import { FC, useContext, useRef } from 'react'
import { makeStyles, Typography, Theme } from '@material-ui/core'
import clickLove from 'assets/clickLove.svg'
import clickClap from 'assets/clickClap.svg'
import clickLike from 'assets/clickLike.svg'
import { ClickIcon } from './ClickIcon'
import { useTranslation } from 'react-i18next'
import {
	replaceFunction,
	getParticipantsName,
} from '../../../utils/outputs/helpers'
import UserAvatar from 'components/common/Avatar'
import { Reactions } from 'enums/reactions/reactions.enum'
import { useDispatch } from 'react-redux'
import IUser from '../../../interface/user/user-interface'
import { update_praise_reactions } from 'redux/praise/praise-actions'
import { uniqeId } from 'utils/helpers'
import { WallOfPraiseOutput } from 'interface/bot'
import { Langs } from 'enums/app'
import dayjs from 'dayjs'
import { AppDirectionContext } from 'context/languages-context'
import { BaseContext, Context } from '../../../interface/bot/index'
import { IUserPersonalInfo } from '../../../interface/user/user-interface'

interface Props {
	templates: WallOfPraiseOutput
	contextTemplate: BaseContext
	userId: string
	company_id: string
	praise_id: string
	outputType: string | number
	sender: IUser
	receiver: IUser
	createdAt: Date
	contributions: string
	context: string
	summarizedPraise: string
	reactions: any
	usersData: any
	participants: IUser[]
	language: Langs
	isMobile: boolean
	onShowMoreReactors: (users: any, type: string, img: string) => void
}

const clickIcons = [
	{ img: clickLike, type: Reactions.like },
	{ img: clickLove, type: Reactions.love },
	{ img: clickClap, type: Reactions.clap },
]

type WallOfPraiseReplacementMapProps = {
	senderName: string
	receiverName: string
	contributions: string
	templates: WallOfPraiseOutput
	context: string
	language: Langs
	reason?: string
	participants?: string
}

export const wallOfPraiseReplacementMap = ({
	senderName,
	receiverName,
	contributions,
	reason,
	participants,
	templates,
	context,
	language,
}: WallOfPraiseReplacementMapProps): Record<string, string> => {
	const PERCENT = contributions?.split(' ')[contributions?.split(' ').length - 1]
	let data = {
		reasonOfPraise: replaceFunction(
			templates?.reasonTemplate ?? {},
			{
				'%RECEIVER_NAME%': receiverName,
				'%SENDER_NAME%': senderName,
				'%REASON%': reason ?? '',
				'%PARTICIPANTS%': participants ?? '',
				'%CONTEXT%': context ?? '',
			},
			language
		),
		contribution: PERCENT
			? replaceFunction(
				templates?.contributionTemplate ?? {},
				{
					'%RECEIVER_NAME%': receiverName,
					'%SENDER_NAME%': senderName,
					'%PERCENT%': PERCENT,
				},
				language
			)
			: ' ',
	}

	return data
}

const PraiseCard: FC<Props> = ({
	contextTemplate,
	templates,
	userId,
	company_id,
	language,
	praise_id,
	participants,
	contributions,
	sender = {} as IUser,
	receiver = {} as IUser,
	createdAt,
	usersData,
	reactions,
	summarizedPraise,
	context,
	isMobile,
	onShowMoreReactors,
}) => {
	const { isRtlDirection } = useContext(AppDirectionContext)
	const iconStyles = useIconStyles()
	const praiseReactions = { ...reactions }
	const dispatch = useDispatch()
	const classes = useStyles({ isRtlDirection })
	const { t } = useTranslation()
	const { personalInfo = {} as IUserPersonalInfo } = sender ?? {
		personalInfo: {},
	}
	const { firstName = '', lastName = '' } = personalInfo ?? {
		firstName: '',
		lastName: '',
	}

	const {
		personalInfo: {
			firstName: receiverFirstName = '',
			lastName: receiverLastName = '',
		} = {} as IUserPersonalInfo,
	} = receiver ?? { personalInfo: {} }

	const onClick = (reaction: Reactions) => {
		for (const r in praiseReactions) {
			if (r === reaction) {
				praiseReactions[r].includes(userId)
					? (praiseReactions[r] = praiseReactions[r].filter(
						(id: string) => id !== userId
					))
					: (praiseReactions[r] = [...praiseReactions[r], userId])
			} else if (praiseReactions[r].includes(userId))
				praiseReactions[r] = praiseReactions[r].filter(
					(id: string) => id !== userId
				)
		}

		dispatch(
			update_praise_reactions({
				company_id,
				praise_id,
				reactions: praiseReactions,
			})
		)
	}
	const reason = // just a plaster!! fix the answers in the bot they have a dot - remove it!!
		summarizedPraise[summarizedPraise.length - 1] === '.'
			? summarizedPraise.slice(0, summarizedPraise.length - 1)
			: summarizedPraise

	const _context = contextTemplate[context as Context] ?? {}

	const { reasonOfPraise, contribution } = wallOfPraiseReplacementMap({
		senderName: firstName,
		receiverName: receiverFirstName,
		contributions,
		templates,
		context: _context[language] ? _context[language] : ' ',
		reason,
		participants: getParticipantsName(participants, t('and')),
		language,
	})

	return (
		<div className={classes.praiseContainer}>
			<div className={classes.participantsSection}>
				<div>
					<div className={classes.userDescription}>
						<UserAvatar userInfo={sender} className={classes.userNameAvatar} />
						<div className={classes.userFullName}>
							{firstName.concat(' ', lastName)}
						</div>
					</div>
					<div className={classes.usersMidContainer}>
						<Typography style={{ fontSize: '14px' }}>
							<div className={classes.praised}> {t('praised')} </div>
							<span className={classes.sumOfReactions}>
								{t('on')} {dayjs(createdAt).format('DD/MM/YYYY')}
							</span>
						</Typography>
					</div>
					<div className={classes.userDescription} style={{}}>
						<UserAvatar userInfo={receiver} className={classes.userNameAvatar} />
						<div className={classes.userFullName}>
							{receiverFirstName.concat(' ', receiverLastName)}
						</div>
					</div>
				</div>
			</div>

			<Typography className={classes.summerizedPraise}>
				{reasonOfPraise}
				<br />
				{contribution}
			</Typography>
			<div className={classes.reactionsContainer}>
				{clickIcons.map((icon, index) => {
					const key = uniqeId()
					const reactions = praiseReactions[icon.type]
					return (
						<ClickIcon
							onShowMoreReactors={onShowMoreReactors}
							classes={iconStyles}
							usersData={usersData.filter((u: any) => reactions?.includes(u.id))}
							onClick={() => onClick(icon.type)}
							key={`${index}-${key}`}
							type={t(icon.type)}
							img={icon.img}
							isClicked={reactions.includes(userId)}
							count={reactions ? reactions.length : 0}
							isMobile={isMobile}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default PraiseCard
const useStyles = makeStyles<Theme, { isRtlDirection: boolean }>(
	({ breakpoints, palette }) => ({
		usersMidContainer: {
			position: 'absolute',
			left: ({ isRtlDirection }) => (isRtlDirection ? '28.5%' : '27.5%'),
			textAlign: 'center',
			[breakpoints.down('sm')]: {
				left: ({ isRtlDirection }) => (isRtlDirection ? '29.5%' : '31%'),
			},
			// [breakpoints.down('sm')]: {
			// 	left: ({ isRtlDirection }) => (isRtlDirection ? '34%' : '39.5%'),
			// },
		},
		praised: {
			fontSize: '110%',
			color: '#34AB53',
			fontWeight: 'bolder',
			[breakpoints.down('sm')]: {
				fontSize: ({ isRtlDirection }) => (isRtlDirection ? '' : '16px'),
			},
		},
		userFullName: {
			marginTop: '1%',
			textAlign: 'center',
			fontSize: '105%',
			[breakpoints.down('sm')]: {
				fontSize: ({ isRtlDirection }) => (isRtlDirection ? '16px' : '16px'),
			},
		},
		userDescription: {
			marginLeft: '-10%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			width: '10rem',
			alignSelf: 'start',
			'&:first-child': {
				[breakpoints.down('sm')]: {
					width: '46%',
					marginLeft: ({ isRtlDirection }) => (isRtlDirection ? '' : '-5%'),
				},
			},
			'&:last-child': {
				[breakpoints.down('sm')]: {
					width: '46%',

					marginRight: ({ isRtlDirection }) => (isRtlDirection ? '0' : '-5%'),
				},
			},
		},
		praiseContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			padding: '1.5% 2.5% 1.5% 2.5%',
			color: palette.text.primary,
			borderBottom: '1px solid rgba(49, 49, 49, 0.152716)',
			'&:last-child': {
				borderBottom: 'none',
				[breakpoints.down('sm')]: {
					justifyContent: 'flex-end',
				},
			},
			'&:div': {
				display: 'flex',
				[breakpoints.down('sm')]: {
					width: '100%',
					'& > div': {
						width: '100%',
					},
				},
			},

			[breakpoints.down('sm')]: {
				padding: '6.5% 3.5% 6.5% 3.5%',
				flexDirection: 'column',
			},
		},
		participantsSection: {
			marginRight: ({ isRtlDirection }) => (isRtlDirection ? '-50px' : '0'),
			position: 'relative',
			height: 'fit-content',
			fontSize: '14px',
			fontWeight: 600,
			display: 'flex',
			flexDirection: 'column',

			'& > div': {
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			},

			[breakpoints.down('sm')]: {
				marginRight: ({ isRtlDirection }) => (isRtlDirection ? '-8%' : '0'),
				width: '100%',
				'& > div': {
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				},
			},
		},
		//
		reactionsContainer: {
			justifyContent: 'center',
			alignItems: 'center',
			display: 'flex',
			marginTop: 0,
			[breakpoints.down('sm')]: {
				marginTop: '5%',
			},
			[breakpoints.down('xs')]: {
				marginTop: 0,
			},
		},
		userImage: {
			width: 34,
			height: 34,
			borderRadius: '50%',
			objectFit: 'cover',
			marginRight: 9,
		},
		sumOfReactions: {
			color: palette.text.disabled,
			fontSize: '14px',
			[breakpoints.down('sm')]: {
				fontSize: ({ isRtlDirection }) => (isRtlDirection ? '' : '14px'),
			},
		},
		summerizedPraise: {
			padding: '0 3% 0 3%',
			fontSize: '14px',
			fontWeight: 500,
			width: '40vw',
			textAlign: ({ isRtlDirection }) => (isRtlDirection ? 'right' : 'left'),
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			color: palette.text.primary,
			[breakpoints.down('sm')]: {
				paddingLeft: '0',
				paddingRight: ({ isRtlDirection }) => (isRtlDirection ? '2%' : '0'),
				marginTop: '3%',
				justifyContent: 'flex-start',
				width: '100%',
			},
		},
		userNameAvatar: {
			width: '64px',
			height: '64px',
			marginRight: '9px',
		},
	})
)

const useIconStyles = makeStyles<Theme>(({ breakpoints, palette }) => ({
	expandTitle: {
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	iconContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[breakpoints.down('xs')]: {
			flexDirection: 'row',
		},
	},
	count: {
		width: '100%',
		textAlign: 'center',
		[breakpoints.down('xs')]: {
			width: '50%',
		},
	},
	toolTipTitle: {
		width: '8vw',
		height: 'auto',
		'& h1': {
			fontSize: '1rem',
		},
	},
	reactionContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		width: '45px',
		height: '45px',
		borderRadius: '10px',
		margin: '9px',
		color: '#717171',
		cursor: 'pointer',
		textAlign: 'center',
		[breakpoints.up('xs')]: {
			'& > img': {
				width: '28px',
				height: '28px',
			},
		},

		[breakpoints.down('xs')]: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			flexWrap: 'wrap',
			margin: '15% 10px 0',
			width: '23vw',
			height: '10vw',
			'& > p': {
				margin: 0,
			},
			'& > img': {
				width: '50%',
				height: '70%',
			},
		},
	},
}))
