import { BaseCompanyOutput } from 'interface/bot/index'
import IUser from 'interface/user/user-interface'

export const filterUserOutputs = <T extends BaseCompanyOutput<IUser>>(
	outputs: Array<T>
): Array<T> => {
	outputs = outputs.reduce((acc: T[], current) => {
		const ids = acc.map(a => a._id)
		if (!ids.includes(current._id)) return [...acc, current]

		return acc
	}, [])

	return outputs
}
