import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from 'redux/user/user-selector'
import classes from './style.module.css'
import { useWindowWidth } from '../Header/useHooks/useWindowWidth'
import DesktopNavigation from './DesktopNavigation'
import MobileNavigation from './MobileNavigation'
import { License } from 'enums/License/License.enum'
import {
	getNotifications,
	getNotificationsV2,
} from 'redux/notifications/notifications-action'
import { newNotificationsSelector } from 'redux/notifications/notifications-selector'
import { employeesSelector } from '../../../redux/employee/employee.selector'
import { get_employees } from '../../../redux/employee/employee-actions'

type SidebarNavigationProps = {
	isRtl: boolean
}

export const NavigationBar: FC<SidebarNavigationProps> = ({ isRtl }) => {
	const user = useSelector(userSelector)
	const employees = useSelector(employeesSelector)

	const { personalInfo, statusInfo, _id, company_id } = user

	const { firstName = '', lastName = '' } = personalInfo || {}
	const { title = '', role, license } = statusInfo || {}

	const dispatch = useDispatch()
	const windowWidth = useWindowWidth()

	const mobileWidth = 900
	const isMobile = mobileWidth >= windowWidth

	useEffect(() => {
		if (!_id) return

		dispatch(
			// getNotifications({ skip: 0, receiverId: _id })
			getNotificationsV2({ skip: 0, receiverId: _id })
		)
	}, [_id])

	useEffect(() => {
		if (employees.length || !company_id) return

		dispatch(get_employees({ company_id }))
	}, [employees.length, company_id])

	const props = { role, firstName, lastName, title, license: license as License }

	return (
		<div className={classes.navbarContainer}>
			{isMobile ? (
				<MobileNavigation {...props} />
			) : (
				<DesktopNavigation {...props} />
			)}
		</div>
	)
}
export default NavigationBar
